import React, { useState } from 'react'

import Points from 'assets/img/points.svg'
import Button from 'components/Button'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { saveLoyaltyConfigAPI } from 'redux/brand/actions'

type TSetupLoyaltyRewardsProp = {
  onFormChange: (data: any) => void
}

export default function SetupLoyaltyRewards({
  onFormChange,
}: TSetupLoyaltyRewardsProp): JSX.Element {
  const [loading, setLoading] = useState(false)
  const { brandId } = useParams<TParamTypes>()

  const saveLoyaltyConfig = async () => {
    setLoading(true)
    try {
      const res = await saveLoyaltyConfigAPI(brandId)
      toast.success(res.message)
      onFormChange(res.brandLoyaltyConfig)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <div className='flex flex-col items-center  space-y-8 mt-8'>
      <div className='text-lead text-center'>
        Activate loyalty rewards to retain existing
        <br />
        customers and attract new ones
      </div>
      <img src={Points} />
      <Button
        color='primary'
        onClick={() => saveLoyaltyConfig()}
        loading={loading}
      >
        Setup Loyalty Rewards
      </Button>
    </div>
  )
}
