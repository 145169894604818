import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import type { TCouponForm } from 'types/coupon'
import axios from 'utils/axiosApi'

export const setKeyword = createAction<string>('coupon/setKeyword')
export const setPage = createAction<number>('coupon/setPage')
export const setCurrentLocationId = createAction<string>(
  'coupon/setCurrentLocationId',
)

export const getCouponList = createAsyncThunk(
  'coupon/getCouponList',
  async ({
    locationId,
    keyword,
    page,
  }: {
    locationId: string
    keyword: string
    page: number
  }) => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons`,
      params: {
        keyword,
        page,
      },
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const createCoupon = ({
  locationId,
  params,
}: {
  locationId: string
  params: TCouponForm
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons`,
      data: params,
      method: 'post',
    })
    return response.data
  }
}

export const editCoupon = ({
  params,
  id,
  locationId,
}: {
  params: TCouponForm
  id: string
  locationId: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons/${id}`,
      data: params,
      method: 'patch',
    })
    return response.data
  }
}

export const deleteCoupon = ({
  id,
  locationId,
}: {
  id: string
  locationId: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons/${id}`,
      method: 'delete',
    })
    return response.data
  }
}

export const getCouponData = ({
  id,
  locationId,
}: {
  id: string
  locationId: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons/${id}`,
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

export const getCouponMenuItems = ({
  locationId,
  keyword,
}: {
  locationId: string
  keyword?: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/coupon/free_items`,
      method: 'get',
      params: {
        keyword,
      },
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}
