export const STEP_LIST = [
  {
    label: '1',
    title: 'Introduction',
  },
  {
    label: '2',
    title: 'Points Setting',
  },
  {
    label: '3',
    title: 'Rewards Setting',
  },
  {
    label: '4',
    title: 'Other Setting',
  },
]

export const EXPIRATION_LIST = [
  {
    label: '6 months',
    value: '6',
  },
  {
    label: '12 months',
    value: '12',
  },
]

export const SAVE_TIME = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
]

export const POINTS_LIST = [
  {
    label: '1000 pts',
    value: '1000',
  },
  {
    label: '1500 pts',
    value: '1500',
  },
  {
    label: '2000 pts',
    value: '2000',
  },
  {
    label: '2500 pts',
    value: '2500',
  },
  {
    label: '3000 pts',
    value: '3000',
  },
  {
    label: '4000 pts',
    value: '4000',
  },
  {
    label: '5000 pts',
    value: '5000',
  },
  {
    label: '6000 pts',
    value: '6000',
  },
]

export const SIGNUP_POINTS = [
  {
    label: '100 pts',
    value: '100',
  },
  {
    label: '300 pts',
    value: '300',
  },
  {
    label: '500 pts',
    value: '500',
  },
  {
    label: '1000 pts',
    value: '1000',
  },
]

export const POINTS_NEED_LIST = [
  {
    label: '250 pts',
    value: '250',
  },
  {
    label: '500 pts',
    value: '500',
  },
  {
    label: '750 pts',
    value: '750',
  },
  {
    label: '1000 pts',
    value: '1000',
  },
  {
    label: '1250 pts',
    value: '1250',
  },
  {
    label: '1500 pts',
    value: '1500',
  },
  {
    label: '1750 pts',
    value: '1750',
  },
  {
    label: '2000 pts',
    value: '2000',
  },
  {
    label: '2500 pts',
    value: '2500',
  },
  {
    label: '3000 pts',
    value: '3000',
  },
]
