import React, { useState } from 'react'

import BankInfoSvg from 'assets/img/bankInfo.svg'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateBankAccountApi } from 'redux/accountAndPayout'
import { useAppSelector } from 'redux/hooks'
import { sendCode } from 'redux/user'

import SecurityVerificationModal from './SecurityVerificationModal'
import { ACCOUNTTYPE } from '../../billingAndInvoice/helpers'

const initSecurityData = {
  open: false,
  confirmLoading: false,
  sendLoading: false,
  error: '',
}

interface IModalTypes {
  disabled?: boolean
  onSuccess: () => void
}
export default function PayoutBankModal({ disabled, onSuccess }: IModalTypes) {
  const { locationId = '' } = useParams<TParamTypes>()
  const { profile } = useAppSelector(state => state.auth)
  const phone = profile?.phone
  const [isVisible, setIsVisible] = useState(false)
  const [securityData, setSecurityData] = useState(initSecurityData)
  const [formData, setFormData] = useState({
    accountName: '',
    accountType: '',
    routingNumber: '',
    accountNumber: '',
    repeatAccountNumber: '',
    verification_code: '',
  })
  const {
    accountName,
    accountType,
    routingNumber,
    accountNumber,
    repeatAccountNumber,
  } = formData

  const handleSubmit = async () => {
    setSecurityData(prev => ({ ...prev, confirmLoading: true, error: '' }))
    const params = {
      account_holder_name: accountName,
      account_holder_type: accountType,
      routing_number: routingNumber,
      account_number: accountNumber,
      phone,
      verification_code: formData.verification_code,
    }
    try {
      const res = await updateBankAccountApi(params, locationId)
      setIsVisible(false)
      onSuccess()
      setSecurityData(initSecurityData)
      toast.success(res.message)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setSecurityData(prev => ({ ...prev, error: msg }))
    } finally {
      setSecurityData(prev => ({ ...prev, confirmLoading: false }))
    }
  }

  const onChangeForm = (name: string, value: any) => {
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleSendCode = async () => {
    try {
      setSecurityData(prev => ({ ...prev, open: true, sendLoading: true }))
      const res = await sendCode({
        phone,
      })
      toast.success(res.message)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setSecurityData(prev => ({ ...prev, error: msg }))
    } finally {
      setSecurityData(prev => ({ ...prev, sendLoading: false }))
    }
  }

  return (
    <>
      <Button
        color='secondary'
        size='sm'
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        UPDATE BANK
      </Button>
      <ModalFull
        open={isVisible}
        title='Update Bank'
        okBtnDisabled={
          !accountName ||
          !accountType ||
          !routingNumber ||
          !accountNumber ||
          !repeatAccountNumber ||
          accountNumber !== repeatAccountNumber
        }
        toggle={() => setIsVisible(false)}
        onOk={() => {
          handleSendCode()
        }}
      >
        <>
          <div className='w-[368px] h-[216px] m-auto'>
            <img src={BankInfoSvg} alt='' />
          </div>
          <div className='mt-6'>
            <div className=''>
              <Field
                label='Account name'
                name='Account name'
                placeholder='Input Text'
                type='text'
                value={accountName}
                onChange={e => {
                  onChangeForm('accountName', e.target.value)
                }}
              />
            </div>
            <div className='flex my-6'>
              <div className='flex-1 mr-6 '>
                <Select
                  className='flex-1'
                  label='Account type'
                  value={accountType}
                  placeholder='Select option'
                  options={ACCOUNTTYPE}
                  onChange={(mode: any) => {
                    onChangeForm('accountType', mode)
                  }}
                />
              </div>
              <div className='flex-1'>
                <Field
                  className='flex-1'
                  label='Routing number'
                  name='Routing number'
                  placeholder='Input here'
                  type='text'
                  value={routingNumber}
                  onChange={e => {
                    onChangeForm('routingNumber', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='flex'>
              <div className='flex-1 mr-6 '>
                <Field
                  className='flex-1'
                  label='Account number'
                  name='Account number'
                  placeholder='Input here'
                  type='text'
                  value={accountNumber}
                  onChange={e => {
                    onChangeForm('accountNumber', e.target.value)
                  }}
                />
              </div>
              <div className='flex-1'>
                <Field
                  className='flex-1'
                  label='Repeat account number'
                  name='Repeat account number'
                  placeholder='Input here'
                  type='text'
                  value={repeatAccountNumber}
                  onChange={e => {
                    onChangeForm('repeatAccountNumber', e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </>
      </ModalFull>
      <SecurityVerificationModal
        open={securityData.open}
        confirmLoading={securityData.confirmLoading}
        sendLoading={securityData.sendLoading}
        error={securityData.error}
        phone={phone}
        code={formData.verification_code}
        toggle={() => {
          setSecurityData(initSecurityData)
        }}
        onChangeForm={onChangeForm}
        handleSendCode={handleSendCode}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
