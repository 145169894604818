import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import { toast } from 'react-toastify'
import type { TCustomerList, TCustomerFormData } from 'types/customer'

import {
  getCustomersList,
  setFilterList,
  setFilterKeywordList,
  setPageSize,
  setPage,
  setFormData,
  setShowFilter,
} from './actions'

type TCustomerState = {
  getCustomerListRequest: boolean
  customerList: null | TCustomerList
  filterList: string[]
  filterKeywordList: any[]
  pageSize: number
  page: number
  formData: TCustomerFormData
  showFilter: string | boolean
  hasLoyaltyReward: boolean
}

const initialState: TCustomerState = {
  getCustomerListRequest: false,
  customerList: null,
  filterList: [''],
  filterKeywordList: [
    { value: 'Keyword filter', label: 'Keyword filter' },
    { value: 'Order count', label: 'Order count' },
    { value: 'Total spend', label: 'Total spend' },
    { value: 'Last order date', label: 'Last order date' },
  ],
  pageSize: 20,
  page: 1,
  formData: {
    keyword: '',
    last_order_date: moment().format('YYYY-MM-DD'),
    last_order_operator: '',
    order_count_operator: '',
    order_count: '1',
    spent_total: '0.00',
    spent_total_operator: '',
  },
  showFilter: '',
  hasLoyaltyReward: false,
}

export const customerReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCustomersList.pending, state => {
      state.getCustomerListRequest = true
    })
    .addCase(getCustomersList.fulfilled, (state, action) => {
      state.getCustomerListRequest = false
      state.customerList = action.payload.customers
      state.hasLoyaltyReward = action.payload.has_opened_loyalty
    })
    .addCase(getCustomersList.rejected, (state, action) => {
      state.getCustomerListRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(setFilterList, (state, action) => {
      state.filterList = action.payload
    })
    .addCase(setFilterKeywordList, (state, action) => {
      state.filterKeywordList = action.payload
    })
    .addCase(setPageSize, (state, action) => {
      state.pageSize = action.payload
    })
    .addCase(setPage, (state, action) => {
      state.page = action.payload
    })
    .addCase(setFormData, (state, action) => {
      state.formData = action.payload
    })
    .addCase(setShowFilter, (state, action) => {
      state.showFilter = action.payload
    })
})

export default customerReducer
