import React, { useEffect } from 'react'

import { toast } from 'react-toastify'
import { TLocation } from 'types/location'

import LocationSelect from './LocationSelect'

export type TLocationsFormProp = {
  showTitle?: boolean
  formData: any
  locations:
    | TLocation[]
    | Pick<TLocation, 'id' | 'name' | 'address' | 'access'>[]
  onFormChange: (name: string, value: any) => void
}

export default function LocationsForm({
  showTitle = true,
  formData,
  locations,
  onFormChange,
}: TLocationsFormProp): JSX.Element {
  let assigned_locations = formData.locations || []

  useEffect(() => {
    assigned_locations = []
    if (formData.role === 'brand_admin') {
      locations.map(location => {
        assigned_locations.push(location.id)
      })
      onFormChange('locations', assigned_locations)
    }
  }, [formData.role])

  const toggleAssigned = (id: number) => {
    if (formData.role !== 'brand_admin') {
      const index = assigned_locations.findIndex((v: number) => v === id)
      const assigned = [...assigned_locations]
      if (index > -1) {
        assigned.splice(index, 1)
      } else {
        assigned.push(id)
      }

      onFormChange('locations', assigned)
    } else {
      toast.warn(
        'As a Brand Admin role, all location(s) will be assigned, and this is a systemic setting that can not be overridden.',
      )
    }
  }
  return (
    <div>
      {showTitle && (
        <p className='text-silver font-bold text-xs mb-2'>Assign Location</p>
      )}
      <div className='grid grid-cols-2 gap-4'>
        {locations.map(location => (
          <LocationSelect
            key={location.id}
            location={location}
            onClick={() => {
              if (location.access) {
                toggleAssigned(location.id)
              }
            }}
            assigned={
              assigned_locations.findIndex((id: number) => id === location.id) >
              -1
            }
          />
        ))}
      </div>
    </div>
  )
}
