/**
 * Assign AccessSetting to the selected User
 * This decides whether the user has access to manage the location
 */
import { PERMISSION } from 'data/permission'
import { useAppSelector } from 'redux/hooks'

import AccessToggle, { TAccessItem } from './AccessToggle'

export type TAccessSettingFormItemProp = {
  formData: any
  onChange: (item: TAccessItem, v: boolean) => void
}

export default function AccessSettingFormItem({
  formData,
  onChange,
}: TAccessSettingFormItemProp): JSX.Element {
  const auth = useAppSelector(state => state.auth.authProfile.data)
  const isCanUpdateAccess =
    auth?.permissionMap?.[PERMISSION.UPDATEUSERACCESSSETTING]?.allowed == 1

  const access_setting: TAccessItem[] = formData.permissions || []

  return (
    <div>
      <p className='text-silver font-bold text-xs'>{formData.name}</p>
      <div
        className={`rounded-lg border-zinc border border-solid px-6 mt-2 ${
          formData.name !== 'User Management' ? 'py-4' : 'pt-6 pb-4'
        }`}
      >
        <div className='flex flex-col'>
          {access_setting.map((item, i) => (
            <AccessToggle
              disabled={!isCanUpdateAccess}
              hasBottom={
                !item.parent_permission_id &&
                !access_setting[i + 1]?.parent_permission_id
              }
              hasTop={
                !item.parent_permission_id &&
                !!access_setting[i - 1]?.parent_permission_id
              }
              key={item.id}
              label={formData.name}
              item={item}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
