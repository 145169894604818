import React, { useEffect, useState } from 'react'

import Tips from 'components/Tips'
import useSearch from 'hooks/useSearch'
import { useParams } from 'react-router-dom'
import { getCloverAPI } from 'redux/auth'

export default function CloverPage(): JSX.Element {
  const [textInfo, setTextInfo] = useState({ text: '', status: '' })
  const { locationId } = useParams<TParamTypes>()
  const { code, merchant_id, employee_id } = useSearch()

  useEffect(() => {
    if (code && locationId && merchant_id) {
      getClover()
    } else {
      setTextInfo({ text: 'Invalid input', status: 'error' })
    }
  }, [])

  const getClover = async () => {
    try {
      const res = await getCloverAPI({
        code,
        location_id: locationId,
        merchant_id,
        employee_id,
      })
      setTextInfo({ text: res.message, status: 'success' })
    } catch (e: any) {
      const msg = e.message || 'request error'
      setTextInfo({ text: msg, status: 'error' })
    }
  }

  return (
    <div className='flex justify-center'>
      {textInfo.text && <Tips status={textInfo.status} text={textInfo.text} />}
    </div>
  )
}
