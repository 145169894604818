import React, { useState, useEffect } from 'react'

import { SolidImage, LinedSearch } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import ModalFull from 'components/Modal/ModalFull'
import TabChangeBar from 'components/TabChangeBar'
import Upload from 'components/Upload/Upload'
import Waterfall from 'components/WaterFall/WaterFall'
import { toast } from 'react-toastify'
import {
  getWebsiteConstantApi,
  getUnsplashPhotosApi,
} from 'redux/brand/actions'

import UnsplashIcon from './Unsplash'

interface Iprops {
  className?: string
  labelText?: string
  width?: string
  height?: string
  value?: string
  onChange: (value: string, blob?: Blob) => void
  onRemove?: () => void
}

export default function SetImage({
  className = '',
  labelText,
  width,
  height = '124px',
  value,
  onChange,
  onRemove,
}: Iprops): JSX.Element {
  const [isVisible, setIsVisible] = useState(false)
  const [curTab, setCurTab] = useState<'stock' | 'own'>('stock')
  const [searchValue, setSearchValue] = useState('')
  const [foods, setFoods] = useState([])
  const [images, setImages] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false)
  const [maskShow, setMaskShow] = useState<boolean>(false)

  useEffect(() => {
    getWebsiteConstant('banner')
  }, [])

  // 获取常量枚举
  const getWebsiteConstant = async (type: string) => {
    try {
      const res = await getWebsiteConstantApi(type)
      const options = res?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
      setFoods(options)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
  }

  const handleSearchImage = async (keyword: string, page = 1) => {
    setLoading(true)
    // 首次加载
    if (page === 1) {
      setImages([])
    }
    try {
      const res = await getUnsplashPhotosApi({
        keyword,
        page,
        per_page: 10,
      })
      const imgs =
        res?.map((v: any) => ({
          small: v?.urls?.small,
          regular: v?.urls?.regular,
        })) || []
      setPage(page)
      if (page === 1) {
        setImages(imgs)
      } else {
        setImages(prev => [...prev, ...imgs])
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const TipNode = (
    <div className='text-center'>
      <SolidImage size={24} className='text-silver flex justify-center' />
      <div className='text-sm font-bold mt-2'>
        <span className=' text-blue'>Click here </span>
        <span className='text-silver'>to set the image</span>
      </div>
    </div>
  )
  return (
    <>
      <div className={className} style={{ fontSize: 0 }}>
        <Label className='mb-2 text-xs text-silver'>{labelText}</Label>
        <div
          className='rounded-lg bg-field p-2 relative cursor-pointer overflow-hidden'
          style={{ width, height }}
        >
          {!value && (
            <div
              className='rounded-lg border border-zinc box-border border-dashed h-full flex justify-center items-center'
              onClick={() => {
                setIsVisible(true)
              }}
            >
              {TipNode}
            </div>
          )}
          {value && (
            <>
              <div
                className='absolute inset-0 z-10 cursor-pointer m-auto bg-cover	bg-no-repeat bg-center'
                style={{ backgroundImage: `url(${value})` }}
                onMouseOver={() => setMaskShow(true)}
              />
              <div
                className={cn(
                  'absolute top-0 left-0 w-full h-full z-30 flex  flex-col justify-center items-center transition duration-300 ease-in-out',
                  { 'bg-opacity-64': maskShow },
                  { invisible: !maskShow },
                )}
                onMouseOver={() => setMaskShow(true)}
                onMouseOut={() => setMaskShow(false)}
              >
                <div
                  className='mt-4 px-4 py-2 bg-blue text-white text-xs font-bold cursor-pointer rounded-lg'
                  onClick={() => {
                    setIsVisible(true)
                  }}
                >
                  REPLACE IMAGE
                </div>
                <div
                  className='text-white text-xs font-bold pt-4 cursor-pointer'
                  onClick={onRemove}
                >
                  REMOVE
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalFull
        open={isVisible}
        toggle={() => setIsVisible(!isVisible)}
        title='Homepage Picture'
        footer={null}
      >
        <div>
          <TabChangeBar
            activeItem={curTab}
            className='w-784px'
            containerClassName='w-388px'
            items={[
              { label: 'Search Stock Image', value: 'stock' },
              { label: 'Upload Your Own', value: 'own' },
            ]}
            onClick={e => setCurTab(e)}
          />
          {curTab === 'own' && (
            <div className='mt-3'>
              <Upload
                labelText='Image'
                desc={
                  <div className='text-sm font-bold mt-2'>
                    <span className=' text-blue'>Click here </span>
                    <span className='text-silver'>to set the image</span>
                  </div>
                }
                onChange={(blob, blobUrl) => {
                  onChange?.(blobUrl, blob)
                  setTimeout(() => {
                    setIsVisible(false)
                  }, 500)
                }}
              ></Upload>
            </div>
          )}
          {curTab === 'stock' && (
            <div className='w-full mt-6'>
              <div className='flex mb-6'>
                <Field
                  containerClassName='flex-1'
                  name='Searchtext'
                  value={searchValue}
                  onChange={e => {
                    setSearchValue(e.target.value)
                  }}
                  placeholder='Search'
                  suffix={<LinedSearch size={16} className='text-silver' />}
                  type='text'
                />
                <Button
                  size='md'
                  loading={loading}
                  disabled={!searchValue?.length}
                  color={searchValue?.length ? 'primary' : 'secondary'}
                  className='text-white ml-4'
                  onClick={() => handleSearchImage(searchValue)}
                >
                  Search
                </Button>
              </div>
              {(!images.length || !searchValue?.length) && (
                <div className='w-[496px] pb-4'>
                  <ul className='flex flex-wrap text-lead text-xs'>
                    {foods.map((item: any, index) => {
                      return (
                        <li
                          className='p-2 border rounded	border-zinc mr-2 mb-2 cursor-pointer'
                          onClick={() => {
                            if (!loading) {
                              setSearchValue(item.label)
                              handleSearchImage(item.label)
                            }
                          }}
                          key={index}
                        >
                          {item.label}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              <ImageList
                images={images}
                loading={loading}
                onChange={item => {
                  onChange(item)
                  setIsVisible(false)
                }}
                onNextPage={() => {
                  handleSearchImage(searchValue, page + 1)
                }}
              />
              <div className='flex items-center justify-center pt-6  border-t border-zinc text-ink text-base'>
                <UnsplashIcon />
                <span className='ml-2'>
                  Free stock images powered by Unsplash.com
                </span>
              </div>
            </div>
          )}
        </div>
      </ModalFull>
    </>
  )
}

interface ImageProps {
  images: string[]
  loading: boolean
  onChange: (image: string) => void
  onNextPage: (page?: number) => void
}

function ImageList({ images, loading, onChange, onNextPage }: ImageProps) {
  if (!images.length) {
    return null
  }
  return (
    <>
      <div className='pb-4'>
        <div className='h-[400px] overflow-scroll scrollbar-hide'>
          <Waterfall columnWidth={236} columnCount={2} gap={24}>
            {images.map((item: any, index) => {
              return (
                <li
                  key={index}
                  className='w-[236px] cursor-pointer'
                  onClick={() => onChange(item.regular)}
                >
                  <div className='w-full min-h-[50px] mb-6 overflow-hidden rounded border border-transparent hover:border-blue'>
                    <img src={item.small} alt='' />
                  </div>
                </li>
              )
            })}
          </Waterfall>
          <div
            className='w-[160px] h-10 leading-10 m-auto cursor-pointer rounded-lg bg-field text-ink text-center font-bold'
            onClick={() => {
              if (!loading) {
                onNextPage()
              }
            }}
          >
            {loading ? 'loading...' : 'LOAD MORE'}
          </div>
        </div>
      </div>
    </>
  )
}
