import { useEffect, useState } from 'react'

import { Dollar } from '@rushable/icons'
import Field from 'components/Field'
import currency from 'currency.js'
import BaseOrderTypeConfig from 'pages/orderSetting/components/BaseOrderTypeConfig'

type TOnDemandDeliveryProps = {
  className?: string
  label: string
  onDemandConfig: any
  onChange: (name: string, value: any) => void
}

export default function OnDemandDelivery({
  className,
  label,
  onDemandConfig,
  onChange,
}: TOnDemandDeliveryProps) {
  const [customerPay, setCustomerPay] = useState('')
  const {
    courier_fee,
    restaurant_pay,
    order_min,
    availability,
    prior_to_close,
  } = onDemandConfig || {}
  useEffect(() => {
    setCustomerPay(
      currency(courier_fee, {
        symbol: '',
      })
        .subtract(restaurant_pay)
        .format(),
    )
  }, [])
  return (
    <div className={className}>
      <div className='pb-6 text-dark-100 text-base font-bold'>
        On-demand delivery setting
      </div>
      <div className='flex gap-8 mb-4'>
        <Field
          type='text'
          disabled={true}
          suffix={<Dollar size={16} className='text-silver' />}
          value={`${courier_fee} per order`}
          name='courier_fee'
          label='Courier fee'
          containerClassName='w-full'
        />
        <Field
          type='tel'
          inputMode='money'
          name='customer_pay'
          label='Customer pay'
          value={customerPay}
          suffix={<Dollar size={16} className='text-silver' />}
          containerClassName='w-full'
          className='text-right'
          onChangeMoney={value => {
            // Customer pay 不能大于 Courier fee
            if (Number(value) <= Number(courier_fee)) {
              setCustomerPay(value)
              onChange(
                'restaurant_pay',
                currency(courier_fee, {
                  // 去掉 format 后金额前的 $ 符号
                  symbol: '',
                })
                  .subtract(value)
                  .format(),
              )
            }
          }}
        />
        <Field
          type='tel'
          inputMode='money'
          name='restaurant_pay'
          label='Restaurant pay'
          value={restaurant_pay}
          suffix={<Dollar size={16} className='text-silver' />}
          containerClassName='w-full'
          className='text-right'
          onChangeMoney={value => {
            // Restaurant pay 不能大于 Courier fee
            if (Number(value) <= Number(courier_fee)) {
              console.log(value, 'value')
              onChange('restaurant_pay', value)
              setCustomerPay(
                currency(courier_fee, {
                  // 去掉 format 后金额前的 $ 符号
                  symbol: '',
                })
                  .subtract(value)
                  .format(),
              )
            }
          }}
        />
      </div>
      <BaseOrderTypeConfig
        label={label}
        formData={{
          order_min,
          availability,
          prior_to_close,
        }}
        onFormChange={(name, value) => onChange(name, value)}
      />
    </div>
  )
}
