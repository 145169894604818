import DayClose from 'components/OpenHoursForm/DayClose'
import DayHoursField from 'components/OpenHoursForm/DayHoursField'
import { THoursInBusiness } from 'types/openHour'
import newid from 'utils/newid'

export type TDayHoursCollectionFormProp = {
  dailyHours?: THoursInBusiness[] | []
  onChange: (hoursInDay: THoursInBusiness[] | []) => void
}

type DayHoursType = 'from' | 'to'

export default function DayHoursCollection({
  dailyHours = [],
  onChange,
}: TDayHoursCollectionFormProp): JSX.Element {
  // 时间范围改变
  const onHourChange = (
    dateIndex: number,
    index: number,
    value: string,
    name: string,
  ) => {
    const item = dailyHours?.[dateIndex]?.value?.[index] || {}
    item[name as DayHoursType] = value
    onChange(dailyHours)
  }

  const addHours = (index: number, day: string) => {
    const item = { id: newid('hours'), day: day, from: '', to: '' }
    dailyHours[index].value.push(item)
    onChange(dailyHours)
  }

  const removeHours = (dateIndex: number, index: number) => {
    const values = dailyHours[dateIndex].value || []
    values.splice(index, 1)
    onChange(dailyHours)
  }
  return (
    <>
      <div>
        {dailyHours.map((dateItem, dateIndex) => {
          const hoursList = dateItem.value || []
          return (
            <div
              key={dateIndex}
              className='flex justify-between py-4 space-x-2'
            >
              <h5 className='text-ink text-base w-36 font-bold flex pt-3'>
                {dateItem.label}
              </h5>
              <div className='block'>
                {!hoursList.length && (
                  <DayClose
                    tips='Not Available'
                    canDelete={false}
                    addHours={() => {
                      addHours(dateIndex, dateItem.day || dateItem.label)
                    }}
                  />
                )}
                {hoursList.map((dayHours, index) => (
                  <DayHoursField
                    index={index}
                    key={dayHours.id}
                    dayHours={dayHours}
                    onChange={(name, value) => {
                      onHourChange(dateIndex, index, value, name)
                    }}
                    addHours={() => {
                      addHours(dateIndex, dayHours.day)
                    }}
                    removeHours={() => {
                      removeHours(dateIndex, index)
                    }}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
