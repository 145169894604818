import { useState, useEffect } from 'react'

import SaveChangeBar from 'components/SaveChangeBar'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import cloneDeep from 'lodash/cloneDeep'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getRegularApi, updateRegularHourApi } from 'redux/business/actions'
import { THoursInBusiness } from 'types/openHour'

import SkeletonItem from './components/SkeletonItem'
import WeekHours from './components/WeekHours'
import { DAYSMAP, WEEKDATAINIT } from './help/index'

export default function PickupSettingDisclosure(): JSX.Element {
  const { locationId = '' } = useParams<TParamTypes>()
  const [hoursInDay, setHoursInDay] = useState<THoursInBusiness[]>([])
  const [hoursInDayInit, setHoursInDayInit] = useState<THoursInBusiness[]>([])
  const [formStatus, setFormStatus] = useState({
    loading: false,
    isDirty: false,
    error: '',
  })
  const compareFn = useDebounce(compare)

  useEffect(() => {
    compareFn(hoursInDay, hoursInDayInit, (flag: boolean) =>
      setFormStatus(prev => ({ ...prev, isDirty: flag })),
    )
  }, [hoursInDay, hoursInDayInit])

  useEffect(() => {
    getRegularHours()
  }, [])

  const getRegularHours = async () => {
    try {
      const res = await getRegularApi(locationId || '')
      initData(res)
    } catch (e: any) {
      toast.error(e?.message || 'request error')
    }
  }

  const initData = (res: any[]) => {
    const initRes = res.reduce((acc, cur) => {
      const day = cur.day
      if (acc[day]) {
        acc[day].push(cur)
      } else {
        acc[day] = [cur]
      }
      return acc
    }, {})

    const arr = WEEKDATAINIT.map(item => {
      const key = item.label
      return {
        day: key,
        label: DAYSMAP[key],
        value: initRes[key] ? initRes[key] : [],
      }
    })
    setHoursInDay(arr)
    setHoursInDayInit(cloneDeep(arr))
  }

  const updateRegularHour = async () => {
    const arr: any[] = []
    hoursInDay.forEach(item1 => {
      item1.value?.forEach(item2 => {
        const obj = { day: item2.day, from: item2.from, to: item2.to }
        arr.push(obj)
      })
    })
    setFormStatus(prev => ({ ...prev, loading: true }))
    try {
      const res = await updateRegularHourApi({ open_hours: arr }, locationId)
      getRegularHours()
      toast.success(res.message)
      setFormStatus(prev => ({ ...prev, error: '', isDirty: false }))
    } catch (e: any) {
      const msg = e?.message || 'request error'
      // toast.error(msg)
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  const onInputChange = (arr: THoursInBusiness[]) => {
    setHoursInDay([...arr])
  }

  return (
    <div>
      {hoursInDay.length ? (
        <WeekHours
          dailyHours={hoursInDay}
          onChange={value => {
            onInputChange(value)
          }}
        ></WeekHours>
      ) : (
        <div className='w-[460px]'>
          <SkeletonItem />
        </div>
      )}
      {formStatus.isDirty && (
        <SaveChangeBar
          onConfirm={() => {
            updateRegularHour()
          }}
          onCancel={() => {
            setFormStatus(prev => ({ ...prev, isDirty: false })),
              setHoursInDay(cloneDeep(hoursInDayInit))
          }}
          error={formStatus.error}
          confirmRequest={formStatus.loading}
        />
      )}
      <UnsavedPrompt when={formStatus.isDirty} />
    </div>
  )
}
