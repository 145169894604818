export const DAYSMAP: any = {
  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
}

export const WEEKDATAINIT = [
  { label: 'sun', value: [] },
  { label: 'mon', value: [] },
  { label: 'tue', value: [] },
  { label: 'wed', value: [] },
  { label: 'thu', value: [] },
  { label: 'fri', value: [] },
  { label: 'sat', value: [] },
]
