/**
 * Assign AccessSetting to the selected User
 * This decides whether the user has access to manage the location
 */
import React, { useState } from 'react'

import Button from 'components/Button'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'

import AccessSettingFormItem from './AccessSettingFormItem'
import { TAccessItem } from './AccessToggle'
import HelpMeDecide from './HelpMeDecide'

export type TAccessSettingFormProp = {
  formData: any
  isInvite?: boolean
  onFormChange: (name: string, value: any) => void
}

export default function AccessSettingForm({
  isInvite,
  formData,
  onFormChange,
}: TAccessSettingFormProp): JSX.Element {
  const [openDecideModal, setOpenDecideModal] = useState(false)

  const onChange = (item: TAccessItem, allowed: boolean) => {
    if (!isInvite) {
      if (formData.role !== 'brand_admin') {
        const new_access_setting: any = cloneDeep(formData.userPermissions)
        let list_index = -1
        let permission_index = -1
        new_access_setting.forEach(
          (childItem: { permissions: TAccessItem[] }, i: number) => {
            childItem.permissions.forEach((v: TAccessItem, j: number) => {
              if (v.name === item.name) {
                list_index = i
                permission_index = j
              }
            })
          },
        )
        if (list_index > -1 && permission_index > -1) {
          new_access_setting[list_index].permissions[permission_index].allowed =
            Number(allowed)
          new_access_setting[list_index].permissions.forEach(
            (childItem: { parent_permission_id: number; allowed: number }) => {
              if (childItem.parent_permission_id === item.id) {
                childItem.allowed = Number(allowed)
              }
            },
          )
        }
        onFormChange('userPermissions', new_access_setting)
      } else {
        toast.warn(
          'As a Brand Admin role, all location(s) will be assigned, and this is a systemic setting that can not be overridden.',
        )
      }
    }
  }

  return (
    <div className='grid gap-4'>
      {formData.role !== 'brand_admin' && !isInvite && (
        <Button
          color='tertiary'
          size='sm'
          className='w-full'
          onClick={e => {
            e.preventDefault()
            setOpenDecideModal(true)
          }}
        >
          HELP ME DECIDE
        </Button>
      )}

      {formData?.userPermissions?.map((permission: any) => {
        return (
          <AccessSettingFormItem
            key={permission.name}
            formData={permission}
            onChange={(accessItem, allowed) => onChange(accessItem, allowed)}
          />
        )
      })}
      <HelpMeDecide
        name={formData.first_name}
        open={openDecideModal}
        toggle={() => setOpenDecideModal(false)}
        onFormChange={onFormChange}
      />
    </div>
  )
}
