/**
 * { key: value }
 * value 需向后端索要
 */
// 路由级别权限控制
export const PERMISSION = {
  STATISTICS: 1,
  ORDERHISTORY: 2,
  // Order History Capability to issue refund
  ISSUEREFUND: 3,
  ONLINEMENU: 4,
  ORDERSETTING: 5,
  BUSINESSHOURS: 7,
  BUSINESSSETTING: 8,
  // RECRUITING 与 BUSINESSSETTING 同级
  RECRUITING: 8,
  // Capability to update the sending email address for marketing
  UPDATEEMAILADDRESS: 9,
  // Capability to update the phone number for marketing
  UPDATEPHONENUMBER: 10,
  ACCOUNTANDPAYOUT: 11,
  // Capability to update business entity
  UPDATEBUSINESSENTITY: 12,
  // Capability to update bank account
  UPDATEBANKACCOUNT: 13,
  COUPONCODE: 14,
  MARKETINGFLYER: 15,
  OUTBOUNDCAMPAIGN: 16,
  // Customer Phone Attendant
  PHONEATTENDANT: 17,
  // Customer Review Generator
  REVIEWGENERATOR: 18,
  // Loyalty Program
  LOYALTYPROGRAM: 19,
  CUSTOMERDATA: 20,
  // Capability to export customer data
  EXPORTCUSTOMERDATA: 21,
  // Capability to invite new user
  INVITENEWUSER: 22,
  // Capability to update any user’s notification setting
  UPDATEUSERNOTISETTING: 23,
  // Capability to update any user’s access setting
  UPDATEUSERACCESSSETTING: 24,
  BILLINGANDINVOICE: 25,
  // Capability to update billing setting
  UPDATEBILLINGSETTING: 26,
  // Capability to update payout schedule
  UPDATEPAYOUTSCHEDULE: 27,
}
