export default function downloadArrayBuffer(data: BlobPart, fileName: string) {
  const localHref = window.URL.createObjectURL(
    new Blob([data], { type: 'application/ynd.ms-excel;charset=UTF-8' }),
  )

  const ele = document.createElement('a')

  ele.href = localHref

  ele.download = `${fileName}.${'xls'}`

  document.querySelectorAll('body')[0].appendChild(ele)

  ele.click()

  setTimeout(() => {
    ele.remove()
  }, 1000)
}
