import React from 'react'

type CircleProcess = {
  percent: number
  size: number
  strokeWidth: number
  strokeColor: string
  trailColor?: string
  text?: string
  textClassName?: string
}

export default function CircleProcess({
  percent = 0,
  size,
  strokeWidth,
  strokeColor,
  trailColor,
  text,
  textClassName,
}: CircleProcess): JSX.Element {
  let leftPercent = 0
  let rightPercent = 0
  if (percent === 100) {
    leftPercent = 100
    rightPercent = 100
  } else if (percent > 50) {
    leftPercent = 100
    rightPercent = (percent % 50) * 2
  } else {
    leftPercent = percent * 2
  }
  return (
    <div
      className='relative flex justify-center items-center'
      style={{
        width: size,
        height: size,
      }}
    >
      <div
        className='absolute top-0 right-0 overflow-hidden'
        style={{
          width: size / 2,
          height: size,
        }}
      >
        <div
          className='absolute top-0 right-0'
          style={{
            width: size,
            height: size,
            border: `${strokeWidth}px solid ${
              trailColor ? trailColor : 'transparent'
            }`,
            borderRadius: '50%',
            transform: `rotate(-${rightPercent * 1.8 + 135}deg)`,
            borderTop: `${strokeWidth}px solid ${strokeColor}`,
            borderRight: `${strokeWidth}px solid ${strokeColor}`,
          }}
        ></div>
      </div>
      <div
        className='absolute top-0 left-0 overflow-hidden'
        style={{
          width: size / 2,
          height: size,
        }}
      >
        <div
          className='absolute top-0 left-0'
          style={{
            width: size,
            height: size,
            borderRadius: '50%',
            transform: `rotate(-${leftPercent * 1.8 + 135}deg)`,
            borderTop: `${strokeWidth}px solid ${
              trailColor ? trailColor : 'transparent'
            }`,
            borderRight: `${strokeWidth}px solid ${
              trailColor ? trailColor : 'transparent'
            }`,
            borderBottom: `${strokeWidth}px solid ${strokeColor}`,
            borderLeft: `${strokeWidth}px solid ${strokeColor}`,
          }}
        ></div>
      </div>
      <div className={textClassName}>{text}</div>
    </div>
  )
}
