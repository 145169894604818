import { ArrowRight } from '@rushable/icons'
import { NavLink } from 'components/NavItem'
import { MYPROFILE, TERMSOFSERVICE, PRIVACYPOLICY } from 'data/routes'

export type TLeftNavBottomProp = {
  mode: string
  isLoading: boolean
  routeToPath: (v: string) => void
}

export default function LeftNavBottom({
  isLoading,
  routeToPath,
}: TLeftNavBottomProp): JSX.Element {
  return (
    <div className='absolute w-full bottom-0 left-0 px-6 pb-6 pt-1 bg-darkblue'>
      {!isLoading && (
        <div className='account-setting' onClick={() => routeToPath(MYPROFILE)}>
          <div className='text-white text-xs font-bold'>
            <span>Account Setting</span>
            <ArrowRight size={12} />
          </div>
        </div>
      )}
      <div className='flex justify-center mt-4'>
        <NavLink
          color='white'
          size='xs'
          text='Terms of Use'
          to={TERMSOFSERVICE}
        />
        <span className='text-xs text-light-64 px-1'>&</span>
        <NavLink
          color='white'
          size='xs'
          text='Privacy Policy'
          to={PRIVACYPOLICY}
        />
      </div>
    </div>
  )
}
