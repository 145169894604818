import { useEffect } from 'react'

import usePrevious from './usePrevious'

const useUnmount = (fn: () => void) => {
  const fnRef = usePrevious(fn)

  useEffect(
    () => () => {
      fnRef && fnRef()
    },
    [],
  )
}

export default useUnmount
