import { SolidGiftCard } from '@rushable/icons'
import Button from 'components/Button'
import Switch from 'components/Switch'
import Table from 'components/TableAndCell/Table'
import Badge from 'components/Tag/Badge'
import { SERVER_TIMEZONE } from 'data/constant'
import { useAppSelector } from 'redux/hooks'
import { currencyFormatter } from 'utils/digit'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

import GiftCardSkeleton from './GiftCardSkeleton'
import DisplayBlock from '../../billingAndInvoice/components/DisplayBlock'

interface TGiftCardListProps {
  stripeInfo: any
  data: any
  total: number
  page: number
  pageSize: number
  loading: boolean
  updating: boolean
  accounting: boolean
  changing: boolean
  updateStripeAccount: () => void
  changeSelling: (e: boolean) => void
  clickedRow: (row: any) => void
  onChange: (name: string, value: any) => void
}

export default function GiftCardList({
  stripeInfo,
  data,
  total,
  page,
  pageSize,
  loading,
  updating,
  accounting,
  changing,
  updateStripeAccount,
  changeSelling,
  clickedRow,
  onChange,
}: TGiftCardListProps): JSX.Element {
  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE

  const columns = [
    {
      key: 'card_number',
      name: 'CARD NUMBER',
      className: 'w-[136px]',
      custom: (value: string) => {
        return (
          <div className='flex items-center'>
            <SolidGiftCard className='mr-1 text-silver' size={16} />
            •••• {value.split('-').pop()}
          </div>
        )
      },
    },
    {
      key: 'id',
      name: 'ISSUE ID',
      className: 'w-120px',
    },
    {
      key: 'balance_amount',
      name: 'REMAINING',
      className: 'w-120px',
      align: 'right',
      custom: (value: any) => {
        return <span>{`${currencyFormatter(Number(value))}`}</span>
      },
    },
    {
      key: 'recipient',
      name: 'RECIPIENT',
    },
    {
      key: 'last_use',
      name: 'LAST USE',
      align: 'right',
      custom: (value: any) => {
        return (
          <span>
            {value
              ? parseServerTimeToLocalTime(value, timezone, 'MM/DD/YYYY')
              : '-'}
          </span>
        )
      },
    },
    {
      key: 'purchaser',
      name: 'PURCHASER',
      tdClassName: 'text-lead',
    },
    {
      key: 'created_at',
      name: 'ACTIVATION',
      align: 'right',
      tdClassName: 'text-lead',
      custom: (value: string) => {
        return (
          <span>
            {parseServerTimeToLocalTime(value, timezone, 'MM/DD/YYYY')}
          </span>
        )
      },
    },
  ]

  return (
    <>
      <div className='flex mb-6 space-x-8'>
        {accounting ? (
          <GiftCardSkeleton />
        ) : (
          <DisplayBlock
            title='Total Remaining'
            iconType='BalanceIcon'
            mainTxt={currencyFormatter(stripeInfo.totalRemaining || 0)}
            descTxt='This is the total amount from unused gift cards'
            button={
              <div className='h-[33px] flex items-center'>
                <Switch
                  name='gift card'
                  label={`Gift cards selling ${
                    stripeInfo.gift_card_selling ? 'activated' : 'stop'
                  }`}
                  loading={changing}
                  checked={!!stripeInfo.gift_card_selling}
                  onChange={e => {
                    changeSelling(e)
                  }}
                />
              </div>
            }
          />
        )}
        {accounting ? (
          <GiftCardSkeleton />
        ) : (
          <DisplayBlock
            title='Stripe Account'
            iconType='ComputerIcon'
            mainTxt={stripeInfo.email || '-'}
            secondaryTxt={
              <>
                {stripeInfo.status === 'connected' && (
                  <Badge className='ml-1' color='green'>
                    {'Active'}
                  </Badge>
                )}
              </>
            }
            descTxt='This is your Stripe account to receive your gift cards selling funds'
            button={
              <div className='flex space-x-4'>
                <Button
                  size='sm'
                  color='primary'
                  onClick={() => {
                    window.open('https://dashboard.stripe.com', '_self')
                  }}
                >
                  ACCESS STRIPE
                </Button>
                <Button
                  loading={updating}
                  size='sm'
                  color='secondary'
                  onClick={updateStripeAccount}
                >
                  UPDATE INFORMATION
                </Button>
              </div>
            }
          />
        )}
      </div>
      <Table
        loading={loading}
        isPageSet={true}
        current={page}
        containerClassName='text-sm text-ink rounded-lg border border-solid border-zinc overflow-hidden'
        columns={columns}
        data={data}
        total={total}
        resizePageSize={pageSize}
        totalText={'results'}
        clickedRow={row => clickedRow(row)}
        onPageNoChange={value => onChange('page', value)}
        onPageSizeChange={value => {
          onChange('pageSize', value)
        }}
      />
    </>
  )
}
