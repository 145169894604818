import { useState, useEffect } from 'react'

import { AngleLeft } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import Field from 'components/Field'
import { Spin } from 'components/Loading'
import { Select } from 'components/Select'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  createCoupon,
  editCoupon,
  deleteCoupon,
  getCouponData,
} from 'redux/coupon'
import { useAppDispatch } from 'redux/hooks'
import type { TCouponForm, TCouponFreeItem } from 'types/coupon'

import CouponTypesField from './components/CouponTypesField'
import SelectDateField from './components/SelectDateField'
import UsageTypeField from './components/UsageTypeField'
import {
  ORDER_TYPE_OPTIONS,
  AUTO_DISPLAY_OPTIONS,
  ELIGIBILITY_OPTIONS,
} from './helpers/constant'

const initFormData = {
  description: '',
  first_order_only: null, // 0 or 1
  auto_display: null, // 0 or 1
  code: '',
  order_types: ['pickup', 'delivery'], // pickup、delivery、dine_in （多选）
  type: '', // flat、percentage、free_item
  amount_off: '',
  percentage_off: '',
  threshold: '',
  menu_item_id: null,
  menu_item: null,
  discount_coverage_type: '', // min_price_amount、full_amount
  usage_type: 'unlimited', // unlimited、limited
  usage_limit: '',
  end_date: null,
  start_date: '',
  end_date_select: 0, // 0 or 1
  brand_id: '',
  cheapest_price: null,
}

export default function CouponCodeDetail() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { couponId, locationId, brandId } = useParams<TParamTypes>()
  const isCreate = couponId === 'create'
  const [requestType, setRequestType] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [formData, setFormData] = useState<TCouponForm>({
    ...initFormData,
  })
  const [prevEndDate, setPrevEndDate] = useState<string | null>(null)
  const [couponStatus, setCouponStatus] = useState('')
  const [cancelledItemName, setCancelledItemName] = useState('')

  const onFormDataChange = (type: string, value: any) => {
    if (type === 'auto_display') {
      setFormData({
        ...formData,
        auto_display: value ? 1 : 0,
      })
      return
    }
    if (type === 'menu_item_id') {
      const { item, value: menuItemId } = value as {
        item: TCouponFreeItem
        value: number
      }
      setFormData({
        ...formData,
        menu_item_id: menuItemId,
        menu_item: item,
      })
      return
    }
    setFormData({
      ...formData,
      [type]: value,
    })
  }

  const handleCreateCoupon = async () => {
    try {
      setRequestType('create')
      const data = await dispatch(
        createCoupon({
          locationId: locationId!,
          params: {
            ...formData,
            brand_id: brandId!,
            end_date: formData.end_date_select === 0 ? null : formData.end_date,
          },
        }),
      )
      toast.success(data.message)
      goBack()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setRequestType('')
    }
  }

  const handleEditCoupon = async () => {
    setRequestType('edit')
    try {
      const data = await dispatch(
        editCoupon({
          params: {
            ...formData,
            end_date: formData.end_date_select === 0 ? null : formData.end_date,
          },
          id: couponId,
          locationId: locationId!,
        }),
      )
      toast.success(data.message)
      goBack()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setRequestType('')
    }
  }

  const handleDeleteCoupon = async () => {
    try {
      setRequestType('delete')
      const data = await dispatch(
        deleteCoupon({
          id: couponId,
          locationId: locationId!,
        }),
      )
      toast.success(data.message)
      goBack()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setRequestType('')
    }
  }

  const handleGetCoupon = async () => {
    try {
      setRequestType('get')
      const data = await dispatch(
        getCouponData({
          id: couponId,
          locationId: locationId!,
        }),
      )
      if (+data.id === +couponId) {
        setFormData({
          order_types: data.coupon_apply_types.map(
            (type: { order_type: string }) => type.order_type,
          ),
          first_order_only: data.first_order_only,
          code: data.code,
          usage_type: data.usage_type,
          auto_display: data.auto_display,
          start_date: data.start_date,
          end_date: data.end_date,
          end_date_select: data.end_date ? 1 : 0,
          type: data.type,
          threshold: data.threshold,
          description: data.description,
          brand_id: '',
          usage_limit: data.usage_limit,
          amount_off: data.amount_off,
          percentage_off: data.percentage_off,
          menu_item_id: data.menu_item?.id || data.menu_item_id || null,
          menu_item: data.menu_item,
          discount_coverage_type: data.discount_coverage_type,
          cheapest_price: data.menu_item?.cheapest_price || null,
        })
        setDisabled(
          (data?.usage > 0 || data.status === 'cancelled') && !!couponId,
        )
        setPrevEndDate(data.end_date)
        setCouponStatus(data?.status || '')
        if (
          data?.status === 'cancelled' &&
          data?.type === 'free_item' &&
          data?.free_item_name
        ) {
          setCancelledItemName(data.free_item_name)
        }
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setRequestType('')
    }
  }

  const goBack = () => {
    history.push(`/brand/${brandId}/location/${locationId}/coupon-code`)
  }

  const handleGetGenerate = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let randomString = ''

    for (let i = 0; i < 6; i++) {
      // 生成一个随机索引，范围在 0 到 characters.length - 1 之间
      const randomIndex = Math.floor(Math.random() * characters.length)
      // 根据随机索引获取字符并添加到随机字符串中
      randomString += characters[randomIndex]
    }
    onFormDataChange('code', randomString)
  }

  const btnsRender = () => {
    if (couponStatus === 'cancelled') {
      return null
    }
    return isCreate ? (
      <>
        <Button
          color='primary'
          className='w-full !mt-10'
          type='submit'
          onClick={handleCreateCoupon}
          loading={requestType === 'create'}
        >
          CREATE COUPON
        </Button>
        <Button
          color='secondary'
          className='w-full !mt-4'
          onClick={goBack}
          type='button'
        >
          CANCEL
        </Button>
      </>
    ) : (
      <>
        <Button
          color='primary'
          className='w-full !mt-10'
          type='submit'
          onClick={handleEditCoupon}
          loading={requestType === 'edit'}
        >
          UPDATE COUPON
        </Button>
        <Button
          color='warning'
          className='w-full !mt-4'
          onClick={handleDeleteCoupon}
          loading={requestType === 'delete'}
          type='submit'
        >
          DEACTIVATE COUPON
        </Button>
      </>
    )
  }

  useEffect(() => {
    if (couponId && !isCreate) {
      handleGetCoupon()
    }
    if (isCreate) {
      setFormData({
        ...initFormData,
        // location_ids: [locationId!],
      })
    }
  }, [])

  return (
    <AdminPage>
      <AdminPageTitle
        title={`${isCreate ? 'Create' : 'Edit'} Coupon`}
        left={
          <Button color='secondary-link' onClick={goBack}>
            <AngleLeft className='mr-1' size={16} />
            GO BACK
          </Button>
        }
      />
      <Spin
        spining={requestType === 'get'}
        top
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='flex justify-center'>
          <div className='w-560px mt-8 mb-20 space-y-6'>
            <Field
              label='Internal name'
              name='internal name'
              type='text'
              placeholder='Input'
              value={formData.description}
              onChange={e => {
                const value = e.target.value
                if (value.length <= 30) {
                  onFormDataChange('description', value)
                }
              }}
              disabled={disabled}
            />
            <Select
              label='Eligibility'
              options={ELIGIBILITY_OPTIONS}
              placeholder='Select'
              value={formData.first_order_only}
              onChange={value => onFormDataChange('first_order_only', value)}
              disabled={disabled}
            />
            <Select
              label='Redemption method'
              options={AUTO_DISPLAY_OPTIONS}
              placeholder='Select'
              value={formData.auto_display}
              onChange={value => onFormDataChange('auto_display', value)}
              disabled={disabled}
            />
            {formData.auto_display === 0 && (
              <div className='w-full flex items-end'>
                <Field
                  containerClassName='flex-1'
                  label='Coupon code'
                  name='Coupon code'
                  type='text'
                  placeholder='Input'
                  value={formData.code}
                  onChange={e => {
                    const value = e.target.value
                    if (value.length <= 12) {
                      onFormDataChange(
                        'code',
                        value.replace(/[^0-9a-zA-Z]/g, ''),
                      )
                    }
                  }}
                  disabled={disabled}
                />
                {!disabled && (
                  <Button
                    color='tertiary'
                    className='h-12.5 ml-4'
                    onClick={handleGetGenerate}
                  >
                    Generate
                  </Button>
                )}
              </div>
            )}
            <Select
              label='Allowed order type'
              options={ORDER_TYPE_OPTIONS}
              placeholder='Select'
              value={formData.order_types}
              isMulti
              isMultiShowAllValue
              onChange={value => onFormDataChange('order_types', value)}
              disabled={disabled}
            />
            <CouponTypesField
              disabled={disabled}
              formData={formData}
              cancelledItemName={cancelledItemName}
              onFormDataChange={onFormDataChange}
            />
            <UsageTypeField
              disabled={disabled}
              formData={formData}
              onFormDataChange={onFormDataChange}
            />

            <SelectDateField
              disabled={disabled}
              formData={formData}
              prevEndDate={prevEndDate}
              couponStatus={couponStatus}
              onFormDataChange={onFormDataChange}
            />
            {btnsRender()}
          </div>
        </div>
      </Spin>
    </AdminPage>
  )
}
