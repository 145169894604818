import React, { useState, useEffect } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { externalHomePageApi } from 'redux/brand/actions'

export default function ExternalHomepage(): JSX.Element {
  const { brandId } = useParams<TParamTypes>()

  const [homepage, setHomepage] = useState('')
  const [formStatus, setFormStatus] = useState<Record<string, any>>({
    homepageError: '',
    saveLoading: false,
  })

  const brand = useSelector((state: any) => state.brand?.selectedBrand) || {}

  useEffect(() => {
    setHomepage(brand.external_homepage || '')
  }, [brand.external_homepage])

  const externalHomePage = async () => {
    setFormStatus(prev => ({ ...prev, saveLoading: true }))
    try {
      const res = await externalHomePageApi({
        brand_id: brandId,
        external_homepage: homepage || '',
      })
      toast.success(res?.message)
    } catch (e: any) {
      setFormStatus(prev => ({ ...prev, homepageError: e.message }))
    }
    setFormStatus(prev => ({ ...prev, saveLoading: false }))
  }

  return (
    <div className='mt-8'>
      <h3 className='pt-8 py-4 border-t border-dashed border-zinc font-bold text-base text-ink'>
        Keep Existing Website
      </h3>
      <p className='text-lead text-base leading-6 mb-4'>
        Input your existing website address if you’d like to keep it while use
        Rushable for online ordering only, this allows customers to jump back to
        the designated website when needed.
      </p>
      <Field
        name='url'
        type='url'
        error={formStatus.homepageError}
        value={homepage}
        onChange={e => {
          setHomepage(e.target.value)
          setFormStatus(prev => ({ ...prev, homepageError: '' }))
        }}
        label='Your current website homepage url'
        placeholder='e.g.https://yourwbesite.com'
      />
      <Button
        className='w-40 mt-4'
        disabled={formStatus.saveLoading}
        loading={formStatus.saveLoading}
        onClick={() => externalHomePage()}
      >
        SAVE
      </Button>
      <h3 className='pb-8 mt-8 border-t border-dashed border-zinc '></h3>
    </div>
  )
}
