import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import BillingSetting from './BillingSetting'
import InvoiceHistory from './InvoiceHistory'

export default function ProfilePage(): JSX.Element {
  const history = useHistory()
  const { path, url } = useRouteMatch()
  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})
  return (
    <AdminPage>
      <Switch>
        <Route path={path}>
          <AdminPageTabs>
            <AdminPageTab
              text='Billing Setting'
              active={activePath === 'billing'}
              onClick={() => switchTab('billing')}
            />
            <AdminPageTab
              text='Invoice History'
              active={activePath === 'invoice'}
              onClick={() => switchTab('invoice')}
            />
          </AdminPageTabs>
          <div className='p-8'>
            <Switch>
              <Route path={`${path}/billing`}>
                <BillingSetting />
              </Route>
              <Route path={`${path}/invoice`}>
                <InvoiceHistory />
              </Route>
              <Redirect from={`${path}`} to={`${path}/billing`} exact />
            </Switch>
          </div>
        </Route>
      </Switch>
    </AdminPage>
  )
}
