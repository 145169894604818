import React, { ReactElement } from 'react'

import { Dialog } from '@headlessui/react'
import Button from 'components/Button'

import Modal from './core'

interface Imodel {
  open: boolean
  toggle: () => void
  title?: string
  footer?: ReactElement | null
  iconFooter?: ReactElement
  body?: ReactElement
  okBtnColor?: TButtonColorType
  okBtnClassName?: string
  okText?: string
  cancelText?: string
  onOk?: () => void
  onCancel?: () => void
  children: ReactElement | ReactElement[]
  loading?: boolean
  hasCancelBtn?: boolean
  error?: string
  okBtnDisabled?: boolean
}

export default function ModalFull({
  open,
  toggle,
  footer,
  iconFooter,
  title,
  okBtnColor = 'primary',
  okText = 'CONFIRM',
  cancelText = 'CANCEL',
  okBtnClassName,
  onOk = toggle,
  onCancel = toggle,
  children,
  loading,
  hasCancelBtn = true,
  error,
  okBtnDisabled = false,
}: Imodel) {
  // footer btn at right
  const Footer = () => {
    return (
      <div className='flex justify-between items-center'>
        <div className=''>{iconFooter}</div>
        <div className='flex'>
          {hasCancelBtn && (
            <Button color='secondary' className='mr-4' onClick={onCancel}>
              {cancelText}
            </Button>
          )}
          <Button
            color={okBtnColor}
            onClick={onOk}
            loading={loading}
            disabled={okBtnDisabled}
            className={okBtnClassName}
          >
            {okText}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Modal open={open} toggle={toggle}>
      <>
        <div className='p-6'>
          {title && (
            <Dialog.Title
              as='h3'
              className='text-base font-bold text-ink text-center'
            >
              {title}
            </Dialog.Title>
          )}
        </div>
        <div className='px-8 pb-8'>{children}</div>
        {error && (
          <div className='text-red text-base text-center px-8 py-4 border-t border-zinc'>
            {error}
          </div>
        )}

        {footer !== null && (
          <div className='px-8 py-4 border-t border-zinc'>
            {footer ? footer : Footer()}
          </div>
        )}
      </>
    </Modal>
  )
}
