import React, { useState } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router-dom'

import AutomatedCampaignPage from './AutomatedCampaignPage'
import FormCampaignPage from './FormCampaignPage'
import { outboundCampaignRoutes } from './helpers/constant'
import type { TAddOpenDataState } from './helpers/types'
import OneOffCampaignPage from './OneOffCampaignPage'

export default function OutboundCampaign(): JSX.Element {
  const history = useHistory()
  const { path, url } = useRouteMatch()

  const [addOpenData, setAddOpenData] = useState<TAddOpenDataState>({
    step: 0,
    type: '',
    typeName: '',
  })

  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }
  const activePath = useActivePageTab({})
  const { pathname } = useLocation()
  return (
    <AdminPage className=''>
      {pathname.includes('campaign-detail') ? (
        <Switch>
          <Route path={`${path}/campaign-detail/:id`}>
            <FormCampaignPage
              addOpenData={addOpenData}
              setAddOpenData={setAddOpenData}
            />
          </Route>
        </Switch>
      ) : (
        <>
          <AdminPageTabs>
            <>
              {outboundCampaignRoutes.map(v => (
                <AdminPageTab
                  key={v.name}
                  text={v.label}
                  active={activePath === v.path}
                  onClick={() => switchTab(v.path)}
                />
              ))}
            </>
          </AdminPageTabs>
          <div className='pt-0 pb-8 px-8'>
            <Switch>
              <Route path={`${path}/one-off-campaign`}>
                <OneOffCampaignPage setAddOpenData={setAddOpenData} />
              </Route>
              <Route path={`${path}/automated-campaign`}>
                <AutomatedCampaignPage setAddOpenData={setAddOpenData} />
              </Route>
              <Redirect
                from={`${path}`}
                to={`${path}/one-off-campaign`}
                exact
              />
            </Switch>
          </div>
        </>
      )}
    </AdminPage>
  )
}
