import { useState, useEffect } from 'react'

import ModalFull from 'components/Modal/ModalFull'
import { useParams, useHistory } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { getNotificationApi, setFirstShowNotification } from 'redux/location'

export default function NotificationModal() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { locationId, brandId } = useParams<TParamTypes>()
  const { firstOpenNotificationLocationId } = useAppSelector(
    state => state.location,
  )
  const [open, setOpen] = useState(false)
  const [notificationInfo, setNotificationInfo] = useState({
    ctaTitle: '',
    ctaUrl: '',
    message: '',
    title: '',
  })
  useEffect(() => {
    // 保存已显示 modal 的 location id，切换 location 时，可以再次显示
    if (locationId && locationId !== firstOpenNotificationLocationId) {
      dispatch(setFirstShowNotification(locationId))
      getNotification()
    }
  }, [locationId])
  const getNotification = async () => {
    try {
      const res = await getNotificationApi(locationId)
      if (res?.notification) {
        setOpen(true)
        setNotificationInfo({
          ctaTitle: res?.notification?.cta_title || '',
          ctaUrl: res?.notification?.cta_url || '',
          message: res?.notification?.message || '',
          title: res?.notification?.title || '',
        })
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const handleClose = () => setOpen(!open)
  return (
    <ModalFull
      title={notificationInfo.title}
      open={open}
      toggle={handleClose}
      onOk={() => {
        history.push(
          `/brand/${brandId}/location/${locationId}/${notificationInfo.ctaUrl}`,
        )
        handleClose()
      }}
      onCancel={handleClose}
      cancelText={'CANCEL'}
      okText={notificationInfo.ctaTitle}
    >
      <div className='text-ink text-center'>{notificationInfo.message}</div>
    </ModalFull>
  )
}
