import React, { useState } from 'react'

import { LinedCalendar } from '@rushable/icons'
import Button from 'components/Button'
import DateRangeModal from 'components/DateRangeModal'
import Field from 'components/Field'
import { Spin } from 'components/Loading'
import { Select } from 'components/Select'
import moment from 'moment'
import { useRouteMatch, useHistory } from 'react-router-dom'

import MonthlyReportCard from './MonthlyReportCard'
import { ACCOUNTING_REPORT_OPTIONS } from '../helpers/constant'

type TAccountingReportListProp = {
  list?: any
  loading: boolean
  type: string
  handleMore?: () => void
  setType: (v: string) => void
}

export default function AccountingReportList({
  list,
  loading,
  type,
  handleMore,
  setType,
}: TAccountingReportListProp): JSX.Element {
  const [dateOpen, setDateOpen] = useState(false)
  const history = useHistory()
  const { url } = useRouteMatch()
  const [range, setRange] = useState<{
    startDate: Date | null
    endDate: Date | null
    key: string
  }>({ startDate: null, endDate: null, key: 'selection' })

  const getCustomDateLabel = () => {
    return range && range.startDate && range.endDate
      ? moment(range.startDate).format('MM/DD/YYYY') +
          ' to ' +
          moment(range.endDate).format('MM/DD/YYYY')
      : ''
  }

  const renderPicker = (e: any) => {
    e.preventDefault()
    toggleModal()
  }

  const toggleModal = () => {
    setDateOpen(!dateOpen)
  }

  return (
    <Spin
      spining={!list.length ? loading : false}
      styles={{
        minHeight: 'calc(100vh - 144px)',
      }}
    >
      <div>
        <div className='flex justify-center space-x-4'>
          <Select
            className='w-[280px]'
            options={ACCOUNTING_REPORT_OPTIONS}
            placeholder='Select'
            value={type}
            onChange={v => setType(v)}
          />
          {type === 'custom' && (
            <>
              <Field
                containerClassName='w-[280px]'
                append={<LinedCalendar size={16} color={'#B0B8BE'} />}
                name='date-range'
                type='tel'
                value={getCustomDateLabel()}
                onClick={e => renderPicker(e)}
                onChange={() => null}
              />
              <Button
                className='w-40'
                color='primary'
                disabled={!range.startDate}
                onClick={() => {
                  range
                    ? history.push({
                        pathname: `${url}/${moment(range.startDate).format(
                          'YYYYMMDD',
                        )}-${moment(range.endDate).format('YYYYMMDD')}`,
                      })
                    : null
                }}
              >
                Show Report
              </Button>
            </>
          )}
        </div>
        <DateRangeModal
          open={dateOpen}
          toggle={toggleModal}
          onChange={e => {
            setRange(e.selection)
          }}
          ranges={[range]}
        />

        {type === 'monthly' && (
          <>
            <div className='mt-8 flex flex-col space-y-8'>
              {list.map((item: any, i: number) => (
                <MonthlyReportCard item={item} key={i} url={url} />
              ))}
            </div>
            {handleMore && (
              <Button
                loading={loading}
                className='min-w-40 mt-8 mx-auto'
                color='tertiary'
                onClick={() => handleMore()}
              >
                Show more
              </Button>
            )}
          </>
        )}
      </div>
    </Spin>
  )
}
