import React from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import Regular from './Regular'
import Special from './Special'

export default function BusinessHours(): JSX.Element {
  const history = useHistory()
  const { path, url } = useRouteMatch()

  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})

  return (
    <AdminPage>
      <>
        <AdminPageTabs>
          <AdminPageTab
            text='Regular Hours'
            active={activePath === 'regular'}
            onClick={() => switchTab('regular')}
          />
          <AdminPageTab
            text='Special Hours'
            active={activePath === 'special'}
            onClick={() => switchTab('special')}
          />
        </AdminPageTabs>
        <Switch>
          <Route path={`${path}/regular`}>
            <div className='py-16 flex justify-center'>
              <Regular></Regular>
            </div>
          </Route>
          <Route path={`${path}/special`}>
            <Special></Special>
          </Route>
          <Redirect from={`${path}`} to={`${path}/regular`} exact />
        </Switch>
      </>
    </AdminPage>
  )
}
