// import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { TPayoutSchedule } from 'pages/billingAndInvoice/helpers'
import axios from 'utils/axiosApi'

/**===================================  Payout  ==================================== */
interface IpayoutList {
  page: number
  per_page: number
}
export async function getPayoutListApi(
  params: IpayoutList,
  locationId: string,
  refresh: boolean,
) {
  const response = await axios.request({
    url: `locations/${locationId}/payouts`,
    method: 'get',
    params: params,
    cache: {
      ignoreCache: refresh,
    },
  })
  return response.data
}

// export async function getPayoutSettingApi(locationId: string, refresh = false) {
//   const response = await axios.request({
//     url: `locations/${locationId}/payout`,
//     method: 'get',
//     cache: {
//       ignoreCache: refresh,
//     },
//   })
//   return response.data
// }

export async function getPayoutSummaryApi(
  locationId: string,
  payoutId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/payouts/${payoutId}`,
    method: 'get',
  })
  return response.data
}

interface Itransactions {
  payout_id: string | null
  page: number
  per_page: number
  begin_time?: string | null
  end_time?: string | null
}
export async function getTransactionsListApi(
  params: Itransactions,
  locationId: string,
  refresh: boolean,
) {
  const response = await axios.request({
    url: `locations/${locationId}/transactions`,
    method: 'get',
    params: params,
    cache: {
      ignoreCache: refresh,
    },
  })
  return response.data
}

interface Ischedule {
  interval: TPayoutSchedule
}
export async function updateScheduleApi(
  params: Ischedule,
  payoutConfigId: string,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/location_payout_configs/${payoutConfigId}`,
    method: 'post',
    data: params,
  })
  return response.data
}

export async function getPayoutConfigApi(
  locationId: string,
  payoutConfigId: string,
  refresh = false,
) {
  const response = await axios.request({
    url: `locations/${locationId}/location_payout_configs/${payoutConfigId}`,
    method: 'get',
    cache: {
      ignoreCache: refresh,
    },
  })
  return response.data
}

export type TDownloadApi = {
  payout_id: string | null
  begin_time: string | null
  end_time: string | null
}
export async function downloadApi(params: TDownloadApi, locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/transaction/download`,
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    responseType: 'arraybuffer', //必须设置，不然导出的文件无法打开
  })
  return response.data
}
/**===================================  Payout  ==================================== */
/**===================================  Account  ==================================== */
export async function getRushalePlanApi() {
  const response = await axios.request({
    url: 'rushable_plans',
    method: 'get',
    params: {
      type: 'rushable_subscription',
      // unit: 'month',
    },
  })
  return response.data
}

export async function updateBasePlanApi(
  plan_id: string | null,
  name: string | null,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/base_plan`,
    method: 'post',
    data: { price_id: plan_id, name },
  })
  return response.data
}

export async function keepBasePlanApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/base_plan`,
    method: 'patch',
  })
  return response.data
}

interface IToken {
  recharge_when_below: string | number
  recharge_amount: string | number
}
export async function updateRushableTokenApi(
  params: IToken,
  locationId: string,
  id: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/rushable_tokens/${id}`,
    method: 'patch',
    data: params,
  })
  return response.data
}

interface IBankAccount {
  account_holder_name: string // first name + last name
  account_holder_type: string // in:individual,company
  routing_number: string
  account_number: string
}
export async function updateBankAccountApi(
  params: IBankAccount,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/bank_account`,
    method: 'patch',
    data: params,
  })
  return response.data
}

interface ICancel {
  reason: string
  notes: string
}
export async function cancelSubjectApi(params: ICancel, locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}`,
    method: 'delete',
    params,
  })
  return response.data
}

export async function getSubscriptionsApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/subscriptions`,
    method: 'get',
  })
  return response.data
}

interface IinvoiceListParam {
  status: string | undefined
  type: string | undefined
  limit: number
  starting_after?: string | undefined
  ending_before?: string | undefined
  start_date?: number | undefined
  end_date?: number | undefined
}
export async function getInvoiceListApi(
  params: IinvoiceListParam,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/invoices`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params: {
      status: params.status,
      subscription_id: params.type,
      limit: params.limit,
      starting_after: params.starting_after,
      ending_before: params.ending_before,
      start_date: params.start_date,
      end_date: params.end_date,
    },
  })
  return response.data
}

export async function addCreditCardApi(id: string, locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/credit_card`,
    method: 'patch',
    data: { payment_method_id: id },
  })
  return response.data
}

export async function getBillingAccountApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/billing_account/update`,
    method: 'post',
  })
  return response.data
}

export async function cancelBillingAccountApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/billing_account/cancel`,
    method: 'post',
  })
  return response.data
}
/**===================================  Account  ==================================== */
