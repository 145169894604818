import React from 'react'

import { MarkI, Check } from '@rushable/icons'
import cn from 'classnames'

import Badge from '../Tag/Badge'

type TOptionBoxProps = {
  className?: string
  selected?: boolean
  disabled?: boolean
  title: string
  description: string
  badge?: string
  extra?: string
  onSelect?: () => void
}

export default function OptionBox({
  className,
  selected = false,
  disabled = false,
  title,
  description,
  badge,
  extra,
  onSelect,
}: TOptionBoxProps) {
  const type = disabled ? 'disabled' : selected ? 'selected' : 'default'
  return (
    <div
      className={cn(
        className,
        'w-full px-6 py-4 border-2 rounded-lg relative cursor-pointer',
        type === 'disabled' ? 'border-zinc opacity-24 cursor-no-drop' : null,
        type === 'selected' ? 'border-blue' : null,
        type === 'default' ? 'border-zinc hover:border-blue' : null,
      )}
      onClick={onSelect}
    >
      <div className='pb-1 font-bold text-dark-100 text-base'>
        {title}
        {badge ? (
          <Badge className='ml-2' color='gray'>
            {badge}
          </Badge>
        ) : null}
      </div>
      <div className='text-dark-100 text-sm'>{description}</div>
      {extra ? (
        <div className='mt-2 px-3 py-2 w-full bg-field flex items-center rounded-lg'>
          <div className='w-[14px] h-[14px] mr-2 bg-dark-32 flex justify-center items-center rounded-full'>
            <MarkI size={10} className='text-white' />
          </div>
          <div className='text-xs text-lead'>{extra}</div>
        </div>
      ) : null}
      {type === 'selected' ? (
        <div className='w-5 h-5 absolute top-0 right-0 bg-blue flex justify-center items-center rounded-bl-lg'>
          <Check size={12} className='text-white' />
        </div>
      ) : null}
    </div>
  )
}
