import {
  LinedChefHat,
  LinedServiceBell,
  LinedDrinkGlass,
  LinedUserWithTie,
  LinedSink,
  LinedLaptop,
  LinedUser,
} from '@rushable/icons'

export default function JobCategoryIcon({
  type,
}: {
  type: string
}): JSX.Element {
  let icon = <LinedChefHat />
  switch (type) {
    case 'Cook':
      icon = <LinedChefHat />
      break
    case 'Server':
      icon = <LinedServiceBell />
      break
    case 'Bartender':
      icon = <LinedDrinkGlass />
      break
    case 'Supervisor':
      icon = <LinedUserWithTie />
      break
    case 'Janitorial':
      icon = <LinedSink />
      break
    case 'Office':
      icon = <LinedLaptop />
      break
    default:
      icon = <LinedUser />
      break
  }

  return <icon.type className='text-lead' size={16} />
}
