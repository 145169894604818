import React, { useEffect, useState } from 'react'

export type TCodeInputProp = {
  length: number
  fieldWidth: number
  fontSize?: number
  onChange: (v: string) => void
  className?: string
}

export default function CodeInput({
  length,
  fieldWidth,
  fontSize = 64,
  onChange,
  className,
}: TCodeInputProp): JSX.Element {
  const input = React.createRef<HTMLInputElement>()
  const wrap = React.createRef<HTMLDivElement>()
  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)
  const [width, setWidth] = useState(0)
  const codeLength = new Array(length).fill(0)

  useEffect(() => {
    if (input.current && wrap.current) {
      input.current.focus()
      setWidth(wrap.current.clientWidth)
    }
  }, [])

  const handleClick = () => {
    if (input.current) {
      input.current.focus()
    }
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      const newValue = value.slice(0, value.length - 1)
      setValue(newValue)
      onChange(newValue)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      value.length >= codeLength.length
        ? ''
        : (value + e.target.value).slice(0, codeLength.length)
    setValue(newValue)
    onChange(newValue)
  }

  const values = value.split('')

  const selectedIndex =
    values.length < codeLength.length ? values.length : codeLength.length - 1

  const hideInput = !(values.length < codeLength.length)

  const gap = (width - codeLength.length * fieldWidth) / (codeLength.length - 1)

  return (
    <div
      role='banner'
      className={`relative w-full flex justify-between ${className}`}
      onClick={handleClick}
      ref={wrap}
    >
      <input
        value=''
        type='tel'
        pattern='[0-9]*'
        ref={input}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className='absolute border-0 !outline-none bg-transparent text-center'
        style={{
          width: fieldWidth,
          top: '0px',
          bottom: '0px',
          left: `${selectedIndex * (fieldWidth + gap)}px`,
          opacity: hideInput ? 0 : 1,
          fontSize: fontSize,
        }}
      />
      {codeLength.map((v, index) => {
        const selected = values.length === index
        const filled =
          values.length === codeLength.length && index === codeLength.length - 1
        return (
          <div
            className='relative text-ink flex justify-center items-center border-b-2 border-solid border-zinc cursor-text'
            style={{
              width: fieldWidth,
              height: `calc(${fontSize}px + 8px)`,
              fontSize: fontSize,
            }}
            key={`code-input-${index}`}
          >
            {values[index]}
            {(selected || filled) && focused && (
              <div className='absolute -inset-y-0.5 inset-x-0 border-b-2 border-solid border-blue' />
            )}
          </div>
        )
      })}
    </div>
  )
}
