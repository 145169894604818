import { useEffect, useState, RefObject, useRef } from 'react'

import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { AngleUp, AngleDown, Plus } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import { Spin } from 'components/Loading'
import ModalFull from 'components/Modal/ModalFull'
import OpenHoursForm from 'components/OpenHoursForm'
import { Select } from 'components/Select'
import TextArea from 'components/TextArea'
import throttle from 'lodash/throttle'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  setLockModalOpen,
  createMenuCategoryAPI,
  createMenuCollectionAPI,
  deleteMenuCollectionAPI,
  deleteMenuCategoryAPI,
  editMenuCollectionAPI,
  editMenuCategoryAPI,
  getMenuCollectionAPI,
  getMenuModifierAPI,
  showMenuCategoryAPI,
  showMenuCollectionAPI,
  sortMenuCategoryAPI,
  sortMenuCollectionAPI,
  sortMenuModifierAPI,
} from 'redux/onlineMenu'
import { returnHoursInDays } from 'utils/hours'

import DeleteModifier from './DeleteModifier'
import MenuCollectionItem from './MenuCollectionItem'
import MenuModifierItem from './MenuModifierItem'
import OnlineMenuSkeleton from './OnlineMenuSkeleton'
import { BUSINESS_HOURS_OPTIONS, MENU_LOCKED_STATUS } from '../helpers/constant'

const baseFormData = {
  id: 0,
  name: '',
  description: '',
  availability: 'same_as_store',
  saveLoading: false,
  openHours: null,
  is_locked: 0,
}

export type TLeftNavProp = {
  openMenu: boolean
  updateMenuNav: boolean
  rightItemListDom: RefObject<HTMLDivElement>
  onChangeMenuNav: (e?: boolean) => void
  onChangeMenuItem: (
    menuCollectionList: any,
    id: number,
    isCollection?: boolean,
  ) => void
}

export default function MenuLeftNav({
  openMenu,
  updateMenuNav,
  rightItemListDom,
  onChangeMenuNav,
  onChangeMenuItem,
}: TLeftNavProp): JSX.Element {
  const dispatch = useAppDispatch()
  const { locationId } = useParams<TParamTypes>()
  const { menuItemList, listScrollTop } = useAppSelector(
    state => state.onlineMenu,
  )
  const [deleteValue, setDeleteValue] = useState('')
  const [menuActionName, setMenuActionName] = useState('')
  const [menuActionIndex, setMenuActionIndex] = useState({ i: -1, j: -1 })
  const [menuActionId, setMenuActionId] = useState(0)
  const [menuEdit, setMenuEdit] = useState({
    open: false,
    type: '',
    method: '',
  })
  const [openDelete, setOpenDelete] = useState(false)
  const [menuCollectionList, setMenuCollectionList] = useState<any>([])
  const [menuModifierList, setMenuModifierList] = useState<any>([])
  const [formData, setFormData] = useState<Record<string, any>>(baseFormData)
  const [loading, setLoading] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)
  const [modifierLoading, setModifierLoading] = useState(false)
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })

  useEffect(() => {
    if (menuItemList?.length > 0) {
      setMenuCollectionList(menuItemList)
    }
    getMenuCollection()
    getMenuModifier()
  }, [])

  useEffect(() => {
    if (updateMenuNav) {
      if (openMenu) {
        getMenuCollection()
      } else {
        getMenuModifier()
      }
    }
  }, [updateMenuNav])

  const listScrollTopChange = (
    dom: HTMLDivElement | null,
    list: any,
    listTop: number | undefined,
  ) => {
    if (dom && listTop) {
      let selectedItem: any = null
      list?.forEach((menuList: any) => {
        if (menuList?.menu_categories?.length > 0) {
          menuList?.menu_categories?.forEach((item: any) => {
            const dom = document.querySelector(`#category-item-${item.id}`)
            const top = dom?.getBoundingClientRect()?.top
            if (top && top < 161) {
              selectedItem = item
            }
          })
        }
      })
      if (selectedItem?.id) {
        setFormData({
          ...formData,
          id: selectedItem.id,
        })
      }
    }
  }

  const domScrollThrottle = useRef(
    throttle(listScrollTopChange, 300, { trailing: true }),
  )

  useEffect(() => {
    domScrollThrottle.current(
      rightItemListDom?.current,
      menuItemList,
      listScrollTop,
    )
  }, [listScrollTop, rightItemListDom?.current?.scrollHeight])

  const sensors = useSensors(mouseSensor)

  const onDragEnd = async (props: any, type: string, categoryList?: any) => {
    const { active, over } = props
    if (!active || !over) {
      return null
    }
    const list =
      type === 'Collection'
        ? menuCollectionList
        : type === 'Category'
        ? categoryList
        : menuModifierList
    const activeIndex = list.findIndex((item: any) => item.id === active.id)
    const overIndex = list.findIndex((item: any) => item.id === over.id)
    const modifierList = arrayMove(list, activeIndex, overIndex)
    onChangeMenuNav(true)
    setMenuActionName('')
    const idList: any = []
    modifierList.map((v: any) => {
      idList.push(v.id)
    })
    setLoading(true)
    if (type === 'Modifier') {
      try {
        const params = {
          menu_modifier_ids: idList,
        }
        const res = await sortMenuModifierAPI(params, locationId)
        toast.success(res.message)
        getMenuModifier(false)
      } catch (e: any) {
        onChangeMenuNav(false)
        const msg = e?.message
        toast.error(msg || 'request error')
      }
    } else {
      try {
        if (openMenu) {
          if (type === 'Collection') {
            const params = {
              menu_collection_ids: idList,
            }
            const res = await sortMenuCollectionAPI(params, locationId)
            toast.success(res.message)
            getMenuCollection()
          } else {
            let collectionIndex = 0
            menuCollectionList.map(
              (
                collectionItem: {
                  id: number
                  menu_categories: { id: number }[]
                },
                i: number,
              ) => {
                collectionItem.menu_categories.map(
                  (categoryItem: { id: number }) => {
                    if (categoryItem.id === active.id) {
                      collectionIndex = i
                    }
                  },
                )
              },
            )
            const params = {
              menu_collection_id: menuCollectionList[collectionIndex].id,
              menu_category_ids: idList,
            }
            const res = await sortMenuCategoryAPI(params, locationId)
            toast.success(res.message)
            getMenuCollection()
          }
        }
      } catch (e: any) {
        onChangeMenuNav(false)
        const msg = e?.message
        toast.error(msg || 'request error')
      }
    }
    setLoading(false)
  }

  const getMenuCollection = async () => {
    // list 数据为空时，也就是第一次打开 online menu 才显示 loading
    menuItemList?.length === 0 && setCollectionLoading(true)
    onChangeMenuNav(true)
    try {
      const res = await getMenuCollectionAPI(locationId)
      if (!formData.id && !listScrollTop) {
        onChangeForm('id', res[0]?.menu_categories[0]?.id || 0)
      }
      onChangeMenuItem([...res], res[0]?.menu_categories[0]?.id || 0)
      setMenuCollectionList(() => {
        return [...res]
      })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    } finally {
      setCollectionLoading(false)
      onChangeMenuNav(false)
    }
  }

  const getMenuModifier = async (setFirstModifier = true) => {
    setModifierLoading(true)
    onChangeMenuNav(true)
    try {
      const res = await getMenuModifierAPI(locationId)
      if (!openMenu && setFirstModifier) {
        getMenuDetail(res[0].name || '', res[0].id || 0)
      }

      setMenuModifierList(() => {
        return [...res]
      })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setModifierLoading(false)
    onChangeMenuNav(false)
  }

  const getMenuDetail = (name: string, id: number) => {
    setMenuActionName('')
    onChangeForm('id', id)
    onChangeMenuItem(menuCollectionList, id)
  }

  const handleMenuAction = (
    value: string,
    { name, id }: { name: string; id: number },
    { i, j }: { i: number; j: number },
    type?: string,
  ) => {
    setMenuActionName('')
    switch (value) {
      case 'open':
        menuActionName === name
          ? setMenuActionIndex({ i: -1, j: -1 })
          : setMenuActionIndex({ i, j })
        setMenuActionName(menuActionName === name ? '' : name)
        break
      case 'edit':
        setFormData({ ...baseFormData, id: formData.id })
        setMenuActionId(id)
        setMenuEdit({ open: true, type: type || '', method: 'edit' })
        type && handleShowMenu(type, id)
        break
      case 'delete':
        setMenuActionId(id)
        if (openMenu) {
          setFormData({ ...formData, name })
          setMenuEdit({
            open: true,
            type: type || '',
            method: 'delete',
          })
        } else {
          setOpenDelete(true)
        }
        break
    }
  }

  const handleUpdateMenu = async () => {
    setFormData(prev => ({ ...prev, saveLoading: true }))
    if (
      ['add', 'edit'].includes(menuEdit.method) &&
      menuEdit.type === 'Collection'
    ) {
      try {
        const newFormData = new FormData()

        newFormData.append('name', formData.name)
        newFormData.append('availability', formData.availability)

        if (formData.availability === 'customize') {
          const newOpenHours: any[] = []
          Object.keys(formData.openHours).map(day => {
            newOpenHours.push(...formData.openHours[day])
          })
          newOpenHours.forEach((item, index) => {
            newFormData.append(`open_hours[${index}][day]`, item.day)
            newFormData.append(`open_hours[${index}][from]`, item.from)
            newFormData.append(`open_hours[${index}][to]`, item.to)
          })
        }
        if (menuEdit.method === 'add') {
          const res = await createMenuCollectionAPI(newFormData, locationId)
          toast.success(res.message)
        } else {
          const res = await editMenuCollectionAPI(
            newFormData,
            menuActionId,
            locationId,
          )
          toast.success(res.message)
        }
        getMenuCollection()
      } catch (e: any) {
        handleApiError(e)
      }
    }
    if (menuEdit.method === 'delete' && menuEdit.type === 'Collection') {
      try {
        const params = {
          menu_collection_id: menuActionId,
          locationId,
        }
        const res = await deleteMenuCollectionAPI(params)
        toast.success(res.message)
        getMenuCollection()
      } catch (e: any) {
        handleApiError(e)
      }
    }
    if (menuEdit.method === 'add' && menuEdit.type === 'Category') {
      try {
        const params = {
          menu_collection_id: menuActionId,
          name: formData.name,
          description: formData.description,
        }
        const res = await createMenuCategoryAPI(params, locationId)
        toast.success(res.message)
        getMenuCollection()
      } catch (e: any) {
        handleApiError(e)
      }
    }
    if (menuEdit.method === 'edit' && menuEdit.type === 'Category') {
      try {
        const params = {
          menu_category_id: menuActionId,
          name: formData.name,
          description: formData.description,
        }
        const res = await editMenuCategoryAPI(params, locationId)
        toast.success(res.message)
        getMenuCollection()
      } catch (e: any) {
        handleApiError(e)
      }
    }
    if (menuEdit.method === 'delete' && menuEdit.type === 'Category') {
      try {
        const params = {
          menu_category_id: menuActionId,
          locationId,
        }
        const res = await deleteMenuCategoryAPI(params)
        toast.success(res.message)
        getMenuCollection()
      } catch (e: any) {
        handleApiError(e)
      }
    }
    setMenuEdit({ open: false, type: '', method: '' })
    setFormData({ ...baseFormData, id: formData.id })
    setDeleteValue('')
  }

  const handleShowMenu = async (type: string, menuId: number) => {
    if (type === 'Collection') {
      try {
        const params = {
          menu_collection_id: menuId,
          locationId,
        }
        const res = await showMenuCollectionAPI(params)
        const {
          name,
          description,
          id,
          availability,
          open_hours_collection,
          is_locked,
        } = res

        let days: any = returnHoursInDays([])
        open_hours_collection?.open_hours.map((v: { day: string | number }) => {
          days = { ...days, [v.day]: [...days[v.day], v] }
        })

        setFormData({
          id,
          name,
          is_locked,
          description: description || '',
          availability,
          openHours: days,
        })
      } catch (e: any) {
        const msg = e?.message
        toast.error(msg || 'request error')
      }
    }
    if (type === 'Category') {
      try {
        const params = {
          menu_category_id: menuId,
        }
        const res = await showMenuCategoryAPI(params, locationId)
        const { name, description, id } = res[0]
        setFormData({
          id,
          name,
          description: description || '',
        })
      } catch (e: any) {
        const msg = e?.message
        toast.error(msg || 'request error')
      }
    }
  }

  const onChangeForm = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value })
  }

  const closeModal = () => {
    setMenuEdit({ open: false, type: '', method: '' })
    setFormData({ ...baseFormData, id: formData.id })
    setDeleteValue('')
    setMenuActionName('')
    setMenuActionId(0)
  }

  const handleApiError = (e: any) => {
    // api 报错统一处理
    const msg = e?.message
    e?.status === MENU_LOCKED_STATUS
      ? dispatch(setLockModalOpen(true))
      : toast.error(msg || 'request error')
  }

  return (
    <>
      <div className='w-60 min-w-[240px] mr-10  h-screen bg-white relative'>
        <div>
          <div
            className='h-[65px]  flex items-center justify-between px-4 py-7 font-bold border-b border-solid border-zinc cursor-pointer'
            onClick={() => {
              if (openMenu) {
                setMenuActionName('')
                setMenuActionId(Number(locationId))
                setFormData({ ...baseFormData, id: formData.id })
                setMenuEdit({
                  open: true,
                  type: 'Collection',
                  method: 'add',
                })
              } else {
                setMenuActionName('')
                onChangeForm(
                  'id',
                  menuCollectionList[0]?.menu_categories[0]?.id || 0,
                )
                onChangeMenuItem(
                  menuCollectionList,
                  menuCollectionList[0]?.menu_categories[0]?.id || 0,
                  true,
                )
              }
            }}
          >
            MENU
            <Button color='ink-link'>
              {openMenu ? <Plus size={16} /> : <AngleDown size={16} />}
            </Button>
          </div>
          <Spin spining={openMenu && loading}>
            <>
              {openMenu && (
                <div className='overflow-scroll pb-4 pl-2 pr-[2px] h-[calc(100vh-132px)]'>
                  {collectionLoading ? (
                    <>
                      <OnlineMenuSkeleton type={0} />
                      <OnlineMenuSkeleton type={0} />
                      <OnlineMenuSkeleton type={0} />
                    </>
                  ) : (
                    <DndContext
                      sensors={sensors}
                      onDragEnd={prop => onDragEnd(prop, 'Collection')}
                      modifiers={[
                        restrictToVerticalAxis,
                        restrictToWindowEdges,
                      ]}
                    >
                      <SortableContext
                        disabled={loading}
                        items={menuCollectionList || []}
                      >
                        {menuCollectionList.map(
                          (
                            collectionItem: {
                              name: string
                              is_locked: number
                              id: number
                              menu_categories: any[]
                            },
                            i: number,
                          ) => {
                            return (
                              <MenuCollectionItem
                                key={collectionItem.name}
                                formData={formData}
                                baseFormData={baseFormData}
                                item={collectionItem}
                                loading={loading}
                                index={i}
                                menuActionId={menuActionId}
                                menuActionName={menuActionName}
                                menuActionIndex={menuActionIndex}
                                handleMenuAction={(
                                  value: string,
                                  { name, id }: { name: string; id: number },
                                  { i, j }: { i: number; j: number },
                                  type?: string,
                                ) =>
                                  handleMenuAction(
                                    value,
                                    { name, id },
                                    { i, j },
                                    type,
                                  )
                                }
                                setMenuActionIndex={item =>
                                  setMenuActionIndex(item)
                                }
                                setMenuActionName={name =>
                                  setMenuActionName(name)
                                }
                                getMenuDetail={(name: string, id: number) =>
                                  getMenuDetail(name, id)
                                }
                                setMenuActionId={id => setMenuActionId(id)}
                                setFormData={formData => setFormData(formData)}
                                setMenuEdit={data => setMenuEdit(data)}
                                onDragEnd={(prop, type, list) =>
                                  onDragEnd(prop, type, list)
                                }
                              />
                            )
                          },
                        )}
                      </SortableContext>
                    </DndContext>
                  )}
                </div>
              )}
            </>
          </Spin>
        </div>
        <div className={`${openMenu && 'absolute bottom-0'} w-full `}>
          <div
            className={`h-[65px] text-ink flex items-center justify-between px-4 py-7 font-bold border-${
              openMenu ? 't' : 'b'
            } border-solid border-zinc cursor-pointer`}
            onClick={() => {
              if (!openMenu) {
                setMenuActionName('')
                onChangeForm('id', 0)
                setMenuActionId(0)
                onChangeMenuItem(menuCollectionList, 0)
              } else {
                setMenuActionName('')
                onChangeForm('id', menuModifierList[0]?.id || 0)
                onChangeMenuItem(
                  menuCollectionList,
                  menuModifierList[0]?.id || 0,
                  true,
                )
              }
            }}
          >
            MODIFIER
            <Button color='ink-link'>
              {!openMenu ? <Plus size={16} /> : <AngleUp size={16} />}
            </Button>
          </div>
          <Spin spining={!openMenu && loading}>
            <>
              {!openMenu && (
                <div className='overflow-scroll pt-3 h-[calc(100vh-132px)]'>
                  {modifierLoading ? (
                    <>
                      <OnlineMenuSkeleton type={0} />
                      <OnlineMenuSkeleton type={0} />
                      <OnlineMenuSkeleton type={0} />
                    </>
                  ) : (
                    <DndContext
                      sensors={sensors}
                      onDragEnd={prop => onDragEnd(prop, 'Modifier')}
                      modifiers={[
                        restrictToVerticalAxis,
                        restrictToWindowEdges,
                      ]}
                    >
                      <SortableContext
                        disabled={loading}
                        items={menuModifierList || []}
                      >
                        {menuModifierList.map(
                          (
                            modifierItem: { name: any; id: number },
                            index: number,
                          ) => {
                            return (
                              <MenuModifierItem
                                key={modifierItem.id}
                                formData={formData}
                                item={modifierItem}
                                index={index}
                                menuActionName={menuActionName}
                                menuActionIndex={menuActionIndex}
                                handleMenuAction={(
                                  value: string,
                                  { name, id }: { name: string; id: number },
                                  { i, j }: { i: number; j: number },
                                  type?: string,
                                ) =>
                                  handleMenuAction(
                                    value,
                                    { name, id },
                                    { i, j },
                                    type,
                                  )
                                }
                                setMenuActionIndex={(item: any) =>
                                  setMenuActionIndex(item)
                                }
                                setMenuActionName={(name: string) =>
                                  setMenuActionName(name)
                                }
                                getMenuDetail={(name: string, id: number) =>
                                  getMenuDetail(name, id)
                                }
                              />
                            )
                          },
                        )}
                      </SortableContext>
                    </DndContext>
                  )}
                </div>
              )}
            </>
          </Spin>
        </div>
      </div>
      <ModalFull
        open={menuEdit.open}
        toggle={() => closeModal()}
        title={`${
          menuEdit.method === 'add'
            ? 'New Item'
            : menuEdit.method === 'edit'
            ? 'Edit Menu'
            : 'Delete Menu'
        }  ${menuEdit.type}`}
        onCancel={() => closeModal()}
        okBtnDisabled={menuEdit.method === 'delete' && deleteValue !== 'Delete'}
        okBtnColor={menuEdit.method === 'delete' ? 'warning' : 'primary'}
        onOk={() => handleUpdateMenu()}
        loading={formData.saveLoading}
      >
        <>
          {menuEdit.method !== 'delete' ? (
            <>
              <Field
                name='name'
                type='text'
                value={formData.name}
                onChange={e => onChangeForm('name', e.target.value)}
                placeholder='Input here'
                label={
                  menuEdit.type === 'Category'
                    ? 'Category name'
                    : 'Menu Collection'
                }
                disabled={!!formData.is_locked}
              />
              {menuEdit.type === 'Category' && (
                <TextArea
                  label={
                    <div className='w-full flex justify-between text-silver'>
                      <span>Description</span>
                      <span className='font-normal'>{`(${formData.description.length}/300 characters)`}</span>
                    </div>
                  }
                  containerClass='mt-6'
                  name='description'
                  maxLength={300}
                  value={formData.description}
                  placeholder='Input'
                  onChange={e => onChangeForm('description', e.target.value)}
                />
              )}
              {menuEdit.type === 'Collection' && (
                <>
                  <Select
                    options={BUSINESS_HOURS_OPTIONS}
                    placeholder='Select option'
                    className='mt-6'
                    label='Availability schedule'
                    value={formData.availability}
                    onChange={value => onChangeForm('availability', value)}
                    disabled={!!formData.is_locked}
                  />

                  {formData.availability === 'customize' && (
                    <div className='pt-4'>
                      <OpenHoursForm
                        hoursInDay={formData.openHours || returnHoursInDays([])}
                        onChange={value => onChangeForm('openHours', value)}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className='text-center mb-6'>
                {`This action will permanently delete “${formData.name} ${
                  menuEdit.type
                }” and all its ${
                  menuEdit.type === 'Category' ? '' : 'categories'
                } items. You will not be able to restore this after the deletion.`}
              </div>
              <Field
                name='Delete'
                label='Type “Delete” below to confirm this action'
                type='text'
                value={deleteValue}
                placeholder='Delete'
                onChange={e => setDeleteValue(e.target.value)}
              />
            </>
          )}
        </>
      </ModalFull>

      {!openMenu && (
        <DeleteModifier
          id={openDelete ? formData.id : 0}
          open={openDelete}
          onChange={success => {
            closeModal()
            setOpenDelete(false)
            success && getMenuModifier()
          }}
        />
      )}
    </>
  )
}
