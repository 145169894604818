import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'

import DomainTable from './components/DomainTable'
import ExternalHomepage from './components/ExternalHomepage'
import SetupSteps from './components/SetupSteps'

const DomainSetting = (): JSX.Element => {
  return (
    <AdminPage>
      <AdminPageTitle title='Domain Setting' />
      <div className='px-16'>
        <SetupSteps />
        <DomainTable />
        <ExternalHomepage />
      </div>
    </AdminPage>
  )
}

export default DomainSetting
