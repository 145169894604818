import { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAccountingReportListAPI } from 'redux/financial'

import AccountingReportList from './components/AccountingReportList'

export default function AccountingReport(): JSX.Element {
  const { locationId } = useParams<TParamTypes>()

  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('monthly') // monthly or custom

  // when switched locations, refresh list
  useEffect(() => {
    getAccountingReport(1)
  }, [locationId])

  const getAccountingReport = async (newPage?: number) => {
    const params = {
      page: newPage,
    }
    try {
      setLoading(true)
      const res = await getAccountingReportListAPI({ params, locationId })
      const { data, current_page, last_page } = res.data

      setList(list.concat(data))
      setCurrentPage(current_page)
      setLastPage(last_page)
      setLoading(false)
    } catch (error: any) {
      toast.warn(error?.message || 'Something went wrong')
      setLoading(false)
    }
  }

  return (
    <AccountingReportList
      list={list}
      loading={loading}
      type={type}
      setType={setType}
      handleMore={
        lastPage === currentPage
          ? undefined
          : () => {
              getAccountingReport(currentPage + 1)
            }
      }
    />
  )
}
