import cn from 'classnames'
import Button from 'components/Button'
import { hexToRgb } from 'utils/colorTool'

interface Iprops {
  themeColor: string
  logo: string
  banner: string
  brandName: string
  tagline: string
  logoShape: string
  link: string
  logoPadding: boolean
}
export default function WebsiteDisplay({
  themeColor,
  logo,
  banner,
  brandName,
  logoShape,
  link,
  logoPadding,
}: Iprops) {
  const color = hexToRgb(themeColor)
  const renderLogo = () => {
    if (logo) {
      return (
        <>
          {logoShape === 'rectangle' && (
            <div className={cn('h-4', { 'p-[2px]': logoPadding })}>
              <img className='h-full' src={logo} alt='' />
            </div>
          )}
          {logoShape === 'square' && (
            <div className='w-8 relative'>
              <div
                className={cn(
                  'w-8 h-8 absolute z-20 left-0 top-[-8px] bg-white',
                  { 'p-[2px]': logoPadding },
                )}
                style={{ borderRadius: '0px 0px 4px 4px' }}
              >
                <img
                  className='w-full h-full object-contain'
                  src={logo}
                  alt=''
                />
              </div>
            </div>
          )}
        </>
      )
    }
    return (
      <div
        className='font-bold border-ink'
        style={{
          fontSize: '12px',
          lineHeight: '12px',
          zoom: 0.5,
        }}
      >
        {brandName}
      </div>
    )
  }
  return (
    <div className=''>
      <div className='font-bold'>Website display</div>
      <div
        className='my-6 h-[136px] bg-field'
        style={{
          filter: `drop-shadow(0px 4px 24px rgba(${color.r}, ${color.g}, ${color.b}, 0.16))`,
        }}
      >
        <div className='flex justify-between pl-4 pr-2 items-center w-full h-4 bg-white'>
          <div className='flex items-center'>
            {renderLogo()}
            <div className='ml-2 w-20 h-1 bg-zinc'></div>
          </div>
          <div
            className='w-8 h-[10px] rounded-lg'
            style={{ backgroundColor: themeColor }}
          ></div>
        </div>
        <div
          className='relative w-full h-[120px] pt-6 bg-center	bg-no-repeat bg-cover website-preview-bg'
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className='relative z-10'>
            <div
              className='w-8 h-1 m-auto mb-1'
              style={{ backgroundColor: themeColor }}
            ></div>
            <div className='w-[96px] h-4 bg-zinc m-auto'></div>
            <div className='w-20 h-1 m-auto mt-1 mb-3 bg-zinc'></div>
            <div
              className='w-10 h-3 m-auto rounded-lg'
              style={{ backgroundColor: themeColor }}
            ></div>
          </div>
        </div>
      </div>
      <Button className='w-full' onClick={() => window.open(link)}>
        VISIT WEBSITE
      </Button>
    </div>
  )
}
