import {
  DuoToneInvoice,
  DuoToneUser,
  DuoToneItems,
  DuoToneBank,
  DuoToneTax,
  DuoToneHappyFace,
  DuoToneStore,
} from '@rushable/icons'
import cn from 'classnames'
import MoneyAccounting from 'components/MoneyAccounting'

type TAccountingSummaryProps = {
  data: any
}

export default function AccountingSummary({
  data,
}: TAccountingSummaryProps): JSX.Element {
  return (
    <div className='min-w-[360px] w-[360px] min-h-[720px] border-r border-solid border-zinc'>
      <div className='flex space-y-2 flex-col p-8 border-b border-solid border-zinc'>
        <SummaryItem
          icon={<DuoToneBank />}
          title='Net Payout'
          value={data.net_payout}
          bold
        />
        <p className='text-dark-64 text-sm'>
          Net payout = (Total collected) - (Customer fees) - (Restaurant fees) ±
          (Refund & Adjustment)
        </p>
      </div>
      <div className='flex flex-col space-y-4 p-8'>
        <SummaryItem
          icon={<DuoToneInvoice />}
          title='Total collected'
          value={data.total}
        />
        <SummaryItem
          icon={<DuoToneTax />}
          title='Tax collected'
          value={data.tax}
        />
        <SummaryItem
          icon={<DuoToneHappyFace />}
          title='Restaurant tip'
          value={data.restaurant_tip}
        />
        <SummaryItem
          icon={<DuoToneUser />}
          title='Customer fees'
          value={data.customer_fees}
        />
        <SummaryItem
          icon={<DuoToneStore />}
          title='Restaurant fees'
          value={data.restaurant_fees}
        />
        <SummaryItem
          icon={<DuoToneItems />}
          title='Refund & Adjustment'
          value={data.refund_adjustment}
        />
      </div>
    </div>
  )
}

const SummaryItem = ({
  icon,
  title,
  value,
  bold = false,
}: {
  icon: JSX.Element
  title: string
  value: string | number
  bold?: boolean
}) => (
  <div className='flex-1 flex justify-between text-ink text-base'>
    <div className={cn('flex items-center', { 'font-bold': bold })}>
      <icon.type size={20} />
      <span className='ml-2'>{title}</span>
    </div>
    <MoneyAccounting
      className='w-24'
      value={value}
      type={bold ? 'bold' : 'normal'}
    />
  </div>
)
