import { useState, useEffect } from 'react'

import Field from 'components/Field'
import { Select } from 'components/Select'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { getJobCategoriesApi, getJobTitlesApi } from 'redux/recruiting'

import { SELECT_STATUS_OPTIONS } from '../helps/constant'

interface TCandidateFilters {
  formData: any
  changeFormData: (key: string, value: any) => void
}

export default function CandidateFilters({
  formData,
  changeFormData,
}: TCandidateFilters): JSX.Element {
  const { locationId, brandId } =
    useParams<{ locationId: string; brandId: string }>()

  const [categoryOptions, setCategoryOptions] = useState<
    {
      label: string
      value: string | number
    }[]
  >([])
  const [titleOptions, setTitleOptions] = useState<
    {
      label: string
      value: string | number
    }[]
  >([])

  const getJobCategories = async () => {
    try {
      const res = await getJobCategoriesApi(locationId)
      if (res) {
        setCategoryOptions(
          [
            {
              label: 'All',
              value: 'all',
            },
          ].concat(
            res.map((cat: any) => {
              return {
                label: cat.name,
                value: cat.id,
              }
            }),
          ),
        )
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
  }

  const getJobTitles = async () => {
    try {
      const res = await getJobTitlesApi(brandId)
      if (res) {
        setTitleOptions(
          [
            {
              label: 'All',
              value: 'all',
            },
          ].concat(
            res.map((item: any) => {
              return {
                label: item.title,
                value: item.title,
              }
            }),
          ),
        )
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
  }
  useEffect(() => {
    getJobCategories()
    getJobTitles()
  }, [])
  return (
    <div className='flex space-x-4 pb-6 border-b border-dashed border-zinc'>
      <Field
        className='w-60'
        label='Keyword'
        name='job_keyword'
        value={formData.keyword}
        type='text'
        placeholder='Job code or applicant name'
        onChange={e => changeFormData('keyword', e.target.value)}
      />
      <Select
        className='w-60'
        options={SELECT_STATUS_OPTIONS}
        value={formData.status}
        isMulti
        label='Status'
        placeholder='Select'
        onChange={value => changeFormData('status', value)}
      />
      <Select
        className='w-60'
        options={categoryOptions}
        value={formData.job_category_id}
        label='Job category'
        placeholder='Select'
        onChange={value =>
          changeFormData('job_category_id', value === 'all' ? '' : value)
        }
      />
      <Select
        className='w-60'
        options={titleOptions}
        value={formData.title}
        label='Job title'
        placeholder='Select'
        onChange={value =>
          changeFormData('title', value === 'all' ? '' : value)
        }
      />
    </div>
  )
}
