import React, { useState, useEffect } from 'react'

import { SolidRecepit, SolidUserCircled } from '@rushable/icons'
import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import CopyBox from 'components/DataOutput/CopyBox'
import ModalFull from 'components/Modal/ModalFull'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { updateCustomerReviewsApi } from 'redux/smartReview'
import { TCommentModal } from 'types/review'
import { formatPhoneNumber } from 'utils/digit'

type TReviewModalProps = {
  commentModal: TCommentModal
  onClose: () => void
  getReviewList: () => void
}

export default function wwModal({
  commentModal,
  onClose,
  getReviewList,
}: TReviewModalProps) {
  const { locationId, brandId } =
    useParams<{ locationId: string; brandId: string }>()
  const open = commentModal?.open
  const data = commentModal?.data
  const impression = data?.impression
  const comment = data?.comment
  const email = data?.order?.customer?.email
  const phone = data?.order?.customer?.phone
  const firstName = data?.order?.customer?.first_name
  const lastName = data?.order?.customer?.last_name
  const contactedBy = data?.contacted_by
  const needContacted = impression === 'bad'
  const formatPhone = phone ? formatPhoneNumber(phone) : ''

  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)

  const handleContacted = async (newChecked: boolean) => {
    try {
      setLoading(true)
      await updateCustomerReviewsApi(locationId, {
        id: data?.id,
        contacted: contactedBy ? '0' : '1',
      })
      setChecked(newChecked)
      getReviewList()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  const goOrderDetails = () => {
    window.open(
      `${window.location.origin}/brand/${brandId}/location/${locationId}/order-history/order-detail/${data?.order_id}`,
    )
  }

  const goCustomerProfile = () => {
    window.open(
      `${window.location.origin}/brand/${brandId}/location/${locationId}/customer-data/customer-detail/${data?.order?.customer_id}`,
    )
  }

  useEffect(() => {
    if (open) {
      setChecked(!!contactedBy)
    }
  }, [open])

  return (
    <ModalFull
      open={open}
      title={`Comment from ${firstName} ${lastName}`}
      toggle={onClose}
      footer={
        <div className='flex justify-end'>
          <Button className='px-14' color='primary' onClick={onClose}>
            CLOSE
          </Button>
        </div>
      }
    >
      <div>
        <div className='text-base text-dark-100 font-normal'>{comment}</div>
        <div className='pt-6 pb-4 flex justify-between'>
          <Button
            color='tertiary'
            className='flex-1 mr-3'
            onClick={goOrderDetails}
          >
            <SolidRecepit className='text-silver mr-2' size={16} />
            Order Details
          </Button>
          <Button
            color='tertiary'
            className='flex-1 ml-3'
            onClick={goCustomerProfile}
          >
            <SolidUserCircled className='text-silver mr-2' size={16} />
            Customer Profile
          </Button>
        </div>
        <CopyBox className='mb-4' text={formatPhone} copyText={formatPhone} />
        <CopyBox text={email!} copyText={email!} />
        {needContacted ? (
          <CheckBox
            className='mt-4 px-4 py-3 bg-field rounded-lg'
            labelClass='text-base text-ink'
            loading={loading}
            checked={checked}
            onChange={e => handleContacted(e)}
            size={20}
          >
            This customer has been contacted
          </CheckBox>
        ) : null}
      </div>
    </ModalFull>
  )
}
