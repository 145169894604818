import React from 'react'

import { AngleDown } from '@rushable/icons'
import cn from 'classnames'
import ColorSelect from 'components/ColorSelect'
import Field from 'components/Field'
import { Select } from 'components/Select'
import TextArea from 'components/TextArea'
import UploadComps from 'components/Upload/Upload'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

import {
  flyerColorOptions,
  TFlyerColorOptions,
  FLYER_OPTIONS,
  FLYER_PROMO_OFFER,
} from '../helpers/constant'
import type { TFormData } from '../MarketingFlyer'

type TMarketingFlyerForm = {
  formData: TFormData
  uploadLoading: boolean
  handleChange: (type: string, value: TFlyerColorOptions | string) => void
  openDrawer: () => void
  imgUrl: string
  handleUpload: (aBlob: Blob, preview: string) => void
  handleDelete: () => void
}

export default function MarketingFlyerForm({
  formData,
  uploadLoading,
  handleChange,
  openDrawer,
  imgUrl,
  handleUpload,
  handleDelete,
}: TMarketingFlyerForm): JSX.Element {
  const {
    color,
    title,
    template,
    description,
    pOffer,
    pCode,
    couponDescription,
  } = formData
  return (
    <div className='p-8 w-[776px] border-r border-zinc min-h-[608px]'>
      <div className='flex flex-row'>
        <div>
          <UploadComps
            labelText='Image'
            width='240px'
            height='162px'
            loading={uploadLoading}
            value={parseMediaAbsoluteURL(imgUrl)}
            onChange={handleUpload}
            canDelete
            onDelete={handleDelete}
          />
        </div>
        <div className='ml-6 flex-1'>
          <ColorSelect<TFlyerColorOptions>
            label='Accent color'
            className='mb-6'
            value={color}
            onChange={value => handleChange('color', value)}
            options={flyerColorOptions}
          />
          <Field
            label='Flyer title'
            type='text'
            name='flyer_title'
            value={title || ''}
            onChange={e => handleChange('title', e.target.value)}
            error={title?.length > 25 ? '25 characters limited' : ''}
          />
        </div>
      </div>
      <div>
        <div className='mt-6 mb-2'>
          <span className='text-xs font-bold text-silver'>
            Promo description
          </span>
        </div>
        <Select
          placeholder='Select'
          value={template}
          options={FLYER_OPTIONS}
          onChange={value => handleChange('template', value)}
        />
        <TextArea
          name='promo_description'
          inputClass={cn(
            'mt-2 h-[120px] resize-none',
            description?.length > 300 ? 'hover:border-red' : '',
          )}
          value={description || ''}
          onChange={e => handleChange('description', e.target.value)}
        />
        {description?.length > 300 ? (
          <div className='text-red text-xs text-left'>
            300 characters limited
          </div>
        ) : null}

        <div className='flex justify-between mt-6'>
          <Select
            label='Coupon offer'
            placeholder='Select'
            value={pOffer}
            options={FLYER_PROMO_OFFER}
            onChange={value => handleChange('pOffer', value)}
            className='flex-1'
          />
          {!!pOffer && (
            <div className='block w-[264px] ml-6'>
              <div className='flex justify-between'>
                <span className='mb-2 text-xs font-bold text-silver'>
                  Coupon code
                </span>
              </div>

              <div
                className='cursor-pointer py-3 px-4 bg-field border-2 border-field rounded-lg flex justify-between text-base'
                onClick={openDrawer}
              >
                {pCode ? (
                  <span className='text-ink'>{pCode}</span>
                ) : (
                  <span className='text-dark-16'>Select</span>
                )}
                <AngleDown size={16} className='text-silver' />
              </div>
            </div>
          )}
        </div>
        {!!pOffer && pCode ? (
          <div className='mt-6'>
            <Field
              label='Coupon description'
              type='text'
              name='coupon_description'
              value={couponDescription || ''}
              error={
                couponDescription?.length > 55 ? '55 characters limited' : ''
              }
              onChange={e => handleChange('couponDescription', e.target.value)}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
