import React, { useState } from 'react'

import cn from 'classnames'

export type TToolTipsProp = {
  className?: string
  tipsClass?: string
  children: React.ReactElement
  hoverEl: React.ReactElement
}

export default function ToolTips({
  children,
  hoverEl,
  className,
  tipsClass,
}: TToolTipsProp): JSX.Element {
  const [tipShow, setTipShow] = useState(false)
  return (
    <div className={cn('relative', className)}>
      <div
        onMouseOver={() => setTipShow(true)}
        onMouseOut={() => setTipShow(false)}
      >
        {hoverEl}
      </div>
      {tipShow && (
        <div
          className={cn(
            'px-2 py-1 text-sm absolute z-10 border border-zinc rounded bg-white pointer-events-none',
            tipsClass,
          )}
          style={{ left: 'calc(100% + 4px)' }}
        >
          {children}
        </div>
      )}
    </div>
  )
}
