import React, { useState, useEffect } from 'react'

import Button from 'components/Button'
import CopyText from 'components/CopyText'
import Table, { TColumnProp } from 'components/TableAndCell/Table'
import Tag from 'components/Tag'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { validateDomainAuthApi, getBusinessDomainRecords } from 'redux/business'

import GetHelp from './GetHelp'

interface Iprops {
  id: string
  onStep: (step: number) => void
}

export default function Step2Dns(props: Iprops): JSX.Element {
  const { locationId = '' } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [dns, setDns] = useState<any[]>([])

  useEffect(() => {
    if (props.id) {
      getDnsList()
    }
  }, [props.id])

  const getDnsList = async () => {
    setTableLoading(true)
    try {
      const res = await getBusinessDomainRecords(props.id, locationId)
      setDns(res || [])
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setTableLoading(false)
  }

  const validateDomain = async () => {
    setLoading(true)
    try {
      const res = await validateDomainAuthApi(props.id, locationId)
      toast.success(res.message || 'update successful')
      props.onStep(2)
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const tableCols: TColumnProp[] = [
    {
      key: 'status',
      name: 'COLUMN',
      className: 'w-28',
      custom: value => (
        <Tag
          dot
          text={value === 'pending' ? 'Pending' : 'Verified'}
          dotColor={value === 'pending' ? 'blue' : 'green'}
        />
      ),
    },
    {
      key: 'cname',
      name: 'TYPE',
      className: 'w-28',
      custom: () => <span>CNAME</span>,
    },
    {
      key: 'host',
      name: 'HOST',
      custom: value => (
        <CopyText content={value}>
          <div className='truncate'>{value}</div>
        </CopyText>
      ),
    },
    {
      key: 'value',
      name: 'VALUE',
      custom: value => (
        <CopyText content={value}>
          <div className='truncate'>{value}</div>
        </CopyText>
      ),
    },
  ]

  return (
    <div className='mx-[200px]'>
      <GetHelp type='dns' />
      <div className='mt-6'>
        <Table
          loading={tableLoading}
          columns={tableCols}
          data={dns}
          paginationShow={false}
        />
      </div>
      <div className='flex justify-center mt-10'>
        <Button
          color='secondary'
          className='w-[354px] mr-3'
          onClick={() => props.onStep(0)}
        >
          PREVIOUS STEP
        </Button>
        <Button
          color='primary'
          loading={loading}
          className='w-[354px]'
          onClick={validateDomain}
        >
          VERIFY NOW
        </Button>
      </div>
    </div>
  )
}

{
  /* <div className='flex border-b border-dashed border-zinc text-silver font-bold text-xs pb-2 mb-3'>
          <div className='w-[88px] mr-2'>STATUS</div>
          <div className='w-[88px] mr-2'>TYPE</div>
          <div className='flex-1 mr-2'>HOST</div>
          <div className='flex-1'>VALUE</div>
        </div>
        {!dns.length && <SkeletonItem />}
        {dns?.map((item, index) => {
          return (
            <div className='flex' key={index}>
              <DnsButton
                className='w-[88px] mr-2'
                color={item.status === 'pending' ? 'gray' : 'green'}
                bg={true}
                center={true}
                bold={true}
              >
                {item.status === 'pending' ? 'PENDING' : 'VERIFIED'}
              </DnsButton>
              <DnsButton
                className='w-[80px] mr-2'
                color='black'
                bg={true}
                center={true}
              >
                CNAME
              </DnsButton>
              <DnsButton
                // className='w-[352px] mr-2'
                className='flex-1 mr-2'
                color='black'
                hasCopy={true}
              >
                {item.host}
              </DnsButton>
              <DnsButton className='flex-1' color='black' hasCopy={true}>
                {item.value}
              </DnsButton>
            </div>
          )
        })} */
}
