import { useState, useEffect, useRef } from 'react'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { SERVER_TIMEZONE } from 'data/constant'
import moment from 'moment'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { getOrderDetailApi } from 'redux/orderHistory'

import EventItem from './EventItem'
import EventTime from './EventTime'
import ProcessLogSkeleton from './ProcessLogSkeleton'

type TOrderProcessLogProp = {
  data: any
  open: boolean
  toggle: () => void
}

export default function OrderProcessLog({
  data,
  open,
  toggle,
}: TOrderProcessLogProp): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const { pathname } = useLocation()
  const [formData, setFormData] = useState<Record<string, any>>({})
  const { locationId } = useParams<TParamTypes>()

  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE

  useEffect(() => {
    if (data.type === 'daas_delivery') {
      getOrderDetail()
    } else {
      setFormData({ ...data })
    }
  }, [data])

  const getOrderDetail = async () => {
    try {
      const res = await getOrderDetailApi(
        pathname.split('detail/')[1],
        locationId,
        {
          'expand[]': 'daas_delivery',
        },
      )
      setFormData({ ...res })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
  }

  useEffect(() => {
    if (Object.keys(formData).length) {
      if (ref && ref.current) {
        const height = ref.current.clientHeight
        setHeight(height - 72)
      }
    }
  }, [formData])

  return (
    <>
      {open && (
        <Drawer
          open={open}
          toggle={toggle}
          position='right'
          title='PROCESSING LOG'
          rightFooter={
            <>
              <Button color='primary' onClick={toggle}>
                CLOSE
              </Button>
            </>
          }
        >
          {Object.keys(formData).length ? (
            <div className='relative' ref={ref}>
              <div
                style={{ height: height }}
                className='absolute inline-block w-[15px] top-[48px] border-r border-dashed border-zinc'
              ></div>
              <div className='relative grid'>
                <EventTime
                  datetimeStr={formData.created_at}
                  timezone={timezone}
                  outputFormat='ll'
                />
                <EventItem
                  className='my-6'
                  type='create'
                  formData={formData}
                  timezone={timezone}
                />
                {(formData.status === 'canceled'
                  ? formData.canceled_at
                  : formData.confirmed_at) &&
                  moment(formData.created_at).format('MM-DD') !==
                    moment(
                      formData.status === 'canceled'
                        ? formData.canceled_at
                        : formData.confirmed_at,
                    ).format('MM-DD') && (
                    <EventTime
                      className='mb-6'
                      datetimeStr={
                        formData.status === 'canceled'
                          ? formData.canceled_at
                          : formData.confirmed_at
                      }
                      timezone={timezone}
                      outputFormat='ll'
                    />
                  )}

                {(formData.status === 'canceled'
                  ? formData.canceled_at
                  : formData.confirmed_at) && (
                  <EventItem
                    type='canceled'
                    formData={formData}
                    timezone={timezone}
                  />
                )}

                <>
                  {formData?.daas_deliveries?.[0]?.events.map(
                    (eventItem: any, i: number) => {
                      return (
                        <div key={i}>
                          {moment(formData.created_at).format('MM-DD') !==
                            moment(eventItem.created_at).format('MM-DD') && (
                            <EventTime
                              className='mt-6'
                              datetimeStr={eventItem.created_at}
                              timezone={timezone}
                              outputFormat='ll'
                            />
                          )}
                          <EventItem
                            className='mt-6'
                            formData={formData}
                            eventItem={eventItem}
                            timezone={timezone}
                          />
                        </div>
                      )
                    },
                  )}
                </>
              </div>
            </div>
          ) : (
            <>
              <ProcessLogSkeleton />
              <ProcessLogSkeleton />
              <ProcessLogSkeleton />
            </>
          )}
        </Drawer>
      )}
    </>
  )
}
