import React from 'react'

export default function MyInfoSkeleton(): JSX.Element {
  return (
    <div className='w-[496px] animate-pulse mx-auto'>
      <div className='grid grid-cols-2 gap-6 mb-6'>
        <div className='bg-zinc rounded w-full h-10'></div>
        <div className='bg-zinc rounded w-full h-10'></div>
      </div>
      <div className='bg-zinc rounded w-full h-10 mb-6'></div>
      <div className='bg-zinc rounded w-full h-10 mb-6'></div>
      <div className='bg-zinc rounded w-full h-10'></div>
    </div>
  )
}
