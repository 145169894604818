import { IMAGE_PREFIX } from 'data/constant'

export default function parseMediaAbsoluteURL(
  url: string | null,
  prefix: string | undefined = IMAGE_PREFIX,
  isThumbnails?: boolean,
) {
  if (!url) {
    return ''
  }
  if (isThumbnails && url.includes('Media')) {
    url = url.split('Media').join('Thumbnails/Media')
  }
  return url.startsWith?.('http') ? url : `${prefix}/${url}`
}
