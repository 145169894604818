import cn from 'classnames'
import Upload from 'components/Upload/Upload'

interface Iprops {
  className?: string
  value: string
  onChange: (blob: Blob, preview: string) => void
  onDelete?: () => void
}
export default function MenuPageBanner({
  className,
  value,
  onChange,
  onDelete,
}: Iprops): JSX.Element {
  return (
    <div className={cn('', className)}>
      <Upload
        loading={false}
        height='224px'
        value={value}
        onChange={(blob, preview) => {
          onChange(blob, preview)
        }}
        labelText='Menu page banner'
        desc={
          <div className='font-medium'>
            <div className='text-xxs text-silver leading-4'>
              To override the default menu page
            </div>
            <div className='text-xxs text-silver'>
              banner, drag an image here or{' '}
              <span className='text-blue'>Browse</span>
            </div>
          </div>
        }
        onDelete={onDelete}
      />
    </div>
  )
}
