import { LinedSearch } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'

export type TUsersFiltersProp = {
  disabled?: boolean
  data: { keyword: string }
  onChange: (name: string, value: any) => void
  onAddUser: () => void
}
export default function UsersFilters({
  disabled,
  data,
  onChange,
  onAddUser,
}: TUsersFiltersProp): JSX.Element {
  return (
    <div className='flex justify-between items-center w-full'>
      <div className='flex justify-start items-center'>
        <Field
          className='w-60'
          suffix={<LinedSearch size={16} className='text-silver' />}
          placeholder='Search'
          type='text'
          name='search user'
          value={data.keyword || ''}
          onChange={e => onChange('keyword', e.target.value)}
        />
      </div>
      <div className='flex justify-end items-center'>
        <Button color='primary' onClick={onAddUser} disabled={disabled}>
          ADD NEW USER
        </Button>
      </div>
    </div>
  )
}
