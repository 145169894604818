export const Financial_STATUS_OPTIONS = [
  { label: 'Any Status', value: '' },
  { label: 'Current', value: 'current' },
  { label: 'In Transit', value: 'in_transit' },
  { label: 'Paid', value: 'paid' },
  { label: 'Failed', value: 'failed' },
]

export const ORDER_REFERENCE: any = {
  daas_delivery: 'Delivery (On-demand)',
  delivery: 'Delivery (In-house)',
  pickup: 'Pickup',
  curbside: 'Curbside',
  'dine-in': 'Dine in',
  takeout: 'Takeout',
}

export const ACCOUNTING_REPORT_OPTIONS = [
  {
    value: 'monthly',
    label: 'Monthly Report',
  },
  {
    value: 'custom',
    label: 'Custom date range',
  },
]
