import React from 'react'

type TGiftCardIconProp = {
  size?: number
  className?: string
}

export default function GiftCardIcon({
  size = 160,
  className = '',
}: TGiftCardIconProp) {
  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 160 160'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_926_93695)'>
          <path
            d='M142 83C142 118.346 113.346 147 78 147C42.6538 147 14 118.346 14 83C14 47.6538 42.6538 19 78 19C113.346 19 142 47.6538 142 83Z'
            fill='#F4F6F8'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M78 146C112.794 146 141 117.794 141 83C141 48.2061 112.794 20 78 20C43.2061 20 15 48.2061 15 83C15 117.794 43.2061 146 78 146ZM78 147C113.346 147 142 118.346 142 83C142 47.6538 113.346 19 78 19C42.6538 19 14 47.6538 14 83C14 118.346 42.6538 147 78 147Z'
            fill='#62727E'
          />
          <path
            d='M31.8216 144.479C26.3973 145.319 21.3189 141.603 20.4787 136.178L10.0511 68.8587C9.21091 63.4344 12.9271 58.3559 18.3514 57.5157L128.164 40.5061C133.589 39.6659 138.667 43.3821 139.507 48.8064L149.935 116.126C150.775 121.55 147.059 126.629 141.635 127.469L31.8216 144.479Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.0393 68.7056L21.4669 136.025C22.2226 140.904 26.79 144.246 31.6686 143.49L141.481 126.481C146.36 125.725 149.702 121.158 148.947 116.279L138.519 48.9595C137.763 44.081 133.196 40.7387 128.317 41.4943L18.5045 58.5039C13.6259 59.2596 10.2837 63.8271 11.0393 68.7056ZM20.4787 136.178C21.3189 141.603 26.3973 145.319 31.8216 144.479L141.635 127.469C147.059 126.629 150.775 121.55 149.935 116.126L139.507 48.8064C138.667 43.3821 133.589 39.6659 128.164 40.5061L18.3514 57.5157C12.9271 58.3559 9.21091 63.4344 10.0511 68.8587L20.4787 136.178Z'
            fill='#62727E'
          />
          <path
            d='M47.4315 110.447C47.1932 109.096 48.0955 107.807 49.4469 107.569L109.55 96.9707C110.902 96.7324 112.19 97.6348 112.429 98.9862L113.302 103.94C113.541 105.292 112.638 106.58 111.287 106.819L51.1834 117.417C49.832 117.655 48.5433 116.753 48.305 115.401L47.4315 110.447Z'
            fill='#E1E5E9'
          />
          <path
            d='M113.471 115.617C112.114 115.827 110.83 114.901 110.62 113.545L107.554 93.75C107.344 92.3939 108.288 91.122 109.644 90.9119C111 90.7019 112.285 91.6286 112.495 92.9847L115.561 112.779C115.771 114.135 114.827 115.407 113.471 115.617Z'
            fill='#D8DCDF'
          />
          <path
            d='M133.033 101.322C142.796 111.085 142.796 126.915 133.033 136.678C123.27 146.441 107.441 146.441 97.6777 136.678C87.9146 126.915 87.9146 111.085 97.6777 101.322C107.441 91.5592 123.27 91.5592 133.033 101.322Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M131.527 134.527C140.314 125.74 140.314 111.494 131.527 102.707C122.74 93.9204 108.494 93.9204 99.7073 102.707C90.9205 111.494 90.9205 125.74 99.7073 134.527C108.494 143.314 122.74 143.314 131.527 134.527ZM136.477 139.477C147.997 127.956 147.997 109.278 136.477 97.7574C124.956 86.2369 106.278 86.2369 94.7575 97.7574C83.2371 109.278 83.2371 127.956 94.7575 139.477C106.278 150.997 124.956 150.997 136.477 139.477Z'
            fill='#E1E5E9'
          />
          <path
            d='M139.979 138.479L152 150.5C153.367 151.867 153.367 154.083 152 155.45C150.633 156.817 148.417 156.817 147.05 155.45L135.03 143.429L139.979 138.479Z'
            fill='#E1E5E9'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M139.979 138.479L152 150.5C153.367 151.867 153.367 154.083 152 155.45C150.633 156.817 148.417 156.817 147.05 155.45L135.03 143.429L139.979 138.479ZM136.444 143.429L147.757 154.743C148.734 155.719 150.317 155.719 151.293 154.743C152.269 153.766 152.269 152.183 151.293 151.207L139.979 139.893L136.444 143.429ZM132.234 102C141.412 111.177 141.412 126.057 132.234 135.234C123.057 144.411 108.177 144.411 99.0002 135.234C89.8229 126.057 89.8229 111.177 99.0002 102C108.177 92.8227 123.057 92.8227 132.234 102ZM135.77 138.77C146.9 127.64 146.9 109.594 135.77 98.4645C124.64 87.3346 106.595 87.3346 95.4646 98.4645C84.3347 109.594 84.3347 127.64 95.4646 138.77C106.595 149.9 124.64 149.9 135.77 138.77ZM131.527 102.707C140.314 111.494 140.314 125.74 131.527 134.527C122.74 143.314 108.494 143.314 99.7073 134.527C90.9205 125.74 90.9205 111.494 99.7073 102.707C108.494 93.9204 122.74 93.9204 131.527 102.707ZM136.477 97.7574C147.997 109.278 147.997 127.956 136.477 139.477C124.956 150.997 106.278 150.997 94.7575 139.477C83.2371 127.956 83.2371 109.278 94.7575 97.7574C106.278 86.2369 124.956 86.2369 136.477 97.7574Z'
            fill='#62727E'
          />
          <path
            d='M106.376 121.028V122H102.602V121.028H106.376ZM102.948 114.891V122H101.723V114.891H102.948ZM105.883 117.859V118.816H102.602V117.859H105.883ZM106.352 114.891V115.867H102.602V114.891H106.352Z'
            fill='#62727E'
          />
          <path
            d='M108.437 117.791V122H107.26V116.717H108.368L108.437 117.791ZM108.246 119.163L107.846 119.158C107.846 118.794 107.891 118.457 107.982 118.147C108.074 117.838 108.207 117.57 108.383 117.342C108.559 117.111 108.777 116.933 109.037 116.81C109.301 116.683 109.605 116.619 109.95 116.619C110.191 116.619 110.411 116.655 110.609 116.727C110.811 116.795 110.985 116.904 111.132 117.054C111.282 117.203 111.396 117.396 111.474 117.63C111.555 117.864 111.596 118.147 111.596 118.479V122H110.419V118.582C110.419 118.325 110.38 118.123 110.302 117.977C110.227 117.83 110.118 117.726 109.975 117.664C109.835 117.599 109.667 117.566 109.472 117.566C109.25 117.566 109.062 117.609 108.905 117.693C108.752 117.778 108.627 117.894 108.529 118.04C108.432 118.187 108.36 118.356 108.314 118.548C108.269 118.74 108.246 118.945 108.246 119.163ZM111.522 118.851L110.971 118.973C110.971 118.654 111.015 118.353 111.103 118.069C111.194 117.783 111.326 117.532 111.498 117.317C111.674 117.099 111.89 116.928 112.147 116.805C112.405 116.681 112.699 116.619 113.031 116.619C113.301 116.619 113.542 116.657 113.754 116.731C113.969 116.803 114.151 116.917 114.301 117.073C114.451 117.229 114.564 117.433 114.643 117.684C114.721 117.931 114.76 118.23 114.76 118.582V122H113.578V118.577C113.578 118.31 113.539 118.104 113.461 117.957C113.386 117.811 113.279 117.71 113.139 117.654C112.999 117.596 112.831 117.566 112.636 117.566C112.453 117.566 112.292 117.601 112.152 117.669C112.016 117.734 111.9 117.827 111.806 117.947C111.711 118.064 111.64 118.2 111.591 118.353C111.545 118.506 111.522 118.672 111.522 118.851Z'
            fill='#62727E'
          />
          <path
            d='M117.152 117.732V124.031H115.976V116.717H117.06L117.152 117.732ZM120.595 119.31V119.412C120.595 119.796 120.549 120.153 120.458 120.481C120.37 120.807 120.238 121.092 120.062 121.336C119.89 121.577 119.677 121.764 119.423 121.897C119.169 122.031 118.876 122.098 118.544 122.098C118.215 122.098 117.927 122.037 117.68 121.917C117.436 121.793 117.229 121.619 117.06 121.395C116.89 121.17 116.754 120.906 116.649 120.604C116.549 120.298 116.477 119.962 116.435 119.598V119.202C116.477 118.815 116.549 118.463 116.649 118.147C116.754 117.832 116.89 117.56 117.06 117.332C117.229 117.104 117.436 116.928 117.68 116.805C117.924 116.681 118.209 116.619 118.534 116.619C118.866 116.619 119.161 116.684 119.418 116.814C119.675 116.941 119.892 117.124 120.067 117.361C120.243 117.596 120.375 117.879 120.463 118.211C120.551 118.54 120.595 118.906 120.595 119.31ZM119.418 119.412V119.31C119.418 119.065 119.395 118.839 119.35 118.631C119.304 118.419 119.232 118.234 119.135 118.074C119.037 117.915 118.912 117.791 118.759 117.703C118.609 117.612 118.428 117.566 118.217 117.566C118.008 117.566 117.829 117.602 117.68 117.674C117.53 117.742 117.405 117.838 117.304 117.962C117.203 118.086 117.125 118.23 117.069 118.396C117.014 118.559 116.975 118.737 116.952 118.929V119.876C116.991 120.11 117.058 120.325 117.152 120.521C117.247 120.716 117.38 120.872 117.553 120.989C117.729 121.103 117.953 121.16 118.227 121.16C118.438 121.16 118.619 121.115 118.769 121.023C118.918 120.932 119.04 120.807 119.135 120.647C119.232 120.485 119.304 120.298 119.35 120.086C119.395 119.874 119.418 119.65 119.418 119.412Z'
            fill='#62727E'
          />
          <path
            d='M124.018 116.717V117.576H121.039V116.717H124.018ZM121.898 115.423H123.075V120.54C123.075 120.703 123.098 120.828 123.144 120.916C123.192 121.001 123.259 121.058 123.344 121.087C123.428 121.116 123.528 121.131 123.642 121.131C123.723 121.131 123.801 121.126 123.876 121.116C123.951 121.106 124.011 121.097 124.057 121.087L124.062 121.985C123.964 122.015 123.85 122.041 123.72 122.063C123.593 122.086 123.446 122.098 123.28 122.098C123.01 122.098 122.771 122.05 122.562 121.956C122.354 121.858 122.191 121.701 122.074 121.482C121.957 121.264 121.898 120.975 121.898 120.613V115.423Z'
            fill='#62727E'
          />
          <path
            d='M126.459 121.424L127.895 116.717H129.154L127.035 122.806C126.986 122.936 126.923 123.077 126.845 123.23C126.767 123.383 126.664 123.528 126.537 123.665C126.413 123.805 126.259 123.917 126.073 124.002C125.888 124.09 125.663 124.134 125.399 124.134C125.295 124.134 125.194 124.124 125.097 124.104C125.002 124.088 124.913 124.07 124.828 124.051L124.823 123.152C124.856 123.156 124.895 123.159 124.94 123.162C124.989 123.165 125.028 123.167 125.058 123.167C125.253 123.167 125.416 123.143 125.546 123.094C125.676 123.048 125.782 122.973 125.863 122.869C125.948 122.765 126.02 122.625 126.078 122.449L126.459 121.424ZM125.648 116.717L126.903 120.672L127.113 121.912L126.298 122.122L124.379 116.717H125.648Z'
            fill='#62727E'
          />
          <path
            d='M52.6733 87.0725L50.2655 87.4971L50.155 86.8707L55.6949 85.8939L55.8053 86.5203L53.3976 86.9448L54.4952 93.1699L53.7709 93.2976L52.6733 87.0725Z'
            fill='#B0B8BE'
          />
          <path
            d='M59.6217 86.9171C60.2677 86.8032 60.8133 86.9021 61.2585 87.2138C61.709 87.5179 61.9976 88.0288 62.1242 88.7466L62.6506 91.7318L61.9556 91.8544L61.4413 88.9376C61.347 88.4026 61.1413 88.0183 60.8243 87.7849C60.5073 87.5515 60.1008 87.4785 59.6049 87.566C59.0503 87.6638 58.6392 87.9078 58.3716 88.2981C58.1094 88.6808 58.0295 89.1625 58.1319 89.7432L58.6082 92.4446L57.9133 92.5672L56.6327 85.3046L57.3276 85.1821L57.8592 88.1967C57.9955 87.8632 58.2157 87.5855 58.5197 87.3637C58.8237 87.1419 59.191 86.993 59.6217 86.9171Z'
            fill='#B0B8BE'
          />
          <path
            d='M65.4235 85.8941C66.0956 85.7756 66.641 85.8543 67.0597 86.1304C67.4773 86.3999 67.7441 86.8642 67.8604 87.5232L68.423 90.714L67.7574 90.8314L67.6159 90.0288C67.5065 90.3239 67.3116 90.5736 67.0314 90.7777C66.7577 90.9807 66.4121 91.1191 65.9945 91.1927C65.4202 91.2939 64.9393 91.2375 64.5517 91.0232C64.164 90.809 63.9305 90.4768 63.8511 90.0265C63.7741 89.5893 63.8685 89.2094 64.1346 88.8866C64.4071 88.5627 64.8892 88.3398 65.5809 88.2178L67.2155 87.9296L67.1602 87.6164C67.082 87.1727 66.8988 86.8585 66.6105 86.6738C66.3212 86.4826 65.9383 86.429 65.462 86.513C65.1357 86.5705 64.8323 86.6812 64.5517 86.8451C64.2699 87.0024 64.0381 87.1947 63.8561 87.4219L63.4514 86.9584C63.6733 86.6905 63.9566 86.4656 64.3013 86.2837C64.6449 86.0953 65.0189 85.9654 65.4235 85.8941ZM66.0055 90.6256C66.397 90.5566 66.7175 90.4092 66.967 90.1836C67.2154 89.9514 67.3782 89.6536 67.4554 89.2901L67.3069 88.4483L65.692 88.7331C64.8111 88.8884 64.4247 89.2728 64.5328 89.8861C64.5858 90.1863 64.7419 90.4043 65.0014 90.5402C65.2597 90.6696 65.5944 90.6981 66.0055 90.6256Z'
            fill='#B0B8BE'
          />
          <path
            d='M72.0189 84.7311C72.6649 84.6172 73.2105 84.7161 73.6557 85.0279C74.1063 85.3319 74.3948 85.8428 74.5214 86.5606L75.0478 89.5459L74.3528 89.6684L73.8385 86.7517C73.7442 86.2166 73.5385 85.8324 73.2215 85.5989C72.9045 85.3655 72.498 85.2926 72.0021 85.38C71.4475 85.4778 71.0364 85.7218 70.7688 86.1122C70.5066 86.4948 70.4267 86.9765 70.5291 87.5572L71.0054 90.2587L70.3105 90.3812L69.4027 85.2328L70.0683 85.1155L70.2357 86.0649C70.3697 85.7183 70.5881 85.4308 70.891 85.2025C71.1992 84.9665 71.5752 84.8094 72.0189 84.7311Z'
            fill='#B0B8BE'
          />
          <path
            d='M78.3874 86.2927L77.3705 87.6225L77.6294 89.0907L76.9345 89.2132L75.6539 81.9507L76.3488 81.8281L77.2169 86.7514L79.795 83.4004L80.6563 83.2485L78.8251 85.7412L81.7696 88.3606L80.9181 88.5108L78.3874 86.2927Z'
            fill='#B0B8BE'
          />
          <path
            d='M87.4527 84.9163L87.8704 87.2849L87.1559 87.4109L86.7382 85.0423L83.2072 81.0427L83.9804 80.9063L87.0078 84.3387L88.6786 80.0779L89.4029 79.9502L87.4527 84.9163Z'
            fill='#B0B8BE'
          />
          <path
            d='M93.1174 86.4102C92.6215 86.4976 92.1549 86.4655 91.7178 86.3138C91.2795 86.1556 90.911 85.8977 90.6125 85.5399C90.3139 85.1821 90.1203 84.752 90.0317 84.2496C89.9431 83.7471 89.978 83.2768 90.1361 82.8385C90.2943 82.4002 90.5529 82.035 90.9118 81.7429C91.2707 81.4509 91.6982 81.2612 92.1941 81.1737C92.69 81.0863 93.1565 81.1184 93.5937 81.2701C94.0308 81.4217 94.3954 81.677 94.6875 82.0359C94.986 82.3937 95.1796 82.8238 95.2682 83.3263C95.3568 83.8287 95.322 84.2991 95.1638 84.7374C95.0121 85.1745 94.7574 85.5424 94.3996 85.841C94.0407 86.133 93.6133 86.3227 93.1174 86.4102ZM93.0087 85.7936C93.3741 85.7291 93.686 85.59 93.9443 85.3763C94.208 85.1548 94.3944 84.8764 94.5035 84.541C94.6125 84.2055 94.6325 83.842 94.5635 83.4505C94.4945 83.059 94.3513 82.7243 94.1341 82.4464C93.9169 82.1684 93.6471 81.9738 93.3247 81.8625C93.0077 81.7434 92.6665 81.7161 92.3011 81.7806C91.9357 81.845 91.6211 81.9879 91.3574 82.2094C91.099 82.4231 90.9121 82.6983 90.7965 83.0349C90.6874 83.3703 90.6674 83.7338 90.7365 84.1253C90.8055 84.5168 90.9486 84.8515 91.1658 85.1295C91.3896 85.4062 91.6599 85.6041 91.977 85.7232C92.2994 85.8345 92.6433 85.858 93.0087 85.7936Z'
            fill='#B0B8BE'
          />
          <path
            d='M100.872 79.6838L101.78 84.8322L101.115 84.9496L100.949 84.01C100.822 84.3554 100.613 84.6444 100.325 84.8769C100.035 85.103 99.6845 85.2522 99.2735 85.3247C98.6014 85.4432 98.0368 85.351 97.5797 85.0481C97.128 84.7375 96.8382 84.2201 96.7105 83.4958L96.1841 80.5105L96.8791 80.388L97.3934 83.3048C97.4889 83.8463 97.6951 84.2338 98.0121 84.4673C98.3291 84.7007 98.7356 84.7736 99.2315 84.6862C99.7731 84.5907 100.172 84.3522 100.427 83.9707C100.682 83.5827 100.757 83.0951 100.654 82.5078L100.178 79.8064L100.872 79.6838Z'
            fill='#B0B8BE'
          />
          <path
            d='M48.2149 75.2582L50.283 74.8935L50.8873 78.3206C50.4744 78.7428 49.9766 79.0986 49.394 79.3882C48.8115 79.6777 48.2129 79.8767 47.5984 79.985C46.7318 80.1379 45.9198 80.0942 45.1626 79.854C44.404 79.606 43.7701 79.1897 43.2609 78.6053C42.7517 78.0208 42.4241 77.315 42.2783 76.4877C42.1324 75.6605 42.1988 74.8852 42.4774 74.1618C42.756 73.4384 43.214 72.8337 43.8513 72.3477C44.4871 71.8538 45.2463 71.529 46.1287 71.3734C46.9008 71.2373 47.617 71.245 48.2774 71.3967C48.9377 71.5483 49.5158 71.8322 50.0116 72.2485L48.7601 73.8582C48.0924 73.3423 47.3686 73.1532 46.5886 73.2907C45.8795 73.4157 45.3465 73.7331 44.9896 74.2428C44.6313 74.7446 44.5154 75.354 44.6418 76.071C44.7224 76.5279 44.8931 76.9202 45.154 77.2479C45.4134 77.5677 45.7385 77.7988 46.1292 77.9411C46.5184 78.0756 46.9415 78.1025 47.3985 78.0219C47.8475 77.9427 48.2531 77.7778 48.615 77.5272L48.2149 75.2582Z'
            fill='#62727E'
          />
          <path
            d='M51.0685 70.673L53.4084 70.2604L54.867 78.5328L52.5271 78.9454L51.0685 70.673Z'
            fill='#62727E'
          />
          <path
            d='M57.7318 71.3624L58.0527 73.1823L61.7044 72.5384L62.0232 74.3465L58.3715 74.9904L58.8716 77.8267L56.5317 78.2393L55.0731 69.9669L61.561 68.8229L61.8798 70.631L57.7318 71.3624Z'
            fill='#62727E'
          />
          <path
            d='M64.801 70.1646L62.2602 70.6126L61.9331 68.7573L69.3428 67.4507L69.6699 69.3061L67.1409 69.752L68.2724 76.1691L65.9325 76.5816L64.801 70.1646Z'
            fill='#62727E'
          />
          <path
            d='M79.2658 74.4012C78.3992 74.554 77.5873 74.5103 76.8301 74.2702C76.0794 74.0208 75.4494 73.6038 74.9402 73.0194C74.4309 72.4349 74.1034 71.7291 73.9575 70.9018C73.8117 70.0746 73.878 69.2993 74.1567 68.5759C74.4353 67.8525 74.8854 67.2492 75.5069 66.766C76.1349 66.2734 76.8822 65.9507 77.7489 65.7979C78.5052 65.6646 79.2103 65.6783 79.8642 65.8392C80.518 66.0001 81.0903 66.2973 81.581 66.7307L80.3296 68.3404C79.6799 67.7889 78.973 67.5804 78.2088 67.7152C77.7597 67.7944 77.3753 67.9637 77.0555 68.2232C76.7435 68.4813 76.521 68.8089 76.388 69.206C76.2614 69.5939 76.2391 70.0202 76.3211 70.4851C76.403 70.9499 76.5705 71.3469 76.8235 71.6759C77.083 71.9957 77.4034 72.2235 77.7848 72.3594C78.174 72.4938 78.5932 72.5215 79.0423 72.4423C79.8065 72.3075 80.3995 71.8699 80.8213 71.1294L82.5479 72.2141C82.235 72.7891 81.7989 73.2641 81.2395 73.6389C80.68 74.0138 80.0222 74.2679 79.2658 74.4012Z'
            fill='#62727E'
          />
          <path
            d='M88.9577 70.8645L85.4597 71.4813L85.0931 73.2031L82.7059 73.624L84.8989 64.7078L87.2034 64.3014L92.3255 71.9279L89.8911 72.3571L88.9577 70.8645ZM87.968 69.26L86.4377 66.8004L85.8408 69.6351L87.968 69.26Z'
            fill='#62727E'
          />
          <path
            d='M96.2175 68.9752L94.9412 69.2002L95.3288 71.3983L92.9889 71.8109L91.5302 63.5385L95.3119 62.8717C96.0603 62.7397 96.7325 62.7512 97.3285 62.906C97.923 63.053 98.411 63.3325 98.7923 63.7446C99.1723 64.1487 99.4171 64.662 99.5269 65.2844C99.6325 65.8832 99.583 66.4321 99.3786 66.9312C99.1806 67.421 98.8439 67.8378 98.3684 68.1816L100.611 70.4668L98.1059 70.9086L96.2175 68.9752ZM97.1633 65.7012C97.0953 65.3151 96.9204 65.0373 96.6386 64.8676C96.3569 64.698 95.9757 64.6555 95.4951 64.7403L94.1952 64.9695L94.6224 67.3921L95.9223 67.1629C96.4029 67.0781 96.7472 66.9118 96.9554 66.6639C97.1621 66.4081 97.2314 66.0872 97.1633 65.7012Z'
            fill='#62727E'
          />
          <path
            d='M100.278 61.996L104.19 61.3063C105.096 61.1465 105.929 61.1742 106.69 61.3894C107.451 61.6045 108.079 61.9892 108.575 62.5435C109.071 63.0979 109.393 63.7926 109.54 64.6277C109.687 65.4628 109.623 66.2256 109.346 66.9161C109.07 67.6066 108.611 68.183 107.97 68.6454C107.328 69.1078 106.554 69.4189 105.648 69.5787L101.737 70.2684L100.278 61.996ZM105.225 67.7281C105.934 67.6031 106.465 67.3022 106.82 66.8254C107.183 66.3472 107.301 65.7535 107.176 65.0445C107.051 64.3354 106.737 63.8182 106.232 63.4928C105.736 63.166 105.133 63.0651 104.424 63.1901L102.947 63.4506L103.747 67.9886L105.225 67.7281Z'
            fill='#62727E'
          />
        </g>
        <defs>
          <clipPath id='clip0_926_93695'>
            <rect width='160' height='160' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
