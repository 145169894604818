import React, { useState, useEffect } from 'react'

import { AngleLeft } from '@rushable/icons'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import moment, { Moment } from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getReportDetailAPI } from 'redux/financial/actions'
import { useAppDispatch } from 'redux/hooks'

import AccountingReportTable from './components/AccountingReportTable'
import AccountingSummary from './components/AccountingSummary'

type TAccountingReportDetailProp = {
  downloading: boolean
  downloadReport: (date: any) => void
}

export default function AccountingReportDetail({
  downloading,
  downloadReport,
}: TAccountingReportDetailProp): JSX.Element {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { locationId, dateRange } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)
  const [detailData, setDetailData] = useState<any>({})
  const [tableData, setTableData] = useState({ data: [], total: 0 })
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [date, setDate] = useState<{
    begin_time: Moment | null
    end_time: Moment | null
  }>({
    begin_time: moment().startOf('month'),
    end_time: moment().endOf('month'),
  })
  const [isMonthly, setIsMonthly] = useState(false)

  const getDateRange = () => {
    let begin_time = null
    let end_time = null
    if (dateRange) {
      if (dateRange.length === 6) {
        const year = dateRange.substring(0, 4)
        const month = dateRange.substring(4, 6)
        const time = moment(`${year}-${month}-01`, 'YYYY-MM-DD')
        if (time.isValid()) {
          begin_time = time
          end_time = time.clone().endOf('month')
        }
      }
      if (dateRange.length === 17) {
        const date1 = dateRange.substring(0, 8)
        const date2 = dateRange.substring(9, 17)
        if (
          moment(date1, 'YYYYMMDD').isValid() &&
          moment(date2, 'YYYYMMDD').isValid()
        ) {
          begin_time = moment(date1, 'YYYYMMDD')
          end_time = moment(date2, 'YYYYMMDD')
        }
      }
      // check if monthly report
      let checkIsMonthly = false
      if (begin_time && end_time) {
        if (
          begin_time.year === end_time.year &&
          begin_time.month === end_time.month
        ) {
          if (
            begin_time.clone().startOf('month').isSame(begin_time) &&
            end_time.clone().endOf('month').isSame(end_time)
          ) {
            checkIsMonthly = true
          }
        }
      }
      setIsMonthly(checkIsMonthly)
      return { begin_time, end_time }
    }
  }

  const getDetail = async (newPage?: number, perPage?: number) => {
    setLoading(true)
    const range = getDateRange()
    if (!range || !range.begin_time || !range.end_time) {
      return
    }
    try {
      setDate(range)
      const res = await dispatch(
        getReportDetailAPI(locationId, {
          page: newPage || page,
          per_page: perPage || pageSize,
          begin_time: range.begin_time.format('YYYY-MM-DD 00:00:00'),
          end_time: range.end_time.format('YYYY-MM-DD 23:59:59'),
        }),
      )
      setDetailData(res.summary)
      setTableData(res.transactions || { data: [], total: 0 })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  useEffect(() => {
    getDetail(1, 20)
  }, [locationId, dateRange])

  if (
    !date ||
    !date.begin_time ||
    !date.end_time ||
    !date.begin_time.isValid() ||
    !date.end_time.isValid()
  ) {
    return <></>
  }

  return (
    <>
      <AdminPageTitle
        title={
          isMonthly
            ? `${date.begin_time.format('MMMM YYYY')} Accounting Report`
            : `${date.begin_time.format(
                'MM/DD/YYYY',
              )} to ${date.end_time.format('MM/DD/YYYY')} Accounting Report`
        }
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            GO BACK
          </Button>
        }
      />
      <div className='flex'>
        <AccountingSummary data={detailData} />
        <AccountingReportTable
          loading={loading}
          downloading={downloading}
          downloadReport={() =>
            downloadReport({
              begin_time: date?.begin_time?.format('YYYY-MM-DD 00:00:00'),
              end_time: date?.end_time?.format('YYYY-MM-DD 23:59:59'),
            })
          }
          tableData={tableData}
          page={page}
          onChange={(name, value) => {
            switch (name) {
              case 'page':
                setPage(value)
                getDetail(value, pageSize)
                break
              case 'pageSize':
                setPage(1)
                setPageSize(value)
                getDetail(1, value)
                break
            }
          }}
        />
      </div>
    </>
  )
}
