const useSearch = () => {
  const result: { [key: string]: string } = {}
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0]) {
      result[pair[0]] = pair[1]
    }
  }
  return result
}

export default useSearch
