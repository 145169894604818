import { useState } from 'react'

import { FullClover, FullSquare, FullGbp } from '@rushable/icons'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import {
  confirmSquareLocation,
  disconnectSquare,
  disconnectClover,
} from 'redux/integration'

import Clover from './Clover'
import Square from './Square'
import type { TypeConnects } from '../index'

type TTypes = 'google' | 'clover' | 'square' | 'check_mate'

type TDetail = {
  type: TTypes
  open: boolean
  connects: TypeConnects
  handleGetIntegration: () => void
  toggle: () => void
}

const LogoMap = {
  google: <FullGbp size={160} />,
  square: <FullSquare className='h-full' size={93.33} />,
  clover: <FullClover className='h-full' size={93.33} />,
  check_mate: <FullClover className='h-full' size={160} />,
}
export default function IntegrationDetail({
  type,
  open,
  connects,
  handleGetIntegration,
  toggle,
}: TDetail) {
  console.log(connects, 'connects')
  const { locationId } = useParams<{ locationId: string }>()
  const [squareSelectLocationId, setSquareSelectLocationId] = useState('')
  const [squareLoading, setSquareLoading] = useState(false)
  const [squareDisconnectLoading, setSquareDisconnectLoading] = useState(false)
  const [cloverDisconnectLoading, setCloverDisconnectLoading] = useState(false)

  const handleSquareSelect = (id: string) => {
    setSquareSelectLocationId(id)
  }

  const handleConfirmSquareLocation = async () => {
    try {
      setSquareLoading(true)
      await confirmSquareLocation(locationId, squareSelectLocationId)
      await handleGetIntegration()
      toggle()
    } catch (err: any) {
      const msg = err.message || 'Request error'
      toast.error(msg)
    } finally {
      setSquareLoading(false)
    }
  }

  const handleDisconnectSquare = async () => {
    if (type === 'square') {
      try {
        setSquareDisconnectLoading(true)
        await disconnectSquare(locationId, connects[type]?.id || 0)
        await handleGetIntegration()
        toggle()
      } catch (err: any) {
        const msg = err.message || 'Request error'
        toast.error(msg)
      } finally {
        setSquareDisconnectLoading(false)
      }
    }
  }

  const handleDisconnectClover = async () => {
    if (type === 'clover') {
      try {
        setCloverDisconnectLoading(true)
        await disconnectClover(locationId, connects[type]?.id || 0)
        await handleGetIntegration()
        toggle()
      } catch (err: any) {
        const msg = err.message || 'Request error'
        toast.error(msg)
      } finally {
        setCloverDisconnectLoading(false)
      }
    }
  }

  const leftFooterMap = {
    google: <Button color='secondary-link'>DISCONNECT</Button>,
    square: (
      <Button
        color='secondary-link'
        loading={squareDisconnectLoading}
        onClick={handleDisconnectSquare}
      >
        DISCONNECT
      </Button>
    ),
    clover: (
      <Button
        color='secondary-link'
        loading={cloverDisconnectLoading}
        onClick={handleDisconnectClover}
      >
        DISCONNECT
      </Button>
    ),
    check_mate: <Button color='secondary-link'>DISCONNECT</Button>,
  }

  const rightFooterMap = {
    google: (
      <>
        <Button color='secondary' onClick={toggle}>
          CANCEL
        </Button>
        <Button color='primary'>CONFIRM</Button>
      </>
    ),
    square: (
      <>
        <Button color='secondary' onClick={toggle}>
          CANCEL
        </Button>
        <Button
          color='primary'
          loading={squareLoading}
          disabled={!squareSelectLocationId}
          onClick={handleConfirmSquareLocation}
        >
          CONFIRM
        </Button>
      </>
    ),
    clover: (
      <>
        <Button color='secondary' onClick={toggle}>
          CANCEL
        </Button>
        {/* <Button color='primary'>CONFIRM</Button> */}
      </>
    ),
    check_mate: (
      <>
        <Button color='secondary' onClick={toggle}>
          CANCEL
        </Button>
        <Button color='primary'>CONFIRM</Button>
      </>
    ),
  }
  const mainMap = {
    google: <div>google</div>,
    square: (
      <Square
        id={connects['square']?.id}
        selectId={squareSelectLocationId}
        onSelect={handleSquareSelect}
      />
    ),
    clover: <Clover id={connects['clover']?.id} />,
    check_mate: <div>check_mate</div>,
  }

  return (
    <Drawer
      open={open}
      toggle={toggle}
      title='Integration Detail'
      leftFooter={leftFooterMap[type]}
      rightFooter={rightFooterMap[type]}
    >
      <div>
        <div className='mb-6 h-10'>{LogoMap[type]}</div>
        {mainMap[type]}
      </div>
    </Drawer>
  )
}
