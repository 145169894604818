import { useState } from 'react'

import Button from 'components/Button'
import type { TCampaignItem } from 'types/campaign'

import FinalOverviewForm from './FinalOverviewForm'
import PaymentModal from './PaymentModal'
import type { TAddOpenDataState } from '../../helpers/types'
import Line from '../Line'

type TFinalReviewFormProps = {
  stepLoading: boolean
  prevStep: () => void
  createOrUpdate: (extraParam: Record<string, any>) => void
  formData: TCampaignItem
  addOpenData: TAddOpenDataState
  handleFormDataChange: (type: string, value: any) => void
  viewOnly?: boolean
}

export default function FinalReviewForm({
  stepLoading,
  prevStep,
  createOrUpdate,
  formData,
  addOpenData,
  handleFormDataChange,
  viewOnly,
}: TFinalReviewFormProps): JSX.Element {
  const { status, type } = formData

  const [paymentShow, setPaymentShow] = useState(false)

  const handlePayment = async () => {
    await createOrUpdate({ status: 'scheduled' })
    setPaymentShow(false)
  }

  const buttonsRender = () => {
    let ele = null
    if (type === 'one_off' && status === 'scheduled') {
      if (viewOnly) {
        ele = (
          <Button
            color='primary'
            className='mr-3 w-full'
            loading={stepLoading}
            onClick={() =>
              handleFormDataChange('status', addOpenData?.item?.status)
            }
          >
            GO DETAILS
          </Button>
        )
      } else {
        ele = (
          <Button
            color='secondary'
            className='mr-3 w-full'
            loading={stepLoading}
            onClick={() => createOrUpdate({ status: 'draft', _client_step: 1 })}
          >
            EDIT CAMPAIGN
          </Button>
        )
      }
    } else {
      ele = (
        <>
          <Button color='secondary' className='mr-3 w-full' onClick={prevStep}>
            PREVIOUS STEP
          </Button>
          <Button
            color='primary'
            className='w-full'
            onClick={() => setPaymentShow(true)}
          >
            SCHEDULE NOW
          </Button>
        </>
      )
    }
    return <div className='flex justify-between'>{ele}</div>
  }

  return (
    <div>
      <FinalOverviewForm formData={formData} />
      <Line className='my-6' />
      {buttonsRender()}
      <PaymentModal
        open={paymentShow}
        stepLoading={stepLoading}
        toggle={() => {
          setPaymentShow(!paymentShow)
        }}
        handlePayment={handlePayment}
      />
    </div>
  )
}
