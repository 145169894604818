import { LinedTrash } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'

interface Iprops {
  value?: any[]
  onChange?: (value: any[]) => void
}

const AddLink = ({ value = [], onChange }: Iprops): JSX.Element => {
  const navLinks = value

  const onChangeForm = (arrs: any[]) => {
    const copyArrs = [...arrs]
    onChange?.(copyArrs)
  }
  return (
    <div className='w-full'>
      <div className='text-xs text-silver font-bold mb-2'>
        Custom navigation link
      </div>
      {navLinks.map((item, index) => {
        return (
          <div className='flex items-center	mb-4' key={index}>
            <Field
              type='text'
              name='navlink'
              containerClassName='w-60 mr-4'
              value={item.name || ''}
              placeholder=''
              onChange={(e: any) => {
                const value = e.target.value
                navLinks[index].name = value
                onChangeForm(navLinks)
              }}
            />
            <Field
              type='text'
              name='navlink'
              containerClassName='flex-1'
              value={item.url || ''}
              placeholder=''
              onChange={(e: any) => {
                const value = e.target.value
                navLinks[index].url = value
                onChangeForm(navLinks)
              }}
            />
            <Button
              className='ml-4'
              color='ink-link'
              onClick={() => {
                navLinks.splice(index, 1)
                onChangeForm(navLinks)
              }}
            >
              <LinedTrash size={16} />
            </Button>
          </div>
        )
      })}
      <div className=''>
        <Button
          className='w-full'
          color='tertiary'
          onClick={() => {
            navLinks.push({})
            onChangeForm(navLinks)
          }}
        >
          + ADD LINK
        </Button>
      </div>
    </div>
  )
}

export default AddLink
