import React from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { AngleRight } from '@rushable/icons'
import Button from 'components/Button'

import MenuAction from './MenuAction'

type TMenuCategoryItemProp = {
  formData: any
  item: any
  i: number
  j: number
  menuActionId: number
  menuActionName: string
  menuActionIndex: any
  handleMenuAction: (
    value: string,
    { name, id }: { name: string; id: number },
    { i, j }: { i: number; j: number },
    type?: string,
  ) => void
  setMenuActionIndex: (item: any) => void
  setMenuActionName: (name: string) => void
  getMenuDetail: (name: string, id: number) => void
  setMenuActionId: (id: number) => void
}

export default function MenuCategoryItem({
  formData,
  item,
  i,
  j,
  menuActionId,
  menuActionName,
  menuActionIndex,
  handleMenuAction,
  setMenuActionIndex,
  setMenuActionName,
  getMenuDetail,
  setMenuActionId,
}: TMenuCategoryItemProp): JSX.Element {
  const { id } = item
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  let styles
  if (transform) {
    const { x, y }: any = transform
    styles = {
      transform: CSS.Transform.toString({ x, y, scaleX: 1, scaleY: 1 }),
      transition,
    }
  }

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      style={styles}
      className='flex items-center px-2  cursor-pointer'
    >
      <MenuAction
        className='mr-2'
        open={
          menuActionName === item.name &&
          menuActionId === item.id &&
          menuActionIndex.i === i &&
          menuActionIndex.j === j
        }
        onChange={v => {
          setMenuActionId(item.id)
          handleMenuAction(v, item, { i, j }, 'Category')
        }}
        clickOutside={() => {
          setMenuActionIndex({ i: -1, j: -1 })
          setMenuActionName('')
        }}
      />
      <div
        className={`py-3 flex w-full ${formData.id === item.id && 'text-blue'}`}
        onClick={() => {
          getMenuDetail(item.name, item.id)
          document.querySelector(`#category-item-${item.id}`)?.scrollIntoView()
        }}
      >
        <div className='w-[158px] text-ellipsis leading-snug'>{item.name}</div>
        <Button className='ml-auto' color='ink-link'>
          <AngleRight
            size={16}
            className={`${formData.id === item.id && 'text-blue'}`}
          />
        </Button>
      </div>
    </div>
  )
}
