import React, { useEffect } from 'react'

import useSearch from 'hooks/useSearch'
import { Redirect, useLocation } from 'react-router-dom'
import { getProfile } from 'redux/auth'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getAccessToken } from 'utils/auth'
type TRequireAuthProps = {
  children: JSX.Element
}
export default function RequireAuth({
  children,
}: TRequireAuthProps): JSX.Element {
  const hasToken = getAccessToken()
  const location = useLocation()
  const { square_oauth, clover_oauth } = useSearch()
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector(state => state.auth)
  useEffect(() => {
    if (hasToken && !profile) {
      dispatch(getProfile())
    }
  }, [])

  if (!hasToken) {
    let search = ''
    if (square_oauth === 'square_first') {
      search = `?square_oauth=${square_oauth}`
    }
    if (clover_oauth === 'clover_first') {
      search = `?clover_oauth=${clover_oauth}`
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Redirect
        to={{ pathname: '/login', state: { from: location }, search }}
      />
    )
  }

  return children
}
