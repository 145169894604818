import React, { useState, useEffect } from 'react'

import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { toast } from 'react-toastify'
import { editUserProfileApi } from 'redux/user/actions'

export type TResetPasswordModalProp = {
  open: boolean
  userId: string
  brandId: string
  toggle: () => void
}
const initState = { curPwd: '', newPwd: '', repeatPwd: '' }
export default function ResetPasswordModal({
  open,
  userId,
  brandId,
  toggle,
}: TResetPasswordModalProp): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [state, setState] = useState(initState)

  useEffect(() => {
    setState(initState)
  }, [open])

  const onChangeForm = (name: string, value: string) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const res = await editUserProfileApi(
        {
          type: 'password',
          old_password: state.curPwd,
          new_password: state.newPwd,
          new_password_confirmation: state.repeatPwd,
        },
        userId,
        brandId,
      )
      toast.success(res.message)
      toggle()
      setFormError('')
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormError(msg)
    }
    setIsLoading(false)
  }

  return (
    <ModalFull
      open={open}
      toggle={toggle}
      title='Reset Password'
      onCancel={toggle}
      loading={isLoading}
      error={formError}
      onOk={() => {
        handleSubmit()
      }}
    >
      <>
        <Field
          label='Current password'
          name='Current password'
          type='password'
          containerClassName='mb-6'
          value={state.curPwd}
          onChange={e => {
            onChangeForm('curPwd', e.target.value)
          }}
        />
        <Field
          label='New password'
          name='New password'
          type='password'
          containerClassName='mb-6'
          value={state.newPwd}
          onChange={e => {
            onChangeForm('newPwd', e.target.value)
          }}
        />
        <Field
          label='Repeat new password'
          name='Repeat new password'
          type='password'
          value={state.repeatPwd}
          onChange={e => {
            onChangeForm('repeatPwd', e.target.value)
          }}
        />
      </>
    </ModalFull>
  )
}
