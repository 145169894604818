import React, { useEffect, useState, useRef } from 'react'

import { LinedTrash } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import Upload from 'components/Upload/Upload'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  updateGallerriesApi,
  createGallerriesApi,
  deleteGallerriesApi,
} from 'redux/brand/actions'

interface Iprops {
  rowData: Record<string, any>
  visible: boolean
  toggle: () => void
  onSuccess: () => void
}
export default function ModalForm({
  rowData,
  onSuccess,
  visible,
  toggle,
}: Iprops): JSX.Element {
  const { brandId } = useParams<TParamTypes>()
  const [isEdit, setIsEdit] = useState(false)
  const [modalForm, setModalForm] = useState<Record<string, any>>({
    image: '',
    description: '',
  })
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const imgRef = useRef<any>()
  useEffect(() => {
    if (visible) {
      setModalForm({
        image: rowData.image,
        description: rowData.description,
      })
      setIsEdit(!!rowData.id)
    }
  }, [visible])

  const createGallerries = async (params: any) => {
    setLoading(true)
    try {
      const res = await createGallerriesApi(params, brandId)
      toast.success(res?.message)
      setModalForm({})
      imgRef.current = ''
      onSuccess()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const updateGallerries = async (params: any, id?: string) => {
    setLoading(true)
    try {
      const res = await updateGallerriesApi(params, id, brandId)
      toast.success(res?.message)
      imgRef.current = ''
      onSuccess()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const handleEditConfirm = () => {
    const formData = new FormData()
    imgRef.current && formData.append('image', imgRef.current)
    modalForm.description &&
      formData.append('description', modalForm.description)

    if (isEdit) {
      updateGallerries(formData, rowData.id)
    } else {
      createGallerries(formData)
    }
  }

  const deleteGallerries = async (id: string) => {
    setdeleteLoading(true)
    try {
      const res = await deleteGallerriesApi(id, brandId)
      toast.success(res?.message)
      onSuccess()
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setdeleteLoading(false)
  }

  return (
    <ModalFull
      open={visible}
      loading={loading}
      toggle={toggle}
      iconFooter={
        <>
          {isEdit && (
            <Button
              color='secondary-link'
              loading={deleteLoading}
              onClick={() => deleteGallerries(rowData.id)}
            >
              {!deleteLoading && (
                <LinedTrash size={16} className='text-silver cursor-pointer' />
              )}
              <span className='text-silver ml-2'>DELETE</span>
            </Button>
          )}
        </>
      }
      title='Gallery Image'
      okBtnDisabled={!modalForm.image || !modalForm.description}
      onCancel={toggle}
      onOk={() => handleEditConfirm()}
    >
      <>
        <div className='mb-6'>
          <Upload
            labelText='Image'
            desc={
              <div className='text-sm font-bold text-silver'>
                <span>Drag images here or </span>
                <span className='text-blue'>Browse</span>
              </div>
            }
            value={modalForm.image || ''}
            onChange={(blob, blobUrl) => {
              setModalForm(prev => ({ ...prev, image: blobUrl }))
              imgRef.current = blob
            }}
            onDelete={() => {
              setModalForm(prev => ({ ...prev, image: '' }))
              imgRef.current = null
            }}
          ></Upload>
        </div>
        <div>
          <Field
            label='Caption'
            name='Caption'
            placeholder='Write caption'
            value={modalForm.description || ''}
            onChange={e => {
              setModalForm(prev => ({ ...prev, description: e.target.value }))
            }}
          />
        </div>
      </>
    </ModalFull>
  )
}
