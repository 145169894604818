import CalendarField from 'components/CalendarField'
import { TimeSelect } from 'components/Select'
import moment from 'moment'
import { toast } from 'react-toastify'
import type { TCampaignItem } from 'types/campaign'

type TSendingScheduleFormProps = {
  viewOnly?: boolean
  formData: TCampaignItem
  handleFormDataChange: (type: string, value: any) => void
}

export default function SendingScheduleForm({
  viewOnly,
  formData,
  handleFormDataChange,
}: TSendingScheduleFormProps) {
  const {
    trigger_of_date_time: triggerOfDateTime,
    type: campaignType,
    trigger_of_inactive_days: days,
  } = formData
  let triggerOfDate = ''
  let triggerOfTime = ''
  if (triggerOfDateTime && triggerOfDateTime.length > 0) {
    triggerOfDate = triggerOfDateTime.split(' ')[0]
    triggerOfTime = triggerOfDateTime.split(' ')[1]
  }
  console.log(days, 'days')
  return (
    <>
      <div className='text-base text-ink font-bold pb-2'>Sending Schedule</div>
      {campaignType === 'automated' ? (
        <div className='h-[40px] px-4 flex items-center bg-ice rounded-lg'>
          {!days
            ? 'Send a wish/offer to a customer 7 days before the birthday date.'
            : `${days} days after the customer’s last purchase`}
        </div>
      ) : (
        <div className='flex'>
          <CalendarField
            className='flex-1'
            date={triggerOfDate ? new Date(triggerOfDate) : new Date()}
            placeholder='Select'
            calendarRestProps={{ minDate: new Date() }}
            disabled={viewOnly}
            value={
              triggerOfDate ? moment(triggerOfDate).format('MMM DD, YYYY') : ''
            }
            onChange={a => {
              if (a) {
                handleFormDataChange(
                  'trigger_of_date',
                  moment(a).format('YYYY-MM-DD'),
                )
              }
            }}
          />
          <TimeSelect
            className='ml-6 flex-1'
            placeholder='Select'
            value={triggerOfTime}
            disabled={viewOnly}
            onChange={value => {
              const time = `${triggerOfDate} ${value}`
              const isAfterTime = moment(time).isAfter(moment())
              if (!isAfterTime) {
                toast.error('Please select a future time')
              }
              handleFormDataChange('trigger_of_time', value)
            }}
          />
        </div>
      )}
    </>
  )
}
