export const JOB_OPTIONS = [
  { label: 'Show both status', value: '' },
  { label: 'Show open job only', value: 'open' },
  { label: 'Show closed job only', value: 'closed' },
]

export const TABLE_STATUS_OPTIONS: any = {
  archived: {
    color: 'gray',
    value: 'Archived',
  },
  hired: {
    color: 'green',
    value: 'Hired',
  },
  new_applicant: {
    color: 'yellow',
    value: 'New applicant',
  },
  disqualified: {
    color: 'red',
    value: 'Disqualified',
  },
  interview_in_progress: {
    color: 'orange',
    value: 'Interview in progress',
  },
}

export const SELECT_STATUS_OPTIONS = [
  { label: 'New applicant', value: 'new_applicant' },
  { label: 'Interview in progress', value: 'interview_in_progress' },
  { label: 'Hired', value: 'hired' },
  { label: 'Disqualified', value: 'disqualified' },
  { label: 'Archived', value: 'archived' },
]

export const STATUS_OPTIONS = [
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' },
]

export type TJobPostData = {
  id: number | null
  code: string | null
  status: string
  job_category_id: number | null
  title: string
}
