import { createReducer } from '@reduxjs/toolkit'

import { setLockModalOpen, setMenuItemList, setListScrollTop } from './actions'

type TOnlineMenuState = {
  lockModalOpen: boolean
  menuItemList: any
  listScrollTop?: number
}

const initialState: TOnlineMenuState = {
  lockModalOpen: false,
  menuItemList: [],
  // 记录 right item list 滑动距离
  listScrollTop: 0,
}

export const onlineMenuReducer = createReducer(initialState, builder => {
  builder
    .addCase(setLockModalOpen, (state, actions) => {
      state.lockModalOpen = actions.payload
    })
    .addCase(setMenuItemList, (state, actions) => {
      state.menuItemList = actions.payload
    })
    .addCase(setListScrollTop, (state, actions) => {
      state.listScrollTop = actions.payload
    })
})

export default onlineMenuReducer
