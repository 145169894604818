import React, { useState } from 'react'

import SaveChangeBar from 'components/SaveChangeBar'

export type TSaveChangeBarProvider = {
  children: React.ReactElement
}
export type TShowSaveChangeBarButtons = {
  confirm?: boolean
  delete?: boolean
  cancel?: boolean
}
export type TShowSaveChangeBar = {
  buttons?: TShowSaveChangeBarButtons
  actionCallback?: (type: 'confirm' | 'delete' | 'cancel') => void
}
export type TSaveChangeBarContextProp = {
  createBar?: () => void
  resetBar?: () => void
  setShow?: (show: boolean) => void
  setErrorMsg?: (mgs: string) => void
  setRequestStatus?: (status: string | null) => void
}

const SaveChangeBarContext = React.createContext({
  createBar: ({ buttons }: TShowSaveChangeBar) => {
    console.log(buttons?.confirm)
  },
  resetBar: () => {
    console.log('hide')
  },
  setShow: (show: boolean) => {
    console.log(`set ${show}`)
  },
  setErrorMsg: (error: string) => {
    console.log(error)
  },
  setRequestStatus: (status: string | null) => {
    console.log(status)
  },
})

const SaveChangeBarProvider = ({ children }: TSaveChangeBarProvider) => {
  const [show, setShow] = useState(false)
  const [barConfig, setBarConfig] = useState<TShowSaveChangeBar>({})
  const [error, setError] = useState<string>()
  const [request, setRequest] = useState<string | null>(null)

  const createBar = ({ actionCallback, buttons }: TShowSaveChangeBar) => {
    setBarConfig({ actionCallback, buttons })
  }

  const resetBar = () => {
    setShow(false)
    setBarConfig({})
  }

  const onConfirm = () => {
    barConfig.actionCallback && barConfig.actionCallback('confirm')
  }

  const onCancel = () => {
    barConfig.actionCallback && barConfig.actionCallback('cancel')
  }

  const onDelete = () => {
    barConfig.actionCallback && barConfig.actionCallback('delete')
  }

  const setErrorMsg = (v: string) => {
    setError(v)
  }

  const setRequestStatus = (v: string | null) => {
    setRequest(v)
  }

  return (
    <SaveChangeBarContext.Provider
      value={{ createBar, resetBar, setShow, setErrorMsg, setRequestStatus }}
    >
      {show && (
        <SaveChangeBar
          error={error}
          onConfirm={barConfig.buttons?.confirm ? onConfirm : undefined}
          onCancel={barConfig.buttons?.cancel ? onCancel : undefined}
          onDelete={barConfig.buttons?.delete ? onDelete : undefined}
          confirmRequest={request === 'confirming'}
        />
      )}
      {children}
    </SaveChangeBarContext.Provider>
  )
}

const useSaveChangeBar = () => {
  const { createBar, resetBar, setShow, setErrorMsg, setRequestStatus } =
    React.useContext(SaveChangeBarContext)

  const getButtonClicked = ({ ...options }) =>
    new Promise(res => {
      createBar({ actionCallback: res, ...options })
    })

  return { getButtonClicked, resetBar, setShow, setErrorMsg, setRequestStatus }
}

export { SaveChangeBarProvider, useSaveChangeBar }
