import React, { useState, useEffect } from 'react'

import { Dollar } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import Timeline from 'components/Timeline'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateLoyaltyConfigAPI } from 'redux/brand/actions'

import { STEP_LIST, EXPIRATION_LIST, POINTS_LIST } from '../helpers/constant'

type TPointsSettingProp = {
  formData: any
  onFormChange: (data: any) => void
  updateRewardList: () => void
}

export default function PointsSetting({
  formData,
  onFormChange,
  updateRewardList,
}: TPointsSettingProp): JSX.Element {
  const [loading, setLoading] = useState(false)
  const { brandId } = useParams<TParamTypes>()
  const [rank, setRank] = useState({
    tab: 'to easy',
    silver: '0.00',
    gold: '0.00',
  })
  const [modalInfo, setModalInfo] = useState({ open: false, value: '' })

  useEffect(() => {
    if (!formData.expiration_month && !formData.ranking_step) {
      let expirationItem = ''
      let pointsItem = ''
      let newSubtract = 0
      EXPIRATION_LIST.map(expiration => {
        POINTS_LIST.map(points => {
          const order_average = Number(formData.order_average)
          const expiration_month = Number(expiration.value)
          const ranking_step = Number(points.value)
          const silver = Number(
            ranking_step / 10 / order_average / expiration_month,
          ).toFixed(2)
          const subtract = Math.abs(Number(silver) - 0.75)
          if (!newSubtract || subtract < newSubtract) {
            expirationItem = expiration.value
            pointsItem = points.value
            newSubtract = subtract
          }
        })
      })
      onFormChange({
        expiration_month: expirationItem,
        ranking_step: pointsItem,
      })
    }
  }, [formData.expiration_month, formData.ranking_step])

  useEffect(() => {
    if (!(!formData.expiration_month && !formData.ranking_step)) {
      const ranking_step = Number(formData.ranking_step)
      const expiration_month = Number(formData.expiration_month)
      const order_average = Number(formData.order_average)
      const silver = order_average
        ? Number(ranking_step / 10 / order_average / expiration_month).toFixed(
            2,
          )
        : '0.00'

      const gold = order_average
        ? Number(
            (ranking_step * 2) / 10 / order_average / expiration_month,
          ).toFixed(2)
        : '0.00'

      const tab = selectTab(silver)
      setRank({ tab, silver, gold })
    }
  }, [formData])

  const selectTab = (silver: string) => {
    let tab = ''
    const newSilver = Number(silver)
    if (newSilver >= 0 && newSilver < 0.51) {
      tab = 'too easy'
    } else if (newSilver >= 0.51 && newSilver < 1.0) {
      tab = 'easy'
    } else if (newSilver >= 1.0 && newSilver < 1.51) {
      tab = 'fair'
    } else {
      tab = 'hard'
    }
    return tab
  }

  const updateStatus = async () => {
    setLoading(true)
    try {
      const params = {
        id: formData.id,
        current_step: 'rewards_setting',
        order_average: formData.order_average,
        expiration_month: formData.expiration_month,
        ranking_step: formData.ranking_step,
      }

      const res = await updateLoyaltyConfigAPI(params, brandId)
      toast.success(res.message)
      updateRewardList()
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <>
      <div className='flex flex-col items-center  space-y-8 mt-8'>
        <Timeline className='w-[720px]' list={STEP_LIST} current={1} isEqual />
        <div className='w-[720px] border border-solid border-zinc rounded-lg'>
          <div className='px-6 py-4 border-b border-dashed border-zinc flex justify-between items-center text-ink'>
            <div>The estimated average check size of your restaurant</div>
            <Field
              className='w-36'
              name='average'
              value={formData.order_average}
              type='text'
              placeholder='0.00'
              align='right'
              inputMode='money'
              suffix={<Dollar size={16} className='text-silver' />}
              onChangeMoney={value => onFormChange({ order_average: value })}
            />
          </div>
          <div className='px-6 py-4 border-b border-dashed border-zinc flex justify-between items-center text-ink'>
            <div>Expiration schedule for points earned by your customers</div>
            <Select
              className='w-36'
              options={EXPIRATION_LIST}
              placeholder='Select'
              value={String(formData.expiration_month)}
              onChange={value => onFormChange({ expiration_month: value })}
            />
          </div>
          <div className='px-6 py-4 flex justify-between items-center text-ink'>
            <div>
              <p>Points needed before the expiration date to rank up</p>
              <p className='text-xs text-lead'>
                Points by every $1 spend in subtotal:{' '}
                <span style={{ color: '#CA8642' }} className='font-bold'>
                  Bronze 10 pts
                </span>
                ,{' '}
                <span style={{ color: '#999FAA' }} className='font-bold'>
                  Silver 11 pts
                </span>
                ,{' '}
                <span style={{ color: '#FFBB08' }} className='font-bold'>
                  Gold 13 pts
                </span>
              </p>
            </div>
            <Select
              className='w-36'
              options={POINTS_LIST}
              placeholder='Select'
              value={String(formData.ranking_step)}
              onChange={value => onFormChange({ ranking_step: value })}
            />
          </div>
        </div>
        <div className='w-[720px] border border-solid border-zinc rounded-lg'>
          <div className='w-full bg-ice flex font-bold cursor-pointer'>
            <div className='py-3 flex-1 flex justify-center items-center'>
              {rank.tab === 'too easy' ? (
                <div className='text-red-darken'>🙅‍♂️ Too Easy</div>
              ) : (
                <div className='text-dark-16'>Too Easy</div>
              )}
            </div>
            <div className='py-3 flex-1 flex justify-center items-center'>
              {rank.tab === 'easy' ? (
                <div className='text-green-darken'>🥰 Easy</div>
              ) : (
                <div className='text-dark-16'>Easy</div>
              )}
            </div>
            <div className='py-3 flex-1 flex justify-center items-center'>
              {rank.tab === 'fair' ? (
                <div className='text-green-darken'>😄 Fair</div>
              ) : (
                <div className='text-dark-16'>Fair</div>
              )}
            </div>
            <div className='py-3 flex-1 flex justify-center items-center'>
              {rank.tab === 'hard' ? (
                <div className='text-red-darken'>😡 Hard</div>
              ) : (
                <div className='text-dark-16'>Hard</div>
              )}
            </div>
          </div>
          <div className='px-6 py-4 text-center'>
            Based on the settings, we estimated that customers need to place{' '}
            {rank.silver} orders per month to reach Silver rank, and {rank.gold}{' '}
            orders to reach Gold rank.{' '}
            <span className='font-bold'>
              {rank.tab === 'hard' ? (
                <>
                  We believe this is{' '}
                  <span className='text-red'>too difficult</span> for your{' '}
                  customers, and therefore{' '}
                  <span className='text-red'>DO NOT recommend</span> continuing
                </>
              ) : rank.tab === 'too easy' ? (
                <>
                  We think this is <span className='text-red'>too easy</span>{' '}
                  for your customers and{' '}
                  <span className='text-red'>DO NOT recommend</span> continuing
                </>
              ) : (
                `We think this is ${rank.tab} for your customers and recommend continuing`
              )}
            </span>{' '}
            with this setting.
          </div>
        </div>
        <Button
          disabled={!formData.order_average}
          className='w-[720px]'
          color={rank.tab === 'hard' ? 'warning' : 'primary'}
          onClick={() => setModalInfo({ open: true, value: '' })}
        >
          {rank.tab === 'hard'
            ? 'Continue to rewards setting anyway'
            : 'Continue to rewards setting'}
        </Button>
      </div>
      <ModalFull
        title='Important Alert'
        open={modalInfo.open}
        toggle={() => setModalInfo({ open: false, value: '' })}
        okBtnDisabled={modalInfo.value !== 'Confirm'}
        loading={loading}
        onOk={() => updateStatus()}
        okText='Continue'
        cancelText='Go back to review'
        onCancel={() => setModalInfo({ open: false, value: '' })}
      >
        <div className='text-ink text-center mb-6'>
          After activating the feature, your points setting will be locked. This
          safeguards you and your customers from potential disputes. If you need
          to review your settings, please do so. Otherwise, type "Confirm" to
          proceed to the next step.
        </div>
        <Field
          name='Confirm'
          label='Type “Confirm” to continue'
          type='text'
          value={modalInfo.value}
          placeholder='Confirm'
          onChange={e => setModalInfo({ ...modalInfo, value: e.target.value })}
        />
      </ModalFull>
    </>
  )
}
