import React from 'react'

import { SolidTrashCan } from '@rushable/icons'
import Button from 'components/Button'

export type TSaveChangeBarProp = {
  onConfirm?: () => void | null
  onCancel?: () => void | null
  onDelete?: () => void | null
  confirmColor?: TButtonColorType
  cancelColor?: TButtonColorType
  deleteColor?: TButtonColorType
  confirmText?: string
  cancelText?: string
  deleteText?: string
  error?: string
  confirmRequest?: boolean
  className?: string
  disabled?: boolean
}

export default function SaveChangeBar({
  confirmColor = 'primary',
  cancelColor = 'secondary',
  deleteColor = 'secondary-link',
  onConfirm,
  onCancel,
  onDelete,
  confirmText = 'CONFIRM',
  cancelText = 'CANCEL',
  deleteText = 'DELETE',
  error,
  confirmRequest = false,
  disabled = false,
  className,
}: TSaveChangeBarProp): JSX.Element {
  return (
    <div
      className={`fixed ${
        className || 'left-60'
      }   right-0 bottom-0 border-t border-solid border-zinc bg-white z-[100]`}
    >
      <div className='w-full pr-10 pl-10 flex justify-between items-center h-16'>
        <div className='flex justify-start items-center space-x-4'>
          {onDelete && (
            <Button color={deleteColor} onClick={onDelete}>
              <SolidTrashCan size={16} />
              {deleteText}
            </Button>
          )}
        </div>
        <div className='flex justify-end items-center space-x-4'>
          {error && (
            <div className='text-red text-base h-[40px] bg-red-opacity-4 rounded-lg px-4 flex items-center truncate'>
              {error}
            </div>
          )}
          {onCancel && (
            <Button color={cancelColor} onClick={onCancel}>
              {cancelText}
            </Button>
          )}

          {onConfirm && (
            <Button
              color={confirmColor}
              onClick={onConfirm}
              loading={confirmRequest}
              disabled={disabled}
            >
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
