import React from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { orderSettingRoutes } from 'routes/orderSettingRoutes'

import OrderGeneralSettingPage from './OrderGeneralSettingPage'
import OrderTypeSettingPage from './OrderTypeSettingPage'

export default function OrderSettingPage(): JSX.Element {
  const history = useHistory()
  const { path, url } = useRouteMatch()

  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})

  return (
    <AdminPage>
      <>
        <AdminPageTabs>
          <>
            {orderSettingRoutes.map(v => (
              <AdminPageTab
                key={v.name}
                text={v.label}
                active={activePath === v.path}
                onClick={() => switchTab(v.path)}
              />
            ))}
          </>
        </AdminPageTabs>
        <div className='pt-0 pb-8 px-8'>
          <Switch>
            <Route path={`${path}/general-setting`}>
              <OrderGeneralSettingPage />
            </Route>
            <Route path={`${path}/order-types`}>
              <OrderTypeSettingPage />
            </Route>
            <Redirect from={`${path}`} to={`${path}/general-setting`} exact />
          </Switch>
        </div>
      </>
    </AdminPage>
  )
}
