import { useState, useEffect } from 'react'

import { Spin } from 'components/Loading'
import SaveChangeBar from 'components/SaveChangeBar'
import { Select } from 'components/Select'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import currency from 'currency.js'
import { PERMISSION } from 'data/permission'
import useDebounce, { compare } from 'hooks/useDebounce'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateRushableTokenApi } from 'redux/accountAndPayout'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { getLocation } from 'redux/location'

import DisplayBlock from './components/DisplayBlock'
import ModalCancel from './components/ModalCancel'
import ModalReviewSubscription from './components/ModalReviewSubscription'
import ModalTipsButton from './components/ModalTipsButton'
import ModelUpdateBankMethod from './components/ModelUpdateBankMethod'
import ModelVerifyBankMethod from './components/ModelVerifyBankMethod'
import { BALANCEOPTION, RECHARGEOPTION } from './helpers/index'

const INITDATA = {
  balance: '',
  recharge: '',
  id: '',
}

const BANKICONARRS = [
  'visa',
  'mastercard',
  'discover',
  'amex',
  'us_bank_account',
]

export default function RushableService() {
  const auth = useAppSelector(state => state.auth.authProfile.data)
  const { locationId = '' } = useParams<TParamTypes>()
  const [baseInfo, setBaseInfo] = useState<Record<string, any>>({})
  const [formData, setFormData] = useState(INITDATA)
  const [formDataInit, setFormDataInit] = useState(INITDATA)
  const [formStatus, setFormStatus] = useState({
    loading: false,
    isDirty: false,
    error: '',
  })
  const dispatch = useAppDispatch()
  const { selectedLocation, getLocationRequest } = useAppSelector(
    state => state.location,
  )
  const isCanUpdateBillingSetting =
    auth?.permissionMap?.[PERMISSION.UPDATEBILLINGSETTING]?.allowed == 1

  const compareFn = useDebounce(compare)

  useEffect(() => {
    compareFn(formData, formDataInit, (flag: boolean) =>
      setFormStatus(prev => ({ ...prev, isDirty: flag })),
    )
  }, [formData, formDataInit])

  useEffect(() => {
    if (!selectedLocation) {
      return
    }
    const {
      rushable_subscription,
      rushable_token,
      online_order_config,
      cancellation_requested_at,
      base_subscription,
      default_payment_method,
      updating_payment_method,
    } = selectedLocation as any
    const card_brand = default_payment_method?.card_brand
    const card_last4 = default_payment_method?.card_last4

    const rushable_plan =
      rushable_subscription?.items?.find(
        (v: any) => v.type === 'rushable_subscription',
      ) || {}

    const services =
      rushable_subscription?.items?.filter(
        (v: any) => v.type === 'rushable_addons',
      ) || []

    const totalService = services.reduce((acc: number, cur: any) => {
      return currency(acc).add(cur.unit_amount)
    }, currency(0)).value

    const nextActionType = updating_payment_method?.next_action?.type || ''

    // 基础信息规整
    const baseInfo = {
      // 需要更改 银行 logo
      bankName: BANKICONARRS.includes(card_brand) ? card_brand : 'unknown',
      bankLast4: card_last4 ? `••• ${card_last4}` : '',
      // bankUpdating.last4 有值的话，就是在 billing account 更新中
      bankUpdating: {
        last4: updating_payment_method?.card_last4
          ? updating_payment_method?.card_last4
          : '',
        microDepositType:
          updating_payment_method?.next_action?.[nextActionType]
            ?.microdeposit_type || '',
        createdAt: updating_payment_method?.created_at,
      },
      balance: rushable_token?.balance ?? '',
      isCancelPending: !!cancellation_requested_at,
      services,
      totalService,
      planName: rushable_plan?.plan_name || '',
      onlineOrderConfig: online_order_config,
      baseSubscription: base_subscription,
    }

    const data = {
      balance: rushable_token?.recharge_when_below || '',
      recharge: rushable_token?.recharge_amount || '',
      id: rushable_token?.id || '',
    }

    setBaseInfo(baseInfo)
    setFormData({ ...data })
    setFormDataInit({ ...data })
  }, [selectedLocation])

  const refreshLocations = () => {
    dispatch(getLocation({ location_id: Number(locationId), refresh: true }))
  }

  const handleUpdateRushable = async () => {
    setFormStatus(prev => ({ ...prev, loading: true }))
    const params = {
      recharge_when_below: formData.balance,
      recharge_amount: formData.recharge,
    }
    try {
      const res = await updateRushableTokenApi(params, locationId, formData.id)
      setFormStatus(prev => ({ ...prev, error: '' }))
      toast.success(res.message)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }
  return (
    <div>
      <Spin spining={getLocationRequest}>
        <>
          {baseInfo.isCancelPending && (
            <div
              className='w-full py-4 mb-4 rounded-lg text-red text-xs font-bold text-center'
              style={{ backgroundColor: 'rgba(239, 74, 65, 0.08)' }}
            >
              Our support team is working on your cancellation process. If you
              have any questions, please contact support@rushable.io
            </div>
          )}
          <div className='flex'>
            <DisplayBlock
              className='mr-8'
              title='Billing Account'
              iconType={baseInfo.bankName}
              mainTxt={
                baseInfo.bankLast4
                  ? baseInfo.bankLast4
                  : baseInfo.bankUpdating?.last4
                  ? `••• ${baseInfo.bankUpdating?.last4}`
                  : ''
              }
              updatingMainTxt={
                baseInfo.bankUpdating?.last4 && baseInfo.bankLast4
                  ? `••• ${baseInfo.bankUpdating?.last4}`
                  : ''
              }
              descTxt='This is the payment method for all your bills'
              button={
                baseInfo.bankUpdating?.microDepositType ? (
                  <ModelVerifyBankMethod
                    bankUpdating={baseInfo.bankUpdating}
                    disabled={!isCanUpdateBillingSetting}
                    onSuccess={refreshLocations}
                  />
                ) : (
                  <ModelUpdateBankMethod
                    disabled={!isCanUpdateBillingSetting}
                    onSuccess={refreshLocations}
                  />
                )
              }
            />
            <DisplayBlock
              title='Base subscription'
              iconType='SubscriptionIcon'
              mainTxt={baseInfo.planName}
              descTxt='This is the base subscription for your basic online ordering solution'
              button={
                <ModalReviewSubscription
                  baseSubscription={baseInfo.baseSubscription}
                  onlineOrderConfig={baseInfo.onlineOrderConfig}
                />
              }
            />
          </div>
          <div className='flex mt-8'>
            <DisplayBlock
              title='Add-on subscription'
              iconType='ComputerIcon'
              mainTxt='Add-ons '
              secondaryTxt={`(Total $${baseInfo.totalService}/mo)`}
              descTxt='This includes all the devices that has additional charge to bill'
              button={<ModalTipsButton type='services' />}
              rightElement={
                <div className='ml-10 text-xs text-lead space-y-3'>
                  {baseInfo.services?.map((item: any, index: number) => {
                    return (
                      <div key={index} className='flex justify-between'>
                        <span>{item.plan_name}</span>
                        <span className='flex-1 h-px border-b border-dashed border-zinc self-center	mx-2'></span>
                        <span>
                          {item.monthly_origin_price !== item.monthly_price && (
                            <span className='line-through	mr-1'>
                              {item.origin_amount && item.monthly_origin_price}
                            </span>
                          )}
                          {item.monthly_price}
                        </span>
                      </div>
                    )
                  })}
                </div>
              }
            />
          </div>
          <div className='flex mt-8'>
            <DisplayBlock
              title='Rushable Token'
              iconType='RushableTokenIcon'
              mainTxt={
                <div className='text-2xl text-ink font-bold mr-1'>
                  {baseInfo.balance} <span className='text-xs'>TOKEN</span>
                </div>
              }
              descTxt='You will need to maintain enough tokens to use premium features'
              button={<ModalTipsButton type='token' />}
              rightElement={
                <div className='ml-10 text-xs text-lead'>
                  <div className='flex items-center mb-3'>
                    <span>When below</span>
                    <Select
                      className='mx-2 w-[100px] text-right'
                      hideIndicator={true}
                      value={formData.balance}
                      options={BALANCEOPTION}
                      onChange={e => {
                        setFormData(prev => ({ ...prev, balance: e }))
                      }}
                    />
                    <span>token balance,</span>
                  </div>
                  <div className='flex items-center'>
                    <span>Recharge</span>
                    <Select
                      value={formData.recharge}
                      hideIndicator={true}
                      className='mx-2 text-right w-[156px]'
                      options={RECHARGEOPTION}
                      onChange={e => {
                        setFormData(prev => ({ ...prev, recharge: e }))
                      }}
                    />
                    <span>automatically.</span>
                  </div>
                </div>
              }
            />
          </div>
          <div className='flex items-center mt-8'>
            <span className='text-base text-ink mr-4'>
              Need to cancel the entire account?
            </span>
            <ModalCancel onSuccess={refreshLocations} />
          </div>
        </>
      </Spin>
      {formStatus.isDirty && (
        <SaveChangeBar
          onConfirm={() => {
            handleUpdateRushable()
          }}
          onCancel={() => {
            setFormStatus(prev => ({ ...prev, isDirty: false })),
              setFormData(formDataInit)
          }}
          error={formStatus.error}
          confirmRequest={formStatus.loading}
        />
      )}
      <UnsavedPrompt when={formStatus.isDirty} />
    </div>
  )
}
