import React, { useState } from 'react'

import { SolidCopy, Check } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import useCopyToClipboard from 'hooks/useCopyToClipboard'

type TCopyBoxProps = {
  className?: string
  text: string
  copyText: string
}

export default function CopyBox({ className, text, copyText }: TCopyBoxProps) {
  const [isCopy, setIsCopy] = useState(false)
  const [, copy] = useCopyToClipboard()

  const handleCopyText = (e: React.MouseEvent) => {
    e.stopPropagation()
    copy(copyText)
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }
  return (
    <div
      className={cn(
        'h-12 px-4 flex justify-between items-center bg-white border-2 rounded-lg cursor-pointer hover:border-blue',
        isCopy ? 'border-blue' : 'border-zinc',
        className,
      )}
      onClick={handleCopyText}
    >
      <span className='text-base text-dark-100 font-normal'>{text}</span>
      <Button color='secondary-link' className={cn('relative')}>
        {isCopy ? (
          <>
            <span className='absolute text-blue right-full p-2 z-50 rounded-lg'>
              Copied
            </span>
            <Check className='text-blue' size={16} />
          </>
        ) : (
          <SolidCopy className='text-blue' size={16} />
        )}
      </Button>
    </div>
  )
}
