import React from 'react'

import cn from 'classnames'

type TTextWithIconLeftProps = {
  className?: string
  icon?: React.ReactNode
  text: string
}

export default function TextWithIconLeft({
  className,
  icon,
  text,
}: TTextWithIconLeftProps) {
  return (
    <div className={cn('text-dark-64 flex items-center', className)}>
      {icon ? icon : null}
      <span
        className={cn('text-sm font-normal text-lead', {
          'pl-1': icon,
        })}
      >
        {text}
      </span>
    </div>
  )
}
