import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { announcementReducer } from './announcement'
import { authReducer } from './auth'
import { brandReducer } from './brand'
import { campaignReducer } from './campaign'
import { couponReducer } from './coupon'
import { customerReducer } from './customer'
import { financialReducer } from './financial'
import { locationReducer } from './location'
import { marketingFlyerReducer } from './marketingFlyer'
import { onlineMenuReducer } from './onlineMenu'
import { orderHistoryReducer } from './orderHistory'
import { smartReviewReducer } from './smartReview'
import { userReducer } from './user'

const store = configureStore({
  reducer: {
    auth: authReducer,
    brand: brandReducer,
    location: locationReducer,
    announcement: announcementReducer,
    user: userReducer,
    marketingFlyer: marketingFlyerReducer,
    coupon: couponReducer,
    campaign: campaignReducer,
    customer: customerReducer,
    financial: financialReducer,
    onlineMenu: onlineMenuReducer,
    smartReview: smartReviewReducer,
    orderHistory: orderHistoryReducer,
  },
  devTools: process.env.NODE_ENV === 'development' ? true : false,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {pastelID: PastelIDState, errorModal: IErrorModalState, ...}
export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
