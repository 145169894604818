import React, { useEffect, useState } from 'react'

import { Plus } from '@rushable/icons'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import { Select } from 'components/Select'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import {
  getJobListApi,
  createJobPostApi,
  postJobPostApi,
  deleteJobPostApi,
} from 'redux/recruiting'

import JobEmpty from './components/JobEmpty'
import JobItemCard from './components/JobItemCard'
import PostJobDrawer from './components/PostJobDrawer'
import { JOB_OPTIONS, TJobPostData } from './helps/constant'

export default function JobListPage(): JSX.Element {
  const { locationId } = useParams<{ locationId: string }>()
  const [hasJob, setHasJob] = useState(true)
  const [jobPosts, setJobPosts] = useState([])
  const [drawerData, setDrawerData] = useState<{
    open: boolean
    id: null | number
  }>({
    open: false,
    id: null,
  })
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [deactivateLoading, setDeactivateLoading] = useState(false)
  const [listLoading, setListLoading] = useState(false)
  const [status, setStatus] = useState('')

  const handleCreateJob = (id?: number) => {
    setDrawerData({
      open: true,
      id: id || null,
    })
  }

  const getJobList = async (nowStatus: string) => {
    try {
      setListLoading(true)
      const res = await getJobListApi(locationId, nowStatus)
      setHasJob(res.has_job_post)
      setJobPosts(res.job_posts)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setListLoading(false)
    }
  }

  const handleConfirmJob = async (params: TJobPostData) => {
    try {
      setConfirmLoading(true)
      if (params.id) {
        await postJobPostApi(locationId, params)
      } else {
        await createJobPostApi(locationId, params)
      }
      setDrawerData({
        open: false,
        id: null,
      })
      await getJobList(status)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleDeleteJob = async (id: number) => {
    try {
      setDeactivateLoading(true)
      await deleteJobPostApi(locationId, id)
      setDrawerData({
        open: false,
        id: null,
      })
      await getJobList(status)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setDeactivateLoading(false)
    }
  }

  const handleStatusChange = (value: string) => {
    setStatus(value)
    if (status !== value) {
      getJobList(value)
    }
  }

  useEffect(() => {
    getJobList(status)
  }, [])

  return (
    <div className='p-8'>
      {hasJob ? (
        <div>
          <div className='flex justify-between'>
            <Select
              className='w-60'
              options={JOB_OPTIONS}
              value={status}
              onChange={value => handleStatusChange(value)}
            />
            <Button color='primary' onClick={() => handleCreateJob()}>
              <Plus size={16} className='mr-2' />
              POST A NEW JOB
            </Button>
          </div>
          <Spin spining={listLoading}>
            <>
              {jobPosts.length === 0 ? (
                <JobEmpty handleCreateJob={handleCreateJob} status={status} />
              ) : null}
              {jobPosts.length > 0 && (
                <div className='mt-6 flex grid grid-cols-3 gap-6 flex-wrap	justify-between'>
                  {jobPosts.map((item: any) => {
                    return (
                      <JobItemCard
                        key={item?.id}
                        item={item}
                        handleOpen={id => {
                          setDrawerData({
                            open: true,
                            id,
                          })
                        }}
                      />
                    )
                  })}
                </div>
              )}
            </>
          </Spin>
        </div>
      ) : (
        <JobEmpty handleCreateJob={handleCreateJob} />
      )}
      <PostJobDrawer
        id={drawerData.id}
        open={drawerData.open}
        confirmLoading={confirmLoading}
        deactivateLoading={deactivateLoading}
        toggle={() =>
          setDrawerData({
            open: false,
            id: null,
          })
        }
        handleConfirmJob={handleConfirmJob}
        handleDeleteJob={handleDeleteJob}
      />
    </div>
  )
}
