// Copied as is from https://usehooks.com/usePrevious/
import { useRef, useEffect } from 'react'

// Hook
function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious
