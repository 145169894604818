import React, { useState, useEffect } from 'react'

import { Loading } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import { Spin } from 'components/Loading'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getLoyaltyConfigAPI,
  getAveragePriceAPI,
  updateLoyaltyConfigAPI,
} from 'redux/brand'
import { useAppSelector } from 'redux/hooks'

import Introduction from './components/Introduction'
import OtherSetting from './components/OtherSetting'
import PointsSetting from './components/PointsSetting'
import RewardsInfo from './components/RewardsInfo'
import RewardsSetting from './components/RewardsSetting'
import SetupLoyaltyRewards from './components/SetupLoyaltyRewards'

export default function LoyaltyRewardPage(): JSX.Element {
  const { selectedBrand } = useAppSelector(state => state.brand)
  const { brandId } = useParams<TParamTypes>()
  const [formData, setFormData] = useState<any>({})
  const [rewardList, setRewardList] = useState<any>([])
  const [pauseModalOpen, setPauseModalOpen] = useState(false)
  const [price, setPrice] = useState('')
  const [loading, setLoading] = useState(false)
  const [pauseLoading, setPauseLoading] = useState(false)

  useEffect(() => {
    getLoyaltyConfig()
  }, [])

  useEffect(() => {
    if (formData.current_step === 'estimated_avg_check_settings') {
      getAveragePrice()
    }
  }, [formData?.current_step])

  const onFormChange = (data: any) => {
    setFormData({
      ...formData,
      ...data,
    })
  }

  const getLoyaltyConfig = async (disableUpdate?: boolean) => {
    setLoading(true)
    try {
      const res = await getLoyaltyConfigAPI(brandId)
      !disableUpdate && setFormData(res.brandLoyaltyConfig)
      setRewardList(res.loyaltyRewardItems)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const getAveragePrice = async () => {
    try {
      const res = await getAveragePriceAPI(brandId)
      setPrice(res.order_average)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
  }

  const handlePauseLoyalty = async () => {
    setPauseLoading(true)
    try {
      const params: any = {
        id: formData.id,
        status: 'active',
      }
      const res = await updateLoyaltyConfigAPI(params, brandId)
      toast.success(res.message)
      onFormChange(res.brandLoyaltyConfig)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setPauseLoading(false)
  }

  return (
    <AdminPage>
      <AdminPageTitle
        title='Loyalty Rewards'
        right={
          formData?.current_step === 'activate' ? (
            formData?.status === 'inactive' ? (
              <div
                className='font-bold text-blue cursor-pointer flex items-center'
                onClick={() => handlePauseLoyalty()}
              >
                {pauseLoading && <Loading size={16} className='mr-2' />} RESUME
                FEATURE
              </div>
            ) : (
              <div
                className='font-bold text-silver cursor-pointer'
                onClick={() => setPauseModalOpen(true)}
              >
                PAUSE FEATURE
              </div>
            )
          ) : (
            <></>
          )
        }
      />

      <Spin
        spining={loading}
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <>
          {selectedBrand && (
            <>
              {selectedBrand.has_pro_package && (
                <div>
                  <>
                    {!formData?.current_step && (
                      <SetupLoyaltyRewards
                        onFormChange={data => onFormChange(data)}
                      />
                    )}
                  </>
                  <>
                    {['introduction', 'estimated_avg_check_settings'].includes(
                      formData?.current_step,
                    ) && (
                      <Introduction
                        price={price}
                        formData={formData}
                        handleUpdatePrice={value => setPrice(value)}
                        onFormChange={data => onFormChange(data)}
                      />
                    )}
                  </>
                  <>
                    {formData?.current_step === 'points_setting' && (
                      <PointsSetting
                        formData={formData}
                        onFormChange={data => onFormChange(data)}
                        updateRewardList={() => getLoyaltyConfig()}
                      />
                    )}
                  </>
                  <>
                    {formData?.current_step === 'rewards_setting' && (
                      <RewardsSetting
                        formData={formData}
                        rewardList={rewardList}
                        onFormChange={data => onFormChange(data)}
                        updateRewardList={() => getLoyaltyConfig(true)}
                      />
                    )}
                  </>
                  <>
                    {formData?.current_step === 'other_setting' && (
                      <OtherSetting
                        formData={formData}
                        onFormChange={data => onFormChange(data)}
                        updateRewardList={() => getLoyaltyConfig()}
                      />
                    )}
                  </>
                  <>
                    {formData?.current_step === 'activate' && (
                      <RewardsInfo
                        open={pauseModalOpen}
                        formData={formData}
                        rewardList={rewardList}
                        onFormChange={data => onFormChange(data)}
                        updateRewardList={() => getLoyaltyConfig()}
                        toggle={() => setPauseModalOpen(false)}
                        // handlePause={() => handlePauseLoyalty()}
                      />
                    )}
                  </>
                </div>
              )}
            </>
          )}
        </>
      </Spin>
    </AdminPage>
  )
}
