import React, { useState } from 'react'

import { SolidImage, LinedTrash, Loading } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Label from 'components/Label'
import { toast } from 'react-toastify'

type Iprops = {
  size?: string
  accept?: string
  limitSize?: number // 上传图片尺寸限制，默认 MB
  className?: string
  labelText?: string | React.ReactElement
  desc?: string | React.ReactElement
  width?: string
  height?: string
  value?: string
  defaultValue?: string
  showTip?: boolean
  onChange?: (blob: Blob, blobUrl: string) => void
  canDelete?: boolean
  onDelete?: () => void
  loading?: boolean
  bgSize?: 'bg-cover' | 'bg-contain' | 'bg-auto'
}

const UploadComps = ({
  size = 'md',
  accept = 'image/*',
  limitSize = 10,
  className = '',
  labelText,
  desc,
  width,
  value,
  defaultValue,
  showTip = true,
  onChange,
  height = '124px',
  canDelete = true,
  onDelete,
  loading,
  bgSize = 'bg-cover',
}: Iprops): JSX.Element => {
  const [image, setImage] = useState(defaultValue || '')
  const [maskShow, setMaskShow] = useState<boolean>(false)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLInputElement>,
  ) => {
    e.preventDefault()
    const files =
      (e as React.ChangeEvent<HTMLInputElement>).target.files ||
      (e as React.DragEvent<HTMLInputElement>).dataTransfer?.files
    if (!files || !files.length) {
      return
    }

    const file = files[0]
    const type = files[0].type
    const currSize = file.size / 1024 / 1024
    if (limitSize && currSize > limitSize) {
      return toast.error(`The maximum image size is ${limitSize} MB`)
    }
    const aBlob = new Blob([file], { type: type || 'image/png' }) // 指定转换成blob的类型
    const preview = URL.createObjectURL(file)
    // 受控组件
    if (value === undefined) {
      setImage(preview)
    }
    onChange && onChange(aBlob, preview)
  }
  const handleDelete = () => {
    onDelete && onDelete()
    if (image) {
      setImage('')
    }
  }
  const imageUrl = value ?? image

  const TipNode = (
    <div className='text-center'>
      <SolidImage size={24} className='text-silver flex justify-center' />
      {showTip && (
        <>
          {desc ? (
            desc
          ) : (
            <>
              <div className='text-xxs font-medium text-silver leading-4'>
                Drag image here
              </div>
              <div className='text-xxs font-medium text-silver'>
                or <span className='text-blue'>Browse</span>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
  const UploadNode = (
    <input
      className='absolute inset-0 z-20 opacity-0 cursor-pointer'
      type='file'
      accept={accept}
      onChange={handleChange}
      onDragEnter={() => setMaskShow(true)}
      onDragLeave={() => setMaskShow(false)}
      onDrop={() => setMaskShow(false)}
    />
  )
  return (
    <div className={className} style={{ fontSize: 0 }}>
      {labelText && (
        <Label className='mb-2 text-xs text-silver'>{labelText}</Label>
      )}
      <div
        className={`${
          size !== 'sm' ? 'rounded-lg' : 'rounded'
        } bg-field p-2 relative overflow-hidden`}
        style={{ width, height }}
      >
        <div
          className={cn(
            'rounded-lg border-zinc box-border border-dashed h-full flex justify-center items-center',
            { border: !imageUrl && showTip },
            { hidden: !!imageUrl },
          )}
        >
          {TipNode}
          {UploadNode}
        </div>
        {imageUrl && (
          <>
            <div
              className={cn(
                'absolute inset-0 z-10 cursor-pointer m-auto bg-no-repeat bg-center',
                bgSize,
              )}
              style={{ backgroundImage: `url(${imageUrl})` }}
              onMouseOver={() => setMaskShow(true)}
            />
            <div
              className={cn(
                'absolute top-0 left-0 w-full h-full z-30 flex justify-center items-center transition duration-300 ease-in-out',
                { 'bg-opacity-96': maskShow && showTip },
                { 'opacity-0': !maskShow },
                { 'opacity-0': loading },
              )}
              onMouseOver={() => setMaskShow(true)}
              onMouseOut={() => setMaskShow(false)}
            >
              {canDelete ? (
                <div className='flex justify-center flex-col'>
                  <div className='relative mb-2.5'>
                    <Button size='sm'>REPLACE IMAGE</Button>
                    {UploadNode}
                  </div>
                  <Button
                    size='sm'
                    color='secondary-link'
                    onClick={handleDelete}
                  >
                    <LinedTrash size={12} className='text-silver mr-1' />
                    <span>REMOVE</span>
                  </Button>
                </div>
              ) : (
                <>
                  {showTip && { TipNode }}
                  {UploadNode}
                </>
              )}
            </div>
          </>
        )}
        {loading && (
          <div
            className={cn(
              'absolute top-0 left-0 w-full h-full z-30 flex justify-center items-center transition duration-300 ease-in-out bg-opacity-64',
            )}
          >
            <Loading size={16} className='text-white' />
          </div>
        )}
      </div>
    </div>
  )
}

export default UploadComps
