import { useState } from 'react'

import SpriteBankCheckPImg from 'assets/img/sprite-bank-check.png'
import Field from 'components/Field'
import { Select } from 'components/Select'
import Tips from 'components/Tips'

import { TFormData } from './ModelUpdateBankMethod'
import { ACCOUNTTYPE } from '../../billingAndInvoice/helpers'

type TUpdateBillingAccountProps = {
  errorText: string
  formData: TFormData
  onFormDataChange: (type: string, value: any) => void
}

export default function UpdateBillingAccount({
  errorText,
  formData,
  onFormDataChange,
}: TUpdateBillingAccountProps) {
  const [fieldFocusType, setFieldFocusType] = useState<
    '' | 'routing' | 'account'
  >('')
  const {
    accountType,
    routingNumber,
    accountNumber,
    repeatAccountNumber,
    name,
    email,
  } = formData
  return (
    <div>
      <div className='flex justify-center relative'>
        <img
          src={SpriteBankCheckPImg}
          className='mb-6'
          width={390}
          height={120}
          alt='Sprite Bank Check Image'
        />
        {fieldFocusType === 'routing' && (
          <div className='absolute bottom-6 left-[93px]'>
            <div className='h-2 border-2 border-blue border-t-0'></div>
            <div className='px-4 text-dark-100'>9 digits</div>
          </div>
        )}
        {fieldFocusType === 'account' && (
          <div className='absolute bottom-6 left-[215px]'>
            <div className='h-2 border-2 border-blue border-t-0'></div>
            <div className='px-4 text-dark-100'>10-12 digits</div>
          </div>
        )}
      </div>
      <div className='flex justify-between space-x-6'>
        <Select
          className='flex-1'
          label='Account type'
          placeholder='Select option'
          options={ACCOUNTTYPE}
          value={accountType}
          onChange={value => onFormDataChange('accountType', value)}
        />
        <Field
          containerClassName='flex-1'
          label='Routing number'
          name='routing-number'
          type='number'
          placeholder='Input here'
          maxLength={9}
          value={routingNumber}
          onBlur={() => setFieldFocusType('')}
          onClick={() => setFieldFocusType('routing')}
          onChange={e => {
            onFormDataChange('routingNumber', e.target.value)
          }}
        />
      </div>
      <div className='mt-6 flex justify-between space-x-6'>
        <Field
          containerClassName='flex-1'
          label='Account number'
          name='account-number'
          type='number'
          placeholder='Input here'
          maxLength={12}
          value={accountNumber}
          onBlur={() => setFieldFocusType('')}
          onClick={() => setFieldFocusType('account')}
          onChange={e => {
            onFormDataChange('accountNumber', e.target.value)
          }}
        />
        <Field
          containerClassName='flex-1'
          label='Repeat account number'
          name='repeat-account-number'
          type='number'
          placeholder='Input here'
          maxLength={12}
          value={repeatAccountNumber}
          onBlur={() => setFieldFocusType('')}
          onClick={() => setFieldFocusType('account')}
          onChange={e => {
            onFormDataChange('repeatAccountNumber', e.target.value)
          }}
        />
      </div>
      <Field
        containerClassName='mt-6'
        label='Account holder name'
        name='account-holder-name'
        type='text'
        placeholder='Input here'
        value={name}
        onChange={e => {
          onFormDataChange('name', e.target.value)
        }}
      />
      <Field
        containerClassName='mt-6'
        label='Contact email'
        name='contact-email'
        type='text'
        placeholder='Input here'
        value={email}
        onChange={e => {
          onFormDataChange('email', e.target.value)
        }}
      />
      {errorText && <Tips className='mt-6'>{`⚠️ ${errorText}`}</Tips>}
    </div>
  )
}
