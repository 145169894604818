import { useState } from 'react'

import { DuoToneCompany } from '@rushable/icons'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import { PERMISSION } from 'data/permission'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { getLocation } from 'redux/location'

import { ModalHelp } from './GetHelp'
import {
  UpdateAddressModal,
  UpdateInformationModal,
} from './LegalOverviewModal'
import { LegalStatus, statusInit } from '../helps'
import type { ILegalstatus } from '../helps'

export default function LegalOverview() {
  const [isShowEntity, setIsShowEntity] = useState(false)
  const [isShowAddress, setIsShowAddress] = useState(false)
  const [isShowInformation, setIsShowInformation] = useState(false)
  const { selectedLocation, getLocationRequest } = useAppSelector(
    state => state.location,
  )
  const auth = useAppSelector(state => state.auth.authProfile.data)
  const isCanUpdateBusiness =
    auth?.permissionMap?.[PERMISSION.UPDATEEMAILADDRESS]?.allowed == 1

  const { locationId } = useParams<TParamTypes>()

  const dispatch = useAppDispatch()

  const reloadLocation = () => {
    dispatch(getLocation({ location_id: Number(locationId), refresh: true }))
  }

  const mailAd = selectedLocation?.mailing_address || {}
  const legal_name = selectedLocation?.legal_name || ''
  const status = (selectedLocation?.legal_entity_status || '') as ILegalstatus
  const currentStatus = status ? LegalStatus[status] : statusInit
  return (
    <>
      <Spin spining={getLocationRequest}>
        <div className='mb-6'>
          <div className='flex w-full h-[166px] p-6 border border-zinc rounded-lg'>
            <div className='flex-1 relative'>
              <div className='text-xs text-silver mb-2'>
                Legal business entity
              </div>
              <div className='flex items-center'>
                <DuoToneCompany size={24} />
                <span className='ml-2 text-base font-bold'>{legal_name}</span>
              </div>
              <Button
                color='secondary'
                size='sm'
                onClick={() => setIsShowEntity(true)}
                className='absolute left-0 bottom-0'
              >
                CHANGE ENTITY
              </Button>
            </div>
            <div className='flex-1 relative pl-8 border-x border-dashed border-zinc'>
              <div className='text-xs text-silver mb-2'>Mailing address</div>
              <div className='text-xs leading-[18px]'>
                {mailAd.line1 || ''}
                {mailAd.line2 ? `, ${mailAd.line2}` : ''}
              </div>
              <div className='text-xs leading-[18px]'>
                {mailAd.city ? `${mailAd.city}, ` : ''}
                {`${mailAd.state || ''} ${mailAd.postal_code || ''}`}
              </div>
              <Button
                color='secondary'
                size='sm'
                className='absolute left-[32px] bottom-0'
                onClick={() => setIsShowAddress(true)}
              >
                CHANGE ADDRESS
              </Button>
            </div>
            <div className='flex-1 pl-8 relative'>
              <div className='text-xs text-silver mb-2'>
                Verification status
              </div>
              <div className='text-xs leading-[18px]'>
                {currentStatus.label}
                <span className='text-lead'>
                  {currentStatus.value ? ` • ${currentStatus.value}` : ''}
                </span>
              </div>
              {currentStatus.isHaveBlue && (
                <Button
                  size='sm'
                  disabled={!isCanUpdateBusiness}
                  className='absolute left-[32px] bottom-0'
                  onClick={() => setIsShowInformation(true)}
                >
                  SUBMIT INFORMATION
                </Button>
              )}
            </div>
          </div>
        </div>
      </Spin>

      <ModalHelp
        type='changeEntity'
        open={isShowEntity}
        toggle={() => setIsShowEntity(false)}
      />

      <UpdateAddressModal
        open={isShowAddress}
        toggle={() => setIsShowAddress(false)}
        onSuccess={() => {
          setIsShowAddress(false)
          reloadLocation()
        }}
      />

      <UpdateInformationModal
        open={isShowInformation}
        toggle={() => setIsShowInformation(false)}
        onSuccess={() => {
          setIsShowInformation(false)
          reloadLocation()
        }}
      />
    </>
  )
}
