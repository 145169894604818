import React from 'react'

import Button from 'components/Button'
import EmptyIcon from 'components/EmptyIcon'

type TListEmptyProps = {
  openModal: () => void
}

export default function ListEmpty({ openModal }: TListEmptyProps) {
  return (
    <div className='flex flex-col justify-center items-center'>
      <p className='text-center text-base text-lead mt-[120px] mb-8'>
        You have not activated this feature yet...
      </p>
      <EmptyIcon />
      <Button className='mt-6' color='primary' onClick={openModal}>
        CONFIGURE NOW
      </Button>
    </div>
  )
}
