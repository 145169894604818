import React, { useState, useEffect, useRef } from 'react'

import cn from 'classnames'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import Label from 'components/Label'
import { BRAND_COLOR } from 'data/constant'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { ColorResult } from 'react-color'
export type TFieldProp = {
  value?: string
  label?: string
  name?: string
  inputLabel?: string
  colors?: string[]
  onChange?: (value: string) => void
}

export default function FieldColor({
  label,
  name,
  value = '',
  colors = BRAND_COLOR,
  onChange,
}: TFieldProp): JSX.Element {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState(value || '')

  const ref = useRef(null)

  useEffect(() => {
    setColor(value)
  }, [value])

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }
  useOnClickOutside(ref, handleClose)

  const handleChange = (color: string | ColorResult) => {
    const colorValue = typeof color === 'string' ? color : color.hex
    setColor(colorValue)
    onChange && onChange(colorValue)
  }
  return (
    <div className='relative'>
      {label && (
        <Label className='flex mb-2 text-xs' htmlFor={name} color='silver'>
          {label}
        </Label>
      )}

      <button
        className={cn([
          'relative flex justify-between items-center text-base px-4 py-3 border-2 rounded-lg w-full',
          'bg-slate border-slate hover:border-blue hover:bg-white',
        ])}
        onClick={handleClick}
      >
        <span>{color}</span>
        <div
          className='w-6 h-6 rounded-md bg-zinc'
          style={{ backgroundColor: color }}
        ></div>
      </button>

      {displayColorPicker ? (
        <div className='absolute top-20 right-0 z-20' ref={ref}>
          <ColorPicker
            color={color}
            onChange={handleChange}
            presetColors={colors}
            pickColor={handleChange}
          />
        </div>
      ) : null}
    </div>
  )
}
