import type { TCoupon } from 'redux/marketingFlyer'

export const getCouponTitle = (coupon: TCoupon | undefined) => {
  let showTitle = ''
  let title = ''
  if (!coupon) {
    return {
      showTitle,
      title,
    }
  }
  const {
    type,
    threshold,
    amount_off,
    percentage_off,
    description,
    first_order_only,
  } = coupon
  const desc = first_order_only === 0 ? 'Regular coupon' : 'New customer only'
  if (type === 'flat') {
    showTitle = `${desc}・Get $${
      amount_off || 0
    } off when order $${threshold} above`
    title = `・Get $${amount_off || 0} off when order $${threshold} above`
  }
  if (type === 'percentage') {
    showTitle = `${desc}・Get ${
      percentage_off || 0
    }% off when order $${threshold} above`
    title = `Get ${percentage_off || 0}% off when order $${threshold} above`
  }
  if (type === 'free_item') {
    showTitle = `${desc}・Get ${
      description || '-'
    } when order $${threshold} above`
    title = `Get ${description || '-'} when order $${threshold} above`
  }
  return {
    showTitle,
    title,
  }
}
