export interface TFlyerColorOptions {
  readonly value: string
  readonly label: string
  readonly code: string
}

export const flyerColorOptions: TFlyerColorOptions[] = [
  {
    value: '#F15B5B',
    label: 'Red',
    code: 'C0 / M80 / Y60 / K0',
  },
  {
    value: '#F58345',
    label: 'Orange',
    code: 'C0 / M60 / Y80 / K0',
  },
  {
    value: '#FFCC32',
    label: 'Yellow',
    code: 'C0 / M20 / Y90 / K0',
  },
  {
    value: '#6DC067',
    label: 'Green',
    code: 'C60 / M0 / Y80 / K0',
  },
  {
    value: '#00BFDF',
    label: 'Cyan',
    code: 'C70 / M0 / Y10 / K0',
  },
  {
    value: '#009EDB',
    label: 'Blue',
    code: 'C80 / M20 / Y0 / K0',
  },
  {
    value: '#EF5BA1',
    label: 'Pink',
    code: 'C0 / M80 / Y0 / K0',
  },
  {
    value: '#F0EAD8',
    label: 'Neutral',
    code: 'C5 / M5 / Y15 / K0',
  },
]

export const FLYER_PROMO_OFFER = [
  {
    value: 0,
    label: 'No special offer',
  },
  {
    value: 1,
    label: 'Attach an offer',
  },
]

export const FLYER_OPTIONS = [
  {
    value: 'default',
    label: 'Template: Default version',
    text: 'Try our new online ordering available directly on our website for pickup and delivery! You’ll save yourself from high service fees while helping our restaurant avoid 25-30% in commissions charged by the 3rd party platforms (DoorDash, UberEats, GrubHub, etc). We appreciate your support! ',
  },
  {
    value: 'lower_menu',
    label: 'Template: Mention lower menu pricing',
    text: 'Enjoy lower menu prices and save money by not being charged outrageous service fees on the 3rd party platforms (DoorDash, UberEats, GrubHub, etc) by ordering directly on our website for pickup or delivery! Thanks for your support!',
  },
  {
    value: 'promo_code',
    label: 'Template: Mention promo code offer',
    text: 'Receive a discount on your next pickup or delivery order by going directly to our website and using the promo code below! It’ll save you from paying high service fees and it’ll save us from high commissions charged by 3rd party marketplaces. We appreciate your support!',
  },
  {
    value: 'custom',
    label: 'Custom editing',
    text: '',
  },
]
