import React, { useEffect } from 'react'

import Drawer from 'components/Drawer'
import LocationSelector from 'components/RestaurantSelector/LocationSelector'
import SkeletonLocation from 'components/RestaurantSelector/SkeletonLocation'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getLocations } from 'redux/location'
import { TBrand } from 'types/brand'
import { TLocation } from 'types/location'

export type TLocationSelectDrawerProp = {
  open: boolean
  toggle: () => void
  onChange: (e: boolean) => void
}

export default function LocationSelectDrawer({
  open,
  toggle,
  onChange,
}: TLocationSelectDrawerProp): JSX.Element {
  const history = useHistory()
  const { brandId, locationId } = useParams<TParamTypes>()
  const { selectedBrand } = useAppSelector(state => state.brand)
  const { locations } = selectedBrand as TBrand
  const dispatch = useAppDispatch()
  useEffect(() => {
    const brand_id = Number(brandId)
    if (locations?.length === 0) {
      dispatch(getLocations({ brand_id }))
    } else {
      if (locations && locations[0].brand_id !== brand_id) {
        dispatch(getLocations({ brand_id }))
      }
    }
  }, [locations, brandId])

  async function onSelect(newLocation: TLocation) {
    onChange(true)
    let path = location.pathname.split(`${locationId}`)[1]
    if (path.includes('order-history')) {
      path = '/order-history'
    }
    if (path.includes('online-menu')) {
      path = '/online-menu'
    }
    if (path.includes('account-and-payout')) {
      path = '/account-and-payout/service'
    }
    if (path.includes('outbound-campaign')) {
      path = '/outbound-campaign/one-off-campaign'
    }
    if (path.includes('customer-data')) {
      path = '/customer-data'
    }
    if (path.includes('users')) {
      path = '/users'
    }
    await history.push(`/brand/${brandId}/location/${newLocation.id}${path}`)
    onChange(false)
    toggle()
  }
  return (
    <Drawer
      open={open}
      title='Choose Location'
      size='sm'
      position='left'
      toggle={toggle}
    >
      <>
        {locations?.length === 0 && (
          <>
            <SkeletonLocation />
            <SkeletonLocation />
            <SkeletonLocation />
            <SkeletonLocation />
          </>
        )}

        {locations
          ?.filter(v => v.access)
          ?.map(location => (
            <LocationSelector
              key={location.id}
              location={location}
              onSelect={onSelect}
            />
          ))}
      </>
    </Drawer>
  )
}
