import React, { useEffect, useState, useRef } from 'react'

import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import {
  SolidCalendar,
  AngleLeft,
  SolidTrashCan,
  Dollar,
} from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import { Spin } from 'components/Loading'
import ModalFull from 'components/Modal/ModalFull'
import SaveChangeBar from 'components/SaveChangeBar'
import { Select } from 'components/Select'
import TextArea from 'components/TextArea'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import Upload from 'components/Upload/Upload'
import useDebounce, { compare } from 'hooks/useDebounce'
import useOnClickOutside from 'hooks/useOnClickOutside'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { Calendar } from 'react-date-range'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'redux/hooks'
import {
  setLockModalOpen,
  createMenuItemAPI,
  showMenuItemAPI,
  editMenuItemAPI,
  getFoodTagList,
  deleteMenuItemAPI,
} from 'redux/onlineMenu'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

import ChangeModifier from './ChangeModifier'
import MenuDetailModifierItem from './MenuDetailModifierItem'
import MenuDetailPriceItem from './MenuDetailPriceItem'
import ModifierDrawer from './ModifierDrawer'
import {
  PRICING_MODE,
  AVAILABILITY_BULK_EDIT,
  REPEAT_ALLOW,
  MENU_LOCKED_STATUS,
} from '../helpers/constant'

export default function MenuDetailPage(): JSX.Element {
  const baseFormData = {
    name: '',
    description: '',
    image_url: '',
    status: 'availability',
    reactive_at: '',
    menu_tags: [],
    pricing_mode: 'simple_pricing',
    simple_pricing: '0.00',
    variable_pricing_list: [],
    modifier_list: [],
    contains_alcohol: 0,
  }
  const dispatch = useAppDispatch()
  const [formData, setFormData] = useState<Record<string, any>>(baseFormData)
  const [formDataInit, setFormDataInit] =
    useState<Record<string, any>>(baseFormData)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [deleteInfo, setDeleteInfo] = useState({
    open: false,
    value: '',
    loading: false,
  })
  const [error, setError] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initRequest, setInitRequest] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [openModifierDrawer, setOpenModifierDrawer] = useState(false)
  const [modifierInfo, setModifierInfo] = useState({ open: false, id: 0 })
  const [menuActionInfo, setMenuActionInfo] = useState({ type: '', index: -1 })
  const [initMenuTags, setInitMenuTags] = useState<any>([])
  const [stockList, setStockList] = useState(cloneDeep(AVAILABILITY_BULK_EDIT))
  const ref = useRef(null)
  const imgRef = useRef<Record<'logo', any>>({
    logo: null,
  })
  const { menuItemId, locationId } = useParams<TParamTypes>()
  const history = useHistory()
  const compareFn = useDebounce(compare)
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(mouseSensor)

  const handleFormChange = (
    name: string,
    value: any,
    key?: string,
    index?: number,
  ) => {
    if (key) {
      const newFormData = cloneDeep(formData)
      const new_pricing_list = [...newFormData[name]]
      new_pricing_list[index || 0][key] = value
      setFormData({ ...formData, variable_pricing_list: new_pricing_list })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  useEffect(() => {
    if (error) {
      setError('')
    }
    compareFn(formData, formDataInit, (flag: boolean) => setFormIsDirty(flag))
  }, [formData, formDataInit])

  useEffect(() => {
    if (location.href.split('?type=')[1] !== 'create') {
      getMenuItemDetail()
      setIsEdit(true)
    }
    getFoodTag()
  }, [menuItemId])

  const handleChangeMenu = (
    method: string,
    type: string,
    index: number,
    id?: number,
  ) => {
    switch (method) {
      case 'open':
        setMenuActionInfo(
          menuActionInfo.type === type && menuActionInfo.index === index
            ? { type: '', index: -1 }
            : { type, index },
        )
        break
      case 'edit':
        setMenuActionInfo({ type: '', index: -1 })
        setModifierInfo({ open: true, id: id || 0 })
        break
      case 'delete':
        setMenuActionInfo({ type: '', index: -1 })
        deleteOptions(type, index)
        break
    }
  }

  const getMenuItemDetail = async () => {
    setInitRequest(true)
    try {
      const params = {
        menu_item_id: menuItemId,
      }
      const res = await showMenuItemAPI(params, locationId)
      const pricing_mode =
        res.menu_modifiers[0]?.private_type === 'pricing'
          ? 'variable_pricing'
          : 'simple_pricing'

      const menu_tags: any = []
      res.menu_tags.map((tagItem: { name: string }) => {
        menu_tags.push(tagItem.name)
      })

      let variable_pricing_list: any = []
      if (pricing_mode === 'variable_pricing') {
        variable_pricing_list = [...res.menu_modifiers[0].menu_modifier_options]
      }

      let modifier_list: any = []
      const idList: any = []
      modifier_list = res.menu_modifiers.reduce(
        (newArr: any[], next: { id: string | number }) => {
          if (!idList.includes(next.id)) {
            idList.push(next.id)
            newArr.push(next)
          }
          return newArr
        },
        [],
      )

      if (pricing_mode === 'variable_pricing') {
        modifier_list = [...modifier_list.slice(1)]
      } else {
        modifier_list = [...modifier_list]
      }

      if (res.status === 'out_of_order') {
        const newStockList = [...stockList]
        newStockList[1].value = res.reactive_at
          ? `Restock on ${moment(res.reactive_at).format('MM/DD/YYYY')}`
          : 'restock'
        newStockList[1].label = res.reactive_at
          ? `Restock on ${moment(res.reactive_at).format('MM/DD/YYYY')}`
          : 'Restock on a date'
        setStockList([...newStockList])
      }

      const baseFormData = {
        name: res.name,
        description: res.description || '',
        image_url: parseMediaAbsoluteURL(res.image_url),
        status:
          res.status === 'active'
            ? 'availability'
            : res.status === 'out_of_order'
            ? res.reactive_at
              ? `Restock on ${moment(res.reactive_at).format('MM/DD/YYYY')}`
              : 'restock'
            : 'make_inactive',
        simple_pricing:
          pricing_mode === 'simple_pricing' ? res.unit_price : '0.00',
        contains_alcohol: res.contains_alcohol > -1 ? res.contains_alcohol : '',
        variable_pricing_list,
        modifier_list,
        pricing_mode,
        menu_tags,
      }
      setFormData(baseFormData)
      setFormDataInit(baseFormData)
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setInitRequest(false)
  }

  const getFoodTag = async () => {
    try {
      const res = await getFoodTagList()
      const tagList: any = []
      res.map((tagItem: { name: string; id: number }) => {
        tagList.push({
          id: tagItem.id,
          label: tagItem.name,
          value: tagItem.name,
        })
      })
      setInitMenuTags([...tagList])
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
  }

  const deleteOptions = (type: string, index: number) => {
    if (type === 'price') {
      handleFormChange(
        'variable_pricing_list',
        formData['variable_pricing_list'].filter(
          (_: any, i: number) => i !== index,
        ),
      )
    }
    if (type === 'modifier') {
      handleFormChange(
        'modifier_list',
        formData['modifier_list'].filter((_: any, i: number) => i !== index),
      )
    }
  }

  const onDragEnd = (props: any, type: string) => {
    setMenuActionInfo({ type: '', index: -1 })
    const { active, over } = props
    if (!active || !over) {
      return null
    }
    const list =
      type === 'price'
        ? formData.variable_pricing_list || []
        : formData.modifier_list || []
    const activeIndex = list.findIndex((item: any) => item.id === active.id)
    const overIndex = list.findIndex((item: any) => item.id === over.id)
    const itemList = arrayMove(list, activeIndex, overIndex)
    handleFormChange(
      type === 'price' ? 'variable_pricing_list' : 'modifier_list',
      itemList,
    )
  }

  const addPricingOption = () => {
    let id = 0
    formData.variable_pricing_list.map((v: any) => {
      if (String(v?.id).length < 8 && v?.id > id) {
        id = v.id
      }
    })

    const item = {
      name: '',
      unit_price: '',
      status: 'active',
    }
    const optionList = [
      ...formData['variable_pricing_list'],
      { ...item, id: id + 1 },
    ]
    handleFormChange('variable_pricing_list', optionList)
  }

  const changePricingMode = (value: string) => {
    if (
      value === 'variable_pricing' &&
      formData.variable_pricing_list.length < 2
    ) {
      const item = {
        name: '',
        unit_price: '',
        status: 'active',
      }
      const optionList = [
        ...formData['variable_pricing_list'],
        { ...item, id: 1 },
        { ...item, id: 2 },
      ]
      setFormData({
        ...formData,
        variable_pricing_list: optionList,
        pricing_mode: value,
      })
    } else {
      setFormData({
        ...formData,
        variable_pricing_list: formDataInit.variable_pricing_list,
        pricing_mode: value,
      })
    }
  }

  const submitFormData = () => {
    editMenuItem()
  }

  const editMenuItem = async () => {
    setLoading(true)
    try {
      const newFormData = new FormData()
      newFormData.append('name', formData.name)
      formData.description &&
        newFormData.append('description', formData.description)
      newFormData.append(
        'price_type',
        formData.pricing_mode === 'simple_pricing' ? 'simple' : 'variable',
      )
      const { logo } = imgRef.current || {}
      if (logo?.blob) {
        newFormData.append('image', logo?.blob)
      } else if (formDataInit.image_url) {
        newFormData.append('image_url', formData.image_url || '')
      }

      newFormData.append('contains_alcohol', formData.contains_alcohol)
      newFormData.append(
        'status',
        formData.status === 'availability'
          ? 'active'
          : formData.status === 'make_inactive'
          ? 'inactive'
          : 'out_of_order',
      )

      if (formData.status.includes('restock')) {
        newFormData.append('reactive_at', '')
      }

      if (formData.status.includes('Restock')) {
        let date = formData.status.split('Restock on ')[1]
        date = moment(date).format('YYYY-MM-DD hh:mm:ss')
        newFormData.append('reactive_at', date)
      }

      formData.menu_tags.forEach((tagItem: any, index: number) => {
        let id = 0
        initMenuTags.forEach((initTagItem: any) => {
          if (tagItem === initTagItem.value) {
            id = initTagItem.id
          }
        })
        newFormData.append(`menu_tag_ids[${index}]`, String(id))
      })

      formData.modifier_list.forEach((modifierItem: any, index: number) => {
        newFormData.append(`menu_modifier_ids[${index}]`, modifierItem.id)
      })

      if (formData.pricing_mode === 'variable_pricing') {
        formData.variable_pricing_list.forEach((item: any, index: number) => {
          newFormData.append(`variable_prices[${index}][name]`, item.name)
          newFormData.append(
            `variable_prices[${index}][unit_price]`,
            item.unit_price,
          )
          newFormData.append(
            `variable_prices[${index}][status]`,
            String(item.status === 'active' ? 1 : 0),
          )
        })
      } else {
        newFormData.append('unit_price', formData.simple_pricing)
      }

      if (isEdit) {
        const res = await editMenuItemAPI(
          Number(menuItemId),
          newFormData,
          locationId,
        )
        toast.success(res.message)
      } else {
        newFormData.append('menu_category_id', String(menuItemId))

        const res = await createMenuItemAPI(newFormData, locationId)
        toast.success(res.message)
        setFormIsDirty(false)
        history.go(-1)
      }
      setFormDataInit(cloneDeep(formData))
    } catch (e: any) {
      const msg = e?.message
      e?.status === MENU_LOCKED_STATUS
        ? dispatch(setLockModalOpen(true))
        : toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  const handleUpdateMenu = async () => {
    setDeleteInfo({ ...deleteInfo, loading: true })
    try {
      const params = {
        menu_item_id: menuItemId,
        locationId,
      }
      const res = await deleteMenuItemAPI(params)
      toast.success(res.message)
      setFormIsDirty(false)
      history.go(-1)
    } catch (e: any) {
      const msg = e?.message
      e?.status === MENU_LOCKED_STATUS
        ? dispatch(setLockModalOpen(true))
        : toast.error(msg || 'request error')
    }
    setDeleteInfo({ ...deleteInfo, loading: false, open: false })
  }

  const handleClickOutside = () => {
    if (formData.status.includes('restock')) {
      handleFormChange('status', formDataInit.status)
    }
    setOpenCalendar(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  return (
    <>
      <UnsavedPrompt when={formIsDirty} />
      {formIsDirty && (
        <SaveChangeBar
          confirmText='SAVE CHANGE'
          error={error}
          confirmRequest={loading}
          onConfirm={() => submitFormData()}
          onCancel={() => {
            setFormData({ ...formDataInit })
          }}
        />
      )}
      <AdminPage>
        <AdminPageTitle
          title={formData.name}
          left={
            <Button color='secondary-link' onClick={() => history.go(-1)}>
              <AngleLeft className='mr-1 text-silver' size={16} />
              GO BACK
            </Button>
          }
          right={
            <>
              {isEdit && (
                <Button
                  color='secondary-link'
                  onClick={() => setDeleteInfo({ ...deleteInfo, open: true })}
                >
                  DELETE
                  <SolidTrashCan className='ml-1 text-silver' size={16} />
                </Button>
              )}
            </>
          }
        />
        <Spin spining={initRequest}>
          <div className='flex divide-x divide-solid divide-zinc min-h-[calc(100vh-100px)]'>
            <div className='w-[50%] p-8'>
              <div className='font-bold'>Item Information</div>
              <Field
                containerClassName='my-6'
                name='name'
                type='text'
                label='Name'
                value={formData.name}
                onChange={e => handleFormChange('name', e.target.value)}
              />
              <Label className='block'>Description</Label>
              <TextArea
                containerClass='mt-2'
                inputClass='h-[144px] resize-none'
                name='description'
                value={formData.description || ''}
                onChange={e => handleFormChange('description', e.target.value)}
              />
              <div className='flex my-6'>
                <div>
                  <Upload
                    width='236px'
                    height='150px'
                    labelText='Image'
                    value={formData.image_url}
                    onChange={(blob, blobUrl) => {
                      handleFormChange('image_url', blobUrl)
                      imgRef.current.logo = { blob, blobUrl }
                    }}
                    onDelete={() => {
                      handleFormChange('image_url', '')
                      imgRef.current.logo = null
                    }}
                  ></Upload>
                </div>
                <div className='flex-1 relative ml-6' ref={ref}>
                  <Select
                    options={stockList}
                    label='Stock'
                    controlIcon={
                      <SolidCalendar size={16} className='text-silver mr-2' />
                    }
                    value={formData.status}
                    onChange={value => {
                      value.includes('stock') && setOpenCalendar(true)
                      handleFormChange('status', value)
                    }}
                  />
                  {formData.status.includes('stock') && openCalendar && (
                    <Calendar
                      className={'absolute z-10'}
                      editableDateInputs={true}
                      onChange={date => {
                        handleFormChange(
                          'status',
                          `Restock on ${moment(date).format('MM/DD/YYYY')}`,
                        )
                        const newStockList = [...stockList]
                        newStockList[1].value = `Restock on ${moment(
                          date,
                        ).format('MM/DD/YYYY')}`
                        newStockList[1].label = `Restock on ${moment(
                          date,
                        ).format('MM/DD/YYYY')}`
                        setStockList([...newStockList])
                        setOpenCalendar(false)
                      }}
                      date={formData.reactive_at ?? new Date()}
                      direction='horizontal'
                      rangeColors={['#008DFF']}
                      dateDisplayFormat='yyyy-MM-dd'
                      minDate={new Date()}
                    />
                  )}
                  <Select
                    className='w-full mt-6'
                    options={REPEAT_ALLOW}
                    label='Is an alcohol item?'
                    value={formData.contains_alcohol}
                    onChange={value =>
                      handleFormChange('contains_alcohol', value)
                    }
                  />
                </div>
              </div>
              <Select
                options={initMenuTags}
                label='Food tag'
                isMulti={true}
                placeholder='Select Food tag'
                value={formData.menu_tags}
                onChange={value => handleFormChange('menu_tags', value)}
              />
            </div>
            <div className='w-[50%] p-8'>
              <div className='font-bold'>Item Price</div>
              <div
                className={`flex mt-6 ${
                  formData.pricing_mode === 'simple_pricing' && 'mb-8'
                }`}
              >
                <Select
                  className='w-full'
                  options={PRICING_MODE}
                  label='Pricing mode'
                  value={formData.pricing_mode}
                  onChange={value => changePricingMode(value)}
                />
                {formData.pricing_mode === 'simple_pricing' && (
                  <Field
                    containerClassName='ml-6'
                    name='amount'
                    type='tel'
                    label='Amount'
                    inputMode='money'
                    suffix={<Dollar className='text-silver' size={16} />}
                    align='right'
                    value={formData.simple_pricing}
                    onChangeMoney={value =>
                      handleFormChange('simple_pricing', value)
                    }
                  />
                )}
              </div>
              {formData.pricing_mode === 'variable_pricing' && (
                <div className='mt-4 flex flex-col'>
                  <DndContext
                    sensors={sensors}
                    onDragEnd={prop => onDragEnd(prop, 'price')}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                  >
                    <SortableContext
                      disabled={loading}
                      items={formData.variable_pricing_list || []}
                    >
                      {formData.variable_pricing_list.map(
                        (priceItem: any, index: number) => {
                          return (
                            <MenuDetailPriceItem
                              key={index}
                              index={index}
                              item={priceItem}
                              menuActionInfo={menuActionInfo}
                              hideAction={
                                formData.variable_pricing_list.length > 1
                              }
                              handleChangeMenu={(method, type, index, id) =>
                                handleChangeMenu(method, type, index, id)
                              }
                              handleFormChange={(name, value, key, index) =>
                                handleFormChange(name, value, key, index)
                              }
                              setMenuActionInfo={({ type, index }) =>
                                setMenuActionInfo({ type, index })
                              }
                            />
                          )
                        },
                      )}
                    </SortableContext>
                  </DndContext>

                  <Button
                    color='tertiary'
                    className='mt-2 mb-8'
                    onClick={() => addPricingOption()}
                  >
                    ADD VARIANT
                  </Button>
                </div>
              )}
              <div className='pt-8 border-t border-dashed border-zinc'>
                <div className='flex flex-col space-y-4'>
                  <div className='font-bold'>Applied modifier</div>

                  {!!formData.modifier_list.length && (
                    <div className='flex flex-col space-y-2'>
                      <DndContext
                        sensors={sensors}
                        onDragEnd={prop => onDragEnd(prop, 'modifier')}
                        modifiers={[
                          restrictToVerticalAxis,
                          restrictToWindowEdges,
                        ]}
                      >
                        <SortableContext
                          disabled={loading}
                          items={formData.modifier_list || []}
                        >
                          {formData.modifier_list.map(
                            (modifierItem: any, index: number) => {
                              return (
                                <MenuDetailModifierItem
                                  key={index}
                                  index={index}
                                  item={modifierItem}
                                  menuActionInfo={menuActionInfo}
                                  handleChangeMenu={(method, type, index, id) =>
                                    handleChangeMenu(method, type, index, id)
                                  }
                                  setMenuActionInfo={({ type, index }) =>
                                    setMenuActionInfo({ type, index })
                                  }
                                />
                              )
                            },
                          )}
                        </SortableContext>
                      </DndContext>
                    </div>
                  )}

                  <Button
                    color='tertiary'
                    className='w-full'
                    onClick={() => setOpenModifierDrawer(true)}
                  >
                    CHOOSE MODIFIER
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </AdminPage>
      <ModalFull
        title='Delete Menu Item'
        open={deleteInfo.open}
        toggle={() => setDeleteInfo({ ...deleteInfo, open: false, value: '' })}
        okBtnDisabled={deleteInfo.value !== 'Delete'}
        onOk={() => handleUpdateMenu()}
        okBtnColor={'warning'}
        loading={deleteInfo.loading}
        onCancel={() =>
          setDeleteInfo({ ...deleteInfo, open: false, value: '' })
        }
      >
        <div className='text-center mb-6'>
          {`This action will permanently delete “${formData.name}”. You will not be able
                to restore this after the deletion.`}
        </div>
        <Field
          name='Delete'
          label='Type “Delete” below to confirm this action'
          type='text'
          value={deleteInfo.value}
          placeholder='Delete'
          onChange={e =>
            setDeleteInfo({ ...deleteInfo, value: e.target.value })
          }
        />
      </ModalFull>
      <ChangeModifier
        open={modifierInfo.open}
        id={modifierInfo.id}
        toggle={value => {
          setModifierInfo({ ...modifierInfo, open: false })
          value && getMenuItemDetail()
        }}
      />
      <ModifierDrawer
        open={openModifierDrawer}
        formData={formData}
        toggle={() => setOpenModifierDrawer(false)}
        onChange={data => handleFormChange('modifier_list', data)}
      />
    </>
  )
}
