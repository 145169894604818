import { createReducer } from '@reduxjs/toolkit'

import { getUser, setCodeErrorMsg, setValidateErrorMsg } from './actions'

const initialState = {
  getUserRequest: false,
  getUserError: '',
  selectedUser: null,
  getUsersRequest: false,
  users: [],
  getUsersError: '',
  codeErrorMsg: '',
  validateErrorMsg: '',
}

export const userReducer = createReducer(initialState, builder => {
  builder
    .addCase(getUser.pending, state => {
      state.getUserRequest = true
      state.getUserError = ''
    })
    .addCase(getUser.fulfilled, (state, action) => {
      state.getUserRequest = false
      const { success, data, message } = action.payload || {}
      if (success) {
        state.selectedUser = data
      } else {
        state.getUserError = message
        state.selectedUser = null
      }
    })
    .addCase(getUser.rejected, (state, action) => {
      state.selectedUser = null
      if (action.error.message) {
        state.getUserError = action.error.message
      }
    })
    .addCase(setValidateErrorMsg, (state, action) => {
      state.validateErrorMsg = action.payload
    })
    .addCase(setCodeErrorMsg, (state, action) => {
      state.codeErrorMsg = action.payload
    })
})

export default userReducer
