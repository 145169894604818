import axios from 'utils/axiosApi'

export async function getJobListApi(
  locationId: string,
  status: string,
  ignoreCache = true,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_posts`,
    method: 'get',
    params: {
      status: status || null,
    },
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function createJobPostApi(
  locationId: string,
  params: any,
  ignoreCache = false,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_posts`,
    method: 'post',
    params,
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function postJobPostApi(
  locationId: string,
  params: any,
  ignoreCache = false,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_posts/${params.id}`,
    method: 'patch',
    params,
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function deleteJobPostApi(
  locationId: string,
  id: number,
  ignoreCache = false,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_posts/${id}`,
    method: 'delete',
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function getJobPostDataApi(
  locationId: string,
  id: number,
  ignoreCache = true,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_posts/${id}`,
    method: 'get',
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function getJobCategoriesApi(
  locationId: string,
  ignoreCache = true,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_categories`,
    method: 'get',
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function getJobTitlesApi(brandId: string, ignoreCache = true) {
  const response = await axios.request({
    url: `brands/${brandId}/job_titles`,
    method: 'get',
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function getJobApplicationsApi(
  locationId: string,
  params: any,
  ignoreCache = true,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_applications`,
    method: 'get',
    params,
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function getJobApplicationDataApi(
  locationId: string,
  id: number,
  ignoreCache = true,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_applications/${id}`,
    method: 'get',
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function updateJobApplicationApi(
  locationId: string,
  id: number,
  status: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/job_applications/${id}`,
    method: 'put',
    params: {
      status,
    },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function deleteJobApplicationApi(locationId: string, id: number) {
  const response = await axios.request({
    url: `locations/${locationId}/job_applications/${id}`,
    method: 'delete',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
