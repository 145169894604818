export const BASE_PREP_TIME_OPTIONS = [
  { value: 5, label: '5 minutes' },
  { value: 10, label: '10 minutes' },
  { value: 15, label: '15 minutes' },
  { value: 20, label: '20 minutes' },
  { value: 30, label: '30 minutes' },
  { value: 45, label: '45 minutes' },
]

export const ALLOW_LATER_ORDER_OPTIONS = [
  { label: 'ASAP only', value: 0 },
  { label: 'Same day', value: 1 },
  { label: 'Within 6 days', value: 6 },
]

export const ALLOW_CUSTOMER_NOTE_OPTIONS = [
  { label: 'No, not allowed.', value: 0 },
  { label: 'Yes, allowed.', value: 1 },
]

export const BUSINESS_HOURS_OPTIONS = [
  { value: 'same_as_store', label: 'Follow business hours' },
  { value: 'customize', label: 'Custom hours' },
]

export const LAST_CALL_SCHEDULE_OPTIONS = [
  { value: 0, label: 'Follow business hours' },
  { value: 15, label: '15 minutes before closing' },
  { value: 30, label: '30 minutes before closing' },
  { value: 45, label: '45 minutes before closing' },
  { value: 60, label: '60 minutes before closing' },
  { value: 75, label: '75 minutes before closing' },
  { value: 90, label: '90 minutes before closing' },
]

export const DELIVERY_METHOD_OPTIONS = [
  { value: 'on_demand', label: 'On-demand delivery' },
  { value: 'in_house', label: 'In-house delivery' },
  { value: 'dynamic', label: 'Both on-demand and in-house delivery' },
]

export const PRIORITIZED_METHOD_OPTIONS = [
  { value: 'on_demand', label: 'On-demand delivery' },
  { value: 'in_house', label: 'In-house delivery' },
]
