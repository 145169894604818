import { currencyFormatter } from 'utils/digit'

type TCampaignROIProps = {
  theROI: number | null
  perDollarROI: string | number
  status: string
}

export default function CampaignROI({
  theROI,
  perDollarROI,
  status,
}: TCampaignROIProps) {
  return (
    <div className='w-[230px] mr-16'>
      <div className='text-xs text-ink font-bold pb-2'>Campaign ROI</div>
      {status === 'complete' ? (
        <div className='text-blue text-[32px] leading-8 font-bold pb-6'>
          {theROI}%
        </div>
      ) : (
        <div className='text-orange text-[32px] leading-8 font-bold pb-6'>
          Pending
        </div>
      )}
      <div className='text-xs text-ink font-bold pb-2'>
        What does this mean?
      </div>
      {status === 'complete' ? (
        <div
          className='text-xs text-ink'
          style={{
            lineHeight: '18px',
          }}
        >
          It means for every $1.00 worth of token you spend, you get{' '}
          {currencyFormatter(Number(perDollarROI))} back as your revenue
          {`${theROI && theROI > 1000 ? ', this is CRAZY!' : '.'}`}
        </div>
      ) : (
        <div
          className='text-xs text-ink'
          style={{
            lineHeight: '18px',
          }}
        >
          This campaign is still being processed and does not have enough data
          to show ROI result, please check back later.
        </div>
      )}
    </div>
  )
}
