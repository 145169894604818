import React, { useState, useEffect } from 'react'

import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import RewardItemCard from 'components/RewardItemCard'
import RewardItemSetupModal from 'components/RewardItemSetupModal'
import Switch from 'components/Switch'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  updateLoyaltyConfigAPI,
  getLoyaltyMenuItemsAPI,
} from 'redux/brand/actions'

type TRewardsInfoProp = {
  open: boolean
  formData: any
  rewardList: any
  onFormChange: (data: any) => void
  updateRewardList: () => void
  toggle: () => void
}

export default function RewardsInfo({
  open,
  formData,
  rewardList,
  onFormChange,
  updateRewardList,
  toggle,
}: TRewardsInfoProp): JSX.Element {
  const [pauseText, setPauseText] = useState('')
  const [switchLoading, setSwitchLoading] = useState(false)
  const [pauseLoading, setPauseLoading] = useState(false)
  const { brandId } = useParams<TParamTypes>()
  const [menuItems, setMenuItems] = useState<any>([])
  const [modalInfo, setModalInfo] = useState({
    type: '',
    open: false,
    data: {},
  })

  useEffect(() => {
    getLoyaltyMenuItems()
  }, [])

  const handleChangeForm = (data: any) => {
    setModalInfo({
      type: modalInfo.type,
      open: modalInfo.open,
      data: {
        ...modalInfo.data,
        ...data,
      },
    })
  }

  const getLoyaltyMenuItems = async () => {
    try {
      const res = await getLoyaltyMenuItemsAPI(brandId)
      setMenuItems(res)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
  }

  const updateStatus = async (type: string, value?: number) => {
    type === 'coupon' ? setSwitchLoading(true) : setPauseLoading(true)
    try {
      let params: any = {
        id: formData.id,
      }
      if (type === 'coupon') {
        params = { ...params, can_use_with_coupon: value }
      }
      if (type === 'pause') {
        params = {
          ...params,
          status: 'inactive',
        }
      }
      const res = await updateLoyaltyConfigAPI(params, brandId)
      toast.success(res.message)
      onFormChange(res.brandLoyaltyConfig)
      if (type === 'pause') {
        setPauseText('')
        toggle()
      }
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    type === 'coupon' ? setSwitchLoading(false) : setPauseLoading(false)
  }

  return (
    <div>
      {formData.status === 'inactive' && (
        <div className='bg-red-opacity-8 px-2 py-4 flex justify-center items-center'>
          <p className='max-w-[800px] text-red-darken text-center text-base'>
            The feature is currently paused, which means customers are not able
            to access their earned points or redeem rewards, and they cannot
            earn new points during this time.
          </p>
        </div>
      )}

      <div className='flex'>
        <div
          style={{
            minHeight: 'calc(100vh - 140px)',
          }}
          className='p-8 w-[400px] border-r border-solid border-zinc'
        >
          <div className='font-bold text-lead text-xl'>Rewards</div>
          <RewardItemCard
            className='w-full mt-6'
            type={'add'}
            onAddClick={() =>
              setModalInfo({
                type: 'add',
                open: true,
                data: {},
              })
            }
          />
          {rewardList.map((item: any, i: number) => {
            return (
              <RewardItemCard
                key={i}
                className='w-full mt-6'
                type={'default'}
                title={item.name}
                rank={item.minimum_rank}
                pts={item.spent_points}
                image_url={item.image_url}
                redeemed={item.redeem}
                disabled={item.status === 'inactive'}
                onAddClick={() =>
                  setModalInfo({
                    type: 'update',
                    open: true,
                    data: item,
                  })
                }
              />
            )
          })}
        </div>
        <div className='p-8 flex-1'>
          <div className='font-bold text-lead text-xl'>General Rules</div>
          <div className='mt-6 w-full p-6 border border-solid border-zinc rounded-lg flex justify-between items-center text-ink'>
            <div>
              Allow customers use both a reward and a coupon at the same time
            </div>
            <Switch
              className='ml-2'
              name='coupon'
              loading={switchLoading}
              checked={!!formData.can_use_with_coupon}
              onChange={value => updateStatus('coupon', Number(value))}
            />
          </div>
          <div className='mt-4 w-full border border-solid border-zinc rounded-lg'>
            <div className='px-6 py-4 border-b border-dashed border-zinc flex justify-between items-center text-ink'>
              <div>Expiration schedule for points earned by your customers</div>
              <div className='text-lead font-bold text-lg ml-2 whitespace-nowrap'>
                {formData.expiration_month} months
              </div>
            </div>
            <div className='px-6 py-4 border-b border-dashed border-zinc flex justify-between items-center text-ink'>
              <div>
                <p>Points needed before the expiration date to rank up</p>
                <p className='text-xs text-lead'>
                  Points by every $1 spend in subtotal:{' '}
                  <span style={{ color: '#CA8642' }} className='font-bold'>
                    Bronze 10 pts
                  </span>
                  ,{' '}
                  <span style={{ color: '#999FAA' }} className='font-bold'>
                    Silver 11 pts
                  </span>
                  ,{' '}
                  <span style={{ color: '#FFBB08' }} className='font-bold'>
                    Gold 13 pts
                  </span>
                </p>
              </div>
              <div className='text-lead font-bold text-lg ml-2 whitespace-nowrap'>
                {formData.ranking_step} pts
              </div>
            </div>
            <div className='px-6 py-4 flex justify-between items-center text-ink'>
              <div>
                Points to grant when there is a new customer account signup
              </div>
              <div className='text-lead font-bold text-lg ml-2 whitespace-nowrap'>
                {formData.customer_initial_points} pts
              </div>
            </div>
          </div>
        </div>
        <RewardItemSetupModal
          type={modalInfo.type}
          rewardData={modalInfo.data}
          open={modalInfo.open}
          menuItems={menuItems}
          updateRewardList={() => updateRewardList()}
          onFormChange={data => handleChangeForm(data)}
          toggle={() => setModalInfo({ type: '', open: false, data: {} })}
        />
        <ModalFull
          open={open}
          title='Pause Loyalty Rewards'
          toggle={() => {
            setPauseText('')
            toggle()
          }}
          loading={pauseLoading}
          okBtnClassName='w-32'
          okBtnColor='warning'
          okText='Pause'
          cancelText='Cancel'
          okBtnDisabled={pauseText !== 'Pause'}
          onOk={() => updateStatus('pause')}
        >
          <div className='text-center text-ink text-base mb-6'>
            After pausing the feature, all reward points and redemptions for
            your customers will be put on hold. This means customers who have
            already earned points will not be able to redeem them until the
            feature is reactivated. Additionally, customers will not be able to
            access or view their reward points and history during this time. Are
            you sure you want to pause the feature?
          </div>
          <Field
            label='Type “Pause” to continue'
            name='pause'
            placeholder='Pause'
            type='text'
            className='w-full'
            onChange={e => setPauseText(e.target.value)}
            value={pauseText}
          />
        </ModalFull>
      </div>
    </div>
  )
}
