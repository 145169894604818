import React, { useState, useEffect, useRef } from 'react'

import {
  BadgeBronze,
  BadgeSilver,
  BadgeGold,
  LinedTrash,
  Loading,
} from '@rushable/icons'
import CheckBox from 'components/CheckBox'
import Field from 'components/Field'
import Label from 'components/Label'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import TabChangeBar from 'components/TabChangeBar'
import Upload from 'components/Upload/Upload'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  saveLoyaltyRewardsAPI,
  updateLoyaltyRewardsAPI,
  deleteLoyaltyRewardsAPI,
} from 'redux/brand/actions'

import { urlToBase64, dataURLtoBlob } from '../../utils/formatTool'

export const POINTS_NEED_LIST = [
  {
    label: '250 pts',
    value: '250',
  },
  {
    label: '500 pts',
    value: '500',
  },
  {
    label: '750 pts',
    value: '750',
  },
  {
    label: '1000 pts',
    value: '1000',
  },
  {
    label: '1250 pts',
    value: '1250',
  },
  {
    label: '1500 pts',
    value: '1500',
  },
  {
    label: '1750 pts',
    value: '1750',
  },
  {
    label: '2000 pts',
    value: '2000',
  },
  {
    label: '2500 pts',
    value: '2500',
  },
  {
    label: '3000 pts',
    value: '3000',
  },
]

type TRewardItemSetupModalProp = {
  type: string
  menuItems: any
  rewardData: any
  open: boolean
  updateRewardList: () => void
  onFormChange: (data: any) => void
  toggle: () => void
}

export default function RewardItemSetupModal({
  type,
  open,
  rewardData,
  menuItems,
  updateRewardList,
  onFormChange,
  toggle,
}: TRewardItemSetupModalProp): JSX.Element {
  const { brandId } = useParams<TParamTypes>()
  const [customName, setCustomName] = useState('')
  const [selectMenuList, setSelectMenuList] = useState<any>([])
  const [selectMenuItem, setSelectMenuItem] = useState<any>({})
  const [pointList, setPointList] = useState<any>([])
  const [checkValue, setCheckValue] = useState('bronze')
  const [loading, setLoading] = useState(false)
  const [inactiveLoading, setInactiveLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('Existing menu item')
  const imgRef = useRef<Record<'logo', any>>({
    logo: null,
  })

  useEffect(() => {
    const menuList: any = []
    menuItems.map((v: any) => {
      menuList.push({ value: v.name, label: v.name })
    })
    setSelectMenuList(menuList)
  }, [menuItems])

  useEffect(() => {
    if (open && Object.keys(rewardData).length) {
      setCheckValue(rewardData.minimum_rank)
      setActiveTab(
        rewardData.type === 'existing'
          ? 'Existing menu item'
          : 'Create custom reward',
      )
      let price = ''
      menuItems.map((v: any) => {
        if (v.name === rewardData.name) {
          price = v.unit_price
        }
      })
      setSelectMenuItem({ ...rewardData, unit_price: price })
      rewardData.type === 'custom' && setCustomName(rewardData.name)
    }
  }, [open])

  useEffect(() => {
    if (selectMenuItem.unit_price) {
      let point: any = null
      let newPointItem: any = null
      const price = selectMenuItem.unit_price * 100
      const new_points_list = [...POINTS_NEED_LIST]
      point = new_points_list.sort(
        (a, b) =>
          Math.abs(price - Number(a.value)) - Math.abs(price - Number(b.value)),
      )[0]
      newPointItem = {
        value: point.value,
        label: `${point.label} (Recommended)`,
      }

      const newPointList: any = []
      POINTS_NEED_LIST.map((v: any) => {
        if (v.value === newPointItem.value) {
          newPointList.push(newPointItem)
        } else {
          newPointList.push(v)
        }
      })

      newPointList.sort((a: any, b: any) => {
        return a.value - b.value
      })
      setPointList(newPointList)
    }
  }, [selectMenuItem])

  const handleUpdateReward = async () => {
    setLoading(true)
    try {
      const formData = new FormData()

      formData.append(
        'name',
        activeTab === 'Existing menu item' ? rewardData.name : customName,
      )
      formData.append('minimum_rank', checkValue)
      formData.append(
        'type',
        activeTab === 'Existing menu item' ? 'existing' : 'custom',
      )
      formData.append('spent_points', rewardData.spent_points)
      const { logo } = imgRef.current || {}

      let res: any = {}
      if (type === 'add') {
        if (logo?.blob) {
          formData.append('image', logo?.blob)
        } else if (selectMenuItem.image_url) {
          let blob: any = null
          await urlToBase64(selectMenuItem.image_url)
            .then(res => {
              blob = dataURLtoBlob(res)
            })
            .catch(() => {
              setLoading(false)
            })
          formData.append('image', blob)
        }
        res = await saveLoyaltyRewardsAPI(formData, brandId)
      } else {
        formData.append('status', 'active')
        if (logo?.blob) {
          formData.append('image', logo?.blob)
        } else if (selectMenuItem.image_url) {
          formData.append('image_url', selectMenuItem.image_url)
        }
        res = await updateLoyaltyRewardsAPI(formData, rewardData.id, brandId)
      }
      toast.success(res.message)
      updateRewardList()
      handleHideModal()
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  const deleteLoyaltyReward = async () => {
    setInactiveLoading(true)
    try {
      const res = await deleteLoyaltyRewardsAPI(brandId, rewardData.id)
      toast.success(res.message)
      updateRewardList()
      handleHideModal()
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setInactiveLoading(false)
  }

  const handleHideModal = () => {
    setSelectMenuItem({})
    setCheckValue('bronze')
    setCustomName('')
    setPointList([])
    setActiveTab('Existing menu item')
    onFormChange({ image_url: '' })
    imgRef.current.logo = null
    toggle()
  }

  return (
    <ModalFull
      title='Setup Reward Item'
      open={open}
      toggle={() => handleHideModal()}
      okBtnDisabled={
        (activeTab === 'Create custom reward'
          ? !customName
          : !rewardData.name) || !rewardData.spent_points
      }
      loading={loading}
      onOk={() => handleUpdateReward()}
      okText={
        type === 'update' && rewardData.status === 'inactive'
          ? 'REACTIVATE'
          : 'CONFIRM'
      }
      cancelText='CANCEL'
      okBtnClassName={
        type === 'update' && rewardData.status === 'inactive'
          ? '!border-blue text-blue'
          : ''
      }
      onCancel={() => handleHideModal()}
      okBtnColor={
        type === 'update' && rewardData.status === 'inactive'
          ? 'tertiary'
          : 'primary'
      }
      iconFooter={
        type === 'update' && rewardData.status !== 'inactive' ? (
          <div
            className='flex cursor-pointer'
            onClick={() => deleteLoyaltyReward()}
          >
            {inactiveLoading ? (
              <Loading size={16} color='#B0B8BE' />
            ) : (
              <LinedTrash size={16} color='#B0B8BE' />
            )}

            <div className='ml-2 font-bold text-silver'>MAKE INACTIVE</div>
          </div>
        ) : (
          <></>
        )
      }
    >
      <div className='text-ink text-center mb-6'>
        When selecting points needed for each reward item, remember that every
        10 points is equal to $1.00 that the customer has spent. For example,
        500 points means the customer has spent $50 in the past and can now
        redeem an item with 500 points.
      </div>
      <>
        {type === 'add' && (
          <TabChangeBar
            items={['Existing menu item', 'Create custom reward']}
            activeItem={activeTab}
            className={'w-full mb-6'}
            onClick={v => {
              setActiveTab(v)
              onFormChange({ name: '' })
            }}
          />
        )}
      </>

      <div className='flex space-x-6'>
        <div className='flex-1 flex flex-col space-y-4'>
          {activeTab === 'Existing menu item' ? (
            <Select
              label='Menu item'
              value={rewardData.name || ''}
              options={selectMenuList}
              placeholder='Select menu item'
              disabled={type === 'update'}
              onChange={value => {
                const item = menuItems.filter((v: any) => v.name === value)
                setSelectMenuItem(item[0])
                onFormChange({ name: value, image_url: item.image_url })
              }}
            />
          ) : (
            <Field
              name='name'
              label='Reward item name'
              type='text'
              value={customName || ''}
              disabled={type === 'update'}
              placeholder='e.g. Small Chicken Soup'
              onChange={e => setCustomName(e.target.value)}
            />
          )}

          <Select
            label='Points needed'
            value={String(rewardData.spent_points) || ''}
            options={
              activeTab === 'Existing menu item' && selectMenuItem.unit_price
                ? pointList
                : POINTS_NEED_LIST
            }
            placeholder='Select'
            onChange={value => {
              onFormChange({ spent_points: value })
            }}
          />
        </div>
        <Upload
          width='158px'
          height='140.83px'
          labelText='Image'
          value={
            activeTab === 'Existing menu item' && selectMenuItem.image_url
              ? selectMenuItem.image_url
              : rewardData.image_url
          }
          onChange={(blob, blobUrl) => {
            if (selectMenuItem.image_url) {
              setSelectMenuItem({ ...selectMenuItem, image_url: '' })
            }
            onFormChange({ image_url: blobUrl })
            imgRef.current.logo = { blob, blobUrl }
          }}
          onDelete={() => {
            if (selectMenuItem.image_url) {
              setSelectMenuItem({ ...selectMenuItem, image_url: '' })
            }
            onFormChange({ image_url: '' })
            imgRef.current.logo = null
          }}
        ></Upload>
      </div>
      <div className='flex flex-col space-y-3 mt-6'>
        <Label>Rank required</Label>
        <div className='w-full border border-solid border-zinc rounded-lg p-4 flex justify-between'>
          <CheckBox
            size={16}
            checkBoxType='circle'
            checked={checkValue === 'bronze'}
            onChange={() => setCheckValue('bronze')}
            shape='circle'
          >
            <div className='text-ink ml-[10px]'>
              Available for Bronze or above
            </div>
          </CheckBox>
          <BadgeBronze size={24} />
        </div>
        <div className='w-full border border-solid border-zinc rounded-lg p-4 flex justify-between'>
          <CheckBox
            size={16}
            checkBoxType='circle'
            checked={checkValue === 'silver'}
            onChange={() => setCheckValue('silver')}
            shape='circle'
          >
            <div className='text-ink ml-[10px]'>
              Available for Silver or above
            </div>
          </CheckBox>
          <BadgeSilver size={24} />
        </div>
        <div className='w-full border border-solid border-zinc rounded-lg p-4 flex justify-between'>
          <CheckBox
            size={16}
            checkBoxType='circle'
            checked={checkValue === 'gold'}
            onChange={() => setCheckValue('gold')}
            shape='circle'
          >
            <div className='text-ink ml-[10px]'>Available for Gold only</div>
          </CheckBox>
          <BadgeGold size={24} />
        </div>
      </div>
    </ModalFull>
  )
}
