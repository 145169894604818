import React, { CSSProperties } from 'react'

import { CustomPicker, Color, ColorChangeHandler } from 'react-color'
import {
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common'

export type TMyPickerProp = {
  color: Color
  onChange: ColorChangeHandler
  presetColors: string[] | undefined
  pickColor: (res: string) => void
}

export const MyPicker = ({
  onChange,
  color,
  presetColors,
  pickColor,
  ...props
}: TMyPickerProp) => {
  const isWhite = (c: Color) => {
    if (typeof c === 'string') {
      return c.toUpperCase() === '#FFF' || c.toUpperCase() === '#FFFFFF'
    }
    return false
  }

  const styles = {
    hue: {
      height: 10,
      borderRadius: 5,
      overflow: 'hidden',
      margin: '16px 16px',
      position: 'relative',
      marginBottom: 10,
    } as CSSProperties,
    saturation: {
      width: '100%',
      height: 100,
      position: 'relative',
    } as CSSProperties,
    input: {
      height: 36,
      border: '1px solid #F5F6FA',
      marginLeft: 10,
      borderRadius: '20px',
      padding: '4px 16px',
      fontSize: '16px',
      lineHeight: '16px',
      width: '120px',
      backgroundColor: '#F5F6FA',
    } as CSSProperties,
    swatch: {
      width: 36,
      height: 36,
      background: color ? color : '#fff',
      borderRadius: 36,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: !color || isWhite(color) ? '#eee' : color,
    } as CSSProperties,
  }

  return (
    <div className='bg-white rounded-xl shadow-2xl pb-4 w-[200px] overflow-hidden'>
      <div style={styles.saturation}>
        <Saturation {...props} color={color} onChange={onChange} />
      </div>
      <div style={styles.hue}>
        <Hue {...props} color={color} onChange={onChange} />
      </div>

      <div className='flex items-center px-4'>
        <div style={styles.swatch} />
        <EditableInput
          style={{ input: styles.input }}
          value={color}
          onChange={onChange}
        />
      </div>

      <div className='grid grid-cols-5 gap-2 px-4 pt-4'>
        {presetColors &&
          presetColors.map((pColor, index) => (
            <div
              className='w-6 h-6 rounded-full mx-auto cursor-pointer hover:opacity-80'
              style={{ backgroundColor: pColor }}
              role='button'
              onClick={() => pickColor(pColor)}
              key={index}
            ></div>
          ))}
      </div>
    </div>
  )
}

export default CustomPicker(MyPicker)
