import React from 'react'

import { LinedTrash } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import { TimeSelect } from 'components/Select'
import { TOpenHour } from 'types/openHour'

export type TDayHoursFieldProp = {
  index: number
  dayHours: TOpenHour
  onChange: (name: string, value: string) => void
  addHours: () => void
  removeHours: () => void
}

export default function DayHoursField({
  index,
  dayHours,
  onChange,
  addHours,
  removeHours,
}: TDayHoursFieldProp): JSX.Element {
  const { from, to } = dayHours
  return (
    <div
      className={cn(
        'flex justify-center items-center space-x-2',
        index > 0 ? 'mt-3' : '',
      )}
    >
      <div className='flex w-[270px] justify-center items-center space-x-2'>
        <TimeSelect
          value={from || ''}
          hideIndicator={true}
          onChange={value => onChange('from', value)}
          className='w-[120px]'
        />
        <span className='text-silver'>to</span>
        <TimeSelect
          hideIndicator={true}
          value={to || ''}
          onChange={value => onChange('to', value)}
          className='w-[120px]'
        />
      </div>
      <Button
        className='w-[50px]'
        size='sm'
        color='primary-link'
        onClick={() => addHours()}
      >
        + HOURS
      </Button>
      <Button color='ink-link' className='ml-2' onClick={() => removeHours()}>
        <LinedTrash size={16} />
      </Button>
    </div>
  )
}
