import cn from 'classnames'
import { SUPPORT_EMAIL } from 'data/constant'

import styles from './terms.module.scss'
export default function PrivacyPolicy(): JSX.Element {
  return (
    <div className='container max-w-3xl mx-auto'>
      <div className='py-12'>
        <article
          className={cn(styles.terms, 'text-lead text-justify leading-6')}
        >
          <h1>Privacy Policy</h1>
          <p className='text-center'>Last updated on: April 01, 2020. </p>

          <h2>1. Introduction</h2>

          <p>
            Welcome to&nbsp;<strong>Rushable, Inc</strong>.
          </p>

          <p>
            Rushable, Inc&nbsp;(“<strong>Rushable</strong>”, “
            <strong>us</strong>”, “<strong>we</strong>”, or “
            <strong>our</strong>”) operates&nbsp;rushable.io and its associated
            sub-domain websites, and Rushable mobile
            application&nbsp;(hereinafter referred to as “
            <strong>Service</strong>”).
          </p>

          <p>
            Our Privacy Policy governs your visits to&nbsp;https://rushable.io
            and its associated sub-domain websites, and Rushable mobile
            application, and explains how we collect, safeguard, and disclose
            information that results from your use of our Service.&nbsp;
          </p>

          <p>
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms of Service.
          </p>

          <p>
            Our{' '}
            <a
              href='/terms-of-service'
              target='_blank'
              rel='noreferrer noopener'
            >
              Terms of Service
            </a>{' '}
            (“<strong>Terms</strong>”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“<strong>Agreement</strong>”).
          </p>

          <h2>2. Definitions</h2>

          <p>
            <strong>SERVICE&nbsp;</strong>means&nbsp;https://rushable.io and its
            associated sub-domain websites, and Rushable mobile
            application&nbsp;operated by&nbsp;Rushable, Inc.
          </p>

          <p>
            <strong>PERSONAL DATA</strong>&nbsp;means data about a living
            individual who can be identified from those data (or from those and
            other information either in our possession or likely to come into
            our possession).
          </p>

          <p>
            <strong>USAGE DATA</strong>&nbsp;is data collected automatically
            either generated by the use of Service or from Service
            infrastructure itself (for example, the duration of a page visit).
          </p>

          <p>
            <strong>COOKIES</strong>&nbsp;are small files stored on your device
            (computer or mobile device).
          </p>

          <p>
            <strong>DATA CONTROLLER</strong>&nbsp;means a natural or legal
            person who (either alone or jointly or in common with other persons)
            determines the purposes for which and the manner in which any
            personal data are, or are to be, processed. For the purpose of this
            Privacy Policy, we are a Data Controller of your data.
          </p>

          <p>
            <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong>
            &nbsp;means any natural or legal person who processes the data on
            behalf of the Data Controller. We may use the services of various
            Service Providers in order to process your data more effectively.
          </p>

          <p>
            <strong>DATA SUBJECT&nbsp;</strong>is any living individual who is
            the subject of Personal Data.
          </p>

          <p>
            <strong>THE USER&nbsp;</strong>is the individual using our Service.
            The User corresponds to the Data Subject, who is the subject of
            Personal Data.
          </p>

          <h2>3. Information Collection and Use</h2>

          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>

          <h2>4. Types of Data Collected</h2>

          <h4>Personal Data</h4>

          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“<strong>Personal Data</strong>”). Personally
            identifiable information may include, but is not limited to:
          </p>

          <ol>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
          </ol>

          <p>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us&nbsp;by following the unsubscribe link.
          </p>

          <h4>Usage Data</h4>

          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through a mobile
            device (“<strong>Usage Data</strong>”).
          </p>

          <p>
            This Usage Data may include information such as your computer’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>

          <p>
            When you access Service with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you
            use, unique device identifiers and other diagnostic data.
          </p>

          <h4>Location Data</h4>

          <p>
            We may use and store information about your location if you give us
            permission to do so (“<strong>Location Data</strong>”). We use this
            data to provide features of our Service, to improve and customize
            our Service.
          </p>

          <p>
            You can enable or disable location services when you use our Service
            at any time by way of your device settings.
          </p>

          <h4>Tracking Cookies Data</h4>

          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>

          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>

          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>

          <p>Examples of Cookies we use:</p>

          <ol>
            <li>
              <strong>Session Cookies:</strong>&nbsp;We use Session Cookies to
              operate our Service.
            </li>
            <li>
              <strong>Preference Cookies:</strong>&nbsp;We use Preference
              Cookies to remember your preferences and various settings.
            </li>
            <li>
              <strong>Security Cookies:</strong>&nbsp;We use Security Cookies
              for security purposes.
            </li>
            <li>
              <strong>Advertising Cookies:</strong>&nbsp;Advertising Cookies are
              used to serve you with advertisements that may be relevant to you
              and your interests.
            </li>
          </ol>

          <h4>Other Data</h4>

          <p>
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at the place of residence and
            actual address, telephone number (work, mobile), details of
            documents on education, qualification, professional training,
            employment agreements, non-disclosure agreements, information on
            bonuses and compensation, information on marital status, family
            members, social security (or other taxpayer identification) number,
            office location, and other data.
          </p>

          <h2>5. Use of Data</h2>

          <p>
            Rushable, Inc&nbsp;uses the collected data for various purposes:
          </p>

          <ol>
            <li>to provide and maintain our Service;&nbsp;</li>
            <li>to notify you about changes to our Service;&nbsp;</li>
            <li>
              to allow you to participate in interactive features of our Service
              when you choose to do so;&nbsp;
            </li>
            <li>to provide customer support;&nbsp;</li>
            <li>
              to gather analysis or valuable information so that we can improve
              our Service;&nbsp;
            </li>
            <li>to monitor the usage of our Service;</li>
            <li>to detect, prevent and address technical issues;</li>
            <li>to fulfill any other purpose for which you provide it;</li>
            <li>
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </li>
            <li>
              to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </li>
            <li>
              to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information;
            </li>
            <li>
              in any other way we may describe when you provide the information;
            </li>
            <li>for any other purpose with your consent.&nbsp;</li>
          </ol>

          <h2>6. Retention of Data</h2>

          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>

          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>

          <h2>7. Transfer of Data</h2>

          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>

          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>

          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>

          <p>
            Rushable, Inc&nbsp;will take all the steps reasonably necessary to
            ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organization or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>

          <h2>8. Disclosure of Data</h2>

          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>

          <h4>Disclosure for Law Enforcement:</h4>

          <p>
            Under certain circumstances, we may be required to disclose your
            Personal Data if required to do so by law or in response to valid
            requests by public authorities.
          </p>

          <h4>Business Transaction:</h4>

          <p>
            If we or our subsidiaries are involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred.
          </p>

          <h4>Other cases. We may disclose your information also:</h4>

          <ol>
            <li>to our subsidiaries and affiliates;&nbsp;</li>
            <li>
              to contractors, service providers, and other third parties we use
              to support our business;
            </li>
            <li>to fulfill the purpose for which you provide it;&nbsp;</li>
            <li>
              for the purpose of including your company’s logo on our
              website;&nbsp;
            </li>
            <li>
              for any other purpose disclosed by us when you provide the
              information;&nbsp;
            </li>
            <li>with your consent in any other cases;&nbsp;</li>
            <li>
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others.
            </li>
          </ol>
          <h2>9. Security of Data</h2>

          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h2>
            10. Your Data Protection Rights under the California Privacy
            Protection Act (CalOPPA)
          </h2>

          <p>
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require a person or
            company in the United States (and conceivable the world) that
            operates websites collecting personally identifiable information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals with whom it is being shared and to comply with
            this policy. – See more at:{' '}
            <a
              href='https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
            </a>
            &nbsp;
          </p>

          <p>According to CalOPPA we agree to the following:</p>

          <ol>
            <li>users can visit our site anonymously;&nbsp;</li>
            <li>
              our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the page specified above on the home page of
              our website;
            </li>
            <li>
              users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </li>
            <li>
              users are able to change their personal information by emailing us
              at support@rushable.io.&nbsp;
            </li>
          </ol>

          <p>How we respond to “Do Not Track” Signals:</p>

          <p>
            Some internet browsers have incorporated “Do Not Track” features.
            Most of these features, when turned on, send a signal or preference
            (the “DNT” signal) to the web sites you visit indicating that you do
            not wish to be tracked. Because there is not yet a common
            understanding of how to interpret DNT Signals, nor a common
            definition of “tracking”, we do not currently respond to DNT
            Signals.{' '}
          </p>
          <h2>
            11. Your Data Protection Rights under the California Consumer
            Privacy Act (CCPA)
          </h2>

          <p>
            If you are a California resident, you are entitled to learn what
            data we collect about you, ask to delete your data and not to sell
            (share) it. To exercise your data protection rights, you can make
            certain requests and ask us:
          </p>

          <p>
            <strong>What personal information we have about you</strong>. If you
            make this request, we will return to you:
          </p>

          <ol>
            <li>
              The categories of personal information we have collected about
              you.&nbsp;
            </li>
            <li>
              The categories of sources from which we collect your personal
              information.
            </li>
            <li>
              The business or commercial purpose for collecting or selling your
              personal information.
            </li>
            <li>
              The categories of third parties with whom we share personal
              information.
            </li>
            <li>
              The specific pieces of personal information we have collected
              about you.
            </li>
            <li>
              A list of categories of personal information that we have sold,
              along with the category of any other company we sold it to. If we
              have not sold your personal information, we will inform you of
              that fact.
            </li>
            <li>
              A list of categories of personal information that we have
              disclosed for a business purpose, along with the category of any
              other company we shared it with.
            </li>
          </ol>

          <p>
            Please note, you are entitled to ask us to provide you with this
            information up to two times in a rolling twelve-month period. When
            you make this request, the information provided may be limited to
            the personal information we collected about you in the previous
            12&nbsp;months.
          </p>

          <p>
            <strong>To delete your personal information</strong>. If you make
            this request, we will delete the personal information we hold about
            you as of the date of your request from our records and direct any
            service providers to do the same. In some cases, deletion may be
            accomplished through de-identification of the information. If you
            choose to delete your personal information, you may not be able to
            use certain functions that require your personal information to
            operate.
          </p>

          <p>
            <strong>To stop selling your personal information</strong>. We do
            not sell your personal information for monetary consideration.
            However, under some circumstances, a transfer of personal
            information to a third party, or within our family of companies,
            without monetary consideration may be considered a “sale” under
            California law. If you submit a request to stop selling your
            personal information, we will stop making such transfers. If you are
            a California resident, to opt-out of the sale of your personal
            information, click “Do Not Sell My Personal Information” at the
            bottom of our home page to submit your request. Please note, if you
            ask us to delete or stop selling your data, it may impact your
            experience with us, and you may not be able to participate in
            certain programs or membership services that require the usage of
            your personal information to function. But in no circumstances, we
            will discriminate against you for exercising your rights. To
            exercise your California data protection rights described above,
            please send your request(s) by email to support@rushable.io
          </p>

          <p>
            Your data protection rights, described above, are covered by the
            CCPA, short for the California Consumer Privacy Act. To find out
            more, visit the official California Legislative Information website.
            The CCPA took effect on 01/01/2020.
          </p>

          <h2>12. Service Providers</h2>

          <p>
            We may employ third party companies and individuals to facilitate
            our Service (“<strong>Service Providers</strong>”), provide Service
            on our behalf, perform Service-related services or assist us in
            analysing how our Service is used.
          </p>

          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose
          </p>

          <h2>13. Analytics</h2>

          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <h4>Google Analytics</h4>

          <p>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </p>

          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:{' '}
            <a
              href='https://policies.google.com/privacy?hl=en'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://policies.google.com/privacy?hl=en
            </a>
          </p>

          <p>
            We also encourage you to review Google’s policy for safeguarding
            your data:{' '}
            <a
              rel='noreferrer noopener'
              href='https://support.google.com/analytics/answer/6004245'
              target='_blank'
            >
              https://support.google.com/analytics/answer/6004245
            </a>
          </p>

          <h2>14. CI/CD Tools</h2>

          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.&nbsp;
          </p>

          <h4>GitHub</h4>

          <p>GitHub is provided by GitHub, Inc.</p>

          <p>
            GitHub is a development platform to host and review code, manage
            projects, and build software.
          </p>

          <p>
            For more information on what data GitHub collects for what purpose
            and how the protection of the data is ensured, please visit GitHub
            Privacy Policy page:{' '}
            <a
              href='https://help.github.com/en/articles/github-privacy-statement'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://help.github.com/en/articles/github-privacy-statement.
            </a>
          </p>

          <h4>GitLab CI/CD</h4>

          <p>GitLab CI/CD is provided by GitLab, Inc.</p>

          <p>
            GitLab CI (Continuous Integration) service is a part of GitLab that
            build and test the software whenever developer pushes code to
            application.&nbsp;
          </p>

          <p>
            GitLab CD (Continuous Deployment) is a software service that places
            the changes of every code in the production which results in every
            day deployment of production.&nbsp;
          </p>

          <p>
            For more information on what data GitLab CI/CD collects for what
            purpose and how the protection of the data is ensured, please visit
            GitLab CI/CD Privacy Policy page:{' '}
            <a
              href='https://about.gitlab.com/privacy/'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://about.gitlab.com/privacy/
            </a>
            .
          </p>

          <h2>15. Behavioral Remarketing</h2>

          <p>
            Rushable, Inc&nbsp;uses remarketing services to advertise on third
            party websites to you after you visited our Service. We and our
            third-party vendors use cookies to inform, optimise and serve ads
            based on your past visits to our Service.
          </p>

          <h4>Google Ads (AdWords)</h4>

          <p>
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </p>

          <p>
            You can opt-out of Google Analytics for Display Advertising and
            customize the Google Display Network ads by visiting the Google Ads
            Settings page:{' '}
            <a
              href='https://www.google.com/settings/ads'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://www.google.com/settings/ads
            </a>
          </p>

          <p>
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on –{' '}
            <a
              href='https://tools.google.com/dlpage/gaoptout'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://tools.google.com/dlpage/gaoptout
            </a>{' '}
            – for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics.
          </p>

          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:{' '}
            <a
              href='https://policies.google.com/privacy?hl=en'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://policies.google.com/privacy?hl=en
            </a>
          </p>

          <h4>Bing Ads Remarketing</h4>

          <p>Bing Ads remarketing service is provided by Microsoft Inc.</p>

          <p>
            You can opt-out of Bing Ads interest-based ads by following their
            instructions:{' '}
            <a
              href='https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
            </a>
          </p>

          <p>
            You can learn more about the privacy practices and policies of
            Microsoft by visiting their Privacy Policy page:{' '}
            <a
              href='https://privacy.microsoft.com/en-us/PrivacyStatement'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://privacy.microsoft.com/en-us/PrivacyStatement
            </a>
          </p>

          <h4>Twitter</h4>

          <p>Twitter remarketing service is provided by Twitter Inc.</p>

          <p>
            You can opt-out from Twitter’s interest-based ads by following their
            instructions:{' '}
            <a
              href='https://support.twitter.com/articles/20170405'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://support.twitter.com/articles/20170405
            </a>
          </p>

          <p>
            You can learn more about the privacy practices and policies of
            Twitter by visiting their Privacy Policy page:{' '}
            <a
              href='https://twitter.com/privacy'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://twitter.com/privacy
            </a>
          </p>

          <h4>Facebook</h4>

          <p>Facebook remarketing service is provided by Facebook Inc.</p>

          <p>
            You can learn more about interest-based advertising from Facebook by
            visiting this page:{' '}
            <a
              href='https://www.facebook.com/help/164968693837950'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://www.facebook.com/help/164968693837950
            </a>
          </p>

          <p>
            To opt-out from Facebook’s interest-based ads, follow these
            instructions from Facebook:{' '}
            <a
              href='https://www.facebook.com/help/568137493302217'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://www.facebook.com/help/568137493302217
            </a>
          </p>

          <p>
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA{' '}
            <a
              rel='noreferrer noopener'
              href='https://www.aboutads.info/choices/'
              target='_blank'
            >
              https://www.aboutads.info/choices/
            </a>
            , the Digital Advertising Alliance of Canada in Canada{' '}
            <a
              href='https://youradchoices.ca/'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://youradchoices.ca/
            </a>{' '}
            or the European Interactive Digital Advertising Alliance in Europe{' '}
            <a
              rel='noreferrer noopener'
              href='https://www.youronlinechoices.eu/'
              target='_blank'
            >
              https://www.youronlinechoices.eu/
            </a>
            , or opt-out using your mobile device settings.
          </p>

          <p>
            For more information on the privacy practices of Facebook, please
            visit Facebook’s Data Policy:{' '}
            <a
              href='https://www.facebook.com/privacy/explanation'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://www.facebook.com/privacy/explanation
            </a>
          </p>

          <h4>Pinterest</h4>

          <p>Pinterest remarketing service is provided by Pinterest Inc.</p>

          <p>
            You can opt-out from Pinterest’s interest-based ads by enabling the
            “Do Not Track” functionality of your web browser or by following
            Pinterest instructions:{' '}
            <a
              href='https://help.pinterest.com/en/articles/personalization-and-data'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://help.pinterest.com/en/articles/personalization-and-data
            </a>
          </p>

          <p>
            You can learn more about the privacy practices and policies of
            Pinterest by visiting their Privacy Policy page:{' '}
            <a
              href='https://about.pinterest.com/en/privacy-policy'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://about.pinterest.com/en/privacy-policy
            </a>
          </p>

          <h2>16. Payments</h2>

          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>

          <p>
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>

          <p>
            The payment processors we work with is <strong>Stripe</strong>.
            Their Privacy Policy can be viewed at:{' '}
            <a
              href='https://stripe.com/us/privacy'
              target='_blank'
              rel='noreferrer noopener'
            >
              https://stripe.com/us/privacy
            </a>
          </p>

          <h2>17. Links to Other Sites</h2>

          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>

          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h2>18. Children’s Privacy</h2>

          <p>
            Our Services are not intended for use by children under the age of
            18 (“<strong>Children</strong>”).&nbsp;
          </p>

          <p>
            We do not knowingly collect personally identifiable information from
            children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>

          <h2>19. Changes to This Privacy Policy</h2>

          <p>
            We may update our Privacy Policy from time to time. We will post the
            new Privacy Policy on this page. It is your responsibility to review
            our Privacy Policy on this page periodically.
          </p>

          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>

          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h2>20. Contact Us</h2>

          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>

          <p>
            By email: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </p>
        </article>
      </div>
    </div>
  )
}
