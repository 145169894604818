import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import {
  TGetLocationsProp,
  TGetLocationProp,
  TLocation,
  TSendNewLocationProp,
} from 'types/location'
import axios from 'utils/axiosApi'

import { addLocationsToBrand } from '../brand'

export const getLocations = createAsyncThunk(
  'location/getLocations',
  async ({ brand_id, refresh = false }: TGetLocationsProp, thunkApi) => {
    const { data } = await axios.request({
      url: `brands/${brand_id}/locations`,
      method: 'get',
      cache: {
        ignoreCache: refresh,
      },
    })
    if (data) {
      thunkApi.dispatch(addLocationsToBrand(data as TLocation[]))
    }
    return data
  },
)

export const getLocation = createAsyncThunk(
  'location/getLocation',
  async ({ location_id, refresh = false }: TGetLocationProp) => {
    const { data } = await axios.request({
      url: `locations/${location_id}`,
      method: 'get',
      cache: {
        ignoreCache: refresh,
      },
    })
    return data
  },
)

export const setLocations = createAction<{ locations: Array<TLocation> }>(
  'location/setLocations',
)

export async function getNotificationApi(locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/notification`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export const setFirstShowNotification = createAction<string>(
  'location/firstShowNotification',
)

export const sendSlackForNewLocation = ({
  brandId,
  locationAddress,
  whenToContact,
}: TSendNewLocationProp) => {
  return async () => {
    const response = await axios.request({
      url: `brands/${brandId}/sendSlackForNewLocation`,
      data: {
        locationAddress,
        whenToContact,
      },
      method: 'post',
    })
    return response.data
  }
}

/**=============================  Order Setting ================================== */
export async function getGeneralSettingApi({
  id,
  locationId,
}: {
  id: number | null | undefined
  locationId?: string
}) {
  const response = await axios.request({
    url: `locations/${locationId}/online_order_configs/${id}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function editGeneralSettingApi(
  id: number | null | undefined,
  params: any,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/online_order_configs/${id}`,
    method: 'post',
    params: {
      _method: 'patch',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getPickupConfigApi(
  id: number | null | undefined,
  params: any,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/pickup_configs/${id}`,
    method: 'post',
    params: {
      _method: 'put',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getDeliveryConfigApi(params: any, locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/delivery`,
    method: 'post',
    // params: {
    //   _method: 'put',
    // },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getInHouseConfigApi(
  id: number | null | undefined,
  params: any,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/in_house_configs/${id}`,
    method: 'post',
    params: {
      _method: 'put',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getMarketPlaceConfigApi(
  id: number | null | undefined,
  params: any,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/marketplace_configs/${id}`,
    method: 'post',
    params: {
      _method: 'put',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getCurbsideConfigApi(
  id: number | null | undefined,
  params: any,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/curbside_configs/${id}`,
    method: 'post',
    params: {
      _method: 'put',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getTakeOutConfigApi(
  id: number | null | undefined,
  params: any,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/contactless_takeout_configs/${id}`,
    method: 'post',
    params: {
      _method: 'put',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getDineInConfigApi(
  id: number | null | undefined,
  params: any,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/contactless_dinein_configs/${id}`,
    method: 'post',
    params: {
      _method: 'put',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function toggleOrderTypesApi(params: any, locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/toggle_order_types`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
/**=============================  End of Order Setting ================================== */
