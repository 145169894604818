import React from 'react'

import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import { DateRange, DefinedRange } from 'react-date-range'

type TDateRangeModalProp = {
  open: boolean
  ranges: any
  toggle: () => void
  onChange?: (e: any) => void
  onConfirm?: () => void
}

export default function DateRangeModal({
  open,
  ranges,
  toggle,
  onChange,
  onConfirm,
}: TDateRangeModalProp): JSX.Element {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }
    toggle()
  }
  return (
    <ModalFull
      open={open}
      toggle={toggle}
      footer={
        <Button className='w-full' color='primary' onClick={handleConfirm}>
          Confirm
        </Button>
      }
    >
      <div className='flex'>
        <DefinedRange onChange={e => onChange && onChange(e)} ranges={ranges} />
        <DateRange
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          onChange={e => onChange && onChange(e)}
          ranges={ranges}
          direction='horizontal'
          maxDate={new Date()}
          rangeColors={['#008DFF']}
        />
      </div>
    </ModalFull>
  )
}
