import React from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import JobListPage from './JobListPage'
import ManageCandidatesPage from './ManageCandidatesPage'

export default function RecruitingPage(): JSX.Element {
  const history = useHistory()
  const { path, url } = useRouteMatch()

  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})

  return (
    <AdminPage>
      <Switch>
        <Route path={path}>
          <>
            <AdminPageTabs>
              <AdminPageTab
                text='Job Listing'
                active={activePath === 'job'}
                onClick={() => switchTab('job')}
              />
              <AdminPageTab
                text='Manage Candidates'
                active={activePath === 'candidates'}
                onClick={() => switchTab('candidates')}
              />
            </AdminPageTabs>
            <Switch>
              <Route path={`${path}/job`}>
                <JobListPage />
              </Route>
              <Route path={`${path}/candidates`}>
                <ManageCandidatesPage />
              </Route>
              <Redirect from={`${path}`} to={`${path}/job`} exact />
            </Switch>
          </>
        </Route>
      </Switch>
    </AdminPage>
  )
}
