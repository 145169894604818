import { useState } from 'react'

import {
  LinedStore,
  LinedMapPin,
  Slash,
  SolidChatQuestion,
} from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import GetSupportModal from 'components/GetSupportModal'
import { useRouteMatch } from 'react-router'
import { logout } from 'redux/auth'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { pageRoutes } from 'routes'
import { brandRoutes } from 'routes/brandRoutes'

type TProps = {
  className?: string
}

export default function BreadCrumbs({ className }: TProps): JSX.Element {
  const dispatch = useAppDispatch()
  const [openSupportModal, setOpenSupportModal] = useState(false)
  const { selectedBrand } = useAppSelector(state => state.brand)
  const { selectedLocation } = useAppSelector(state => state.location)

  const currentRoute = pageRoutes.find(r =>
    useRouteMatch({
      path: r.path,
    }),
  )
  const isBrand = brandRoutes.findIndex(r => currentRoute?.path === r.path) > -1
  const group = currentRoute?.group || ''
  const currentPath = currentRoute?.label || ''

  const handleLogout = () => {
    dispatch(logout())
  }
  return (
    <div
      className={cn(
        ' text-lead text-xs flex justify-between ',
        className || 'mx-10 mt-5 mb-4 min-w-[980px]',
      )}
    >
      {selectedBrand && selectedLocation && (
        <>
          <div className='flex-1 flex items-center'>
            <LinedStore className='mr-1' size={16} />
            {selectedBrand.name}
            {!isBrand ? (
              <>
                <Slash className='mr-1 text-dark-16' size={16} />
                <LinedMapPin className='mr-1' size={16} />
                {selectedLocation.name}
              </>
            ) : null}
            {group && (
              <>
                <Slash className='mr-1 text-dark-16' size={16} />
                {group}
              </>
            )}
            {currentPath && (
              <>
                <Slash className='mr-1 text-dark-16' size={16} />
                {currentPath.includes('Loyalty')
                  ? 'Loyalty Rewards'
                  : currentPath}
              </>
            )}
          </div>
          <div className='flex items-center font-bold text-dark-100'>
            <SolidChatQuestion className='mr-2' size={12} />
            SUPPORT (855) 979-8860
          </div>
          <Button
            className='ml-4'
            color='tertiary'
            size='sm'
            onClick={handleLogout}
          >
            LOG OUT
          </Button>
        </>
      )}
      <GetSupportModal
        open={openSupportModal}
        toggle={() => setOpenSupportModal(!openSupportModal)}
      />
    </div>
  )
}
