import TextArea from 'components/TextArea'

interface Iprops {
  label: string
  value: string
  maxLength?: number
  height?: number
  placeholder?: string
  onChange: (value: string) => void
}
export default function TextareaComps({
  label,
  value,
  maxLength = 1000,
  height = 124,
  placeholder = '',
  onChange,
}: Iprops) {
  return (
    <>
      <div className='text-xs flex justify-between text-silver mb-2	'>
        <span className=' font-bold'>{label}</span>
        <span>({value.length}/1000characters)</span>
      </div>
      <TextArea
        name='description'
        maxLength={maxLength}
        value={value}
        onChange={(e: any) => {
          const value = e.target.value
          onChange(value)
        }}
        placeholder={placeholder}
        style={{ height: `${height}px`, marginTop: '0' }}
      />
    </>
  )
}
