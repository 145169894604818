import React from 'react'

import cn from 'classnames'

export type TAdminPageProp = {
  className?: string
  children?: React.ReactElement | React.ReactElement[]
}

export default function AdminPage({
  className,
  children,
}: TAdminPageProp): JSX.Element {
  return (
    <div
      className={cn('bg-white rounded-lg mb-10 mx-10 relative', className)}
      style={{ minHeight: 'calc(100vh - 80px)', minWidth: '980px' }}
    >
      {children}
    </div>
  )
}
