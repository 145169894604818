export const BALANCEOPTION = [
  {
    label: '2,000',
    value: 2000,
    optionChildren: <span className='w-full text-right'>2,000</span>,
  },
  {
    label: '5,000',
    value: 5000,
    optionChildren: <span className='w-full text-right'>5,000</span>,
  },
  {
    label: '10,000',
    value: 10000,
    optionChildren: <span className='w-full text-right'>10,000</span>,
  },
  {
    label: '30,000',
    value: 30000,
    optionChildren: <span className='w-full text-right'>30,000</span>,
  },
]

export const RECHARGEOPTION = [
  {
    label: '5000 ($50)',
    value: 5000,
    optionChildren: <span className='w-full text-right'>5000 ($50)</span>,
  },
  {
    label: '10,000 ($100)',
    value: 10000,
    optionChildren: <span className='w-full text-right'>10,000 ($100)</span>,
  },
  {
    label: '25,000 ($250)',
    value: 25000,
    optionChildren: <span className='w-full text-right'>25,000 ($250)</span>,
  },
  {
    label: '100,000 ($1000)',
    value: 100000,
    optionChildren: <span className='w-full text-right'>100,000 ($1000)</span>,
  },
]

export const ACCOUNTTYPE = [
  { label: 'Business Checking', value: 'company' },
  { label: 'Personal Checking', value: 'individual' },
]

export const CANCELREASON = [
  { label: 'Too many system errors', value: 'too_many_system_errors' },
  { label: 'Too hard to use', value: 'too_hard_to_use' },
  { label: 'Too expensive', value: 'too_expensive' },
  { label: 'Found better solution', value: 'found_better_solution' },
  { label: 'Selling / Closing down', value: 'selling_/_closing_down' },
  { label: 'Other reason', value: 'other_reason' },
]

export type TPayoutSchedule = 'daily' | 'weekly' | 'stripe_managed'

export const PAYOUT_SCHEDULES = {
  daily: {
    title: 'Daily Payout ',
    titleTips: '($1.00/payout)',
    desc: 'Receive payout daily except for weekends and holidays',
  },
  weekly: {
    title: 'Weekly Payout ',
    titleTips: '(Free)',
    desc: 'Receive payout weekly on Thursday of each week',
  },
  stripe_managed: { title: '', titleTips: '', desc: '' },
}

export const getModalConstant = {
  services: {
    modalTitle: 'Question About Add-on Subscription?',
    modalContent: (
      <div className='text-center'>
        Please contact us at your earliest convenience by calling our support
        line at <span className='font-bold'>(855) 979-8860</span> or email to{' '}
        <span className='font-bold'>support@rushable.io</span>
      </div>
    ),
  },
  token: {
    modalTitle: 'Question About Rushable Token?',
    modalContent: (
      <div className='text-center'>
        Please contact us at your earliest convenience by calling our support
        line at <span className='font-bold'>(855) 979-8860</span> or email to{' '}
        <span className='font-bold'>support@rushable.io</span>
      </div>
    ),
  },
}

export const TABLECUSTOMPAGESIZE = [
  { label: '8 records per page', value: 8 },
  { label: '32 records per page', value: 32 },
  { label: '64 records per page', value: 64 },
  { label: '128 records per page', value: 128 },
]

export const STATUSTAG: any = {
  null: {
    color: 'blue',
    value: 'Current Balance',
  },
  paid: {
    color: 'green',
    value: 'Deposited',
  },
  // withdraw
  // pending: 'Withdrawl',
  in_transit: {
    color: 'blue',
    value: 'In Transit',
  },
  pending: {
    color: 'blue',
    value: 'In Transit',
  },
  canceled: {
    color: '',
    value: 'Cancelled',
  },
  failed: {
    color: 'yellow',
    value: (
      <span>
        Failed <span className='text-xs text-lead font-normal'></span>
      </span>
    ),
  },
}

export const STATUSTYPE: any = {
  order_payment: {
    color: 'green',
    desc: 'Order Payment',
  },
  adjustment: {
    color: 'yellow',
    desc: 'Adjustment',
  },
  order_refund: {
    color: 'red',
    desc: 'Order Refund',
  },
  dispute_fee: {
    color: 'red',
    desc: 'Dispute Fee',
  },
  rushable_service: {
    color: 'blue',
    desc: 'Rushable Service',
  },
}

export const ORDERREFERENCE: any = {
  daas_delivery: 'Delivery (On-demand)',
  delivery: 'Delivery (In-house)',
  pickup: 'Pickup',
  curbside: 'Curbside',
  'dine-in': 'Dine in',
  takeout: 'Takeout',
}

export const INVOICESTATUS: any = {
  0: {
    color: 'red',
    desc: 'Failed',
  },
  1: {
    color: 'green',
    desc: 'Succeeded',
  },
}

export const STATUSOPTIONS = [
  { label: 'Any Status', value: '' },
  { label: 'Open', value: 'open', color: 'blue' },
  { label: 'Paid', value: 'paid', color: 'green' },
  { label: 'Uncollectible', value: 'uncollectible', color: 'red' },
  { label: 'Void', value: 'void', color: 'red' },
]

// STATUS REFUNDED OPTIONS 不出现在筛选里
export const STATUSREFUNDEDOPTIONS = [
  { label: 'Refunded', value: 'refunded', color: 'red' },
  { label: 'Partially Refunded', value: 'partially_refunded', color: 'red' },
]

export const STATUSOPTIONSMAP = STATUSOPTIONS.concat(
  STATUSREFUNDEDOPTIONS,
).reduce((acc: any, cur) => {
  if (cur.value) {
    acc[cur.value] = { color: cur.color, label: cur.label }
  }
  return acc
}, {})

export const PAYDETAILFILTER = [
  { label: 'Show both orders and services', value: '' },
  { label: 'Show orders only', value: 'orders' },
  { label: 'Show services only', value: 'services' },
]
