import React from 'react'

import cn from 'classnames'

type TLineProps = {
  className?: string
  type?: string
}

export default function Line({
  className,
  type = 'dashed',
}: TLineProps): JSX.Element {
  return (
    <div
      className={cn('w-full border-t border-zinc', `border-${type}`, className)}
    />
  )
}
