import { useState, useEffect } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getGiftCardList,
  showStripeAccountAPI,
  updateStripeAccountAPI,
  redirectStripeAccountAPI,
  setPage,
  setPageSize,
} from 'redux/brand/actions'
import { useAppSelector, useAppDispatch } from 'redux/hooks'

import GiftCardList from './components/GiftCardList'

export default function GiftCardPage(): JSX.Element {
  const dispatch = useAppDispatch()
  const { giftCardList, giftCardPage, giftCardPageSize, getGiftCardRequest } =
    useAppSelector(state => state.brand)
  const history = useHistory()
  const { url } = useRouteMatch()
  const { brandId, locationId } = useParams<TParamTypes>()
  const [updating, setUpdating] = useState(false)
  const [accounting, setAccounting] = useState(false)
  const [changing, setChanging] = useState(false)
  const [stripeInfo, setStripeInfo] = useState({})

  const showStripeAccount = async () => {
    setAccounting(true)
    try {
      const res = await showStripeAccountAPI(brandId)
      setStripeInfo({ ...res })
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setAccounting(false)
  }

  const updateStripeAccount = async (selling_status: boolean) => {
    setChanging(true)
    try {
      await updateStripeAccountAPI(Number(selling_status), brandId)
      showStripeAccount()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setChanging(false)
  }

  const redirectStripeAccount = async () => {
    setUpdating(true)
    try {
      const res = await redirectStripeAccountAPI(brandId, locationId)
      if (res) {
        window.open(res, '_self')
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setUpdating(false)
  }

  const getGiftCard = async (pageNow: number, pageSizeNow: number) => {
    dispatch(setPage(pageNow))
    dispatch(setPageSize(pageSizeNow))
    dispatch(
      getGiftCardList({
        brandId,
        page: pageNow,
        per_page: pageSizeNow,
      }),
    )
  }

  useEffect(() => {
    getGiftCard(giftCardPage, giftCardPageSize)
    showStripeAccount()
  }, [])

  return (
    <AdminPage>
      <AdminPageTitle title='Gift Card' />
      <div className='py-8 px-8'>
        <GiftCardList
          stripeInfo={stripeInfo}
          accounting={accounting}
          updating={updating}
          changing={changing}
          loading={getGiftCardRequest}
          page={giftCardPage || 1}
          pageSize={giftCardPageSize || 20}
          data={giftCardList?.data || []}
          total={giftCardList?.total || 0}
          updateStripeAccount={() => redirectStripeAccount()}
          changeSelling={e => updateStripeAccount(e)}
          clickedRow={row => {
            history.push(`${url.slice(0, -15)}/gift-card-detail/${row.id}`)
          }}
          onChange={(name, value) => {
            if (name === 'page') {
              getGiftCard(value, giftCardPageSize)
            } else {
              getGiftCard(1, value)
            }
          }}
        />
      </div>
    </AdminPage>
  )
}
