import ModalFull from 'components/Modal/ModalFull'
import SourceIconRender from 'components/PayIcon/PayIconRender'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

type TPaymentModalProps = {
  open: boolean
  stepLoading: boolean
  toggle: () => void
  handlePayment: () => void
}

export default function PaymentModal({
  open,
  stepLoading,
  toggle,
  handlePayment,
}: TPaymentModalProps) {
  const history = useHistory()
  const { brandId, locationId } = useParams<TParamTypes>()
  const { selectedLocation } = useAppSelector(state => state.location)
  const card_last4 = selectedLocation?.default_payment_method?.card_last4
  const card_name = selectedLocation?.default_payment_method?.card_brand
  const goPayment = () => {
    history.push(
      `/brand/${brandId}/location/${locationId}/billing-and-invoice/billing`,
    )
  }
  return (
    <ModalFull
      open={open}
      title='Important Reminder'
      toggle={toggle}
      okText='PAYMENT IS CORRECT'
      onCancel={toggle}
      onOk={handlePayment}
      loading={stepLoading}
    >
      <>
        <div className='text-base text-ink leading-6 text-center'>
          Please ensure your payment method is up to date, in order to
          successfully recharge your token balance when it is necessary.
        </div>
        <div className='flex justify-center items-center my-4'>
          {SourceIconRender({
            type: card_name!,
            iconParams: {
              size: 24,
              className: 'mr-2',
            },
          })}
          <span className='text-base text-ink font-bold'>••• {card_last4}</span>
        </div>
        <div className='flex justify-center'>
          <div
            className='w-[135px] h-[28px] border-2 border-zinc rounded flex justify-center items-center text-xs font-bold text-ink cursor-pointer'
            onClick={goPayment}
          >
            UPDATE PAYMENT
          </div>
        </div>
      </>
    </ModalFull>
  )
}
