const TIPS = [
  "Purchase a domain from any domain providers, if you haven't done so.",
  'Login to the domain provider where you purchased your domain.',
  'Go to domain management and find the DNS setting.',
  'Find the section to add/edit DNS records with following:',
  'Click Add your domain on this page, and enter your domain.',
  'Wait until the CNAME A RECORD and SSL status are all verified.',
  'This process could take up to 48 hours.',
]

export default function SetupSteps(): JSX.Element {
  return (
    <>
      <div className='my-8 text-base text-ink'>
        <p>
          Before you begin, these instructions might affect other settings, such
          as email forwarding. Please contact your web master or domain provider
          if needed.
        </p>
        <br />
        <p>
          To connect a domain to Rushable, please follow this step-by-step
          instruction guide:
        </p>
      </div>
      <h3 className='mb-4 font-bold text-base text-ink'>Setting Guide</h3>
      <div className='text-lead font-base flex relative'>
        <div
          className='border-l border-zinc w-px absolute h-full'
          style={{ left: '12px' }}
        ></div>
        <ul className='relative space-y-4 text-base'>
          {TIPS.map((item, index) => {
            return (
              <li key={index} className='ring-white'>
                <div className='flex items-center py-1 bg-white'>
                  <div className='border text-xs bg-blue rounded-full w-6 h-6 text-white flex items-center justify-center'>
                    {index + 1}
                  </div>
                  <p className='ml-2 text-lead'>{item}</p>
                </div>
                {index === 3 && (
                  <div className='inline-flex ml-8 my-3 rounded  border	border-zinc  box-border'>
                    <table className='table'>
                      <thead className='bg-field border-b border-zinc'>
                        <tr className='text-silver font-bold text-xs'>
                          <th className='px-4 py-3 text-center'>NAME</th>
                          <th className='px-2 py-3	text-center'>RECORD TYPE</th>
                          <th className='px-4 py-3 text-left'>VALUE</th>
                        </tr>
                      </thead>
                      <tbody className='text-ink'>
                        <tr>
                          <td className='py-3 px-4'>
                            <span>@</span>
                          </td>
                          <td className='py-3 px-2'>
                            <span>A</span>
                          </td>
                          <td className='py-3 px-4'>
                            <span>3.20.252.139</span>
                          </td>
                        </tr>
                        <tr>
                          <td className='py-3 px-4'>
                            <span>www</span>
                          </td>
                          <td className='py-3 px-2'>
                            <span>CName</span>
                          </td>
                          <td className='py-3 px-4'>
                            <span>rushable.site</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
