import { useState, useEffect } from 'react'

import cn from 'classnames'
import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateScheduleApi } from 'redux/accountAndPayout'

import {
  PAYOUT_SCHEDULES,
  TPayoutSchedule,
} from '../../billingAndInvoice/helpers'

interface IModalTypes {
  disabled?: boolean
  payoutConfigId: string
  currentInterval: TPayoutSchedule
  onSuccess: () => void
}
export default function PayoutScheduleModal({
  disabled,
  payoutConfigId,
  onSuccess,
  currentInterval,
}: IModalTypes) {
  const { locationId } = useParams<TParamTypes>()
  const [isVisible, setIsVisible] = useState(false)
  const schedules = Object.keys(PAYOUT_SCHEDULES)
  const [selected, setSelected] = useState(currentInterval)
  const [formStatus, setFormStatus] = useState({ loading: false, error: '' })

  useEffect(() => {
    setSelected(currentInterval)
  }, [currentInterval])

  const handleSubmit = async () => {
    setFormStatus(prev => ({ ...prev, loading: true }))
    const params = {
      interval: selected,
    }
    try {
      const res = await updateScheduleApi(
        params,
        payoutConfigId,
        locationId || '',
      )
      toast.success(res.message)
      setFormStatus(prev => ({ ...prev, error: '' }))
      setIsVisible(false)
      onSuccess()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }
  return (
    <>
      <Button
        color='primary'
        size='sm'
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        CHANGE SCHEDULE
      </Button>
      <ModalFull
        open={isVisible}
        title='Change Schedule'
        loading={formStatus.loading}
        error={formStatus.error}
        toggle={() => setIsVisible(false)}
        onOk={() => {
          handleSubmit()
        }}
        okBtnDisabled={currentInterval === selected}
      >
        <ul className='space-y-6'>
          {schedules.map((item, index: number) => {
            return (
              <li
                key={index}
                onClick={() => setSelected(item as TPayoutSchedule)}
                className={cn(
                  'option-box-class',
                  item === selected ? 'selected' : '',
                )}
              >
                <div className='flex items-center text-base'>
                  <span className='font-bold'>
                    {PAYOUT_SCHEDULES[item as TPayoutSchedule].title}
                  </span>
                  <span>
                    {PAYOUT_SCHEDULES[item as TPayoutSchedule].titleTips}
                  </span>
                </div>
                <div className='text-sm mt-1'>
                  {PAYOUT_SCHEDULES[item as TPayoutSchedule].desc}
                </div>
              </li>
            )
          })}
        </ul>
      </ModalFull>
    </>
  )
}
