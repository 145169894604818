import { createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { TAnnouncementStateProp } from 'types/announcement'

import { getLocationAnnouncement,createAnnouncement,editAnnouncement,deleteAnnouncement } from './actions'

const initialState: TAnnouncementStateProp = {
  updated: false,
  location_announcement_config: null,
  getAnnouncementRequest: false,
  createAnnouncementRequest: false,
  editAnnouncementRequest : false,
  deleteAnnouncementRequest: false,
}

export const announcementReducer = createReducer(initialState, builder => {
  builder
    .addCase(getLocationAnnouncement.pending, state => {
      state.getAnnouncementRequest = true
    })
    .addCase(getLocationAnnouncement.fulfilled, (state, action) => {
      state.getAnnouncementRequest = false
      const {data, message } = action.payload || {}
      if (data) {
        state.updated = true
        state.location_announcement_config = data
      } else {
        state.updated = false
        state.location_announcement_config = null
        toast.warn(message)
      }
    })
    .addCase(getLocationAnnouncement.rejected, (state, action) => {
      state.getAnnouncementRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(createAnnouncement.pending, state => {
      state.createAnnouncementRequest = true
    })
    .addCase(createAnnouncement.fulfilled, (state, action) => {
      state.createAnnouncementRequest = false
      const { errors, message } = action.payload || {}
      if (errors) {
        toast.warn(message)
      } else {
        state.updated = true
        toast.success(message)
      }
    })
    .addCase(createAnnouncement.rejected, (state, action) => {
      state.createAnnouncementRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })

    .addCase(editAnnouncement.pending, state => {
      state.editAnnouncementRequest = true
    })
    .addCase(editAnnouncement.fulfilled, (state, action) => {
      state.editAnnouncementRequest = false
      const { errors, message } = action.payload || {}
      if (errors) {
        toast.warn(message)
      } else {
        state.updated = true
        toast.success(message)
      }
    })
    .addCase(editAnnouncement.rejected, (state, action) => {
      state.editAnnouncementRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(deleteAnnouncement.pending, state => {
      state.deleteAnnouncementRequest = true
    })
    .addCase(deleteAnnouncement.fulfilled, (state, action) => {
      state.deleteAnnouncementRequest = false
      const { errors, message } = action.payload || {}
      if (errors) {
        toast.warn(message)
      } else {
        state.updated = false
        state.location_announcement_config = null
        toast.success(message)
      }
    })
    .addCase(deleteAnnouncement.rejected, (state, action) => {
      state.deleteAnnouncementRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
})

export default announcementReducer
