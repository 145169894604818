import { useState } from 'react'

import { loadStripe } from '@stripe/stripe-js'
import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import { STRIPE_API_KEY } from 'data/constant'
import { useParams } from 'react-router-dom'
import {
  getBillingAccountApi,
  cancelBillingAccountApi,
} from 'redux/accountAndPayout'

import VerifyBillingAccount from './VerifyBillingAccount'

interface IModalTypes {
  bankUpdating: {
    last4: string
    microDepositType: 'descriptor_code' | 'amounts' | ''
    createdAt: string
  }
  disabled?: boolean
  onSuccess: () => void
}

export type TFormData = {
  descriptorCode: string
  amounts1: string
  amounts2: string
}

const INIT_FORM_DATA = {
  descriptorCode: '',
  amounts1: '',
  amounts2: '',
}

export default function ModelVerifyBankMethod({
  bankUpdating,
  disabled,
  onSuccess,
}: IModalTypes) {
  const { locationId = '' } = useParams<TParamTypes>()
  const stripePromise = loadStripe(STRIPE_API_KEY)
  const [isVisible, setIsVisible] = useState(false)
  const [formStatus, setFormStatus] = useState({
    cancelLoading: false,
    loading: false,
    error: '',
  })

  const [formData, setFormData] = useState<TFormData>(INIT_FORM_DATA)
  const { microDepositType } = bankUpdating

  const onFormDataChange = (type: string, value: any) => {
    setFormData({
      ...formData,
      [type]: value,
    })
  }

  const handleSubmit = async () => {
    try {
      setFormStatus({
        cancelLoading: false,
        loading: true,
        error: '',
      })
      const res = await getBillingAccountApi(locationId)
      const stripe = await stripePromise
      if (res?.client_secret && stripe) {
        const params: {
          descriptor_code?: string
          amounts?: number[]
        } = {}
        if (microDepositType === 'descriptor_code') {
          params.descriptor_code = `SM${formData.descriptorCode}`
        }
        if (microDepositType === 'amounts') {
          params.amounts = [
            Number(formData.amounts1),
            Number(formData.amounts2),
          ]
        }
        const result = await stripe.verifyMicrodepositsForSetup(
          res?.client_secret,
          params,
        )
        // 处理确认设置银行账户的结果
        if (result.error) {
          // 处理错误
          setFormStatus(prev => ({
            ...prev,
            loading: false,
            error:
              result.error?.setup_intent?.last_setup_error?.message ||
              result.error?.message ||
              'Request error',
          }))
        } else {
          // 跳转到成功页面或执行其他操作
          onSuccess()
        }
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({
        ...prev,
        loading: false,
        error: msg || '',
      }))
    }
  }

  const handleCancel = async () => {
    try {
      setFormStatus({
        cancelLoading: true,
        loading: false,
        error: '',
      })
      await cancelBillingAccountApi(locationId)
      onSuccess()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({
        ...prev,
        cancelLoading: false,
        error: msg || '',
      }))
    }
  }

  return (
    <>
      <Button
        color='secondary'
        size='sm'
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        VERIFY NOW
      </Button>
      <ModalFull
        open={isVisible}
        title='Update Billing Account'
        loading={formStatus.loading}
        toggle={() => setIsVisible(false)}
        okBtnDisabled={
          microDepositType === 'descriptor_code'
            ? !formData.descriptorCode
            : microDepositType === 'amounts'
            ? !formData.amounts1 || !formData.amounts2
            : false
          // !formData.descriptorCode || (!formData.amounts1 && !formData.amounts2)
        }
        onOk={() => {
          handleSubmit()
        }}
        okText='VERIFY'
        cancelText='CLOSE'
        iconFooter={
          <Button
            color='secondary-link'
            disabled={disabled}
            onClick={handleCancel}
            loading={formStatus.cancelLoading}
          >
            CANCEL PROGRESS
          </Button>
        }
      >
        <VerifyBillingAccount
          bankUpdating={bankUpdating}
          formData={formData}
          errorText={formStatus.error}
          onFormDataChange={onFormDataChange}
        />
      </ModalFull>
    </>
  )
}
