import React, { useState, useEffect } from 'react'

import { SolidStar } from '@rushable/icons'
import Button from 'components/Button'
import ControlToggle from 'components/ControlToggle'
import DateRangeModal from 'components/DateRangeModal'
import Field from 'components/Field'
import { Select } from 'components/Select'
import moment from 'moment'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { updateReviewsConfigApi } from 'redux/smartReview'

import { TReviewFormData } from '../../../types/review'
import { SELECT_IMPRESSION_OPTIONS } from '../helpers/constant'

type TFormFilterProps = {
  formData: TReviewFormData
  status?: 'active' | 'inactive'
  handleFormDataChange: (key: string, value: any) => void
  getReviewConfig: () => void
  openSettingModal: () => void
}

export default function FormFilter({
  formData,
  status,
  handleFormDataChange,
  getReviewConfig,
  openSettingModal,
}: TFormFilterProps) {
  const { locationId } = useParams<{ locationId: string }>()
  const { keyword, impressions, ranges } = formData
  const [dateOpen, setDateOpen] = useState(false)
  const [dateLabel, setDateLabel] = useState('')
  const [loading, setLoading] = useState(false)
  const [dateRangeData, setDateRangeData] = useState({
    startDate: moment().format(),
    endDate: moment().format(),
  })
  const renderPicker = (e: any) => {
    e.preventDefault()
    toggleModal()
  }

  const toggleModal = () => {
    if (!dateOpen && !ranges[0]) {
      handleFormDataChange('ranges', [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'dateRange',
        },
      ])
    }
    setDateOpen(!dateOpen)
  }

  const handleToggleStatus = async () => {
    try {
      setLoading(true)
      await updateReviewsConfigApi(locationId, {
        status: status === 'active' ? 'inactive' : 'active',
      })
      getReviewConfig()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (ranges[0].startDate && ranges[0].endDate) {
      const label = ranges[0]
        ? moment(ranges[0].startDate).format('MM/DD/YY') +
          ' - ' +
          moment(ranges[0].endDate).format('MM/DD/YY')
        : ''
      setDateLabel(label)
    } else {
      setDateLabel('')
    }
  }, [ranges])
  return (
    <div className='w-full pb-4 mb-4 border-b border-zinc border-dashed'>
      <div className='w-full flex'>
        <ControlToggle
          className='flex-1'
          name={`Smart Review ${
            status === 'active'
              ? 'is currently active and running'
              : 'has been paused'
          }`}
          state='Off'
          icon={<SolidStar size={16} className='text-silver' />}
          checked={status === 'active'}
          loading={loading}
          onChange={handleToggleStatus}
        />
        <Button
          className='w-[200px] h-[46px] ml-6'
          color='tertiary'
          onClick={openSettingModal}
        >
          SETTING
        </Button>
      </div>
      <div className='w-full flex mt-6'>
        <div className='flex-1'>
          <Field
            placeholder='Order ID, Customer'
            type='text'
            name='smart_review_keyword'
            label='Keyword'
            value={keyword || ''}
            onChange={e => {
              handleFormDataChange('keyword', e.target.value)
            }}
          />
        </div>
        <div className='flex-1 mx-6'>
          <Select
            label='Impression'
            className='flex-1'
            options={SELECT_IMPRESSION_OPTIONS}
            value={impressions || []}
            isMulti
            placeholder='Select'
            onChange={value => handleFormDataChange('impressions', value)}
          />
        </div>
        <div className='flex-1'>
          <Field
            containerClassName='flex-1'
            label='Date Range'
            name='date-range'
            type='tel'
            placeholder='Select'
            value={dateLabel}
            onClick={e => renderPicker(e)}
            onChange={() =>
              handleFormDataChange('ranges', [
                {
                  startDate: '',
                  endDate: '',
                  key: 'dateRange',
                },
              ])
            }
          />
        </div>
      </div>
      <DateRangeModal
        open={dateOpen}
        toggle={toggleModal}
        onChange={e => {
          const { dateRange } = e
          // FormData 会出发 api 请求，onChange，数据暂存
          setDateRangeData({
            startDate: moment(dateRange.startDate).format(),
            endDate: moment(dateRange.endDate).format(),
          })
        }}
        onConfirm={() => {
          handleFormDataChange('ranges', [
            {
              startDate: dateRangeData.startDate,
              endDate: dateRangeData.endDate,
              key: 'dateRange',
            },
          ])
        }}
        ranges={[
          {
            startDate: new Date(dateRangeData.startDate),
            endDate: new Date(dateRangeData.endDate),
            key: 'dateRange',
          },
        ]}
      />
    </div>
  )
}
