import { createAction } from '@reduxjs/toolkit'
import type { TOrderHistoryFormData } from 'types/orderHistory'
import axios from 'utils/axiosApi'

export const setFormData = createAction<TOrderHistoryFormData>(
  'orderHistory/setFormData',
)
export const setTableData = createAction<{
  total: number
  data: any[]
}>('orderHistory/setTableData')

export const resetFilters = createAction('orderHistory/resetFilters')
export const setCurrentLocationId = createAction<string>(
  'orderHistory/setCurrentLocationId',
)

export type TOrderListParams = {
  status?: string
  keyword?: string
  coupon_code?: string
  begin_time?: string
  end_time?: string
  page?: number
  per_page?: number
}
export async function getOrderListApi(
  params: TOrderListParams,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/orders`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params,
  })
  return response.data
}

export async function getOrderSummaryApi(
  params: TOrderListParams,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/orderSummary`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params,
  })
  return response.data
}

export async function getOrderDetailApi(
  id: string | number | undefined,
  locationId: string | undefined,
  params?: any,
) {
  const response = await axios.request({
    url: `locations/${locationId}/orders/${id}`,
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

type Trefund = {
  order_id: string | number | undefined
  amount: string | number
  reason_to_cancel?: string
}
export async function refundOrderApi(
  locationId: string | undefined,
  params: Trefund,
) {
  const response = await axios.request({
    url: `locations/${locationId}/order/refund`,
    data: params,
    method: 'post',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
