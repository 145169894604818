import { createReducer } from '@reduxjs/toolkit'

import { getMarketingFlyer, getCoupons } from './actions'

export type TCoupon = {
  amount_off: null | string
  auto_display: number
  brand_id: number
  campaign_offer_id: null | number
  code: string
  description: string
  discount_coverage: null
  end_date: string | null
  first_order_only: number
  id: number
  location_id: number
  menu_item_id: null | number
  percentage_off: number
  start_date: string
  status: string
  threshold: string
  type: string
  usage_limit: number
  usage_type: string
  user_id: number
  uuid: null | string
  winning_probability: string
}

type TMarketingFlyerState = {
  getMarketingFlyerRequest: boolean
  getCouponsRequest: boolean
  coupons: TCoupon[]
}

const initialState: TMarketingFlyerState = {
  getMarketingFlyerRequest: false,
  getCouponsRequest: false,
  coupons: [],
}

export const marketingFlyerReducer = createReducer(initialState, builder => {
  builder
    .addCase(getMarketingFlyer.pending, state => {
      state.getMarketingFlyerRequest = true
    })
    .addCase(getMarketingFlyer.fulfilled, state => {
      state.getMarketingFlyerRequest = false
    })
    .addCase(getMarketingFlyer.rejected, state => {
      state.getMarketingFlyerRequest = false
    })
    .addCase(getCoupons.pending, state => {
      state.getCouponsRequest = true
    })
    .addCase(getCoupons.fulfilled, (state, action) => {
      state.getCouponsRequest = false
      state.coupons = action.payload
    })
    .addCase(getCoupons.rejected, state => {
      state.getCouponsRequest = false
    })
})

export default marketingFlyerReducer
