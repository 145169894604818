import React, { useState, useRef } from 'react'

import { SolidCalendar } from '@rushable/icons'
import cn from 'classnames'
import Field from 'components/Field'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

export type TCalendarFieldProp = {
  value: string
  align?: 'left' | 'center' | 'right'
  label?: string | React.ReactElement
  disabled?: boolean
  placeholder?: string
  date?: Date | undefined
  className?: string
  inputClass?: string
  calendarClassName?: string
  onChange?: (event: any) => void
  hasBorder?: boolean
  calendarRestProps?: Record<string, any>
}

export default function CalendarField({
  label,
  align,
  value,
  disabled,
  date,
  onChange,
  className = '',
  inputClass = '',
  calendarClassName = '',
  placeholder,
  hasBorder = true,
  calendarRestProps = {},
}: TCalendarFieldProp): JSX.Element {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const calendarChange = (v: any) => {
    onChange && onChange(v)
    setOpen(false)
  }

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)
  return (
    <div className={cn('relative', className)} ref={ref}>
      <Field
        align={align}
        label={label}
        className={inputClass}
        disabled={disabled}
        name='calendar-input'
        type='text'
        autoComplete='off'
        value={value}
        suffix={<SolidCalendar className='text-silver' size={16} />}
        onChange={() => null}
        placeholder={placeholder}
        onClick={() => setOpen(true)}
      />
      {open && (
        <div>
          <Calendar
            className={cn(
              `absolute z-20 rounded-lg border-field ${calendarClassName}`,
              { 'border-2': hasBorder },
            )}
            editableDateInputs={true}
            onChange={calendarChange}
            date={date ?? new Date()}
            direction='horizontal'
            rangeColors={['#008DFF']}
            dateDisplayFormat='yyyy-MM-dd'
            {...calendarRestProps}
          />
        </div>
      )}
    </div>
  )
}
