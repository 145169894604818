import { useEffect, useState } from 'react'

import DateRangeModal from 'components/DateRangeModal'
import Field from 'components/Field'
import { Select } from 'components/Select'
import moment from 'moment'
import { ORDER_HISTORY_STATUS_OPTIONS } from 'pages/orderHistory/helpers/constant'
import { useAppSelector } from 'redux/hooks'

type TFormFilterProps = {
  handleFormDataChange: (key: string, value: any) => void
}

export default function FormFilter({ handleFormDataChange }: TFormFilterProps) {
  const { formData } = useAppSelector(state => state.orderHistory)
  const { keyword, status, ranges, couponCode } = formData
  const [dateOpen, setDateOpen] = useState(false)
  const [dateLabel, setDateLabel] = useState('')
  const [dateRangeData, setDateRangeData] = useState({
    startDate: moment().format(),
    endDate: moment().format(),
  })

  const renderPicker = (e: any) => {
    e.preventDefault()
    toggleModal()
  }

  const toggleModal = () => {
    if (!dateOpen && !ranges[0]) {
      handleFormDataChange('ranges', [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'dateRange',
        },
      ])
    }
    setDateOpen(!dateOpen)
  }

  useEffect(() => {
    if (ranges[0].startDate && ranges[0].endDate) {
      const label = ranges[0]
        ? moment(ranges[0].startDate).format('MM/DD/YY') +
          ' - ' +
          moment(ranges[0].endDate).format('MM/DD/YY')
        : ''
      setDateLabel(label)
    } else {
      setDateLabel('')
    }
  }, [ranges])

  return (
    <div className='grid grid-cols-4 gap-6 items-end pb-6 border-b border-dashed border-zinc'>
      <Field
        containerClassName='w-full'
        label='Keyword'
        name='keyword'
        value={keyword}
        type='tel'
        placeholder='Order ID, Customer'
        onChange={e => handleFormDataChange('keyword', e.target.value)}
      />
      <Select
        options={ORDER_HISTORY_STATUS_OPTIONS}
        placeholder='Select'
        value={status}
        label='Status'
        className='w-full'
        onChange={value => handleFormDataChange('status', value)}
      />
      <Field
        containerClassName='w-full'
        label='Date Range'
        name='date-range'
        type='tel'
        value={dateLabel}
        placeholder='Select'
        onClick={e => renderPicker(e)}
        onChange={() =>
          handleFormDataChange('ranges', [
            {
              startDate: '',
              endDate: '',
              key: 'dateRange',
            },
          ])
        }
      />
      <Field
        containerClassName='w-full'
        label='Coupon Used'
        name='coupon used'
        value={couponCode}
        type='text'
        placeholder='Coupon code'
        onChange={e => handleFormDataChange('couponCode', e.target.value)}
      />
      <DateRangeModal
        open={dateOpen}
        toggle={toggleModal}
        onChange={e => {
          const { dateRange } = e
          // FormData 会出发 api 请求，onChange，数据暂存
          setDateRangeData({
            startDate: moment(dateRange.startDate).format(),
            endDate: moment(dateRange.endDate).format(),
          })
        }}
        onConfirm={() => {
          handleFormDataChange('ranges', [
            {
              startDate: dateRangeData.startDate,
              endDate: dateRangeData.endDate,
              key: 'dateRange',
            },
          ])
        }}
        ranges={[
          {
            startDate: new Date(dateRangeData.startDate),
            endDate: new Date(dateRangeData.endDate),
            key: 'dateRange',
          },
        ]}
      />
    </div>
  )
}
