import React, { useState } from 'react'

import Button from 'components/Button'
import { Select } from 'components/Select'
import Timeline from 'components/Timeline'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateLoyaltyConfigAPI } from 'redux/brand/actions'

import { STEP_LIST, SAVE_TIME, SIGNUP_POINTS } from '../helpers/constant'

type TOtherSettingProp = {
  formData: any
  onFormChange: (data: any) => void
  updateRewardList: () => void
}

export default function OtherSetting({
  formData,
  onFormChange,
  updateRewardList,
}: TOtherSettingProp): JSX.Element {
  const [loading, setLoading] = useState(false)
  const { brandId } = useParams<TParamTypes>()

  const updateStatus = async () => {
    setLoading(true)
    try {
      const params = {
        id: formData.id,
        current_step: 'activate',
        can_use_with_coupon:
          formData.can_use_with_coupon === null
            ? 0
            : formData.can_use_with_coupon,
        customer_initial_points: formData.customer_initial_points || '300',
      }

      const res = await updateLoyaltyConfigAPI(params, brandId)
      toast.success(res.message)
      updateRewardList()
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <div className='flex flex-col items-center  space-y-8 mt-8'>
      <Timeline className='w-[720px]' list={STEP_LIST} current={3} isEqual />
      <div className='w-[720px] mt-8 flex flex-col items-center  space-y-8 text-ink'>
        <div className='w-full px-6 py-4 border border-solid border-zinc rounded-lg flex justify-between items-center text-ink'>
          <div>
            Can customers use both a reward and a coupon at the same time?
          </div>
          <Select
            className='w-36'
            options={SAVE_TIME}
            placeholder='Select'
            value={
              formData.can_use_with_coupon === null
                ? 0
                : formData.can_use_with_coupon
            }
            onChange={value => onFormChange({ can_use_with_coupon: value })}
          />
        </div>
        <div className='w-full px-6 py-4 border border-solid border-zinc rounded-lg flex justify-between items-center text-ink'>
          <div>Points to grant when there is a new customer account signup</div>
          <Select
            className='w-36'
            options={SIGNUP_POINTS}
            placeholder='Select'
            value={formData.customer_initial_points || '300'}
            onChange={value => onFormChange({ customer_initial_points: value })}
          />
        </div>

        <div className='w-full flex space-x-6'>
          <Button
            className='w-[200px]'
            color={'secondary'}
            onClick={() => onFormChange({ current_step: 'rewards_setting' })}
          >
            Go back
          </Button>
          <Button
            loading={loading}
            className='flex-1'
            color={'primary'}
            onClick={() => updateStatus()}
          >
            Complete, activate rewards feature now
          </Button>
        </div>
      </div>
    </div>
  )
}
