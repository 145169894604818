import React from 'react'

import { AngleRight, SolidMapPin } from '@rushable/icons'
import cn from 'classnames'
import { TLocation } from 'types/location'

export type TLocationSelectorProp = {
  isActive?: boolean
  location: TLocation
  onSelect: (v: TLocation) => void
}

export default function LocationSelector({
  location,
  onSelect,
}: TLocationSelectorProp): JSX.Element {
  return (
    <div
      className={cn(
        'location-selector',
        'rounded-lg w-full flex items-center p-4 mt-4 first:mt-4 last:mb-0',
        ' text-silver bg-slate hover:bg-blue hover:text-white',
      )}
      role='button'
      onClick={() => onSelect(location)}
    >
      <SolidMapPin size={16} />
      <div className='ml-4 flex-1'>
        <h5 className='location-name text-base text-ink'>{location.name}</h5>
        <p className='text-xs'>{location.address?.line_1}</p>
      </div>
      <AngleRight size={16} />
    </div>
  )
}
