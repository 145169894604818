import SkeletonText from 'components/Skeleton/SkeletonText'

export default function SkeletonItem(): JSX.Element {
  return (
    <div className='pr-6 w-full'>
      <SkeletonText className='h-[38px] w-full mt-2' />
      <SkeletonText className='h-[38px] w-full mt-2' />
      <SkeletonText className='h-[38px] w-full mt-2' />
    </div>
  )
}

export function PhoneSkeletonItem(): JSX.Element {
  return (
    <div className='grid grid-cols-2 gap-4 mb-4 mt-6'>
      <SkeletonText className='h-[68px]' />
      <SkeletonText className='h-[68px]' />
      <SkeletonText className='h-[68px]' />
      <SkeletonText className='h-[68px]' />
      <SkeletonText className='h-[68px]' />
      <SkeletonText className='h-[68px]' />
      <SkeletonText className='h-[68px]' />
      <SkeletonText className='h-[68px]' />
    </div>
  )
}
