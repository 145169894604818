import { useEffect, useState } from 'react'

import {
  FullClover,
  FullSquare,
  Check,
  FullItsaCheckMate,
} from '@rushable/icons'
import cn from 'classnames'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import useSearch from 'hooks/useSearch'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import {
  getIntegrationInfoApi,
  updateSquareStatus,
  updateCloverStatus,
} from 'redux/integration'

import IntegrationDetail from './component/Detail'

type TIntegrationType = 'clover' | 'square' | 'check_mate'

export type TypeConnects = {
  square: {
    enabled: 0 | 1
    id: number
    location_id: number
    pos_type: string
    token_expires_at: string | null
  } | null
  clover: {
    enabled: 0 | 1
    id: number
    location_id: number
    pos_type: string
    token_expires_at: string | null
  } | null
  check_mate: {
    external_location_id: string
    market_place_url: string
    token_expires_at: string | null
  } | null
  google?: null
}

export default function IntegrationPage(): JSX.Element {
  const { locationId } = useParams<{ locationId: string }>()
  const { square_oauth, clover_oauth } = useSearch()
  const [connect, setConnect] = useState<TypeConnects>({
    square: null,
    clover: null,
    check_mate: null,
  })
  const [connectLinks, setConnectLinks] = useState({
    check_mate: '',
    clover: '',
    square: '',
  })
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  // 目前只有一个 square
  const [openType, setOpenType] = useState<TIntegrationType>('square')
  const [squareBtnLoading, setSquareBtnLoading] = useState(false)
  const [cloverBtnLoading, setCloverBtnLoading] = useState(false)

  useEffect(() => {
    getIntegrationInfo()
    if (square_oauth === 'square_first') {
      setOpen(true)
      setOpenType('square')
    }
    if (clover_oauth === 'clover_first') {
      setOpen(true)
      setOpenType('clover')
    }
  }, [])

  const getIntegrationInfo = async () => {
    setLoading(true)
    await handleGetIntegration()
    setLoading(false)
  }

  const handleGetIntegration = async () => {
    try {
      const res = await getIntegrationInfoApi(locationId)
      if (res && res?.connect_link) {
        setConnectLinks(res?.connect_link)
        setConnect({
          square: res?.location_pos_config_square,
          clover: res?.location_pos_config_clover,
          check_mate: res?.location_check_mate_config,
        })
      }
    } catch (err: any) {
      setLoading(false)
      const msg = err.message || 'Request error'
      toast.error(msg)
    }
  }

  const handleUpdateSquareStatus = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    if (connect?.square?.id) {
      try {
        setSquareBtnLoading(true)
        const res = await updateSquareStatus(locationId, connect?.square?.id)
        if (res?.message) {
          await handleGetIntegration()
        }
      } catch (err: any) {
        const msg = err.message || 'Request error'
        toast.error(msg)
      } finally {
        setSquareBtnLoading(false)
      }
    }
  }

  const handleUpdateCloverStatus = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    if (connect?.clover?.id) {
      try {
        setCloverBtnLoading(true)
        const res = await updateCloverStatus(locationId, connect?.clover?.id)
        if (res?.message) {
          await handleGetIntegration()
        }
      } catch (err: any) {
        const msg = err.message || 'Request error'
        toast.error(msg)
      } finally {
        setCloverBtnLoading(false)
      }
    }
  }

  const cls =
    'w-[560px] h-[72px] m-auto border border-zinc rounded-lg px-6 flex justify-between items-center'

  const renderConnectBtn = (type: TIntegrationType) => {
    const connected = !!connect[type]?.token_expires_at
    const enabled =
      type === 'square' || type === 'clover' ? connect[type]?.enabled : 0
    if (connected) {
      return (
        <div className='flex'>
          <div className='flex'>
            <Button color='ink-link' size='sm' className='pr-2'>
              <div className='mr-1 bg-blue rounded-full flex items-center justify-center w-3 h-3'>
                <Check size={8} className='text-white' />
              </div>
              CONNECTED
            </Button>
            {type === 'square' ? (
              <Button
                className='w-24 ml-4'
                size='sm'
                color='tertiary'
                loading={squareBtnLoading}
                onClick={handleUpdateSquareStatus}
              >
                {enabled ? 'PAUSE' : 'RESUME'}
              </Button>
            ) : null}
            {type === 'clover' ? (
              <Button
                className='w-24 ml-4'
                size='sm'
                color='tertiary'
                loading={cloverBtnLoading}
                onClick={handleUpdateCloverStatus}
              >
                {enabled ? 'PAUSE' : 'RESUME'}
              </Button>
            ) : null}
          </div>
        </div>
      )
    }
    return (
      <Button
        className='w-24'
        size='sm'
        onClick={() => window.open(connectLinks[type])}
      >
        CONNECT
      </Button>
    )
  }
  return (
    <AdminPage>
      <AdminPageTitle title='Integration' />
      <Spin spining={loading} className='z-50'>
        <>
          <div>
            {/* <div className={cn(cls, 'mt-10')}>
          <FullGbp size={108} />
          {renderConnectBtn('google', 'complete')}
        </div> */}
            <div
              className={cn(cls, 'mt-6', {
                'cursor-pointer': !!connect['clover']?.token_expires_at,
              })}
              onClick={() => {
                if (connect['clover']?.token_expires_at) {
                  setOpen(true)
                  setOpenType('clover')
                }
              }}
            >
              <FullClover size={75} />
              {renderConnectBtn('clover')}
            </div>
            <div
              className={cn(cls, 'mt-6', {
                'cursor-pointer': !!connect['square']?.token_expires_at,
              })}
              onClick={() => {
                if (connect['square']?.token_expires_at) {
                  setOpen(true)
                  setOpenType('square')
                }
              }}
            >
              <FullSquare size={75} />
              {renderConnectBtn('square')}
            </div>
            <div className={cn(cls, 'mt-6')}>
              <FullItsaCheckMate size={112} />
              {renderConnectBtn('check_mate')}
            </div>
          </div>
          <IntegrationDetail
            open={open}
            type={openType}
            connects={connect}
            handleGetIntegration={handleGetIntegration}
            toggle={() => setOpen(false)}
          />
        </>
      </Spin>
    </AdminPage>
  )
}
