import { useMemo } from 'react'

import { LinedStore, LinedCar, LinedUser } from '@rushable/icons'
import cn from 'classnames'
import moment from 'moment'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

type TEventItemProps = {
  className?: string
  type?: string
  formData: Record<string, any>
  eventItem?: any
  timezone: string
}

export default function EventItem({
  className,
  type,
  formData,
  eventItem,
  timezone,
}: TEventItemProps) {
  const sameOrFutureDay = (dt1: string | number, dt2: string | number) => {
    return moment(dt1).format('MM-DD') === moment(dt2).format('MM-DD')
      ? 'on the same day'
      : 'on the future day'
  }
  const showTimeAndIcon = useMemo(() => {
    switch (type) {
      case 'create':
        return {
          time: formData?.created_at,
          icon: <LinedUser size={16} />,
        }
      case 'canceled':
        return {
          time:
            formData.status === 'canceled'
              ? formData?.canceled_at
              : formData.confirmed_at,
          icon: <LinedStore size={16} />,
        }
      default:
        return {
          time: eventItem?.created_at,
          icon:
            eventItem.event === 'delivery_attempted' ? (
              <LinedStore size={16} />
            ) : (
              <LinedCar size={16} />
            ),
        }
    }
  }, [formData, eventItem, type])

  return (
    <div className={cn('flex', className)}>
      <div className='inline-block mr-4' style={{ minWidth: '99px' }}>
        <div className='bg-slate w-[98px] h-7 px-2 flex items-center justify-between text-sm text-lead'>
          {showTimeAndIcon.icon}
          {parseServerTimeToLocalTime(
            showTimeAndIcon.time,
            timezone,
            'hh:mm A',
          )}
        </div>
      </div>
      {type === 'create' ? (
        <div className='mt-1'>
          Order submitted for{' '}
          {formData.request_at <= formData.created_at ? 'ASAP' : 'scheduled'}{' '}
          {formData.type === 'daas_delivery' ? 'delivery' : formData.type}
        </div>
      ) : null}
      {type === 'canceled' ? (
        formData.status === 'canceled' ? (
          <>
            <div className='mt-1'>
              Order canceled by the restaurant at{' '}
              {parseServerTimeToLocalTime(
                formData.canceled_at,
                timezone,
                'hh:mm A',
              )}{' '}
              {sameOrFutureDay(formData.canceled_at, formData.created_at)}
            </div>
          </>
        ) : (
          <>
            <div className='mt-1'>
              Order confirmed to be ready{' '}
              {['pickup', 'delivery', 'daas_delivery'].includes(
                formData.type,
              ) &&
                `for${' '}${
                  formData.type === 'daas_delivery' ? 'delivery' : formData.type
                }${' '}`}
              at{' '}
              {parseServerTimeToLocalTime(
                formData?.estimated_ready_time,
                timezone,
                'hh:mm A',
              )}{' '}
              {sameOrFutureDay(
                formData.request_at,
                formData?.estimated_ready_time,
              )}
            </div>
          </>
        )
      ) : null}
      {eventItem?.event === 'delivery_created' && (
        <div className='mt-1'>
          DoorDash estimated driver pickup at{' '}
          {parseServerTimeToLocalTime(
            formData?.daas_deliveries[0]?.estimated_pickup_time,
            timezone,
            'hh:mm A',
          )}{' '}
          {sameOrFutureDay(
            formData?.daas_deliveries[0]?.estimated_pickup_time,
            formData?.created_at,
          )}
        </div>
      )}
      {eventItem?.event === 'dasher_confirmed' && (
        <div className='mt-1'>
          Driver assigned to {formData?.daas_deliveries[0]?.driver_first_name}{' '}
          {formData?.daas_deliveries[0]?.driver_last_name}
        </div>
      )}
      {eventItem?.event === 'dasher_confirmed_store_arrival' && (
        <div className='mt-1'>Driver store arrival</div>
      )}
      {eventItem?.event === 'delivery_cancelled' && (
        <div className='mt-1 font-bold'>
          <span className='text-red'>Driver cancelled service</span> (reason
          given: {eventItem.note})
        </div>
      )}
      {eventItem?.event === 'delivery_attempted' && (
        <div className='mt-1'>
          Delivery Attempted, but the customer was not available
        </div>
      )}
      {eventItem?.event === 'dasher_picked_up' && (
        <div className='mt-1'>
          Driver picked up order and enroute to the customer
        </div>
      )}
      {eventItem?.event === 'dasher_confirmed_consumer_arrival' && (
        <div className='mt-1'>Driver arrived at the customer</div>
      )}
      {eventItem?.event === 'dasher_dropped_off' && (
        <div className='mt-1 font-bold text-green'>Order delivered</div>
      )}
    </div>
  )
}
