import { TOKEN_KEY } from 'data/constant'
import Cookies from 'js-cookie'
import moment from 'moment'

export const getAccessToken = (): string | undefined => {
  return Cookies.get(TOKEN_KEY)
}

export const saveAccessToken = (
  access_token: string,
  expires_at: Date,
): void => {
  Cookies.set(TOKEN_KEY, access_token, {
    expires: moment(expires_at).diff(moment(), 'days'),
  })
}

export const removeAccessToken = (): void => {
  Cookies.remove(TOKEN_KEY)
}
