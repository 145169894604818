import React, { useEffect, useState } from 'react'

import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import CodeInput from 'components/CodeInput'
import Field from 'components/Field'
import { Spin } from 'components/Loading'
import Tips from 'components/Tips'
import { TERMSOFSERVICE } from 'data/routes'
import useCountdown from 'hooks/useCountDown'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import {
  getInvitationAPI,
  acceptInvitationAPI,
  sendCode,
  validateInvitationAPI,
  setCodeErrorMsg,
  setValidateErrorMsg,
} from 'redux/user'
import { removeAccessToken } from 'utils/auth'
import { formatPhoneNumber, formatDigitOnly } from 'utils/digit'

const COUNTDOWN_SECONDS = 60000
export default function UserInvitationPage(): JSX.Element {
  const baseFormData = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: '',
    code: '',
  }
  const dispatch = useAppDispatch()
  const { codeErrorMsg, validateErrorMsg } = useAppSelector(state => state.user)
  const { token } = useParams<TParamTypes>()
  const [formData, setFormData] = useState<Record<string, any>>(baseFormData)
  const [checked, setChecked] = useState(false)
  const [step, setStep] = useState(0)
  const [newTime, setNewTime] = useState(new Date().getTime())
  const [timeLeft, formattedRes] = useCountdown({
    targetDate: newTime + COUNTDOWN_SECONDS,
  })
  const { seconds } = formattedRes
  const [loading, setLoading] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)

  const onFormChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    })
    if (name === 'code' && value.length === 4) {
      acceptInvitation(value)
    }
  }

  useEffect(() => {
    removeAccessToken()
    getInvitation()
  }, [])

  useEffect(() => {
    if (step === 1) {
      setNewTime(new Date().getTime())
    }
  }, [step])

  const getReqParam = () => {
    return {
      token,
      first_name: formData.first_name,
      last_name: formData.last_name,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
      phone: formatDigitOnly(formData.phone),
      email: formData.email,
    }
  }

  // 点击 continue 校验
  const continueValidate = async () => {
    setSendLoading(true)
    try {
      const reqParam = getReqParam()
      await validateInvitationAPI(reqParam)
      dispatch(setValidateErrorMsg(''))
      setStep(prev => prev + 1)
      handleSendCode()
    } catch (e: any) {
      if (
        e.message.includes('phone') ||
        e.message.includes('password') ||
        e.message.includes('email')
      ) {
        dispatch(setValidateErrorMsg(e.message))
      } else {
        const msg = e.message || 'request error'
        toast.error(msg)
      }
    }
    setSendLoading(false)
  }
  // 发送验证码
  const handleSendCode = async (phone = '') => {
    setSendLoading(true)
    try {
      const params = {
        phone: formatDigitOnly(formData.phone || phone),
      }
      const res: any = await sendCode(params)
      dispatch(setCodeErrorMsg(''))
      toast.success(res.message)
    } catch (e: any) {
      dispatch(setCodeErrorMsg(e.message || 'request error'))
    }
    setSendLoading(false)
  }
  // 初始化接口
  const getInvitation = async () => {
    setLoading(true)
    try {
      const res = await getInvitationAPI({ token })
      setFormData({ ...formData, ...res })
      if (res.user_id) {
        setStep(1)
        handleSendCode(res.phone)
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }
  // 接受邀请
  const acceptInvitation = async (code: string) => {
    setLoading(true)
    try {
      const reqParam = getReqParam()
      const res = await acceptInvitationAPI({
        ...reqParam,
        verification_code: code,
      })
      dispatch(setCodeErrorMsg(''))
      toast.success(res.message)
      setStep(prev => prev + 1)
    } catch (e: any) {
      dispatch(setCodeErrorMsg(e.message || 'request error'))
    }
    setLoading(false)
  }

  return (
    <div
      className={'w-[560px] mb-[145px] bg-white mx-auto rounded-xl pt-8 pb-4'}
    >
      <div className='border-b border-dashed border-zinc pb-2 mx-8'>
        <h3 className='text-ink font-bold mb-6 text-center'>
          Account Invitation
        </h3>
        {step === 2 ? (
          <p className='text-center text-lead text-base'>
            You now have access to the following location(s) of Taste of
            Nations:
          </p>
        ) : (
          <p className='text-center text-lead text-base'>
            {formData?.inviter?.first_name} {formData?.inviter?.last_name} from
            Taste of Nations is adding you as a{' '}
            {formData?.role === 'location_owner'
              ? 'Location Admin to manage the following'
              : 'Brand Admin to manage all'}{' '}
            location(s):
          </p>
        )}

        <div className='flex justify-center flex-wrap  pt-2 pb-6  mr-[-8px] mb-[-8px]'>
          {formData?.invitation_locations?.map((item: any) => {
            return (
              <div
                key={item.location.name}
                className='flex justify-center items-center h-[22px] px-2 py-0.5 rounded bg-slate text-xs text-lead font-medium mr-2 mt-2'
              >
                {item.location.name}
              </div>
            )
          })}
        </div>
      </div>
      <Spin spining={loading}>
        <>
          {step === 0 && (
            <>
              <div className='grid gap-6 pt-6 px-8'>
                <div className='grid grid-cols-2 gap-4'>
                  <Field
                    label='First name'
                    name='first_name'
                    value={formData?.first_name}
                    onChange={e => onFormChange('first_name', e.target.value)}
                    type='text'
                  />
                  <Field
                    label='Last name'
                    name='last_name'
                    value={formData?.last_name}
                    onChange={e => onFormChange('last_name', e.target.value)}
                    type='text'
                  />
                </div>
                <Field
                  label='Email'
                  name='email'
                  value={formData?.email || ''}
                  onChange={e => onFormChange('email', e.target.value)}
                  type='email'
                  error={
                    validateErrorMsg.includes('email') ? validateErrorMsg : ''
                  }
                />
                <Field
                  label='Cellphone'
                  name='CellPhone'
                  value={formData?.phone}
                  inputMode='tel'
                  onChangePhone={value => onFormChange('phone', value)}
                  type='tel'
                  error={
                    validateErrorMsg.includes('phone') ? validateErrorMsg : ''
                  }
                />
                <div className='grid grid-cols-2 gap-4'>
                  <Field
                    label='Create password'
                    name='password'
                    value={formData?.password}
                    onChange={e => onFormChange('password', e.target.value)}
                    type='password'
                    error={
                      validateErrorMsg.includes('password')
                        ? validateErrorMsg
                        : ''
                    }
                  />
                  <Field
                    label='Repeat password'
                    name='repeat_password'
                    error={
                      formData.password &&
                      formData.password_confirmation &&
                      formData.password !== formData.password_confirmation &&
                      'password not matched'
                    }
                    value={formData?.password_confirmation}
                    onChange={e =>
                      onFormChange('password_confirmation', e.target.value)
                    }
                    type='password'
                  />
                </div>
              </div>
              <div className='flex justify-center items-center my-6 text-xs text-center'>
                <CheckBox
                  size={12}
                  checked={checked}
                  onChange={e => setChecked(e)}
                  shape='circle'
                >
                  <>
                    I accept the
                    <a
                      className='text-blue font-bold cursor-pointer mx-1'
                      href={`${location.origin}${TERMSOFSERVICE}`}
                    >
                      Terms of Service
                    </a>
                    and
                    <a
                      className='text-blue font-bold cursor-pointer mx-1'
                      href={`${location.origin}${TERMSOFSERVICE}`}
                    >
                      Privacy Policy
                    </a>
                    of Rushable.
                  </>
                </CheckBox>
              </div>
              <div className='px-8 pt-4 mt-6 border-t border-solid border-zinc'>
                <Button
                  className='w-full'
                  loading={sendLoading}
                  disabled={
                    !formData.first_name ||
                    !formData.last_name ||
                    !formData.phone ||
                    !formData.email ||
                    !formData.password ||
                    formData.password !== formData.password_confirmation ||
                    !checked
                  }
                  color='primary'
                  onClick={() => continueValidate()}
                >
                  CONTINUE
                </Button>
              </div>
            </>
          )}
          {step === 1 && (
            <>
              <div className='pt-6 px-8'>
                <p className='text-center text-lead'>
                  {`Please input the code sent to ${
                    formatPhoneNumber(formData.phone) || formData.phone
                  }`}
                </p>
                <CodeInput
                  className='mt-6'
                  length={4}
                  fieldWidth={80}
                  onChange={value => onFormChange('code', value)}
                />
                {codeErrorMsg && <Tips className='mt-2' text={codeErrorMsg} />}
              </div>
              <div className='flex space-x-4 px-8 pt-4 mt-6 border-t border-solid border-zinc'>
                <Button
                  className='flex-1'
                  onClick={() => setStep(prev => prev - 1)}
                  color='secondary'
                >
                  GO BACK
                </Button>
                <Button
                  className='flex-1'
                  onClick={() => {
                    handleSendCode()
                    setNewTime(new Date().getTime())
                  }}
                  disabled={seconds > 0}
                  color='tertiary'
                >
                  RESEND {seconds > 0 && `(${seconds})`}
                </Button>
              </div>
            </>
          )}
          {step === 2 && (
            <Button
              onClick={() => location.replace(location.origin)}
              color='primary'
              className='mx-auto mt-4'
            >
              CONTINUE TO DASHBOARD
            </Button>
          )}
        </>
      </Spin>
    </div>
  )
}
