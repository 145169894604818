import React, { useState, useEffect } from 'react'

import Field from 'components/Field'
import OptionBox from 'components/OptionBox'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { getSquareLocations } from 'redux/integration'

import SkeletonItem from './SkeletonItem'

type TSquareProps = {
  id?: number
  selectId: string
  onSelect: (id: string) => void
}

export default function Square({ id, selectId, onSelect }: TSquareProps) {
  const { locationId } = useParams<{ locationId: string }>()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [squareLocations, setSquareLocations] = useState([])
  const handleGetSquareLocations = async () => {
    try {
      setLoading(true)
      const res = await getSquareLocations(locationId, id)
      if (res) {
        setEmail(res?.merchant?.owner_email || '')
        onSelect(res?.square_location_id || '')
        setSquareLocations(res?.square_locations || [])
      }
    } catch (err: any) {
      const msg = err.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetSquareLocations()
  }, [])
  return (
    <div>
      {loading ? (
        <>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </>
      ) : (
        <>
          <Field
            className='mb-6'
            label='Account email'
            name='Account_email'
            value={email}
            disabled
          />
          <div>
            <div className='mb-2 font-bold text-silver text-xs'>
              Connect location account
            </div>
            {squareLocations.map((loc: any) => {
              return (
                <OptionBox
                  className='mb-2'
                  selected={loc.id === selectId}
                  title={loc.name}
                  badge={loc.id}
                  description={`${loc?.address?.address_line_1}, ${loc?.address?.locality}, ${loc?.address?.country} ${loc?.address?.postal_code} `}
                  onSelect={() => {
                    onSelect(loc.id)
                  }}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
