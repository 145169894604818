import { createReducer } from '@reduxjs/toolkit'
import type { TOrderHistoryFormData } from 'types/orderHistory'

import {
  setFormData,
  setTableData,
  resetFilters,
  setCurrentLocationId,
} from './actions'

type TOrderHistoryState = {
  lockModalOpen: boolean
  formData: TOrderHistoryFormData
  tableData: {
    total: number
    data: any[]
  }
  currentLocationId: string
}

export const initFormData: TOrderHistoryFormData = {
  keyword: '',
  status: '',
  ranges: [
    {
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
      key: 'dateRange',
    },
  ],
  couponCode: '',
  page: 1,
  pageSize: 20,
}

const initialState: TOrderHistoryState = {
  lockModalOpen: false,
  formData: initFormData,
  tableData: {
    total: 0,
    data: [],
  },
  currentLocationId: '',
}

export const orderHistoryReducer = createReducer(initialState, builder => {
  builder
    .addCase(setFormData, (state, actions) => {
      state.formData = actions.payload
    })
    .addCase(setTableData, (state, actions) => {
      state.tableData = actions.payload
    })
    .addCase(resetFilters, state => {
      state.formData = initFormData
    })
    .addCase(setCurrentLocationId, (state, action) => {
      state.currentLocationId = action.payload
    })
})

export default orderHistoryReducer
