import React, { HTMLInputTypeAttribute, ReactElement, Ref } from 'react'

import cn from 'classnames'
import Label from 'components/Label'
import Switch from 'components/Switch'
import {
  onPhoneKeyDown,
  onPhoneKeyUp,
  isNumber,
  formatPhoneNumberPart,
  formatCurrencyString,
  onMoneyKeyDown,
} from 'utils/digit'

export type TFieldProp = {
  type?: HTMLInputTypeAttribute
  align?: 'left' | 'center' | 'right'
  label?: string | ReactElement | ReactElement[]
  inputLabel?: string // input 里面的label，CheckBox 情况下需要的
  placeholder?: string
  disabled?: boolean
  error?: string
  suffix?: string | React.ReactNode
  append?: string | React.ReactNode
  name: string
  value?: string | number
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChangePhone?: (e: string) => void
  onChangeMoney?: (e: string) => void
  maxLength?: number
  className?: string
  containerClassName?: string
  autoComplete?: string
  inputMode?:
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url'
    | 'money'
}

const Field = React.forwardRef(
  (
    {
      value,
      name,
      type = 'text',
      className = '',
      placeholder,
      disabled = false,
      align = 'left',
      onChange,
      onBlur,
      onClick,
      onChangePhone,
      onChangeMoney,
      append,
      suffix,
      containerClassName,
      error = '',
      label,
      checked,
      inputLabel,
      autoComplete,
      inputMode = 'text',
      ...args
    }: TFieldProp,
    ref: Ref<HTMLInputElement>,
  ) => {
    const style = {
      disabled: 'cursor-not-allowed bg-ice border-ice placeholder-dark-16',
      default:
        'border-2 text-base w-full rounded-lg py-3 px-4 text-ink outline-none focus:bg-white',
    }

    const inputClasses = cn(
      style.default,
      disabled
        ? style.disabled
        : 'bg-field border-field hover:bg-white hover:border-blue',
      align === 'center'
        ? 'text-center'
        : align === 'right'
        ? 'text-right'
        : '',
      error ? 'focus:border-red' : 'focus:border-blue',
      { 'pl-9': suffix },
      { 'pr-9': append },
    )

    let inputField = (
      <input
        className={cn(className, inputClasses)}
        ref={ref}
        type={type}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        value={value}
        autoComplete={autoComplete}
        {...args}
      />
    )

    // 外部 label （左 + 右）
    let labelCtrl
    if (label) {
      labelCtrl = (
        <Label
          className='mb-2 w-full flex justify-between'
          htmlFor={name}
          color='silver'
        >
          {label}
        </Label>
      )
    }

    if (type === 'checkbox') {
      return (
        <div className={containerClassName}>
          {labelCtrl}
          <div className={cn(inputClasses, 'flex')}>
            <div className='flex-1 text-base'>{inputLabel}</div>
            <Switch
              name={name}
              checked={checked}
              onChange={e => onChange?.(e as any)}
            />
          </div>
        </div>
      )
    }

    if (inputMode === 'tel') {
      let formatValue = value
      if (isNumber(value)) {
        formatValue = formatPhoneNumberPart(value)
      }
      inputField = (
        <input
          className={cn(className, inputClasses)}
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={formatValue}
          onKeyUp={(e: any) => {
            const ph = onPhoneKeyUp(e, e.target.value)
            onChangePhone?.(ph)
          }}
          onKeyDown={onPhoneKeyDown}
          onChange={e => {
            const value = e.target.value
            onChangePhone?.(value)
          }}
          {...args}
        />
      )
    }

    if (inputMode === 'money') {
      inputField = (
        <input
          className={cn(className, inputClasses)}
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onBlur={e => {
            let value = e.target.value
            if (value) {
              if (value.includes('$')) {
                value = value.slice(1, value.length)
              }
              onChangeMoney?.(formatCurrencyString(value))
            } else {
              onChangeMoney?.(formatCurrencyString(0))
            }
          }}
          onKeyDown={onMoneyKeyDown}
          onChange={e => {
            const value = e.target.value
            onChangeMoney?.(value)
          }}
          {...args}
        />
      )
    }

    return (
      <div className={containerClassName}>
        {labelCtrl}
        <div className='relative'>
          {suffix && (
            <div className='absolute left-4 top-0 bottom-0 flex items-center text-dark-16'>
              {suffix}
            </div>
          )}
          {inputField}
          {append && (
            <div className='absolute right-4 top-0 bottom-0 flex items-center'>
              {append}
            </div>
          )}
        </div>
        {error && (
          <div className='text-red text-xs text-left mt-1'>{error}</div>
        )}
      </div>
    )
  },
)

export default Field
