import React, { useState, useEffect } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import { Spin } from 'components/Loading'
import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  getReviewListApi,
  getReviewConfigApi,
  setFormData,
  setSortData,
  setPageLoading,
  setReviewConfig,
  setTableData,
} from 'redux/smartReview'
import { TReviewFormData } from 'types/review'
import { TSortData } from 'types/table'

import FormFilter from './components/FormFilter'
import HowWorksModal from './components/HowWorksModal'
import ListEmpty from './components/ListEmpty'
import ReviewTable from './components/ReviewTable'

export default function SmartReviewPage(): JSX.Element {
  const { locationId } = useParams<{ locationId: string }>()
  const dispatch = useAppDispatch()
  const { formData, sortData, pageLoading, reviewConfig, tableData } =
    useAppSelector(state => state.smartReview)
  const [tableLoading, setTableLoading] = useState(false)
  const [worksModalOpen, setWorksModalOpen] = useState(false)

  const handleFormDataChange = (key: string, value: any) => {
    let newFormData = {
      ...formData,
    }
    if (key === 'page') {
      newFormData = {
        ...newFormData,
        page: value,
      }
    } else {
      newFormData = {
        ...newFormData,
        [key]: value,
        page: 1,
      }
    }
    dispatch(
      setFormData({
        ...newFormData,
        page: key === 'page' ? value : 1,
      }),
    )

    if (key === 'keyword') {
      getReviewListDebounce(newFormData)
    } else {
      getReviewList(newFormData, sortData)
    }
  }

  const handleSortDataChange = (newSortData: TSortData) => {
    dispatch(setSortData(newSortData))
    getReviewList(
      {
        ...formData,
        page: 1,
      },
      newSortData,
    )
  }

  const getReviewList = async (
    newFormData?: TReviewFormData,
    newSortData?: TSortData,
  ) => {
    const paramsFormData = newFormData || formData
    const paramsSortData = newSortData || sortData
    const { page, pageSize, keyword, impressions, ranges } = paramsFormData
    const { sort, sort_by } = paramsSortData
    try {
      setTableLoading(true)
      const res = await getReviewListApi(locationId, {
        page,
        per_page: pageSize,
        keyword,
        impressions,
        begin_time: ranges[0].startDate
          ? moment(ranges[0].startDate).format('YYYY-MM-DD') + ' 00:00:00'
          : null,
        end_time: ranges[0].endDate
          ? moment(ranges[0].endDate).format('YYYY-MM-DD') + ' 23:59:59'
          : null,
        sort_by: sort ? sort_by : '',
        sort: sort,
      })
      dispatch(
        setTableData({
          total: res.total,
          data: res.data,
        }),
      )
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setTableLoading(false)
    }
  }
  const getReviewListDebounce = useDebounce(getReviewList, 1000)

  const getReviewConfig = async () => {
    try {
      dispatch(setPageLoading(true))
      const res = await getReviewConfigApi(locationId)
      dispatch(setReviewConfig(res))
      if (res.id) {
        getReviewList(formData, sortData)
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      dispatch(setPageLoading(false))
    }
  }
  useEffect(() => {
    if (!reviewConfig || reviewConfig.location_id !== Number(locationId)) {
      getReviewConfig()
    } else {
      getReviewList()
    }
  }, [])

  return (
    <AdminPage>
      <AdminPageTitle title='Smart Review' />
      <Spin
        spining={pageLoading}
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='p-8'>
          {reviewConfig?.id ? (
            <>
              <FormFilter
                formData={formData}
                status={reviewConfig?.status}
                handleFormDataChange={handleFormDataChange}
                getReviewConfig={getReviewConfig}
                openSettingModal={() => setWorksModalOpen(true)}
              />
              <ReviewTable
                loading={tableLoading}
                page={formData.page}
                tableData={tableData}
                handleFormDataChange={handleFormDataChange}
                handleSortDataChange={handleSortDataChange}
                getReviewList={getReviewList}
              />
            </>
          ) : (
            <ListEmpty openModal={() => setWorksModalOpen(true)} />
          )}
        </div>
      </Spin>
      <HowWorksModal
        open={worksModalOpen}
        onClose={() => setWorksModalOpen(false)}
        getReviewConfig={getReviewConfig}
      />
    </AdminPage>
  )
}
