import React, { useState } from 'react'

import { SolidFile, LinedTrash } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Label from 'components/Label'

type Iprops = {
  accept?: string
  className?: string
  labelText?: string
  value?: string
  defaultValue?: string
  onChange?: (blob: Blob, blobUrl: string) => void
  canDelete?: boolean
  onDelete?: () => void
}

const UploadComps = ({
  className = '',
  labelText,
  value,
  accept = 'image/*',
  defaultValue,
  onChange,
  canDelete = true,
  onDelete,
}: Iprops): JSX.Element => {
  const [fileName, setFileName] = useState(defaultValue || '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    const { type, name } = file
    const aBlob = new Blob([file], { type: type })

    // 受控组件
    if (value === undefined) {
      setFileName(name)
    }
    onChange && onChange(aBlob, name)
  }

  const handleDelete = () => {
    onDelete && onDelete()
    if (fileName) {
      setFileName('')
    }
  }

  const fileDisplay = value ?? fileName

  return (
    <div className={className}>
      <Label color='silver'>{labelText}</Label>
      <div className={cn('relative cursor-pointer', { 'mt-2': !!labelText })}>
        {!fileDisplay && (
          <>
            <Button color='tertiary' className='w-full'>
              UPLOAD DOCUMENT
            </Button>
            <input
              className='absolute inset-0 z-20 opacity-0 cursor-pointer'
              type='file'
              accept={accept}
              onChange={handleChange}
            />
          </>
        )}
        {fileDisplay && (
          <>
            <div className='w-full h-10 flex justify-between px-4 py-3 items-center text-lead text-base bg-field rounded-lg'>
              <div className='flex flex-1 w-[100px]'>
                <SolidFile className='text-silver mr-2' size={16} />
                <div className='flex-1 text-base text-ellipsis'>
                  {fileDisplay}
                </div>
              </div>
              {canDelete && (
                <Button color='primary-link' onClick={handleDelete}>
                  <LinedTrash className='text-ink' size={16} />
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default UploadComps
