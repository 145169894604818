import { createReducer } from '@reduxjs/toolkit'
import { TAuthStateProp } from 'types/user'
import { saveAccessToken } from 'utils/auth'

import {
  login,
  getProfile,
  getPermissionsAndProfile,
  logout,
  sendCode,
  passwordResetSend,
  passwordResetVerify,
  clearError,
} from './actions'

const initialState: TAuthStateProp = {
  loginRequest: false,
  loginErrors: null,
  profile: null,
  logoutRequest: false,
  selectedBrandId: null,
  resetRequest: null,
  getProfileRequest: false,
  getProfileError: '',
  authProfile: { data: null, request: false, error: '' },
  editProfileRequest: false,
  loggedIn: false,
  sendCodeRequest: false,
  sendCodeSuccess: false,
  sendCodeError: '',
  passwordResetSendRequest: false,
  passwordResetSendError: '',
  passwordResetVerifyRequest: false,
  passwordResetVerifyError: '',
}

export const authReducer = createReducer(initialState, builder => {
  builder
    .addCase(login.pending, state => {
      state.loginRequest = true
    })
    .addCase(login.fulfilled, (state, action) => {
      state.loginRequest = false
      const { data, message } = action.payload || {}
      if (data?.access_token && data?.expires_at) {
        state.loggedIn = true
        state.loginErrors = null
        saveAccessToken(data?.access_token, data?.expires_at)
      } else {
        state.loggedIn = false
        state.loginErrors = { message }
      }
    })
    .addCase(login.rejected, (state, action) => {
      state.loginRequest = false
      if (action.payload) {
        state.loginErrors = action.payload as any
      }
    })
    .addCase(logout.fulfilled, state => {
      state.loggedIn = false
    })
    .addCase(logout.rejected, state => {
      state.loggedIn = false
    })
    .addCase(getProfile.pending, state => {
      state.getProfileRequest = true
    })
    .addCase(getProfile.fulfilled, (state, action) => {
      state.getProfileRequest = false
      state.profile = action.payload
      state.getProfileError = ''
      state.loggedIn = true
    })
    .addCase(getProfile.rejected, (state, action) => {
      state.profile = null
      if (action.error.message) {
        state.getProfileError = action.error.message
      }
    })
    // ===== profile 权限相关======
    .addCase(getPermissionsAndProfile.pending, state => {
      state.authProfile.request = true
    })
    .addCase(getPermissionsAndProfile.fulfilled, (state, action) => {
      state.authProfile.request = true
      state.authProfile.error = ''
      const data = action.payload || {}
      const arr = data?.permissions || []
      const permissionMap = arr.reduce((acc: any, cur: any) => {
        const item = { ...cur, ...cur?.permission, permission: null, child: {} }
        const id = item.permission_id
        acc[id] = item
        return acc
      }, {})
      state.authProfile.data = { ...data, permissionMap }
    })
    .addCase(getPermissionsAndProfile.rejected, (state, action) => {
      state.authProfile.request = true
      state.authProfile.data = action.payload
      if (action.error.message) {
        state.authProfile.error = action.error.message
      }
    })
    .addCase(sendCode.pending, state => {
      state.sendCodeRequest = true
      state.sendCodeSuccess = false
    })
    .addCase(sendCode.fulfilled, state => {
      state.sendCodeRequest = false
      state.sendCodeSuccess = true
    })
    .addCase(sendCode.rejected, (state, action) => {
      state.sendCodeRequest = false
      state.sendCodeSuccess = false
      if (action.error.message) {
        state.sendCodeError = action.error.message
      }
    })
    .addCase(passwordResetSend.pending, state => {
      state.passwordResetSendRequest = true
      state.passwordResetSendError = ''
    })
    .addCase(passwordResetSend.fulfilled, state => {
      state.passwordResetSendRequest = false
      state.passwordResetSendError = ''
    })
    .addCase(passwordResetSend.rejected, (state, action) => {
      state.passwordResetSendRequest = false
      if (action.error.message) {
        state.passwordResetSendError = action.error.message
      }
    })
    .addCase(passwordResetVerify.pending, state => {
      state.passwordResetVerifyRequest = true
      state.passwordResetVerifyError = ''
    })
    .addCase(passwordResetVerify.fulfilled, state => {
      state.passwordResetVerifyRequest = false
      state.passwordResetVerifyError = ''
    })
    .addCase(passwordResetVerify.rejected, (state, action) => {
      state.passwordResetVerifyRequest = false
      if (action.error.message) {
        state.passwordResetVerifyError = action.error.message
      }
    })
    .addCase(clearError, state => {
      state.loginErrors = null
      state.sendCodeError = ''
      state.passwordResetSendError = ''
    })
})

export default authReducer
