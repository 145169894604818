import React, { useEffect, useState } from 'react'

import { SolidTrashCan } from '@rushable/icons'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import ModalFull from 'components/Modal/ModalFull'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'redux/hooks'
import {
  setLockModalOpen,
  createMenuModifierAPI,
  editMenuModifierAPI,
  showMenuModifierAPI,
} from 'redux/onlineMenu'

import DeleteModifier from './DeleteModifier'
import MenuModifierDetail from './MenuModifierDetail'
import { MENU_LOCKED_STATUS } from '../helpers/constant'

type TChangeModifierProp = {
  id: number
  open: boolean
  toggle: (e: boolean) => void
}

export default function ChangeModifier({
  open,
  id,
  toggle,
}: TChangeModifierProp): JSX.Element {
  const baseFormData = {
    name: '',
    note: '',
    qty_min: 0,
    qty_max: 0,
    qty_free: 0,
    allow_repeat: 0,
    usage: 0,
    menu_modifier_options: [
      {
        name: '',
        unit_price: '',
        status: 'active',
      },
    ],
  }
  const dispatch = useAppDispatch()
  const [formData, setFormData] = useState(baseFormData)
  const [loading, setLoading] = useState(false)
  const [initRequest, setInitRequest] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const { locationId } = useParams<TParamTypes>()

  const changeFormData = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value })
  }

  useEffect(() => {
    if (open && id) {
      getMenuModifierDetail()
    } else {
      setFormData({ ...baseFormData })
    }
  }, [open])

  const getMenuModifierDetail = async () => {
    setInitRequest(true)
    try {
      const params = {
        menu_modifier_id: id,
        locationId,
      }
      const res = await showMenuModifierAPI(params)
      const {
        name,
        note,
        qty_min,
        qty_max,
        qty_free,
        allow_repeat,
        usage,
        menu_modifier_options,
      } = res
      const detailFormData = {
        name,
        note,
        qty_min,
        qty_max,
        qty_free,
        allow_repeat,
        usage,
        menu_modifier_options: res.menu_modifier_options.length
          ? [...menu_modifier_options]
          : [{ name: '', unit_price: '', status: 'inactive' }],
      }
      setFormData({ ...detailFormData })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setInitRequest(false)
  }

  const updateMenuModifier = async () => {
    setLoading(true)
    try {
      const { menu_modifier_options } = formData
      const obj: any = { ...formData }

      const newFormData = new FormData()
      Object.keys(obj).forEach(key => {
        if (key !== 'menu_modifier_options') {
          newFormData.append(key, obj[key])
        }
      })

      menu_modifier_options.forEach((item, index) => {
        newFormData.append(`options[${index}][name]`, item.name)
        newFormData.append(`options[${index}][unit_price]`, item.unit_price)
        newFormData.append(`options[${index}][status]`, item.status)
      })

      if (id) {
        const res = await editMenuModifierAPI(newFormData, id, locationId)
        toast.success(res.message)
      } else {
        const res = await createMenuModifierAPI(newFormData, locationId)
        toast.success(res.message)
      }
      closeModal(true)
    } catch (e: any) {
      const msg = e?.message
      e?.status === MENU_LOCKED_STATUS
        ? dispatch(setLockModalOpen(true))
        : toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  const closeModal = (e: boolean) => {
    setFormData({ ...baseFormData })
    toggle(e)
  }

  return (
    <>
      <ModalFull
        open={open}
        toggle={() => closeModal(false)}
        title={id ? 'Edit Modifier' : 'Create New Modifier'}
        loading={loading}
        onCancel={() => closeModal(false)}
        onOk={() => updateMenuModifier()}
        iconFooter={
          <>
            {!!id && (
              <Button
                color='secondary-link'
                onClick={() => {
                  closeModal(false)
                  setOpenDelete(true)
                }}
              >
                <SolidTrashCan className='mr-1' size={16} />
                DELETE
              </Button>
            )}
          </>
        }
      >
        <Spin spining={initRequest}>
          <div>
            <MenuModifierDetail
              formData={formData}
              onChange={(name, value) => {
                changeFormData(name, value)
              }}
            />
          </div>
        </Spin>
      </ModalFull>
      <DeleteModifier
        id={openDelete ? id : 0}
        open={openDelete}
        onChange={success => {
          setOpenDelete(false)
          success && closeModal(true)
        }}
      />
    </>
  )
}
