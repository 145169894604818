/**
 * LegalOverview 弹框表单组件==> Address Infomation
 */
import React, { useState, useRef } from 'react'

import CheckBox from 'components/CheckBox'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { Select, SearchSelect } from 'components/Select'
import { UploadFile } from 'components/Upload/index'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateLegalEntityApi, updateMailAddressApi } from 'redux/business'

interface IModalTypes {
  open: boolean
  toggle: () => void
  onSuccess: () => void
}

/**
 *  Update Mailing Address
 */
export function UpdateAddressModal({ open, toggle, onSuccess }: IModalTypes) {
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [formState, setFormState] = useState({ addr1: '', addr2: '' })
  const { locationId } = useParams<TParamTypes>()

  const updateAddress = async () => {
    setIsLoading(true)
    try {
      const params = {
        address_line_1: formState.addr1,
        address_line_2: formState.addr2,
      }
      const res = await updateMailAddressApi(params, locationId || '')
      toast.success(res?.message)
      setFormError('')
      onSuccess()
    } catch (e: any) {
      const msg = e?.message || 'request error'
      setFormError(msg)
    }
    setIsLoading(false)
  }

  return (
    <ModalFull
      open={open}
      toggle={toggle}
      title='Update Mailing Address'
      loading={isLoading}
      error={formError}
      onOk={updateAddress}
    >
      <>
        <div className='text-base leading-[24px] text-ink text-center'>
          Input your new mailing address to receive tax documents.
        </div>
        <SearchSelect
          placeholder='Input address here'
          className='my-6'
          label='New mailing address line 1'
          value={formState.addr1 || ''}
          onChange={value => {
            setFormState(prev => ({ ...prev, addr1: value }))
          }}
        />
        <Field
          type='text'
          name='address'
          placeholder='Suite, floor, unit, etc'
          label='New mailing address line 2'
          value={formState.addr2 || ''}
          onChange={e => {
            setFormState(prev => ({ ...prev, addr2: e.target.value }))
          }}
        />
      </>
    </ModalFull>
  )
}

/**
 * Submit Information Modal
 */
export function UpdateInformationModal({
  open,
  toggle,
  onSuccess,
}: IModalTypes) {
  const [isLoading, setIsLoading] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [formError, setFormError] = useState('')
  const docRef = useRef<any>(null)
  const { locationId } = useParams<TParamTypes>()
  const [formState, setFormState] = useState({
    name: '',
    ein: '',
    docs: '',
    haveNameEin: false,
    docUrl: '',
  })

  const getRequestParams = () => {
    const formData = new FormData()
    const { name, ein, docs, haveNameEin } = formState
    formData.append('name', name)
    formData.append('ein', ein)
    formData.append('document_type', docs)
    formData.append('document_contains_name_ein', haveNameEin ? '1' : '0')
    formData.append('document', docRef.current)
    return formData
  }
  const submitInfomation = async () => {
    const formData = getRequestParams()
    setIsLoading(true)
    try {
      const res = await updateLegalEntityApi(formData, locationId || '')
      toast.success(res?.message)
      setFormError('')
      onSuccess()
    } catch (e: any) {
      const msg = e?.message || 'request error'
      setFormError(msg)
    }
    setIsLoading(false)
  }

  const onChangeForm = (data: Record<string, any>) => {
    const obj: any = {
      ...formState,
      ...data,
    }
    setFormState(obj)
    const hasValue = Object.keys(obj).every(key => !!obj[key])
    setIsActive(hasValue)
  }

  return (
    <ModalFull
      open={open}
      toggle={toggle}
      error={formError}
      title='Submit Missing Information'
      okText='SUBMIT DOCUMENT'
      okBtnDisabled={!isActive}
      loading={isLoading}
      onOk={submitInfomation}
    >
      <>
        <div className='text-base leading-[24px] text-ink text-center'>
          Input your new mailing address to receive tax documents.
        </div>
        <Field
          type='text'
          name='Legal business entity name'
          containerClassName='my-6'
          placeholder='Input here'
          label='Legal business entity name'
          value={formState.name || ''}
          onChange={e => {
            onChangeForm({ name: e.target.value })
          }}
        />
        <Field
          type='text'
          name='Legal business entity EIN'
          placeholder='Input here'
          label='Legal business entity EIN'
          value={formState.ein || ''}
          onChange={e => {
            onChangeForm({ ein: e.target.value })
          }}
        />
        <Select
          className='mt-6 mb-4'
          label='Proof of business document'
          value={formState.docs}
          options={[
            {
              label: 'IRS SS-4 confirmation letter',
              value: 'irs_ss4_confirmation',
            },
            { label: 'IRS Letter 147C', value: 'irs_letter_147c' },
          ]}
          placeholder='Select document type'
          onChange={docs => {
            onChangeForm({ docs: docs })
          }}
        />
        {formState.docs && (
          <UploadFile
            accept='.pdf,.jpg,.jpeg,.png'
            value={formState.docUrl}
            onDelete={() => {
              docRef.current = null
              onChangeForm({ docUrl: '', haveNameEin: false })
            }}
            onChange={(blob, name) => {
              docRef.current = blob
              onChangeForm({ docUrl: name })
            }}
          />
        )}
        {formState.docs && formState.docUrl && (
          <div className='flex items-center mt-[11px]'>
            <CheckBox
              labelClass='text-base text-ink'
              checked={formState.haveNameEin}
              onChange={checked => onChangeForm({ haveNameEin: checked })}
            >
              This document shows my business name and EIN
            </CheckBox>
          </div>
        )}
      </>
    </ModalFull>
  )
}
