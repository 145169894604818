import { useState, useEffect } from 'react'

import { LightOwg } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import SourceIconRender, {
  sourceIconMap,
} from 'components/SourceIcon/SourceIconRender'
import CellOrderTypes from 'components/TableAndCell/Cell/CellOrderTypes'
import Table from 'components/TableAndCell/Table'
import Badge from 'components/Tag/Badge'
import ToolTips from 'components/ToolTips'
import { SERVER_TIMEZONE } from 'data/constant'
import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import { useParams } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  initFormData,
  getOrderListApi,
  setFormData,
  setTableData,
  resetFilters,
  setCurrentLocationId,
} from 'redux/orderHistory'
import type { TOrderHistoryFormData } from 'types/orderHistory'
import { currencyFormatter } from 'utils/digit'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

import FormFilter from './components/FormFilter'
import OrderSummary from './components/OrderSummary'

export default function OrderHistoryPage(): JSX.Element {
  const { locationId } = useParams<{ locationId: string }>()
  const history = useHistory()
  const { url } = useRouteMatch()
  const dispatch = useAppDispatch()
  const { formData, tableData, currentLocationId } = useAppSelector(
    state => state.orderHistory,
  )
  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE

  const [openSummary, setOpenSummary] = useState(false)
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-[136px]',
      custom: (value: string, item: any) => {
        let statusValue = ''
        let color: any = ''
        switch (value) {
          case 'placed':
            statusValue = 'New'
            color = 'yellow'
            break
          case 'confirmed':
          case 'ready':
          case 'done':
            statusValue = 'Processed'
            color = 'blue'
            break
          case 'canceled':
            statusValue = item.confirmed_at ? 'Cancelled' : 'Rejected'
            color = item.confirmed_at ? 'red' : 'gray'
            break
        }

        if (
          item.transaction &&
          item.transaction?.stripe_object_id &&
          item.transaction.stripe_object_id !== ''
        ) {
          statusValue = 'Deposited'
          color = 'green'
        }
        return <Badge color={color}>{statusValue}</Badge>
      },
    },
    {
      key: 'id',
      name: 'ORDER ID',
      align: 'left',
      className: 'w-[136px]',
      tdClassName: 'text-ink',
      custom: (value: any) => {
        return <div>#{value}</div>
      },
    },
    {
      key: 'type',
      name: 'TYPE',
      className: 'w-[136px]',
      custom: (value: any) => {
        return (
          <div className='flex items-center text-lead'>
            <CellOrderTypes type={value} />
          </div>
        )
      },
    },
    {
      key: 'utm_tracking',
      name: 'SOURCE',
      className: 'w-[88px]',
      custom: (value: any) => {
        const source = value?.source
        return (
          <div className='flex justify-start items-center'>
            <ToolTips
              className='flex justify-center'
              tipsClass='text-ink'
              hoverEl={
                <div className='flex'>
                  <SourceIconRender
                    type={source}
                    iconParams={{
                      size: 20,
                    }}
                  />
                  {value?.source === 'OwG' ? (
                    <LightOwg size={20} className='ml-1 text-blue-darken' />
                  ) : null}
                </div>
              }
            >
              <div className='whitespace-nowrap'>
                {sourceIconMap(source) || source || 'Other'}
              </div>
            </ToolTips>
          </div>
        )
      },
    },
    {
      key: 'customer',
      name: 'CUSTOMER',
      custom: (customer: any) => {
        return (
          <div className='text-ellipsis'>
            {customer?.first_name} {customer?.last_name}
          </div>
        )
      },
    },
    {
      key: 'total',
      name: 'TOTAL',
      align: 'right',
      custom: (value: any) => {
        return <span>{`${currencyFormatter(Number(value))}`}</span>
      },
    },
    {
      key: 'refund_sum',
      name: 'REFUND',
      align: 'right',
      custom: (value: string) => {
        return (
          <>
            {value ? (
              <span className='text-red'>{`${currencyFormatter(
                Number(value),
              )}`}</span>
            ) : (
              <div className='flex justify-end items-center'>
                <span
                  style={{ width: '5px', height: '1px' }}
                  className='bg-ink inline-block'
                ></span>
              </div>
            )}
          </>
        )
      },
    },
    {
      key: 'created_at',
      name: 'TIME',
      align: 'right',
      className: 'w-[200px]',
      custom: (value: any) => {
        if (value) {
          return (
            <span>
              {parseServerTimeToLocalTime(
                value,
                timezone,
                'MM/DD/YYYY @ hh:mm A',
              )}{' '}
            </span>
          )
        }
        return (
          <div className='flex justify-end items-center'>
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          </div>
        )
      },
    },
  ]

  const handleFormDataChange = (key: string, value: any) => {
    let newFormData = {
      ...formData,
    }
    if (key === 'page') {
      newFormData = {
        ...newFormData,
        page: value,
      }
    } else {
      newFormData = {
        ...newFormData,
        [key]: value,
        page: 1,
      }
    }
    dispatch(
      setFormData({
        ...newFormData,
        page: key === 'page' ? value : 1,
      }),
    )

    if (key === 'keyword' || key === 'couponCode') {
      getOrderListDebounce(newFormData)
    } else {
      getOrderList(newFormData)
    }
  }
  const getOrderList = async (newFormData?: TOrderHistoryFormData) => {
    setLoading(true)
    try {
      const paramsFormData = newFormData || formData
      const { page, pageSize, keyword, status, couponCode, ranges } =
        paramsFormData
      const res = await getOrderListApi(
        {
          page,
          per_page: pageSize,
          keyword: keyword || undefined,
          status: status || undefined,
          coupon_code: couponCode,
          begin_time: ranges[0].startDate
            ? moment(ranges[0].startDate).format('YYYY-MM-DD') + ' 00:00:00'
            : undefined,
          end_time: ranges[0].endDate
            ? moment(ranges[0].endDate).format('YYYY-MM-DD') + ' 23:59:59'
            : undefined,
        },
        locationId,
      )
      dispatch(
        setTableData({
          total: res.total,
          data: res.data,
        }),
      )
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    } finally {
      setLoading(false)
    }
  }

  const getOrderListDebounce = useDebounce(getOrderList, 1000)

  const goDetail = (orderId: number) => {
    history.push(`${url}/order-detail/${orderId}`)
  }

  // if locationId has changed
  // rest filters and reload data
  useEffect(() => {
    if (currentLocationId !== locationId) {
      dispatch(resetFilters())
      dispatch(setCurrentLocationId(locationId))
      if (!window.location.href.includes('customer')) {
        getOrderList(initFormData)
      }
    }
  }, [locationId])

  return (
    <AdminPage>
      <AdminPageTitle title='Order History' />
      <div className='py-8 px-8'>
        <FormFilter handleFormDataChange={handleFormDataChange} />
        <div className='mt-6'>
          <Table
            loading={loading}
            isPageSet={true}
            current={formData.page}
            containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-hidden'
            columns={columns}
            data={tableData.data}
            total={tableData.total}
            totalText={'results'}
            rightButton={
              <Button
                size='sm'
                color='tertiary'
                onClick={() => setOpenSummary(true)}
              >
                SHOW SUMMARY
              </Button>
            }
            onPageNoChange={value => handleFormDataChange('page', value)}
            onPageSizeChange={value => {
              handleFormDataChange('pageSize', value)
            }}
            clickedRow={row => goDetail(row.id)}
          />
        </div>
        <OrderSummary
          open={openSummary}
          toggle={() => setOpenSummary(!openSummary)}
        />
      </div>
    </AdminPage>
  )
}
