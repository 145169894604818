import React, { useState, useEffect } from 'react'

import {
  DuoToneCellphone,
  DuoToneEmail,
  DuoToneBirthdayCake,
  LinedStore,
  LinedCar,
  LinedUtensils,
  AngleLeft,
  BadgeSilver,
  BadgeBronze,
  BadgeGold,
} from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import Table from 'components/TableAndCell/Table'
import Badge from 'components/Tag/Badge'
import currency from 'currency.js'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCustomerDetail } from 'redux/customer'
import { useAppDispatch } from 'redux/hooks'
import { TCustomerDetailOrders, TCustomerDetailUser } from 'types/customer'
import { formatPhoneNumber } from 'utils/digit'

export default function CustomerDetailPage(): JSX.Element {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { locationId, orderId, brandId } = useParams<TParamTypes>()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [customer, setCustomer] = useState<TCustomerDetailUser>({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    birthday: '',
  })
  const [orders, setOrders] = useState<TCustomerDetailOrders>(null)
  const [brandConfig, setBrandConfig] = useState({
    available_points: '',
    rank: '',
    rank_points: '',
  })

  const getDetail = async (nowPage: number, pageSizeNow: number) => {
    try {
      setLoading(true)
      setPage(nowPage)
      setPageSize(pageSizeNow)
      const res = await dispatch(
        getCustomerDetail({
          orderId: orderId as string,
          location_id: locationId as string,
          per_page: pageSizeNow,
          page: nowPage,
        }),
      )
      if (res.customer) {
        setCustomer(res.customer)
      }
      if (res.orders) {
        setOrders(res.orders)
      }
      if (res.customer_brand_configs) {
        setBrandConfig(res.customer_brand_configs)
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  useEffect(() => {
    if (orderId) {
      getDetail(1, pageSize)
    }
  }, [orderId])

  const goDetail = (orderId: number) => {
    history.push(
      `/brand/${brandId}/location/${locationId}/order-history/order-detail/${orderId}?customer=1`,
    )
  }

  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-[120px]',
      custom: (value: string, item: any) => {
        let statusValue = ''
        let color: any = ''
        switch (value) {
          case 'placed':
            statusValue = 'New'
            color = 'yellow'
            break
          case 'confirmed':
          case 'ready':
          case 'done':
            statusValue = 'Processed'
            color = 'blue'
            break
          case 'canceled':
            statusValue = item.confirmed_at ? 'Cancelled' : 'Rejected'
            color = item.confirmed_at ? 'red' : 'gray'
            break
        }

        if (
          item.transaction &&
          item.transaction?.stripe_object_id &&
          item.transaction.stripe_object_id !== ''
        ) {
          statusValue = 'Deposited'
          color = 'green'
        }
        return <Badge color={color}>{statusValue}</Badge>
      },
    },
    {
      key: 'type',
      name: 'TYPE',
      className: 'w-[120px]',
      custom: (value: any) => {
        return (
          <div className='flex items-center text-lead'>
            {value === 'pickup' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Pickup</span>
              </>
            )}
            {value === 'delivery' && (
              <>
                <LinedCar size={16} className='mr-1' />
                <span>Delivery</span>
              </>
            )}
            {value === 'daas_delivery' && (
              <>
                <LinedCar size={16} className='mr-1' />
                <span className='whitespace-nowrap'>Delivery (OD)</span>
              </>
            )}
            {value === 'dine-in' && (
              <>
                <LinedUtensils size={16} className='mr-1' />
                <span>Dine-in</span>
              </>
            )}
            {value === 'curbside' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Curbside</span>
              </>
            )}
            {value === 'takeout' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Takeout</span>
              </>
            )}
          </div>
        )
      },
    },
    {
      key: 'total',
      name: 'TOTAL',
      align: 'right',
      className: 'w-[100px]',
      custom: (value: any) => {
        return <span className='text-sm'>{currency(value).format()}</span>
      },
    },
    {
      key: 'refund_amount',
      name: 'REFUND',
      align: 'right',
      className: 'w-[100px]',
      custom: (value: string) => {
        return (
          <>
            {value ? (
              <span className='text-red'>{currency(value).format()}</span>
            ) : (
              <div className='flex justify-end items-center'>
                <span
                  style={{ width: '5px', height: '1px' }}
                  className='bg-ink inline-block'
                ></span>
              </div>
            )}
          </>
        )
      },
    },
    {
      key: 'created_at',
      name: 'TIME',
      align: 'right',
      tdClassName: 'text-lead',
      custom: (value: any) => {
        if (value) {
          return <span>{moment(value).format('MM/DD/YYYY @ hh:mm A')} </span>
        }
        return (
          <div className='flex justify-end items-center'>
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          </div>
        )
      },
    },
  ]

  return (
    <AdminPage>
      <AdminPageTitle
        title='Customer Detail'
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            GO BACK
          </Button>
        }
      />
      <Spin
        spining={page === 1 && loading && !orders}
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='flex '>
          <div className='flex-1 min-h-[calc(100vh-125px)] flex flex-col border-r border-solid border-zinc'>
            <div className='border-b border-solid border-zinc px-8 py-6 text-ink'>
              <p className='text-xl font-bold'>
                {customer.first_name ? (
                  `${customer.first_name} ${customer.last_name}`
                ) : (
                  <span
                    style={{ width: '5px', height: '1px' }}
                    className='bg-ink inline-block'
                  ></span>
                )}
              </p>
              <div className='mt-2 flex'>
                <DuoToneEmail size={20} color='#62727E' />
                <p className='ml-2 text-base break-all flex items-center'>
                  {customer.email ? (
                    customer.email
                  ) : (
                    <span
                      style={{ width: '5px', height: '1px' }}
                      className='bg-ink inline-block'
                    ></span>
                  )}
                </p>
              </div>
              <div className='mt-2 flex'>
                <DuoToneCellphone size={20} color='#62727E' />
                <p className='ml-2 text-base flex items-center'>
                  {customer.phone ? (
                    formatPhoneNumber(customer.phone)
                  ) : (
                    <span
                      style={{ width: '5px', height: '1px' }}
                      className='bg-ink inline-block'
                    ></span>
                  )}
                </p>
              </div>
              <div className='mt-2 flex'>
                <DuoToneBirthdayCake size={20} color='#62727E' />
                <p className='ml-2 text-base flex items-center'>
                  {customer.birthday ? (
                    moment(customer.birthday).format('MMMM DD')
                  ) : (
                    <span
                      style={{ width: '5px', height: '1px' }}
                      className='bg-ink inline-block'
                    ></span>
                  )}
                </p>
              </div>
            </div>
            <div className='flex space-x-4 border-b border-solid border-zinc px-8 py-6 text-ink'>
              <div className='flex-1'>
                <p className='text-lead text-xs'>Loyalty rank</p>
                <div className='mt-1 text-ink text-base'>
                  {brandConfig.rank === 'bronze' && (
                    <div className='flex'>
                      <BadgeBronze size={20} className='mr-2' />
                      Bronze
                    </div>
                  )}
                  {brandConfig.rank === 'silver' && (
                    <div className='flex'>
                      <BadgeSilver size={20} className='mr-2' />
                      Silver
                    </div>
                  )}
                  {brandConfig.rank === 'gold' && (
                    <div className='flex'>
                      <BadgeGold size={20} className='mr-2' />
                      Gold
                    </div>
                  )}
                  {!brandConfig.rank && (
                    <span
                      style={{ width: '5px', height: '1px' }}
                      className='bg-ink inline-block'
                    ></span>
                  )}
                </div>
              </div>
              <div className='flex-1'>
                <p className='text-lead text-xs'>Current balance</p>
                <div className='mt-1 text-ink text-base inline-flex'>
                  {brandConfig.available_points ? (
                    `${brandConfig.available_points} pts`
                  ) : (
                    <span
                      style={{ width: '5px', height: '1px' }}
                      className='bg-ink inline-block'
                    ></span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='w-[720px] px-10 py-6'>
            <Table
              loading={loading}
              isPageSet={true}
              containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-hidden'
              current={orders?.current_page || 1}
              columns={columns}
              data={orders?.data || []}
              total={orders?.total || 0}
              totalText='results'
              onPageNoChange={value => {
                getDetail(value, pageSize)
              }}
              onPageSizeChange={value => {
                getDetail(1, value)
              }}
              clickedRow={row => goDetail(row.id)}
            />
          </div>
        </div>
      </Spin>
    </AdminPage>
  )
}
