import React from 'react'

import cn from 'classnames'

export type TTagProp = {
  dotColor?: 'red' | 'yellow' | 'blue' | 'green' | 'gray' | string
  dot?: boolean
  text: string | React.ReactElement
  className?: string
}

export default function Tag({
  dotColor = 'blue',
  dot,
  text,
  className,
}: TTagProp): JSX.Element {
  return (
    <div
      className={cn(
        'bg-field text-lead rounded px-2 py-1 inline-flex items-center text-xs',
        className,
      )}
    >
      {dot && (
        <span
          className={cn(
            'w-1 h-1 mr-1 rounded-full',
            { 'bg-yellow': dotColor === 'yellow' },
            { 'bg-blue': dotColor === 'blue' },
            { 'bg-red': dotColor === 'red' },
            { 'bg-green': dotColor === 'green' },
            { 'bg-dark-32': dotColor === 'gray' },
          )}
        ></span>
      )}

      {text}
    </div>
  )
}
