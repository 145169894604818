import Field from 'components/Field'
import { Select } from 'components/Select'
import type { TCouponForm } from 'types/coupon'

import { USAGE_TYPE_ITEMS } from '../helpers/constant'

type TUsageTypeFieldProps = {
  disabled: boolean
  formData: TCouponForm
  onFormDataChange: (type: string, value: any) => void
}

export default function UsageTypeField({
  disabled,
  formData,
  onFormDataChange,
}: TUsageTypeFieldProps) {
  return (
    <div className='flex space-x-4'>
      <Select
        className='flex-1'
        label='Redemption limit'
        options={USAGE_TYPE_ITEMS}
        placeholder='Select'
        value={formData.usage_type}
        onChange={value => onFormDataChange('usage_type', value)}
        disabled={disabled}
      />
      {formData.usage_type === 'limited' ? (
        <Field
          containerClassName='flex-1'
          label='Total number of redemptions allowed'
          name='total number of redemptions allowed'
          type='number'
          value={formData.usage_limit}
          onChange={e => {
            const value = e.target.value
            onFormDataChange(
              'usage_limit',
              // 只能输入正整数
              Number(value) > 0 ? Math.floor(Number(value)) : '',
            )
          }}
          disabled={disabled}
        />
      ) : null}
    </div>
  )
}
