import { createAction } from '@reduxjs/toolkit'
import axios from 'utils/axiosApi'

export const setLockModalOpen = createAction<boolean>(
  'onlineMenu/setLockModalOpen',
)

export const setMenuItemList = createAction<any>('onlineMenu/setMenuItemList')
export const setListScrollTop = createAction<number | undefined>(
  'onlineMenu/setListScrollTop',
)

/**=============================  Online Menu ================================== */
type TMenuItem = {
  locationId?: string
  menu_collection_id?: string | number
  menu_category_id?: string | number
  menu_modifier_id?: string | number
  menu_item_id?: string | number
  name?: string
  description?: string
}

export async function getMenuCollectionAPI(locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_collections`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function createMenuCollectionAPI(
  params: FormData,
  locationId?: string,
) {
  const response = await axios.request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `locations/${locationId}/menu_collections`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function editMenuCollectionAPI(
  params: FormData,
  menu_collection_id: number,
  locationId?: string,
) {
  const response = await axios.request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `locations/${locationId}/menu_collections/${menu_collection_id}`,
    method: 'post',
    params: {
      _method: 'patch',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getMenuCollectionCountAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_collections/data/count`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function showMenuCollectionAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_collections/${params.menu_collection_id}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function deleteMenuCollectionAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_collections/${params.menu_collection_id}`,
    method: 'delete',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function sortMenuCollectionAPI(params: any, locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_collections/sort`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function createMenuCategoryAPI(
  params: TMenuItem,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_categories`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function showMenuCategoryAPI(
  params: TMenuItem,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_categories/${params.menu_category_id}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function editMenuCategoryAPI(
  params: TMenuItem,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_categories/${params.menu_category_id}`,
    method: 'post',
    params: {
      _method: 'patch',
    },
    data: {
      name: params.name,
      description: params.description,
    },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function deleteMenuCategoryAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_categories/${params.menu_category_id}`,
    method: 'delete',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function sortMenuCategoryAPI(
  params: TMenuItem,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_categories/sort`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getMenuModifierAPI(locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_modifiers`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function createMenuModifierAPI(
  params: FormData,
  locationId?: string,
) {
  const response = await axios.request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `locations/${locationId}/menu_modifiers`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function editMenuModifierAPI(
  params: FormData,
  menu_modifier_id: number,
  locationId?: string,
) {
  const response = await axios.request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `locations/${locationId}/menu_modifiers/${menu_modifier_id}`,
    method: 'post',
    params: {
      _method: 'patch',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function showMenuModifierAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_modifiers/${params.menu_modifier_id}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getMenuModifierCountAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_modifiers/${params.menu_modifier_id}/data/count`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function deleteMenuModifierAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_modifiers/${params.menu_modifier_id}`,
    method: 'delete',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function sortMenuModifierAPI(params: any, locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_modifiers/sort`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function createMenuItemAPI(params: FormData, locationId?: string) {
  const response = await axios.request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `locations/${locationId}/menu_items`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function editMenuItemAPI(
  menu_item_id: number,
  params: FormData,
  locationId?: string,
) {
  const response = await axios.request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `locations/${locationId}/menu_items/${menu_item_id}`,
    method: 'post',
    params: {
      _method: 'patch',
    },
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function bulkEditMenuItemAPI(params: any, locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_items/bulk_edit`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function sortMenuItemAPI(params: TMenuItem, locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_items/sort`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function deleteMenuItemAPI(params: TMenuItem) {
  const response = await axios.request({
    url: `locations/${params.locationId}/menu_items/${params.menu_item_id}`,
    method: 'delete',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function showMenuItemAPI(params: TMenuItem, locationId?: string) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_items/${params.menu_item_id}`,
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getFoodTagList() {
  const response = await axios.request({
    url: 'menu_tags',
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function handleUpdateImageAPI(
  menu_collection_id: number,
  params: FormData,
  locationId?: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/menu_items/${menu_collection_id}/upload_image`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

/**=============================  Online Menu ================================== */
