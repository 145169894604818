import React from 'react'

import { getTimeOptions } from 'utils/hoursConstant'

import { IOptions } from './help/index'
import Select from './Select'

export type TProps = {
  startTime?: string
  endTime?: string
  className?: string
  label?: string | React.ReactElement
  disabled?: boolean
  hideIndicator?: boolean
  placeholder?: string
  value?: any | any[]
  step?: number // 时间间隔
  onChange: (item: any, rest: any) => void
}

export default function TimeSelect({
  className,
  startTime = '03:00:00',
  endTime = '24:00:00',
  label,
  disabled,
  hideIndicator,
  placeholder,
  value,
  step,
  onChange,
}: TProps): JSX.Element {
  return (
    <Select
      className={className}
      value={value}
      label={label}
      hideIndicator={hideIndicator}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      options={getTimeOptions(startTime, endTime, step) as IOptions[]}
    />
  )
}
