import { createReducer } from '@reduxjs/toolkit'


type TFinancialState = Record<string, never>

const initialState: TFinancialState = {
}

export const financialReducer = createReducer(initialState, builder => {
  builder
})

export default financialReducer
