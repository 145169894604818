import React from 'react'

import { AngleLeft, AngleRight } from '@rushable/icons'
import cn from 'classnames'

type IProps = {
  onChange: any
  className?: string
  leftDisabled?: boolean
  rightDisabled?: boolean
}

export default function SimplePagination({
  leftDisabled = false,
  rightDisabled = false,
  onChange,
  className,
}: IProps): JSX.Element {
  const cls =
    'flex justify-center items-center cursor-pointer h-6 w-6 text-xs text-ink  rounded-full select-none bg-slate'
  return (
    <div className={className}>
      <ul className='flex justify-center p-0 space-x-1'>
        <li
          className={cn(
            cls,
            leftDisabled ? 'bg-ice text-dark-16 pointer-events-none' : '',
          )}
          onClick={() => onChange('left')}
        >
          <AngleLeft size={12} />
        </li>
        <li
          className={cn(
            cls,
            rightDisabled ? 'bg-ice text-dark-16 pointer-events-none' : '',
          )}
          onClick={() => onChange('right')}
        >
          <AngleRight size={12} />
        </li>
      </ul>
    </div>
  )
}
