import React from 'react'

import { DuoToneInvoice, DuoToneUser, DuoToneStore } from '@rushable/icons'
import MoneyAccounting from 'components/MoneyAccounting'
import moment from 'moment'
import { useHistory, useParams } from 'react-router'
import { currencyFormatter } from 'utils/digit'

type TOrderSummaryProp = {
  ranges: any
  status: string
  formData: any
  isPDF?: boolean
}

export default function OrderSummary({
  ranges,
  status,
  formData,
  isPDF,
}: TOrderSummaryProp): JSX.Element {
  const history = useHistory()
  const { brandId, locationId } = useParams<TParamTypes>()
  const currencyPrice = (value: number) => {
    return currencyFormatter(value || 0, {
      decimalNum: 2,
      thousandsSeparator: ',',
      decimalSeparator: '.',
      symbol: ' $',
    })
  }

  return (
    <div className={'pt-2'}>
      <div>
        <p className='text-base text-ink'>
          Total{' '}
          <span className='text-blue font-bold'>
            {formData.order_count} orders
          </span>{' '}
          from:
        </p>
        <div className={`flex mt-${isPDF ? '4' : '2'}`}>
          <div
            className={`h-[46px] bg-ice px-4 py-3 mr-2 rounded-lg ${
              isPDF ? 'leading-none' : 'flex items-center'
            }`}
          >
            {status === ''
              ? 'Any Status'
              : `${status.slice(0, 1).toUpperCase() + status.slice(1)}`}
          </div>
          <div
            className={`h-[46px] bg-ice px-4 py-3 rounded-lg ${
              isPDF ? 'leading-none' : 'flex items-center'
            }`}
          >
            {`${moment(ranges[0].startDate).format('MM/DD/YY')} - ${moment(
              ranges[0].endDate,
            ).format('MM/DD/YY')}`}
          </div>
        </div>
      </div>

      <div className='mt-8 pb-4  rounded-lg border border-solid border-zinc leading-none'>
        <div className='p-4 relative  text-ink  flex justify-between  font-bold  border-b border-solid border-zinc'>
          <div className={'h-6 flex items-center'}>
            <div className='mr-2 flex justify-center items-center w-6 h-6 bg-zinc rounded'>
              <DuoToneInvoice size={24} />
            </div>
            Total money collected
          </div>
          <div className={'h-6 flex items-center'}>
            <MoneyAccounting
              className='w-24'
              value={formData.original_order_total}
            />
          </div>
        </div>
        <div
          className={
            'flex flex-col space-y-1 leading-snug ml-auto  mr-4 w-[288px] text-lead mt-4'
          }
        >
          <div className='flex justify-between'>
            Subtotal{' '}
            <MoneyAccounting className='w-24' value={formData.subtotal} />
          </div>
          <div className='flex justify-between'>
            Discount{' '}
            <MoneyAccounting
              className='w-24'
              value={formData.discount ? -Math.abs(formData.discount) : 0}
            />
          </div>
          <div className='flex justify-between'>
            Custom fee
            <MoneyAccounting className='w-24' value={formData.custom_fee} />
          </div>
          <div className='flex justify-between'>
            Tax <MoneyAccounting className='w-24' value={formData.tax} />
          </div>
          <div className='flex justify-between'>
            Delivery fee{' '}
            <MoneyAccounting className='w-24' value={formData.delivery_fee} />
          </div>
          <div className='flex justify-between'>
            Restaurant tip{' '}
            <MoneyAccounting className='w-24' value={formData.restaurant_tip} />
          </div>
        </div>
        <div className='flex flex-col leading-snug ml-auto  mr-4 w-[288px] text-lead mt-3'>
          <div className='text-sm font-bold text-silver'>
            Fees paid by customers
          </div>
          <div className='flex flex-col space-y-1 mt-2'>
            <div className='flex justify-between'>
              Courier fee
              <MoneyAccounting
                className='w-24'
                value={formData.courier_fee_customer}
              />
            </div>
            <div className='flex justify-between'>
              Regulatory fee
              <MoneyAccounting
                className='w-24'
                value={formData.regulatory_fee}
              />
            </div>
            <div className='flex justify-between'>
              Courier tip
              <MoneyAccounting className='w-24' value={formData.courier_tip} />
            </div>
            <div className='flex justify-between'>
              Convenience fee
              <MoneyAccounting
                className='w-24'
                value={formData.convenience_fee}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col leading-snug ml-auto  mr-4 w-[288px] text-lead mt-3'>
          <div className='text-sm font-bold text-silver'>
            Paid with gift card
          </div>
          <div className='flex justify-between mt-2'>
            Gift card payment
            <MoneyAccounting
              className='w-24'
              value={
                formData.gift_card_payment
                  ? -Math.abs(formData.gift_card_payment)
                  : 0
              }
            />
          </div>
        </div>
      </div>
      <div className='mt-8 pb-4  rounded-lg border border-solid border-zinc leading-none'>
        <div className='p-4 relative  text-ink  flex justify-between  font-bold  border-b border-solid border-zinc'>
          <div className={'h-6 flex items-center'}>
            <div className='mr-2 flex justify-center items-center w-6 h-6 bg-zinc rounded'>
              <DuoToneUser size={24} />
            </div>
            Customer fees
          </div>
          <div className={'h-6 flex items-center'}>
            <MoneyAccounting className='w-24' value={formData.customer_fee} />
          </div>
        </div>
        <div
          className={
            'flex flex-col space-y-1 leading-snug ml-auto  mr-4 w-[288px] text-lead mt-4'
          }
        >
          <div className='flex justify-between'>
            Courier fee
            <MoneyAccounting
              className='w-24'
              value={formData.courier_fee_customer}
            />
          </div>
          <div className='flex justify-between'>
            Regulatory fee
            <MoneyAccounting className='w-24' value={formData.regulatory_fee} />
          </div>
          <div className='flex justify-between'>
            Courier tip
            <MoneyAccounting className='w-24' value={formData.courier_tip} />
          </div>
          <div className='flex justify-between'>
            Convenience fee
            <MoneyAccounting
              className='w-24'
              value={formData.convenience_fee}
            />
          </div>
        </div>
      </div>

      <div className='mt-8 pb-4  rounded-lg border border-solid border-zinc leading-none'>
        <div className='p-4 relative  text-ink  flex justify-between  font-bold  border-b border-solid border-zinc'>
          <div className={'h-6 flex items-center'}>
            <div className='mr-2 flex justify-center items-center w-6 h-6 bg-zinc rounded'>
              <DuoToneStore size={24} />
            </div>
            Restaurant fees
          </div>
          <div className={'h-6 flex items-center'}>
            <MoneyAccounting
              className='w-24'
              value={Math.abs(formData.restaurant_fee)}
            />
          </div>
        </div>
        <div
          className={
            'flex flex-col space-y-1 leading-snug ml-auto  mr-4 w-[288px] text-lead mt-4'
          }
        >
          <div className='flex justify-between'>
            Courier fee
            <MoneyAccounting
              className='w-24'
              value={Math.abs(formData.courier_fee_restaurant)}
            />
          </div>
          <div className='flex justify-between'>
            Dispatch fee
            <MoneyAccounting
              className='w-24'
              value={Math.abs(formData.dispatch_fee)}
            />
          </div>
          <div className='flex justify-between'>
            Card processing fee
            <MoneyAccounting
              className='w-24'
              value={Math.abs(formData.processing_fee)}
            />
          </div>
        </div>
      </div>
      <div className='my-8'>
        ⚠️{' '}
        {!!formData.refund_order_count &&
          `Total of ${currencyPrice(Math.abs(formData.refund))} refund was
        applied to ${formData.refund_order_count} orders, refunds are not
        included in this summary. `}
        Please see detailed reports from{' '}
        <span
          className='text-blue font-bold cursor-pointer'
          onClick={() =>
            history.push(`/brand/${brandId}/location/${locationId}/financial`)
          }
        >
          {'Performance > Financial'}
        </span>
      </div>
    </div>
  )
}
