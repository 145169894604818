import React from 'react'

import cn from 'classnames'

export type TAdminPageTitleProp = {
  title?: string | React.ReactElement
  left?: React.ReactElement
  right?: React.ReactElement
  className?: string
}

export default function AdminPageTitle({
  title,
  left,
  right,
  className,
}: TAdminPageTitleProp): JSX.Element {
  return (
    <div
      className={cn(
        'px-6 py-4 w-full flex justify-between rounded-t-lg items-center bg-white border-b border-zinc',
        className,
      )}
    >
      <div className='flex items-center w-44'>{left}</div>
      {title && (
        <h1 className='text-ink font-bold text-center text-xl'>{title}</h1>
      )}
      <div className='flex justify-end items-center w-44'>{right}</div>
    </div>
  )
}
