import { useEffect, useState } from 'react'

import Button from 'components/Button'
import DragBar from 'components/DragBar'
import ModalFull from 'components/Modal/ModalFull'
import currency from 'currency.js'
import { SUPPORT_PHONE, SUPPORT_EMAIL } from 'data/constant'

type Treview = {
  baseSubscription: Record<string, any>
  onlineOrderConfig: Record<string, any>
}
export default function ModalReviewSubscription({
  baseSubscription,
  onlineOrderConfig,
}: Treview) {
  const [isVisible, setIsVisible] = useState(false)
  const [value, setValue] = useState(0)
  const originAmount = currency(baseSubscription?.origin_amount || 0).value
  const discountAmount = currency(originAmount).subtract(
    baseSubscription?.unit_amount || 0,
  ).value
  // 数据库手动修改过的 convenience_fee
  const locked = !!onlineOrderConfig?.service_fee_locked
  const api_fee = currency(onlineOrderConfig?.service_fee).format()
  const fee = currency(1.49)
    .subtract(currency(value).subtract(49).multiply(0.00496))
    .format()

  useEffect(() => {
    setValue(originAmount)
  }, [isVisible])

  return (
    <>
      <Button color='secondary' size='sm' onClick={() => setIsVisible(true)}>
        REVIEW SUBSCRIPTION
      </Button>
      <ModalFull
        open={isVisible}
        title='Review Subscription'
        footer={
          <div className='w-full flex justify-end'>
            <Button color='secondary' onClick={() => setIsVisible(false)}>
              CLOSE
            </Button>
          </div>
        }
        toggle={() => setIsVisible(false)}
      >
        <>
          <div className='w-full p-6 rounded-lg bg-field text-ink'>
            <div className='flex justify-between text-base font-bold'>
              <span>Restaurant pay</span>
              <span>Customer pay</span>
            </div>
            <DragBar
              className='my-3'
              disabled={locked}
              discount={+discountAmount}
              // 订阅金额最小为 49
              min={+discountAmount > 49 ? +discountAmount : 49}
              max={349}
              value={value}
              onChange={value => {
                setValue(value)
              }}
            />
            <div className='flex justify-between text-sm'>
              <span>
                <span className='line-through'>{currency(value).format()}</span>{' '}
                {currency(value).subtract(discountAmount).format()}/month
              </span>
              <span>{locked ? api_fee : fee}/order</span>
            </div>
            <div className='text-xs text-lead'>
              {currency(discountAmount).format()} monthly discount applied to
              your account
            </div>
          </div>
          <p className='text-center mt-6 text-ink text-sm'>
            To adjust the subscription scale, please contact us at your earliest
            convenience by calling our support line at{' '}
            <span className='font-bold'>{SUPPORT_PHONE}</span> or email to{' '}
            <span className='font-bold'>{SUPPORT_EMAIL}</span>
          </p>
        </>
      </ModalFull>
    </>
  )
}
