import React from 'react'

import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Plus } from '@rushable/icons'
import Button from 'components/Button'

import MenuAction from './MenuAction'
import MenuCategoryItem from './MenuCategoryItem'

type TMenuCollectionItemProp = {
  formData: any
  baseFormData: any
  loading: boolean
  item: any
  index: number
  menuActionId: number
  menuActionName: string
  menuActionIndex: any
  handleMenuAction: (
    value: string,
    { name, id }: { name: string; id: number },
    { i, j }: { i: number; j: number },
    type?: string,
  ) => void
  setMenuActionIndex: (item: any) => void
  setMenuActionName: (name: string) => void
  getMenuDetail: (name: string, id: number) => void
  setFormData: (form: any) => void
  setMenuEdit: (data: any) => void
  setMenuActionId: (id: number) => void
  onDragEnd: (prop: any, type: string, list?: any) => void
}

export default function MenuCollectionItem({
  formData,
  baseFormData,
  loading,
  item,
  index,
  menuActionId,
  menuActionName,
  menuActionIndex,
  handleMenuAction,
  setMenuActionIndex,
  setMenuActionName,
  setFormData,
  setMenuEdit,
  getMenuDetail,
  setMenuActionId,
  onDragEnd,
}: TMenuCollectionItemProp): JSX.Element {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(mouseSensor)
  const { id } = item
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  let styles
  if (transform) {
    const { x, y }: any = transform
    styles = {
      transform: CSS.Transform.toString({ x, y, scaleX: 1, scaleY: 1 }),
      transition,
    }
  }

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      style={styles}
      className='pt-4 pb-2 text-ink'
    >
      <div className='flex items-center p-2 mb-2 rounded-lg bg-ice font-bold'>
        <MenuAction
          className='mr-2'
          hasDelete={!item.is_locked}
          open={
            menuActionName === item.name &&
            menuActionId === item.id &&
            menuActionIndex.i === index
          }
          onChange={value => {
            setMenuActionId(item.id)
            handleMenuAction(value, item, { i: index, j: 0 }, 'Collection')
          }}
          clickOutside={() => {
            setMenuActionIndex({ i: -1, j: -1 })
            setMenuActionName('')
          }}
        />
        <div className='w-[158px] text-ellipsis leading-snug'>{item.name}</div>
        <Button
          color='ink-link'
          className='ml-auto'
          onClick={() => {
            setFormData({
              ...baseFormData,
              id: formData.id,
            })
            setMenuActionName('')
            setMenuActionId(item.id)
            setMenuEdit({
              open: true,
              type: 'Category',
              method: 'add',
            })
          }}
        >
          <Plus size={16} />
        </Button>
      </div>
      <DndContext
        sensors={sensors}
        onDragEnd={prop => onDragEnd(prop, 'Category', item.menu_categories)}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext disabled={loading} items={item.menu_categories || []}>
          {item.menu_categories.map(
            (item: { name: string; id: number }, j: number) => {
              return (
                <MenuCategoryItem
                  key={item.name}
                  formData={formData}
                  item={item}
                  i={index}
                  j={j}
                  menuActionId={menuActionId}
                  menuActionName={menuActionName}
                  menuActionIndex={menuActionIndex}
                  handleMenuAction={(
                    value: string,
                    { name, id }: { name: string; id: number },
                    { i, j }: { i: number; j: number },
                    type?: string,
                  ) => handleMenuAction(value, { name, id }, { i, j }, type)}
                  setMenuActionIndex={item => setMenuActionIndex(item)}
                  setMenuActionName={name => setMenuActionName(name)}
                  getMenuDetail={(name: string, id: number) =>
                    getMenuDetail(name, id)
                  }
                  setMenuActionId={id => setMenuActionId(id)}
                />
              )
            },
          )}
        </SortableContext>
      </DndContext>
    </div>
  )
}
