import { PERMISSION } from 'data/permission'
import * as ROUTES from 'data/routes'
import AnnouncementPage from 'pages/announcement/AnnouncementPage'
import BillingAndInvoice from 'pages/billingAndInvoice/index'
import BusinessHours from 'pages/businessHours/BusinessHours'
import BusinessSetting from 'pages/businessSetting/index'
import CouponCodePage from 'pages/couponCode/CouponCodePage'
import customerDataPage from 'pages/customerData/CustomerDataPage'
import FinancialPage from 'pages/financial'
import IntegrationPage from 'pages/integration'
import AdminLayout from 'pages/layout/AdminLayout'
import MarketingFlyer from 'pages/marketingFlyer/MarketingFlyer'
import OnlineMenuPage from 'pages/onlineMenu/OnlineMenuPage'
import OrderHistoryPage from 'pages/orderHistory/OrderHistoryPage'
import OrderSettingPage from 'pages/orderSetting/OrderSettingPage'
import OutboundCampaign from 'pages/outboundCampaign/OutboundCampaign'
import RecruitingPage from 'pages/recruiting/RecruitingPage'
import SmartReviewPage from 'pages/smartReview'

// const SAMPLE: TRouteType = {
//   path: ROUTES.SAMPLE,
//   name: 'sample',
//   label: 'SamplePage',
//   component: SamplePage,
//   layout: AdminLayout,
//   auth: true,
//   group: 'Performance',
// }

const FINANCIAL: TRouteType = {
  path: ROUTES.FINANCIAL,
  name: 'FINANCIAL',
  label: 'Financial',
  component: FinancialPage,
  layout: AdminLayout,
  auth: true,
  group: 'Performance',
  showBreadCrumbs: true,
}

const ORDERHISTORY: TRouteType = {
  path: ROUTES.ORDERHISTORY,
  name: 'ORDERHISTORY',
  label: 'Order History',
  component: OrderHistoryPage,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.ORDERHISTORY,
  group: 'Performance',
  showBreadCrumbs: true,
}

const ONLINEMENU: TRouteType = {
  path: ROUTES.ONLINEMENU,
  name: 'ONLINEMENU',
  label: 'Online Menu',
  component: OnlineMenuPage,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.ONLINEMENU,
  group: 'Restaurant',
}

const ORDERSETTING: TRouteType = {
  path: ROUTES.ORDERSETTING,
  name: 'ORDERSETTING',
  label: 'Order Setting',
  component: OrderSettingPage,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.ORDERSETTING,
  group: 'Restaurant',
  showBreadCrumbs: true,
}

const ANNOUNCEMENT: TRouteType = {
  path: ROUTES.ANNOUNCEMENT,
  name: 'ANNOUNCEMENT',
  label: 'Announcement',
  component: AnnouncementPage,
  layout: AdminLayout,
  auth: true,
  group: 'Restaurant',
  showBreadCrumbs: true,
}

const RECRUITING: TRouteType = {
  path: ROUTES.RECRUITING,
  name: 'RECRUITING',
  label: 'Recruiting',
  component: RecruitingPage,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.RECRUITING,
  group: 'Restaurant',
  showBreadCrumbs: true,
}

const BUSINESSHOURS: TRouteType = {
  path: ROUTES.BUSINESSHOURS,
  name: 'BUSINESSHOURS',
  label: 'Business Hours',
  component: BusinessHours,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.BUSINESSHOURS,
  group: 'Restaurant',
  showBreadCrumbs: true,
}

const BUSINESSSETTING: TRouteType = {
  path: ROUTES.BUSINESSSETTING,
  name: 'BUSINESSSETTING',
  label: 'Business Setting',
  component: BusinessSetting,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.BUSINESSSETTING,
  group: 'Restaurant',
  showBreadCrumbs: true,
}

const BILLINGANDINVOICE: TRouteType = {
  path: ROUTES.BILLINGANDINVOICE,
  name: 'BILLINGANDINVOICE',
  label: 'Billing & Invoice',
  component: BillingAndInvoice,
  permissionId: PERMISSION.BILLINGANDINVOICE,
  layout: AdminLayout,
  auth: true,
  group: 'Restaurant',
  showBreadCrumbs: true,
}

const INTERGRATION: TRouteType = {
  path: ROUTES.INTERGRATION,
  name: 'INTERGRATION',
  label: 'Intergration',
  component: IntegrationPage,
  layout: AdminLayout,
  auth: true,
  group: 'Restaurant',
  showBreadCrumbs: true,
}

// const MARKETINGSETTING: TRouteType = {
//   path: ROUTES.MARKETINGSETTING,
//   name: 'MARKETINGSETTING',
//   label: 'Marketing Setting',
//   component: SamplePage,
//   layout: AdminLayout,
//   auth: true,
//   group: 'Marketing',
// }

const PROMOCODE: TRouteType = {
  path: ROUTES.COUPONCODE,
  name: 'PROMOCODE',
  label: 'Coupon Code',
  component: CouponCodePage,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.COUPONCODE,
  group: 'Marketing',
  showBreadCrumbs: true,
}

const MARKETINGFLYER: TRouteType = {
  path: ROUTES.MARKETINGFLYER,
  name: 'MARKETINGFLYER',
  label: 'Marketing Flyer',
  component: MarketingFlyer,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.MARKETINGFLYER,
  group: 'Marketing',
  showBreadCrumbs: true,
}

const OUTBOUNDCAMPAIGN: TRouteType = {
  path: ROUTES.OUTBOUNDCAMPAIGN,
  name: 'MARKETINGFLYER',
  label: 'Outbound Campaign',
  component: OutboundCampaign,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.OUTBOUNDCAMPAIGN,
  group: 'Marketing',
  showBreadCrumbs: true,
}

// const EMAILANDTEXT: TRouteType = {
//   path: ROUTES.EMAILANDTEXT,
//   name: 'EMAILANDTEXT',
//   label: 'Email & Text',
//   component: SamplePage,
//   layout: AdminLayout,
//   auth: true,
//   group: 'Marketing',
// }

// const PHONEATTENDANT: TRouteType = {
//   path: ROUTES.PHONEATTENDANT,
//   name: 'PHONEATTENDANT',
//   label: 'Phone Attendant',
//   component: SamplePage,
//   layout: AdminLayout,
//   auth: true,
// permissionId: PERMISSION.PHONEATTENDANT,
//   group: 'Marketing',
// }

// const REVIEWGENERATOR: TRouteType = {
//   path: ROUTES.REVIEWGENERATOR,
//   name: 'REVIEWGENERATOR',
//   label: 'Review Generator',
//   component: SamplePage,
//   layout: AdminLayout,
//   auth: true,
// permissionId: PERMISSION.REVIEWGENERATOR,
//   group: 'Marketing',
// }

// const LOYALTYPROGRAM: TRouteType = {
//   path: ROUTES.LOYALTYPROGRAM,
//   name: 'LOYALTYPROGRAM',
//   label: 'Loyalty Program',
//   component: SamplePage,
//   layout: AdminLayout,
//   auth: true,
//   permissionId: PERMISSION.LOYALTYPROGRAM,
//   group: 'Marketing',
// }

const SMARTREVIEW: TRouteType = {
  path: ROUTES.SMARTREVIEW,
  name: 'SMARTREVIEW',
  label: 'Smart Review',
  component: SmartReviewPage,
  layout: AdminLayout,
  auth: true,
  // permissionId: PERMISSION.LOYALTYPROGRAM,
  group: 'Marketing',
  showBreadCrumbs: true,
}

const CUSTOMERDATA: TRouteType = {
  path: ROUTES.CUSTOMERDATA,
  name: 'CUSTOMERDATA',
  label: 'Customer Data',
  component: customerDataPage,
  layout: AdminLayout,
  auth: true,
  permissionId: PERMISSION.CUSTOMERDATA,
  group: 'Marketing',
  showBreadCrumbs: true,
}

export const performanceRoutes = [
  // STATISTICS,
  // SAMPLE,
  ORDERHISTORY,
  FINANCIAL,
]

export const restaurantRoutes = [
  ONLINEMENU,
  ORDERSETTING,
  ANNOUNCEMENT,
  RECRUITING,
  BUSINESSHOURS,
  BUSINESSSETTING,
  BILLINGANDINVOICE,
  INTERGRATION,
]

export const marketingRoutes = [
  // MARKETINGSETTING,
  PROMOCODE,
  MARKETINGFLYER,
  OUTBOUNDCAMPAIGN,
  // EMAILANDTEXT,
  // PHONEATTENDANT,
  SMARTREVIEW,
  // REVIEWGENERATOR,
  // LOYALTYPROGRAM,
  CUSTOMERDATA,
]

export const locationRoutes = [
  ...performanceRoutes,
  ...restaurantRoutes,
  ...marketingRoutes,
]
