import {
  SolidBrowser,
  SolidImage,
  SolidLink,
  SolidGiftCard,
  SolidStar,
} from '@rushable/icons'
import * as ROUTES from 'data/routes'
import DomainsSetting from 'pages/domainsSetting/DomainsSetting'
import GalleryPosting from 'pages/galleryPosting/GalleryPosting'
import GiftCardPage from 'pages/giftCard/GiftCardPage'
import AdminLayout from 'pages/layout/AdminLayout'
import LoyaltyRewardPage from 'pages/loyaltyRewards'
import WebsiteSetting from 'pages/websiteSetting/WebsiteSetting'

const ICONSTYLE = { size: 16 }

const DOMAINSETTING: TRouteType = {
  path: ROUTES.DOMAINSETTING,
  name: 'DOMAINSETTING',
  label: 'Domain',
  component: DomainsSetting,
  layout: AdminLayout,
  auth: false,
  showBreadCrumbs: true,
  icon: <SolidLink {...ICONSTYLE} />,
}

const WEBSITESETTING: TRouteType = {
  path: ROUTES.WEBSITESETTING,
  name: 'WEBSITESETTING',
  label: 'Website',
  component: WebsiteSetting,
  layout: AdminLayout,
  auth: true,
  showBreadCrumbs: true,
  icon: <SolidBrowser {...ICONSTYLE} />,
}

const LOYALTY: TRouteType = {
  path: ROUTES.LOYALTY,
  name: 'LOYALTY',
  label: 'Loyalty',
  component: LoyaltyRewardPage,
  layout: AdminLayout,
  auth: true,
  showBreadCrumbs: true,
  icon: <SolidStar {...ICONSTYLE} />,
}

const GIFTCARD: TRouteType = {
  path: ROUTES.GIFTCARD,
  name: 'GIFTCARD',
  label: 'Gift Card',
  component: GiftCardPage,
  layout: AdminLayout,
  auth: true,
  showBreadCrumbs: true,
  icon: <SolidGiftCard {...ICONSTYLE} />,
}

const GALLERYPOSTING: TRouteType = {
  path: ROUTES.GALLERYPOSTING,
  name: 'GALLERYPOSTING',
  label: 'Gallery',
  component: GalleryPosting,
  layout: AdminLayout,
  auth: true,
  showBreadCrumbs: true,
  icon: <SolidImage {...ICONSTYLE} />,
}

export const brandRoutes = [
  DOMAINSETTING,
  WEBSITESETTING,
  LOYALTY,
  GIFTCARD,
  GALLERYPOSTING,
]
