import React from 'react'

import { AngleDown } from '@rushable/icons'
import chroma from 'chroma-js'
import cn from 'classnames'
import Label from 'components/Label'
import Select, { StylesConfig } from 'react-select'

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 4,
    content: '" "',
    display: 'block',
    marginRight: 10,
    height: 15,
    width: 15,
  },
})

const customStyles: StylesConfig = {
  menu: provided => ({
    ...provided,
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '2px solid #00A5EB',
    borderTop: '0px',
  }),

  control: (provided, state) => {
    const borderBottomWidth = state.menuIsOpen ? 0 : 1
    const borderBottomLeftRadius = state.menuIsOpen ? 0 : 8
    const borderBottomRightRadius = state.menuIsOpen ? 0 : 8
    const transition = 'opacity 300ms'
    return {
      ...provided,
      height: 50.4,
      borderWidth: 2,
      borderColor: state.menuIsOpen ? '#00A5EB' : '#F4F6F8',
      borderBottomWidth,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      boxShadow: 'none',
      transition,
      paddingLeft: 8,
      backgroundColor: state.menuIsOpen ? '#fff' : '#F4F6F8',
      ':focus': {
        borderColor: '#00A5EB',
      },
      ':hover': {
        borderColor: state.menuIsOpen ? '#00A5EB' : 'transparent',
        cursor: 'pointer',
      },
    }
  },

  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'transparent',
  }),

  dropdownIndicator: provided => ({
    ...provided,
    paddingRight: 14,
  }),

  singleValue: (provided, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition, ...dot(state?.data?.value) }
  },
  input: styles => ({ ...styles, ...dot() }),
  option: (
    styles,
    {
      data,
      isDisabled,
      isFocused,
      isSelected,
    }: {
      data: any
      isDisabled: boolean
      isFocused: boolean
      isSelected: boolean
    },
  ) => {
    const color = chroma(data.value)
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.value
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.value,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.value : color.alpha(0.3).css()),
      },
    }
  },
}

type TSelectProps<T> = {
  label?: string
  className?: string
  value?: T
  onChange?: (value: any) => void
  options: T[]
  mode?: string
}

const ColorSelect = <T,>({
  className,
  label,
  value,
  onChange,
  options,
  mode = 'light',
}: TSelectProps<T>): JSX.Element => {
  const DropdownIndicator = ({ isFocused }: { isFocused: boolean }) => {
    if ((value as any)?.code) {
      return (
        <div className='text-base pr-4 text-silver'>{(value as any).code}</div>
      )
    } else {
      return (
        <AngleDown
          size={16}
          className={cn(
            'mr-4 transition duration-75',
            mode === 'light' ? 'text-silver' : 'text-white',
            isFocused ? 'origin-center rotate-180' : '',
          )}
        />
      )
    }
  }
  return (
    <div className={cn('block', className)}>
      {label && (
        <Label>
          <div className='flex justify-between mb-2'>
            <span>{label}</span>
          </div>
        </Label>
      )}
      <Select
        className='basic-single'
        classNamePrefix='select'
        value={value}
        options={options}
        onChange={onChange}
        components={{ DropdownIndicator }}
        theme={theme => ({
          ...theme,
          borderRadius: 8,
          colors: { ...theme.colors, primary: '#00A5EB' },
        })}
        styles={customStyles}
      />
    </div>
  )
}

export default ColorSelect
