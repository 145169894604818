import React from 'react'

export default function SkeletonCoupon(): JSX.Element {
  return (
    <div className='rounded-lg bg-slate h-16 w-[496px] animate-pulse p-4 mt-4'>
      <div className='bg-zinc rounded-lg w-1/3 h-2 mb-2 '></div>
      <div className='bg-zinc rounded-lg w-2/3 h-2'></div>
    </div>
  )
}
