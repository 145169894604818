import React, { useState, useRef } from 'react'

import { AngleLeft } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateUserStatusAPI } from 'redux/user'

import UserManagementDetail, { Ttitle } from './components/UserManagementDetail'

const OPERATEMAP = {
  active: 'MAKE INACTIVE',
  inactive: 'MAKE ACTIVE',
}

type Tstatus = 'active' | 'inactive'

export default function UserManagementPage(): JSX.Element {
  const history = useHistory()

  const [openUserActive, setOpenActive] = useState(false)
  const { userId, brandId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)
  const [titleInfo, setTitleInfo] = useState<Ttitle>()
  const randomRef = useRef(1)

  const updateUserStatus = async () => {
    setLoading(true)
    const status = titleInfo?.status === 'active' ? 'inactive' : 'active'
    try {
      const res = await updateUserStatusAPI(userId, brandId, { status })
      toast.success(res.message)
      // 强制子组件强制更新，刷新数据
      ++randomRef.current
      setTitleInfo(undefined)
      setOpenActive(false)
    } catch (e: any) {
      toast.error(e.message || 'request error')
    }
    setLoading(false)
  }

  const isActive = titleInfo?.status === 'active'
  return (
    <AdminPage>
      <AdminPageTitle
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            GO BACK
          </Button>
        }
        title={`${titleInfo?.first_name || ''} ${titleInfo?.last_name || ''}`}
        right={
          <Button color='secondary-link' onClick={() => setOpenActive(true)}>
            {OPERATEMAP[titleInfo?.status as Tstatus]}
          </Button>
        }
      />
      <UserManagementDetail
        key={randomRef.current}
        onChange={(res: Ttitle) => setTitleInfo(res)}
      />
      <ModalFull
        open={openUserActive}
        toggle={() => setOpenActive(false)}
        title={`Make User ${isActive ? 'Inactive' : 'Active'}`}
        onCancel={() => setOpenActive(false)}
        loading={loading}
        okBtnColor='warning'
        onOk={updateUserStatus}
      >
        <div className='text-ink text-center'>
          {isActive
            ? 'The user will no longer be able to sign in, do you confirm?'
            : 'The user will be able to access your dashboard again, do you wish to continue?'}
        </div>
      </ModalFull>
    </AdminPage>
  )
}
