import type { TCouponItem } from 'types/coupon'

type TPromotionTooltipsProps = {
  coupon: TCouponItem
}

export default function PromotionTooltips({ coupon }: TPromotionTooltipsProps) {
  // render lucky spin tooltips
  return (
    <>
      {coupon.type === 'flat' ? (
        <div>
          <div className='text-silver text-xxs font-medium pb-1'>Amount</div>
          <div className='text-ink text-xs'>{`$${coupon.amount_off}`}</div>
        </div>
      ) : null}
      {coupon.type === 'percentage' ? (
        <div>
          <div className='text-silver text-xxs font-medium pb-1'>
            Percentage
          </div>
          <div className='text-ink text-xs'>{`${coupon.percentage_off}%`}</div>
        </div>
      ) : null}
      {coupon.type === 'free_item' ? (
        <div>
          <div className='text-silver text-xxs font-medium pb-1'>
            Description
          </div>
          <div className='text-ink text-xs'>{`${coupon.description}`}</div>
        </div>
      ) : null}
    </>
  )
}
