import { baseRoutes } from './baseRoutes'
import { brandRoutes } from './brandRoutes'
import { locationRoutes } from './locationRoutes'
import { otherAdminRoutes } from './otherAdminRoutes'

export const adminRoutes = [
  ...brandRoutes,
  ...locationRoutes,
  ...otherAdminRoutes,
]
export const pageRoutes = [...baseRoutes, ...adminRoutes]
