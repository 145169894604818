import React from 'react'

import { Loading } from '@rushable/icons'
import cn from 'classnames'

export type TSwitchProp = {
  label?: string | React.ReactElement
  disabled?: boolean
  checked?: boolean
  name: string
  position?: 'left' | 'right'
  className?: string
  inputClassName?: string
  onChange?: (v: boolean) => void
  loading?: boolean
}

export default function Switch({
  label,
  disabled,
  name,
  checked,
  position = 'left',
  className = 'py-1',
  inputClassName,
  onChange,
  loading = false,
}: TSwitchProp): JSX.Element {
  const checkboxClasses = cn(
    `top-0.5 right-auto left-0.5 duration-200 ease-in absolute block w-3 h-3 rounded-full bg-white appearance-none ${
      disabled ? 'cursor-not-allowed' : 'cursor-pointer'
    }`,
    'checked:right-0.5 checked:left-auto',
    'focus:outline-none',
  )
  return (
    <label
      htmlFor={name}
      className={cn([
        `inline-flex items-center rounded-lg text-lead text-xs ${
          disabled ? 'cursor-not-allowed text-dark-16' : 'cursor-pointer'
        }`,
        position === 'left' ? 'flex-row-reverse' : 'flex-row',
        className,
      ])}
      onClick={() => !disabled && onChange && !loading && onChange(!checked)}
    >
      {label && (
        <div
          className={cn(
            {
              'ml-2': position === 'left',
              'mr-2': position === 'right',
            },
            'select-none',
          )}
        >
          {label}
        </div>
      )}
      {loading ? (
        <span className='w-8 flex justify-center items-center'>
          <Loading size={14} className='text-dark-32' />
        </span>
      ) : (
        <div className='relative w-8 align-middle select-none'>
          <input
            className={checkboxClasses}
            disabled={disabled}
            name={name}
            type='checkbox'
            checked={checked}
            readOnly
          />
          <label
            className={cn(
              'block overflow-hidden h-4 rounded-full',
              {
                'bg-blue cursor-pointer':
                  checked && !disabled && !inputClassName,
              },
              {
                'bg-zinc cursor-pointer':
                  !checked && !disabled && !inputClassName,
              },
              {
                'bg-field cursor-not-allowed':
                  !checked && disabled && !inputClassName,
              },
              {
                'bg-blue/10 cursor-not-allowed':
                  checked && disabled && !inputClassName,
              },
              inputClassName,
            )}
          ></label>
        </div>
      )}
    </label>
  )
}
