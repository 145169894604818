import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import RedirectIACM from './RedirectIACM'

export default function RedirectPage(): JSX.Element {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/iacm`}>
        <RedirectIACM />
      </Route>
      <Redirect from={`${path}`} to='404' exact />
    </Switch>
  )
}
