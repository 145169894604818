import React from 'react'

import { AngleRight } from '@rushable/icons'
import cn from 'classnames'
import Switch from 'components/Switch'

export type TOrderTypeDisclosureProp = {
  name: string
  type: string
  label: string
  enabled?: boolean
  hoursStatus: string
  children?: React.ReactElement
  onChange: (name: string, value: any) => void
  tabChange: (value: string) => void
}

export default function OrderTypeDisclosure({
  name,
  type,
  label,
  enabled,
  hoursStatus,
  children,
  tabChange,
  onChange,
}: TOrderTypeDisclosureProp): JSX.Element {
  return (
    <div
      className='p-6 mb-6 block bg-white border border-zinc rounded-lg'
      role='button'
    >
      <div className='relative flex justify-start items-center'>
        <div className='w-40 mr-8'>
          <Switch
            name={type}
            disabled={!!name}
            checked={enabled || false}
            label={
              <span className='text-base text-dark-100 ml-3 font-bold'>
                {label}
              </span>
            }
            position='left'
            onChange={v => onChange('enabled', v)}
            className='py-0'
          />
        </div>
        <div
          className='w-full flex items-center'
          onClick={() => tabChange(label)}
        >
          <div
            className={cn(
              'w-full px-4 text-base border-l border-solid border-zinc text-ellipsis truncate text-dark-32',
              name !== label ? null : 'opacity-0',
            )}
          >
            {hoursStatus}
          </div>
        </div>
        <div
          onClick={() => tabChange(label)}
          className={cn(
            'flex flex-1 justify-center items-center transition duration-75',
            name === label
              ? 'origin-center rotate-90 text-lead'
              : 'text-silver',
          )}
        >
          <AngleRight size={16} />
        </div>
      </div>
      {name === label && <div className='bg-white pt-6'>{children}</div>}
    </div>
  )
}
