import React, { useEffect, useState } from 'react'

import { Plus } from '@rushable/icons'
import Button from 'components/Button'
import RestaurantSelector from 'components/RestaurantSelector'
import SkeletonBrand from 'components/RestaurantSelector/SkeletonBrand'
import Tips from 'components/Tips'
import useSearch from 'hooks/useSearch'
import { useHistory } from 'react-router-dom'
import { logout } from 'redux/auth'
import { getBrands } from 'redux/brand'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getLocations } from 'redux/location'
import { TLocation } from 'types/location'

import AddBrandModal from './components/AddBrandModal'
import AddLocationModal from './components/AddLocationModal'
import IACMConnectModal from './components/IacmConnectModal'

export type TLocationModal = {
  open: boolean
  id: number | null
}

export type TConnectModal = {
  open: boolean
  location: TLocation | null
  config_id: string | null
  brand_id: number | null
}

export default function SelectRestaurant(): JSX.Element {
  const [locationModal, setLocationModal] = useState<TLocationModal>({
    open: false,
    id: null,
  })
  const [connectModal, setConnectModal] = useState<TConnectModal>({
    open: false,
    location: null,
    config_id: null,
    brand_id: null,
  })
  const [brandModalOpen, setBrandModalOpen] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { redirect_from, config_id } = useSearch()
  const { brands } = useAppSelector(state => state.brand)
  useEffect(() => {
    dispatch(getBrands())
  }, [])
  const handleSignout = () => {
    dispatch(logout())
  }
  const handleToggleBrand = (open: boolean, brand_id: number) => {
    if (open) {
      dispatch(getLocations({ brand_id }))
    }
  }
  const onAddModalOpen = (id: number) => {
    setLocationModal({
      open: true,
      id,
    })
  }
  const handleSelectLocation = (location: TLocation, brand_id: number) => {
    if (redirect_from && config_id) {
      setConnectModal({
        open: true,
        location,
        config_id,
        brand_id,
      })
    } else {
      history.push(`/brand/${brand_id}/location/${location.id}/order-history`)
    }
  }
  return (
    <>
      {redirect_from === 'iacm' && config_id ? (
        <div className='flex flex-col justify-center items-center pb-12'>
          <Tips className='flex-col h-16 justify-center' status='success'>
            <div className='text-center'>
              Please follow the steps below to select one of your restaurant
              locations
            </div>
            <div className='text-center'>
              and complete the ItsaCheckmate integration.
            </div>
          </Tips>
        </div>
      ) : null}
      <div className='flex flex-col justify-center items-center pb-12'>
        <div className='bg-white rounded-xl p-8 inline-block'>
          <h1 className='text-center text-2xl font-bold'>
            Choose your restaurant
          </h1>
          {!brands.length &&
            Array(3)
              .fill(0)
              .map((num, index) => <SkeletonBrand key={num + index} />)}
          {brands.length > 0 &&
            brands.map(brand => (
              <RestaurantSelector
                key={brand.id}
                brand={brand}
                onToggle={v => handleToggleBrand(v, brand.id)}
                onSelect={location => handleSelectLocation(location, brand.id)}
                onAddModalOpen={onAddModalOpen}
              />
            ))}
          <div className='text-center mt-8'>
            <Button
              color='primary-link'
              className='mx-auto'
              onClick={() => setBrandModalOpen(true)}
            >
              <Plus size={16} className='mr-1' /> ADD NEW BRAND
            </Button>
          </div>
        </div>
        <div className='text-center my-8'>
          <Button color='secondary-link' onClick={handleSignout}>
            SIGN OUT
          </Button>
        </div>
        <AddLocationModal
          locationModal={locationModal}
          setLocationModal={setLocationModal}
        />
        <AddBrandModal
          open={brandModalOpen}
          toggle={() => setBrandModalOpen(false)}
        />
        <IACMConnectModal
          connectModal={connectModal}
          setConnectModal={setConnectModal}
        />
      </div>
    </>
  )
}
