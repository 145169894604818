import { useEffect, useState } from 'react'

import {
  Dollar,
  LinedTrash,
  BadgeSilver,
  BadgeBronze,
  BadgeGold,
} from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import CalendarField from 'components/CalendarField'
import Field from 'components/Field'
import { Spin } from 'components/Loading'
import { Select } from 'components/Select'
import Table from 'components/TableAndCell/Table'
import currency from 'currency.js'
import { PERMISSION } from 'data/permission'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { useParams, useHistory, useRouteMatch } from 'react-router'
import { toast } from 'react-toastify'
import {
  getCustomersList,
  getCustomerData,
  setFilterList,
  setFilterKeywordList,
  setPageSize,
  setPage,
  setFormData,
  setShowFilter,
} from 'redux/customer'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { formatPhoneNumber } from 'utils/digit'
import downloadArrayBuffer from 'utils/downloadArrayBuffer'

import {
  FILTER_MORE_OR_LESS,
  FILTER_MORE_OR_FEWER,
  FILTER_BEFORE_OR_AFTER,
  SELECT_CUSTOMER,
} from '../helpers/constant'

const initColumns = [
  {
    key: 'customer',
    name: 'CUSTOMER',
    className: 'w-40',
    tdClassName: 'text-ink',
    custom: (value: string | number, row: any) => {
      return (
        <div className='flex items-center'>
          {!row.first_name ? (
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          ) : (
            `${row.first_name} ${row.last_name}`
          )}
        </div>
      )
    },
  },
  {
    key: 'phone',
    name: 'PHONE',
    className: 'w-40',
    custom: (value: string, row: any) => {
      return (
        <div className='flex items-center'>
          {value ? (
            formatPhoneNumber(value)
          ) : (
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          )}
        </div>
      )
    },
  },
  {
    key: 'email',
    name: 'EMAIL',
    custom: (value: any) => {
      return <div className='text-ellipsis'>{value}</div>
    },
  },
  {
    key: 'order_count',
    name: 'ORDER COUNT',
    align: 'right',
  },
  {
    key: 'spent_total',
    name: 'TOTAL SPEND',
    align: 'right',
    className: 'w-28',
    custom: (value: string | number) => {
      return (
        <div className='flex justify-end items-center'>
          {Number(value) > 0 ? (
            currency(value).format()
          ) : (
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          )}
        </div>
      )
    },
  },
  {
    key: 'last_order',
    name: 'LAST ORDER',
    align: 'right',
    className: 'w-40',
    custom: (value: string | number) => {
      return (
        <div className='flex justify-end items-center'>
          {value ? (
            moment(value).format('MM/DD/YYYY')
          ) : (
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          )}{' '}
        </div>
      )
    },
  },
]

export default function CustomerDataListPage(): JSX.Element {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { locationId } = useParams<{ locationId: string }>()
  const {
    getCustomerListRequest,
    customerList,
    filterList,
    filterKeywordList,
    pageSize,
    page,
    formData,
    showFilter,
    hasLoyaltyReward,
  } = useAppSelector(state => state.customer)
  const { selectedLocation } = useAppSelector(state => state.location)
  const auth = useAppSelector(state => state.auth.authProfile.data)
  const isCanExport =
    auth?.permissionMap?.[PERMISSION.EXPORTCUSTOMERDATA]?.allowed == 1
  const [columns, setColumns] = useState(initColumns)

  useEffect(() => {
    if (!showFilter) {
      handleSearch({
        pageNow: 1,
        pageSizeNow: pageSize,
      })
    }
  }, [showFilter])
  useEffect(() => {
    if (hasLoyaltyReward) {
      const newColumns = [...initColumns]
      newColumns.splice(1, 0, {
        key: 'available_points',
        name: 'LOYALTY',
        className: 'w-120px',
        custom: (value: any, row: any) => {
          return (
            <div className='flex items-center'>
              {row.rank ? (
                <div className='flex items-center text-ink text-sm'>
                  {row.rank === 'bronze' && (
                    <BadgeBronze size={20} className='mr-2' />
                  )}
                  {row.rank === 'silver' && (
                    <BadgeSilver size={20} className='mr-2' />
                  )}
                  {row.rank === 'gold' && (
                    <BadgeGold size={20} className='mr-2' />
                  )}
                  {value ? (
                    `${value} pts`
                  ) : (
                    <span
                      style={{ width: '5px', height: '1px' }}
                      className='bg-ink inline-block'
                    ></span>
                  )}
                </div>
              ) : (
                <span
                  style={{ width: '5px', height: '1px' }}
                  className='bg-ink inline-block'
                ></span>
              )}
            </div>
          )
        },
      })
      setColumns([...newColumns])
    }
  }, [hasLoyaltyReward])

  useEffect(() => {
    const newFilterKeywordList = cloneDeep(filterKeywordList)
    newFilterKeywordList.map(k => {
      k.disabled = filterList.includes(k.label)
    })
    dispatch(setFilterKeywordList([...newFilterKeywordList]))
  }, [filterList])

  const handleAddFiler = () => {
    dispatch(setFilterList([...filterList, '']))
  }

  const handleSelectFiler = (filterItem: string, i: number) => {
    const newFilterList = cloneDeep(filterList)
    newFilterList.splice(i, 1, filterItem)
    dispatch(setFilterList([...newFilterList]))
  }

  const handleDeleteFiler = (filterItem: string, i: number) => {
    const newFilterList = cloneDeep(filterList)
    newFilterList.splice(i, 1)
    dispatch(setFilterList([...newFilterList]))

    if (filterItem === 'Last order date') {
      dispatch(
        setFormData({
          ...formData,
          last_order_date: '',
          last_order_operator: '',
        }),
      )
    }
    if (filterItem === 'Total spend') {
      dispatch(
        setFormData({
          ...formData,
          spent_total: '0.00',
          spent_total_operator: '',
        }),
      )
    }
    if (filterItem === 'Order count') {
      dispatch(
        setFormData({
          ...formData,
          order_count_operator: '',
          order_count: '1',
        }),
      )
    }
    if (filterItem === 'Keyword filter') {
      dispatch(
        setFormData({
          ...formData,
          keyword: '',
        }),
      )
    }
  }

  const handleSearch = ({
    pageNow,
    pageSizeNow,
  }: {
    pageNow: number
    pageSizeNow: number
  }) => {
    dispatch(setPage(pageNow))
    dispatch(setPageSize(pageSizeNow))
    dispatch(
      getCustomersList({
        locationId,
        page: pageNow,
        per_page: pageSizeNow,
        keyword: showFilter ? formData.keyword : '',
        last_order_date:
          showFilter && filterList.indexOf('Last order date') > -1
            ? formData.last_order_date
            : '',
        last_order_operator:
          showFilter && filterList.indexOf('Last order date') > -1
            ? formData.last_order_operator
            : '',

        order_count_operator:
          showFilter && filterList.indexOf('Order count') > -1
            ? formData.order_count_operator
            : '',
        order_count:
          showFilter && filterList.indexOf('Order count') > -1
            ? formData.order_count
            : '',

        spent_total:
          showFilter && filterList.indexOf('Total spend') > -1
            ? formData.spent_total
            : '',
        spent_total_operator:
          showFilter && filterList.indexOf('Total spend') > -1
            ? formData.spent_total_operator
            : '',
      }),
    )
    // todo 请求data
  }

  const goDetail = (orderId: number) => {
    history.push(`${url}/customer-detail/${orderId}`)
  }

  const getExport = async () => {
    try {
      const res = await dispatch(
        getCustomerData({
          location_id: locationId,
        }),
      )
      if (selectedLocation) {
        const { brand, name } = selectedLocation
        let fileName = `Customers ${brand.name} ${name}`
        fileName = fileName.replace(/\s+/g, '-').replace(/[^\w-]+/g, '')
        downloadArrayBuffer(res, fileName)
      }
    } catch (error: any) {
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  return (
    <AdminPage>
      <AdminPageTitle title='Customer Data' />
      <Spin
        spining={page === 1 && getCustomerListRequest && !customerList}
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='py-8 px-8'>
          <div>
            <Select
              placeholder='Select'
              options={SELECT_CUSTOMER}
              value={showFilter || ''}
              onChange={value => {
                dispatch(setShowFilter(value))
              }}
            />
          </div>
          {!!showFilter && (
            <div className='mt-6 p-6 rounded-lg border border-solid border-zinc'>
              <div className='grid gap-4'>
                {filterList.map((filterItem, i) => {
                  return (
                    <div className='flex items-center' key={i}>
                      <Select
                        className='w-[200px]'
                        placeholder='Select'
                        options={filterKeywordList}
                        value={filterItem}
                        onChange={value => {
                          handleSelectFiler(value, i)
                        }}
                      />
                      <div className='w-0.5 h-[22px] bg-silver mx-4'></div>
                      {filterItem === 'Keyword filter' && (
                        <div className='flex items-center text-ink'>
                          Contains{' '}
                          <Field
                            name='keyword'
                            containerClassName='mx-2 w-[192px]'
                            type='text'
                            value={formData.keyword}
                            placeholder='Input here'
                            onChange={e =>
                              dispatch(
                                setFormData({
                                  ...formData,
                                  keyword: e.target.value,
                                }),
                              )
                            }
                          />
                          in name, phone, or email data.
                        </div>
                      )}
                      {filterItem === 'Order count' && (
                        <div className='flex items-center text-ink'>
                          Placed
                          <Select
                            className='mx-2 w-[76px]'
                            hideIndicator={true}
                            placeholder='Select'
                            options={FILTER_MORE_OR_FEWER}
                            value={formData.order_count_operator}
                            onChange={value => {
                              dispatch(
                                setFormData({
                                  ...formData,
                                  order_count_operator: value,
                                }),
                              )
                            }}
                          />
                          than
                          <Field
                            name='count'
                            align='right'
                            containerClassName='mx-2 w-[68px]'
                            type='text'
                            value={formData.order_count}
                            onChange={e => {
                              dispatch(
                                setFormData({
                                  ...formData,
                                  order_count: e.target.value.replace(
                                    /[^\d]/g,
                                    '',
                                  ),
                                }),
                              )
                            }}
                          />
                          order(s) in lifetime.
                        </div>
                      )}
                      {filterItem === 'Total spend' && (
                        <div className='flex items-center text-ink'>
                          Spent
                          <Select
                            className='mx-2 w-[76px]'
                            placeholder='Select'
                            hideIndicator={true}
                            options={FILTER_MORE_OR_LESS}
                            value={formData.spent_total_operator}
                            onChange={value => {
                              dispatch(
                                setFormData({
                                  ...formData,
                                  spent_total_operator: value,
                                }),
                              )
                            }}
                          />
                          than
                          <Field
                            name='total'
                            align='right'
                            inputMode='money'
                            containerClassName='mx-2 w-[138px]'
                            suffix={<Dollar size={16} />}
                            type='text'
                            value={formData.spent_total}
                            onChangeMoney={value => {
                              dispatch(
                                setFormData({
                                  ...formData,
                                  spent_total: value,
                                }),
                              )
                            }}
                          />
                          in lifetime.
                        </div>
                      )}
                      {filterItem === 'Last order date' && (
                        <div className='flex items-center text-ink'>
                          Last time ordered{' '}
                          <Select
                            className='mx-2 w-[76px]'
                            placeholder='Select'
                            hideIndicator={true}
                            options={FILTER_BEFORE_OR_AFTER}
                            value={formData.last_order_operator}
                            onChange={value => {
                              dispatch(
                                setFormData({
                                  ...formData,
                                  last_order_operator: value,
                                }),
                              )
                            }}
                          />
                          <CalendarField
                            className='w-[138px]'
                            date={
                              formData.last_order_date
                                ? new Date(formData.last_order_date)
                                : new Date()
                            }
                            value={moment(formData.last_order_date).format(
                              'MM/DD/YYYY',
                            )}
                            onChange={date => {
                              dispatch(
                                setFormData({
                                  ...formData,
                                  last_order_date:
                                    moment(date).format('YYYY-MM-DD'),
                                }),
                              )
                            }}
                          />
                        </div>
                      )}
                      {filterList.length > 1 && (
                        <Button
                          color='secondary-link'
                          className='ml-auto w-5 h-5'
                          onClick={() => handleDeleteFiler(filterItem, i)}
                        >
                          <LinedTrash size={16} className='text-ink' />
                        </Button>
                      )}
                    </div>
                  )
                })}
              </div>

              <div className='flex  mt-4 pt-4 border-t border-dashed border-zinc'>
                {filterList.length < 4 && (
                  <Button
                    className='mr-4'
                    color='tertiary'
                    onClick={() => handleAddFiler()}
                  >
                    ADD FILTER
                  </Button>
                )}

                <Button
                  color='primary'
                  onClick={() =>
                    handleSearch({
                      pageNow: 1,
                      pageSizeNow: pageSize,
                    })
                  }
                  loading={getCustomerListRequest}
                >
                  SEARCH NOW
                </Button>
              </div>
            </div>
          )}

          <div className='mt-6'>
            <Table
              isPageSet={true}
              containerClassName='text-xs text-lead rounded-lg border border-solid border-zinc overflow-hidden'
              columns={columns}
              current={customerList?.current_page || 1}
              data={customerList?.data || []}
              total={customerList?.total || 0}
              loading={getCustomerListRequest}
              resizePageSize={pageSize || 20}
              totalText={'records'}
              paginationShow
              clickedRow={row =>
                row.order_count > 0 ? goDetail(row.id) : undefined
              }
              rightButton={
                isCanExport ? (
                  <div className='flex space-x-4'>
                    <Button size='sm' color='tertiary' onClick={getExport}>
                      EXPORT DATA
                    </Button>
                  </div>
                ) : undefined
              }
              onPageNoChange={value =>
                handleSearch({
                  pageNow: value,
                  pageSizeNow: pageSize,
                })
              }
              onPageSizeChange={value =>
                handleSearch({
                  pageNow: 1,
                  pageSizeNow: value,
                })
              }
            />
          </div>
        </div>
      </Spin>
    </AdminPage>
  )
}
