import React from 'react'

import cn from 'classnames'

export type Tprops = {
  color: 'red' | 'blue' | 'green' | 'gray' | 'yellow' | 'orange'
  children: string | React.ReactElement
  className?: string
}

export default function StatusTag({
  children,
  color,
  className,
}: Tprops): JSX.Element {
  return (
    <span
      className={cn(
        className,
        'px-2 py-[2.5px] text-xs font-medium rounded ',
        { 'text-blue-darken bg-blue/[0.08]': color === 'blue' },
        { 'text-red-darken bg-red/[0.08]': color === 'red' },
        { 'text-green-darken bg-green/[0.08]': color === 'green' },
        { 'text-yellow-darken bg-yellow/[0.08]': color === 'yellow' },
        { 'text-orange-darken bg-orange-darken/[0.08]': color === 'orange' },
        { 'text-lead bg-field': color === 'gray' },
      )}
    >
      {children}
    </span>
  )
}
