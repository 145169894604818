import React, { useEffect, useState } from 'react'

import Button from 'components/Button'
import useSearch from 'hooks/useSearch'
import { useHistory } from 'react-router-dom'
import { passwordResetVerify } from 'redux/auth'
import { useAppSelector, useAppDispatch } from 'redux/hooks'

import ForgotPasswordForm from './components/ForgotPasswordForm'

export default function ResetPasswordPage(): JSX.Element {
  const [type, setType] = useState<'reset' | 'success'>('reset')
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  })
  const [formError, setFormError] = useState<string>('')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { code, email } = useSearch()
  const { passwordResetVerifyRequest, passwordResetVerifyError } =
    useAppSelector(state => state.auth)

  const handleFormChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    })
    if (name === 'confirmPassword' && value !== formData.password) {
      setFormError('Passwords do not match')
    } else {
      setFormError('')
    }
  }

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    const params = {
      email,
      verification_code: code,
      new_password: formData.password,
      new_password_confirmation: formData.confirmPassword,
    }
    const data = await dispatch(passwordResetVerify(params))
    if (data.type !== 'auth/password-reset-verify/rejected') {
      setType('success')
    }
  }

  const gotoLogin = () => {
    history.push('/login')
  }

  useEffect(() => {
    if (passwordResetVerifyError) {
      setFormError(passwordResetVerifyError)
    }
  }, [passwordResetVerifyError])

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='bg-white rounded-xl p-8 inline-block'>
        <h1 className='text-center text-2xl font-bold'>Reset Password</h1>
        {type === 'reset' ? (
          <ForgotPasswordForm
            handleFormChange={handleFormChange}
            password={formData.password}
            confirmPassword={formData.confirmPassword}
            handleLogin={handleLogin}
            gotoLogin={gotoLogin}
            request={passwordResetVerifyRequest}
            error={formError}
          />
        ) : (
          <>
            <div className='text-center mt-6 mb-8'>
              ✅ You have successfully reset your password
            </div>
            <Button
              color='primary'
              className='w-[400px]'
              type='submit'
              onClick={gotoLogin}
            >
              BACK TO SIGN IN
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
