import React from 'react'

import cn from 'classnames'

export type TAdminPageTabProp = {
  icon?: React.ReactElement
  text?: string
  active?: boolean
  onClick?: () => void
}

export default function AdminPageTab({
  icon,
  text,
  active,
  onClick,
}: TAdminPageTabProp): JSX.Element {
  return (
    <div
      className={cn(
        'relative flex justify-center items-center w-full cursor-pointer',
        active
          ? 'bg-blue-opacity-8  border-blue border-b-4'
          : 'bg-transparent border-zinc border-b',
      )}
      role='button'
      onClick={() => onClick && onClick()}
    >
      {icon && (
        <div className={cn(active ? 'text-blue' : 'text-silver')}>{icon}</div>
      )}
      {text && <h6 className='pt-4 pb-3 text-xl text-ink font-bold'>{text}</h6>}
    </div>
  )
}
