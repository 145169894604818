/**
 * The user profile setting form
 */
import Field from 'components/Field'
import { Select } from 'components/Select'

import { accountTypeOptions } from '../helpers/data'

export type TProfileFormProp = {
  formData: any
  onFormChange: (name: string, value: any) => void
}
export default function ProfileForm({
  formData,
  onFormChange,
}: TProfileFormProp): JSX.Element {
  return (
    <div>
      <div className='grid grid-cols-2 gap-6'>
        <Field
          type='text'
          label='First name'
          name='first_name'
          value={formData.first_name}
          containerClassName='w-full mb-6'
          onChange={e => onFormChange('first_name', e.target.value)}
        />
        <Field
          type='text'
          label='Last name'
          name='last_name'
          value={formData.last_name}
          containerClassName='w-full mb-6'
          onChange={e => onFormChange('last_name', e.target.value)}
        />
      </div>
      <div className='grid grid-cols-2 gap-6'>
        <Field
          type='tel'
          label='Cellphone'
          inputMode='tel'
          name='phone'
          value={formData.phone}
          containerClassName='w-full mb-6'
          onChangePhone={value => {
            onFormChange('phone', value)
          }}
        />
        <Field
          type='tel'
          label='Repeat cellphone'
          name='cellphone'
          inputMode='tel'
          value={formData.repeat_phone}
          containerClassName='w-full mb-6'
          onChangePhone={value => onFormChange('repeat_phone', value)}
        />
      </div>
      <Select
        label='Account type'
        options={accountTypeOptions}
        value={formData.role || ''}
        placeholder='Select...'
        onChange={value => onFormChange('role', value)}
      />
    </div>
  )
}
