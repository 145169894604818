import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'utils/axiosApi'

/**
 * Call API to fetch selected user
 */
export const getUser = createAsyncThunk(
  'user/getUser',
  async ({ user_id }: { user_id: number }) => {
    const response = await axios.request({
      url: `users/${user_id}`,
      method: 'get',
    })
    return response.data
  },
)

/**
 * Call API to invite user to brand
 */
export const inviteUser = createAsyncThunk(
  'user/inviteUser',
  async (params: {
    phone: string
    email: string
    assigned_locations: number[]
  }) => {
    const response = await axios.request({
      url: 'users/invite',
      method: 'post',
      data: params,
    })
    return response.data
  },
)

export async function getSupportCodeApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/support_codes`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

/*** ================================  Profile  =========================================*/
export async function getUserProfileApi(brandId: string) {
  const response = await axios.request({
    url: `brands/${brandId}/profile`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export type IEditProfileType = 'basic' | 'password' | 'email' | 'phone'
export interface IeditUser {
  type: IEditProfileType
  old_password?: string
  new_password?: string
  new_password_confirmation?: string
  phone?: string
  email?: string
  code?: string
  first_name?: string
  last_name?: string
}
export async function editUserProfileApi(
  params: IeditUser,
  userId: string,
  brandId: string,
) {
  const response = await axios.request({
    url: `brands/${brandId}/users/${userId}`,
    method: 'patch',
    params,
  })
  return response.data
}

export async function editNotificationProfileApi(
  formData: FormData,
  id: string,
  brand_id?: string,
) {
  const response = await axios.request({
    url: `brands/${brand_id}/user_notification_configs/${id}`,
    method: 'post',
    params: {
      _method: 'put',
    },
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

interface ISendCode {
  type: 'login' | 'update_email' | 'update_phone' | 'forget_password'
  phone?: string
  email?: string
}
export async function sendVerifyCodeApi(params: ISendCode) {
  const response = await axios.request({
    url: 'auth/send_code',
    data: params,
    method: 'post',
  })
  return response.data
}

/*** ================================  Profile  =========================================*/

/*** ================================  Team Users  =========================================*/

interface ITeamUsersListType {
  locationId?: string
  keyword?: string
}

export async function getUsersListAPI(params: ITeamUsersListType) {
  const response = await axios.request({
    url: `locations/${params.locationId}/users`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params: {
      keyword: params.keyword,
    },
  })
  return response.data
}

export async function updatePermissionListAPI(params: any) {
  const response = await axios.request({
    url: `brands/${params.brand_id}/user_permissions/update_all`,
    method: 'post',
    cache: {
      ignoreCache: true,
    },
    data: params,
  })
  return response.data
}

export async function editUserRoleAPI(params: any) {
  const response = await axios.request({
    url: `brands/${params.brand_id}}/users/${params.user_id}/edit`,
    method: 'post',
    cache: {
      ignoreCache: true,
    },
    data: { role: params.role, location_ids: params.location_ids },
  })
  return response.data
}

interface IUsersDetailOrInvitationType {
  type?: string
  user_id?: string
  user_invitation_id?: string
  brand_id?: string
  location_id?: string
}
export async function getUserDetailOrInvitationAPI(
  params: IUsersDetailOrInvitationType,
) {
  const response = await axios.request({
    url: `brands/${params.brand_id}/user_or_invitation`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params,
  })
  return response.data
}

export async function getPermissionListAPI(params?: any) {
  const response = await axios.request({
    url: 'permission_categories',
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params,
  })
  return response.data
}

interface IInvitationUserType {
  brand_id?: string
  role?: string
  first_name?: string
  last_name?: string
  phone?: string
  permissions?: any
  location_ids?: number[]
}

export async function invitationUserAPI(
  brandId: string | undefined,
  params: IInvitationUserType,
) {
  const response = await axios.request({
    url: `brands/${brandId}/user_invitations`,
    method: 'post',
    cache: {
      ignoreCache: true,
    },
    data: params,
  })
  return response.data
}

export async function updateUserStatusAPI(
  userId: string | undefined,
  brandId: string | undefined,
  params: { status: string },
) {
  const response = await axios.request({
    url: `brands/${brandId}/users/${userId}/toggle`,
    method: 'post',
    cache: {
      ignoreCache: true,
    },
    data: params,
  })
  return response.data
}

export async function cancelInvitationAPI(
  brandId: string | undefined,
  userId: string | undefined,
) {
  const response = await axios.request({
    url: `brands/${brandId}/user_invitations/${userId}`,
    method: 'delete',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getInvitationAPI(params: { token: string | undefined }) {
  const response = await axios.request({
    url: 'user_invitation',
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params,
  })
  return response.data
}

export async function resendInvitationAPI(
  brandId: string | undefined,
  userId: string | undefined,
) {
  const response = await axios.request({
    url: `brands/${brandId}/user_invitation/${userId}/resend`,
    method: 'post',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function acceptInvitationAPI(params: any) {
  const response = await axios.request({
    url: 'user_invitation/accept',
    method: 'post',
    cache: {
      ignoreCache: true,
    },
    data: params,
  })
  return response.data
}

// client_dashboard/user_invitation/validate
export async function validateInvitationAPI(params: any) {
  const response = await axios.request({
    url: 'user_invitation/validate',
    method: 'post',
    cache: {
      ignoreCache: true,
    },
    data: params,
  })
  return response.data
}

export async function sendCode(params: any) {
  const response = await axios.request({
    url: 'send_code',
    method: 'post',
    cache: {
      ignoreCache: true,
    },
    data: params,
  })
  return response.data
}

export const setValidateErrorMsg = createAction<string>(
  'auth/setValidateErrorMsg',
)
export const setCodeErrorMsg = createAction<string>('auth/setCodeErrorMsg')
/*** ================================  Team Users  =========================================*/
