import React, { useEffect, useState } from 'react'

import { LinedPenEdit } from '@rushable/icons'
import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import Drawer from 'components/Drawer'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getMenuModifierAPI } from 'redux/onlineMenu'

import ChangeModifier from './ChangeModifier'
import OnlineMenuSkeleton from './OnlineMenuSkeleton'

type TModifierDrawerProp = {
  open: boolean
  toggle: () => void
  onChange: (e: any) => void
  formData: any
}

export default function ModifierDrawer({
  open,
  toggle,
  onChange,
  formData,
}: TModifierDrawerProp): JSX.Element {
  const [modifierList, setModifierList] = useState<any>([])
  const { locationId } = useParams<TParamTypes>()
  const [modifierInfo, setModifierInfo] = useState({ open: false, id: 0 })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) {
      getMenuModifier()
    }
  }, [open])

  const getMenuModifier = async () => {
    setLoading(true)
    try {
      const res = await getMenuModifierAPI(locationId)
      res.map((v: any, i: number) => {
        formData.modifier_list.map((m: any) => {
          if (v.id === m.id) {
            res[i]['checked'] = true
          }
        })
      })
      setModifierList([...res])
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  const handleFormChange = (name: string, value: any, index: number) => {
    const newModifierList = [...modifierList]
    newModifierList[index || 0] = {
      ...newModifierList[index || 0],
      [name]: value,
    }
    setModifierList([...newModifierList])
  }

  const submitModifier = () => {
    const newModifierList: any = []
    modifierList.map((item: { checked: boolean }) => {
      if (item.checked) {
        newModifierList.push(item)
      }
    })
    toggle()
    onChange(newModifierList)
  }

  return (
    <>
      <Drawer
        open={open}
        toggle={() => {
          if (!modifierInfo.open) {
            toggle()
          }
        }}
        title='ADD MODIFIER'
        rightFooter={
          <>
            <Button color='secondary' onClick={toggle}>
              CANCEL
            </Button>
            <Button color='primary' onClick={() => submitModifier()}>
              CONFIRM
            </Button>
          </>
        }
      >
        <>
          <div className='w-full grid gap-4 mt-2'>
            {loading ? (
              <>
                <OnlineMenuSkeleton type={3} />
                <OnlineMenuSkeleton type={3} />
                <OnlineMenuSkeleton type={3} />
              </>
            ) : (
              <>
                {modifierList.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`w-[496px] p-2 flex items-center rounded-lg ${
                        item.checked
                          ? 'bg-white border border-solid border-zinc'
                          : 'bg-field'
                      }`}
                    >
                      <CheckBox
                        size={20}
                        checked={item.checked}
                        onChange={e => handleFormChange('checked', e, index)}
                      ></CheckBox>
                      <div className='flex-1 mx-2 text-ink leading-snug text-ellipsis'>
                        {item.name}
                      </div>
                      <div className='flex items-center'>
                        <div className='mr-2 font-medium text-sm text-silver'>
                          {item.note}
                        </div>
                        <Button
                          color='secondary-link'
                          onClick={() => {
                            setModifierInfo({ id: item.id, open: true })
                          }}
                        >
                          <LinedPenEdit size={16} className='text-ink' />
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
          {!loading && (
            <Button
              color='tertiary'
              className='mt-6 w-full'
              onClick={() => {
                setModifierInfo({ id: 0, open: true })
              }}
            >
              CREATE NEW MODIFIER
            </Button>
          )}
        </>
        <ChangeModifier
          open={modifierInfo.open}
          id={modifierInfo.id}
          toggle={value => {
            setModifierInfo({ ...modifierInfo, open: false })
            value && getMenuModifier()
          }}
        />
      </Drawer>
    </>
  )
}
