import Button from 'components/Button'
import moment from 'moment'
import { toast } from 'react-toastify'
import type { TCampaignItem } from 'types/campaign'

import AudienceForm from './AudienceForm'
import SendingScheduleForm from './SendingScheduleForm'
import SendTestEmail from './SendTestEmail'
import Line from '../Line'

type TSendingRulesFormProps = {
  stepLoading: boolean
  formData: TCampaignItem
  viewOnly?: boolean
  prevStep: () => void
  createOrUpdate: () => void
  handleFormDataChange: (type: string, value: any) => void
}

export default function SendingRulesForm({
  stepLoading,
  formData,
  viewOnly,
  prevStep,
  createOrUpdate,
  handleFormDataChange,
}: TSendingRulesFormProps): JSX.Element {
  const { trigger_of_date_time, id, type: campaignType } = formData
  let triggerOfDate = ''
  let triggerOfTime = ''
  if (trigger_of_date_time && trigger_of_date_time.length > 0) {
    triggerOfDate = trigger_of_date_time.split(' ')[0]
    triggerOfTime = trigger_of_date_time.split(' ')[1]
  }

  return (
    <div className='mt-8'>
      <SendTestEmail id={id} />
      <Line className='mt-6 mb-6' />
      <SendingScheduleForm
        viewOnly={viewOnly}
        formData={formData}
        handleFormDataChange={handleFormDataChange}
      />
      <Line className='mt-6 mb-6' />
      <AudienceForm
        viewOnly={viewOnly}
        formData={formData}
        handleFormDataChange={handleFormDataChange}
      />
      <Line className='my-6' />
      <div className='flex justify-between'>
        <Button color='secondary' className='mr-3 w-full' onClick={prevStep}>
          PREVIOUS STEP
        </Button>
        <Button
          color='primary'
          className='w-full'
          onClick={() => {
            if (campaignType === 'one_off' && !viewOnly) {
              const time = `${triggerOfDate} ${triggerOfTime}`
              const isAfterTime = moment(time).isAfter(moment())
              if (!isAfterTime) {
                toast.error('Please select a future time')
                return
              }
            }
            createOrUpdate()
          }}
          loading={stepLoading}
        >
          NEXT STEP
        </Button>
      </div>
    </div>
  )
}
