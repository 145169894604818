import React from 'react'

import cn from 'classnames'

export type TModeSwitcherOption = {
  label: string
  value: string
  activeColor?: 'bg-blue' | 'bg-green'
}
export type TModeSwitcherProp = {
  value: string
  options: TModeSwitcherOption[]
  onClick?: (value: string) => void
  className?: string
}

export default function ModeSwitcher({
  options = [],
  value,
  onClick,
  className,
}: TModeSwitcherProp): JSX.Element {
  return (
    <div
      className={cn(
        'p-1 bg-light-8 rounded-lg flex flex-row items-center',
        className,
      )}
    >
      {options.map((o, index) => (
        <button
          key={index}
          className={cn(
            'w-1/2 p-2 rounded text-white text-xs font-bold',
            value === o.value ? o.activeColor : 'bg-transparent',
          )}
          onClick={() => onClick && onClick(o.value as 'brand' | 'location')}
        >
          {o.label}
        </button>
      ))}
    </div>
  )
}
