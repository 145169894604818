import React, { Ref, TextareaHTMLAttributes } from 'react'

import cn from 'classnames'
import Label from 'components/Label'

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string | React.ReactElement | React.ReactElement[]
  containerClass?: string
  inputClass?: string
}

const style = {
  normal: 'bg-field border-field hover:bg-white hover:border-blue',
  disabled: 'cursor-not-allowed bg-ice border-ice placeholder-dark-16',
  default:
    'border-2 text-base w-full rounded-lg py-3 px-4 text-ink outline-none focus:border-blue focus:bg-white ',
}

const TextArea = React.forwardRef(
  (
    {
      disabled,
      label,
      containerClass = '',
      inputClass = '',
      ...rest
    }: ITextAreaProps,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    return (
      <div className={containerClass}>
        {label && <Label className='mb-2 text-xs'>{label}</Label>}
        <textarea
          ref={ref}
          className={cn(
            'min-h-[100px]',
            style.default,
            inputClass,
            disabled ? style.disabled : style.normal,
          )}
          disabled={disabled}
          {...rest}
        />
      </div>
    )
  },
)

export default TextArea
