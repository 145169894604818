import React, { useEffect, useState } from 'react'

import Table, { TColumnProp } from 'components/TableAndCell/Table'
import Tag from 'components/Tag'
import { PERMISSION } from 'data/permission'
import useDebounce from 'hooks/useDebounce'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { getUsersListAPI } from 'redux/user'

import NewUserDrawer from './components/NewUserDrawer'
import UsersFilters from './components/UsersFilters'
import { getStatusTagArgs } from './helpers/args'

export default function UserManagementPage(): JSX.Element {
  const auth = useAppSelector(state => state.auth.authProfile.data)
  const history = useHistory()
  const { locationId } = useParams<TParamTypes>()
  const { url } = useRouteMatch()
  const [filters, setFilters] = useState({
    keyword: '',
  })
  const [openDrawer, setOpenDrawer] = useState(false)
  const [formData, setFormData] = useState<any>([])
  const [isInvite, setIsInvite] = useState(false)
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(true)
  const isCanInviteNewUser =
    auth?.permissionMap?.[PERMISSION.INVITENEWUSER]?.allowed == 1

  const onFiltersChange = (name: string, value: any) => {
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const tableCols: TColumnProp[] = [
    {
      key: 'status',
      name: 'STATUS',
      custom: (value, row) => {
        const { text, color } = getStatusTagArgs(
          value as string,
          row.expiration,
        )
        return <Tag dot dotColor={color} text={text} />
      },
      className: 'w-48',
    },
    {
      key: 'name',
      name: 'NAME',
      custom: (value, row) => {
        const isMe = auth?.id === row.id
        return (
          <div className='text-lead'>{`${row.first_name} ${row.last_name}${
            isMe ? '(me)' : ''
          }`}</div>
        )
      },
      className: 'w-60',
    },
    {
      key: 'role',
      name: 'ACCOUNT TYPE',
      custom: value => (
        <div className='text-lead'>
          {value === 'brand_admin'
            ? 'Brand Admin'
            : value === 'location_owner'
            ? 'Location Admin'
            : value.charAt(0).toUpperCase() + value.slice(1)}
        </div>
      ),
      className: 'w-48',
    },
    {
      key: 'locations',
      name: 'LOCATION ASSIGNED',
      align: 'right',
      tdClassName: 'whitespace-normal',
      custom: (value, row) => (
        <div className='text-dark-64'>
          {' '}
          {row.role === 'brand_admin' ? (
            'All Locations'
          ) : (
            <div className='flex justify-end space-x-2'>
              {value?.map((v: any, index: number) => (
                <Tag key={index} text={v.name} />
              ))}
            </div>
          )}
        </div>
      ),
    },
  ]

  const getUserList = async (keyword: string) => {
    setLoading(true)
    try {
      const res = await getUsersListAPI({
        locationId,
        keyword,
      })
      setFormData([...res.data])
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const getUserListFn = useDebounce(getUserList)

  useEffect(() => {
    getUserListFn(filters.keyword)
  }, [filters.keyword])

  return (
    <>
      <UsersFilters
        data={filters}
        disabled={!isCanInviteNewUser}
        onChange={onFiltersChange}
        onAddUser={() => {
          setOpenDrawer(!openDrawer)
          setIsInvite(false)
        }}
      />
      <NewUserDrawer
        open={openDrawer}
        toggle={() => {
          setOpenDrawer(!openDrawer)
          setIsInvite(false)
        }}
        handleInvite={() => getUserList('')}
        isInvite={isInvite}
        userId={userId}
        title='Invite New User'
      />
      <div className='py-6'>
        <Table
          loading={loading}
          columns={tableCols}
          data={formData}
          trClassName='align-top'
          clickedRow={rowItem => {
            if (rowItem.type === 'user') {
              history.push(`${url}/users-detail/${rowItem.id}`)
            } else {
              setUserId(rowItem.id)
              setIsInvite(true)
              setOpenDrawer(!openDrawer)
            }
          }}
        />
      </div>
    </>
  )
}
