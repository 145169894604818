import React, { useState, useEffect } from 'react'

import {
  Check,
  Dots,
  DuoToneBirthdayCake,
  DuoToneCalendar7,
  DuoToneCalendar14,
  DuoToneCalendar30,
  DuoToneCalendar60,
} from '@rushable/icons'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { getTemplates } from 'redux/campaign'
import { useAppDispatch } from 'redux/hooks'
import type { TCampaignItem } from 'types/campaign'

import type { TAddOpenDataState } from './helpers/types'

type TAutomatedCampaignPageProp = {
  setAddOpenData: (data: TAddOpenDataState) => void
}

const SetupButton = () => (
  <Button size='sm' className='w-[fit-content] whitespace-nowrap'>
    SETUP NOW
  </Button>
)
const RunningButton = () => (
  <div className='px-4 py-2 w-[fit-content] bg-green-opacity-8 rounded-sm text-xs font-bold inline-flex justify-center items-center text-green-darken'>
    <div className='w-3 h-3 mr-1 bg-green-darken rounded-full flex justify-center items-center'>
      <Check className='text-white' size={8} />
    </div>
    Active & Running
  </div>
)
const PausedButton = () => (
  <div className='px-4 py-2 w-[fit-content] bg-yellow-opacity-8 rounded-sm text-xs font-bold inline-flex justify-center items-center text-yellow-darken'>
    <Dots className='text-yellow-darken mr-1' size={12} />
    Campaign Paused
  </div>
)

const iconRender = (template: any) => {
  if (template.trigger_type === 'birthday') {
    return <DuoToneBirthdayCake size={24} />
  } else {
    if (template.trigger_of_inactive_days === 7) {
      return <DuoToneCalendar7 size={24} />
    }
    if (template.trigger_of_inactive_days === 14) {
      return <DuoToneCalendar14 size={24} />
    }
    if (template.trigger_of_inactive_days === 30) {
      return <DuoToneCalendar30 size={24} />
    }
    if (template.trigger_of_inactive_days === 60) {
      return <DuoToneCalendar60 size={24} />
    }
  }
}

export default function AutomatedCampaignPage({
  setAddOpenData,
}: TAutomatedCampaignPageProp): JSX.Element {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { brandId, locationId } =
    useParams<{ brandId: string; locationId: string }>()
  const [templateItems, setTemplateItems] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const handleGetTemplates = async () => {
    setLoading(true)
    try {
      const res = await dispatch(getTemplates(locationId))
      setLoading(false)
      setTemplateItems(res.data)
    } catch (error: any) {
      setLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }
  const goDetail = (count: number) => {
    if (templateItems[count].campaigns[0]) {
      const item = templateItems[count].campaigns[0]
      setAddOpenData({
        step:
          item.status === 'draft' || item.status === 'scheduled'
            ? Number(item.current_step)
            : 5,
        type: 'automated',
        typeName: 'Automated Campaign',
        item,
      })
      history.push(
        `/brand/${brandId}/location/${locationId}/outbound-campaign/campaign-detail/${item.id}`,
      )
    } else {
      setAddOpenData({
        step: 1,
        type: 'automated',
        typeName: 'Automated Campaign',
        item: {
          ...templateItems[count],
          id: 0,
          campaign_template_id: templateItems[count].id,
        },
        create: true,
      })
      history.push(
        `/brand/${brandId}/location/${locationId}/outbound-campaign/campaign-detail/0`,
      )
    }
  }

  const renderButton = (item: TCampaignItem | null) => {
    if (!item) {
      return SetupButton()
    } else {
      if (item.status === 'draft' || item.status === 'scheduled') {
        return SetupButton()
      } else if (item.status === 'processing' || item.status === 'complete') {
        return RunningButton()
      } else if (item.status === 'paused') {
        return PausedButton()
      }
    }
  }

  useEffect(() => {
    handleGetTemplates()
  }, [])

  return (
    <div className='pt-8'>
      <Spin
        spining={loading}
        className='rounded-b-lg overflow-hidden min-w-[920px]'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='flex flex-wrap justify-between'>
          {templateItems && templateItems.length > 0
            ? templateItems.map((template, index) => {
                return (
                  <div
                    className='w-[326px] h-[246px] px-6 py-4 border border-zinc rounded-lg flex flex-col justify-between cursor-pointer mb-6 mr-6 hover:border-blue'
                    onClick={() => goDetail(index)}
                    key={`template-${index}`}
                  >
                    <div className='flex flex-col'>
                      {iconRender(template)}
                      <div className='text-xl font-bold pt-4 pb-2'>
                        {template.name}
                      </div>
                      <div className='text-base text-lead'>
                        {template.trigger_type === 'birthday'
                          ? 'Send a wish/offer to a customer 7 days before the birthday date.'
                          : `Re-engage with customers who haven’t been placing any order for
                more than ${template.trigger_of_inactive_days} days since the last time.`}
                      </div>
                    </div>
                    {templateItems && templateItems.length > 0
                      ? renderButton(template.campaigns[0])
                      : SetupButton()}
                  </div>
                )
              })
            : null}
          <div className='w-[326px] h-[246px] px-6 py-4 rounded-lg flex flex-col justify-between mb-6 mr-6'></div>
        </div>
      </Spin>
    </div>
  )
}
