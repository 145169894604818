import { Check, Dots } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Switch from 'components/Switch'
import moment from 'moment'
import type { TCampaignItem } from 'types/campaign'

type TCampaignStatusProps = {
  campaign: TCampaignItem
  statusLoading: boolean
  handleStatusChange: (status: string) => void
}

export default function CampaignStatus({
  campaign,
  statusLoading,
  handleStatusChange,
}: TCampaignStatusProps) {
  const { type, status, scheduled_at, paused_at } = campaign

  const statusName = (status: string) => {
    switch (status) {
      case 'processing':
        return 'Processing'
      case 'paused':
        return 'Paused'
      case 'complete':
        return 'Processed'
      default:
        return status.charAt(0).toUpperCase() + status.slice(1)
    }
  }
  return (
    <div className='bg-field flex justify-between px-6 py-4 rounded-lg'>
      {type === 'automated' ? (
        <>
          <div className='flex items-center text-base font-bold'>
            {status === 'paused' ? (
              <>
                <Dots size={16} className='mr-1 text-orange' />
                Campaign {statusName(status)}
              </>
            ) : (
              <>
                <Check size={16} className='mr-1 text-green' />
                Active & Running
              </>
            )}
          </div>
          <div>
            <Switch
              label={
                status === 'paused'
                  ? `${
                      paused_at ? moment().diff(moment(paused_at), 'day') : '-'
                    } days since last paused`
                  : `${
                      scheduled_at
                        ? moment().diff(moment(scheduled_at), 'day')
                        : '-'
                    } days since last start`
              }
              inputClassName={cn(
                'cursor-pointer',
                status === 'paused' ? 'bg-orange' : 'bg-blue',
              )}
              loading={statusLoading}
              name='automated status'
              checked={status === 'processing'}
              onChange={bool => {
                handleStatusChange(bool ? 'scheduled' : 'paused')
              }}
              position='right'
            />
          </div>
        </>
      ) : (
        <CampaignProcess
          campaign={campaign}
          statusLoading={statusLoading}
          handleStatusChange={handleStatusChange}
          statusName={statusName}
        />
      )}
    </div>
  )
}

const CampaignProcess = ({
  campaign,
  statusName,
  statusLoading,
  handleStatusChange,
}: {
  campaign: TCampaignItem
  statusName: (status: string) => string
  statusLoading: boolean
  handleStatusChange: (status: string) => void
}) => {
  const { status, campaign_performance } = campaign
  const { progress, actual_audience } = campaign_performance || {
    progress: 0,
    actual_audience: 0,
  }
  return (
    <>
      <div className='pb-2.5 flex-1 flex justify-between items-center relative'>
        <div className='text-base text-ink font-bold'>
          Campaign {statusName(status)} •{' '}
          {isNaN(progress / actual_audience)
            ? 0
            : ((progress / actual_audience) * 100).toFixed(0)}
          %
        </div>
        <div className='text-xs text-lead'>
          {progress}/{actual_audience} customer reached
        </div>
        <div className='w-full h-0.5 absolute bottom-0 bg-dark-16' />
        <div
          className={cn(
            'h-0.5 absolute bottom-0 rounded-full',
            {
              'bg-orange': status === 'paused',
            },
            {
              'bg-blue': status === 'processing',
            },
            {
              'bg-green': status === 'complete',
            },
          )}
          style={{
            width: `${((progress / actual_audience) * 100).toFixed(0)}%`,
          }}
        />
      </div>
      {status === 'paused' && (
        <Button
          className='ml-4'
          size='sm'
          onClick={() => handleStatusChange('scheduled')}
          loading={statusLoading}
        >
          RESUME
        </Button>
      )}
    </>
  )
}
