import React from 'react'

export default function UnsplashIcon() {
  return (
    <div>
      <svg
        width={16}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M5 4.5V0H11V4.5H5ZM11 7H16V16H0V7H5V11.5H11V7Z' fill='black' />
      </svg>
    </div>
  )
}
