import { SolidBank } from '@rushable/icon2'
import Field from 'components/Field'
import Tips from 'components/Tips'
import moment from 'moment'

import { TFormData } from './ModelVerifyBankMethod'

type TVerifyBillingAccountProps = {
  errorText: string
  bankUpdating: {
    last4: string
    microDepositType: 'descriptor_code' | 'amounts' | ''
    createdAt: string
  }
  formData: TFormData
  onFormDataChange: (type: string, value: any) => void
}

export default function VerifyBillingAccount({
  errorText,
  bankUpdating,
  formData,
  onFormDataChange,
}: TVerifyBillingAccountProps) {
  const { microDepositType, last4, createdAt } = bankUpdating
  const formatCreatedAt = moment(createdAt).format('M/DD/YYYY')
  const fieldCls = '!pl-11 tracking-widest text-dark-100 text-base font-bold'
  const fieldSuffixCls = 'text-dark-100 text-base font-bold tracking-widest'
  return (
    <div>
      <p className='mb-6'>
        {microDepositType === 'descriptor_code' && (
          <>
            We deposited $0.01 to your bank <strong>••••{last4}</strong> on{' '}
            {formatCreatedAt}. It usually arrives within minutes but may take up
            to two days. To verify this account, enter the 6-digit code from
            that deposit.
          </>
        )}
        {microDepositType === 'amounts' && (
          <>
            We sent two micro-deposits to your bank <strong>••••{last4}</strong>{' '}
            on {formatCreatedAt}. It usually arrives within minutes but may take
            up to two days. To verify this account, confirm the amounts of these
            deposits.
          </>
        )}
      </p>
      <div className='flex justify-center relative'>
        <div className='w-400px p-4 bg-ice border border-zinc rounded-lg'>
          <div className='mb-4 flex items-center'>
            <SolidBank className='text-dark-32 mr-2' size={16} />
            <span className='text-dark-32 text-xs font-medium'>
              •••• {last4} BANK STATEMENT
            </span>
          </div>
          <div className='pb-2 flex border-b border-zinc text-dark-64 text-xs'>
            <div className='px-2 flex-1'>Transaction</div>
            <div className='px-2 w-[89px]'>Type</div>
            <div className='px-2 w-20 text-right'>Amount</div>
          </div>
          {microDepositType === 'descriptor_code' && (
            <div className='mt-2 flex text-xs'>
              <div className='px-2 flex-1 font-bold text-blue'>SMXXXX</div>
              <div className='px-2 w-[89px] text-dark-100'>ACH CREDIT</div>
              <div className='px-2 w-20 text-right font-bold'>$0.01</div>
            </div>
          )}
          {microDepositType === 'amounts' && (
            <>
              <div className='mt-2 flex text-xs'>
                <div className='px-2 flex-1 font-bold text-dark-100'>
                  ACCTVERIFY
                </div>
                <div className='px-2 w-[89px] text-dark-64'>ACH CREDIT</div>
                <div className='px-2 w-20 text-right font-bold text-blue'>
                  $0.XX
                </div>
              </div>
              <div className='mt-2 flex text-xs'>
                <div className='px-2 flex-1 font-bold text-dark-100'>
                  ACCTVERIFY
                </div>
                <div className='px-2 w-[89px] text-dark-64'>ACH CREDIT</div>
                <div className='px-2 w-20 text-right font-bold text-blue'>
                  $0.XX
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='mt-6 flex justify-between space-x-6'>
        {microDepositType === 'descriptor_code' && (
          <Field
            containerClassName='flex-1'
            className={fieldCls}
            label='Enter code'
            name='enter-code'
            type='text'
            placeholder='XXXX'
            maxLength={4}
            suffix={<span className={fieldSuffixCls}>SM</span>}
            value={formData.descriptorCode}
            onChange={e => {
              const value = e.target.value
              if (value.length <= 4) {
                onFormDataChange('descriptorCode', e.target.value)
              }
            }}
          />
        )}
        {microDepositType === 'amounts' && (
          <>
            <Field
              containerClassName='flex-1'
              className={fieldCls}
              label='Deposit 1'
              name='deposit-1'
              type='number'
              placeholder='00'
              maxLength={2}
              suffix={<span className={fieldSuffixCls}>$0.</span>}
              value={formData.amounts1}
              onChange={e => {
                const value = e.target.value
                if (value.length <= 2) {
                  onFormDataChange('amounts1', e.target.value)
                }
              }}
            />
            <Field
              containerClassName='flex-1'
              className={fieldCls}
              label='Deposit 2'
              name='deposit-2'
              type='number'
              placeholder='00'
              maxLength={2}
              suffix={<span className={fieldSuffixCls}>$0.</span>}
              value={formData.amounts2}
              onChange={e => {
                const value = e.target.value
                if (value.length <= 2) {
                  onFormDataChange('amounts2', e.target.value)
                }
              }}
            />
          </>
        )}
      </div>
      {errorText && <Tips className='mt-6'>{`⚠️ ${errorText}`}</Tips>}
    </div>
  )
}
