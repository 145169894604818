import React from 'react'

import {
  Visa,
  ApplePay,
  GooglePay,
  Master,
  Amex,
  Discover,
  SolidGiftCard,
  Other,
} from '@rushable/icons'

import type { TCommonIconProp } from './type'

type TSourceIconRenderProps = {
  type: string | null
  iconParams?: TCommonIconProp
}

export default function PayIconRender({
  type,
  iconParams,
}: TSourceIconRenderProps): JSX.Element {
  if (type?.includes('visa')) {
    return <Visa {...iconParams} />
  } else if (type?.includes('apple')) {
    return <ApplePay {...iconParams} />
  } else if (type?.includes('amex')) {
    return <Amex {...iconParams} />
  } else if (type?.includes('master')) {
    return <Master {...iconParams} />
  } else if (type?.includes('google')) {
    return <GooglePay {...iconParams} />
  } else if (type?.includes('discover')) {
    return <Discover {...iconParams} />
  } else if (type?.includes('gift')) {
    return <SolidGiftCard {...iconParams} />
  } else {
    return <Other {...iconParams} />
  }
}
