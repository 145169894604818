import React, { useState } from 'react'

import { SolidCopy, Check } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import useCopyToClipboard from 'hooks/useCopyToClipboard'

export type TTagProp = {
  className?: string
  content: string // 真实 copy 的内容
  children?: string | React.ReactElement
}

export default function CopyText({
  className,
  content,
  children,
}: TTagProp): JSX.Element {
  const [isCopy, setIsCopy] = useState(false)
  const [, copy] = useCopyToClipboard()

  const copyText = (e: React.MouseEvent) => {
    e.stopPropagation()
    copy(content)
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }
  return (
    <div className={cn('flex', className)}>
      <Button
        color='secondary-link'
        className={cn('relative', children ? 'mr-2.5' : '')}
        onClick={copyText}
      >
        {isCopy ? (
          <>
            <span className='absolute text-blue right-full p-2 z-50 rounded-lg'>
              Copied
            </span>
            <Check className='text-blue' size={16} />
          </>
        ) : (
          <SolidCopy className='text-blue' size={16} />
        )}
      </Button>
      {children}
    </div>
  )
}
