import { LinedStore, LinedCar, LinedUtensils } from '@rushable/icons'
import TextWithIconLeft from 'components/TableAndCell/Cell/TextWithIconLeft'

type TCellOrderTypesProps = {
  className?: string
  type:
    | 'pickup'
    | 'delivery'
    | 'daas_delivery'
    | 'dine-in'
    | 'curbside'
    | 'takeout'
}

export default function CellOrderTypes({
  className,
  type,
}: TCellOrderTypesProps) {
  const typeMap = {
    pickup: {
      icon: <LinedStore size={16} />,
      text: 'Pickup',
    },
    delivery: {
      icon: <LinedCar size={16} />,
      text: 'Delivery',
    },
    daas_delivery: {
      icon: <LinedCar size={16} />,
      text: 'Delivery (OD)',
    },
    'dine-in': {
      icon: <LinedUtensils size={16} />,
      text: 'Dine-in',
    },
    curbside: {
      icon: <LinedStore size={16} />,
      text: 'Curbside',
    },
    takeout: {
      icon: <LinedStore size={16} />,
      text: 'Takeout',
    },
  }
  const { text, icon } = typeMap[type]
  return <TextWithIconLeft className={className} text={text} icon={icon} />
}
