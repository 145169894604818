import React, { useState, useEffect } from 'react'

import { AngleLeft, Dollar } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { SERVER_TIMEZONE } from 'data/constant'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateGiftCard, getTransactions } from 'redux/brand/actions'
import { useAppSelector } from 'redux/hooks'
import { currencyFormatter } from 'utils/digit'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

import GiftCardDetail from './components/GiftCardDetail'

export default function GiftCardPage(): JSX.Element {
  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE
  const { brandId, giftCardId } = useParams<TParamTypes>()
  const [showTopModal, setShowTopModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [giftCardInfo, setGiftCardInfo] = useState<any>({})
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [detailData, setDetailData] = useState({ data: [], total: 0 })
  const [formData, setFormData] = useState({
    amount: '',
    notes: '',
    password: '',
  })

  const onFormChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const getGiftCardDetail = async (pageNow: number, pageSizeNow: number) => {
    setLoading(true)
    try {
      setPage(pageNow)
      setPageSize(pageSizeNow)
      const res = await getTransactions({
        brandId: brandId,
        id: giftCardId,
        page: pageNow,
        per_page: pageSizeNow,
      })
      setGiftCardInfo(res.giftCard)
      setDetailData({
        data: res.transactions.data,
        total: res.transactions.total,
      })
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const getTopUpCard = async () => {
    setConfirming(true)
    try {
      const res = await updateGiftCard({
        brandId,
        giftCardId,
        amount: formData.amount,
        notes: formData.notes,
        password: formData.password,
      })
      if (res?.message) {
        toast.success(res.message)
      }
      closeTopUpCard()
      getGiftCardDetail(page, pageSize)
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setConfirming(false)
  }

  const closeTopUpCard = () => {
    setFormData({ amount: '', notes: '', password: '' })
    setShowTopModal(false)
  }

  useEffect(() => {
    getGiftCardDetail(page, pageSize)
  }, [])

  return (
    <AdminPage>
      <AdminPageTitle
        title={
          giftCardInfo?.recipient &&
          `${
            giftCardInfo?.recipient.split(' ')[0]
          }’s Gift Card •••• ${giftCardInfo?.card_number.split('-').pop()}`
        }
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            Go Back
          </Button>
        }
        right={
          <Button
            color='primary-link'
            onClick={() => {
              setShowTopModal(!showTopModal)
            }}
          >
            TOP UP CARD
          </Button>
        }
      />
      <>
        <div className='py-8 px-8'>
          <GiftCardDetail
            loading={loading}
            data={detailData.data}
            total={detailData.total}
            page={page || 1}
            pageSize={pageSize || 20}
            rightInfo={
              giftCardInfo?.recipient &&
              `${currencyFormatter(giftCardInfo.initial_amount)} activated by ${
                giftCardInfo.recipient
              } on ${parseServerTimeToLocalTime(
                giftCardInfo.created_at,
                timezone,
                'MMMM Do, YYYY',
              )}.`
            }
            onChange={(name, value) => {
              if (name === 'page') {
                getGiftCardDetail(value, pageSize)
              } else {
                getGiftCardDetail(1, value)
              }
            }}
          />
        </div>

        {showTopModal && (
          <ModalFull
            title={'TOP UP THIS CARD'}
            open={showTopModal}
            toggle={() => closeTopUpCard()}
            footer={
              <div className='flex justify-end'>
                <Button
                  className='mr-4'
                  color='secondary'
                  onClick={() => closeTopUpCard()}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!formData.amount || !formData.password}
                  loading={confirming}
                  color='primary'
                  onClick={() => {
                    getTopUpCard()
                  }}
                >
                  CONFIRM
                </Button>
              </div>
            }
          >
            <div>
              <Field
                name='top-up-amount'
                label='Top up amount'
                value={formData.amount}
                placeholder='Input amount'
                inputMode='money'
                suffix={<Dollar size={16} className='text-silver' />}
                onChangeMoney={value => {
                  if (Number(value) > 300) {
                    toast.error('Top-up amount can not exceed the $300 limit')
                    return
                  }
                  onFormChange('amount', value)
                }}
              />
              <Field
                containerClassName='mt-6'
                label={
                  <div className='w-full flex justify-between'>
                    <span>Notes</span>
                    <span className='font-normal'>
                      ({formData.notes?.length || 0}/40 characters)
                    </span>
                  </div>
                }
                type='text'
                name='top_up_notes'
                value={formData.notes}
                placeholder='Input notes'
                onChange={e => {
                  if (e.target.value.length <= 40) {
                    onFormChange('notes', e.target.value)
                  }
                }}
              />
              <Field
                containerClassName='mt-6'
                label='Password'
                type='password'
                name='top_up_password'
                autoComplete='new-password'
                value={formData.password}
                placeholder='Input password'
                onChange={e => {
                  onFormChange('password', e.target.value)
                }}
              />
            </div>
          </ModalFull>
        )}
      </>
    </AdminPage>
  )
}
