import React from 'react'

export type TAdminPageTabsProp = {
  children?: React.ReactElement | React.ReactElement[]
}

export default function AdminPageTabs({
  children,
}: TAdminPageTabsProp): JSX.Element {
  return (
    <div className='sticky left-0 right-0 top-0 z-10 bg-white rounded-t-lg overflow-hidden flex justify-between w-full'>
      {children}
    </div>
  )
}
