import React, { useState } from 'react'

import Button from 'components/Button'
import GetSupportModal from 'components/GetSupportModal'
import ModalFull from 'components/Modal/ModalFull'

import { getModalConstant, getHelpConstant } from '../helps'

type constantType = 'email' | 'dns' | 'verification' | 'phone'

interface Iprops {
  type: constantType
}
export default function GetHelp({ type }: Iprops) {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div className='flex justify-between items-center w-full h-[60px] px-6 border border-zinc rounded-lg bg-ice'>
      <div className='font-bold text-ink'>{getHelpConstant[type]}</div>
      <Button color='tertiary' size='sm' onClick={() => setIsVisible(true)}>
        GET HELP
      </Button>
      <GetSupportModal open={isVisible} toggle={() => setIsVisible(false)} />
    </div>
  )
}

type modalContantType = 'email' | 'phone' | 'changeEntity' | 'contactUs'
interface IModalTypes {
  type: modalContantType
  open: boolean
  toggle: () => void
}
export function ModalHelp({ type, open, toggle }: IModalTypes) {
  return (
    <ModalFull
      open={open}
      toggle={toggle}
      title={getModalConstant[type].modalTitle}
    >
      <div>{getModalConstant[type].modalContent}</div>
    </ModalFull>
  )
}
