import React, { useEffect, useState } from 'react'

import { DuoToneBank } from '@rushable/icons'
import MoneyAccounting from 'components/MoneyAccounting'
import Table from 'components/TableAndCell/Table'
import Badge from 'components/Tag/Badge'
import { PERMISSION } from 'data/permission'
import moment from 'moment'
import { TPayoutSchedule } from 'pages/billingAndInvoice/helpers'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPayoutConfigApi } from 'redux/accountAndPayout'
import { getPayoutList } from 'redux/financial'
import { useAppSelector } from 'redux/hooks'
import { titleCase } from 'utils/textFormat'

import PayoutBankModal from './components/PayoutBankModal'
import PayoutScheduleModal from './components/PayoutScheduleModal'
import DisplayBlock from '../billingAndInvoice/components/DisplayBlock'

type TPayoutInfo = {
  schedule: string
  interval: TPayoutSchedule
  bankName: string
  bankLast4: string
}

export default function PayoutReport(): JSX.Element {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { locationId } = useParams<TParamTypes>()
  const auth = useAppSelector(state => state.auth.authProfile.data)

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(20)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [payoutInfo, setPayoutInfo] = useState<TPayoutInfo>()
  const { selectedLocation } = useAppSelector(state => state.location)
  const payoutConfigId = selectedLocation?.location_payout_config?.id || ''

  const isCanUpdateBank =
    auth?.permissionMap?.[PERMISSION.UPDATEBANKACCOUNT]?.allowed == 1
  const isCanUpdateSchedule =
    auth?.permissionMap?.[PERMISSION.UPDATEPAYOUTSCHEDULE]?.allowed == 1

  useEffect(() => {
    setPerPage(20)
    getFinancial(1)
  }, [locationId])

  const getFinancial = async (page: number, pageSize?: number) => {
    if (pageSize) {
      setPerPage(pageSize)
    }
    try {
      setLoading(true)
      const res = await getPayoutList({
        params: { page, per_page: pageSize || perPage },
        locationId,
      })
      const { data, per_page, current_page, total } = res.data
      setList(data)
      setCurrentPage(current_page)
      setPerPage(per_page)
      setLoading(false)
      setTotalCount(total)
    } catch (e: any) {
      toast.warn(e.message || 'Something went wrong')
      setLoading(false)
    }
  }

  const columns = [
    {
      key: 'stripe_status',
      name: 'STATUS',
      className: 'w-[120px]',
      custom: (value: string) => {
        let statusValue = ''
        let color: any = ''
        switch (value) {
          case null:
            statusValue = 'Current'
            color = 'gray'
            break
          case 'in_transit':
          case 'pending':
            statusValue = 'In Transit'
            color = 'blue'
            break
          case 'paid':
            statusValue = 'Deposited'
            color = 'green'
            break
          case 'failed':
            statusValue = 'Failed'
            color = 'red'
            break
        }
        return (
          <div>
            <Badge color={color}>{statusValue}</Badge>
          </div>
        )
      },
    },
    {
      key: 'stripe_amount',
      name: 'NET PAYOUT',
      className: 'w-[140px]',
      align: 'right',
      custom: (value: any) => {
        return <MoneyAccounting className='w-24' size='sm' value={value} />
      },
    },
    {
      key: 'last_4',
      name: 'BANK ACCOUNT',
      custom: (value: any, row: any) => {
        return (
          <>
            {value ? (
              <div className='flex items-center text-sm'>
                <DuoToneBank className='text-ink mr-2' size={20} />
                <span className='whitespace-nowrap'>•••• {value} </span>
                <span className='ml-1 text-lead whitespace-nowrap overflow-hidden text-ellipsis'>
                  {row.bank_name}
                </span>
              </div>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
    {
      key: 'period',
      name: 'REVENUE PERIOD',
      align: 'right',
      className: 'w-40',
      custom: (value: any, row: any) => {
        const beginTime = moment(row.period_begin_time).format('MM/DD')
        const endTime = moment(row.period_end_time).format('MM/DD')
        const yearEndTime = moment(row.period_end_time).format('MM/DD/YYYY')
        const period = row.period_begin_time
          ? beginTime === endTime
            ? yearEndTime
            : `${beginTime} - ${yearEndTime}`
          : '-'
        return <div>{period}</div>
      },
    },
    {
      key: 'payout_created_at',
      name: 'PAYOUT',
      align: 'right',
      className: 'w-34',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value ? moment(value).format('MM/DD/YYYY') : '-'}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (payoutConfigId) {
      getPayoutInfo(false)
    }
  }, [payoutConfigId])

  const refreshLocations = () => {
    getPayoutInfo(true)
  }

  const getScheduleDesc = (schedule: TPayoutSchedule) => {
    if (schedule === 'stripe_managed') {
      return 'Stripe Managed Payout'
    }
    if (schedule) {
      return `${titleCase(schedule)} Payout`
    }
    return ''
  }

  const getPayoutInfo = async (refresh: boolean) => {
    try {
      const res = await getPayoutConfigApi(
        locationId || '',
        payoutConfigId,
        refresh,
      )

      const info = res
        ? {
            schedule: getScheduleDesc(res?.interval),
            interval: res?.interval,
            bankName: res.bank_name ? `(${res.bank_name})` : '',
            bankLast4: res.bank_last_4 ? `••• ${res.bank_last_4}` : '',
          }
        : undefined
      setPayoutInfo(info)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
  }

  return (
    <div>
      <div className='flex'>
        <DisplayBlock
          className='mr-8'
          title='Bank deposit'
          iconType='BankAccountIcon'
          mainTxt={payoutInfo?.bankLast4}
          secondaryTxt={payoutInfo?.bankName}
          descTxt='This is your checking account to receive your payout'
          button={
            <PayoutBankModal
              disabled={!isCanUpdateBank}
              onSuccess={refreshLocations}
            />
          }
        />
        <DisplayBlock
          title='Payout schedule'
          iconType='PayoutScheduleIcon'
          mainTxt={payoutInfo?.schedule || ''}
          descTxt='This is the frequency of your payout schedule.'
          button={
            <PayoutScheduleModal
              disabled={
                payoutInfo?.interval === 'stripe_managed' ||
                !isCanUpdateSchedule
              }
              currentInterval={payoutInfo?.interval || 'weekly'}
              onSuccess={refreshLocations}
              payoutConfigId={payoutConfigId}
            />
          }
        />
      </div>
      <div className='mt-6'>
        <Table
          bodyClassName=''
          loading={loading}
          isPageSet={true}
          current={currentPage}
          trClassName={'bg-white'}
          containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-x-scroll'
          columns={columns}
          data={list}
          total={totalCount}
          totalText={'results'}
          onPageNoChange={value => getFinancial(value)}
          onPageSizeChange={value => getFinancial(1, value)}
          clickedRow={(row: any) =>
            history.push({
              pathname: `${url}/${row.id}`,
            })
          }
        />
      </div>
    </div>
  )
}
