import React from 'react'

type TOnlineMenuSkeletonProp = {
  type: number
}

export default function OnlineMenuSkeleton({
  type,
}: TOnlineMenuSkeletonProp): JSX.Element {
  return (
    <>
      {type === 0 && (
        <div className='w-full bg-slate rounded-lg h-6 animate-pulse pl-4 mt-4 flex items-center'>
          <div className='bg-zinc rounded h-3 w-3 '></div>
          <div className='bg-zinc rounded w-[168px] h-3 ml-3'></div>
        </div>
      )}
      {type === 1 && (
        <div className='w-full ml-4 bg-slate rounded-lg h-16 animate-pulse mt-4 flex items-center'>
          <div className='bg-zinc rounded h-3 w-3 ml-2'></div>
          <div className='bg-zinc rounded w-12 h-10 ml-3'></div>
          <div className='bg-zinc rounded w-[248px] h-4 ml-3'></div>
        </div>
      )}
      {type === 3 && (
        <div className='w-full bg-slate rounded-lg h-10 animate-pulse mt-4 flex items-center'>
          <div className='bg-zinc rounded h-3 w-3 ml-3'></div>
          <div className='bg-zinc rounded w-[300px] h-4 ml-2'></div>
          <div className='bg-zinc rounded w-[74px] h-2 ml-auto mr-8'></div>
        </div>
      )}
    </>
  )
}
