import React from 'react'

export default function AccessSkeleton(): JSX.Element {
  return (
    <div className='w-[484px]'>
      <div className='w-[100px] h-4 bg-slate animate-pulse'></div>
      <div className=' bg-slate rounded-lg my-4 h-18 animate-pulse p-4 mt-4'>
        <div className='bg-zinc rounded-lg w-1/3 h-2 mb-2 '></div>
        <div className='bg-zinc rounded-lg w-2/3 h-2'></div>
      </div>
    </div>
  )
}
