import React, { useState } from 'react'

import cn from 'classnames'
import Button from 'components/Button'

type Tprops = {
  className?: string
  url: string
  onChange: () => void
}

export default function GalleryImage({ className, url, onChange }: Tprops) {
  const [maskShow, setMaskShow] = useState<boolean>(false)
  return (
    <div className={cn('rounded-lg relative', className)}>
      <img className='w-full h-full object-cover rounded' src={url} alt='' />
      <div
        className={cn(
          'absolute top-0 left-0 w-full h-full z-30 flex justify-center items-center transition duration-300 ease-in-out',
          { 'bg-opacity-96': maskShow },
          { 'opacity-0': !maskShow },
        )}
        onMouseOver={() => setMaskShow(true)}
        onMouseOut={() => setMaskShow(false)}
      >
        <div className='flex justify-center item-center'>
          <Button size='sm' onClick={onChange}>
            MAKE CHANGE
          </Button>
        </div>
      </div>
    </div>
  )
}
