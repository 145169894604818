import React from 'react'

import { Check } from '@rushable/icons'
import cn from 'classnames'

export type TTimelineNodeProp = {
  label: string | React.ReactElement
  title: string
  index: number
  current: number
  total: number
  isEqual?: boolean
}

export default function TimelineNode({
  label,
  title,
  index,
  current,
  total,
  isEqual,
}: TTimelineNodeProp): JSX.Element {
  return (
    <li className={`mb-0 flex relative ${isEqual && 'flex-1'}`}>
      <div
        className={`flex flex-col justify-center items-center ${
          isEqual && 'flex-1'
        }`}
      >
        <div
          className={cn(
            'flex z-10 justify-center items-center w-6 h-6 rounded-full ring-4 ring-white border shrink-0 text-xs font-bold',
            { 'bg-blue text-white  border-blue': index <= current },
            { 'bg-white text-ink  border-ink': index > current },
          )}
        >
          {index >= current ? (
            label
          ) : (
            <Check className='text-white' size={14} />
          )}
        </div>
        <div className='mt-2'>
          <h3
            className={cn(
              'text-xs whitespace-nowrap',
              index === current ? 'text-dark' : 'text-dark-32',
            )}
          >
            {title}
          </h3>
        </div>
      </div>
      {index === 0 && (
        <div className='absolute left-0 w-1/2 top-0 h-7 bg-white'></div>
      )}
      {index === total - 1 && (
        <div className='absolute right-0 w-1/2 top-0 h-7 bg-white'></div>
      )}
    </li>
  )
}
