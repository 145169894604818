import { useState } from 'react'

import Button from 'components/Button'
import { Select } from 'components/Select'
import { TCoupon } from 'redux/marketingFlyer'
import type { TCampaignItem, TCampaignCoupon } from 'types/campaign'

import CouponCodeForm from './CouponCodeForm'
import CouponSelectDrawer from './CouponSelectDrawer'
import HowWorksDrawer from './HowWorksDrawer'
import LuckySpinForm from './LuckySpinForm'
import { OFFER_ITEMS } from '../../helpers/constant'
import Line from '../Line'

type TContentForm = {
  stepLoading: boolean
  prevStep: () => void
  createOrUpdate: () => void
  formData: TCampaignItem
  viewOnly?: boolean
  handleFormDataChange: (type: string, value: any) => void
}

export default function AttachOfferForm({
  stepLoading,
  prevStep,
  formData,
  createOrUpdate,
  viewOnly,
  handleFormDataChange,
}: TContentForm): JSX.Element {
  const { campaign_offer } = formData
  let type = ''
  let coupons: TCampaignCoupon[] = []
  const validDays = campaign_offer?.valid_days
  if (campaign_offer) {
    type = campaign_offer?.type
    coupons = campaign_offer?.coupons || []
  }
  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const handleConfirm = (item: TCoupon) => {
    if (item) {
      setOpenDrawer(false)
      handleFormDataChange('coupon_id', {
        id: item.id,
        code: item.code,
      })
    }
  }

  return (
    <div className='mt-8'>
      <div className='text-base text-ink font-bold pb-2'>
        Attach a promo offer?
      </div>
      <div className='flex'>
        <Select
          className='flex-1'
          options={OFFER_ITEMS}
          placeholder='Select A Promo Offer'
          value={type}
          disabled={viewOnly}
          onChange={newType => {
            handleFormDataChange('campaign_offer_type', newType)
          }}
        />
        {type === 'lucky_spin' ? (
          <Button
            color='tertiary'
            className='ml-4'
            onClick={() => setOpen(true)}
          >
            HOW IT WORKS ?
          </Button>
        ) : null}
      </div>
      {type === 'lucky_spin' ? (
        <LuckySpinForm
          coupons={coupons}
          viewOnly={viewOnly}
          validDays={validDays}
          handleFormDataChange={handleFormDataChange}
        />
      ) : null}

      {type === 'coupon_code' ? (
        <CouponCodeForm coupons={coupons} setOpenDrawer={setOpenDrawer} />
      ) : null}

      <Line className='my-6' />
      <div className='flex justify-between'>
        <Button color='secondary' className='mr-3 w-full' onClick={prevStep}>
          PREVIOUS STEP
        </Button>
        <Button
          color='primary'
          className='w-full'
          onClick={() => createOrUpdate()}
          loading={stepLoading}
        >
          NEXT STEP
        </Button>
      </div>
      <HowWorksDrawer open={open} toggle={() => setOpen(!open)} />
      <CouponSelectDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        couponId={coupons?.[0]?.id}
        handleConfirm={handleConfirm}
      />
    </div>
  )
}
