import { useState, useEffect } from 'react'

import { Loading } from '@rushable/icons'
import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLocationCheckMateConfig, bindCheckMateApi } from 'redux/integration'

import type { TConnectModal } from '../SelectRestaurant'

type TAddLocationModal = {
  connectModal: TConnectModal
  setConnectModal: (v: TConnectModal) => void
}

export default function IACMConnectModal({
  connectModal,
  setConnectModal,
}: TAddLocationModal) {
  const history = useHistory()
  const [modalLoading, setModalLoading] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [checkmateData, setCheckmateData] = useState<{
    created_at: string
    external_address: string
    external_brand: string
    external_city: string
    external_location_id: string
    external_name: string
    external_phone: null | string
    external_state: string
    external_url: string
    location: null | any
    token_expires_at: string
    updated_at: string
  }>()
  const locationCheckMateConfig = async () => {
    try {
      setModalLoading(true)
      const data = await getLocationCheckMateConfig(connectModal.config_id)
      if (data) {
        setCheckmateData(data)
      }
      setModalLoading(false)
    } catch (error: any) {
      if (error.message) {
        toast.warn(error.message)
      }
      setModalLoading(false)
    }
  }

  const handleConnect = async () => {
    try {
      setLoading(true)
      await bindCheckMateApi(connectModal?.location?.id, connectModal.config_id)
      history.push(
        `/brand/${connectModal.brand_id}/location/${connectModal?.location?.id}/integration`,
      )
      setLoading(false)
    } catch (error: any) {
      if (error.message) {
        toast.warn(error.message)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (connectModal.open) {
      locationCheckMateConfig()
    }
  }, [connectModal.open])

  const address = connectModal?.location?.address
  return (
    <ModalFull
      open={connectModal.open}
      loading={modalLoading}
      title='Connect to ItsaCheckmate'
      toggle={() =>
        setConnectModal({
          ...connectModal,
          open: !connectModal.open,
          location: null,
        })
      }
      footer={
        <div className='flex justify-end'>
          <Button
            color='secondary'
            className='mr-4'
            onClick={() =>
              setConnectModal({
                ...connectModal,
                open: !connectModal.open,
                location: null,
              })
            }
            type='button'
          >
            CLOSE
          </Button>
          <Button
            color='primary'
            onClick={handleConnect}
            disabled={modalLoading || loading}
            loading={loading}
          >
            CONNECT
          </Button>
        </div>
      }
    >
      <div>
        {modalLoading ? (
          <div className='flex justify-center items-center'>
            <Loading className='mr-2' />
            Loading ...
          </div>
        ) : (
          <div>
            <div>
              Please confirm that the location in{' '}
              <span className='text-blue font-bold'>
                {connectModal?.location?.name}
              </span>{' '}
              matches the location that you are trying to connect to{' '}
              <span className='text-blue font-bold'>ItsaCheckmate</span>.
            </div>
            <div className='mt-4'>If they match, click Connect.</div>
            <div className='mt-4'>
              If they do not match click Close and verify you are using the
              correct login credentials.
            </div>
            <div className='mt-4'>
              <div>
                <span className='text-blue font-bold text-xl'>
                  {connectModal?.location?.name} Account
                </span>
              </div>
              <div>Name: {connectModal?.location?.name}</div>
              <div>
                Address:{' '}
                {`${address?.line_1 ? address.line_1 + ' ' : ''}${
                  address?.line_2 ? address.line_2 + ' ' : ''
                }${address?.city ? address.city + ' ' : ''}${
                  address?.state ? ',' + address.state + ' ' : ''
                }${address?.zipcode ? address.zipcode : ''}`}
              </div>
            </div>
            <div className='mt-4'>
              <div>
                <span className='text-blue font-bold text-xl'>
                  ItsaCheckmate Location
                </span>
              </div>
              <div>Id: {checkmateData?.external_location_id}</div>
              <div>Name: {checkmateData?.external_name}</div>
              <div>Address: {checkmateData?.external_address}</div>
              <div>City: {checkmateData?.external_city}</div>
              <div>State: {checkmateData?.external_state}</div>
            </div>
          </div>
        )}
      </div>
    </ModalFull>
  )
}
