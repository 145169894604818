import { useState } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { sendTestEmail } from 'redux/campaign'
import { useAppDispatch } from 'redux/hooks'

type TSendTestEmailModalProps = {
  id: number | null
}

export default function SendTestEmail({ id }: TSendTestEmailModalProps) {
  const { locationId } = useParams<{ locationId: string }>()
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState(false)
  const [testEmail, setTestEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const sendEmail = async () => {
    try {
      setLoading(true)
      setError('')
      const res = await dispatch(
        sendTestEmail(id as number, testEmail, locationId),
      )
      if (res) {
        setVisible(false)
        toast.success('Send Success')
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error.message) {
        setError(error.message)
      }
    }
  }
  return (
    <>
      <Button
        color='tertiary'
        className='w-full'
        onClick={() => setVisible(true)}
      >
        SEND A TEST EMAIL
      </Button>
      <ModalFull
        open={visible}
        title='Send A Test Email'
        toggle={() => setVisible(!visible)}
        okText='SEND NOW'
        onCancel={() => setVisible(false)}
        loading={loading}
        onOk={sendEmail}
      >
        <Field
          label='Send test email to'
          name='email'
          value={testEmail}
          onChange={e => setTestEmail(e.target.value)}
          placeholder='Input email address'
          type='email'
          error={error}
        />
      </ModalFull>
    </>
  )
}
