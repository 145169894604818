import { useState, useEffect } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import useSearch from 'hooks/useSearch'
import { setUpAutoCampaign, getUpAutoCampaign } from 'redux/campaign/actions'
import { useAppDispatch } from 'redux/hooks'

export default function SetUpAutoCampaign() {
  const { ubid } = useSearch()
  const dispatch = useAppDispatch()
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [formData, setFormData] = useState({
    restaurant: '',
    note: '',
  })

  useEffect(() => {
    // 查找已有的 <meta name="viewport"> 标签
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    const newContent =
      'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, viewport-fit=cover'
    // 如果存在，则修改其 content 属性
    if (viewportMeta) {
      viewportMeta.setAttribute('content', newContent)
    } else {
      // 如果不存在，则创建一个新的 <meta> 标签并添加到 <head> 中
      const newViewportMeta = document.createElement('meta')
      newViewportMeta.name = 'viewport'
      newViewportMeta.content = newContent
      document.head.appendChild(newViewportMeta)
    }
    handleGetData()
  }, [])

  const handleGetData = async () => {
    try {
      setLoading(true)
      setErrorMsg('')
      const res = await dispatch(getUpAutoCampaign(ubid))
      if (res) {
        setFormData({
          restaurant: res?.brand?.name || '',
          note: '',
        })
      }
    } catch (e: any) {
      setErrorMsg(e.message || 'Request error')
    } finally {
      setLoading(false)
    }
  }

  const handleFormDataChange = (type: string, value: string) => {
    setFormData({
      ...formData,
      [type]: value,
    })
    if (disabled) {
      setDisabled(false)
    }
  }

  const handleClick = async () => {
    try {
      setLoading(true)
      setErrorMsg('')
      setSuccessMsg('')
      const res = await dispatch(
        setUpAutoCampaign({
          id: ubid,
          note: formData.note,
        }),
      )
      setDisabled(true)
      if (res.message) {
        setSuccessMsg(res.message)
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setErrorMsg(msg)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className='flex justify-center'>
      <div className='max-w-full flex flex-col  sm:max-w-560px'>
        <div className='mx-auto p-6 bg-white shadow-lg rounded-lg'>
          {errorMsg && (
            <p className='bg-red text-white text-base mb-4 p-4 rounded-sm text-center'>
              {errorMsg}
            </p>
          )}
          {successMsg && (
            <p className='bg-green text-white text-base mb-4 p-4 rounded-sm text-center'>
              {successMsg}
            </p>
          )}
          <h1 className='text-3xl text-ink font-bold text-center mb-4'>
            Campaign Activation
          </h1>
          <p className='text-ink text-base mb-4'>
            We're working on your campaign case!
          </p>
          <p className='text-ink text-base mb-4'>
            Meanwhile, please confirm the following information, one of our
            Customer Success specialists will get in touch with you soon to
            finalize and launch the campaigns!
          </p>
          <Field
            label='Restaurant'
            name='Restaurant'
            type='text'
            disabled
            placeholder='Input your restaurant'
            value={formData.restaurant}
            onChange={e => {
              handleFormDataChange('restaurant', e.target.value)
            }}
          />
          <Field
            containerClassName='mt-4'
            label='Note'
            name='note'
            type='text'
            placeholder='Input note'
            value={formData.note}
            onChange={e => {
              handleFormDataChange('note', e.target.value)
            }}
          />
        </div>
        <div className='mt-5 mb-10 px-5 sm:px-0'>
          <Button
            className='w-full'
            loading={loading}
            disabled={disabled}
            onClick={handleClick}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
