import { createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import type { TCampaignPage } from 'types/campaign'

import { getCampaigns } from './actions'

type TCouponState = {
  getCampaignsRequest: boolean
  campaigns: TCampaignPage
}

const initialState: TCouponState = {
  getCampaignsRequest: false,
  campaigns: {
    data: [],
  },
}

export const campaignReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCampaigns.pending, state => {
      state.getCampaignsRequest = true
    })
    .addCase(getCampaigns.fulfilled, (state, action) => {
      state.getCampaignsRequest = false
      state.campaigns = action.payload
    })
    .addCase(getCampaigns.rejected, (state, action) => {
      state.getCampaignsRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
})

export default campaignReducer
