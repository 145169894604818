import React, { useState } from 'react'

import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import { Select } from 'components/Select'

import MenuModifierOptionItem from './MenuModifierOptionItem'
import {
  SELECTIONS_LIST,
  SELECTIONS_LIST_MAX,
  REPEAT_ALLOW,
} from '../helpers/constant'

type TMenuModifierDetailProp = {
  formData: any
  loading?: boolean
  onChange: (name: string, value: any) => void
}

export default function MenuModifierDetail({
  formData,
  loading,
  onChange,
}: TMenuModifierDetailProp): JSX.Element {
  const [openIndex, setOpenIndex] = useState<number>()
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(mouseSensor)

  function onDragEnd(props: any) {
    const { active, over } = props
    if (!active || !over) {
      return null
    }
    const list = formData.menu_modifier_options || []
    const activeIndex = list.findIndex((item: any) => item.id === active.id)
    const overIndex = list.findIndex((item: any) => item.id === over.id)
    const modifierOptions = arrayMove(list, activeIndex, overIndex)
    onChange('menu_modifier_options', modifierOptions)
  }

  const handleFormChange = (
    name: string,
    value: any,
    item?: string,
    index?: number,
  ) => {
    if (name !== 'menu_modifier_options') {
      onChange(name, value)
    } else {
      const modifierOptions = [...formData[name]]
      if (modifierOptions.length && item) {
        modifierOptions[index || 0] = {
          ...modifierOptions[index || 0],
          [item]: value,
        }
      }
      onChange(name, modifierOptions)
    }
  }

  const addOption = () => {
    let id = 0
    formData.menu_modifier_options.map((v: any) => {
      if (String(v?.id).length < 8 && v?.id > id) {
        id = v.id
      }
    })
    const item = {
      name: '',
      unit_price: '',
      status: 'active',
    }
    const optionList = [
      ...formData['menu_modifier_options'],
      { ...item, id: id + 1 },
    ]
    onChange('menu_modifier_options', optionList)
  }

  const handleMenuAction = (name: string, index: number) => {
    switch (name) {
      case 'open':
        setOpenIndex(openIndex === index ? -1 : index)
        break
      case 'delete':
        setOpenIndex(-1)
        onChange(
          'menu_modifier_options',
          formData['menu_modifier_options'].filter(
            (_: any, i: number) => i !== index,
          ),
        )
        break
    }
  }

  return (
    <>
      <div className='grid grid-cols-2 gap-6'>
        <Field
          label='Modifier name'
          name='name'
          placeholder='e.g. Choose your side'
          type='text'
          value={formData.name || ''}
          containerClassName='w-full'
          onChange={e => handleFormChange('name', e.target.value)}
        />
        <Field
          label='Modifier note'
          name='note'
          placeholder='e.g for drink items'
          type='text'
          value={formData.note || ''}
          containerClassName='w-full'
          onChange={e => handleFormChange('note', e.target.value)}
        />
      </div>
      <div className='grid grid-cols-4 gap-6 mt-6'>
        <Select
          options={SELECTIONS_LIST}
          placeholder='Select'
          className='w-full'
          label='Min selection'
          value={String(formData.qty_min)}
          onChange={value => handleFormChange('qty_min', Number(value))}
        />
        <Select
          options={SELECTIONS_LIST_MAX}
          placeholder='Select'
          className='w-full'
          label='Max selection'
          value={String(formData.qty_max)}
          onChange={value => handleFormChange('qty_max', Number(value))}
        />
        <Select
          options={SELECTIONS_LIST}
          placeholder='Select'
          className='w-full'
          label='Free selection'
          value={String(formData.qty_free)}
          onChange={value => handleFormChange('qty_free', Number(value))}
        />
        <Select
          options={REPEAT_ALLOW}
          placeholder='No'
          className='w-full'
          label='Allow repeat'
          value={formData.allow_repeat}
          onChange={value => handleFormChange('allow_repeat', value)}
        />
      </div>
      <div className='grid gap-2 mt-6'>
        <Label>Options</Label>
        <DndContext
          sensors={sensors}
          onDragEnd={onDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
          <SortableContext
            disabled={loading}
            items={formData.menu_modifier_options || []}
          >
            {formData.menu_modifier_options.map((item: any, index: number) => (
              <MenuModifierOptionItem
                key={index}
                hideAction={formData.menu_modifier_options.length > 1}
                index={index}
                openIndex={openIndex}
                item={item}
                handleFormChange={(name, value, item, index) =>
                  handleFormChange(name, value, item, index)
                }
                handleMenuAction={(v, index) => handleMenuAction(v, index)}
                setOpenIndex={v => setOpenIndex(v)}
              />
            ))}
          </SortableContext>
        </DndContext>

        <Button color='tertiary' className='mt-2' onClick={() => addOption()}>
          ADD OPTION
        </Button>
      </div>
    </>
  )
}
