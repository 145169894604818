import React from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'

import type { TForgotData } from '../LoginPage'
export type TForgotPasswordProp = {
  forgotData: TForgotData
  setForgotData: (v: TForgotData) => void
  handleFormChange: (name: string, value: string) => void
  handleForgotPassword: () => void
  email: string
  request: boolean
  error: string
}
export default function ForgotPasswordModal({
  forgotData,
  setForgotData,
  handleFormChange,
  handleForgotPassword,
  email,
  request,
  error,
}: TForgotPasswordProp): JSX.Element {
  const footer = forgotData.success ? (
    <div className='flex justify-end'>
      <Button
        color='primary'
        onClick={() => {
          setForgotData({ ...forgotData, open: false })
        }}
      >
        BACK TO LOGIN
      </Button>
    </div>
  ) : (
    <div className='flex justify-end'>
      <Button
        color='secondary'
        className='mr-4'
        onClick={() =>
          setForgotData({
            open: false,
            success: false,
            loading: false,
          })
        }
      >
        CANCEL
      </Button>
      <Button color='primary' onClick={handleForgotPassword} loading={request}>
        CONFIRM
      </Button>
    </div>
  )

  return (
    <ModalFull
      title='Reset Account Password'
      open={forgotData.open}
      toggle={() =>
        setForgotData({
          open: !forgotData.open,
          success: false,
          loading: false,
        })
      }
      footer={footer}
    >
      {forgotData.success ? (
        <div className='mt-6'>
          <div className='text-base leading-6 text-center pb-4 text-ink'>
            We have sent a password reset instruction to the email below:
          </div>
          <div className='text-base leading-6 text-center text-ink'>
            {email}
          </div>
        </div>
      ) : (
        <div className='mt-6'>
          <div className='text-base leading-6 text-center pb-6 text-ink'>
            Please input your account email below to reset your password
          </div>
          <Field
            label='Your email'
            name='email'
            placeholder='Input your email'
            type='email'
            className='w-full'
            onChange={e => handleFormChange('email', e.target.value)}
            value={email}
            error={error || ''}
          />
        </div>
      )}
    </ModalFull>
  )
}
