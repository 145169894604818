import React from 'react'

import { Loading } from '@rushable/icons'
import cn from 'classnames'

export type TButtonProp = {
  children: React.ReactNode | React.ReactNode[]
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  color?: TButtonColorType
  size?: 'sm' | 'md'
  inline?: boolean
  disabled?: boolean
  className?: string
  type?: 'button' | 'submit'
  loading?: boolean
  href?: string
  target?: string
}

export default function Button({
  children,
  color = 'primary',
  size = 'md',
  disabled = false,
  className = '',
  onClick,
  type,
  loading = false,
  inline = false,
  href,
  target = '_self',
  ...args
}: TButtonProp): JSX.Element {
  const colorClsMap = {
    primary:
      'border-2 border-blue bg-blue text-white hover:bg-blue-darken hover:border-blue-darken',
    secondary:
      'border-2 border-slate bg-slate text-ink hover:bg-zinc hover:border-zinc',
    tertiary: 'border-2 border-zinc bg-transparent hover:border-ink',
    warning:
      'border-2 border-red bg-red text-white hover:bg-red-darken hover:border-red-darken',
    success: 'border-2 border-green bg-green text-white',
    'primary-link': 'text-blue !p-0 !bg-transparent !border-transparent',
    'secondary-link': 'text-silver !p-0 !bg-transparent !border-transparent',
    'ink-link': 'text-ink !p-0 !bg-transparent !border-transparent',
  }

  const sizeCls = {
    sm: 'text-xs py-2 px-4',
    md: 'text-base py-3 px-6',
  }

  const loadingSize = size === 'sm' ? 14 : 20

  const buttonCls = cn(
    'flex justify-center items-center font-bold rounded-lg',
    colorClsMap[color],
    sizeCls[size],
    inline ? 'inline-flex' : 'flex',
    disabled ? 'disabled:border-ice disabled:bg-ice disabled:text-dark-16' : '',
    { 'pointer-events-none': loading || disabled },
    className,
  )
  if (href) {
    return (
      <a
        className={buttonCls}
        href={href}
        target={target}
        type={type}
        {...args}
      >
        {loading && <Loading className='mr-2' size={loadingSize} />}
        {children}
      </a>
    )
  }
  return (
    <button
      onClick={onClick}
      className={buttonCls}
      disabled={disabled}
      type={type}
      {...args}
    >
      {loading && <Loading className='mr-2' size={loadingSize} />}
      {children}
    </button>
  )
}
