import { Plus, BadgeBronze, BadgeSilver, BadgeGold } from '@rushable/icons'
import cn from 'classnames'

export type TTagProp = {
  className?: string
  type?: 'add' | 'default'
  rank?: string
  disabled?: boolean
  title?: string
  pts?: number
  image_url?: string
  redeemed?: number
  onAddClick?: () => void
}

export default function RewardItemCard({
  className,
  type = 'default',
  disabled = false,
  rank,
  title,
  pts,
  image_url,
  redeemed,
  onAddClick,
}: TTagProp): JSX.Element {
  return (
    <div
      className={cn(
        'h-[104px]',
        type === 'add' ? 'bg-white' : '',
        disabled ? 'opacity-32' : '',
        className,
      )}
    >
      {type === 'add' ? (
        <div
          className={
            'w-full h-full bg-white cursor-pointer flex justify-center items-center border border-dashed border-zinc rounded-lg text-dark-100 text-base'
          }
          onClick={onAddClick}
        >
          <div className='w-5 h-5 mr-2 bg-blue flex justify-center items-center rounded-full '>
            <Plus size={14} color='white' />
          </div>
          Add reward option
        </div>
      ) : (
        <div
          className={`w-full h-full p-4 border border-zinc ${
            !disabled && 'hover:border-blue'
          }  rounded-lg flex justify-between space-x-4 items-center cursor-pointer`}
          onClick={onAddClick}
        >
          <div className='grid'>
            <div className='text-base font-bold text-dark-100 text-ellipsis'>
              {title}
            </div>
            <div className='text-base py-1 flex items-center text-dark-100'>
              {rank === 'bronze' && <BadgeBronze className='mr-1' size={20} />}
              {rank === 'silver' && <BadgeSilver className='mr-1' size={20} />}
              {rank === 'gold' && <BadgeGold className='mr-1' size={20} />}
              {pts} pts
            </div>
            {typeof redeemed !== 'undefined' && (
              <div className='text-sm text-dark-64'>
                {redeemed || 0} redeemed
              </div>
            )}
          </div>
          {image_url && (
            <img
              src={image_url}
              className='min-w-[72px] w-[72px] h-[72px] object-cover rounded'
            />
          )}
        </div>
      )}
    </div>
  )
}
