import React from 'react'

import cn from 'classnames'
import { TLocation } from 'types/location'

export type TLocationSelectProp = {
  location: TLocation | Pick<TLocation, 'id' | 'name' | 'address' | 'access'>
  assigned: boolean
  onClick: () => void
}

export default function LocationSelect({
  location,
  assigned,
  onClick,
}: TLocationSelectProp): JSX.Element {
  return (
    <div
      className={cn(
        'option-box-class',
        assigned ? 'selected' : '',
        location.access ? '' : 'disabled',
      )}
      onClick={onClick}
    >
      <h4 className='font-bold text-base'>{location.name}</h4>
      <div className='text-sm mt-1'>{location.address?.line_1}</div>
    </div>
  )
}
