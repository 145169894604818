import React, { useEffect, useState } from 'react'

import { Dollar } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import { Spin } from 'components/Loading'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import { DAYS } from 'data/constant'
import moment from 'moment'
import {
  BUSINESS_HOURS_OPTIONS,
  LAST_CALL_SCHEDULE_OPTIONS,
} from 'pages/orderSetting/helpers/constant'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getRegularApi } from 'redux/business/actions'
import { returnHoursInDays } from 'utils/hours'

export type TBaseFormDataProp = {
  order_min?: string | number
  availability?: 'same_as_store' | 'customize'
  prior_to_close?: number | null
}
export type TBaseOrderTypeConfigProp = {
  label?: string
  formData: TBaseFormDataProp
  onFormChange: (name: string, value: any) => void
}

export default function BaseOrderTypeConfig({
  formData,
  onFormChange,
}: TBaseOrderTypeConfigProp): JSX.Element {
  const { locationId = '' } = useParams<TParamTypes>()
  const [openHours, setOpenHours] = useState(false)
  const { order_min, availability, prior_to_close } = formData
  const [openHoursDay, setOpenHoursDay] = useState<Record<string, any>>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (openHours) {
      getRegularHours()
    }
  }, [openHours])

  const getRegularHours = async () => {
    setLoading(true)
    try {
      const res = await getRegularApi(locationId || '')
      let days: any = returnHoursInDays([])
      res.map((v: { day: string | number }) => {
        days = { ...days, [v.day]: [...days[v.day], v] }
      })
      setOpenHoursDay({ ...days })
    } catch (e: any) {
      toast.error(e?.message || 'request error')
    }
    setLoading(false)
  }

  return (
    <>
      <div className='grid grid-cols-3 gap-8 justify-between'>
        <Field
          type='text'
          inputMode='money'
          name='order_min'
          value={order_min}
          label='Order minimum'
          onChangeMoney={value => onFormChange('order_min', value)}
          suffix={<Dollar size={16} className='text-silver' />}
          containerClassName='w-full'
          align='right'
        />
        <Select
          options={BUSINESS_HOURS_OPTIONS}
          placeholder='Select'
          value={availability}
          className='w-full'
          label='Order schedule'
          onChange={value => onFormChange('availability', value)}
        />
        {availability === 'customize' ? (
          <div className='w-full flex flex-col'>
            <Label>Original business hours</Label>
            <Button
              color='tertiary'
              className='w-full h-10 mt-2'
              onClick={() => setOpenHours(true)}
            >
              VIEW HOURS
            </Button>
          </div>
        ) : (
          <Select
            options={LAST_CALL_SCHEDULE_OPTIONS}
            placeholder='Select'
            value={prior_to_close}
            className='w-full'
            label='Last call schedule'
            onChange={value => onFormChange('prior_to_close', value)}
          />
        )}
      </div>
      <ModalFull
        open={openHours}
        toggle={() => setOpenHours(!openHours)}
        title='Regular Business Hours'
        footer={
          <div className='flex justify-end w-full'>
            <Button
              color='primary'
              className='w-[120px]'
              onClick={() => {
                setOpenHours(!openHours)
              }}
            >
              CLOSE
            </Button>
          </div>
        }
      >
        <Spin spining={loading}>
          <div className='mx-auto  w-[400px] rounded-lg  flex flex-col   divide-y divide-dashed divide-zinc leading-none'>
            {DAYS.map(day => (
              <div className='px-4 py-4 flex justify-between' key={day.label}>
                <div className='font-bold w-24 text-ink'>{day.label}</div>
                {openHoursDay?.[day.abbr]?.length ? (
                  <div className='grid gap-2 w-[169px]'>
                    {openHoursDay[day.abbr].map(
                      (
                        item: {
                          from: moment.MomentInput
                          to: moment.MomentInput
                        },
                        i: number,
                      ) => {
                        return (
                          <div className='text-ink flex' key={i}>
                            <span className=''>
                              {item.from
                                ? moment(item.from, 'hh:mm:ss').format(
                                    'hh:mm A',
                                  )
                                : ''}
                            </span>

                            <span
                              className={'text-silver text-center mx-2 flex-1'}
                            >
                              to
                            </span>
                            <span className=''>
                              {item.to
                                ? moment(item.to, 'hh:mm:ss').format('hh:mm A')
                                : ''}
                            </span>
                          </div>
                        )
                      },
                    )}
                  </div>
                ) : (
                  <div className='text-silver text-center w-[169px]'>
                    CLOSED
                  </div>
                )}
              </div>
            ))}
          </div>
        </Spin>
      </ModalFull>
    </>
  )
}
