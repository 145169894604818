import React from 'react'

import { ArrowRight, SolidEmail, SolidCellphone } from '@rushable/icons'
import Button from 'components/Button'
export type TLoginMethodProp = {
  setMethod: (v: string) => void
}
export default function LoginMethod({
  setMethod,
}: TLoginMethodProp): JSX.Element {
  return (
    <div>
      <div className='w-400px mx-auto my-6'>
        <Button
          color='secondary'
          className='w-full mb-4'
          onClick={() => setMethod('email')}
        >
          <SolidEmail size={16} className='text-ink mr-2' /> Sign in with email
        </Button>
        <Button
          color='secondary'
          className='w-full'
          onClick={() => setMethod('phone')}
        >
          <SolidCellphone size={16} className='text-ink mr-2' /> Sign in with
          cellphone
        </Button>
      </div>
      <div className='text-center text-base text-lead leading-6'>
        Don’t have an account?
        <br />
        Get in touch with us to get started.
      </div>
      <div className='mt-2 flex flex-1 justify-center'>
        <Button color='ink-link' href='https://rushable.io' target='_brank'>
          Let's Talk <ArrowRight size={16} className='text-ink' />
        </Button>
      </div>
    </div>
  )
}
