import React, { useEffect, useState } from 'react'

import { LinedPenEdit } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import SaveChangeBar from 'components/SaveChangeBar'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editUserProfileApi } from 'redux/user/actions'
import type { IeditUser } from 'redux/user/actions'
import { formatPhoneNumber } from 'utils/digit'

import ContactModal from './components/ContactModal'
import MyInfoSkeleton from './components/MyInfoSkeleton'
import ResetPasswordModal from './components/ResetPasswordModal'

type Itype = 'phone' | 'email'
interface Iprops {
  formData: Record<string, any>
  getProfile: () => void
}

const formobj = {
  first_name: '',
  last_name: '',
}

export default function MyInfoPage({
  formData = formobj,
  getProfile,
}: Iprops): JSX.Element {
  const { brandId = '' } = useParams<TParamTypes>()
  const [isOpenPwd, setIsOpenPwd] = useState(false)
  const [isOpenContact, setIsOpenContact] = useState(false)
  const [curType, setCurType] = useState<Itype>('phone')
  const [stateInit, setStateInit] = useState(formobj)
  const [state, setState] = useState(formobj)
  const [formError, setFormError] = useState('')
  const [loading, setLoading] = useState(false)
  const [formIsDirty, setFormIsDirty] = useState(false)

  const compareFn = useDebounce(compare)

  useEffect(() => {
    compareFn(state, stateInit, (flag: boolean) => setFormIsDirty(flag))
  }, [state, stateInit])

  useEffect(() => {
    const obj = {
      first_name: formData.first_name,
      last_name: formData.last_name,
    }
    setState({ ...obj })
    setStateInit({ ...obj })
  }, [formData.first_name])

  const handleSave = async () => {
    const params: IeditUser = {
      type: 'basic',
      first_name: state.first_name,
      last_name: state.last_name,
    }
    setLoading(true)
    try {
      const res = await editUserProfileApi(params, formData.id, brandId)
      toast.success(res?.message)
      setFormError('')
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormError(msg)
    }
    setLoading(false)
  }

  const onEdit = (type: string) => {
    if (type === 'password') {
      setIsOpenPwd(true)
    } else {
      setCurType(type as Itype)
      setIsOpenContact(true)
    }
  }

  const editBtn = (type: string) => {
    return (
      <Button color='secondary-link' onClick={() => onEdit(type)}>
        <LinedPenEdit size={16} className='text-silver hover:text-blue' />
      </Button>
    )
  }
  return (
    <>
      {!state.first_name ? (
        <MyInfoSkeleton />
      ) : (
        <div className='w-[496px] max-w-lg mx-auto'>
          <div className='flex justify-between'>
            <Field
              label='First name'
              value={state.first_name || ''}
              onChange={e => setState({ ...state, first_name: e.target.value })}
              name='first_name'
              type='text'
              containerClassName='mb-6 mr-6 w-full'
            />
            <Field
              label='Last name'
              value={state.last_name || ''}
              name='last_name'
              type='text'
              onChange={e => setState({ ...state, last_name: e.target.value })}
              containerClassName='mb-6 w-full'
            />
          </div>

          <Field
            label='Cellphone'
            value={formatPhoneNumber(formData.phone || '') || ''}
            name='Cellphone'
            disabled={true}
            type='tel'
            append={editBtn('phone')}
            containerClassName='mb-6'
            onChange={e => {
              null
            }}
          />
          <Field
            label='Email'
            value={formData.email || ''}
            name='Email'
            disabled={true}
            type='email'
            append={editBtn('email')}
            containerClassName='mb-6'
          />
          <Field
            label='Password'
            value={'********'}
            name='Password'
            disabled={true}
            append={editBtn('password')}
            type='password'
          />
        </div>
      )}

      <ResetPasswordModal
        brandId={brandId}
        userId={formData.id}
        open={isOpenPwd}
        toggle={() => setIsOpenPwd(!isOpenPwd)}
      />

      <ContactModal
        brandId={brandId}
        userId={formData.id}
        type={curType}
        open={isOpenContact}
        onSuccess={() => getProfile()}
        toggle={() => setIsOpenContact(!isOpenContact)}
      />

      {formIsDirty && (
        <SaveChangeBar
          error={formError}
          confirmRequest={loading}
          onConfirm={() => {
            handleSave()
          }}
        />
      )}
      <UnsavedPrompt when={formIsDirty} />
    </>
  )
}
