import { useState, useEffect } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import moment from 'moment'
import { DateRange, DefinedRange } from 'react-date-range'

type Iranges = {
  startDate?: string
  endDate?: string
}
type TDateRangeModalProp = {
  placeholder?: string
  format?: string
  label?: string
  ranges?: Iranges
  onChange: (e: DateItem) => void
}

type DateItem = {
  startDate?: undefined | Date
  endDate?: undefined | Date
  key: string
}

const defaultRanage = [
  {
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  },
]

export default function DateRangeModal({
  placeholder = 'Select range',
  label = '',
  format = 'YYYY/MM/DD',
  ranges = {},
  onChange,
}: TDateRangeModalProp): JSX.Element {
  const [open, setOpen] = useState(false)
  const [dateField, setDateField] = useState('')
  const [state, setState] = useState<DateItem[]>(defaultRanage)

  useEffect(() => {
    if (ranges.startDate && ranges.endDate) {
      const dateItem = {
        startDate: new Date(ranges.startDate),
        endDate: new Date(ranges.endDate),
        key: 'selection',
      }
      setState([dateItem])
    }
  }, [ranges.startDate, ranges.endDate])

  useEffect(() => {
    const date = state[0]
    if (date.startDate && date.endDate) {
      const s = moment(date.startDate).format(format)
      const e = moment(date.endDate).format(format)
      setDateField(`${s}-${e}`)
    }
  }, [state])

  const handleChange = (e: any) => {
    setState([e.selection])
    onChange(e.selection)
  }
  return (
    <>
      <Field
        containerClassName='w-full'
        label={label}
        name='date-range-select-x'
        autoComplete='off'
        type='text'
        placeholder={placeholder}
        value={dateField}
        onClick={e => {
          e.preventDefault()
          setOpen(true)
        }}
        onChange={() => null}
      />
      <ModalFull
        open={open}
        toggle={() => setOpen(false)}
        footer={
          <Button
            className='w-full'
            color='primary'
            onClick={() => {
              setOpen(false)
            }}
          >
            Confirm
          </Button>
        }
      >
        <div className='flex'>
          <DefinedRange onChange={handleChange} ranges={state} />
          <DateRange
            maxDate={new Date()}
            direction='horizontal'
            rangeColors={['#008DFF']}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            onChange={handleChange}
            ranges={state}
          />
        </div>
      </ModalFull>
    </>
  )
}
