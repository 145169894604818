import { useState, useEffect } from 'react'

import { LinedEmail, LinedEmailOpen, LinedChatSms } from '@rushable/icons'
import cn from 'classnames'
import { Spin } from 'components/Loading'
import Switch from 'components/Switch'
import Table from 'components/TableAndCell/Table'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCampaignJobs, showCampaign } from 'redux/campaign'
import { useAppDispatch } from 'redux/hooks'
import type { TCampaignItem, TJobs } from 'types/campaign'
import { currencyFormatter, formatPhoneNumber, formatPrice } from 'utils/digit'

import CampaignROI from './CampaignROI'
import CampaignStatus from './CampaignStatus'
import ResumeModal from './ResumeModal'

type TOverviewFormProps = {
  id: string
  campaign: TCampaignItem
  createOrUpdate: (extraParam: Record<string, any>) => void
}

export default function OverviewForm({
  id,
  campaign,
  createOrUpdate,
}: TOverviewFormProps): JSX.Element {
  const { locationId } = useParams<TParamTypes>()
  const dispatch = useAppDispatch()

  const [resumeModal, setResumeModal] = useState(false)
  const [jobs, setJobs] = useState<TJobs | null>(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [statusLoading, setStatusLoading] = useState(false)

  const [showDirectSales, setShowDirectSales] = useState(false)
  const {
    actual_audience = 0,
    actual_email_audience = 0,
    actual_email_token = 0,
    actual_sms_token = 0,
    sales_volume = 0,
    potential_sales_volume = 0,
    roi = 0,
    roi_w_potential_sales = 0,
    cta_click = 0,
    email_opened = 0,
  } = campaign.campaign_performance || {}

  const columns = [
    {
      key: 'user',
      name: 'CUSTOMER',
      className: 'w-40 px-6 py-4',
      tdClassName: 'text-xs text-ink',
      custom: (value: { first_name: string; last_name: string }) => {
        return (
          <div>
            {value.first_name} {value.last_name}
          </div>
        )
      },
    },
    {
      key: 'phone',
      name: 'PHONE',
      className: 'w-36 px-6 py-4',
      tdClassName: 'text-xs text-lead',
      custom: (value: string) => {
        return <div>{formatPhoneNumber(value)}</div>
      },
    },
    {
      key: 'email',
      name: 'EMAIL',
      className: 'w-64',
      tdClassName: 'text-xs text-lead',
    },
    {
      key: 'method',
      name: 'METHOD',
      align: 'center',
      custom: (value: string, item: any) => {
        const EmailIcon = () => {
          if (item?.email_status === 'delivered') {
            return <LinedEmailOpen size={12} className='text-lead' />
          }
          if (item?.email_status === 'failed') {
            return <LinedEmail size={12} className='text-red' />
          }
          return <LinedEmail size={12} className='text-lead' />
        }
        return (
          <div className='flex justify-center'>
            {item.email_status && <EmailIcon />}
            {item.sms_status && (
              <LinedChatSms
                size={12}
                className={cn(
                  {
                    'text-dark-16': item.sms_status === 'pending',
                  },
                  {
                    'text-lead': item.sms_status === 'delivered',
                  },
                  {
                    'text-red': item.sms_status === 'failed',
                  },
                  { 'ml-1': item.email_status },
                )}
              />
            )}
          </div>
        )
      },
    },
    {
      key: 'last_click',
      name: 'LAST CLICK',
      align: 'right',
      tdClassName: 'text-xs text-lead',
      className: 'w-32',
      custom: (value: string, item: any) => {
        const lastClick =
          new Date(item.email_cta_clicked_at) >
          new Date(item.sms_cta_clicked_at)
            ? item.email_cta_clicked_at
            : item.sms_cta_clicked_at
        return <>{lastClick ? moment(lastClick).format('MM/DD/YYYY') : '-'}</>
      },
    },
    {
      key: showDirectSales ? 'sales_volume' : 'potential_sales_volume',
      name: 'SALES',
      align: 'right',
      tdClassName: 'text-xs text-lead',
      custom: (value: string, item: any) => {
        return (
          <>
            {showDirectSales
              ? currencyFormatter(Number(item.sales_volume))
              : currencyFormatter(
                  Number(item.sales_volume) +
                    Number(item.potential_sales_volume),
                )}
          </>
        )
      },
    },
  ]

  const getJobs = async (page: number, pageSize: number) => {
    try {
      setLoading(true)
      const res = await dispatch(
        getCampaignJobs({
          locationId: locationId as string,
          id,
          page,
          pageSize,
        }),
      )
      setPage(page)
      setPageSize(pageSize)

      if (res) {
        setJobs(res)
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  const getShow = async () => {
    try {
      setPageLoading(true)
      await dispatch(showCampaign(id, locationId as string))
      setPageLoading(false)
    } catch (error: any) {
      setPageLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  const handleStatusChange = async (status: string) => {
    try {
      setStatusLoading(true)
      await createOrUpdate({ status })
      await getShow()
      setStatusLoading(false)
    } catch (error: any) {
      setStatusLoading(false)
      setResumeModal(true)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  useEffect(() => {
    getJobs(1, pageSize)
  }, [])

  const theROI = showDirectSales ? roi : roi_w_potential_sales
  const perDollarROI = theROI ? (theROI / 100).toFixed(2) : 0
  const hasMetrics = campaign.enable_cta || campaign.campaign_offer

  // NOTE: since we have data display bug in the current automated campaign,
  // and we are doing the redesign, so we make a quick update to just show N/A
  // for opened and clicks occurred. Only for the automated campaigns.
  const getMetric = (value: string) => {
    let result = ''
    switch (value) {
      case 'Sales generated':
        result = showDirectSales
          ? currencyFormatter(Number(sales_volume))
          : currencyFormatter(
              Number(potential_sales_volume) + Number(sales_volume),
            )
        break
      case 'Opened':
        result =
          campaign.type === 'automated'
            ? 'N/A'
            : `${
                isNaN(email_opened / actual_email_audience)
                  ? 0
                  : ((email_opened / actual_email_audience) * 100).toFixed(2)
              }%`
        break
      case 'Clicks occurred':
        result =
          campaign.type === 'automated'
            ? 'N/A'
            : `${
                isNaN(cta_click / actual_audience)
                  ? 0
                  : ((cta_click / actual_audience) * 100).toFixed(0)
              }%`
        break
      case 'Token spent':
        result = formatPrice(actual_email_token + actual_sms_token)
        break
      default:
        break
    }
    return result
  }
  return (
    <Spin
      spining={pageLoading}
      className='rounded-b-lg overflow-hidden z-10'
      styles={{
        minHeight: 'calc(100vh - 144px)',
      }}
    >
      <div className='my-8 px-8'>
        {hasMetrics ? (
          <div className='flex justify-between border border-zinc rounded-lg px-6 py-4'>
            <CampaignROI
              perDollarROI={perDollarROI}
              theROI={theROI}
              status={campaign.status}
            />
            <div className='flex-1'>
              <CampaignStatus
                campaign={campaign}
                statusLoading={statusLoading}
                handleStatusChange={handleStatusChange}
              />
              <div className='mt-4 flex space-x-4'>
                {[
                  'Sales generated',
                  'Opened',
                  'Clicks occurred',
                  'Token spent',
                ].map(m => (
                  <div className='flex-1'>
                    <MetricCard context={getMetric(m)} label={m} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className='flex justify-between border border-zinc rounded-lg px-6 py-4'>
            <div className='flex-1 items-center bg-field pt-3 mr-4'>
              <CampaignStatus
                campaign={campaign}
                statusLoading={statusLoading}
                handleStatusChange={handleStatusChange}
              />
            </div>
            <MetricCard
              context={getMetric('Token spent')}
              label='Token spent'
            />
          </div>
        )}
        <div className='pt-8 pb-16'>
          <Table
            columns={columns}
            data={jobs?.data || []}
            isPageSet
            totalText='records'
            loading={loading}
            current={jobs?.current_page || 1}
            total={jobs?.total || 0}
            onPageSizeChange={newPageSize => {
              getJobs(page, newPageSize)
            }}
            onPageNoChange={newPage => {
              getJobs(newPage, pageSize)
            }}
            rightButton={
              <Switch
                label='Show sales from clicks only'
                name='showDirectSales'
                checked={showDirectSales}
                onChange={setShowDirectSales}
              />
            }
          />
        </div>
        <ResumeModal
          open={resumeModal}
          toggle={() => {
            setResumeModal(!resumeModal)
          }}
        />
      </div>
    </Spin>
  )
}

const MetricCard = ({
  context,
  label,
}: {
  context: string | number
  label: string
}) => (
  <div className='min-w-[160px] bg-field px-6 py-4 rounded-lg'>
    <div className='text-ink text-2xl font-bold pb-2'>{context}</div>
    <div className='text-lead text-xs whitespace-nowrap'>{label}</div>
  </div>
)
