import { useEffect, useState } from 'react'

import { loadStripe } from '@stripe/stripe-js'
import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import { STRIPE_API_KEY } from 'data/constant'
import { useParams } from 'react-router-dom'
import { getBillingAccountApi } from 'redux/accountAndPayout'
import { useAppSelector } from 'redux/hooks'

import UpdateBillingAccount from './UpdateBillingAccount'

interface IModalTypes {
  disabled?: boolean
  onSuccess: () => void
}

export type TFormData = {
  accountType: string
  routingNumber: string
  accountNumber: string
  repeatAccountNumber: string
  name: string
  email: string
}

const INIT_FORM_DATA = {
  accountType: '',
  routingNumber: '',
  accountNumber: '',
  repeatAccountNumber: '',
  name: '',
  email: '',
}

export default function ModalUpdateBank({ disabled, onSuccess }: IModalTypes) {
  const { locationId = '' } = useParams<TParamTypes>()
  const stripePromise = loadStripe(STRIPE_API_KEY)
  const auth = useAppSelector(state => state.auth.authProfile.data)
  const [isVisible, setIsVisible] = useState(false)
  const [formStatus, setFormStatus] = useState({
    loading: false,
    error: '',
  })

  const [formData, setFormData] = useState<TFormData>(INIT_FORM_DATA)
  const onFormDataChange = (type: string, value: any) => {
    if (formStatus.error) {
      // form 改变时，重置 error 信息
      setFormStatus(prev => ({
        ...prev,
        error: '',
      }))
    }
    setFormData({
      ...formData,
      [type]: value,
    })
  }

  const handleSubmit = async () => {
    try {
      setFormStatus({
        loading: true,
        error: '',
      })
      const res = await getBillingAccountApi(locationId)
      const stripe = await stripePromise
      if (res?.client_secret && stripe) {
        const result = await stripe.confirmUsBankAccountSetup(
          res?.client_secret,
          {
            payment_method: {
              billing_details: {
                name: formData.name,
                email: formData.email,
              },
              us_bank_account: {
                // account_number test 000123456789
                account_number: formData.accountNumber,
                // routing_number test 110000000
                routing_number: formData.routingNumber,
                account_holder_type: formData.accountType, // 'individual' or 'company'
              },
            },
          },
        )
        // 处理确认设置银行账户的结果
        if (result.error) {
          // 处理错误
          setFormStatus({
            loading: false,
            error:
              result.error?.setup_intent?.last_setup_error?.message ||
              result.error?.message ||
              'Request error',
          })
        } else {
          // 成功后从新获取 location 数据
          onSuccess()
        }
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus({
        loading: false,
        error: msg || '',
      })
    }
  }

  useEffect(() => {
    const email = auth?.email || ''
    const name =
      auth?.first_name && auth?.last_name
        ? `${auth?.first_name} ${auth?.last_name}`
        : ''
    if (!formData.name && !formData.email && email && name) {
      setFormData(prev => {
        return {
          ...prev,
          name,
          email,
        }
      })
    }
  }, [auth])

  return (
    <>
      <Button
        color='secondary'
        size='sm'
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        UPDATE BILLING ACCOUNT
      </Button>
      <ModalFull
        open={isVisible}
        title='Update Billing Account'
        loading={formStatus.loading}
        toggle={() => setIsVisible(false)}
        okBtnDisabled={
          !formData.accountType ||
          !formData.routingNumber ||
          !formData.accountNumber ||
          formData.accountNumber !== formData.repeatAccountNumber ||
          !formData.name ||
          !formData.email
        }
        onOk={() => {
          handleSubmit()
        }}
      >
        <UpdateBillingAccount
          formData={formData}
          errorText={formStatus.error}
          onFormDataChange={onFormDataChange}
        />
      </ModalFull>
    </>
  )
}
