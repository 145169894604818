import React from 'react'

import {
  DuoToneBank,
  DuoToneMoney,
  DuoToneItems,
  DuoToneTax,
  DuoToneHappyFace,
} from '@rushable/icons'
import Button from 'components/Button'
import MoneyAccounting from 'components/MoneyAccounting'
import moment from 'moment'
import { Link } from 'react-router-dom'

const MonthlyReportCard = ({ item, url }: { item: any; url: string }) => (
  <div className='relative flex justify-between items-center pl-[128px] pr-8 py-6 border border-solid border-zinc rounded-2xl'>
    <div className='absolute top-0 left-8 flex flex-col bg-field px-3 py-[14px] rounded-b-lg'>
      <div className='text-xl text-ink font-bold'>
        {moment(item.date).format('MMM')}
      </div>
      <div className='text-sm text-ink font-medium mt-[-4px]'>
        {moment(item.date).format('YYYY')}
      </div>
    </div>
    <div className='flex-1'>
      <p className='text-xxs text-ink mb-2'>Net payout</p>
      <div className='flex items-center'>
        <DuoToneBank size={20} />
        <MoneyAccounting
          className='ml-2'
          size='sm'
          type='bold'
          value={item.net_payout}
        />
      </div>
    </div>
    <div className='flex-1'>
      <p className='text-xxs text-ink mb-2'>Total collected</p>
      <div className='flex items-center'>
        <DuoToneMoney size={20} />
        <MoneyAccounting
          className='ml-2'
          size='sm'
          type='bold'
          value={item.total}
        />
      </div>
    </div>
    <div className='flex-1'>
      <p className='text-xxs text-ink mb-2'>Tax collected</p>
      <div className='flex items-center'>
        <DuoToneTax size={20} />
        <MoneyAccounting
          className='ml-2'
          size='sm'
          type='bold'
          value={item.tax}
        />
      </div>
    </div>
    <div className='flex-1'>
      <p className='text-xxs text-ink mb-2'>Restaurant tip</p>
      <div className='flex items-center'>
        <DuoToneHappyFace size={20} />
        <MoneyAccounting
          className='ml-2'
          size='sm'
          type='bold'
          value={item.restaurant_tip}
        />
      </div>
    </div>
    <div className='flex-1'>
      <p className='text-xxs text-ink mb-2'>Refund & Adjustment</p>
      <div className='flex items-center'>
        <DuoToneItems size={20} />
        <MoneyAccounting
          className='ml-2'
          size='sm'
          type='bold'
          value={item.refund_adjustment}
          plusSymbol
        />
      </div>
    </div>
    <Link to={`${url}/${moment(item.date).format('YYYYMM')}`}>
      <Button size='sm' color='primary'>
        VIEW REPORT
      </Button>
    </Link>
  </div>
)

export default MonthlyReportCard
