import React from 'react'

import { LinedBriefcase, LinedUser } from '@rushable/icons'
import Badge from 'components/Tag/Badge'

import JobCategoryIcon from './JobCategoryIcon'

interface TJobItemCard {
  item: any
  handleOpen: (id: number) => void
}

export default function JobItemCard({
  item,
  handleOpen,
}: TJobItemCard): JSX.Element {
  const { status, job_applications_count, code, title, id, job_category_name } =
    item
  return (
    <div
      className='flex-1 flex flex-col space-y-3 p-6 border border-solid border-zinc rounded-lg cursor-pointer'
      onClick={() => handleOpen(id)}
    >
      <div className='flex items-center space-x-4 justify-between'>
        <p className='flex-1 text-xl text-ink font-bold overflow-hidden	whitespace-nowrap text-ellipsis'>
          {title}
        </p>
        <Badge color={status === 'open' ? 'blue' : 'red'}>
          {status === 'open' ? 'OPEN' : 'CLOSED'}
        </Badge>
      </div>
      <div className='flex space-x-2 text-ink text-sm'>
        <div className='flex items-center space-x-1'>
          <LinedUser className='text-lead' size={16} />
          <div className='whitespace-nowrap'>
            {job_applications_count || 0} applicant
          </div>
        </div>
        <div className='flex items-center space-x-1'>
          <LinedBriefcase className='text-lead' size={16} />
          <div>{code}</div>
        </div>
        <div className='flex items-center space-x-1'>
          <JobCategoryIcon type={job_category_name} />
          <div>{job_category_name}</div>
        </div>
      </div>
    </div>
  )
}
