import React from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import ConnectAccountPage from './ConnectAccountPage'
import GiftCardDetailPage from './GiftCardDetailPage'
import GiftCardListPage from './GiftCardListPage'
import StartGiftCardPage from './StartGiftCardPage'

export default function GiftCardPage(): JSX.Element {
  const { path } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <StartGiftCardPage />
        </Route>
        <Route exact path={`${path}/gift-card-list`}>
          <GiftCardListPage />
        </Route>
        <Route exact path={`${path}/create`}>
          <ConnectAccountPage />
        </Route>
        <Route exact path={`${path}/gift-card-detail/:giftCardId`}>
          <GiftCardDetailPage />
        </Route>
      </Switch>
    </>
  )
}
