// 再添加需要 pro package 的页面，只需要修改 isProPackagePath 和 isProPackageName
export function isProPackagePath(path: string) {
  return (
    path.includes('gift-card') ||
    path.includes('loyalty-rewards') ||
    path.includes('recruiting') ||
    path.includes('smart-review')
  )
}

export function isProPackageName(name?: string) {
  return (
    name === 'Gift Card' ||
    name === 'Loyalty' ||
    name === 'Recruiting' ||
    name === 'Smart Review'
  )
}
