import react from 'react'

import { Transition } from '@headlessui/react'

interface Iprops {
  children: react.ReactElement
  spining: boolean
  className?: string
  styles?: Record<string, string>
  top?: boolean
}
export default function Spin(props: Iprops) {
  const { className, children, spining, styles, top = false } = props
  return (
    <div className={className} style={{ position: 'relative', ...styles }}>
      {children}

      <Transition show={spining} appear={true}>
        <div
          className={`absolute top-0 bottom-[-2px] right-[-2px] left-[-2px] items-center flex-col ${
            top ? 'justify-start pt-[120px]' : 'justify-center'
          }`}
          style={{
            display: spining ? 'flex' : 'none',
            zIndex: 99,
            backgroundColor: 'rgba(255, 255, 255)',
          }}
        >
          <div className='relative w-[55px]'>
            <div className='bar' />
            <div className='bar' />
            <div className='bar' />
            <div className='bar' />
            <div className='bar' />
          </div>
          <div className='pt-[45px] text-ink text-base font-bold'>
            Please Wait
          </div>
        </div>
      </Transition>
    </div>
  )
}
