import React, { useEffect, useState } from 'react'

import { SolidTrashCan, SolidFile } from '@rushable/icons'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import Field from 'components/Field'
import { Select } from 'components/Select'
import TextArea from 'components/TextArea'
import moment from 'moment'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { getJobApplicationDataApi } from 'redux/recruiting'
import { formatPhoneNumber } from 'utils/digit'

import Skeleton from './Skeleton'
import { SELECT_STATUS_OPTIONS } from '../helps/constant'

interface TCandidateDrawer {
  id: number
  open: boolean
  deleteLoading: boolean
  updateLoading: boolean
  updateJobApplicationData: (id: number, status: string) => void
  deleteJobApplication: (id: number) => void
  toggle: () => void
}

const defaultFormData = {
  status: '',
  resume_url: null,
  name: '',
  job: '',
  phone: '',
  date: '',
  email: '',
  message: '',
}

export default function CandidateDrawer({
  id,
  open,
  deleteLoading,
  updateLoading,
  updateJobApplicationData,
  deleteJobApplication,
  toggle,
}: TCandidateDrawer): JSX.Element {
  const { locationId } = useParams<{ locationId: string }>()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [formData, setFormData] = useState<Record<string, any>>(defaultFormData)

  const getJobApplicationData = async () => {
    try {
      setLoading(true)
      const res = await getJobApplicationDataApi(locationId, id)
      setFormData({
        status: res?.status || '',
        resume_url: res?.resume_url || null,
        name: `${res?.first_name} ${res?.last_name}`,
        job: res?.job_post?.job_category?.name || '',
        phone: res?.phone,
        date: res?.created_at,
        email: res?.email,
        message: res?.message,
      })
      setStatus(res?.status || '')
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open && id) {
      getJobApplicationData()
    } else {
      setFormData(defaultFormData)
      setStatus('')
    }
  }, [open, id])

  return (
    <Drawer
      open={open}
      toggle={toggle}
      title='Manage Candidate'
      leftFooter={
        <>
          <Button
            color='secondary-link'
            loading={deleteLoading}
            disabled={!!loading}
            onClick={() => deleteJobApplication(id)}
          >
            <div className='flex items-center'>
              {deleteLoading ? null : (
                <SolidTrashCan size={16} className='mr-2' />
              )}
              DELETE
            </div>
          </Button>
        </>
      }
      rightFooter={
        <>
          <Button color='secondary' onClick={toggle}>
            CANCEL
          </Button>
          <Button
            color='primary'
            disabled={loading || (!!status && status === formData.status)}
            loading={updateLoading}
            onClick={() => updateJobApplicationData(id, status)}
          >
            CONFIRM
          </Button>
        </>
      }
    >
      {id && loading ? (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <div className='mt-2 flex flex-col space-y-6'>
          <div className='flex items-end space-x-4'>
            <Select
              className='w-60'
              options={SELECT_STATUS_OPTIONS}
              value={status}
              label='Applicant status'
              onChange={value => setStatus(value)}
            />
            {formData.resume_url ? (
              <Button
                className='w-60'
                color='tertiary'
                onClick={() => {
                  window.open(formData.resume_url)
                }}
              >
                <div className='flex text-ink font-bold'>
                  <SolidFile size={16} className='mr-2' />
                  View Resume
                </div>
              </Button>
            ) : null}
          </div>
          <div className='flex space-x-4'>
            <Field
              className='w-60'
              label='Applicant name'
              name='name'
              value={formData.name}
              type='text'
              disabled
            />
            <Field
              className='w-60'
              label='Applicant job'
              name='job'
              value={formData.job}
              type='text'
              disabled
            />
          </div>
          <div className='flex space-x-4'>
            <Field
              className='w-60'
              label='Cellphone'
              name='phone'
              value={formData.phone ? formatPhoneNumber(formData.phone) : ''}
              type='text'
              disabled
            />
            <Field
              className='w-60'
              label='Submission date'
              name='date'
              value={
                formData.date ? moment(formData.date).format('MM/DD/YYYY') : ''
              }
              type='text'
              disabled
            />
          </div>
          <Field
            className='w-full'
            label='Email'
            name='email'
            value={formData.email}
            type='text'
            disabled
          />
          {formData.message ? (
            <TextArea
              inputClass='resize-none'
              name='message'
              label='Message'
              maxLength={300}
              value={formData.message}
              disabled
            />
          ) : null}
        </div>
      )}
    </Drawer>
  )
}
