import { useState, useEffect } from 'react'

import { ThumbUp, ThumbDown, Google, Yelp } from '@rushable/icons'
import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import Drawer from 'components/Drawer'
import Field from 'components/Field'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { updateReviewsConfigApi } from 'redux/smartReview'

type THowWorksModalProps = {
  open: boolean
  onClose: () => void
  getReviewConfig: () => void
}

type TLinkData = {
  link: string
  id: null | number
}

export default function HowWorksModal({
  open,
  onClose,
  getReviewConfig,
}: THowWorksModalProps) {
  const { locationId } = useParams<{ locationId: string }>()
  const { reviewConfig } = useAppSelector(state => state.smartReview)
  const links = reviewConfig?.location_review_links
  const [googleData, setGoogleData] = useState<TLinkData>({
    link: '',
    id: null,
  })
  const [yelpData, setYelpData] = useState<TLinkData>({
    link: '',
    id: null,
  })
  const [loading, setLoading] = useState(false)
  const [tested, setTested] = useState(false)

  const canConfirm = tested && (!!googleData.link || !!yelpData.link)

  const handleConfirm = async () => {
    const params: {
      status: string
      location_review_links: {
        type: string
        link: string
        id: number | null
      }[]
    } = {
      status: reviewConfig?.status || '',
      location_review_links: [],
    }
    if (googleData.id || googleData.link) {
      params.location_review_links.push({
        type: 'google',
        link: googleData.link,
        id: googleData?.id || null,
      })
    }
    if (yelpData.id || yelpData.link) {
      params.location_review_links.push({
        type: 'yelp',
        link: yelpData.link,
        id: yelpData?.id || null,
      })
    }
    try {
      setLoading(true)
      await updateReviewsConfigApi(locationId, params)
      getReviewConfig()
      onClose()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  const formatUrl = (url: string) => {
    return url.includes('http') ? url : `https://${url}`
  }

  useEffect(() => {
    if (open && links?.length) {
      const google = links.find(item => item.type === 'google') || null
      const yelp = links.find(item => item.type === 'yelp') || null
      if (google) {
        setGoogleData({
          link: google?.link,
          id: google?.id,
        })
      }
      if (yelp) {
        setYelpData({
          link: yelp?.link,
          id: yelp?.id,
        })
      }
    }
    if (open) {
      setTested(false)
    }
  }, [open, links])

  return (
    <Drawer
      open={open}
      title='How Smart Review Works'
      toggle={onClose}
      rightFooter={
        <div className='flex justify-end'>
          <Button className='mr-4' color='secondary' onClick={onClose}>
            CANCEL
          </Button>
          <Button
            color='primary'
            disabled={!canConfirm}
            loading={loading}
            onClick={handleConfirm}
          >
            CONFIRM
          </Button>
        </div>
      }
    >
      <div>
        <div className='text-base text-dark-100 font-normal'>
          Your customers would receive a review invitation two hours after their
          order time via text message. The invitation would ask the overall
          experience first, and depends on the outcome, two different actions
          would be triggered:
        </div>
        <div className='mt-6 p-4 border border-zinc rounded-lg text-dark-100'>
          <div className='flex'>
            <div className='mr-2 w-16 h-fit rounded bg-ice  flex justify-center items-center'>
              <ThumbUp size={16} className='mr-1' />
              <span className='text-sm font-medium'>Good</span>
            </div>
            <div className='flex-1 text-base'>
              If the response was good, the customer would be redirected to your
              preferred review platform configured below
            </div>
          </div>
          <div className='flex mt-4'>
            <div className='mr-2 w-16 h-fit rounded bg-ice text-sm flex justify-center items-center'>
              <ThumbDown size={16} className='mr-1' />
              <span className='text-sm font-medium'>Bad</span>
            </div>
            <div className='flex-1 text-base'>
              If the response was bad, the customer would be asked to describe
              the issue encountered and the message would only be sent to you
            </div>
          </div>
        </div>
        <div className='mt-6 mb-4 text-base font-bold text-dark-100'>
          Review Platform
        </div>
        <div className='flex items-center'>
          <Field
            containerClassName='flex-1'
            placeholder='g.page/r/......'
            type='text'
            name='smart_review_google_url'
            suffix={<Google className='relative right-1.5' size={24} />}
            value={googleData.link}
            onChange={e =>
              setGoogleData({
                ...googleData,
                link: e.target.value,
              })
            }
          />
          <Button
            className='ml-4'
            color='tertiary'
            disabled={!googleData.link}
            onClick={() => window.open(formatUrl(googleData.link))}
          >
            TEST
          </Button>
        </div>
        <div className='mt-4 flex items-center'>
          <Field
            containerClassName='flex-1'
            placeholder='yelp.com/writeareview/biz/......'
            type='text'
            name='smart_review_yelp_url'
            suffix={<Yelp className='relative right-1.5' size={24} />}
            value={yelpData.link}
            onChange={e =>
              setYelpData({
                ...yelpData,
                link: e.target.value,
              })
            }
          />
          <Button
            className='ml-4'
            color='tertiary'
            disabled={!yelpData.link}
            onClick={() => window.open(formatUrl(yelpData.link))}
          >
            TEST
          </Button>
        </div>
        <CheckBox
          className='mt-4'
          labelClass='text-base text-ink'
          checked={tested}
          onChange={e => setTested(e)}
          size={20}
        >
          The platform link has been tested successfully
        </CheckBox>
      </div>
    </Drawer>
  )
}
