import React, { useState, useEffect } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import { useAppSelector } from 'redux/hooks'

import CodeVerifyForm from './CodeVerifyForm'
export type TPhoneLoginFormProp = {
  setMethod?: (v: string) => void
  handleFormChange: (name: string, value: string) => void
  handleSendCode: () => void
  phone: string
  errorMsg: string
  request: boolean
  signInBtnTxt?: string
}
export default function PhoneLoginForm({
  setMethod,
  handleFormChange,
  handleSendCode,
  phone,
  errorMsg,
  request,
  signInBtnTxt = 'SIGN IN'
}: TPhoneLoginFormProp): JSX.Element {
  const { sendCodeRequest, sendCodeSuccess, sendCodeError } = useAppSelector(
    state => state.auth,
  )
  const [phoneTyped, setPhoneTyped] = useState<boolean>(false)

  useEffect(() => {
    if (sendCodeRequest) {
      setPhoneTyped(true)
    }
  }, [sendCodeRequest, sendCodeSuccess])

  return (
    <div>
      {phoneTyped && sendCodeSuccess ? (
        <CodeVerifyForm
          phone={phone}
          setPhoneTyped={setPhoneTyped}
          handleFormChange={handleFormChange}
          handleSendCode={handleSendCode}
          errorMsg={errorMsg}
          request={request}
        />
      ) : (
        <form className='w-400px max-w-full' onSubmit={handleSendCode}>
          <div className='w-full mx-auto my-6'>
            <Field
              label='Cellphone'
              name='phone'
              placeholder='Input your cellphone'
              inputMode='tel'
              type='tel'
              className='w-full'
              containerClassName='mb-4'
              onChangePhone={value => {
                handleFormChange('phone', value)
              }}
              value={phone}
              error={sendCodeError}
            />
          </div>
          <div className='mt-8 flex space-x-4 items-center justify-center'>
            {setMethod ? <Button
              color='secondary'
              type='button'
              className='w-full'
              onClick={() => setMethod('')}
            >
              GO BACK
            </Button> : null}
            <Button
              color='primary'
              type='submit'
              className='w-full'
              loading={sendCodeRequest}
            >
              {signInBtnTxt}
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
