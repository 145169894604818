export const USAGE_TYPE_ITEMS = [
  {
    value: 'unlimited',
    label: 'No limit',
  },
  {
    value: 'limited',
    label: 'Limited',
  },
]

export const COUPON_TYPE_ITEMS = [
  { value: 'flat', label: 'Flat amount off' },
  { value: 'percentage', label: 'Percentage amount off' },
  { value: 'free_item', label: 'Free menu item' },
]

export const ORDER_TYPE_OPTIONS = [
  { value: 'pickup', label: 'Pickup' },
  { value: 'delivery', label: 'Delivery' },
  { value: 'dine_in', label: 'Dine-in' },
]

export const AUTO_DISPLAY_OPTIONS = [
  { value: 0, label: 'Require coupon code' },
  { value: 1, label: 'Show redemption option during ordering' },
]

export const ELIGIBILITY_OPTIONS = [
  { value: 0, label: 'All customers' },
  { value: 1, label: 'First-time customers only' },
]

export const DISCOUNT_COVERAGE_OPTIONS = [
  { value: 'min_price_amount', label: 'Minimum price amount' },
  { value: 'full_amount', label: 'Full amount' },
]

export const COUPON_EXPIRATION_OPTIONS = [
  { value: 0, label: 'This coupon remains valid until manually deactivated' },
  { value: 1, label: 'This coupon has an expiration date' },
]
