import { createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { TBrandStateProp } from 'types/brand'

import {
  getBrands,
  getBrand,
  addLocationsToBrand,
  getGiftCardList,
  setPage,
  setPageSize,
  setBrand,
  setNextRoute,
  setProPackageModalOpen,
} from './actions'

const initialState: TBrandStateProp = {
  getBrandsRequest: false,
  brands: [],
  selectedBrand: null,
  getBrandRequest: false,
  getGiftCardRequest: false,
  giftCardPage: 1,
  giftCardPageSize: 20,
  giftCardList: null,
  proPackageModalOpen: false,
  nextRoute: '',
}

export const brandReducer = createReducer(initialState, builder => {
  builder
    .addCase(getBrands.pending, state => {
      state.getBrandsRequest = true
    })
    .addCase(getBrands.fulfilled, (state, action) => {
      state.getBrandsRequest = false
      const data = action.payload || {}
      state.brands = data
    })
    .addCase(getBrands.rejected, (state, action) => {
      state.getBrandsRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(getBrand.pending, state => {
      state.getBrandRequest = true
    })
    .addCase(getBrand.fulfilled, (state, action) => {
      state.getBrandRequest = false
      const data = action.payload || {}
      state.selectedBrand = data
    })
    .addCase(getBrand.rejected, (state, action) => {
      state.getBrandRequest = false
      state.selectedBrand = null
      if (action.error.message) {
        // only get method use toast warning
        // post/patch/delete method return state error
        toast.warn(action.error.message)
      }
    })
    .addCase(addLocationsToBrand, (state, action) => {
      const locations = action.payload
      const brandId = locations[0].brand_id
      const targetIndex = state.brands.findIndex(v => v.id === brandId)
      if (targetIndex > -1) {
        state.brands[targetIndex].locations = [...locations]
      }
    })
    .addCase(getGiftCardList.pending, state => {
      state.getGiftCardRequest = true
    })
    .addCase(getGiftCardList.fulfilled, (state, action) => {
      state.getGiftCardRequest = false
      state.giftCardList = action.payload
    })
    .addCase(getGiftCardList.rejected, (state, action) => {
      state.getGiftCardRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(setPageSize, (state, action) => {
      state.giftCardPageSize = action.payload
    })
    .addCase(setPage, (state, action) => {
      state.giftCardPage = action.payload
    })
    .addCase(setBrand, (state, action) => {
      state.selectedBrand = action.payload
    })
    .addCase(setProPackageModalOpen, (state, action) => {
      state.proPackageModalOpen = action.payload
    })
    .addCase(setNextRoute, (state, action) => {
      state.nextRoute = action.payload
    })
})

export default brandReducer
