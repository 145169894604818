import React, { useState, useRef, useEffect } from 'react'

import Button from 'components/Button'
import CodeInput from 'components/CodeInput'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { toast } from 'react-toastify'
import { sendVerifyCodeApi, editUserProfileApi } from 'redux/user/actions'
import { formatDigitOnly } from 'utils/digit'

interface Iprops {
  userId: string
  brandId: string
  type: 'email' | 'phone'
  open: boolean
  toggle: () => void
  onSuccess?: () => void
}

const initState = { value: '', code: '' }
const initFormStatus = { loading: false, error: '' }
export default function ContactModal({
  type,
  open,
  userId,
  brandId,
  toggle,
  onSuccess,
}: Iprops): JSX.Element {
  const [isShowCode, setIsShowCode] = useState(false)
  const [time, setTime] = useState(-1)
  const timerRef = useRef<any>()
  const [formStatus, setFormStatus] = useState(initFormStatus)
  const [state, setState] = useState(initState)

  useEffect(() => {
    setState(initState)
    setFormStatus(initFormStatus)
    setIsShowCode(false)
    return () => {
      timerRef.current && clearInterval(timerRef.current)
    }
  }, [open])

  const onChangeForm = (obj: Record<string, any>) => {
    setState({
      ...state,
      ...obj,
    })
  }

  const handleSendCode = async () => {
    setFormStatus(prev => ({ ...prev, loading: true, error: '' }))
    try {
      await sendVerifyCodeApi({
        type: type === 'phone' ? 'update_phone' : 'update_email',
        [type]: type === 'phone' ? formatDigitOnly(state.value) : state.value,
      })
      toast.success('send successful')
      setIsShowCode(true)
      countDown()
      setFormStatus(prev => ({ ...prev, error: '' }))
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  const countDown = () => {
    setTime(59)
    timerRef.current = setInterval(() => {
      setTime(prev => {
        if (prev === 0) {
          clearInterval(timerRef.current)
          return -1
        }
        return --prev
      })
    }, 1000)
  }

  const handleSubmit = async () => {
    const params = {
      type,
      code: state.code,
      [type]: type === 'phone' ? formatDigitOnly(state.value) : state.value,
    }
    setFormStatus(prev => ({ ...prev, loading: true }))
    try {
      const res = await editUserProfileApi(params, userId, brandId)
      toast.success(res.message)
      setFormStatus(prev => ({ ...prev, error: '' }))
      toggle()
      onSuccess && onSuccess()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  return (
    <ModalFull
      open={open}
      toggle={toggle}
      title={type === 'phone' ? 'Update Cellphone' : 'Update Email'}
      error={formStatus.error}
      footer={
        <div className='flex justify-end'>
          <Button color='secondary' className='mr-4' onClick={toggle}>
            CANCEL
          </Button>
          <div />
          {time > 0 ? (
            <Button color='tertiary'>RESEND ({time})</Button>
          ) : (
            <Button
              color='primary'
              loading={formStatus.loading}
              onClick={() => {
                if (state.code?.length === 4) {
                  handleSubmit()
                } else {
                  handleSendCode()
                }
              }}
            >
              {state.code?.length ? 'CONFIRM' : 'SEND CODE'}
            </Button>
          )}
        </div>
      }
    >
      <>
        {type === 'phone' ? (
          <Field
            label='Update Cellphone'
            placeholder='Input your new cellphone'
            name='phone'
            value={state.value}
            type='tel'
            inputMode='tel'
            onChangePhone={value => onChangeForm({ value })}
          />
        ) : (
          <Field
            label='New email'
            placeholder='Input your  new email'
            name='email'
            type='email'
            value={state.value}
            onChange={e => {
              onChangeForm({ value: e.target.value })
            }}
          />
        )}
        {isShowCode && (
          <div className='mt-6'>
            <label className='font-bold block text-xs text-silver'>
              Please input the verification code
            </label>
            <CodeInput
              className='mt-2'
              length={4}
              fieldWidth={80}
              onChange={value => {
                onChangeForm({ code: value })
                if (value?.length === 4) {
                  timerRef.current && clearInterval(timerRef.current)
                  setTime(-1)
                }
              }}
            />
          </div>
        )}
      </>
    </ModalFull>
  )
}
