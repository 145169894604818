import { useState, useRef } from 'react'

import { DuoToneEmail, DuoToneCellphone } from '@rushable/icons'
import Button from 'components/Button'
import { PERMISSION } from 'data/permission'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { formatPhoneNumber } from 'utils/digit'

import { ModalHelp } from './GetHelp'

interface Iprops {
  emailDomain: string
  emailPrefix: string
  phone: string // 需对电话号码做格式化处理
  emailStatus: 'none' | 'pending' | 'complete'
}

export default function MarketingSetting({
  emailPrefix,
  emailDomain,
  phone,
  emailStatus,
}: Iprops) {
  const auth = useAppSelector(state => state.auth.authProfile.data)
  const [isVisible, setIsVisible] = useState(false)
  const typeRef = useRef<'email' | 'phone'>('email')
  const { url } = useRouteMatch()
  const history = useHistory()

  const isCanUpdateEmail =
    auth?.permissionMap?.[PERMISSION.UPDATEEMAILADDRESS]?.allowed == 1
  const isCanUpdatePhone =
    auth?.permissionMap?.[PERMISSION.UPDATEPHONENUMBER]?.allowed == 1

  const getEmailButton = () => {
    const buttonText = {
      none: 'SETUP NOW',
      pending: 'CHECK STATUS',
      complete: 'RESET',
    }
    if (!emailStatus) {
      return null
    }
    const isEmailPrefix = !!emailPrefix
    return (
      <Button
        disabled={!isCanUpdateEmail}
        color={
          !isEmailPrefix
            ? 'primary'
            : emailStatus === 'complete'
            ? 'secondary'
            : 'primary'
        }
        size='sm'
        onClick={() => {
          if (!isEmailPrefix) {
            history.push(`${url}/setup-email`)
          } else {
            if (emailStatus === 'none') {
              history.push(`${url}/setup-email`)
            }
            if (emailStatus === 'pending') {
              history.push(`${url}/setup-email?step=1`)
            }
            if (emailStatus === 'complete') {
              typeRef.current = 'email'
              setIsVisible(true)
            }
          }
        }}
      >
        {!isEmailPrefix ? 'SETUP NOW' : buttonText[emailStatus]}
      </Button>
    )
  }

  const getPhoneButton = () => {
    const isPhone = !!phone
    return (
      <Button
        disabled={!isCanUpdatePhone}
        color={isPhone ? 'secondary' : 'primary'}
        size='sm'
        onClick={() => {
          if (isPhone) {
            typeRef.current = 'phone'
            setIsVisible(true)
          } else {
            history.push(`${url}/setup-phone`)
          }
        }}
      >
        {isPhone ? 'GET A NEW NUMBER' : 'SETUP NOW'}
      </Button>
    )
  }
  return (
    <div className='mb-6'>
      <div className='text-xs font-bold text-silver mb-2'>
        Marketing setting
      </div>
      <div className='flex'>
        <div className='flex-1 border border-zinc rounded-lg pl-6 py-4 mr-6'>
          <div className='text-xs text-lead'>Email prefix for marketing</div>
          <div className='flex items-center py-2 text-base text-ink font-bold'>
            <DuoToneEmail size={24} className='pr-2' />
            <span>
              {emailPrefix || <span className='text-dark-16'>Prefix</span>}@
              {emailDomain}
            </span>
          </div>
          <div className='text-xs text-silver leading-[18px]	mb-4'>
            {!emailPrefix || emailStatus === 'none'
              ? 'Your system can use this prefix for outbound emails.'
              : 'Email address your system can be used to for outbound communication'}
          </div>
          <div className='flex justify-between items-center pr-6'>
            <div>{getEmailButton()}</div>
            {emailPrefix && emailStatus === 'pending' && (
              <div className='text-base text-yellow'>Pending Verification</div>
            )}
          </div>
        </div>
        <div className='flex-1 border border-zinc rounded-lg pl-6 py-4'>
          <div className='text-xs text-lead'>Phone number for marketing</div>
          <div className='flex items-center py-2 text-base text-ink font-bold'>
            <DuoToneCellphone size={24} className='pr-2' />
            {phone ? (
              <span>{formatPhoneNumber(phone)}</span>
            ) : (
              <span className='text-dark-16'>(XXX) XXX-XXXX</span>
            )}
          </div>
          <div className='text-xs text-silver leading-[18px]	mb-4'>
            Some features are required to setup a phone number here.
          </div>
          {getPhoneButton()}
        </div>
      </div>
      <ModalHelp
        type={typeRef.current}
        open={isVisible}
        toggle={() => setIsVisible(false)}
      />
    </div>
  )
}
