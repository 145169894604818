export const getStatusTagArgs = (
  status: string,
  expiration?: number | null,
) => {
  let color = 'blue'
  let text = 'Active'
  if (status === 'inactive') {
    color = 'red'
    text = 'Inactive'
  }
  if (status === 'pending' && expiration) {
    color = 'yellow'
    text = `Inviting • ${expiration}hr left`
  }
  return { color, text }
}
