import React, { useState, useEffect } from 'react'

import {
  DuoToneBank,
  AngleLeft,
  DuoToneItems,
  DuoToneInvoice,
  DuoToneCalendar,
} from '@rushable/icons'
import MarkSVG from 'assets/img/mark.svg'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import MoneyAccounting from 'components/MoneyAccounting'
import Table from 'components/TableAndCell/Table'
import ToolTips from 'components/ToolTips'
import moment from 'moment'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getPayoutDetailAPI,
  getPayoutTransactionsAPI,
} from 'redux/financial/actions'

import { ORDER_REFERENCE } from './helpers/constant'

type TPayoutDetailProp = {
  downloading: boolean
  downloadReport: (value: any) => void
}

export default function PayoutDetail({
  downloading,
  downloadReport,
}: TPayoutDetailProp): JSX.Element {
  const history = useHistory()
  const { locationId, brandId, payoutId } = useParams<TParamTypes>()
  const [tableData, setTableData] = useState({ data: [], total: 0 })
  const [loading, setLoading] = useState(false)
  const [detailData, setDetailData] = useState<any>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [, setPerPage] = useState(20)

  const columns = [
    {
      key: 'stripe_created_at',
      name: 'DATE',
      className: 'w-[110px]',
      custom: (value: string) => {
        return <div className='flex'>{moment(value).format('MM/DD/YYYY')}</div>
      },
    },
    {
      key: 'type',
      name: 'TRANSACTION',
      className: 'w-[132px]',
      custom: (value: string) => {
        let typeValue = ''
        switch (value) {
          case 'order_payment':
            typeValue = 'Order Payment'
            break
          case 'order_refund':
            typeValue = 'Refund'
            break
          case 'dispute_fee':
            typeValue = 'Dispute Fee'
            break
          case 'adjustment':
            typeValue = 'Adjustment'
            break
        }
        return <div className='flex'>{typeValue}</div>
      },
    },
    {
      key: 'order',
      name: 'ASSO. ORDER',
      className: 'w-[110px]',
      custom: (value: any, item: any) => {
        const id =
          item.type === 'order_refund' ? item?.refund?.order?.id : value?.id

        return id ? (
          <Link
            className={id && 'hover:text-blue cursor-pointer'}
            to={`/brand/${brandId}/location/${locationId}/order-history/order-detail/${id}`}
            target='_brank'
          >
            {`#${id}`}
          </Link>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      key: 'type',
      name: 'REFERENCE',
      className: 'w-[146px]',
      custom: (value: string, item: any) => {
        let typeValue = ''
        switch (value) {
          case 'order_payment':
            typeValue = ORDER_REFERENCE[item.order?.type]
            break
          case 'order_refund':
            typeValue = ORDER_REFERENCE[item?.refund?.order?.type]
            break
          case 'dispute_fee':
            typeValue = 'Dispute Fee'
            break
          case 'adjustment':
            typeValue = item?.description
            break
        }
        return <div className='text-ellipsis'>{typeValue}</div>
      },
    },
    {
      key: 'stripe_net',
      name: 'NET PAYOUT',
      className: 'w-[140px]',
      align: 'right',
      custom: (value: any) => {
        return (
          <MoneyAccounting
            className='w-24'
            type='normal'
            size='sm'
            value={value}
          />
        )
      },
    },

    {
      key: 'stripe_amount',
      name: 'TOTAL COLLECTED',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => {
        return (
          <MoneyAccounting
            className='w-24 text-lead'
            type='normal'
            size='sm'
            value={value}
          />
        )
      },
    },
    {
      key: 'order',
      name: 'CUSTOMER FEES',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => {
        return (
          <MoneyAccounting
            className='w-24 text-lead'
            type='normal'
            size='sm'
            value={value?.customer_fee}
          />
        )
      },
    },
    {
      key: 'order',
      name: 'RESTAURANT FEES',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => {
        return (
          <MoneyAccounting
            className='w-24 text-lead'
            type='normal'
            size='sm'
            value={value?.restaurant_fee}
          />
        )
      },
    },
  ]

  const getDetail = async () => {
    try {
      const res = await getPayoutDetailAPI(payoutId, locationId)
      setDetailData({ ...res.data })
    } catch (e: any) {
      toast.error(e?.message || 'request error')
    }
  }

  const getDetailTransactions = async (page: number, pageSize?: number) => {
    if (pageSize) {
      setPerPage(pageSize)
    }
    setCurrentPage(page)
    setLoading(true)
    try {
      const res = await getPayoutTransactionsAPI(payoutId, locationId, {
        page: page,
        per_page: pageSize,
      })

      setTableData(res.data || { data: [], total: 0 })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  useEffect(() => {
    getDetailTransactions(1)
    getDetail()
  }, [locationId, payoutId])

  return (
    <>
      <AdminPageTitle
        title={
          detailData.payout_created_at
            ? `Payout on ${moment(detailData.payout_created_at).format(
                'M/DD/YYYY',
              )}`
            : 'Upcoming Payout'
        }
        left={
          <div
            className='flex items-center text-silver font-bold cursor-pointer'
            onClick={() => history.go(-1)}
          >
            <AngleLeft className='mr-1' size={14} />
            GO BACK
          </div>
        }
      />
      <div className='py-8 px-8'>
        <div className='p-6 border border-solid border-zinc rounded-lg'>
          <div className='flex justify-between'>
            <div className='flex flex-col space-y-2 text-ink'>
              <p className='text-xs'>Deposit bank</p>
              <div className='flex space-x-2 font-bold'>
                <DuoToneBank size={24} />
                <div>
                  {detailData?.last_4 ? <>•••• {detailData?.last_4}</> : '-'}
                </div>
                <ToolTips
                  className='flex justify-center items-center'
                  hoverEl={
                    <div className='flex'>
                      <img src={MarkSVG} alt='' />
                    </div>
                  }
                >
                  <div className='whitespace-nowrap'>
                    {detailData.bank_name}
                  </div>
                </ToolTips>
                {/* <MarkI size={16} className='ml-2' /> */}
              </div>
            </div>
            <div className='flex flex-col space-y-2 text-ink'>
              <p className='text-xs'>Net payout</p>
              <div className='flex space-x-2 font-bold'>
                <DuoToneBank size={24} />
                <MoneyAccounting
                  className='w-24'
                  type='bold'
                  value={detailData?.summary?.net_amount}
                />
              </div>
            </div>
            <div className='flex flex-col space-y-2 text-ink'>
              <p className='text-xs'>Revenue period</p>
              <div className='flex space-x-2 font-bold'>
                <DuoToneCalendar size={24} />
                <div>
                  {detailData?.summary ? (
                    <>
                      {moment(detailData?.summary?.period_begin_time).format(
                        'M/DD',
                      ) +
                        ' to ' +
                        moment(detailData?.summary?.period_end_time).format(
                          'M/DD',
                        )}
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
            <div className='flex flex-col space-y-2 text-ink'>
              <p className='text-xs'>Payout date</p>
              <div className='flex space-x-2 font-bold'>
                <DuoToneCalendar size={24} />
                <div>
                  {detailData?.payout_created_at ? (
                    <>
                      {moment(detailData?.payout_created_at).format(
                        'M/DD/YYYY',
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
            <div className='flex flex-col space-y-2 text-ink'>
              <p className='text-xs'>Orders</p>
              <div className='flex space-x-2 font-bold'>
                <DuoToneInvoice size={24} />
                <div>{detailData?.summary?.order_count || '-'}</div>
              </div>
            </div>
            <div className='flex flex-col space-y-2 text-ink'>
              <p className='text-xs'>Refund & Adjustment</p>
              <div className='flex space-x-2 font-bold'>
                <DuoToneItems size={24} />
                {detailData?.summary ? (
                  <div>
                    {Number(detailData?.summary?.adjustment_count) +
                      Number(detailData?.summary?.refund_count)}
                  </div>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </div>
          <div className='mt-6 rounded-lg bg-ice px-6 py-4 text-ink'>
            ⚠️ This report should not be used for accounting purposes due to the
            weekly or daily payout cycles not aligning with any full month. To
            access accounting-friendly monthly report, please go to{' '}
            <span className='text-blue font-bold'>
              {'Performance > Financial > Accounting Report'}
            </span>
          </div>
        </div>
        <div className='mt-6'>
          <Table
            bodyClassName=''
            loading={loading}
            disableHover={true}
            isPageSet={true}
            current={currentPage}
            containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-x-scroll'
            columns={columns}
            data={tableData.data}
            total={tableData.total}
            totalText={'results'}
            rightButton={
              <Button
                loading={downloading}
                size='sm'
                color='primary'
                onClick={() => downloadReport(detailData.id)}
              >
                Export Detailed Report
              </Button>
            }
            onPageNoChange={value => {
              getDetailTransactions(value)
            }}
            onPageSizeChange={value => {
              getDetailTransactions(1, value)
            }}
          />
        </div>
      </div>
    </>
  )
}
