// import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'utils/axiosApi'

/*** ================================  Business Hours  =========================================*/

export async function getRegularApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/open_hours`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getHolidaysApi() {
  const response = await axios.request({
    url: 'holidays',
    method: 'get',
  })
  return response.data
}

export async function getSpecialHourApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/special_hours`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function updateRegularHourApi(
  params: Record<'open_hours', any[]>,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/open_hours`,
    method: 'post',
    data: params,
  })
  return response.data
}

export async function updateSpecialHourApi(
  params: Record<'special_hours', any[]>,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/special_hours`,
    method: 'post',
    data: params,
  })
  return response.data
}

/*** ================================  Business Hours  =========================================*/

/*** ================================  Business Setting  =========================================*/
// location_business_id
export async function updateLocationApi(
  formData: FormData,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}`,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

interface Iupdate {
  email?: string
  phone?: string
}
// location_business_id
export async function updatePrefixApi(
  params: Iupdate,
  id: string,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/location_business_settings/${id}`,
    method: 'patch',
    data: {
      marketing_email_prefix: params.email,
      marketing_phone_number: params.phone,
    },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

// location_business_id
export async function validateDomainAuthApi(id: string, locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/location_business_settings/${id}/validateDomainAuthentication`,
    method: 'post',
  })
  return response.data
}
// location_business_id
export async function getBusinessDomainRecords(id: string, locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/location_business_settings/${id}/businessSettingDomainRecords`,
    method: 'get',
  })
  return response.data
}

export async function searchPhoneNumberApi(area_code: string) {
  const response = await axios.request({
    url: `twilio_services/searchPhoneNumber?area_code=${area_code}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function updateLegalEntityApi(
  formData: FormData,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/legal_entity`,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

interface ImailType {
  address_line_1: string
  address_line_2: string
}
export async function updateMailAddressApi(
  param: ImailType,
  locationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/mailing_address`,
    method: 'post',
    data: param,
  })
  return response.data
}

/*** ================================  Business Setting  =========================================*/
