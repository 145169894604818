import { createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import type { TCouponList } from 'types/coupon'

import {
  getCouponList,
  setKeyword,
  setPage,
  setCurrentLocationId,
} from './actions'

type TCouponState = {
  getCouponsRequest: boolean
  coupons: null | TCouponList
  keyword: string
  page: number
  currentLocationId: string
}

const initialState: TCouponState = {
  getCouponsRequest: false,
  coupons: null,
  keyword: '',
  page: 1,
  currentLocationId: '',
}

export const couponReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCouponList.pending, state => {
      state.getCouponsRequest = true
    })
    .addCase(getCouponList.fulfilled, (state, action) => {
      state.getCouponsRequest = false
      state.coupons = action.payload
    })
    .addCase(getCouponList.rejected, (state, action) => {
      state.getCouponsRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(setKeyword, (state, action) => {
      state.keyword = action.payload
    })
    .addCase(setPage, (state, action) => {
      state.page = action.payload
    })
    .addCase(setCurrentLocationId, (state, action) => {
      state.currentLocationId = action.payload
    })
})

export default couponReducer
