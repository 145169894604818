import React from 'react'

import { THoursInDay } from 'types/openHour'
import newid from 'utils/newid'

import DayHoursCollection from './DayHoursCollectionForm'

export type TOpenHoursFormProp = {
  hoursInDay: THoursInDay
  onChange: (hoursInDay: THoursInDay) => void
  className?: string
}

export default function OpenHoursForm({
  hoursInDay,
  onChange,
  className,
}: TOpenHoursFormProp): JSX.Element {
  const onHourChange = (
    index: number,
    day: string,
    name: string,
    value: string,
  ) => {
    const dayHours = hoursInDay[day]
    if (dayHours.length) {
      dayHours[index] = {
        ...dayHours[index],
        [name]: value,
      }
    }
    onChange({ ...hoursInDay, [day]: dayHours })
  }

  const addHours = (day: string) => {
    const item = { id: newid('hours'), day: day, from: '', to: '' }
    const dayHours = [...hoursInDay[day], item]
    onChange({ ...hoursInDay, [day]: dayHours })
  }

  const removeHours = (day: string, index: number) => {
    onChange({
      ...hoursInDay,
      [day]: hoursInDay[day].filter((_, i) => i !== index),
    })
  }

  return (
    <div className={className}>
      {Object.keys(hoursInDay).map(key => (
        <DayHoursCollection
          key={key}
          day={key}
          dailyHours={hoursInDay[key]}
          onHourChange={onHourChange}
          addHours={addHours}
          removeHours={removeHours}
        />
      ))}
    </div>
  )
}
