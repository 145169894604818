import React from 'react'

import Button from 'components/Button'

interface TCreateStripeAccountProps {
  loading: boolean
  onClick?: () => void
}

export default function CreateStripeAccount({
  loading,
  onClick,
}: TCreateStripeAccountProps): JSX.Element {
  return (
    <>
      <div className='w-[640px] leading-snug text-center text-base text-ink mt-16'>
        A Stripe account is required for receiving funds from selling your gift
        cards. If you need any assistance, please contact us at your earliest
        convenience by calling our support line at <b>(855) 979-8860</b> or
        email to <b>support@rushable.io</b>
      </div>
      <Button
        loading={loading}
        className='mt-6'
        color='primary'
        onClick={onClick}
      >
        Create Stripe account
      </Button>
    </>
  )
}
