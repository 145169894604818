import moment from 'moment'

export const getTimeOptions = (
  startTime = '03:00:00',
  endTime = '24:00:00',
  step = 15,
): TOptionType[] => {
  const options = []

  for (let i = 0; i < 1440 / step; i++) {
    const value = moment(startTime, 'HH:mm:ss')
      .add(step * i, 'minutes')
      .format('HH:mm:ss')
    const label = moment(startTime, 'HH:mm:ss')
      .add(step * i, 'minutes')
      .format('h:mm A')
    if (moment(value, 'HH:mm:ss').isAfter(moment(endTime, 'HH:mm:ss'))) {
      break
    }
    options.push({
      value,
      label,
    })
  }
  return options
}
