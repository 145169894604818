import React, { useState } from 'react'

import { Dollar } from '@rushable/icons'
import cn from 'classnames'
import Label from 'components/Label'
import { formatCurrencyString, onMoneyKeyDown } from 'utils/digit'

export type TFieldProp = {
  label?: string // input 外面的label
  disabled?: boolean
  error?: string
  name: string
  pricePlaceholder?: string
  textPlaceholder?: string
  priceValue?: string | number
  textValue?: string | number
  onChangeText?: (e: string) => void
  onChangePrice?: (e: string) => void
  onChangeMoney?: (e: string) => void // 专门针对金额
  maxLength?: number
  className?: string
}

export default function FieldPrice({
  name,
  disabled = false,
  className = '',
  priceValue,
  textValue,
  pricePlaceholder,
  textPlaceholder,
  onChangeText,
  onChangePrice,
  onChangeMoney,
  error = '',
  maxLength,
  label,
}: TFieldProp): JSX.Element {
  const [isFocus, setIsFoucs] = useState(false)

  const style = {
    disabled: 'cursor-not-allowed bg-ice border-ice placeholder-dark-16',
    default: 'text-base w-full py-3 text-ink outline-none bg-transparent',
    foucs: 'border-blue bg-white',
  }

  const inputCls = cn(style.default, disabled ? style.disabled : '')

  const textField = (
    <input
      className={cn(inputCls, 'flex-1')}
      type='text'
      name={`text-${name}`}
      placeholder={textPlaceholder}
      disabled={disabled}
      onChange={e => onChangeText?.(e.target.value)}
      value={textValue}
      maxLength={maxLength}
      onFocus={() => setIsFoucs(true)}
      onBlur={() => setIsFoucs(false)}
    />
  )

  const priceField = (
    <input
      className={cn(inputCls, 'w-[52px] text-right')}
      type='text'
      name={`price-${name}`}
      placeholder={pricePlaceholder}
      disabled={disabled}
      value={priceValue}
      maxLength={maxLength}
      onFocus={() => setIsFoucs(true)}
      onKeyDown={onMoneyKeyDown}
      onChange={e => {
        const value = e.target.value
        onChangeMoney?.(value)
        onChangePrice?.(value)
      }}
      onBlur={e => {
        let value = e.target.value
        if (onChangeMoney && value) {
          if (value.includes('$')) {
            value = value.slice(1, value.length)
          }
          onChangeMoney?.(formatCurrencyString(value))
        }
        setIsFoucs(false)
      }}
    />
  )

  let labelCtrl
  if (label) {
    labelCtrl = (
      <Label
        className='mb-1.5 flex justify-between'
        htmlFor={name}
        color='silver'
      >
        {label}
      </Label>
    )
  }

  return (
    <div className={cn(className)}>
      {labelCtrl}
      <div
        className={cn(
          'flex px-4  border-2 rounded-lg overflow-hidden',
          isFocus ? style.foucs : 'border-field bg-field',
          disabled ? style.disabled : 'hover:bg-white hover:border-blue',
        )}
      >
        {textField}
        <Dollar className='text-silver mx-2' />
        {priceField}
      </div>
      {error && <div className='text-red text-xs text-left mt-1'>{error}</div>}
    </div>
  )
}
