import React, { useState, useEffect } from 'react'

import Field from 'components/Field'
import OptionBox from 'components/OptionBox'
import moment from 'moment'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { getCloverMerchant } from 'redux/integration'

import SkeletonItem from './SkeletonItem'

type TCloverProps = {
  id?: number
}

export default function Clover({ id }: TCloverProps) {
  const { locationId } = useParams<{ locationId: string }>()
  const [loading, setLoading] = useState(false)
  const [cloverDetail, setCloverDetail] = useState<any>('')
  const handleGetCloverMerchant = async () => {
    try {
      setLoading(true)
      const res = await getCloverMerchant(locationId, id)
      if (res?.merchant) {
        setCloverDetail(res?.merchant)
      }
    } catch (err: any) {
      const msg = err.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetCloverMerchant()
  }, [])
  return (
    <div>
      {loading ? (
        <>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </>
      ) : (
        <>
          <Field
            className='mb-6'
            label='Account email'
            name='account_email'
            value={cloverDetail?.owner?.email}
            disabled
          />
          <Field
            className='mb-6'
            label='Integration expiration'
            name='integration_expiration'
            value={
              cloverDetail?.expires_at
                ? moment(cloverDetail?.expires_at).format('MM/DD/YYYY')
                : ''
            }
            disabled
          />
          <div>
            <div className='mb-2 font-bold text-silver text-xs'>
              Connect location account
            </div>
            {cloverDetail ? (
              <OptionBox
                className='mb-2'
                selected
                title={cloverDetail?.name}
                badge={cloverDetail?.id}
                description={`${cloverDetail?.address?.state} ${cloverDetail?.address?.address1}, ${cloverDetail?.address?.city}, ${cloverDetail?.address?.country} ${cloverDetail?.address?.zip}`}
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  )
}
