/**
 * Single Access Setting
 *
 */
import React from 'react'

import Switch from 'components/Switch'

export type TAccessItem = {
  name: string
  id: number
  allowed: boolean
  parent_permission_id: number | null
}
export type TAccessToggleProp = {
  disabled?: boolean
  hasBottom: boolean
  hasTop: boolean
  label: string
  item: TAccessItem
  onChange: (item: TAccessItem, v: boolean) => void
}

export default function AccessToggle({
  disabled,
  hasBottom,
  hasTop,
  label,
  item,
  onChange,
}: TAccessToggleProp): JSX.Element {
  return (
    <div
      className={`text-xs  first:pt-0 last:pb-0 ${
        hasBottom && label !== 'User Management'
          ? 'border-b border-dashed border-zinc'
          : 'pt-0'
      } ${hasTop && 'border-t border-dashed border-zinc'}`}
    >
      {!item.parent_permission_id && label !== 'User Management' ? (
        <div className='flex py-2 justify-between items-center '>
          <h6 className='font-medium'>{item.name}</h6>
          <Switch
            className='py-0'
            disabled={disabled}
            name={item.name}
            checked={!!item.allowed}
            onChange={v => onChange(item, v)}
          />
        </div>
      ) : (
        <ul className='list-disc mb-2'>
          <li className='text-dark-64 ml-[18px] h-4 leading-4'>
            <h6 className='inline-block'>{item.name}</h6>
            <Switch
              className='align-middle float-right	 py-0'
              disabled={disabled}
              name={item.name}
              checked={item.allowed}
              onChange={v => onChange(item, v)}
            />
          </li>
        </ul>
      )}
    </div>
  )
}
