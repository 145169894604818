import ModalFull from 'components/Modal/ModalFull'
import SourceIconRender from 'components/PayIcon/PayIconRender'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

type TResumeModalProps = {
  open: boolean
  toggle: () => void
}

export default function ResumeModal({ open, toggle }: TResumeModalProps) {
  const history = useHistory()
  const { brandId, locationId } = useParams<TParamTypes>()
  const { selectedLocation } = useAppSelector(state => state.location)
  const card_last4 = selectedLocation?.default_payment_method?.card_last4
  const card_name = selectedLocation?.default_payment_method?.card_brand
  const goPayment = () => {
    history.push(
      `/brand/${brandId}/location/${locationId}/billing-and-invoice/billing`,
    )
  }
  return (
    <ModalFull
      open={open}
      title='Resume Error'
      toggle={toggle}
      cancelText='CLOSE'
      okText='UPDATE PAYMENT'
      onCancel={toggle}
      onOk={goPayment}
    >
      <>
        <div className='text-base text-ink leading-6 text-center'>
          You need to have at least 5 token to continue. Please navigate to your
          account billing to check if your payment method is up to date for auto
          recharging your token balance.
        </div>
        <div className='flex justify-center items-center mt-4'>
          {SourceIconRender({
            type: card_name!,
            iconParams: {
              size: 24,
              className: 'mr-2',
            },
          })}
          <span className='text-base text-ink font-bold'>••• {card_last4}</span>
        </div>
      </>
    </ModalFull>
  )
}
