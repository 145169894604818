import React, { useRef, useEffect, useState } from 'react'

import { LogoDark, SolidStore } from '@rushable/icons'
import cn from 'classnames'
import { useAppSelector } from 'redux/hooks'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

type TEmailPreviewProps = {
  enableCta: number
  imgUrl: string
  emailHeadline: string
  emailContent: string
  couponCode: string
  offerType: string
}

export default function EmailPreview({
  enableCta,
  imgUrl,
  emailHeadline,
  emailContent,
  couponCode,
  offerType,
}: TEmailPreviewProps) {
  const { selectedLocation } = useAppSelector(state => state.location)
  const logoImgRef = useRef<HTMLImageElement>(null)
  const [isSquare, setIsSquare] = useState(false)
  let name = ''
  let logoUrl = ''
  if (selectedLocation) {
    name = selectedLocation.brand.name
    logoUrl = parseMediaAbsoluteURL(selectedLocation.brand.logo_url)
  }

  useEffect(() => {
    if (logoImgRef.current) {
      const { width, height } = logoImgRef.current
      width === height ? setIsSquare(true) : setIsSquare(false)
    }
  }, [logoImgRef.current])

  return (
    <div className='pt-10'>
      <div
        className={cn(
          'email-preview w-[440px] max-h-[800px] transform scale-50 origin-top-left overflow-y-auto',
        )}
      >
        <div className='px-8'>
          <div className='pb-4 flex justify-center items-center'>
            {logoUrl ? (
              <img
                ref={logoImgRef}
                src={logoUrl}
                className={cn(isSquare ? 'h-20' : 'h-10')}
                alt={name}
              />
            ) : (
              <div className='flex'>
                <SolidStore className='text-dark-32 mr-2' size={24} />
                <span className='text-2xl font-bold text-dark-100'>{name}</span>
              </div>
            )}
          </div>
          <div className='p-4 rounded-2xl bg-white'>
            {imgUrl && (
              <div
                className='w-full h-[168px] rounded-2xl overflow-hidden bg-slate bg-cover	bg-no-repeat bg-center'
                style={{ backgroundImage: `url(${imgUrl})` }}
              ></div>
            )}
            <div className='pt-4'>
              <div
                className='font-bold text-ink pb-4'
                style={{
                  lineHeight: 1.2,
                  fontSize: 28,
                }}
              >
                {emailHeadline || 'Your email headline will be displayed here'}
              </div>
              <div className='text-sm leading-5 whitespace-pre-line'>
                {emailContent ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, aliquet ullamcorper gravida aliquam rhoncus mattis phasellus magnis. Nibh lectus pharetra, feugiat phasellus.\r\n\r\n Habitant a nisi et blandit commodo. A vel, lacus dui, sodales ut.'}
              </div>
              {offerType === 'coupon_code' && couponCode ? (
                <div className='mt-4 '>
                  <div
                    className='px-4 inline-flex h-10 items-center bg-ice border-r'
                    style={{
                      borderColor: '#eee',
                    }}
                  >
                    Use Code
                  </div>
                  <div
                    className='px-4 inline-flex h-10 items-center bg-ice text-base font-bold'
                    style={{
                      letterSpacing: '2px',
                    }}
                  >
                    {couponCode}
                  </div>
                </div>
              ) : null}
              {!!enableCta || !!offerType ? (
                <div
                  className='mt-4 h-[52px] flex justify-center items-center text-base text-white cursor-pointer rounded-full'
                  style={{
                    backgroundColor: '#000',
                  }}
                >
                  {offerType === 'lucky_spin' ? (
                    <>
                      🎁
                      <span className='pl-2'>REVEAL DEAL</span>
                    </>
                  ) : (
                    <>
                      😋
                      <span className='pl-2'>ORDER NOW</span>
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='px-4'>
          <div className='mt-4 border-b border-zinc'>
            <div className='px-12 text-center text-xs leading-snug'>
              This system email was sent to ryan@rushable.io by Taste of Nation,
              10001 Bellaire Blvd, Suite 800 Houston, TX 77036
            </div>
            <div className='pt-2 pb-4 text-center text-xs font-bold leading-snug'>
              UNSUBSCRIBE
            </div>
          </div>
        </div>
        <div className='flex mt-8 text-sm text-ink justify-center items-center'>
          Powered by
          <LogoDark className='ml-2' />
        </div>
      </div>
    </div>
  )
}
