import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'utils/axiosApi'

export const getMarketingFlyer = createAsyncThunk(
  'flyer/getMarketingFlyer',
  async ({ location_id }: { location_id: string }) => {
    const response = await axios.request({
      url: `locations/${location_id}/marketing_flyers`,
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const editMarketingFlyer = createAsyncThunk(
  'flyer/editMarketingFlyer',
  async ({
    locationId,
    id,
    params,
  }: {
    locationId: string
    id: string
    params: any
  }) => {
    const response = await axios.request({
      url: `locations/${locationId}/marketing_flyers/${id}`,
      data: params,
      method: 'patch',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const getCoupons = createAsyncThunk(
  'flyer/getCoupons',
  async ({
    id,
    marketing_type,
  }: {
    id: string
    marketing_type: 'marketing_flyer' | 'campaign'
  }) => {
    const response = await axios.request({
      url: `locations/${id}/marketing_flyers/location_coupons`,
      method: 'get',
      params: {
        marketing_type,
      },
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const uploadFlyerImg = (file: Blob, id: string, locationId: string) => {
  return async () => {
    const formData = new FormData()
    formData.append('image', file)
    formData.append('marketing_flyer_id', id)
    const response = await axios.request({
      url: `locations/${locationId}/marketing_flyers`,
      data: formData,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
}

export const deleteFlyerImg = (id: string, locationId: string) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/marketing_flyers/${id}`,
      method: 'delete',
    })
    return response.data
  }
}
