import { useState, useEffect } from 'react'

import Button from 'components/Button'
import { Spin } from 'components/Loading'
import ModalFull from 'components/Modal/ModalFull'
import useCopyToClipboard from 'hooks/useCopyToClipboard'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSupportCodeApi } from 'redux/user/actions'
import { formatPhoneNumber } from 'utils/digit'

export type TGetSupportModalProp = {
  open: boolean
  toggle: () => void
}

const defaultData = { phone: '', email: '', code: '' }
export default function GetSupportModal({
  open,
  toggle,
}: TGetSupportModalProp): JSX.Element {
  const { locationId } = useParams<TParamTypes>()
  const [copiedText, copy] = useCopyToClipboard()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(defaultData)

  useEffect(() => {
    if (open) {
      setFormData(defaultData)
      getSupportCode()
    }
  }, [open])

  const getSupportCode = async () => {
    setLoading(true)
    try {
      const res = await getSupportCodeApi(locationId || '')
      setFormData({
        phone: res.phone,
        email: res.email,
        code: res.code,
      })
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const copyText = (str: string) => {
    copy(str)
    toast.success('Copied')
    console.log(copiedText)
  }
  const { phone, email, code } = formData
  const formatPhone = phone ? formatPhoneNumber(phone) : ''
  return (
    <ModalFull
      open={open}
      toggle={toggle}
      title='Get Support'
      footer={
        <div className='w-full flex justify-end'>
          <Button color='primary' onClick={toggle}>
            CLOSE
          </Button>
        </div>
      }
    >
      <div className='border border-solid border-zinc rounded-lg'>
        <Spin spining={loading}>
          <div className='flex flex-col divide-y divide-dashed divide-zinc'>
            <div className='flex flex-col text-center p-4'>
              <div className='text-base text-dark-32'>SUPPORT CALL</div>
              <div
                className='text-2xl text-dark-100 leading-8 font-bold mt-2 cursor-pointer'
                onClick={() => copyText(formatPhone || '')}
              >
                {formatPhone}
              </div>
            </div>
            <div className='flex flex-col text-center p-4'>
              <div className='text-base text-dark-32'>SUPPORT EMAIL</div>
              <div
                className='text-2xl text-dark-100 leading-8 font-bold mt-2 cursor-pointer'
                onClick={() => copyText(email)}
              >
                {email}
              </div>
            </div>
            <div className='flex flex-col text-center p-4'>
              <div className='text-base text-dark-32'>ACCESS CODE</div>
              <div
                className='text-2xl text-green leading-8 font-bold mt-2 cursor-pointer'
                onClick={() => copyText(code)}
              >
                {code}
              </div>
            </div>
          </div>
        </Spin>
      </div>
      <div className='text-center mt-8 text-lead'>
        Upon providing the access code , you allow Rushable team to access your
        location account and operate on your behalf for 48 hours
      </div>
    </ModalFull>
  )
}
