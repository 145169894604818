import { LinedTrash } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'

export type TDayCloseProp = {
  tips?: string
  canDelete?: boolean
  addHours: () => void
  removeDays?: () => void
}
export default function DayClose({
  tips = 'Not Available',
  canDelete = false,
  addHours,
  removeDays,
}: TDayCloseProp): JSX.Element {
  return (
    <div className='flex justify-center items-center h-full space-x-2'>
      <div className='w-[270px] h-10 leading-10'>
        <div className='rounded-lg flex items-center justify-center text-dark-32'>
          {tips}
        </div>
      </div>
      <Button
        className='w-[50px]'
        size='sm'
        color='primary-link'
        onClick={() => addHours()}
      >
        + HOURS
      </Button>
      <Button
        color='ink-link'
        className={cn('ml-2', { 'cursor-pointer': canDelete })}
        onClick={() => {
          if (canDelete && removeDays) {
            removeDays()
          }
        }}
      >
        <LinedTrash size={16} />
      </Button>
    </div>
  )
}
