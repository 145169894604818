import React from 'react'

import { SolidEmail } from '@rushable/icons'
import cn from 'classnames'
import Label from 'components/Label'
import Switch from 'components/Switch'

export type TLabelProp = {
  className?: string
  label?: string
  name: string
  icon?: React.ReactNode
  state?: 'On' | 'Off'
  checked: boolean
  loading?: boolean
  onChange: (checked: boolean) => void
}

export default function ControlToggle({
  className,
  label,
  name,
  icon = <SolidEmail size={16} className='text-silver' />,
  state = 'On',
  checked,
  loading,
  onChange,
}: TLabelProp): JSX.Element {
  return (
    <div className={cn('ru-control-toggle', className)}>
      {label && (
        <Label
          className='mb-1.5 w-full flex justify-between'
          htmlFor={name}
          color='silver'
        >
          {label}
        </Label>
      )}
      <div
        className={cn(
          'h-[46px] px-4 flex items-center justify-between border rounded-lg',
          state === 'On' ? 'bg-white  border-zinc ' : 'bg-field  border-field',
        )}
      >
        <div className='flex items-center'>
          {icon}
          <span className='pl-2 text-base text-dark-100'>{name}</span>
        </div>
        <Switch
          name='smart_review_running'
          loading={loading}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
