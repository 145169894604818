import React from 'react'

import Button from 'components/Button'
import Field from 'components/Field'

export type TForgotPasswordFormProp = {
  handleFormChange: (name: string, value: string) => void
  handleLogin: (e: React.FormEvent<HTMLFormElement>) => void
  password: string
  confirmPassword: string
  gotoLogin: () => void
  request?: boolean
  error: string
}
export default function ForgotPasswordForm({
  handleFormChange,
  handleLogin,
  password,
  confirmPassword,
  gotoLogin,
  request,
  error,
}: TForgotPasswordFormProp): JSX.Element {
  return (
    <form onSubmit={handleLogin}>
      <div className='w-400px mx-auto mt-6'>
        <Field
          label='New password'
          name='password'
          placeholder='Input here'
          type='password'
          className='w-full mb-6'
          onChange={e => handleFormChange('password', e.target.value)}
          value={password}
        />
        <Field
          label='Confirm new password'
          name='confirmPassword'
          placeholder='Input here'
          type='password'
          className='w-full'
          onChange={e => handleFormChange('confirmPassword', e.target.value)}
          value={confirmPassword}
          error={error}
        />
      </div>
      <div className='mt-8 flex gap-4 items-center justify-center'>
        <Button
          color='secondary'
          className='w-full'
          onClick={gotoLogin}
          type='button'
        >
          CANCEL
        </Button>
        <Button
          color='primary'
          className='w-full'
          type='submit'
          loading={request}
          disabled={!(password.length >= 8 && password === confirmPassword)}
        >
          CONFIRM
        </Button>
      </div>
    </form>
  )
}
