import React, { useState, useEffect } from 'react'

import {
  LinedStar,
  LinedGiftCard,
  LinedPaperPlane,
  LinedBriefcase,
  LinedChatComment,
  Loading,
} from '@rushable/icons'
import ModalFull from 'components/Modal/ModalFull'
import currency from 'currency.js'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { openProPackageAPI, getProPackageInfoAPI } from 'redux/brand'

type TProPackageModalProp = {
  open: boolean
  toggle: () => void
  onSuccess: () => void
}

export default function ProPackageModal({
  open,
  toggle,
  onSuccess,
}: TProPackageModalProp): JSX.Element {
  const [isConfirm, setIsConfirm] = useState(false)
  const { brandId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)
  const [infoLoading, setInfoLoading] = useState(false)
  const [proPackageInfo, setProPackageInfo] = useState<any>(null)

  useEffect(() => {
    if (open) {
      setIsConfirm(false)
      getProPackage()
    }
  }, [open])

  const infos = [
    {
      icon: <LinedStar size={16} color='#00A5EB' />,
      title: 'Provide loyalty rewards',
      describe: 'Customers can earn points, gain ranking, and redeem rewards',
    },
    {
      icon: <LinedGiftCard size={16} color='#00A5EB' />,
      title: 'Sell digital gift card',
      describe: 'Customers can purchase and send gift cards on your website',
    },
    {
      icon: <LinedPaperPlane size={16} color='#00A5EB' />,
      title: 'Send email & text marketing',
      describe: 'Send up to 5,000 emails or 1,000 texts per month for free',
    },
    {
      icon: <LinedBriefcase size={16} color='#00A5EB' />,
      title: 'Job board management',
      describe: 'Advertise job openings and draw in potential candidates',
    },
    {
      icon: <LinedChatComment size={16} color='#00A5EB' />,
      title: 'Collect and filter reviews',
      describe: 'Automate review invitations and route negative ones to you',
    },
  ]

  const getProPackage = async () => {
    try {
      setInfoLoading(true)
      const res = await getProPackageInfoAPI(brandId)
      setProPackageInfo(res)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setInfoLoading(false)
    }
  }

  const handleConfirm = async () => {
    try {
      setLoading(true)
      const res = await openProPackageAPI(brandId)
      onSuccess()
      toast.success(res.message)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalFull
      title='Unlock Pro features with an upgrade'
      open={open}
      toggle={toggle}
      loading={loading}
      okBtnDisabled={infoLoading}
      onOk={() => {
        if (isConfirm) {
          handleConfirm()
        } else {
          setIsConfirm(true)
        }
      }}
      onCancel={() => {
        if (isConfirm) {
          setIsConfirm(false)
        } else {
          toggle()
        }
      }}
      cancelText={isConfirm ? 'GO BACK' : 'CANCEL'}
      okText={isConfirm ? 'CONFIRM TO UPGRADE' : 'CONTINUE TO BILLING'}
    >
      <div className='text-ink text-center mb-6'>
        {isConfirm
          ? 'Get access to all the Pro features with just a click! A prorated charge will be applied to each location account today. Please make sure you have a valid payment card in your location settings.'
          : "Rushable's Pro package is a powerful collection of features that can help take your restaurant business to the next level."}
      </div>

      {isConfirm ? (
        <div className='bg-field rounded-lg p-6'>
          <div className='text-lead text-sm mb-2'>Future billing period</div>
          <div className='flex justify-between items-center text-ink text-base'>
            <span>Pro package monthly fee</span>
            <div className='flex-1 mx-2 border-t border-dashed border-zinc'></div>
            <span>
              {currency(proPackageInfo?.monthly_amount, {
                separator: '',
              }).format()}
              /location
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className='flex flex-col space-y-3'>
            {infos.map((item, i) => {
              return (
                <div
                  className='px-4 py-3  bg-field rounded-lg flex items-center'
                  key={i}
                >
                  <div className='mr-3 bg-white w-8 h-8 rounded-full flex justify-center items-center'>
                    {item.icon}
                  </div>
                  <div>
                    <div className='text-base font-medium text-ink flex items-center'>
                      {item.title}
                    </div>
                    <div className='text-sm text-lead'>{item.describe}</div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className='pro-package mt-6'>
            {infoLoading ? (
              <div className='text-ink'>
                <Loading className='mr-2' />
                Loading...
              </div>
            ) : (
              <div className='text-ink'>
                <span>✌️ Special offer:</span>
                <span className='ml-2 mr-1 line-through'>
                  {currency(proPackageInfo?.origin_amount || 0, {
                    separator: '',
                    precision: 0,
                  }).format()}
                  /mo
                </span>
                <span>
                  {proPackageInfo?.monthly_price || '$0/mo'} per location
                </span>
                <span className='ml-1 text-green font-medium'>
                  (save{' '}
                  {proPackageInfo
                    ? (
                        (1 -
                          proPackageInfo.unit_amount /
                            proPackageInfo.origin_amount) *
                        100
                      ).toFixed(0)
                    : 0}
                  %)
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </ModalFull>
  )
}
