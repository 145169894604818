import React, { useState } from 'react'

import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import { toast } from 'react-toastify'
import { getPermissionListAPI } from 'redux/user'

import { selectOptions, helpMeDecideOptions } from '../helpers/data'

export type THelpMeDecideProp = {
  open: boolean
  toggle: () => void
  onFormChange: (name: string, value: any) => void
  name: string
}

export default function HelpMeDecide({
  name,
  open,
  toggle,
  onFormChange,
}: THelpMeDecideProp): JSX.Element {
  const baseOptionsData = {
    user_role: '',
    access_customer: true,
    manager_other_user: true,
    access_marketing: true,
    access_bank_account: true,
  }

  const [optionsData, setOptionsData] =
    useState<Record<string, any>>(baseOptionsData)
  const [loading, setLoading] = useState(false)

  const onOptionsChange = (name: string, value: any) => {
    setOptionsData({
      ...optionsData,
      [name]: value,
    })
  }

  const getPermissionList = async () => {
    setLoading(true)
    try {
      const params = {
        user_role: optionsData.user_role,
        access_customer:
          optionsData.user_role === 'manager'
            ? Number(optionsData.access_customer)
            : undefined,
        manager_other_user:
          optionsData.user_role === 'manager'
            ? Number(optionsData.manager_other_user)
            : undefined,
        access_marketing:
          optionsData.user_role !== 'owner'
            ? Number(optionsData.access_marketing)
            : undefined,
        access_bank_account:
          optionsData.user_role === 'manager'
            ? Number(optionsData.access_bank_account)
            : undefined,
      }
      const res = await getPermissionListAPI(params)
      onFormChange('userPermissions', [...res])
      toggle()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setOptionsData({ ...baseOptionsData })
    setLoading(false)
  }
  return (
    <ModalFull
      open={open}
      title='Access Setting Help'
      toggle={() => {
        toggle()
        setOptionsData({ ...baseOptionsData })
      }}
      onCancel={() => {
        toggle()
        setOptionsData({ ...baseOptionsData })
      }}
      loading={loading}
      okBtnDisabled={optionsData.user_role === ''}
      onOk={() => getPermissionList()}
      okText='SEE RECOMMENDED SETTING'
    >
      <div className='pb-6 border-b border-dashed border-zinc text-ink text-center'>
        {!optionsData.user_role
          ? 'This is a guide to help you decide what accesses are recommended to this user. Answer some questions below to start:'
          : 'This is a guide can help you decide what accesses are recommended to this person, by answering some questions below:'}
      </div>
      <div className='grid gap-6 pt-6'>
        <Select
          label={`What is ${name}’s role at assigned location(s)?`}
          // labelClassName='text-lead font-normal text-base'
          placeholder='Select option'
          value={optionsData.user_role}
          options={helpMeDecideOptions}
          onChange={value => onOptionsChange('user_role', value)}
        />
        {optionsData.user_role && optionsData.user_role !== 'owner' && (
          <>
            {optionsData.user_role === 'manager' && (
              <>
                <Select
                  label={`Will you allow ${name} to manager other user’s access?`}
                  placeholder='Select option'
                  value={optionsData.manager_other_user}
                  options={selectOptions}
                  onChange={value =>
                    onOptionsChange('manager_other_user', value)
                  }
                />
                <Select
                  label={`Will you allow ${name} to access the entire list of your customers?`}
                  placeholder='Select option'
                  value={optionsData.access_customer}
                  options={selectOptions}
                  onChange={value => onOptionsChange('access_customer', value)}
                />
                <Select
                  label={`Will you allow ${name} to manage marketing activities?`}
                  placeholder='Select option'
                  value={optionsData.access_marketing}
                  options={selectOptions}
                  onChange={value => onOptionsChange('access_marketing', value)}
                />
                <Select
                  label={`Will you allow ${name} to update your bank account?`}
                  placeholder='Select option'
                  value={optionsData.access_bank_account}
                  options={selectOptions}
                  onChange={value =>
                    onOptionsChange('access_bank_account', value)
                  }
                />
              </>
            )}
            {optionsData.user_role === 'external' && (
              <>
                <Select
                  label={`Will ${name} be managing any marketing activity?`}
                  placeholder='Select option'
                  value={optionsData.access_marketing}
                  options={selectOptions}
                  onChange={value => onOptionsChange('access_marketing', value)}
                />
              </>
            )}
          </>
        )}
      </div>
    </ModalFull>
  )
}
