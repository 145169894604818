import React, { useEffect, useState } from 'react'

import UnsavedBlockedModal from 'components/UnsavedBlockedModal'
import { Location } from 'history'
import { useHistory, Prompt } from 'react-router-dom'

type TUnsavedPromptProp = {
  when: boolean
}

export default function UnsavedPrompt({
  when,
}: TUnsavedPromptProp): JSX.Element {
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const toggleModal = () => {
    setOpen(!open)
  }
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setOpen(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setOpen(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <UnsavedBlockedModal
        open={open}
        toggle={toggleModal}
        onCancel={toggleModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  )
}
