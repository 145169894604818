import SkeletonText from 'components/Skeleton/SkeletonText'

export default function SkeletonItem(): JSX.Element {
  return (
    <div className='pr-6 w-full'>
      <SkeletonText className='h-[66px] w-full' />
      <SkeletonText className='h-[66px] w-full mt-4' />
      <SkeletonText className='h-2 w-3/4 mt-4' />
      <SkeletonText className='h-2 w-3/4 mt-4' />
    </div>
  )
}
