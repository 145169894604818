import { useEffect, useState } from 'react'

import { Spin } from 'components/Loading'
import SaveChangeBar from 'components/SaveChangeBar'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import cloneDeep from 'lodash/cloneDeep'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import {
  getGeneralSettingApi,
  getPickupConfigApi,
  getCurbsideConfigApi,
  getDineInConfigApi,
  getTakeOutConfigApi,
  getMarketPlaceConfigApi,
  getDeliveryConfigApi,
  toggleOrderTypesApi,
} from 'redux/location'

import DeliveryDisclosure from './components/DeliveryDisclosure'
import MarketPlaceDisclosure from './components/MarketPlaceDisclosure'
import PickupSettingDisclosure from './components/PickupSettingDisclosure'

export default function OrderTypeSettingPage(): JSX.Element {
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [updateConfigRequest, setUpdateConfigRequest] = useState(false)
  const [tabName, setTabName] = useState('')
  // const [activeItem, setActiveItem] = useState('')
  const [formData, setFormData] = useState<Record<string, any>>({})
  const [formDataInit, setFormDataInit] = useState<Record<string, any>>({})
  const { selectedLocation } = useAppSelector(state => state.location)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { locationId } = useParams<TParamTypes>()

  const compareFn = useDebounce(compare)

  useEffect(() => {
    if (selectedLocation && !loading) {
      setLoading(true)
      getGeneralSetting()
    }
  }, [selectedLocation])

  useEffect(() => {
    compareFn(formData, formDataInit, (flag: boolean) => {
      setFormIsDirty(flag)
      setError('')
    })
  }, [formData, formDataInit])

  const getGeneralSetting = async () => {
    setLoading(true)
    setFormIsDirty(false)
    if (selectedLocation?.online_order_config?.id) {
      try {
        const res = await getGeneralSettingApi({
          id: selectedLocation?.online_order_config?.id,
          locationId,
        })
        initFormData(res)
      } catch (e: any) {
        toast.error(e?.message || 'request error')
      }
    }
    setLoading(false)
  }

  const initFormData = (res: any) => {
    setFormData(res)
    setFormDataInit(cloneDeep(res))
  }

  const changeTabName = (value: string) => {
    if (value.includes('In-House') || value.includes('On-Demand')) {
      // setActiveItem(value)
      setFormData(prev => ({ ...prev, activeItem: value }))
    } else {
      resetFormData()
      setTabName(tabName === value ? '' : value)
    }
  }

  const resetFormData = () => {
    setFormData(cloneDeep(formDataInit))
  }

  const submitForm = () => {
    if (['Pickup', 'Curbside', 'Take-out', 'Dine-in'].includes(tabName)) {
      let key = ''
      switch (tabName) {
        case 'Pickup':
          key = 'pickup_config'
          break
        case 'Curbside':
          key = 'curbside_config'
          break
        case 'Take-out':
          key = 'contactless_takeout_config'
          break
        case 'Dine-in':
          key = 'contactless_dinein_config'
          break
      }
      updatePickupConfig(key)
    }
    if (tabName === 'Marketplace') {
      updateMarketPlaceConfig()
    }
    if (tabName === 'Delivery') {
      updateDeliveryConfig()
    }
  }

  const updatePickupConfig = async (key: string) => {
    const {
      id,
      enabled,
      order_min,
      availability,
      prior_to_close,
      open_hours_collection,
    } = formData[key]
    const params = {
      enabled,
      order_min: Number(order_min),
      availability,
      prior_to_close,
      open_hours: null,
    }
    if (availability === 'customize' && open_hours_collection) {
      params.open_hours = open_hours_collection.open_hours
    }

    try {
      setUpdateConfigRequest(true)
      let res: any
      switch (key) {
        case 'pickup_config':
          res = await getPickupConfigApi(id, params, locationId)
          break
        case 'curbside_config':
          res = await getCurbsideConfigApi(id, params, locationId)
          break
        case 'contactless_takeout_config':
          res = await getTakeOutConfigApi(id, params, locationId)
          break
        case 'contactless_dinein_config':
          res = await getDineInConfigApi(id, params, locationId)
          break
      }
      toast.success(res.message)
      getGeneralSetting()
    } catch (e: any) {
      const msg = e?.message || 'request error'
      setError(msg)
    } finally {
      setUpdateConfigRequest(false)
    }
  }

  const updateMarketPlaceConfig = async () => {
    const { id, enabled, links } = formData.marketplace_config || {}
    const linksArr: any[] = []
    links &&
      links.map((item: any) => {
        linksArr.push(item.link)
      })
    const params = {
      enabled,
      links: linksArr,
    }
    try {
      setUpdateConfigRequest(true)
      const res: any = await getMarketPlaceConfigApi(id, params, locationId)
      toast.success(res.message)
      getGeneralSetting()
    } catch (e: any) {
      const msg = e?.message || 'request error'
      setError(msg)
    } finally {
      setUpdateConfigRequest(false)
    }
  }

  const updateDeliveryConfig = async () => {
    const onDemandConfig = formData.doordash_delivery_config || {}
    const inHouseConfig = formData.in_house_config || {}
    const currentDeliveryType = formData.current_delivery_type
    const params = {
      current_delivery_type: currentDeliveryType,
      prioritized_delivery_type: formData.prioritized_delivery_type,
      on_demand: {
        enabled:
          currentDeliveryType === 'on_demand' ||
          currentDeliveryType === 'dynamic'
            ? 1
            : 0,
        order_min: Number(onDemandConfig?.order_min),
        availability: onDemandConfig?.availability,
        prior_to_close: onDemandConfig?.prior_to_close,
        restaurant_pay: Number(onDemandConfig?.restaurant_pay),
        dispatch_fee: onDemandConfig?.dispatch_fee,
        open_hours: onDemandConfig?.open_hours_collection?.open_hours || {},
      },
      in_house: {
        enabled:
          currentDeliveryType === 'in_house' ||
          currentDeliveryType === 'dynamic'
            ? 1
            : 0,
        order_min: Number(inHouseConfig?.order_min),
        availability: inHouseConfig?.availability,
        prior_to_close: inHouseConfig?.prior_to_close,
        deliveries: inHouseConfig?.delivery_fees,
        open_hours: inHouseConfig?.open_hours_collection?.open_hours || {},
      },
    }

    try {
      setUpdateConfigRequest(true)
      const res: any = await getDeliveryConfigApi(params, locationId)
      toast.success(res.message)
      getGeneralSetting()
    } catch (e: any) {
      const msg = e?.message || 'request error'
      setError(msg)
    } finally {
      setUpdateConfigRequest(false)
    }
  }

  const toggleOrderTypes = async (key: string, value: boolean) => {
    const mapKey: any = {
      pickup_config: 'pickup',
      doordash_delivery_config: 'doordash',
      in_house_config: 'in_house',
      marketplace_config: 'marketplace',
      curbside_config: 'curbside',
      contactless_takeout_config: 'contactless_takeout',
      contactless_dinein_config: 'contactless_dinein',
    }
    let params = null
    if (key === 'delivery') {
      params = {
        order_types: {
          delivery: {
            enabled: !!value,
          },
        },
      }
    } else {
      const paramKey = mapKey[key]
      params = {
        order_types: {
          [paramKey]: {
            enabled: !!value,
          },
        },
      }
    }
    setLoading(true)
    try {
      const res: any = await toggleOrderTypesApi(params, locationId)
      toast.success(res.message)
      getGeneralSetting()
    } catch (e: any) {
      const msg = e?.message || 'request error'
      toast.error(msg)
      setLoading(false)
    }
  }

  const changeFormData = (key: string, name: string, value: any) => {
    if (name === 'enabled') {
      toggleOrderTypes(key, value)
      return
    }
    if (name === 'open_hours') {
      let data: any = []
      Object.keys(value).map(key => {
        data = [...data, ...value[key]]
      })
      setFormData({
        ...formData,
        [key]: {
          ...formData[key],
          open_hours_collection: {
            ...formData[key]['open_hours_collection'],
            [name]: data,
          },
        },
      })
    } else if (!key) {
      setFormData({
        ...formData,
        [name]: value,
      })
    } else {
      setFormData({
        ...formData,
        [key]: { ...formData[key], [name]: value },
      })
    }
  }
  return (
    <>
      <UnsavedPrompt when={formIsDirty} />
      {formIsDirty && (
        <SaveChangeBar
          error={error}
          confirmText='SAVE UPDATE'
          onConfirm={submitForm}
          onCancel={resetFormData}
          confirmRequest={updateConfigRequest}
        />
      )}
      <Spin
        spining={loading}
        className='rounded-b-lg'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='py-8'>
          <PickupSettingDisclosure
            name={tabName}
            label='Pickup'
            type='pickup'
            pickupConfig={formData.pickup_config}
            onChange={(name, value) =>
              changeFormData('pickup_config', name, value)
            }
            tabChange={v => changeTabName(v)}
          />
          <DeliveryDisclosure
            name={tabName}
            label='Delivery'
            type='delivery'
            currentDeliveryType={formData.current_delivery_type}
            prioritizedDeliveryType={formData.prioritized_delivery_type}
            onDemandConfig={formData.doordash_delivery_config}
            inHouseConfig={formData.in_house_config}
            onChange={changeFormData}
            tabChange={v => changeTabName(v)}
          />
          <MarketPlaceDisclosure
            name={tabName}
            label='Marketplace'
            type='delivery'
            marketPlaceConfig={formData.marketplace_config}
            tabChange={v => changeTabName(v)}
            onChange={(name, value) =>
              changeFormData('marketplace_config', name, value)
            }
          />
          <PickupSettingDisclosure
            name={tabName}
            label='Take-out'
            type='take-out'
            pickupConfig={formData.contactless_takeout_config}
            onChange={(name, value) =>
              changeFormData('contactless_takeout_config', name, value)
            }
            tabChange={v => changeTabName(v)}
          />
          <PickupSettingDisclosure
            name={tabName}
            label='Dine-in'
            type='dine-in'
            pickupConfig={formData.contactless_dinein_config}
            onChange={(name, value) =>
              changeFormData('contactless_dinein_config', name, value)
            }
            tabChange={v => changeTabName(v)}
          />
        </div>
      </Spin>
    </>
  )
}
