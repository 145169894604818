import { useState } from 'react'

import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import type { TCampaignItem } from 'types/campaign'

import PromotionTooltips from './PromotionTooltips'
import { DEAL_OFFER_OPTIONS } from '../../helpers/constant'
import Line from '../Line'

type TOverviewFormProps = {
  formData: TCampaignItem
}

export default function FinalOverviewForm({ formData }: TOverviewFormProps) {
  const { name } = formData
  const firstCoupon: any = formData?.campaign_offer?.coupons?.[0] || {}
  const secondCoupon: any = formData?.campaign_offer?.coupons?.[1] || {}
  const [emailShow, setEmailShow] = useState(false)
  const handleEmailShow = useDebounce((type: boolean) => {
    setEmailShow(type)
  }, 0)

  return (
    <>
      <div className='mb-2 text-base text-ink font-bold'>Overview</div>
      <div className='border border-zinc rounded-lg p-6'>
        <div className='flex justify-between'>
          <div>
            <div className='text-xs text-silver font-bold pb-2'>
              Campaign name
            </div>
            <div className='text-base text-ink'>{name}</div>
          </div>
          {formData.type === 'one_off' ? (
            <div>
              <div className='text-xs text-silver font-bold pb-2'>
                Sending Schedule
              </div>
              <div className='text-base text-ink'>
                {moment(formData.trigger_of_date_time).format(
                  'h:mma • MM/DD/YYYY',
                )}
              </div>
            </div>
          ) : null}
          <div
            className='mr-20'
            onMouseOver={() => handleEmailShow(true)}
            onMouseOut={() => handleEmailShow(false)}
          >
            <div className='text-xs text-silver font-bold pb-2'>Promotion</div>
            <div className='flex items-center text-base'>
              {formData?.campaign_offer?.type === 'lucky_spin' ? (
                <span className='text-blue font-bold'>Lucky Spin</span>
              ) : (
                <span className='text-ink'>all</span>
              )}
              <div className='relative'>
                {emailShow &&
                formData?.campaign_offer?.type === 'lucky_spin' ? (
                  <div className='pt-2 absolute right-[-50px] top-[-8px] z-10'>
                    <div
                      className='w-[382px] p-4 border border-zinc rounded bg-white'
                      style={{
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      <div className='flex'>
                        <div className='w-[120px]'>
                          <div className='text-lead text-xxs font-medium pb-1'>
                            Promo offer 1
                          </div>
                          <div className='text-ink text-xs'>
                            {
                              DEAL_OFFER_OPTIONS.find(
                                v => v.value === firstCoupon.type,
                              )?.label
                            }
                          </div>
                        </div>
                        <div className='w-[100px]'>
                          <div className='text-silver text-xxs font-medium pb-1'>
                            Order minimum
                          </div>
                          <div className='text-ink text-xs'>{`$${firstCoupon.threshold}`}</div>
                        </div>
                        <div className='flex-1'>
                          <PromotionTooltips coupon={firstCoupon} />
                        </div>
                      </div>
                      <div className='flex mt-4'>
                        <div className='w-[120px]'>
                          <div className='text-lead text-xxs font-medium pb-1'>
                            Promo offer 2
                          </div>
                          <div className='text-ink text-xs'>
                            {
                              DEAL_OFFER_OPTIONS.find(
                                v => v.value === secondCoupon.type,
                              )?.label
                            }
                          </div>
                        </div>
                        <div className='w-[100px]'>
                          <div className='text-silver text-xxs font-medium pb-1'>
                            Order minimum
                          </div>
                          <div className='text-ink text-xs'>{`$${secondCoupon.threshold}`}</div>
                        </div>
                        <div className='flex-1'>
                          <PromotionTooltips coupon={secondCoupon} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Line className='my-4' />
        <div className='flex justify-between'>
          <div>
            <div className='text-xs text-silver font-bold pb-2'>Audience</div>
            <div className='text-base text-ink'>
              {formData?.campaign_audience?.conditional_type ===
              'lifetime_order_count' ? (
                <>
                  Subscribers that have placed{' '}
                  <span className='underline'>
                    {formData.campaign_audience.lifetime_order_count_operator}
                  </span>{' '}
                  than{' '}
                  <span className='underline'>
                    {formData.campaign_audience.lifetime_order_count}
                  </span>{' '}
                  order in lifetime.
                </>
              ) : formData?.campaign_audience?.conditional_type ===
                'recent_order_within' ? (
                <>
                  Subscribers that have Ordered within{' '}
                  <span className='underline'>
                    {formData.campaign_audience.recent_order_within}
                  </span>{' '}
                  <span className='underline'>
                    {formData.campaign_audience.recent_order_within_unit}
                  </span>{' '}
                  recently.
                </>
              ) : (
                ' All subscribers'
              )}
            </div>
          </div>
          <div className='mr-6'>
            <div className='text-xs text-silver font-bold pb-2'>
              Total reach
            </div>
            <div className='text-base text-ink'>
              {formData?.campaign_performance?.estimated_audience} Customers
            </div>
          </div>
        </div>
        <div className='mt-4 mb-2 bg-field rounded-lg p-4'>
          <div className='flex justify-between pb-2 relative'>
            <div className='text-xs text-ink font-bold'>
              Estimate email reach
            </div>
            <div className='text-xs text-lead'>
              {formData?.campaign_performance?.estimated_email_audience}/
              {formData?.campaign_performance?.estimated_audience} customers •{' '}
              {formData?.campaign_performance?.estimated_email_token} token
            </div>
            <div className='rounded-sm bg-zinc h-[2px] absolute w-full bottom-0' />
            <div
              className='rounded-sm bg-blue h-[2px] absolute bottom-0'
              style={{
                width: `${
                  ((formData?.campaign_performance?.estimated_email_audience ||
                    0) /
                    (formData?.campaign_performance?.estimated_audience || 0)) *
                  100
                }%`,
              }}
            />
          </div>
          <div className='flex justify-between pb-2 pt-4 relative'>
            <div className='text-xs text-ink font-bold'>
              Estimate text reach
            </div>
            <div className='text-xs text-lead'>
              {formData?.campaign_performance?.estimated_sms_audience}/
              {formData?.campaign_performance?.estimated_audience} customers •{' '}
              {formData?.campaign_performance?.estimated_sms_token} token
            </div>
            <div className='rounded-sm bg-zinc h-[2px] absolute w-full bottom-0' />
            <div
              className='rounded-sm bg-blue h-[2px] absolute bottom-0'
              style={{
                width: `${
                  ((formData?.campaign_performance?.estimated_sms_audience ||
                    0) /
                    (formData?.campaign_performance?.estimated_audience || 0)) *
                  100
                }%`,
              }}
            />
          </div>
        </div>
        <div className='text-orange text-xs'>
          Estimate as of today and will vary upon your actual sending schedule
        </div>
      </div>
    </>
  )
}
