import React from 'react'

import Button from 'components/Button'
import EmptyIcon from 'components/EmptyIcon'

interface TJobEmpty {
  status?: string
  handleCreateJob: () => void
}

export default function JobEmpty({
  status,
  handleCreateJob,
}: TJobEmpty): JSX.Element {
  return (
    <div className='flex flex-col items-center mx-auto'>
      <p className='text-center text-base text-lead mt-[112px] mb-8'>
        You don’t have any {status ? `${status} ` : ''}job listing yet...
      </p>
      <EmptyIcon />
      {!status && (
        <Button
          className='mt-6'
          color='primary'
          onClick={() => handleCreateJob()}
        >
          CREATE JOB LISTING
        </Button>
      )}
    </div>
  )
}
