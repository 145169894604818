import React from 'react'

import cn from 'classnames'

type TTipsProps = {
  text?: string
  status?: string
  className?: string
  children?: string | React.ReactElement | React.ReactElement[]
}

export default function Tips({
  text,
  status = 'error',
  className,
  children,
}: TTipsProps): JSX.Element {
  return (
    <div
      className={cn(
        className,
        'py-3 px-4 flex items-center rounded-lg  text-base',
        `${
          status === 'success'
            ? 'bg-green-opacity-4 text-green'
            : 'bg-red-opacity-4 text-red'
        }`,
      )}
    >
      {text}
      {children}
    </div>
  )
}
