import React, { useRef } from 'react'

import { ActionMenu } from '@rushable/icons'
import Button from 'components/Button'
import useOnClickOutside from 'hooks/useOnClickOutside'

export type TMenuActionProp = {
  size?: number
  open: boolean
  className?: string
  hasDetail?: boolean
  hasDelete?: boolean
  onChange?: (value: any) => void
  clickOutside?: () => void
}

export default function MenuAction({
  size = 16,
  className = '',
  open,
  onChange,
  hasDetail = true,
  hasDelete = true,
  clickOutside,
}: TMenuActionProp): JSX.Element {
  const ref = useRef(null)

  const handleClickOutside = () => {
    clickOutside && clickOutside()
  }
  useOnClickOutside(ref, handleClickOutside)

  return (
    <div className='relative'>
      <Button color='primary-link' onClick={() => onChange?.('open')}>
        <ActionMenu
          className={` text-ink cursor-pointer p-0.5 rounded hover:bg-zinc ${className}`}
          size={size}
        />
      </Button>
      {open && (
        <div
          ref={ref}
          className='z-[100] cursor-pointer flex flex-col py-2 absolute top-6 border border-solid border-zinc bg-white w-32 text-base font-normal rounded-lg'
        >
          <div className='pl-8 text-xxs-l text-silver font-bold my-1'>
            ACTIONS
          </div>
          {hasDetail && (
            <div
              className='pl-4 py-2 cursor-pointer hover:bg-field'
              onClick={() => onChange?.('edit')}
            >
              Detail / Edit
            </div>
          )}
          {hasDelete && (
            <div
              className='pl-4 py-2 cursor-pointer hover:bg-field text-red'
              onClick={() => onChange?.('delete')}
            >
              Delete
            </div>
          )}
        </div>
      )}
    </div>
  )
}
