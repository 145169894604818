import { ReactElement, useEffect, useRef } from 'react'

import Waterfall from './index'

interface Iprops {
  children?: ReactElement[]
  columnWidth: number
  columnCount: number
  gap: number
  onChangeUlMaxH?: (h: number) => void
}
export default function WaterFall({
  children,
  columnWidth,
  columnCount,
  gap,
  onChangeUlMaxH,
}: Iprops): JSX.Element {
  const wfRef = useRef<any>()

  useEffect(() => {
    if (wfRef.current) {
      return
    }
    wfRef.current = new Waterfall({
      el: '#waterfall',
      width: columnWidth,
      gap: gap,
      columnCount: columnCount,
      delay: 500,
      customStyle: '',
      onChangeUlMaxH: h => {
        onChangeUlMaxH?.(h)
      },
    })
    wfRef.current.init()
  }, [])

  useEffect(() => {
    if (children?.length) {
      setTimeout(() => {
        wfRef.current.loadMore()
      }, 100)
    }
  }, [children?.length])

  if (children?.length === 0) {
    return <ul id='waterfall' />
  }
  return <ul id='waterfall'>{children}</ul>
}
