import { useEffect, useMemo, useState } from 'react'

import { SolidUser, SolidTrashCan } from '@rushable/icons'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import Field from 'components/Field'
import { Select } from 'components/Select'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getJobPostDataApi,
  getJobCategoriesApi,
  getJobTitlesApi,
} from 'redux/recruiting'

import Skeleton from './Skeleton'
import { TJobPostData, STATUS_OPTIONS } from '../helps/constant'

interface TPostJobDrawer {
  id: null | number
  open: boolean
  confirmLoading: boolean
  deactivateLoading: boolean
  toggle: () => void
  handleConfirmJob: (params: any) => void
  handleDeleteJob: (id: number) => void
}

const defaultFormData = {
  id: null,
  code: null,
  status: '',
  job_category_id: null,
  title: '',
}

export default function PostJobDrawer({
  id,
  open,
  confirmLoading,
  deactivateLoading,
  toggle,
  handleConfirmJob,
  handleDeleteJob,
}: TPostJobDrawer): JSX.Element {
  const { locationId, brandId } =
    useParams<{ locationId: string; brandId: string }>()
  const history = useHistory()
  const [formData, setFormData] = useState<TJobPostData>(defaultFormData)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [categoryOptions, setCategoryOptions] = useState<
    {
      label: string
      value: string | number
    }[]
  >([])
  const [titleOptions, setTitleOptions] = useState<
    {
      label: string
      value: string | number
    }[]
  >([])

  const renderTitleOptions = useMemo(() => {
    if (title) {
      const createOption = {
        label: title,
        value: title,
        optionChildren: (
          <div className='text-base text-dark-100'>
            + Create “<span className='text-blue'>{title}</span>” job
          </div>
        ),
      }
      // 判断列表里有没有相同的值，有的话，不需要 create
      let hasTitle = false
      titleOptions.forEach(option => {
        if (title === option.label) {
          hasTitle = true
        }
      })
      const list = titleOptions.map(option => {
        let label = option.label
        const reg = new RegExp(title, 'gi')
        label = label.replace(reg, function (txt: any) {
          return `<span class="text-blue">${txt}</span>`
        })

        return {
          label: option.label,
          value: option.value,
          optionChildren: (
            <div dangerouslySetInnerHTML={{ __html: label }}></div>
          ),
        }
      })
      return hasTitle ? list : list.concat(createOption)
    }
    return titleOptions
  }, [title, formData.title, titleOptions])

  const changeFormData = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value })
  }

  const getJobCategories = async () => {
    try {
      const res = await getJobCategoriesApi(locationId)
      if (res) {
        setCategoryOptions(
          res.map((cat: any) => {
            return {
              label: cat.name,
              value: cat.id,
            }
          }),
        )
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
  }

  const getJobTitles = async () => {
    try {
      const res = await getJobTitlesApi(brandId)
      if (res) {
        setTitleOptions(
          res.map((item: any) => {
            return {
              label: item.title,
              value: item.title,
            }
          }),
        )
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
  }

  const getJobPostData = async () => {
    try {
      setLoading(true)
      const res = await getJobPostDataApi(locationId, id!)
      setFormData({
        id: res?.id,
        code: res?.code,
        status: res?.status,
        job_category_id: res?.job_category_id,
        title: res?.title,
      })
      setCount(res?.job_applications_count || 0)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open && id) {
      getJobPostData()
    } else {
      setFormData(defaultFormData)
    }
    if (open) {
      getJobCategories()
      getJobTitles()
    }
  }, [open, id])

  const confirmNoDisabled =
    formData.status && formData.title && formData.job_category_id

  return (
    <Drawer
      open={open}
      toggle={toggle}
      title='Job Listing'
      leftFooter={
        id ? (
          <Button
            color='secondary-link'
            loading={deactivateLoading}
            onClick={() => handleDeleteJob(id)}
          >
            <div className='flex'>
              {deactivateLoading ? null : (
                <SolidTrashCan size={16} className='mr-2' />
              )}
              DEACTIVATE
            </div>
          </Button>
        ) : (
          <></>
        )
      }
      rightFooter={
        <>
          <Button color='secondary' onClick={toggle}>
            CANCEL
          </Button>
          <Button
            color='primary'
            loading={confirmLoading}
            disabled={!confirmNoDisabled}
            onClick={() => {
              if (id && count > 0) {
                handleConfirmJob({
                  id: formData.id,
                  status: formData.status,
                })
              } else {
                handleConfirmJob(formData)
              }
            }}
          >
            CONFIRM
          </Button>
        </>
      }
    >
      {id && loading ? (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <div className='mt-2 flex flex-col space-y-6'>
          <Select
            options={STATUS_OPTIONS}
            value={formData.status}
            label='Job listing status'
            onChange={value => changeFormData('status', value)}
          />
          {formData.code ? (
            <Field
              label='Job code'
              name='job_code'
              value={formData.code || ''}
              type='text'
              disabled={!!id}
            />
          ) : null}
          <Select
            options={categoryOptions}
            value={formData.job_category_id}
            label='Job category'
            disabled={!!id && count > 0}
            onChange={value => changeFormData('job_category_id', value)}
          />
          <Select
            options={renderTitleOptions}
            value={formData.title}
            label='Job title'
            disabled={!!id && count > 0}
            onChange={value => changeFormData('title', value)}
            selectProps={{
              onInputChange: (a: string) => setTitle(a),
            }}
          />
          {count ? (
            <Button
              color='tertiary'
              onClick={() =>
                history.push(
                  `/brand/${brandId}/location/${locationId}/recruiting/candidates?keyword=${formData.code}`,
                )
              }
            >
              <div className='flex text-ink font-bold'>
                <SolidUser size={16} className='mr-1' />
                View {count} Applicant{count > 1 ? 's' : ''}
              </div>
            </Button>
          ) : null}
        </div>
      )}
    </Drawer>
  )
}
