import React, { useEffect, useState, useRef } from 'react'

import { Plus } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getGallerriesApi } from 'redux/brand/actions'

import GalleryImage from './components/GalleryImage'
import ModalForm from './components/ModalForm'

const GalleryPosting = (): JSX.Element => {
  const { brandId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState<any[]>([])
  const [visible, setIsVisible] = useState(false)
  const tableRowDataRef = useRef<Record<string, any>>({})

  useEffect(() => {
    getGallerries()
  }, [])

  const getGallerries = async () => {
    setLoading(true)
    try {
      const res = await getGallerriesApi(brandId)
      setTableData(res || [])
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <AdminPage>
      <AdminPageTitle
        title='Gallery'
        right={
          <Button
            color='primary-link'
            onClick={() => {
              tableRowDataRef.current = {}
              setIsVisible(true)
            }}
          >
            <Plus size={16} className='text-blue' />
            <span className='ml-2 text-base font-bold text-blue'>
              ADD IMAGE
            </span>
          </Button>
        }
      />
      <div className='px-16 py-8'>
        <Spin spining={loading}>
          <div className='m-auto min-h-[320px] flex justify-center flex-wrap'>
            {tableData.map((row, index) => {
              return (
                <GalleryImage
                  className='m-2 w-[236px] h-[158px] '
                  key={index}
                  url={row.image_url || ''}
                  onChange={() => {
                    tableRowDataRef.current = {
                      id: row.id,
                      description: row.description,
                      image: row.image_url,
                    }
                    setIsVisible(true)
                  }}
                />
              )
            })}
            {[null, null, null, null].map((v, i) => (
              <i className='m-2 w-[236px]' key={i} />
            ))}
          </div>
        </Spin>
      </div>
      <ModalForm
        visible={visible}
        rowData={tableRowDataRef.current}
        toggle={() => setIsVisible(false)}
        onSuccess={() => {
          setIsVisible(false)
          getGallerries()
        }}
      />
    </AdminPage>
  )
}

export default GalleryPosting
