import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'

export type TUnsavedBlockedModalProp = {
  open: boolean
  toggle: () => void
  onConfirm: () => void
  onCancel: () => void
}

export default function UnsavedBlockedModal({
  open,
  toggle,
  onConfirm,
  onCancel,
}: TUnsavedBlockedModalProp): JSX.Element {
  return (
    <ModalFull
      open={open}
      title='Unsaved Change'
      toggle={toggle}
      footer={
        <div className='flex justify-between w-full'>
          <Button color='secondary' className='flex-1 mr-4' onClick={onConfirm}>
            LEAVE WITHOUT SAVING
          </Button>
          <Button color='primary' className='flex-1' onClick={onCancel}>
            GO BACK TO EDIT
          </Button>
        </div>
      }
    >
      <div className='text-center'>
        It looks like you have been editing something. If you leave before
        saving, your changes will be lost.
      </div>
    </ModalFull>
  )
}
