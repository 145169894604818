import luckySpinGamePng from 'assets/img/lucky-spin-game.png'
import Button from 'components/Button'
import Drawer from 'components/Drawer'

type THowWorksDrawerProps = {
  open: boolean
  toggle: () => void
}

export default function HowWorksDrawer({ open, toggle }: THowWorksDrawerProps) {
  return (
    <Drawer
      open={open}
      toggle={toggle}
      rightFooter={<Button onClick={toggle}>CLOSE</Button>}
    >
      <div className='flex flex-col items-center'>
        <img src={luckySpinGamePng} width='320' className='my-6' />
        <div className='text-base font-bold text-ink'>
          How “Lucky Spin” works
        </div>
        <div className='w-[420px] mt-6'>
          <div className='flex text-base text-ink leading-6'>
            <div className='mr-1'>1.</div>
            <div>
              Setup 2 deal offers to be included in the campaign you are
              currently configuring.
            </div>
          </div>
          <div className='flex text-base text-ink leading-6'>
            <div className='mr-1'>2.</div>
            <div>
              Your customer will receive an email/text with this lucky spin game
              included.
            </div>
          </div>
          <div className='flex text-base text-ink leading-6'>
            <div className='mr-1'>3.</div>
            <div>
              Despite it is a “luck” game, the winning ratio for your customer
              is set to be 100%.
            </div>
          </div>
          <div className='flex text-base text-ink leading-6'>
            <div className='mr-1'>4.</div>
            <div>
              Once your customer won the deal, they can redeem it within the
              time period you set for them.
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
