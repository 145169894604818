import SkeletonText from 'components/Skeleton/SkeletonText'

export default function SkeletonItem(): JSX.Element {
  return (
    <div className=''>
      <SkeletonText className='h-[56px] w-full' />
      <SkeletonText className='h-[56px] w-full mt-4' />
      <SkeletonText className='h-[56px] w-full mt-4' />
      <SkeletonText className='h-[56px] w-full mt-4' />
      <SkeletonText className='h-[56px] w-full mt-4' />
      <SkeletonText className='h-[56px] w-full mt-4' />
    </div>
  )
}
