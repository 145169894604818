import React from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import CustomerDataListPage from './components/CustomerDataListPage'
import CustomerDetailPage from './components/CustomerDetailPage'

export default function CustomerDataPage(): JSX.Element {
  const { path } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <CustomerDataListPage />
        </Route>
        <Route exact path={`${path}/customer-detail/:orderId`}>
          <CustomerDetailPage />
        </Route>
      </Switch>
    </>
  )
}
