// timeline list
export const timelineList = [
  {
    label: '1',
    title: 'User Profile',
  },
  {
    label: '2',
    title: 'Assign Location',
  },
  {
    label: '3',
    title: 'Access Setting',
  },
]

// user account types
export const accountTypeOptions = [
  {
    label: 'Brand Admin',
    value: 'brand_admin',
  },
  {
    label: 'Location Admin',
    value: 'location_owner',
  },
]

// user account types
export const selectOptions = [
  {
    label: 'YES',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const helpMeDecideOptions = [
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Manager or team lead',
    value: 'manager',
  },
  {
    label: 'External service provider',
    value: 'external',
  },
]
