import { createReducer } from '@reduxjs/toolkit'
import {
  TReviewFormData,
  TCommentModal,
  TReviewConfig,
  TReviewComment,
} from 'types/review'
import { TSortData } from 'types/table'

import {
  setFormData,
  setSortData,
  setCommentModal,
  setPageLoading,
  setReviewConfig,
  setTableData,
} from './actions'

type TSmartReviewState = {
  lockModalOpen: boolean
  formData: TReviewFormData
  sortData: TSortData
  commentModal: TCommentModal
  pageLoading: boolean
  reviewConfig: TReviewConfig | null
  tableData: {
    total: number
    data: TReviewComment[]
  }
}

const initialState: TSmartReviewState = {
  lockModalOpen: false,
  formData: {
    keyword: '',
    impressions: ['good', 'bad_and_not_contacted', 'bad_and_contacted'],
    ranges: [
      {
        startDate: '',
        endDate: '',
        key: 'dateRange',
      },
    ],
    page: 1,
    pageSize: 20,
  },
  sortData: {
    sort_by: '',
    sort: '',
  },
  commentModal: {
    open: false,
    data: null,
  },
  pageLoading: true,
  reviewConfig: null,
  tableData: {
    total: 0,
    data: [],
  },
}

export const smartReviewReducer = createReducer(initialState, builder => {
  builder
    .addCase(setFormData, (state, actions) => {
      state.formData = actions.payload
    })
    .addCase(setSortData, (state, actions) => {
      state.sortData = actions.payload
    })
    .addCase(setCommentModal, (state, actions) => {
      state.commentModal = actions.payload
    })
    .addCase(setPageLoading, (state, actions) => {
      state.pageLoading = actions.payload
    })
    .addCase(setReviewConfig, (state, actions) => {
      state.reviewConfig = actions.payload
    })
    .addCase(setTableData, (state, actions) => {
      state.tableData = actions.payload
    })
})

export default smartReviewReducer
