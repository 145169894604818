import React from 'react'

import cn from 'classnames'
import currency from 'currency.js'

export type TMoneyAccountingProp = {
  value: string | number
  type?: 'empty' | 'normal' | 'bold'
  size?: 'lg' | 'md' | 'sm'
  className?: string
  reject?: boolean
  plusSymbol?: boolean
}

export default function MoneyAccounting({
  size = 'md',
  type = 'normal',
  value,
  className = 'max-w-max',
  reject,
  plusSymbol = false,
}: TMoneyAccountingProp): JSX.Element {
  return (
    <div
      className={cn(
        { 'font-bold': type === 'bold' },
        { 'text-sm': size === 'sm' },
        { 'text-base': size === 'md' },
        { 'text-xl': size === 'lg' },
      )}
    >
      {type !== 'empty' && (
        <div className={cn('inline-flex justify-between', className)}>
          <span className='mr-1'>$</span>
          <div className={`${reject && 'line-through'}`}>
            {plusSymbol && Number(value) > 0 ? '+' : ''}
            {Number(value)
              ? currency(value, { symbol: '', separator: '' }).format()
              : '-'}
          </div>
        </div>
      )}
    </div>
  )
}
