import React from 'react'

import Button from 'components/Button'
import { SELECTRESTAURANT } from 'data/routes'
import { Link } from 'react-router-dom'

export default function NoMatch() {
  return (
    <div className='text-center py-24'>
      <h2 className='text-5xl'>Page Not Found</h2>
      <div className='flex justify-center my-8'>
        <Button color='primary'>
          <Link to={SELECTRESTAURANT}>Go to the home page</Link>
        </Button>
      </div>
    </div>
  )
}
