import React from 'react'

import BaseOrderTypeConfig from './BaseOrderTypeConfig'
import InHouseDeliverFee from './InHouseDeliverFee'

type TInHouseDeliveryProps = {
  className?: string
  label: string
  inHouseConfig: any
  onChange: (name: string, value: any) => void
}

export default function InHouseDelivery({
  className,
  label,
  inHouseConfig,
  onChange,
}: TInHouseDeliveryProps) {
  const { order_min, availability, prior_to_close, delivery_fees } =
    inHouseConfig || {}
  return (
    <div className={className}>
      <div className='pb-6 text-dark-100 text-base font-bold'>
        In-house delivery setting
      </div>
      <InHouseDeliverFee deliveryFees={delivery_fees} onChange={onChange} />
      <BaseOrderTypeConfig
        label={label}
        formData={{
          order_min,
          availability,
          prior_to_close,
        }}
        onFormChange={(name, value) => onChange(name, value)}
      />
    </div>
  )
}
