import { Plus } from '@rushable/icons'
import moment from 'moment'

interface Iprops {
  list: any[]
  onChange: (date: string) => void
}

export default function HolidaySelector({
  list,
  onChange,
}: Iprops): JSX.Element {
  return (
    <ul className='mr-8'>
      {list.map((item, index) => {
        const weekName = moment(item.date).format('dddd')
        return (
          <li
            key={index}
            className='relative z-0 box-border rounded-lg border border-zinc p-3 mt-4 first:mt-0'
          >
            {item.disabled && (
              <div className='absolute bg-opacity-[0.84] bg-white rounded-lg left-[-4px] top-[-4px] right-[-4px] bottom-[-4px] z-10'></div>
            )}
            <div
              className='flex justify-between item-center cursor-pointer'
              onClick={() => onChange(item.date)}
            >
              <div>
                <div className='mb-2 text-base text-ink font-bold'>
                  {item.name}
                </div>
                <div className='flex items-center'>
                  <div className='rounded text-xs bg-field text-lead px-2 py-0.5'>
                    {weekName}
                  </div>
                  <div className='text-sm text-lead ml-2'>
                    {moment(item.date).format('MMM DD, YYYY')}
                  </div>
                </div>
              </div>
              <Plus size={16} className='text-blue' />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

{
  /* <div className='flex'>
    <div className='flex'>
      <div
        className='py-[3px] px-[6px] rounded bg-field text-lead'
        style={{ zoom: 0.5, fontSize: '20px' }}
      >
        {weekName}
      </div>
      <div className='text-base text-lead ml-2'>
        {moment(item.date).format('MMMM DD, YYYY')}
      </div>
    </div>
  </div> */
}
