import React from 'react'

import { LogoDark } from '@rushable/icons'
import { ToastContainer, toast } from 'react-toastify'

type TSimpleLayoutProps = {
  children: React.ReactElement
}
export default function SimpleLayout({
  children,
}: TSimpleLayoutProps): JSX.Element {
  return (
    <div className='bg-slate min-h-screen w-full overflow-y-scroll'>
      <div className='mx-auto flex flex-1 justify-center pt-16 mb-8'>
        <LogoDark size={174.67} />
      </div>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
      {children}
    </div>
  )
}
