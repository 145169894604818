import React from 'react'

import cn from 'classnames'
import Label from 'components/Label'
import Select from 'react-select'

import {
  IOptions,
  customTableFilterStyles,
  customTheme,
  Control,
  IndicatorsContainer,
  customSingleOption,
} from './help/index'

const getFormatValue = (value: any, options: IOptions[]) => {
  return options.find(item => item.value === value)
}

export type TFormSelect = {
  className?: string
  label?: string | React.ReactElement
  controlIcon?: React.ReactElement
  indicator?: React.ReactElement
  disabled?: boolean
  options: IOptions[] | []
  placeholder?: string
  value?: any | any[]
  defaultValue?: any | any[]
  onChange: (item: any, rest: any) => void
  isMulti?: boolean
  isPerson?: boolean
  hideIndicator?: boolean
}

export default function FormSelect({
  className = '',
  label,
  disabled,
  options = [],
  placeholder = '',
  value,
  defaultValue = '',
  onChange,
}: TFormSelect) {
  const formatValue = getFormatValue(value, options)
  const formatDefaultValue = getFormatValue(defaultValue, options)

  const handleChange = (item: IOptions) => {
    onChange && onChange(item.value, item)
  }
  return (
    <div className={cn(className, 'w-full')}>
      {label && <Label className='mb-2'>{label}</Label>}
      <Select<IOptions>
        // menuIsOpen={true}
        isDisabled={disabled}
        options={options}
        value={value ? formatValue : undefined}
        defaultValue={formatDefaultValue}
        onChange={handleChange as any}
        placeholder={placeholder}
        backspaceRemovesValue={true}
        isClearable={false}
        hideSelectedOptions={false}
        components={{
          Control,
          // @ts-ignore
          Option: customSingleOption,
          // @ts-ignore
          IndicatorsContainer,
        }}
        theme={customTheme}
        styles={customTableFilterStyles}
      />
    </div>
  )
}
