import { createAsyncThunk } from '@reduxjs/toolkit'
import { TLocationAnnouncementConfig } from 'types/announcement'
import { TGetLocationProp } from 'types/location'
import axios from 'utils/axiosApi'

export const getLocationAnnouncement = createAsyncThunk(
  'announcement/getLocation',
  async ({ location_id }: TGetLocationProp) => {
    const response = await axios.request({
      url: `locations/${location_id}/location_announcements`,
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const createAnnouncement = createAsyncThunk(
  'announcement/create',
  async ({ params, locationId }: TLocationAnnouncementConfig, thunkApi) => {
    const response = await axios.request({
      url: `locations/${locationId}/location_announcements`,
      method: 'post',
      data: params,
      cache: {
        ignoreCache: true,
      },
    })
    if (response) {
      thunkApi.dispatch(
        getLocationAnnouncement({ location_id: Number(locationId) }),
      )
    }
    return response.data
  },
)

export const editAnnouncement = createAsyncThunk(
  'announcement/edit',
  async ({ params, locationId }: TLocationAnnouncementConfig) => {
    const response = await axios.request({
      url: `locations/${locationId}/location_announcements/${params.id}`,
      method: 'post',
      params: {
        _method: 'patch',
      },
      data: params,
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const deleteAnnouncement = createAsyncThunk(
  'announcement/delete',
  async ({ id, locationId }: { id: number; locationId?: string }) => {
    const response = await axios.request({
      url: `locations/${locationId}/location_announcements/${id}`,
      method: 'delete',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)
