import React from 'react'

import { SortDown, SortUp } from '@rushable/icons'
import cn from 'classnames'

type TTableColumnLabelProps = {
  className?: string
  name?: string
  sort?: string
  onOptionChange?: (value: string) => void
}

export default function TableColumnLabel({
  className,
  name,
  sort,
  onOptionChange,
}: TTableColumnLabelProps) {
  const handleOptionChange = () => {
    if (sort === 'ASC') {
      onOptionChange?.('DESC')
    } else if (sort === 'DESC') {
      onOptionChange?.('')
    } else if (!sort) {
      onOptionChange?.('ASC')
    }
  }
  return (
    <div
      className={cn(
        className,
        'flex items-center whitespace-nowrap text-xs font-bold cursor-pointer',
      )}
      onClick={handleOptionChange}
    >
      <div className='text-silver'>{name}</div>
      <div className='flex flex-col items-center ml-1'>
        <SortUp
          className={`${sort === 'ASC' ? 'text-blue' : 'text-silver'}`}
          size={6}
        />
        <SortDown
          className={`${sort === 'DESC' ? 'text-blue' : 'text-silver'}`}
          size={6}
        />
      </div>
    </div>
  )
}
