import { useState, useEffect } from 'react'

import cn from 'classnames'
import OpenHoursForm from 'components/OpenHoursForm'
import { Select } from 'components/Select'
import {
  DELIVERY_METHOD_OPTIONS,
  PRIORITIZED_METHOD_OPTIONS,
} from 'pages/orderSetting/helpers/constant'
import { returnHoursInDays } from 'utils/hours'

import InHouseDelivery from './InHouseDelivery'
import OnDemandDelivery from './OnDemandDelivery'
import OrderTypeDisclosure from './OrderTypeDisclosure'

export type TDeliverDisclosureProp = {
  name: string
  label: string
  type: string
  currentDeliveryType: 'in_house' | 'on_demand' | 'dynamic'
  prioritizedDeliveryType: 'in_house' | 'on_demand' | null
  onDemandConfig: any
  inHouseConfig: any
  onChange: (key: string, name: string, value: any) => void
  tabChange: (value: string) => void
}
export default function DeliveryDisclosure({
  name,
  label,
  type,
  currentDeliveryType,
  prioritizedDeliveryType,
  onDemandConfig,
  inHouseConfig,
  onChange,
  tabChange,
}: TDeliverDisclosureProp): JSX.Element {
  const [inHouseOpenHoursDay, setInHouseOpenHoursDay] = useState({})
  const [onDemandOpenHoursDay, setOnDemandOpenHoursDay] = useState({})

  const {
    open_hours_collection: inHouseOpenHours,
    availability: inHouseAvailability,
  } = inHouseConfig || {}
  const {
    open_hours_collection: onDemandOpenHours,
    availability: onDemandAvailability,
  } = onDemandConfig || {}

  const enabled = inHouseConfig?.enabled || onDemandConfig?.enabled

  const isInHouse = currentDeliveryType === 'in_house'
  const isDynamic = currentDeliveryType === 'dynamic'
  const isOnDemand = currentDeliveryType === 'on_demand'

  useEffect(() => {
    let days: any = returnHoursInDays([])
    inHouseOpenHours?.open_hours.map((v: { day: string | number }) => {
      days = { ...days, [v.day]: [...days[v.day], v] }
    })
    setInHouseOpenHoursDay({ ...days })
  }, [inHouseOpenHours])

  useEffect(() => {
    let days: any = returnHoursInDays([])
    onDemandOpenHours?.open_hours.map((v: { day: string | number }) => {
      days = { ...days, [v.day]: [...days[v.day], v] }
    })
    setOnDemandOpenHoursDay({ ...days })
  }, [onDemandOpenHours])

  useEffect(() => {
    if (isDynamic && !prioritizedDeliveryType) {
      // 设置 prioritized 默认值
      onChange('', 'prioritized_delivery_type', 'on_demand')
    }
  }, [currentDeliveryType])

  const getHoursStatus = () => {
    const desc = (availability: string) =>
      availability === 'customize' ? 'Custom hours' : 'Follow business hours'

    if (isDynamic && prioritizedDeliveryType === 'in_house') {
      return desc(inHouseConfig?.availability)
    }

    if (isDynamic && prioritizedDeliveryType === 'on_demand') {
      return desc(onDemandConfig?.availability)
    }
    if (isOnDemand) {
      return desc(onDemandConfig?.availability)
    }
    if (isInHouse) {
      return desc(inHouseConfig?.availability)
    }
    return ''
  }
  return (
    <OrderTypeDisclosure
      name={name}
      label={label}
      type={type}
      enabled={enabled}
      hoursStatus={getHoursStatus()}
      tabChange={tabChange}
      onChange={(name, value) => onChange('delivery', name, value)}
    >
      <>
        <div className='pb-6 mb-6 flex border-b border-dashed border-zinc'>
          <Select
            options={DELIVERY_METHOD_OPTIONS}
            placeholder='Select'
            value={currentDeliveryType}
            className='w-full flex-1'
            label='Delivery method'
            onChange={value => onChange('', 'current_delivery_type', value)}
          />
          {isDynamic && (
            <Select
              options={PRIORITIZED_METHOD_OPTIONS}
              placeholder='Select'
              value={prioritizedDeliveryType}
              className='w-[314.67px] ml-6'
              label='Prioritized method'
              onChange={value =>
                onChange('', 'prioritized_delivery_type', value)
              }
            />
          )}
        </div>
        {isOnDemand || isDynamic ? (
          <>
            <OnDemandDelivery
              className={cn(
                isDynamic
                  ? 'pb-6 mb-6 border-b border-dashed border-zinc'
                  : null,
              )}
              label={label}
              onDemandConfig={onDemandConfig}
              onChange={(name, value) =>
                onChange('doordash_delivery_config', name, value)
              }
            />
            {onDemandAvailability === 'customize' && (
              <div
                className={cn(
                  'border border-solid rounded-lg p-4 border-zinc',
                  isDynamic ? 'my-6' : 'mt-6',
                )}
              >
                <OpenHoursForm
                  hoursInDay={onDemandOpenHoursDay}
                  onChange={value =>
                    onChange('doordash_delivery_config', 'open_hours', value)
                  }
                />
              </div>
            )}
          </>
        ) : null}

        {isInHouse || isDynamic ? (
          <>
            <InHouseDelivery
              label={label}
              inHouseConfig={inHouseConfig}
              onChange={(name, value) =>
                onChange('in_house_config', name, value)
              }
            />
            {inHouseAvailability === 'customize' && (
              <div className='border border-solid rounded-lg p-4 border-zinc mt-6'>
                <OpenHoursForm
                  hoursInDay={inHouseOpenHoursDay}
                  onChange={value =>
                    onChange('in_house_config', 'open_hours', value)
                  }
                />
              </div>
            )}
          </>
        ) : null}
      </>
    </OrderTypeDisclosure>
  )
}
