import * as ROUTES from 'data/routes'
import AdminLayout from 'pages/layout/AdminLayout'
import ProfilePage from 'pages/profile/ProfilePage'

const MYPROFILE: TRouteType = {
  path: ROUTES.MYPROFILE,
  name: 'MYPROFILE',
  label: 'My Profile',
  component: ProfilePage,
  layout: AdminLayout,
  auth: true,
  showBreadCrumbs: true,
}

export const otherAdminRoutes = [MYPROFILE]
