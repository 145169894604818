import GetHelp from './GetHelp'

interface Iprops {
  onStep?: (step: number) => void
}
export default function Step3Verification(props: Iprops): JSX.Element {
  return (
    <div className='mx-[120px]'>
      <GetHelp type='verification' />
    </div>
  )
}
