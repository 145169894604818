import React from 'react'

import Button from 'components/Button'

import GiftCardIcon from './GiftCardIcon'

interface TSellingGiftCardProps {
  onClick?: () => void
}

export default function SellingGiftCard({
  onClick,
}: TSellingGiftCardProps): JSX.Element {
  return (
    <>
      <p className='text-center text-base text-lead mt-40 mb-8'>
        You haven't started selling gift cards yet...
      </p>
      <GiftCardIcon />
      <Button className='mt-6' color='primary' onClick={onClick}>
        START SELLING GIFT CARD
      </Button>
    </>
  )
}
