import { LinedChatComment, SolidStar, SolidTag } from '@rushable/icons'
import MoneyAccounting from 'components/MoneyAccounting'

import GiftCards from './GiftCards'

type TOrderDetailMenuProp = {
  formData: any
}

export default function OrderDetailMenu({
  formData,
}: TOrderDetailMenuProp): JSX.Element {
  const couponDesc = formData?.coupon_description || ''
  const orderList =
    formData.order_items?.filter(
      (v: any) => v.type !== 'loyalty_reward_item',
    ) || []
  const rewardItem =
    formData.order_items?.find((v: any) => v.type === 'loyalty_reward_item') ||
    null

  const getAddress = () => {
    if (formData.address) {
      const { line_1, line_2, city, state, country, zipcode } = formData.address
      const line1 = line_1 ?? ''
      const line2 = line_2 ?? ''
      const city1 = city ?? ''
      const state1 = state ?? ''
      const country1 = country ?? ''
      const zipcode1 = zipcode != null ? zipcode : ''

      return (
        line1 +
        (line2 === '' ? '' : ' ' + line2) +
        ' ' +
        city1 +
        ', ' +
        state1 +
        ' ' +
        zipcode1 +
        ', ' +
        country1
      )
    }

    return 'None'
  }

  return (
    <div className='flex-1 py-8 pr-8'>
      {(formData.type === 'daas_delivery' || formData.type === 'delivery') && (
        <div className='mb-6'>
          <div className='grid gap-4 bg-ice rounded-lg py-4 px-6 '>
            {(!!formData.check_id || !!formData.check_card) && (
              <div>
                <p className='text-lead text-xs'>Fraud prevention</p>
                <p className='mt-1 text-ink text-base leading-snug'>
                  {formData.check_id && formData.check_card
                    ? 'ID verification and Payment card verification'
                    : formData.check_card
                    ? 'Payment card verification'
                    : formData.check_id
                    ? 'ID verification'
                    : ''}
                </p>
              </div>
            )}

            <div>
              <p className='text-lead text-xs'>Delivery information</p>
              <p className=' mt-1 text-ink text-base leading-snug'>
                {getAddress()}
                <br /> {formData?.delivery_note || 'None'}
              </p>
            </div>
          </div>
        </div>
      )}

      <div
        className={`grid gap-6 leading-snug ${
          (formData.coupon_code || formData.customer_note || rewardItem) &&
          'pb-2'
        }`}
      >
        {orderList &&
          orderList.map((item: any, key: number) => {
            return (
              <div className='flex flex-col' key={key}>
                <p className='text-lead text-xs mb-1 pl-10'>
                  {item.category_name}
                </p>
                <div className='flex items-center text-ink'>
                  <div className='w-6 h-[22px] p-0.5  bg-field rounded flex items-center justify-center text-xs text-lead font-bold mr-4'>
                    {item.quantity}
                  </div>
                  <p className='font-bold text-base'>{item.name}</p>
                  <div className='flex-1 h-1 mx-4  border-t border-dashed border-zinc'></div>
                  <MoneyAccounting
                    className='w-24'
                    value={item.total.updated}
                  />
                </div>
                {item.options_array && item.options_array.length > 0 && (
                  <div className='inline-block ml-10 mt-3 mb-2 last:mb-0'>
                    {item.options_array.map((options: any, i: number) => {
                      return (
                        <div
                          key={options.name}
                          className={`flex ${i === 0 ? 'pt-0' : 'pt-2'}`}
                        >
                          <div className='border-l-2 border-solid border-zinc mr-2 w-0.5'></div>
                          <div>
                            <p className='text-lead text-xxs-l mt-[-4px]'>
                              {options.name}
                            </p>
                            {options.value.length > 0 &&
                              options.value.map((value_item: any) => {
                                return (
                                  <p
                                    className='text-sm mb-1 last:mb-0  text-ink'
                                    key={value_item}
                                  >
                                    {value_item}
                                  </p>
                                )
                              })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
                {item.customer_note && (
                  <div
                    className={`inline-block ml-10 border-l-2 border-solid border-yellow text-ink text-sm pl-2 ${
                      item.options_array.length ? 'mt-0' : 'mt-3'
                    }`}
                  >
                    {item.customer_note}
                  </div>
                )}
              </div>
            )
          })}
      </div>
      <div
        className={`flex flex-col space-y-6 ${
          (formData.coupon_code || formData.customer_note || rewardItem) &&
          'my-6'
        }`}
      >
        {rewardItem && (
          <div className='flex flex-col'>
            <p className='text-lead text-xs mb-1 pl-10'>
              {rewardItem.category_name}
            </p>
            <div className='flex items-center'>
              <SolidStar size={20} />
              <p className='ml-5 font-bold text-ink'>{`[${rewardItem.spent_points}pts] ${rewardItem.name}`}</p>
            </div>
          </div>
        )}

        {couponDesc && (
          <div className='flex flex-col'>
            <p className='text-lead text-xs mb-1 pl-10'>Coupon Deal</p>
            <div className='flex items-center'>
              <SolidTag size={20} />
              <p className='ml-5 font-bold text-ink'>{couponDesc}</p>
            </div>
          </div>
        )}
        {formData.customer_note && (
          <div className='flex items-center py-3 px-4 bg-ice rounded-lg text-ink'>
            <LinedChatComment size={20} className='mr-2' />{' '}
            {formData.customer_note}
          </div>
        )}
      </div>

      <div className='mt-6 pt-6  flex justify-between border-t border-dashed border-zinc'>
        <div className='text-silver'>
          {formData.order_items_count} items in total
        </div>
        <div className='w-[320px] leading-snug'>
          <div className='flex flex-col space-y-1 text-ink text-base'>
            <div className='flex justify-between'>
              <span>Subtotal</span>
              <MoneyAccounting
                className='w-24'
                value={formData.price_summary?.subtotal}
                reject={
                  formData.status === 'canceled' && !formData.confirmed_at
                }
              />
            </div>
            {formData.price_summary?.coupon &&
            formData.price_summary.coupon > 0 ? (
              <div className='flex justify-between'>
                <span>Discount</span>
                <MoneyAccounting
                  className='w-24'
                  value={-Math.abs(formData.price_summary.coupon)}
                  reject={
                    formData.status === 'canceled' && !formData.confirmed_at
                  }
                />
              </div>
            ) : null}
            {formData.price_summary?.custom_fee &&
            formData.price_summary.custom_fee > 0 ? (
              <div className='flex justify-between'>
                <span>Custom fee</span>
                <MoneyAccounting
                  className='w-24'
                  value={formData.price_summary?.custom_fee}
                  reject={
                    formData.status === 'canceled' && !formData.confirmed_at
                  }
                />
              </div>
            ) : null}

            <div className='flex justify-between'>
              <span>Tax</span>
              <MoneyAccounting
                className='w-24'
                value={formData.price_summary?.sales_tax}
                reject={
                  formData.status === 'canceled' && !formData.confirmed_at
                }
              />
            </div>
            {formData.price_summary?.regulatory_fee &&
            Number(formData.price_summary?.regulatory_fee) > 0 ? (
              <div className='flex justify-between'>
                <span>Regulatory fee</span>
                <MoneyAccounting
                  className='w-24'
                  value={formData.price_summary?.regulatory_fee}
                  reject={
                    formData.status === 'canceled' && !formData.confirmed_at
                  }
                />
              </div>
            ) : null}
            {formData.type === 'delivery' && (
              <div className='flex justify-between'>
                <span>Delivery fee</span>
                <MoneyAccounting
                  className='w-24'
                  value={formData.price_summary?.delivery_fee}
                  reject={
                    formData.status === 'canceled' && !formData.confirmed_at
                  }
                />
              </div>
            )}
            {/** Courier fee **/}
            {formData.type === 'daas_delivery' && (
              <div className='flex justify-between'>
                <span>Courier fee</span>
                <MoneyAccounting
                  className='w-24'
                  value={formData.price_summary?.courier_fee_customer}
                  reject={
                    formData.status === 'canceled' && !formData.confirmed_at
                  }
                />
              </div>
            )}
            {/** Restaurant tip / Courier tip **/}
            <div className='flex justify-between'>
              <span>
                {formData.type === 'daas_delivery'
                  ? 'Courier tip'
                  : 'Restaurant tip'}
              </span>
              <MoneyAccounting
                className='w-24'
                value={
                  formData.type === 'daas_delivery'
                    ? formData.price_summary?.courier_tip
                    : formData.price_summary?.tip
                }
                reject={
                  formData.status === 'canceled' && !formData.confirmed_at
                }
              />
            </div>
            {formData.price_summary?.service_fee ? (
              <div className='flex justify-between'>
                <span>Convenience fee</span>
                <MoneyAccounting
                  className='w-24'
                  value={formData.price_summary?.service_fee}
                  reject={
                    formData.status === 'canceled' && !formData.confirmed_at
                  }
                />
              </div>
            ) : null}
            {/** Total without refunds **/}
            <div className='flex justify-between font-bold'>
              <span>GRAND TOTAL</span>
              <MoneyAccounting
                className='w-24'
                value={formData.total}
                reject={
                  formData.status === 'canceled' && !formData.confirmed_at
                }
              />
            </div>
          </div>
          <GiftCards
            brand={formData?.payment_card_brand}
            last_4={formData?.payment_card_last_4}
            total={formData?.total}
            giftCards={formData?.gift_card_transactions}
            reject={formData.status === 'canceled' && !formData.confirmed_at}
          />

          {(!!formData.price_summary?.refunds.length ||
            !!formData.credits?.length) && (
            <div
              className={`flex flex-col space-y-2 mt-4 ${
                !formData?.gift_card_transactions?.length &&
                'pt-4 border-t border-dashed border-zinc'
              }`}
            >
              {formData.price_summary.refunds.map(
                (refund: any, key: number) => {
                  return (
                    <div
                      className={`${
                        formData.status === 'canceled' && !formData.confirmed_at
                          ? 'bg-field'
                          : 'bg-red-opacity-8'
                      }  px-4 py-2 rounded-lg`}
                      key={key}
                    >
                      <div
                        className={`${
                          formData.status === 'canceled' &&
                          !formData.confirmed_at
                            ? 'text-ink'
                            : 'text-red-darken'
                        }  text-sm font-bold flex justify-between font-bold`}
                      >
                        <span>
                          {formData.status === 'canceled' &&
                          !formData.confirmed_at
                            ? 'Order Rejected'
                            : `Refund to Visa ${formData?.payment_card_last_4}`}
                        </span>
                        {!(
                          formData.status === 'canceled' &&
                          !formData.confirmed_at
                        ) && (
                          <MoneyAccounting
                            className='w-20'
                            value={-refund.amount}
                          />
                        )}
                      </div>
                      <div className='text-sm text-ink'>{refund.note}</div>
                    </div>
                  )
                },
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
