import React, { useState, useEffect } from 'react'

import Button from 'components/Button'
import Table, { TColumnProp } from 'components/TableAndCell/Table'
import { Badge } from 'components/Tag'
import { useAppSelector } from 'redux/hooks'

import { ModalHelp } from './components/GetHelp'
import LegalOverview from './components/LegalOverview'
import { taxStatusMap } from './helps'

export default function OperationInfo() {
  const [isShowContact, setIsShowContact] = useState(false)
  const { selectedLocation } = useAppSelector(state => state.location)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    // 看后面api会不会变更table获取方式
    // getTableData()
  }, [])

  useEffect(() => {
    const { location_taxes } = selectedLocation || ({} as any)
    if (location_taxes?.length) {
      setTableData(location_taxes)
    }
  }, [selectedLocation])

  // const getTableData = async () => {
  // const params = {
  // location_id: locationId || '',
  // }
  // try {
  //   const res = await getPayoutListApi(params)
  //   setTableData(res.data || [])
  // } catch (e: any) {
  //   const msg = e.message || 'Request error'
  //   toast.error(msg)
  // }
  // }

  const tableCols: TColumnProp[] = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-40',
      custom: value => {
        return (
          <Badge color={taxStatusMap[value]?.color}>
            {taxStatusMap[value]?.desc}
          </Badge>
        )
      },
    },
    {
      key: 'type',
      className: 'w-25',
      name: 'FORM',
      custom: value => <span>{value}</span>,
    },
    {
      key: 'year',
      name: 'TAX YEAR',
      align: 'right',
      className: 'w-28',
      custom: value => <span>{value}</span>,
    },
    {
      key: 'note',
      name: 'NOTE',
      className: '',
      custom: value => <span>{value}</span>,
    },
    {
      key: 'ACTION',
      name: 'ACTION',
      className: 'w-40',
      align: 'right',
      custom: (value, row) => {
        return (
          <div className='flex justify-center'>
            {row.form_link ? (
              <Button
                color='tertiary'
                className='my-1'
                size='sm'
                onClick={() => {
                  window.location.href = row.form_link
                }}
              >
                DOWNLOAD
              </Button>
            ) : (
              <Button
                color='primary'
                className='my-1'
                size='sm'
                onClick={() => setIsShowContact(true)}
              >
                CONTACT US
              </Button>
            )}
          </div>
        )
      },
    },
  ]
  return (
    <div className='p-8'>
      <LegalOverview />
      <Table columns={tableCols} data={tableData} />
      <ModalHelp
        type='contactUs'
        open={isShowContact}
        toggle={() => setIsShowContact(false)}
      />
    </div>
  )
}
