import { createAction } from '@reduxjs/toolkit'
import {
  TReviewFormData,
  TCommentModal,
  TReviewConfig,
  TReviewComment,
} from 'types/review'
import { TSortData } from 'types/table'
import axios from 'utils/axiosApi'

export const setFormData = createAction<TReviewFormData>(
  'smartReview/setFormData',
)
export const setSortData = createAction<TSortData>('smartReview/setSortData')

export const setCommentModal = createAction<TCommentModal>(
  'smartReview/setCommentModal',
)

export const setPageLoading = createAction<boolean>(
  'smartReview/setPageLoading',
)

export const setReviewConfig = createAction<TReviewConfig | null>(
  'smartReview/setReviewConfig',
)

export const setTableData = createAction<{
  total: number
  data: TReviewComment[]
}>('smartReview/setTableData')

export async function getReviewListApi(
  locationId: string,
  params: any,
  ignoreCache = true,
) {
  const response = await axios.request({
    url: `locations/${locationId}/customer_reviews`,
    method: 'get',
    params,
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function getReviewConfigApi(
  locationId: string,
  ignoreCache = true,
) {
  const response = await axios.request({
    url: `locations/${locationId}/location_review_config`,
    method: 'get',
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function updateCustomerReviewsApi(
  locationId: string,
  params: any,
) {
  const response = await axios.request({
    url: `locations/${locationId}/customer_reviews/${params.id}`,
    method: 'patch',
    data: {
      contacted: params.contacted,
    },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function updateReviewsConfigApi(locationId: string, data: any) {
  const response = await axios.request({
    url: `locations/${locationId}/location_review_config`,
    method: 'patch',
    data,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
