export const TOKEN_KEY = 'RUSHABLE_ADMIN_TOKEN'
export const API_DOMAIN: string = process.env.REACT_APP_API_DOMAIN || ''
export const API_DOMAIN_V2: string = process.env.REACT_APP_API_DOMAIN_V2 || ''
export const STRIPE_API_KEY: string =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''

export const API_URL = `${API_DOMAIN}/client_dashboard/`
export const API_URL_V2 = `${API_DOMAIN_V2}/api/`
export const IMAGE_PREFIX = process.env.REACT_APP_MEDIA_DOMAIN || ''
export const API_TIMEOUT: number = 60 * 1000 // wait for 15 seconds
export const SERVER_TIMEZONE = 'America/Chicago'
export const DAYS = [
  {
    label: 'Sunday',
    abbr: 'sun',
  },
  {
    label: 'Monday',
    abbr: 'mon',
  },
  {
    label: 'Tuesday',
    abbr: 'tue',
  },
  {
    label: 'Wednesday',
    abbr: 'wed',
  },
  {
    label: 'Thursday',
    abbr: 'thu',
  },
  {
    label: 'Friday',
    abbr: 'fri',
  },
  {
    label: 'Saturday',
    abbr: 'sat',
  },
]

export const BRAND_COLOR = [
  '#00CA9F',
  '#0214D0',
  '#2DB67C',
  '#21BFDB',
  '#FA2D59',
  '#F40E0E',
  '#FF7829',
  '#CFA670',
]

export const SUPPORT_PHONE = '(855) 979-8860'
export const SUPPORT_EMAIL = 'support@rushable.io'
