import axios from 'axios'
import { API_URL, API_TIMEOUT } from 'data/constant'

import { cache, setupInterceptors } from './intercaptor'

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
  adapter: cache.adapter,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json; charset=utf-8',
  },
})

setupInterceptors(axiosInstance)

export default axiosInstance
