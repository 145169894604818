import React from 'react'

import cn from 'classnames'
import { Link } from 'react-router-dom'

export type TNavLinkProp = {
  text?: string
  icon?: React.ReactNode
  color?: 'white' | 'ink' | 'silver' | 'primary'
  size?: 'xxs' | 'xs' | 'sm' | 'base' | 'lg'
  underline?: boolean
  disabled?: boolean
  className?: string
  to: string
}

export default function NavLink({
  text,
  icon,
  color = 'ink',
  underline = false,
  size,
  disabled,
  className,
  to,
}: TNavLinkProp): JSX.Element {
  return (
    <Link
      className={cn(
        `text-${color} text-${size} cursor-pointer`,
        underline ? 'underline' : 'no-underline',
        disabled ? 'pointer-events-none' : 'hover:opacity-75',
        icon && text ? 'flex items-center' : '',
        className,
      )}
      to={to}
    >
      {icon && <div className='mr-1'>{icon}</div>}
      {text}
    </Link>
  )
}
