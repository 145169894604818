export const urlToBase64 = (url: string) => {
  return new Promise((resolve, reject) => {
    let canvas: any = document.createElement('canvas')
    const ctx: any = canvas.getContext('2d')
    const img = new Image()
    img.crossOrigin = 'anonymous' //解决Canvas.toDataURL 图片跨域问题
    img.onload = function () {
      canvas.height = img.height
      canvas.width = img.width
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase() // 获取到图片的格式
      const dataURL = canvas.toDataURL('image/' + ext) // 得到base64 编码的 dataURL
      canvas = null
      resolve(dataURL)
    }
    img.onerror = err => {
      reject(err)
    }
    img.src = url + '?' + new Date().getTime()
  })
}

// Base64 转 Blob
export function dataURLtoBlob(dataUrl: any) {
  const arr = dataUrl.split(',') //分割为数组，分割到第一个逗号
  const bstr = window.atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: 'image/png' })
}
