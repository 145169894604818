import React, { useLayoutEffect, useState } from 'react'

import cn from 'classnames'
import BrandNavItems from 'pages/layout/components/BrandNavItems'
import LeftNavBottom from 'pages/layout/components/LeftNavBottom'
import LeftNavTop from 'pages/layout/components/LeftNavTop'
import LocationNavItems from 'pages/layout/components/LocationNavItems'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { setNextRoute, setProPackageModalOpen } from 'redux/brand'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { brandRoutes } from 'routes/brandRoutes'
import { isProPackagePath } from 'routes/helpers/tool'
import { locationRoutes } from 'routes/locationRoutes'

import LocationSelectDrawer from './LocationSelectDrawer'
import SkeletonLeftNav from './SkeletonLeftNav'

type TLeftNavProp = {
  activeMode: 'brand' | 'location'
  onChange: (e: boolean) => void
  setActiveMode: (mode: 'brand' | 'location') => void
}

export default function LeftNav({
  activeMode = 'brand',
  onChange,
}: TLeftNavProp): JSX.Element {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const [mode, setMode] = useState(activeMode as string)
  const [activeGroup, setActiveGroup] = useState('')
  const { brandId, locationId } = useParams<TParamTypes>()
  const history = useHistory()
  const { selectedLocation } = useAppSelector(state => state.location)
  const { selectedBrand } = useAppSelector(state => state.brand)
  const permission = useAppSelector(state => state.auth.authProfile.data)

  const onModeChange = (value: string) => {
    setMode(value)
  }

  const routeToPath = (toPath: string) => {
    if (toPath.includes(':brandId') && brandId) {
      toPath = toPath.replace(':brandId', brandId)
    }
    if (toPath.includes(':locationId') && locationId) {
      toPath = toPath.replace(':locationId', locationId)
    }
    if (!selectedBrand?.has_pro_package && isProPackagePath(toPath)) {
      dispatch(setNextRoute(toPath))
      dispatch(setProPackageModalOpen(true))
    } else {
      history.push(toPath)
    }
  }

  const [activePath, setActivePath] = useState('')

  useLayoutEffect(() => {
    const getActivePath = () => {
      let normalizePath = pathname
      if (brandId) {
        normalizePath = normalizePath.replace(brandId, ':brandId')
      }
      if (locationId) {
        normalizePath = normalizePath.replace(locationId, ':locationId')
      }

      const isBrand =
        brandRoutes.findIndex(r => normalizePath.includes(r.path)) > -1
      setMode(isBrand ? 'brand' : 'location')
      setActivePath(normalizePath)
      if (!isBrand) {
        getActiveGroup(normalizePath)
      }
    }

    const getActiveGroup = (path: string) => {
      const routeObject = locationRoutes.find(r => path.includes(r.path))
      if (routeObject) {
        setActiveGroup(routeObject.group || '')
      }
    }
    getActivePath()
  }, [pathname])

  const isLoading = !selectedBrand || !selectedLocation || !permission

  const [drawerOpen, setDrawerOpen] = useState(false)
  function toggleLocationDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <div className={cn('w-60 px-6 absolute left-0 top-0 bottom-0 bg-darkblue')}>
      {isLoading && <SkeletonLeftNav />}

      {!isLoading && (
        <LocationSelectDrawer
          open={drawerOpen}
          toggle={toggleLocationDrawer}
          onChange={onChange}
        />
      )}

      {mode === 'brand' && !isLoading && (
        <BrandNavItems
          activePath={activePath}
          permission={permission?.permissionMap || {}}
          routeToPath={routeToPath}
        />
      )}

      {mode === 'location' && !isLoading && (
        <LocationNavItems
          activeGroup={activeGroup}
          activePath={activePath}
          permission={permission?.permissionMap || {}}
          routeToPath={routeToPath}
        />
      )}

      <LeftNavTop
        mode={mode}
        isLoading={isLoading}
        onModeChange={onModeChange}
        toggleLocationDrawer={toggleLocationDrawer}
      />

      <LeftNavBottom
        routeToPath={routeToPath}
        mode={mode}
        isLoading={isLoading}
      />
    </div>
  )
}
