import Button from 'components/Button'
import MoneyAccounting from 'components/MoneyAccounting'
import Table from 'components/TableAndCell/Table'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

type TAccountingReportTableProps = {
  tableData: any
  loading: boolean
  page: number
  downloading: boolean
  onChange: (name: string, value: any) => void
  downloadReport: () => void
}

export default function AccountingReportTable({
  tableData,
  loading,
  downloading,
  page,
  onChange,
  downloadReport,
}: TAccountingReportTableProps): JSX.Element {
  const { locationId, brandId } = useParams<TParamTypes>()

  const columns = [
    {
      key: 'date',
      name: 'DATE',
      className: 'w-[110px]',
      tdClassName: 'bg-white',
      sticky: true,
      stickyClassName: '0px',
      custom: (value: string) => {
        return <div className='flex'>{moment(value).format('M/DD/YYYY')}</div>
      },
    },
    {
      key: 'transaction_type',
      name: 'TRANSACTION',
      className: 'w-[132px]',
      tdClassName: 'bg-white',
      sticky: true,
      stickyClassName: '110px',
      custom: (value: string) => {
        return <div className='flex'>{value}</div>
      },
    },
    {
      key: 'order_id',
      name: 'ASSO. ORDER',
      className: 'w-[110px]',
      custom: (value: any) =>
        value ? (
          <Link
            className='hover:text-blue cursor-pointer'
            to={`/brand/${brandId}/location/${locationId}/order-history/order-detail/${value}`}
            target='_brank'
          >
            {`#${value}`}
          </Link>
        ) : (
          <span>-</span>
        ),
    },
    {
      key: 'reference',
      name: 'REFERENCE',
      className: 'w-[144px]',
      custom: (value: string) => {
        return <div className='text-ellipsis'>{value}</div>
      },
    },
    {
      key: 'net_payout',
      name: 'NET PAYOUT',
      className: 'w-[140px]',
      align: 'right',
      custom: (value: any) => (
        <MoneyAccounting value={value} className='w-24' size='sm' />
      ),
    },

    {
      key: 'total',
      name: 'TOTAL COLLECTED',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => (
        <MoneyAccounting value={value} className='w-24 text-lead' size='sm' />
      ),
    },
    {
      key: 'tax',
      name: 'TAX COLLECTED',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => (
        <MoneyAccounting value={value} className='w-24 text-lead' size='sm' />
      ),
    },
    {
      key: 'restaurant_tip',
      name: 'RESTAURANT TIP',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => (
        <MoneyAccounting value={value} className='w-24 text-lead' size='sm' />
      ),
    },
    {
      key: 'customer_fees',
      name: 'CUSTOMER FEES',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => (
        <MoneyAccounting value={value} className='w-24 text-lead' size='sm' />
      ),
    },
    {
      key: 'restaurant_fees',
      name: 'RESTAURANT FEES',
      align: 'right',
      className: 'w-[140px]',
      custom: (value: any) => (
        <MoneyAccounting value={value} className='w-24 text-lead' size='sm' />
      ),
    },
  ]

  return (
    <div style={{ width: 'calc(100% - 360px)' }} className='flex flex-col p-8'>
      <Table
        sticky={true}
        disableHover={true}
        bodyClassName=''
        loading={loading}
        isPageSet={true}
        current={page}
        containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-x-scroll'
        columns={columns}
        data={tableData.data}
        total={tableData.total}
        totalText={'results'}
        rightButton={
          <Button
            loading={downloading}
            size='sm'
            color='primary'
            onClick={() => downloadReport()}
          >
            Export Detailed Report
          </Button>
        }
        onPageNoChange={value => onChange('page', value)}
        onPageSizeChange={value => {
          onChange('pageSize', value)
        }}
      />
    </div>
  )
}
