import React, { useState } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
  useParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import { downloadReportAPI } from 'redux/financial'
import { useAppSelector } from 'redux/hooks'
import downloadArrayBuffer from 'utils/downloadArrayBuffer'

import AccountingReport from './AccountingReport'
import AccountingReportDetail from './AccountingReportDetail'
import PayoutDetail from './PayoutDetail'
import PayoutReport from './PayoutReport'

export default function FinancialPage(): JSX.Element {
  const [downloading, setDownLoading] = useState(false)
  const { locationId } = useParams<TParamTypes>()
  const { selectedLocation } = useAppSelector(state => state.location)
  const history = useHistory()
  const { path, url } = useRouteMatch()
  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})

  const getReport = async (value?: any) => {
    setDownLoading(true)
    try {
      const res = await downloadReportAPI(locationId, { ...value })
      if (selectedLocation) {
        const { brand, name } = selectedLocation
        let fileName = `Payouts ${brand.name} ${name}`
        fileName = fileName.replace(/\s+/g, '-').replace(/[^\w-]+/g, '')
        downloadArrayBuffer(res, fileName)
      }
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setDownLoading(false)
  }

  return (
    <AdminPage>
      <Switch>
        <Route path={`${path}/accounting-report/:dateRange`} exact>
          <AccountingReportDetail
            downloading={downloading}
            downloadReport={value => getReport(value)}
          />
        </Route>
        <Route path={`${path}/payout-report/:payoutId`} exact>
          <PayoutDetail
            downloading={downloading}
            downloadReport={value => {
              getReport({ payout_id: value })
            }}
          />
        </Route>
        <Route path={path}>
          <AdminPageTabs>
            <AdminPageTab
              text='Accounting Report'
              active={activePath === 'accounting-report'}
              onClick={() => switchTab('accounting-report')}
            />
            <AdminPageTab
              text='Payout Report'
              active={activePath === 'payout-report'}
              onClick={() => switchTab('payout-report')}
            />
          </AdminPageTabs>
          <div className='p-8'>
            <Switch>
              <Route path={`${path}/accounting-report`} exact>
                <AccountingReport />
              </Route>
              <Route path={`${path}/payout-report`} exact>
                <PayoutReport />
              </Route>
              <Redirect
                from={`${path}`}
                to={`${path}/accounting-report`}
                exact
              />
            </Switch>
          </div>
        </Route>
      </Switch>
    </AdminPage>
  )
}
