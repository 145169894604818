import { Fragment, useRef, useEffect } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { MarkX } from '@rushable/icons'
import cn from 'classnames'
import useScroll from 'hooks/useScroll'

export type TDrawerProp = {
  open: boolean
  position?: 'left' | 'right' | 'top' | 'bottom'
  size?: 'sm' | 'md' | 'lg'
  toggle: () => void
  title?: string | React.ReactElement
  error?: string
  children: React.ReactElement | React.ReactElement[]
  leftFooter?: React.ReactElement
  rightFooter?: React.ReactElement
  showClose?: boolean
  onScroll?: (e: any) => void
  // childClassName?: string
}

export default function Drawer({
  open,
  toggle,
  children,
  position = 'right',
  size = 'lg',
  title,
  error,
  leftFooter,
  rightFooter,
  showClose = false,
  onScroll,
}: TDrawerProp): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  const scroll = useScroll(ref)

  useEffect(() => {
    onScroll?.({
      ...scroll,
      scrollHeight: ref.current?.scrollHeight,
      clientHeight: ref.current?.clientHeight,
    })
  }, [scroll])

  const isHorizontal = position === 'top' || position === 'bottom'
  let sizeClass =
    size === 'sm' ? 'max-w-sm' : size === 'md' ? 'max-w-md' : 'max-w-[560px]'
  const horizontalSizeClass = 'min-h-36'
  if (isHorizontal) {
    sizeClass = horizontalSizeClass
  }
  let closeClass = 'top-0 left-0 -ml-12'
  let positionClass = 'inset-y-0 right-0 pl-10'
  let enterFromClass = 'translate-x-full'
  let enterToClass = 'translate-x-0'
  switch (position) {
    case 'left':
      positionClass = 'inset-y-0 left-0 pr-10'
      enterFromClass = '-translate-x-full'
      enterToClass = 'translate-x-0'
      closeClass = 'top-0 right-0 -mr-12'
      break
    case 'top':
      positionClass = 'inset-x-0 top-0 pb-10'
      enterFromClass = '-translate-y-full'
      enterToClass = 'translate-y-0'
      closeClass = 'bottom-0 right-0 -mb-12'
      break
    case 'bottom':
      positionClass = 'inset-x-0 bottom-0 pt-10'
      enterFromClass = 'translate-y-full'
      enterToClass = 'translate-y-0'
      closeClass = 'bottom-top right-0 -mt-12'
      break
    default:
      break
  }

  const hasFooter = leftFooter || rightFooter
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 overflow-hidden z-[150]'
        onClose={toggle}
      >
        <div className='absolute inset-0 overflow-hidden'>
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-150'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-150'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='absolute inset-0 bg-opacity-64 transition-opacity' />
          </Transition.Child>
          <div className={cn('fixed max-w-full flex', positionClass)}>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-150 sm:duration-150'
              enterFrom={enterFromClass}
              enterTo={enterToClass}
              leave='transform transition ease-in-out duration-150 sm:duration-150'
              leaveFrom={enterToClass}
              leaveTo={enterFromClass}
            >
              <div
                className={cn(
                  'relative w-screen',
                  sizeClass,
                  isHorizontal && 'max-h-screen',
                )}
              >
                {showClose && (
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-150'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-150'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className={cn('absolute p-4 flex', closeClass)}>
                      <button
                        type='button'
                        className='text-white/75 hover:text-white focus:outline-none focus:text-white'
                        onClick={toggle}
                      >
                        <span className='sr-only'>Close panel</span>
                        <MarkX size={16} className='text-silver' />
                      </button>
                    </div>
                  </Transition.Child>
                )}
                <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll overscroll-none'>
                  {title && (
                    <div className={cn('px-8 pt-8 pb-6')}>
                      {title && (
                        <Dialog.Title className='text-base font-bold text-ink text-center'>
                          {title}
                        </Dialog.Title>
                      )}
                    </div>
                  )}

                  <div
                    ref={ref}
                    className={cn(
                      'px-8 pb-24 relative flex-1 h-fit overflow-y-auto overscroll-none',
                    )}
                  >
                    {children}
                  </div>

                  {error && (
                    <div
                      className={cn(
                        'absolute inset-x-0 bg-white flex items-center justify-center px-8 py-4 border-t border-solid border-zinc',
                        hasFooter ? 'bottom-[72px]' : 'bottom-0',
                      )}
                    >
                      <div className='text-red text-base'>{error}</div>
                    </div>
                  )}
                  {hasFooter && (
                    <div className='absolute bottom-0 inset-x-0 bg-white h-18 flex items-center justify-between px-8 py-4 border-t border-solid border-zinc'>
                      <div className='flex items-center justify-start space-x-4'>
                        {leftFooter}
                      </div>
                      <div className='flex items-center justify-end space-x-4'>
                        {rightFooter}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
