import React, { useState, useEffect } from 'react'

import cn from 'classnames'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'redux/hooks'
import { sendSlackForNewLocation } from 'redux/location/actions'

import { GOOD_TIME_TO_CONTACT_OPTIONS } from '../helpers/constant'
import type { TLocationModal } from '../SelectRestaurant'

type TAddLocationModal = {
  locationModal: TLocationModal
  setLocationModal: (v: TLocationModal) => void
}

export default function AddLocationModal({
  locationModal,
  setLocationModal,
}: TAddLocationModal) {
  const [address, setAddress] = useState<string>('')
  const [time, setTime] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const handleConfirm = async () => {
    const params = {
      brandId: locationModal.id as number,
      locationAddress: address,
      whenToContact: time,
    }
    try {
      setLoading(true)
      await dispatch(sendSlackForNewLocation(params))
      setSuccess(true)
      setLoading(false)
    } catch (error: any) {
      if (error.message) {
        toast.warn(error.message)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setAddress('')
      setTime('')
      setSuccess(false)
    }, 500)
  }, [locationModal.open])
  return (
    <ModalFull
      open={locationModal.open}
      title='Add New Location'
      toggle={() =>
        setLocationModal({
          open: !locationModal.open,
          id: null,
        })
      }
      footer={
        success ? (
          <div className='flex justify-end'>
            <Button
              color='primary'
              onClick={() =>
                setLocationModal({
                  open: !locationModal.open,
                  id: null,
                })
              }
            >
              CLOSE
            </Button>
          </div>
        ) : (
          <div className='flex justify-end'>
            <Button
              color='secondary'
              className='mr-4'
              onClick={() =>
                setLocationModal({
                  open: !locationModal.open,
                  id: null,
                })
              }
              type='button'
            >
              CANCEL
            </Button>
            <Button color='primary' onClick={handleConfirm} loading={loading}>
              CONFIRM
            </Button>
          </div>
        )
      }
    >
      <div className='mt-6'>
        <div
          className={cn('text-base leading-6 text-center pl-3 pr-3 text-ink', {
            'pb-6': !success,
          })}
        >
          {success
            ? 'Thank you, our team will get in touch with you soon!'
            : 'Please share with us the new location information of Taste of Nations, our team will reach back to you as soon as possible to help you with the setup.'}
        </div>
        {!success && (
          <>
            <Field
              label='New location address'
              name='address'
              placeholder='Input address'
              type='address'
              className='mb-6 w-full'
              onChange={e => setAddress(e.target.value)}
              value={address}
            />
            <Select
              options={GOOD_TIME_TO_CONTACT_OPTIONS}
              placeholder='Select option'
              value={time}
              className='w-full'
              label='When will be a good time to contact'
              onChange={value => {
                setTime(value)
              }}
            />
          </>
        )}
      </div>
    </ModalFull>
  )
}
