import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_DOMAIN } from 'data/constant'
import type { TCampaignItem } from 'types/campaign'
import axios from 'utils/axiosApi'

export const getCampaigns = createAsyncThunk(
  'campaigns/getCampaigns',
  async ({
    locationId,
    type,
    page,
    keyword,
    status,
  }: {
    locationId: string
    type: string
    page: number
    keyword: string
    status: string
  }) => {
    const response = await axios.request({
      url: `locations/${locationId}/campaigns`,
      params: {
        // location_id: locationId,
        type,
        page,
        name: keyword,
        status,
      },
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const getCampaignCount = (locationId: string) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/campaigns/data/count`,
      params: {
        type: 'one_off',
      },
      method: 'get',
      cache: {
        ignoreCache: false,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
}

export const createCampaigns = (params: TCampaignItem, locationId: string) => {
  return async () => {
    const formData = new FormData()
    formData.append('type', params.type)
    if (params.type === 'automated' && params.trigger_type) {
      formData.append('trigger_type', params.trigger_type)
      if (params.trigger_of_inactive_days) {
        formData.append(
          'trigger_of_inactive_days',
          params.trigger_of_inactive_days + '',
        )
      }
    }
    formData.append('status', params.status)
    formData.append('current_step', params.current_step + '')
    if (params.campaign_template_id) {
      formData.append('campaign_template_id', params.campaign_template_id + '')
    }
    formData.append('campaign_email_template_id', '1')

    formData.append('name', params.name)
    formData.append('via_email', params.via_email + '')
    formData.append('via_sms', params.via_sms + '')
    if (params.via_sms === 1) {
      formData.append('sms_content', params.sms_content)
    }
    formData.append('enable_cta', params.enable_cta + '')
    formData.append('email_subject', params.email_subject)
    if (params.email_image_file) {
      formData.append('email_image_file', params.email_image_file)
    }
    if (params.email_image_url) {
      formData.append('email_image_url', params.email_image_url)
    }
    formData.append('email_headline', params.email_headline)
    formData.append('email_content', params.email_content)
    // params.campaign_locations.forEach((item, index) => {
    //   formData.append(
    //     `campaign_locations[${index}][location_id]`,
    //     item.location_id + '',
    //   )
    //   formData.append(
    //     `campaign_locations[${index}][is_initiator]`,
    //     item.is_initiator + '',
    //   )
    // })
    const response = await axios.request({
      url: `locations/${locationId}/campaigns`,
      data: formData,
      method: 'post',
      cache: {
        ignoreCache: true,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
}

export const updateCampaigns = (params: TCampaignItem, locationId: string) => {
  return async () => {
    const formData = new FormData()
    formData.append('type', params.type)
    if (params.type === 'automated' && params.trigger_type) {
      formData.append('trigger_type', params.trigger_type)
      if (params.trigger_of_inactive_days) {
        formData.append(
          'trigger_of_inactive_days',
          params.trigger_of_inactive_days + '',
        )
      }
    }
    formData.append('status', params.status)
    formData.append('current_step', params.current_step + '')
    if (params.campaign_template_id) {
      formData.append('campaign_template_id', params.campaign_template_id + '')
    }
    formData.append('campaign_email_template_id', '1')

    formData.append('name', params.name)
    formData.append('via_email', params.via_email + '')
    formData.append('via_sms', params.via_sms + '')
    if (params.via_sms === 1) {
      formData.append('sms_content', params.sms_content)
    }
    formData.append('enable_cta', params.enable_cta + '')
    formData.append('email_subject', params.email_subject)
    if (params.email_image_file) {
      formData.append('email_image_file', params.email_image_file)
    }
    if (params.email_image_url) {
      formData.append('email_image_url', params.email_image_url)
    }
    formData.append('email_headline', params.email_headline)
    formData.append('email_content', params.email_content)

    // params.campaign_locations.forEach((item, index) => {
    //   formData.append(
    //     `campaign_locations[${index}][location_id]`,
    //     item.location_id + '',
    //   )
    // })
    if (params.campaign_offer) {
      const { type, name, coupons, valid_days } = params.campaign_offer
      if (type) {
        formData.append('campaign_offer[type]', type)
        formData.append('campaign_offer[name]', name)
        if (valid_days) {
          formData.append('campaign_offer[valid_days]', valid_days.toString())
        }
        if (type === 'coupon_code') {
          if (coupons && coupons.length > 0 && coupons[0]?.id) {
            formData.append(
              'campaign_offer[coupons][0][id]',
              coupons[0]?.id?.toString(),
            )
          }
        }
        if (type === 'lucky_spin') {
          if (coupons && coupons.length > 0) {
            coupons.forEach((item, index) => {
              formData.append(
                `campaign_offer[coupons][${index}][type]`,
                item.type,
              )
              if (item.threshold) {
                formData.append(
                  `campaign_offer[coupons][${index}][threshold]`,
                  item.threshold,
                )
              }
              if (item.description) {
                formData.append(
                  `campaign_offer[coupons][${index}][description]`,
                  item.description,
                )
              }
              if (item.amount_off) {
                formData.append(
                  `campaign_offer[coupons][${index}][amount_off]`,
                  item.amount_off.toString(),
                )
              }
              if (item.percentage_off) {
                formData.append(
                  `campaign_offer[coupons][${index}][percentage_off]`,
                  item.percentage_off.toString(),
                )
              }
            })
          }
        }
      }
    }
    if (params.trigger_of_date_time) {
      formData.append('trigger_of_date_time', params.trigger_of_date_time)
    }
    if (params.trigger_type) {
      formData.append('trigger_type', params.trigger_type)
    }
    if (params.campaign_audience) {
      const {
        type,
        conditional_type,
        lifetime_order_count_operator,
        lifetime_order_count,
        recent_order_within_unit,
        recent_order_within,
      } = params.campaign_audience
      formData.append('campaign_audience[type]', type || '')
      formData.append(
        'campaign_audience[conditional_type]',
        conditional_type || '',
      )
      formData.append(
        'campaign_audience[recent_order_within_unit]',
        recent_order_within_unit || '',
      )
      formData.append(
        'campaign_audience[recent_order_within]',
        recent_order_within || '',
      )
      formData.append(
        'campaign_audience[lifetime_order_count_operator]',
        lifetime_order_count_operator || '',
      )
      formData.append(
        'campaign_audience[lifetime_order_count]',
        lifetime_order_count || '',
      )
    }

    const response = await axios.request({
      url: `locations/${locationId}/campaigns/${params.id}`,
      data: formData,
      params: {
        _method: 'put',
      },
      method: 'post',
      cache: {
        ignoreCache: true,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
}

export const sendTestEmail = (
  id: number,
  email: string,
  locationId: string,
) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/campaigns/${id}/test`,
      data: {
        email,
        // fail: 1,
      },
      method: 'post',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

export const getCampaignJobs = ({
  locationId,
  id,
  pageSize,
  page,
}: {
  locationId: string
  id: string
  pageSize: number
  page: number
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/campaign_jobs`,
      params: {
        campaign_id: id,
        per_page: pageSize,
        page,
      },
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

export const showCampaign = (id: string, locationId: string) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/campaigns/${id}`,
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

export const getTemplates = (id: string) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${id}/campaign_templates`,
      params: {
        type: 'automated',
      },
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

// 删除 one_off 活动
export async function deleteOneOffApi(id: number, locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/campaigns/${id}`,
    method: 'delete',
  })
  return response.data
}

// set up auto campaign
export const setUpAutoCampaign = ({
  id,
  note,
}: {
  id: string
  note: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `${API_DOMAIN}/client_dashboard/campaign_interest`,
      data: {
        user_brand_id: id,
        note,
      },
      method: 'post',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

// get up auto campaign
export const getUpAutoCampaign = (id: string) => {
  return async () => {
    const response = await axios.request({
      url: `${API_DOMAIN}/client_dashboard/user_of_campaign_interest`,
      params: {
        user_brand_id: id,
      },
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}
