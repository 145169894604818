import { useEffect, useState } from 'react'

import Table from 'components/TableAndCell/Table'
import Badge from 'components/Tag/Badge'
import useDebounce from 'hooks/useDebounce'
import useSearch from 'hooks/useSearch'
import moment from 'moment'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getJobApplicationsApi,
  updateJobApplicationApi,
  deleteJobApplicationApi,
} from 'redux/recruiting'
import { formatPhoneNumber } from 'utils/digit'

import CandidateDrawer from './components/CandidateDrawer'
import CandidateFilters from './components/CandidateFilters'
import JobCategoryIcon from './components/JobCategoryIcon'
import { TABLE_STATUS_OPTIONS } from './helps/constant'

type TFormData = {
  page: number
  per_page: number
  keyword: string
  status: string[]
  job_category_id: number | null
  title: string
}

export default function ManageCandidatesPage(): JSX.Element {
  const { keyword: urlKeyword } = useSearch()
  const { locationId, brandId } =
    useParams<{ locationId: string; brandId: string }>()
  const history = useHistory()
  const [drawerData, setDrawerData] = useState<{
    open: boolean
    id: null | number
  }>({
    open: false,
    id: null,
  })
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [tableData, setTableData] = useState<any>([])
  const [formData, setFormData] = useState<TFormData>({
    page: 1,
    per_page: 20,
    keyword: '',
    status: [],
    job_category_id: null,
    title: '',
  })

  const changeFormData = (name: string, value: any) => {
    let newFormData = { ...formData }
    if (name === 'per_page') {
      // per_page 改变，回到 page === 1
      newFormData = { ...formData, [name]: value, page: 1 }
    } else {
      newFormData = { ...formData, [name]: value }
    }
    setFormData(newFormData)
    name === 'keyword'
      ? debounceGetJobApplications(newFormData)
      : getJobApplications(newFormData)
  }

  const columns = [
    {
      className: 'w-[192px]',
      key: 'status',
      name: 'STATUS',
      custom: (value: any) => {
        const color = TABLE_STATUS_OPTIONS[value]?.color
        const txt = TABLE_STATUS_OPTIONS[value]?.value
        return (
          <div>
            <Badge color={color}>{txt}</Badge>
          </div>
        )
      },
    },
    {
      key: 'applicant',
      name: 'APPLICANT',
      custom: (value: any, item: any) => {
        return (
          <div>
            {item?.first_name} {item?.last_name}
          </div>
        )
      },
    },
    {
      className: 'w-40',
      key: 'phone',
      name: 'CELLPHONE',
      custom: (value: any) => {
        return <div>{value ? formatPhoneNumber(value) : '-'}</div>
      },
    },
    {
      key: 'title',
      name: 'JOB TITLE',
    },
    {
      className: 'w-40',
      key: 'name',
      name: 'CATEGORY',
      custom: (value: any) => {
        return (
          <div className='flex space-x-1 text-lead'>
            <JobCategoryIcon type={value} />
            <p>{value}</p>
          </div>
        )
      },
    },
    {
      className: 'w-40',
      key: 'created_at',
      name: 'SUBMISSION',
      align: 'right',
      custom: (value: any) => {
        return <div>{value ? moment(value).format('MM/DD/YYYY') : '-'}</div>
      },
    },
  ]

  const getJobApplications = async (newFormData: TFormData) => {
    try {
      setTableLoading(true)
      const res = await getJobApplicationsApi(locationId, newFormData)
      setTableData(res?.data || [])
      setTotal(res?.total || 0)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setTableLoading(false)
    }
  }

  const debounceGetJobApplications = useDebounce(newFormData => {
    getJobApplications(newFormData)
  }, 1000)

  const updateJobApplicationData = async (id: number, status: string) => {
    try {
      setUpdateLoading(true)
      await updateJobApplicationApi(locationId, id, status)
      setDrawerData({
        open: false,
        id: null,
      })
      getJobApplications(formData)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setUpdateLoading(false)
    }
  }

  const deleteJobApplication = async (id: number) => {
    try {
      setDeleteLoading(true)
      await deleteJobApplicationApi(locationId, id)
      setDrawerData({
        open: false,
        id: null,
      })
      getJobApplications(formData)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setDeleteLoading(false)
    }
  }

  useEffect(() => {
    if (urlKeyword) {
      // 有初始 keyword
      const newFormData = {
        ...formData,
        keyword: urlKeyword,
      }
      setFormData(newFormData)
      getJobApplications(newFormData)
      // 删掉 url 上 keyword 的信息
      history.replace(
        `/brand/${brandId}/location/${locationId}/recruiting/candidates`,
      )
    } else {
      getJobApplications(formData)
    }
  }, [])

  return (
    <div className='p-8'>
      <CandidateFilters
        formData={formData}
        changeFormData={(key, value) => changeFormData(key, value)}
      />
      <div className='mt-6'>
        <Table
          loading={tableLoading}
          isPageSet={true}
          current={formData.page}
          containerClassName='text-sm	 text-ink rounded-lg border border-solid border-zinc overflow-hidden'
          columns={columns}
          data={tableData}
          total={total}
          totalText={'results'}
          onPageNoChange={page => {
            changeFormData('page', page)
          }}
          onPageSizeChange={per_page => {
            changeFormData('per_page', per_page)
          }}
          clickedRow={item =>
            setDrawerData({
              open: true,
              id: item.id,
            })
          }
        />
      </div>
      <CandidateDrawer
        id={drawerData.id!}
        open={drawerData.open}
        deleteLoading={deleteLoading}
        updateLoading={updateLoading}
        updateJobApplicationData={updateJobApplicationData}
        deleteJobApplication={deleteJobApplication}
        toggle={() =>
          setDrawerData({
            open: false,
            id: null,
          })
        }
      />
    </div>
  )
}
