import { useState, useRef } from 'react'

import BreadCrumbs from 'components/BreadCrumbs'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { setLockModalOpen, setMenuItemList } from 'redux/onlineMenu'

import MenuItemDetailPage from './components/MenuItemDetailPage'
import MenuItemListPage from './components/MenuItemListPage'
import MenuLeftNav from './components/MenuLeftNav'
import MenuLockedModal from './components/MenuLockedModal'
import MenuModifierPage from './components/MenuModifierPage'

export default function OnlineMenuPage(): JSX.Element {
  const dispatch = useAppDispatch()
  const { lockModalOpen, menuItemList } = useAppSelector(
    state => state.onlineMenu,
  )
  const [updateMenuNav, setUpdateMenuNav] = useState(false)
  const [openCollection, setOpenCollection] = useState(true)
  const [loading, setLoading] = useState(false)
  const [menuId, setMenuId] = useState(0)
  const { path } = useRouteMatch()
  const rightItemListDom = useRef<HTMLDivElement>(null)

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <div className='flex overflow-hidden'>
            <MenuLeftNav
              openMenu={openCollection}
              updateMenuNav={updateMenuNav}
              rightItemListDom={rightItemListDom}
              onChangeMenuNav={value => {
                if (typeof value === 'boolean' && menuItemList?.length === 0) {
                  setLoading(value)
                }
                setUpdateMenuNav(false)
              }}
              onChangeMenuItem={(itemList, id, isCollection) => {
                dispatch(setMenuItemList([...itemList]))
                setMenuId(id)
                isCollection && setOpenCollection(!openCollection)
              }}
            />
            <div className='flex-1 mr-10'>
              <BreadCrumbs className='mx-0 my-6' />
              {openCollection ? (
                <MenuItemListPage
                  initLoading={loading}
                  menuItemList={menuItemList}
                  rightItemListDom={rightItemListDom}
                  onChange={() => setUpdateMenuNav(true)}
                />
              ) : (
                <MenuModifierPage
                  initLoading={loading}
                  modifierId={menuId}
                  onChange={() => setUpdateMenuNav(true)}
                />
              )}
            </div>
          </div>
        </Route>
        <Route exact path={`${path}/menu-item-detail/:menuItemId`}>
          <div>
            <BreadCrumbs className='min-w-[980px] mx-10 my-6' />
            <MenuItemDetailPage />
          </div>
        </Route>
      </Switch>
      <MenuLockedModal
        open={lockModalOpen}
        toggle={() => dispatch(setLockModalOpen(!lockModalOpen))}
      />
    </>
  )
}
