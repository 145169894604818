import cn from 'classnames'
import ReactSlider from 'react-slider'

export type TLabelProp = {
  className?: string
  discount?: number
  min?: number
  max?: number
  step?: number
  value: number
  disabled?: boolean
  onChange: (value: number) => void
}

export default function DragBar({
  className,
  value,
  discount = 0,
  min = 0,
  max = 100,
  step = 1,
  disabled,
  onChange,
}: TLabelProp): JSX.Element {
  // 当 discount 逼近 max 时，滑块会被挤出滚动条故再此处做一个补偿
  const barWidth = discount > 149 ? 24 : 0
  const discountWidth = (discount / (max - min + discount)) * 100
  return (
    <div className={cn('ru-dragbar', className)}>
      {discount > 0 && (
        <div
          className='w-20 ru-dargbar-extra'
          style={{ width: `calc(${discountWidth}% - ${barWidth}px` }}
        />
      )}
      <ReactSlider
        min={min}
        max={max}
        disabled={min === max || disabled}
        step={step}
        value={value}
        className={cn('dragbar-slider', discount > 0 ? 'more-than-0' : '')}
        trackClassName='dragbar-track'
        thumbClassName='dragbar-thumb'
        renderThumb={props => (
          <div {...props}>
            <div />
            <div />
          </div>
        )}
        onChange={onChange}
      />
    </div>
  )
}
