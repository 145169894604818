import { useEffect } from 'react'

import CalendarField from 'components/CalendarField'
import { Select } from 'components/Select'
import moment from 'moment'
import type { TCouponForm } from 'types/coupon'

import { COUPON_EXPIRATION_OPTIONS } from '../helpers/constant'

type TSelectDateFieldProps = {
  disabled: boolean
  formData: TCouponForm
  prevEndDate: string | null
  couponStatus: string
  onFormDataChange: (type: string, value: any) => void
}

export default function SelectDateField({
  disabled,
  formData,
  prevEndDate,
  couponStatus,
  onFormDataChange,
}: TSelectDateFieldProps) {
  const {
    end_date_select: endDateSelect,
    start_date: startDate,
    end_date: endDate,
  } = formData

  useEffect(() => {
    if (endDateSelect === 1 && moment(endDate).isBefore(startDate)) {
      // end_date 需要在 start_date 之后
      onFormDataChange('end_date', null)
    }
  }, [endDateSelect, startDate, endDate])

  return (
    <>
      <Select
        label='Coupon expiration'
        options={COUPON_EXPIRATION_OPTIONS}
        placeholder='Select'
        value={endDateSelect}
        onChange={value => onFormDataChange('end_date_select', value)}
        disabled={couponStatus === 'cancelled'}
      />

      <div className='flex justify-between space-x-4'>
        <CalendarField
          label='Start date'
          className='flex-1'
          calendarClassName={'bottom-10'}
          onChange={time => {
            const nowTime = moment(time)
            if (
              endDateSelect === 0 ||
              !endDate ||
              (endDate && nowTime.isSameOrBefore(moment(endDate))) ||
              startDate.length === 0
            ) {
              onFormDataChange(
                'start_date',
                nowTime.format('YYYY-MM-DD HH:mm:ss'),
              )
            }
          }}
          date={startDate ? new Date(startDate) : undefined}
          value={
            startDate
              ? moment(startDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
              : ''
          }
          disabled={disabled}
        />
        {endDateSelect ? (
          <CalendarField
            label='Expiration date'
            className='flex-1'
            calendarClassName={'right-0 bottom-10'}
            onChange={time => {
              const nowTime = moment(time)
              if (
                nowTime.isSameOrAfter(moment(startDate)) ||
                startDate.length === 0
              ) {
                onFormDataChange(
                  'end_date',
                  nowTime.format('YYYY-MM-DD') + ' 23:59:59',
                )
              }
            }}
            date={endDate ? new Date(endDate) : undefined}
            calendarRestProps={{
              minDate:
                prevEndDate && disabled
                  ? new Date(prevEndDate)
                  : startDate
                  ? new Date(startDate)
                  : new Date(),
            }}
            value={
              endDate
                ? moment(endDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
                : ''
            }
            disabled={couponStatus === 'cancelled'}
          />
        ) : null}
      </div>
    </>
  )
}
