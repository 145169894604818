import { Dollar, Percentage } from '@rushable/icons'
import Field from 'components/Field'
import { Select } from 'components/Select'
import type { TCouponForm } from 'types/coupon'

import FreeItemFields from './FreeItemFields'
import { COUPON_TYPE_ITEMS } from '../helpers/constant'

type TCouponTypesFieldProps = {
  disabled: boolean
  formData: TCouponForm
  cancelledItemName: string
  onFormDataChange: (type: string, value: any) => void
}

export default function CouponTypesField({
  disabled,
  formData,
  cancelledItemName,
  onFormDataChange,
}: TCouponTypesFieldProps) {
  const type = formData.type

  const typeRender = () => {
    if (type === 'flat') {
      return (
        <div className='flex space-x-4'>
          <Field
            containerClassName='flex-1'
            label='Discount amount'
            name='discount-amount'
            align='left'
            type='text'
            inputMode='money'
            suffix={<Dollar className='text-silver' />}
            placeholder='5.00'
            value={formData.amount_off}
            onChangeMoney={e => onFormDataChange('amount_off', e)}
            disabled={disabled}
          />
          <Field
            containerClassName='flex-1'
            label='Order minimum'
            name='Order minimum'
            align='left'
            type='text'
            inputMode='money'
            suffix={<Dollar className='text-silver' />}
            placeholder='25.00'
            value={formData.threshold}
            onChangeMoney={e => onFormDataChange('threshold', e)}
            disabled={disabled}
          />
        </div>
      )
    }
    if (type === 'percentage') {
      return (
        <div className='flex space-x-4'>
          <Field
            containerClassName='flex-1'
            label='Discount percentage'
            name='discount percentage'
            align='left'
            type='text'
            inputMode='money'
            append={<Percentage className='text-silver' />}
            placeholder='10'
            value={formData.percentage_off}
            onChangeMoney={e => onFormDataChange('percentage_off', e)}
            disabled={disabled}
          />
          <Field
            containerClassName='flex-1'
            label='Order minimum'
            name='Order minimum'
            align='left'
            type='text'
            inputMode='money'
            suffix={<Dollar className='text-silver' />}
            placeholder='25.00'
            value={formData.threshold}
            onChangeMoney={e => onFormDataChange('threshold', e)}
            disabled={disabled}
          />
        </div>
      )
    }
  }

  return (
    <>
      <Select
        label='Coupon type'
        options={COUPON_TYPE_ITEMS}
        placeholder='Select'
        value={formData.type}
        onChange={value => onFormDataChange('type', value)}
        disabled={disabled}
      />
      {typeRender()}
      {type === 'free_item' && (
        <FreeItemFields
          disabled={disabled}
          formData={formData}
          cancelledItemName={cancelledItemName}
          onFormDataChange={onFormDataChange}
        />
      )}
    </>
  )
}
