import React from 'react'

import {
  DoorDash,
  UberEats,
  GrubHub,
  Seamless,
  ChowBus,
  Deliveroo,
  Caviar,
  Favor,
  Postmates,
  MarketOther,
  MarketCar,
} from '@rushable/icons'

export default function MarketPlaceIcon({
  link,
  source,
}: {
  link: string
  source: string | null
}): JSX.Element {
  const marketplaceIcon = {
    doordash: <DoorDash />,
    ubereats: <UberEats />,
    grubhub: <GrubHub />,
    seamless: <Seamless />,
    chowbus: <ChowBus />,
    deliveroo: <Deliveroo />,
    caviar: <Caviar />,
    favor: <Favor />,
    postmates: <Postmates />,
    other: <MarketOther />,
  }

  const keyOfMarketplaceIcon = Object.keys(marketplaceIcon)
  link = keyOfMarketplaceIcon.find(e => link.includes(e)) || ''
  source = source?.toLocaleLowerCase() || ''
  const marketplace = source || link || ''

  const icon: JSX.Element =
    marketplace in marketplaceIcon
      ? (marketplaceIcon as any)[marketplace]
      : marketplaceIcon['other']

  return (
    <div className='w-12 h-12'>
      <icon.type size={48} className='text-ink rounded-lg overflow-hidden' />
    </div>
  )
}
