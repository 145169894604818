import React, { useState, useEffect } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import { Spin } from 'components/Loading'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { showStripeAccountAPI } from 'redux/brand/actions'

import SellingGiftCard from './components/SellingGiftCard'

export default function StartGiftCardPage(): JSX.Element {
  const history = useHistory()
  const { url } = useRouteMatch()
  const [loading, setLoading] = useState(false)
  const { brandId } = useParams<TParamTypes>()

  const getStripeAccount = async () => {
    setLoading(true)
    try {
      const res = await showStripeAccountAPI(brandId)
      if (res.status === 'connected') {
        history.push(`${url}/gift-card-list`)
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  useEffect(() => {
    getStripeAccount()
  }, [])

  return (
    <AdminPage>
      <AdminPageTitle title='Gift Card' />
      <Spin spining={loading} top>
        <div className='flex flex-col items-center mx-auto pb-6'>
          <SellingGiftCard onClick={() => history.push(`${url}/create`)} />
        </div>
      </Spin>
    </AdminPage>
  )
}
