import OrderGeneralSettingPage from 'pages/orderSetting/OrderGeneralSettingPage'
import OrderTypeSettingPage from 'pages/orderSetting/OrderTypeSettingPage'
const ORDERGENERALSETTING: TRouteType = {
  path: 'general-setting',
  name: 'ORDERGENERALSETTING',
  label: 'General Settings',
  component: OrderGeneralSettingPage,
}
const ORDERTYPESETTING: TRouteType = {
  path: 'order-types',
  name: 'ORDERTYPESETTING',
  label: 'Order Types',
  component: OrderTypeSettingPage,
}

export const orderSettingRoutes = [ORDERGENERALSETTING, ORDERTYPESETTING]
