import React, { useState, useEffect } from 'react'

import Button from 'components/Button'
import RewardItemCard from 'components/RewardItemCard'
import RewardItemSetupModal from 'components/RewardItemSetupModal'
import Timeline from 'components/Timeline'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  updateLoyaltyConfigAPI,
  getLoyaltyMenuItemsAPI,
} from 'redux/brand/actions'

import { STEP_LIST } from '../helpers/constant'

type TRewardsSettingProp = {
  formData: any
  rewardList: any
  onFormChange: (data: any) => void
  updateRewardList: () => void
}
export default function RewardsSetting({
  formData,
  rewardList,
  onFormChange,
  updateRewardList,
}: TRewardsSettingProp): JSX.Element {
  const rewardItems = [0, 1, 2, 3, 4, 5, 6, 7]
  const { brandId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)
  const [menuItems, setMenuItems] = useState<any>([])
  const [modalInfo, setModalInfo] = useState({
    type: '',
    open: false,
    data: {},
  })

  useEffect(() => {
    getLoyaltyMenuItems()
  }, [])

  const handleChangeForm = (data: any) => {
    setModalInfo({
      type: modalInfo.type,
      open: modalInfo.open,
      data: {
        ...modalInfo.data,
        ...data,
      },
    })
  }

  const getLoyaltyMenuItems = async () => {
    try {
      const res = await getLoyaltyMenuItemsAPI(brandId)
      setMenuItems(res)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
  }

  const updateStatus = async () => {
    setLoading(true)
    try {
      const params = {
        id: formData.id,
        current_step: 'other_setting',
      }

      const res = await updateLoyaltyConfigAPI(params, brandId)
      toast.success(res.message)
      onFormChange(res.brandLoyaltyConfig)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <div className='flex flex-col items-center  space-y-8 mt-8 pb-[76px]'>
      <Timeline className='w-[720px]' list={STEP_LIST} current={2} isEqual />
      <div className='w-[720px] mt-8 flex flex-col items-center  space-y-8 text-ink'>
        <div className='flex flex-wrap'>
          {rewardItems.map(v => {
            return (
              <RewardItemCard
                key={v}
                className={`w-[348px] ${v < 6 && 'mb-6'} ${
                  v % 2 === 0 && 'mr-6'
                }`}
                type={rewardList[v] ? 'default' : 'add'}
                title={rewardList[v]?.name ?? ''}
                pts={rewardList[v]?.spent_points ?? ''}
                image_url={rewardList[v]?.image_url ?? ''}
                disabled={rewardList[v]?.status === 'inactive'}
                rank={rewardList[v]?.minimum_rank ?? ''}
                onAddClick={() =>
                  setModalInfo({
                    type: rewardList[v] ? 'update' : 'add',
                    open: true,
                    data: rewardList[v] ?? {},
                  })
                }
              />
            )
          })}
        </div>
        <div className='text-center text-base'>
          We recommend adding as many rewards as possible after this initial
          setting. More choices mean more engaged customers and better program
          results 👍.
        </div>
        <div className='w-full flex space-x-6'>
          <Button
            className='w-[200px]'
            color={'secondary'}
            onClick={() => onFormChange({ current_step: 'points_setting' })}
          >
            Go back
          </Button>
          <Button
            loading={loading}
            className='flex-1'
            color={'primary'}
            disabled={rewardList.length < 8}
            onClick={() => updateStatus()}
          >
            Continue to other setting
          </Button>
        </div>
      </div>
      <RewardItemSetupModal
        type={modalInfo.type}
        rewardData={modalInfo.data}
        open={modalInfo.open}
        menuItems={menuItems}
        updateRewardList={() => updateRewardList()}
        onFormChange={data => handleChangeForm(data)}
        toggle={() => setModalInfo({ type: '', open: false, data: {} })}
      />
    </div>
  )
}
