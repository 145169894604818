import React from 'react'

import cn from 'classnames'
import SkeletonText from 'components/Skeleton/SkeletonText'

export default function SkeletonLeftNav(): JSX.Element {
  return (
    <div className={cn('pt-28 pb-28 h-full ')}>
      <SkeletonText mode='dark' className='h-6 w-full' />
      <SkeletonText mode='dark' className='h-6 w-full mt-4' />
      <SkeletonText mode='dark' className='h-2 w-3/4 mt-4' />
      <SkeletonText mode='dark' className='h-2 w-3/4 mt-4' />
      <SkeletonText mode='dark' className='h-2 w-3/4 mt-4' />
      <SkeletonText mode='dark' className='h-2 w-3/4 mt-4' />
    </div>
  )
}
