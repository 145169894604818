import React, { useState } from 'react'

import { AngleLeft } from '@rushable/icons'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import Timeline from 'components/Timeline'
import useSearch from 'hooks/useSearch'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

import Step1Email from './components/Step1Email'
import Step2Dns from './components/Step2Dns'
import Step3Verification from './components/Step3Verification'
import { stepsArr, getRoutePath } from './helps'

const SetupEmail = (): JSX.Element => {
  const history = useHistory()
  const search = useSearch()
  const [curStep, setCurStep] = useState(+search.step || 0)
  const { selectedLocation } = useAppSelector<any>(state => state.location)

  const id = selectedLocation?.location_business_setting?.id || ''
  const emailDomain = selectedLocation?.brand_site_url?.split('//')?.[1] || ''
  const emailPrefix =
    selectedLocation?.location_business_setting?.marketing_email_prefix || ''
  const emailStatus =
    selectedLocation?.brand?.brand_business_setting?.sendgrid_sender_auth_status
  return (
    <div>
      <AdminPageTitle
        title='Email prefix for marketing'
        left={
          <Button
            color='secondary-link'
            onClick={() => {
              const path = getRoutePath()
              history.replace(path, { refresh: true })
            }}
          >
            <AngleLeft size={16} />
            <span className='ml-1'>GO BACK</span>
          </Button>
        }
      />
      <div className='my-8 mx-[240px]'>
        {!(!emailPrefix && emailStatus !== 'none') && (
          <Timeline list={stepsArr} current={curStep} lineClassName='top-3' />
        )}
      </div>
      {curStep === 0 && (
        <Step1Email
          id={id}
          emailPrefix={emailPrefix}
          emailDomain={emailDomain}
          emailStatus={emailStatus}
          onStep={step => setCurStep(step)}
        />
      )}
      {curStep === 1 && <Step2Dns id={id} onStep={step => setCurStep(step)} />}
      {curStep === 2 && <Step3Verification onStep={step => setCurStep(step)} />}
    </div>
  )
}

export default SetupEmail
