import React, { useState, useEffect } from 'react'

import cn from 'classnames'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import { toast } from 'react-toastify'
import { sendSlackForNewBrand } from 'redux/brand/actions'
import { useAppDispatch } from 'redux/hooks'

import { GOOD_TIME_TO_CONTACT_OPTIONS } from '../helpers/constant'

type TAddBrandModal = {
  open: boolean
  toggle: () => void
}

export default function AddBrandModal({ open, toggle }: TAddBrandModal) {
  const [name, setName] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [time, setTime] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const handleConfirm = async () => {
    const params = {
      name,
      locationAddress: address,
      whenToContact: time,
    }
    try {
      setLoading(true)
      await dispatch(sendSlackForNewBrand(params))
      setSuccess(true)
      setLoading(false)
    } catch (error: any) {
      if (error.message) {
        toast.warn(error.message)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setName('')
      setAddress('')
      setTime('')
      setSuccess(false)
    }, 500)
  }, [open])

  return (
    <ModalFull
      open={open}
      toggle={toggle}
      title='Add New Brand'
      footer={
        success ? (
          <div className='flex justify-end'>
            <Button color='primary' className='w-full' onClick={toggle}>
              CLOSE
            </Button>
          </div>
        ) : (
          <div className='flex justify-end'>
            <Button
              color='secondary'
              className='mr-4'
              onClick={toggle}
              type='button'
            >
              CANCEL
            </Button>
            <Button color='primary' onClick={handleConfirm} loading={loading}>
              CONFIRM
            </Button>
          </div>
        )
      }
    >
      <div className='mt-6'>
        <div
          className={cn('text-base leading-6 text-center  pl-3 pr-3 text-ink', {
            'pb-6': !success,
          })}
        >
          {success
            ? 'Thank you, our team will get in touch with you soon!'
            : 'Please share with us the name of your new brand and the location information, our team will reach back to you as soon as possible to help you with the setup.'}
        </div>
        {!success && (
          <>
            <Field
              label='New brand name'
              name='name'
              placeholder='Input brand name'
              type='name'
              className='mb-6 w-full'
              onChange={e => setName(e.target.value)}
              value={name}
            />
            <Field
              label='New location address'
              name='address'
              placeholder='Input address'
              type='address'
              className='mb-6 w-full'
              onChange={e => setAddress(e.target.value)}
              value={address}
            />
            <Select
              options={GOOD_TIME_TO_CONTACT_OPTIONS}
              placeholder='Select option '
              value={time}
              className='w-full'
              label='When will be a good time to contact'
              onChange={value => {
                setTime(value)
              }}
            />
          </>
        )}
      </div>
    </ModalFull>
  )
}
