import React from 'react'

import {
  Direct,
  Unknown,
  Google,
  Facebook,
  Yelp,
  Instagram,
  TikTok,
  Campaign,
  MobileApp,
} from '@rushable/icons'

import type { TCommonIconProp } from './type'

type TSourceIconRenderProps = {
  type?: string
  iconParams?: TCommonIconProp
}

function isMatch(url: string | undefined, keyword: string) {
  if (!url) {
    return false
  }
  const regex = new RegExp('^(?:[a-zA-Z0-9-]+\\.)?' + keyword + '\\.[^.]+')
  return regex.test(url)
}

export default function SourceIconRender({
  type,
  iconParams,
}: TSourceIconRenderProps): JSX.Element {
  if (type === '(direct)') {
    return <Direct {...iconParams} />
  } else if (type === 'rushable_campaign') {
    return <Campaign {...iconParams} />
  } else if (type === 'sms_campaign') {
    return <MobileApp {...iconParams} />
  } else if (type === 'google' || type === 'OwG' || isMatch(type, 'google')) {
    return <Google {...iconParams} />
  } else if (
    type === 'facebook' ||
    type === 'Facebook' ||
    isMatch(type, 'facebook')
  ) {
    return <Facebook {...iconParams} />
  } else if (
    type === 'instagram' ||
    type === 'Instagram' ||
    isMatch(type, 'instagram')
  ) {
    return <Instagram {...iconParams} />
  } else if (type === 'yelp' || isMatch(type, 'yelp')) {
    return <Yelp {...iconParams} />
  } else if (type === 'tiktok' || isMatch(type, 'tiktok')) {
    return <TikTok {...iconParams} />
  } else {
    return <Unknown {...iconParams} />
  }
}

export function sourceIconMap(source: string) {
  if (source === '(direct)') {
    return 'Direct Visit'
  } else if (source === 'rushable_campaign') {
    return 'Campaign'
  } else if (source === 'sms_campaign') {
    return 'Mobile App'
  } else if (
    source === 'google' ||
    source === 'OwG' ||
    isMatch(source, 'google')
  ) {
    return 'Google'
  } else if (
    source === 'facebook' ||
    source === 'Facebook' ||
    isMatch(source, 'facebook')
  ) {
    return 'Facebook'
  } else if (
    source === 'instagram' ||
    source === 'Instagram' ||
    isMatch(source, 'instagram')
  ) {
    return 'Instagram'
  } else if (source === 'yelp' || isMatch(source, 'yelp')) {
    return 'Yelp'
  } else if (source === 'tiktok' || isMatch(source, 'tiktok')) {
    return 'Tiktok'
  } else {
    return ''
  }
}
