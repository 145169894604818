import axios from 'utils/axiosApi'

export async function getIntegrationInfoApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/integrations`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getLocationCheckMateConfig(configId: string | null) {
  const response = await axios.request({
    url: `location_check_mate_configs/${configId}`,
    method: 'get',
  })
  return response.data
}

export async function bindCheckMateApi(
  locationId: number | undefined,
  configId: string | null,
) {
  const response = await axios.request({
    url: `location_check_mate_configs/${configId}`,
    data: {
      location_id: locationId,
    },
    method: 'patch',
  })
  return response.data
}

export async function getSquareLocations(locationId: string, id?: number) {
  const response = await axios.request({
    url: `locations/${locationId}/square_locations/${id}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getCloverMerchant(locationId: string, id?: number) {
  const response = await axios.request({
    url: `locations/${locationId}/clover_locations/${id}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function confirmSquareLocation(
  locationId: string,
  confirmLocationId: string,
) {
  const response = await axios.request({
    url: `locations/${locationId}/square_locations`,
    data: {
      external_location_id: confirmLocationId,
    },
    method: 'post',
  })
  return response.data
}

export async function updateSquareStatus(locationId: string, configId: number) {
  const response = await axios.request({
    url: `locations/${locationId}/square_locations/${configId}`,
    data: {},
    method: 'patch',
  })
  return response.data
}

export async function updateCloverStatus(locationId: string, configId: number) {
  const response = await axios.request({
    url: `locations/${locationId}/clover_locations/${configId}`,
    data: {},
    method: 'patch',
  })
  return response.data
}

export async function disconnectSquare(locationId: string, configId: number) {
  const response = await axios.request({
    url: `locations/${locationId}/square_locations/${configId}`,
    data: {},
    method: 'delete',
  })
  return response.data
}

export async function disconnectClover(locationId: string, configId: number) {
  const response = await axios.request({
    url: `locations/${locationId}/clover_locations/${configId}`,
    data: {},
    method: 'delete',
  })
  return response.data
}
