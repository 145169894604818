import React from 'react'

import cn from 'classnames'

type Toption = {
  label: string
  value: string | number
}

export type TTabChangeBarProp = {
  activeItem: string
  className?: string
  containerClassName?: string
  items: Array<string | Toption>
  onClick?: (e: any) => void
}

export default function TabChangeBar({
  activeItem,
  className,
  containerClassName,
  items,
  onClick,
}: TTabChangeBarProp): JSX.Element {
  return (
    <div className={cn(['flex bg-field p-1 rounded-64', className])}>
      {items.map((item, index) => {
        const curValue = typeof item === 'string' ? item : item.value
        const curLabel = typeof item === 'string' ? item : item.label
        return (
          <div
            key={index}
            className={cn([
              'flex flex-1 justify-center items-center rounded-64 text-sm py-2 font-bold',
              { 'bg-white': activeItem === curValue },
              { 'cursor-pointer': activeItem !== curValue },
              containerClassName,
            ])}
            onClick={() => onClick && onClick(curValue)}
          >
            {curLabel}
          </div>
        )
      })}
    </div>
  )
}
