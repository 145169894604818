import React, { useState, useEffect } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import UserManagementDetail from 'pages/user/UserManagementDetail'
import UserManagementPage from 'pages/user/UserManagementPage'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
  useParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import { getUserProfileApi } from 'redux/user/actions'

import MyInfoPage from './MyInfoPage'
import NotificationSettingPage from './NotificationSettingPage'

export default function ProfilePage(): JSX.Element {
  const { brandId = '' } = useParams<TParamTypes>()
  const history = useHistory()
  const { path, url } = useRouteMatch()

  const [state, setState] = useState<Record<string, any>>({})

  useEffect(() => {
    getProfile()
  }, [])

  const getProfile = async () => {
    try {
      const res = await getUserProfileApi(brandId)
      setState({
        baseInfo: res,
        notification: res.userNotificationConfigs,
      })
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
  }
  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})
  return (
    <Switch>
      <Route exact path={`${path}/team-setting/users-detail/:userId`}>
        <UserManagementDetail />
      </Route>
      <Route path={path}>
        <AdminPage>
          <AdminPageTabs>
            <AdminPageTab
              text='My Information'
              active={activePath === 'my-info'}
              onClick={() => switchTab('my-info')}
            />
            <AdminPageTab
              text='Notification Setting'
              active={activePath === 'notification-setting'}
              onClick={() => switchTab('notification-setting')}
            />
            <AdminPageTab
              text='Team Setting'
              active={activePath === 'team-setting'}
              onClick={() => switchTab('team-setting')}
            />
          </AdminPageTabs>

          <div className='pt-16 pb-8 px-8'>
            <Switch>
              <Route path={`${path}/my-info`}>
                <MyInfoPage formData={state.baseInfo} getProfile={getProfile} />
              </Route>
              <Route path={`${path}/notification-setting`}>
                <NotificationSettingPage
                  data={state.notification}
                  getProfile={getProfile}
                />
              </Route>
              <Route path={`${path}/team-setting`}>
                <UserManagementPage />
              </Route>
              <Redirect from={`${path}`} to={`${path}/my-info`} exact />
            </Switch>
          </div>
        </AdminPage>
      </Route>
    </Switch>
  )
}
