import React from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FieldPrice } from 'components/Field'
import Switch from 'components/Switch'

import MenuAction from './MenuAction'

type TMenuModifierOptionItemProp = {
  item: any
  index: number
  openIndex?: number
  hideAction: boolean
  handleFormChange: (
    name: string,
    value: any,
    item?: string,
    index?: number,
  ) => void
  handleMenuAction: (name: string, index: number) => void
  setOpenIndex: (index: number) => void
}

export default function MenuModifierOptionItem({
  item,
  index,
  openIndex,
  hideAction,
  handleFormChange,
  handleMenuAction,
  setOpenIndex,
}: TMenuModifierOptionItemProp): JSX.Element {
  const { id } = item
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  let styles
  if (transform) {
    const { x, y }: any = transform
    styles = {
      transform: CSS.Transform.toString({ x, y, scaleX: 1, scaleY: 1 }),
      transition,
    }
  }

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      style={styles}
      className='flex items-center'
    >
      {hideAction && (
        <MenuAction
          className='mr-4'
          hasDetail={false}
          size={16}
          open={openIndex === index}
          onChange={v => handleMenuAction(v, index)}
          clickOutside={() => setOpenIndex(-1)}
        />
      )}
      <FieldPrice
        className='flex-1'
        name='Option name'
        textPlaceholder='Option name'
        pricePlaceholder='0.00'
        priceValue={item.unit_price}
        textValue={item.name}
        onChangeMoney={value =>
          handleFormChange('menu_modifier_options', value, 'unit_price', index)
        }
        onChangeText={value =>
          handleFormChange('menu_modifier_options', value, 'name', index)
        }
      />
      <Switch
        name='check_name'
        checked={item.status === 'active'}
        className='ml-4'
        onChange={v =>
          handleFormChange(
            'menu_modifier_options',
            v ? 'active' : 'inactive',
            'status',
            index,
          )
        }
      />
    </div>
  )
}
