import { SUPPORT_PHONE, SUPPORT_EMAIL } from 'data/constant'

export const stepsArr = [
  { label: '1', title: 'Email Prefix' },
  { label: '2', title: 'DNS Setting' },
  { label: '3', title: 'Verification' },
]

export const getHelpConstant = {
  email: 'Please pick a word for your email prefix',
  dns: 'Please update the following records to your DNS',
  verification: 'Verification in progress, please check back later.',
  phone: 'Please pick a desired phone number in your area',
}

export const taxStatusMap: any = {
  ready: {
    desc: 'Ready',
    color: 'blue',
  },
  accepted: {
    desc: 'Filed',
    color: 'green',
  },
  need_attention: {
    desc: 'Needs attention',
    color: 'yellow',
  },
}

export const getModalConstant = {
  email: {
    modalTitle: 'Update Email Prefix',
    modalContent: (
      <div className='text-center'>
        This is a sensitive action, please contact us for assistance at your
        earliest convenience by calling our support line at{' '}
        <span className='font-bold'>{SUPPORT_PHONE}</span> or email to{' '}
        <span className='font-bold'>{SUPPORT_EMAIL}</span>
      </div>
    ),
  },
  phone: {
    modalTitle: 'Get A New Number',
    modalContent: (
      <div className='text-center'>
        This is a sensitive action, please contact us for assistance at your
        earliest convenience by calling our support line at{' '}
        <span className='font-bold'>{SUPPORT_PHONE}</span> or email to{' '}
        <span className='font-bold'>{SUPPORT_EMAIL}</span>
      </div>
    ),
  },
  changeEntity: {
    modalTitle: 'CHANGE OF ENTITY',
    modalContent: (
      <div className='text-center'>
        To change the existing legal business entity, please contact our support
        team so we can set the tax information correctly with the change. You
        can reach us at your earliest convenience by calling our support line at{' '}
        <span className='font-bold'>{SUPPORT_PHONE}</span> or email to{' '}
        <span className='font-bold'>{SUPPORT_EMAIL}</span>{' '}
      </div>
    ),
  },
  contactUs: {
    modalTitle: 'IMPORTANT',
    modalContent: (
      <div className='text-center'>
        Additional information is required to generate and file your tax
        document. Please contact us at your earliest convenience by calling our
        support line at <span className='font-bold'>{SUPPORT_PHONE}</span> or
        email to <span className='font-bold'>{SUPPORT_EMAIL}</span>
      </div>
    ),
  },
}

export type ILegalstatus =
  | 'complete'
  | 'enabled'
  | 'reviewing'
  | 'restrict_soon'
  | 'restricted'
export const statusInit = {
  label: '',
  value: '',
  isHaveBlue: false,
}
export const LegalStatus = {
  complete: {
    label: <span className='font-bold text-green'>Complete</span>,
    value:
      'You have provided all required information to enable online ordering and receive payouts.',
    isHaveBlue: false,
  },
  enabled: {
    label: <span className='font-bold text-blue'>Enabled</span>,
    value:
      'More information will eventually be required before generating $10,000 sales.',
    isHaveBlue: true,
  },
  reviewing: {
    label: <span className='font-bold text-yellow'>Reviewing</span>,
    value:
      'Online ordering and payouts are paused while Stripe reviews your information.',
    isHaveBlue: false,
  },
  restrict_soon: {
    label: <span className='font-bold text-red'>Restrict soon</span>,
    value:
      'Your online ordering and payouts will be paused soon before additional information is updated.',
    isHaveBlue: true,
  },
  restricted: {
    label: <span className='font-bold text-red'>Restricted</span>,
    value:
      'Your online ordering and payouts are now paused until additional information is updated.',
    isHaveBlue: true,
  },
}

/**
 * 获取返回正确路由地址
 * @returns
 */
export function getRoutePath(): string {
  const routeName = location.pathname?.split('/')
  routeName.pop()
  return routeName.join('/')
}
