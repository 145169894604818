import React, { useState } from 'react'

import { Dollar } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import Timeline from 'components/Timeline'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateLoyaltyConfigAPI } from 'redux/brand/actions'

import { STEP_LIST } from '../helpers/constant'

type TIntroductionProp = {
  formData: any
  price: string | number
  handleUpdatePrice: (value: string) => void
  onFormChange: (data: any) => void
}

export default function Introduction({
  formData,
  price,
  handleUpdatePrice,
  onFormChange,
}: TIntroductionProp): JSX.Element {
  const [loading, setLoading] = useState(false)
  const { brandId } = useParams<TParamTypes>()

  const updateStatus = async () => {
    setLoading(true)
    try {
      let params: any = {
        id: formData.id,
        current_step: 'estimated_avg_check_settings',
      }

      if (formData.current_step === 'estimated_avg_check_settings') {
        params = {
          ...params,
          current_step: 'points_setting',
          order_average: price,
        }
      }

      const res = await updateLoyaltyConfigAPI(params, brandId)
      toast.success(res.message)
      onFormChange(res.brandLoyaltyConfig)
    } catch (e: any) {
      const msg = e.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <div className='flex flex-col items-center  space-y-8 mt-8'>
      <Timeline className='w-[720px]' list={STEP_LIST} current={0} isEqual />
      {formData?.current_step === 'introduction' ? (
        <>
          <div className='w-[560px] bg-ice text-ink rounded-lg border border-solid border-zinc'>
            <div className='px-6 py-4  font-bold border-b border-solid border-zinc'>
              How it works
            </div>
            <div className='px-6 py-4 flex flex-col space-y-3'>
              <div>💯 Customers earn points for every dollar spent</div>
              <div>🎁 Points can be used to redeem rewards, as set by you</div>
              <div>🥉 Bronze rank earns 10 points for every $1.00 spent</div>
              <div>🥈 Silver rank earns 11 points for every $1.00 spent</div>
              <div>🥇 Gold rank earns 13 points for every $1.00 spent</div>
              <div>
                🆙 The rank will go up with enough points earned in a given
                period
              </div>
              <div>
                ❤️ Higher rank unlocks exclusive benefits according to your
                settings
              </div>
              <div>
                🗓️ Points earned will expire after a period of time, as set by
                you
              </div>
            </div>
          </div>
          <Button
            className='w-[560px]'
            color='primary'
            onClick={() => updateStatus()}
            loading={loading}
          >
            Continue to setup
          </Button>
        </>
      ) : (
        <div className='w-[720px]'>
          <div className='w-full mb-8 flex justify-between items-center p-4 text-ink border border-solid border-zinc rounded-lg'>
            <div>The estimated average check size of your restaurant</div>
            <Field
              className='w-36'
              name='average'
              value={price}
              type='text'
              placeholder='0.00'
              align='right'
              inputMode='money'
              suffix={<Dollar size={16} className='text-silver' />}
              onChangeMoney={value => handleUpdatePrice(value)}
            />
          </div>
          <Button
            disabled={!price}
            className='w-full'
            color='primary'
            onClick={() => updateStatus()}
            loading={loading}
          >
            See recommended settings
          </Button>
        </div>
      )}
    </div>
  )
}
