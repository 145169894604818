import { useEffect, useState } from 'react'

import Tips from 'components/Tips'
import useSearch from 'hooks/useSearch'
import { useHistory } from 'react-router-dom'
import { getAccessToken } from 'utils/auth'

export default function RedirectIACM(): JSX.Element {
  const history = useHistory()
  const [textInfo, setTextInfo] = useState({ text: '', status: '' })
  const { config_id } = useSearch()

  useEffect(() => {
    if (config_id) {
      // iacm 跳转的相关逻辑
      history.push(
        `/${
          getAccessToken() ? 'select-restaurant' : 'login'
        }?redirect_from=iacm&config_id=${config_id}`,
      )
    }
    setTextInfo({ text: 'Invalid input', status: 'error' })
  }, [])

  return (
    <div className='flex justify-center'>
      {textInfo.text && <Tips status={textInfo.status} text={textInfo.text} />}
    </div>
  )
}
