import React, { useState, useEffect } from 'react'

import { AngleLeft, SolidRecepit } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getOrderDetailApi } from 'redux/orderHistory'

import OrderDetailMenu from './components/OrderDetailMenu'
import OrderDetailStyle from './components/OrderDetailStyle'

export default function OrderDetailPage(): JSX.Element {
  const history = useHistory()
  const [formData, setFormData] = useState<Record<string, any>>({})
  const { orderId, locationId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getOrderDetail()
  }, [])

  const getOrderDetail = async () => {
    setLoading(true)
    try {
      const res = await getOrderDetailApi(orderId, locationId)
      setFormData({ ...res })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <AdminPage>
      <AdminPageTitle
        title='Order Detail'
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            GO BACK
          </Button>
        }
        right={
          <Button
            color='secondary-link'
            inline
            disabled={!formData.receipt_link}
            onClick={() => window.open(`${formData.receipt_link}`, '_blank')}
          >
            <div className='whitespace-nowrap'>CUSTOMER RECEIPT</div>
            <SolidRecepit className='ml-1' size={16} />
          </Button>
        }
      />
      <Spin
        spining={loading}
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div
          style={{
            minHeight: 'calc(100vh - 144px)',
          }}
          className='flex'
        >
          <OrderDetailStyle formData={formData} onReload={getOrderDetail} />
          <OrderDetailMenu formData={formData} />
        </div>
      </Spin>
    </AdminPage>
  )
}
