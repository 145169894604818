import React from 'react'

export default function GiftCardSkeleton(): JSX.Element {
  return (
    <>
      <div className='w-full flex flex-col px-6 py-4 border border-zinc rounded-lg'>
        <div className='w-25 bg-slate rounded-lg h-6 animate-pulse pl-4 flex items-center'>
          <div className='bg-zinc rounded w-14 h-3'></div>
        </div>
        <div className='w-[200px] bg-slate rounded-lg h-6 animate-pulse pl-4 my-2 flex items-center'>
          <div className='bg-zinc rounded w-[168px] h-3'></div>
        </div>
        <div className='w-full bg-slate rounded-lg h-6 animate-pulse pl-4 flex items-center'>
          <div className='bg-zinc rounded w-[300px] h-3'></div>
        </div>
        <div className='w-25 bg-slate rounded-lg h-9 animate-pulse mt-4 flex items-center'></div>
      </div>
    </>
  )
}
