import { useEffect, useState, useRef } from 'react'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { getOrderSummaryApi } from 'redux/orderHistory'

import SummaryDetail from './SummaryDetail'

type TOrderSummaryProp = {
  open: boolean
  toggle: () => void
}

export default function OrderSummary({
  open,
  toggle,
}: TOrderSummaryProp): JSX.Element {
  const { formData } = useAppSelector(state => state.orderHistory)
  const { ranges, status, couponCode } = formData
  const [summaryFormData, setSummaryFormData] = useState<Record<string, any>>(
    {},
  )
  const ref = useRef<HTMLDivElement>(null)
  const { locationId } = useParams<TParamTypes>()

  useEffect(() => {
    if (open) {
      getSummary()
    }
  }, [open])

  const getSummary = async () => {
    try {
      const res = await getOrderSummaryApi(
        {
          coupon_code: couponCode,
          begin_time:
            moment(ranges[0].startDate).format('YYYY-MM-DD') + ' 00:00:00',
          end_time:
            moment(ranges[0].endDate).format('YYYY-MM-DD') + ' 23:59:59',
        },
        locationId,
      )
      setSummaryFormData(res)
    } catch (e: any) {
      const msg = e?.response?.data?.message
      toast.error(msg || 'request error')
    }
  }

  return (
    <>
      <Drawer open={open} toggle={toggle} position='right'>
        <>
          <div className='' ref={ref} id='detail'>
            <div className='px-8 pt-8'>
              <h2 className='text-base font-bold text-ink text-center pb-2'>
                Order Summary
              </h2>
            </div>
            <div className='pt-2'>
              <SummaryDetail
                formData={summaryFormData}
                status={status}
                ranges={ranges}
              />
            </div>
          </div>
          <div className='fixed  right-0 bottom-0 w-full   bg-white flex justify-end items-center px-8 py-4 border-t border-solid border-zinc'>
            <Button className='mr-4' color='primary' onClick={toggle}>
              CLOSE
            </Button>
          </div>
        </>
      </Drawer>
    </>
  )
}
