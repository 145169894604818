import React from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import Legal from './Legal'
import OperationInfo from './Operation'
import SetupEmail from './SetupEmail'
import SetupPhone from './SetupPhone'

export default function BusinessSetting(): JSX.Element {
  const history = useHistory()
  const { path, url } = useRouteMatch()

  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})
  return (
    <AdminPage>
      <Switch>
        <Route path={`${path}/operation/setup-email`}>
          <SetupEmail />
        </Route>
        <Route path={`${path}/operation/setup-phone`}>
          <SetupPhone />
        </Route>
        <Route path={path}>
          <>
            <AdminPageTabs>
              <AdminPageTab
                text='Operation Information'
                active={activePath === 'operation'}
                onClick={() => switchTab('operation')}
              />
              <AdminPageTab
                text='Legal Entity & Tax Documents'
                active={activePath === 'legal'}
                onClick={() => switchTab('legal')}
              />
            </AdminPageTabs>
            <Switch>
              <Route path={`${path}/operation`}>
                <OperationInfo />
              </Route>
              <Route path={`${path}/legal`}>
                <Legal />
              </Route>
              <Redirect from={`${path}`} to={`${path}/operation`} exact />
            </Switch>
          </>
        </Route>
      </Switch>
    </AdminPage>
  )
}
