import React, { useState, useEffect } from 'react'

import Switch from 'components/Switch'
import { PERMISSION } from 'data/permission'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { editNotificationProfileApi } from 'redux/user/actions'

import NotificationSettingSkeleton from './components/NotificationSettingSkeleton'

interface Iprops {
  data: any[]
  getProfile: () => void
}

const defaultData: any[] = []
const defaultLoading = { index: -1, loading: false, type: '' }

export default function NotificationSettingPage({
  data = defaultData,
  getProfile,
}: Iprops): JSX.Element {
  const auth = useAppSelector(state => state.auth.authProfile.data)

  const { brandId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(defaultLoading)
  const [state, setState] = useState<any[]>(defaultData)
  const isCanUpdateNotice =
    auth?.permissionMap?.[PERMISSION.UPDATEUSERNOTISETTING]?.allowed == 1

  useEffect(() => {
    initState(data)
  }, [data])

  const initState = (data: any[]) => {
    const result = data.map(item => {
      return {
        id: item.id,
        label: item?.notification_categories?.name,
        to_text: !!item.text,
        to_email: !!item.email,
      }
    })
    setState(result)
    setLoading({ ...defaultLoading })
  }

  const handleSubmit = async (
    type: string,
    value: boolean,
    item: any,
    index: number,
  ) => {
    const formData = new FormData()
    formData.append(type, value ? '1' : '0')
    setLoading({ index, loading: true, type })
    try {
      await editNotificationProfileApi(formData, item.id, brandId)
      getProfile()
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
      setLoading({ index, loading: false, type })
    }
  }

  return (
    <div className='w-[768px] m-auto'>
      <h2 className='text-xs font-bold text-silver mb-2'>Notification</h2>
      {!state.length ? (
        <NotificationSettingSkeleton />
      ) : (
        <div className='px-6 border border-solid border-zinc rounded-lg'>
          <div className='flex flex-col divide-y divide-zinc divide-dashed'>
            {state.map((noti, index) => (
              <div
                key={index}
                className='flex justify-between items-center py-4'
              >
                <div className='text-base text-ink'>{noti.label}</div>
                <div className='flex items-center'>
                  <Switch
                    className='!text-base !text-ink'
                    label='Text'
                    disabled={!isCanUpdateNotice}
                    loading={
                      index === loading.index &&
                      loading.type === 'text' &&
                      loading.loading
                    }
                    position='right'
                    checked={noti.to_text}
                    name='to_text'
                    onChange={checked => {
                      handleSubmit('text', checked, noti, index)
                    }}
                  />
                  <div className='w-0.5 bg-zinc mx-4'>&nbsp;</div>
                  <Switch
                    className='!text-base !text-ink'
                    label='Email'
                    disabled={!isCanUpdateNotice}
                    loading={
                      index === loading.index &&
                      loading.type === 'email' &&
                      loading.loading
                    }
                    position='right'
                    checked={noti.to_email}
                    name='to_email'
                    onChange={checked => {
                      handleSubmit('email', checked, noti, index)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
