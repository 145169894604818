import React from 'react'

import cn from 'classnames'
export type TLabelProp = {
  children?: string | React.ReactElement | React.ReactElement[]
  color?: 'silver' | 'red'
  htmlFor?: string
  className?: string
}

export default function Label({
  children,
  color = 'silver',
  htmlFor,
  className,
}: TLabelProp): JSX.Element {
  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        'flex text-xs font-bold',
        { 'text-silver': color === 'silver' },
        { 'text-red': color === 'red' },
        className,
      )}
    >
      {children}
    </label>
  )
}
