import React, { useEffect, useState } from 'react'

import Button from 'components/Button'
import CodeInput from 'components/CodeInput'
import ModalFull from 'components/Modal/ModalFull'
import useCountdown from 'hooks/useCountDown'
import { formatPhoneNumber } from 'utils/digit'

type TModalProps = {
  open: boolean
  confirmLoading: boolean
  sendLoading: boolean
  error: string
  phone?: string
  code: string
  toggle: () => void
  onChangeForm: (type: string, value: any) => void
  handleSendCode: () => void
  handleSubmit: () => void
}

const COUNTDOWN_SECONDS = 60000
export default function SecurityVerificationModal({
  open,
  confirmLoading,
  sendLoading,
  error,
  phone,
  code,
  toggle,
  onChangeForm,
  handleSendCode,
  handleSubmit,
}: TModalProps) {
  const [newTime, setNewTime] = useState(new Date().getTime())
  const [timeLeft, formattedRes] = useCountdown({
    targetDate: newTime + COUNTDOWN_SECONDS,
  })
  const { seconds } = formattedRes
  const formatPhone = phone ? formatPhoneNumber(phone) : ''

  return (
    <ModalFull
      open={open}
      title='Security Verification'
      loading={confirmLoading}
      error={error}
      toggle={toggle}
      okBtnDisabled={code.length !== 4}
      onOk={() => {
        handleSubmit()
      }}
    >
      <div>
        <div className='text-base text-dark-100 text-center'>
          For security purposes, please input the 4-digit security code sent to
          your associated cellphone at {formatPhone}
        </div>
        <div className='flex flex-col items-center'>
          <Button
            color='tertiary'
            className='w-fit my-6'
            disabled={seconds > 0 || sendLoading}
            onClick={() => {
              handleSendCode()
              setNewTime(new Date().getTime())
            }}
            loading={sendLoading}
          >
            {sendLoading
              ? 'SENDING'
              : `RESEND${seconds > 0 ? `(${seconds})` : ''}`}
          </Button>
          <CodeInput
            className='w-400px'
            length={4}
            fieldWidth={76}
            onChange={v => onChangeForm('verification_code', v)}
          />
        </div>
      </div>
    </ModalFull>
  )
}
