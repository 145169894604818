import React, { useState, useEffect } from 'react'

import { AngleLeft, AngleRight } from '@rushable/icons'

type IProps = {
  showTotal?: boolean | ((a: number, b: number, c: number) => void)
  total: number
  current: number
  pageSize: number
  onChange: any
  className?: string
}

type IState = {
  current: number
  pageSize: number
  groupCount: number
  total: number
  startPage: number
  totalPage: number
}

export default function Pagination({
  showTotal,
  total,
  current,
  pageSize,
  onChange,
  className,
}: IProps): JSX.Element {
  const [data, setData] = useState<IState>({
    current: 1, // 当前页码
    pageSize: 10, // 每页条数
    groupCount: 5, // 页码分组，显示7个页码，其余用省略号显示
    total: 0, // 总条数
    startPage: 1, // 分页起始位置
    totalPage: 1, // 总页码
  })
  useEffect(() => {
    if (
      showTotal &&
      typeof showTotal !== 'function' &&
      typeof showTotal !== 'boolean'
    ) {
      throw new Error('The showTotal type must be a function or boolean')
    }
    setData({
      ...data,
      total: total || 0,
      current: current || 1,
      pageSize: pageSize || 10,
      totalPage: Math.ceil(total / pageSize) || 1,
    })
  }, [showTotal, total, current, pageSize])
  const pageClick = (currentPage: number) => {
    const { groupCount } = data
    const getCurrentPage = onChange
    // 当 当前页码 大于 分组的页码 时，使 当前页 前面 显示 两个页码
    if (currentPage >= groupCount) {
      setData({
        ...data,
        startPage: currentPage - 4,
      })
    }
    if (currentPage < groupCount) {
      setData({
        ...data,
        startPage: 1,
      })
    }
    // 第一页时重新设置分组的起始页
    if (currentPage === 1) {
      setData({
        ...data,
        current: 1,
      })
    }
    setData({
      ...data,
      current: currentPage,
    })
    // 将当前页码返回父组件
    getCurrentPage && getCurrentPage(currentPage, data.pageSize)
  }
  const handlePrev = () => {
    let { current } = data
    if (current === 1) {
      return
    }
    setData({
      ...data,
      current: current > 1 ? --current : 1,
    })
    pageClick(current)
  }
  const handleNext = () => {
    const { totalPage } = data
    let { current } = data
    if (current >= totalPage) {
      return
    }
    setData({
      ...data,
      current: ++current,
    })
    pageClick(current)
  }
  const renderPage = (totalPage: number) => {
    const { current, groupCount, startPage } = data

    const paginationItemClass =
      'flex justify-center items-center cursor-pointer h-6 w-6 text-xs text-ink  rounded-full select-none'

    const paginationItemActionClass = 'text-white '
    const pages = []
    if (totalPage < 10) {
      // 总页数小于10，全部显示
      for (let i = 1; i <= totalPage; i++) {
        let classNames = `${paginationItemClass} bg-slate`
        if (current === i) {
          classNames = `${paginationItemClass} ${paginationItemActionClass} bg-blue`
        }
        pages.push(
          <li
            className={classNames}
            key={i}
            onClick={() => {
              pageClick(i)
            }}
          >
            {i}
          </li>,
        )
      }
    } else {
      // 否则显示部分
      pages.push(
        <li
          className={
            current === 1
              ? `${paginationItemClass} ${paginationItemActionClass} bg-blue`
              : `${paginationItemClass} bg-slate`
          }
          key={1}
          onClick={() => {
            pageClick(1)
          }}
        >
          1
        </li>,
      )

      // 前面省略号(当当前页码比分组的页码大时显示省略号)
      if (current >= groupCount) {
        pages.push(
          <li
            className={`${paginationItemClass} bg-slate`}
            onClick={() => {
              handlePrev()
            }}
            key={-1}
          >
            <AngleLeft size={12} />
          </li>,
        )
      }
      // 非第一页和最后一页显示
      for (
        let i = current < 4 ? startPage : current - 2;
        i <= (current < 4 ? startPage + 5 : current + 2);
        i++
      ) {
        if (i <= totalPage - 1 && i > 1) {
          pages.push(
            <li
              className={
                current === i
                  ? `${paginationItemClass} ${paginationItemActionClass} bg-blue`
                  : `${paginationItemClass} bg-slate`
              }
              key={i}
              onClick={() => pageClick(i)}
            >
              {i}
            </li>,
          )
        }
      }
      // 后面省略号
      if (totalPage - current > 3) {
        pages.push(
          <li
            className={`${paginationItemClass} bg-slate`}
            key={-2}
            onClick={() => {
              handleNext()
            }}
          >
            <AngleRight size={12} />
          </li>,
        )
      }
      pages.push(
        <li
          className={
            current === totalPage
              ? `${paginationItemClass} ${paginationItemActionClass} bg-blue`
              : `${paginationItemClass} bg-slate`
          }
          key={totalPage}
          onClick={() => {
            pageClick(totalPage)
          }}
        >
          {totalPage}
        </li>,
      )
    }
    return pages
  }
  return (
    <div className={className}>
      <ul className='flex justify-center p-0 space-x-1'>
        {showTotal
          ? typeof showTotal === 'function' &&
            showTotal(
              Math.ceil(total / pageSize),
              (current - 1) * pageSize + 1,
              current === data.totalPage ? total : current * pageSize,
            )
          : ''}
        {renderPage(data.totalPage)}
      </ul>
    </div>
  )
}
