import type { TCouponFreeItem } from 'types/coupon'
import { formatCurrency } from 'utils/digit'

export const toFloat = (input: any): number => {
  if (!input) {
    return 0
  }
  if (isNaN(input)) {
    return 0
  }
  return parseFloat(input)
}

export const menuItemBasePrice = (item: TCouponFreeItem): string => {
  if (item?.menu_modifiers?.length === 0) {
    return toFloat(item.unit_price) !== 0
      ? `${formatCurrency(item.unit_price)}`
      : ''
  }
  // let multi = false
  let basePrice = 0
  item?.menu_modifiers?.forEach(modifier => {
    const { qty_min, qty_free, menu_modifier_options } = modifier
    if (qty_min > 0 && qty_min > qty_free) {
      // qty_min > 0 && qty_min > qty_free 才需要最低价格
      if (menu_modifier_options.length > 1) {
        // 满足上面体检，并且 option length > 1, 返回的价格需要添加 “+”
        // multi = true
      }
      // 按 unit_price 从小到大排序 menu_modifier_options
      const sortModifierOptions = menu_modifier_options
        ?.slice()
        ?.sort((a, b) => toFloat(a.unit_price) - toFloat(b.unit_price))

      const needMin = qty_min - qty_free
      if (modifier?.allow_repeat) {
        basePrice += toFloat(sortModifierOptions[0].unit_price) * needMin
      } else {
        // 按照排序后的 sortModifierOptions 算出最终需要的最低价格
        sortModifierOptions.slice(0, needMin).forEach(op => {
          basePrice += toFloat(op.unit_price)
        })
      }
    }
  })

  return `${formatCurrency(basePrice + toFloat(item.unit_price))}`
}
