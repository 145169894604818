import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import type { TCustomerFormData } from 'types/customer'
import axios from 'utils/axiosApi'

export const getCustomersList = createAsyncThunk(
  'customer/getCustomersList',
  async ({
    locationId,
    page,
    per_page,
    keyword,
    last_order_date,
    last_order_operator,
    order_count_operator,
    order_count,
    spent_total,
    spent_total_operator,
  }: {
    locationId: string
    page: number
    per_page: number
    keyword?: string
    last_order_date?: string
    last_order_operator?: string
    order_count_operator?: string
    order_count?: string
    spent_total?: string
    spent_total_operator?: string
  }) => {
    const response = await axios.request({
      url: `locations/${locationId}/customers`,
      params: {
        // location_id: locationId,
        page,
        per_page,
        keyword,
        last_order_date,
        last_order_operator,
        order_count_operator,
        order_count,
        spent_total,
        spent_total_operator,
      },
      method: 'get',
      cache: {
        ignoreCache: false,
      },
    })
    return response.data
  },
)

export const getCustomerDetail = ({
  orderId,
  location_id,
  per_page,
  page,
}: {
  orderId: string
  location_id: string
  per_page: number
  page: number
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${location_id}/customers/${orderId}`,
      method: 'get',
      params: {
        // location_id,
        per_page,
        page,
      },
      cache: {
        ignoreCache: false,
      },
    })
    return response.data
  }
}

export const getCustomerData = ({
  location_id,
  per_page,
}: {
  location_id: string
  per_page?: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${location_id}/customers/data/export`,
      method: 'get',
      params: {
        per_page,
      },
      cache: {
        ignoreCache: true,
      },
      responseType: 'arraybuffer',
    })
    return response.data
  }
}

export const importCustomerData = (file: Blob, locationId: string) => {
  return async () => {
    const formData = new FormData()
    formData.append('csv', file)
    const response = await axios.request({
      url: `locations/${locationId}/customers/data/import`,
      data: formData,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
}

export const setFilterList = createAction<string[]>('customer/set-filterList')
export const setFilterKeywordList = createAction<string[]>(
  'customer/set-filterKeywordList',
)
export const setPageSize = createAction<number>('customer/set-page-size')
export const setPage = createAction<number>('customer/set-page')
export const setFormData = createAction<TCustomerFormData>(
  'customer/set-form-data',
)
export const setShowFilter = createAction<boolean>('customer/set-show-filter')
