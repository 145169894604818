import { useState, useEffect } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import { useParams } from 'react-router'
import {
  getCouponList,
  setCurrentLocationId,
  setKeyword,
  setPage,
} from 'redux/coupon'
import { useAppSelector, useAppDispatch } from 'redux/hooks'

import CouponList from './components/CouponList'
import CouponNone from './components/CouponNone'

export default function CouponCode() {
  const dispatch = useAppDispatch()
  const { coupons, keyword, page, currentLocationId } = useAppSelector(
    state => state.coupon,
  )
  const { locationId } = useParams<{ locationId: string }>()
  const [showNone, setShowNone] = useState(false)
  const getCoupons = async (page = 1, search = '') => {
    dispatch(setPage(page))
    const res = await dispatch(
      getCouponList({
        locationId,
        keyword: search,
        page,
      }),
    )
    if (res.type === 'coupon/getCouponList/fulfilled') {
      page === 1 && search.length === 0 && res.payload?.data?.length === 0
        ? setShowNone(true)
        : setShowNone(false)
    }
  }

  useEffect(() => {
    if (currentLocationId !== locationId) {
      // locationId 切换时，重置 keyword、page 并 Get Coupons
      dispatch(setKeyword(''))
      dispatch(setCurrentLocationId(locationId))
      getCoupons(1)
    } else {
      getCoupons(page, keyword)
    }
  }, [locationId])
  return (
    <AdminPage>
      <AdminPageTitle title='Coupon Code' />
      {showNone ? (
        <CouponNone />
      ) : (
        <CouponList coupons={coupons} getCoupons={getCoupons} />
      )}
    </AdminPage>
  )
}
