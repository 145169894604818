import { useRef } from 'react'

import deepEqual from 'deep-equal'

/**
 * how to use
 * 
  import useDebounce, { compare } from 'hooks/useDebounce'
  
  const compareFn = useDebounce(compare)
  useEffect(() => {
    compareFn(formData, formDataInit, (flag: boolean) => setFormIsDirty(flag))
  }, [formData, formDataInit])
 */

// useDebounce Hook
type IdebounceFnType = (...args: any[]) => void
export default function useDebounce(fn: IdebounceFnType, wait = 500) {
  const debounceFnRef = useRef(debounce(fn, wait))
  return debounceFnRef.current
}

type ICallback = (args: boolean) => void
// 对比函数
export function compare(a: any, b: any, fn: ICallback) {
  fn(!deepEqual(a, b))
}

// debounce 原始函数
function debounce(this: any, fn: IdebounceFnType, wait = 1000) {
  let timer: NodeJS.Timeout | null = null
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const ctx = this
  return function (...args: any[]) {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(ctx, args)
    }, wait)
  }
}
