import React from 'react'

import { ThumbDown, ThumbUp, SolidMark } from '@rushable/icons'
import cn from 'classnames'

type TImpressionProps = {
  className?: string
  impression?: 'bad' | 'good'
  renderText?: string | React.ReactNode
}

export default function Impression({
  className,
  impression,
  renderText,
}: TImpressionProps) {
  const text = impression ? (impression === 'bad' ? 'Bad' : 'Good') : 'Pending'
  return (
    <div className={cn('text-dark-64 flex items-center', className)}>
      {impression ? (
        impression === 'bad' ? (
          <ThumbDown size={16} className='mr-1' />
        ) : (
          <ThumbUp size={16} className='mr-1' />
        )
      ) : (
        <SolidMark size={16} className='text-dark-16 mr-1' />
      )}
      <span
        className={cn(
          'text-sm font-medium',
          impression ? 'text-dark-100' : 'text-dark-32',
        )}
      >
        {renderText || text}
      </span>
    </div>
  )
}
