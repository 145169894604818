import React, { useState, useEffect } from 'react'

import Field from 'components/Field'
import { Spin } from 'components/Loading'
import SaveChangeBar from 'components/SaveChangeBar'
import { Select } from 'components/Select'
import TabChangeBar from 'components/TabChangeBar'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import {
  getUserDetailOrInvitationAPI,
  getPermissionListAPI,
  updatePermissionListAPI,
  editUserRoleAPI,
} from 'redux/user'

import AccessSettingForm from './AccessSettingForm'
import LocationsForm from './LocationsForm'
import { accountTypeOptions } from '../helpers/data'

export type Ttitle = {
  first_name: string
  last_name: string
  status: string
}
type TProp = {
  onChange: (res: Ttitle) => void
}

export default function UserManagementDetail({ onChange }: TProp): JSX.Element {
  const { selectedBrand } = useAppSelector(state => state.brand)
  const locations = selectedBrand?.locations || []
  const tabItems = ['Contact Information', 'Access Setting']
  const { userId, brandId, locationId } = useParams<TParamTypes>()
  const [tabName, setTabName] = useState('Contact Information')
  const [formData, setFormData] = useState<Record<string, any>>({})
  const [formDataInit, setFormDataInit] = useState<Record<string, any>>({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const onFormChange = (name: string, value: any) => {
    if (name === 'locations') {
      value = value.sort()
    }
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  useEffect(() => {
    getUserDetailOrInvitation()
  }, [])

  const compareFn = useDebounce(compare)

  useEffect(() => {
    setError('')
    compareFn(formData, formDataInit, (flag: boolean) => setIsDirty(flag))
  }, [formData, formDataInit])

  const getUserDetailOrInvitation = async () => {
    setLoading(true)
    try {
      const res = await getUserDetailOrInvitationAPI({
        type: 'user',
        user_id: userId,
        brand_id: brandId,
        location_id: locationId,
      })
      getPermissionList(res)
      // 将状态传给上层组件
      onChange({
        first_name: res.first_name,
        last_name: res.last_name,
        status: res.status,
      })
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
      setLoading(false)
    }
  }

  const getPermissionList = async (userDetail: any) => {
    try {
      const res = await getPermissionListAPI()
      const userPermissions = [...res]
      userPermissions.forEach((childItem: any) => {
        childItem.permissions.forEach((v: any) => {
          const index = userDetail.userPermissions.findIndex(
            (m: any) => m.permission.name === v.name,
          )
          v.allowed = userDetail.userPermissions[index]?.allowed || false
        })
      })
      const assigned_locations: number[] = []
      if (userDetail.role !== 'brand_admin') {
        userDetail.locations.map((location: { id: number }) => {
          assigned_locations.push(location.id)
        })
      }

      const data = {
        ...userDetail,
        userPermissions,
        locations: assigned_locations.sort(),
      }
      setFormData({ ...data })
      setFormDataInit({ ...data })
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const handleSubmit = () => {
    if (tabName === 'Contact Information') {
      editUserRole()
    } else {
      submitPermission()
    }
  }

  const editUserRole = async () => {
    setSubmitLoading(true)
    try {
      const permissionsList: any = []
      formData.userPermissions.forEach((childItem: any) => {
        childItem.permissions.forEach((permissionItem: any) => {
          permissionsList.push({
            permission_id: permissionItem.id,
            allowed: !!permissionItem.allowed,
          })
        })
      })
      const params = {
        user_id: userId,
        brand_id: brandId,
        role: formData.role,
        location_ids: formData.locations,
      }

      const res = await editUserRoleAPI(params)
      toast.success(res.message)
      setFormDataInit({ ...formData })
    } catch (e: any) {
      const msg = e.message || 'request error'
      setError(msg)
    }
    setSubmitLoading(false)
  }

  const submitPermission = async () => {
    setSubmitLoading(true)
    try {
      const permissionsList: any = []
      formData.userPermissions.forEach((childItem: any) => {
        childItem.permissions.forEach((permissionItem: any) => {
          permissionsList.push({
            permission_id: permissionItem.id,
            allowed: !!permissionItem.allowed,
          })
        })
      })
      const params = {
        user_id: userId,
        brand_id: brandId,
        user_permissions: permissionsList,
      }

      const res = await updatePermissionListAPI(params)
      toast.success(res.message)
      setFormDataInit({ ...formData })
    } catch (e: any) {
      const msg = e.message || 'request error'
      setError(msg)
    }
    setSubmitLoading(false)
  }

  return (
    <Spin
      spining={loading}
      className='rounded-b-lg overflow-hidden'
      styles={{
        minHeight: 'calc(100vh - 144px)',
      }}
    >
      <div className='pt-8 px-8'>
        <UnsavedPrompt when={isDirty} />
        {isDirty && (
          <SaveChangeBar
            error={error}
            onConfirm={() => handleSubmit()}
            onCancel={() => {
              setFormData({ ...formDataInit })
            }}
            confirmRequest={submitLoading}
          />
        )}
        <div className='flex flex-col mx-auto w-[496px] pb-8'>
          <TabChangeBar
            items={tabItems}
            activeItem={tabName}
            className='w-[496px] mb-8'
            containerClassName='w-[244px] text-xs font-bold'
            onClick={value => setTabName(value)}
          />
          {tabName === 'Contact Information' && (
            <>
              <div className='grid grid-cols-2 gap-6'>
                <Field
                  label='First name'
                  disabled={true}
                  name='first_name'
                  value={formData.first_name || ''}
                  containerClassName='w-full mb-6'
                  onChange={e => onFormChange('first_name', e.target.value)}
                />
                <Field
                  disabled={true}
                  label='Last name'
                  name='last_name'
                  value={formData.last_name || ''}
                  containerClassName='w-full mb-6'
                  onChange={e => onFormChange('last_name', e.target.value)}
                />
              </div>
              <div className='grid grid-cols-2 gap-6'>
                <Field
                  type='tel'
                  disabled={true}
                  label='Cellphone'
                  inputMode='tel'
                  name='phone'
                  value={formData.phone || ''}
                  containerClassName='w-full mb-6'
                  onChangePhone={value => {
                    onFormChange('phone', value)
                  }}
                />
                <Field
                  disabled={true}
                  label='Account email'
                  name='Account_email'
                  value={formData.email || ''}
                  containerClassName='w-full mb-6'
                  onChange={e => onFormChange('email', e.target.value)}
                />
              </div>
              {formData.role && (
                <Select
                  label='Account type'
                  className='mb-4'
                  options={accountTypeOptions}
                  value={formData.role || ''}
                  placeholder='Select...'
                  onChange={value => onFormChange('role', value)}
                />
              )}

              {['location_owner', 'staff'].includes(formData.role) && (
                <LocationsForm
                  showTitle={false}
                  locations={locations}
                  formData={formData}
                  onFormChange={onFormChange}
                />
              )}
            </>
          )}

          {tabName === 'Access Setting' && (
            <div className='pb-8'>
              <AccessSettingForm
                formData={formData}
                onFormChange={onFormChange}
              />
            </div>
          )}
        </div>
      </div>
    </Spin>
  )
}
