import axios from 'utils/axiosApi'

export const getPayoutList = ({
  params,
  locationId,
}: {
  params: any
  locationId?: string
}) => {
  return axios.request({
    url: `locations/${locationId}/payouts`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params,
  })
}

export const getPayoutDetailAPI = (
  id: string | number | undefined,
  locationId?: string,
  params?: any,
) => {
  return axios.request({
    url: `locations/${locationId}/payouts/${id}`,
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
}

export const getPayoutTransactionsAPI = (
  id: string | number | undefined,
  locationId?: string,
  params?: any,
) => {
  return axios.request({
    url: `locations/${locationId}/payouts/${id}/transactions`,
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
}

export const getAccountingReportListAPI = ({
  params,
  locationId,
}: {
  params: any
  locationId?: string
}) => {
  return axios.request({
    url: `locations/${locationId}/transaction_reports`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params,
  })
}

export const getReportDetailAPI = (locationId?: string, params?: any) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/transaction_reports/detail`,
      method: 'get',
      params,
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

export async function downloadReportAPI(locationId?: string, params?: any) {
  const response = await axios.request({
    url: `locations/${locationId}/transaction/download`,
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
    responseType: 'arraybuffer',
  })
  return response.data
}
