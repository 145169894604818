export const FILTER_MORE_OR_LESS = [
  { value: '>', label: 'more' },
  { value: '<', label: 'less' },
]

export const FILTER_MORE_OR_FEWER = [
  { value: '>', label: 'more' },
  { value: '<', label: 'fewer' },
]

export const FILTER_BEFORE_OR_AFTER = [
  { value: '<', label: 'before' },
  { value: '>', label: 'after' },
]

export const SELECT_CUSTOMER = [
  { value: '', label: 'Show all my customers' },
  { value: true, label: 'Search customer with condition(s)' },
]
