import { useState } from 'react'

import { AngleLeft } from '@rushable/icons'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import Tips from 'components/Tips'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { searchPhoneNumberApi, updatePrefixApi } from 'redux/business'
import { useAppSelector } from 'redux/hooks'

import GetHelp from './components/GetHelp'
import { PhoneSkeletonItem } from './components/SkeletonItem'
import { getRoutePath } from './helps'

const SetupPhone = (): JSX.Element => {
  const history = useHistory()
  const { locationId = '' } = useParams<TParamTypes>()
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [isRequested, setIsRequested] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [phoneArrs, setPhoneArrs] = useState<any[]>([])
  const [curPhone, setCurPhone] = useState<any>({})
  const { selectedLocation } = useAppSelector(state => state.location)

  const id = selectedLocation?.location_business_setting?.id || ''

  const getPhoneArrs = async () => {
    setLoading(true)
    setPhoneArrs([])
    try {
      const res = await searchPhoneNumberApi(phone)
      setPhoneArrs(res)
    } catch (e: any) {
      setPhoneArrs([])
    }
    setIsRequested(true)
    setLoading(false)
  }

  const handleClickPhone = (item: any) => {
    setCurPhone(item)
    setIsShowModal(true)
  }

  const updatePhone = async () => {
    setModalLoading(true)
    try {
      const phone = curPhone.phonenumber?.slice(-10)
      const res = await updatePrefixApi({ phone }, id, locationId)
      toast.success(res.message)
      setIsShowModal(false)
      setTimeout(() => goBackPage(), 500)
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setModalLoading(false)
  }

  const goBackPage = () => {
    const path = getRoutePath()
    history.replace(path, { refresh: true })
  }

  return (
    <div>
      <AdminPageTitle
        title='Phone number for marketing'
        left={
          <Button color='secondary-link' onClick={goBackPage}>
            <AngleLeft size={16} />
            <span className='ml-1'>GO BACK</span>
          </Button>
        }
      />
      <div className='py-8'>
        <div className='mx-[200px]'>
          <GetHelp type='phone' />
          <div className='flex justify-between items-center w-full h-[72px] mt-6 px-6 border border-zinc rounded-lg'>
            <div className='flex-1'>
              <span className='mr-4'>Input your area code to search:</span>
            </div>
            <div className='flex items-center mr-6'>
              <Field
                name='setup-phone'
                className='text-right'
                type='text'
                value={phone}
                placeholder='XXX'
                maxLength={3}
                onChange={e => setPhone(e.target.value)}
                containerClassName='mt-0 w-[64px]'
              />
              <span className='ml-2'>XXX-XXXX</span>
            </div>
            <Button color='primary' loading={loading} onClick={getPhoneArrs}>
              SEARCH
            </Button>
          </div>
          {!!phoneArrs.length && (
            <>
              <ul className='grid grid-cols-2 gap-4 mb-4 mt-6'>
                {phoneArrs.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleClickPhone(item)}
                      className='p-4 h-[68px] border-2 border-zinc rounded-lg cursor-pointer hover:border-blue'
                    >
                      <div className='text-ink mb-2 text-base'>
                        {item.friendlyName}
                      </div>
                      <div className='text-silver text-xs'>{item.locality}</div>
                    </li>
                  )
                })}
              </ul>
              <Button
                color='tertiary'
                className='w-full'
                loading={loading}
                onClick={getPhoneArrs}
              >
                SHOW ME ANOTHER BATCH
              </Button>
            </>
          )}
          {loading && <PhoneSkeletonItem />}
          {!phoneArrs.length && isRequested && !loading && (
            <div className='flex justify-center mt-16'>
              <Tips className='!inline-flex'>
                No number is available, please try with another area code...
              </Tips>
            </div>
          )}
        </div>
      </div>
      <ModalFull
        open={isShowModal}
        loading={modalLoading}
        toggle={() => setIsShowModal(false)}
        onCancel={() => setIsShowModal(false)}
        onOk={() => updatePhone()}
        title='Phone number for marketing'
      >
        <div className='text-center w-full h-[112px] border border-zinc rounded-lg text-ink'>
          <div className='mt-6 mb-4 text-[32px] leading-none font-bold'>
            {curPhone.friendlyName}
          </div>
          <div className='text-base'>
            $5.00/month will be added to your account billing
          </div>
        </div>
      </ModalFull>
    </div>
  )
}

export default SetupPhone
