/**
 * 字符串首字母变为大写
 * @param str
 * @returns string
 */
export function titleCase(str: string): string {
  if (!str?.length || typeof str !== 'string') {
    return ''
  }
  return str.trim().toLowerCase().replace(str[0], str[0].toUpperCase())
}
