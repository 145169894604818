import { Route, Switch, useRouteMatch } from 'react-router-dom'

import CouponCodeDetail from './CouponCodeDetail'
import CouponCodeList from './CouponCodeList'

export default function CouponCodePage(): JSX.Element {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <CouponCodeList />
        </Route>
        <Route exact path={`${path}/detail/:couponId`}>
          <CouponCodeDetail />
        </Route>
      </Switch>
    </>
  )
}
