import React from 'react'

import { Logo, SolidMapPin, AngleDown } from '@rushable/icons'
import cn from 'classnames'
import ModeSwitcher from 'components/ModeSwitcher'
import { TModeSwitcherOption } from 'components/ModeSwitcher/ModeSwitcher'
import { SELECTRESTAURANT } from 'data/routes'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

const MODES: TModeSwitcherOption[] = [
  {
    label: 'Location',
    value: 'location',
    activeColor: 'bg-blue',
  },
  {
    label: 'Brand',
    value: 'brand',
    activeColor: 'bg-green',
  },
]

export type TLeftNavTopProp = {
  mode: string
  isLoading: boolean
  onModeChange: (mode: string) => void
  toggleLocationDrawer?: () => void
}

export default function LeftNavTop({
  mode,
  isLoading,
  onModeChange,
  toggleLocationDrawer,
}: TLeftNavTopProp): JSX.Element {
  const history = useHistory()
  const { selectedLocation } = useAppSelector(state => state.location)
  return (
    <div className={cn('absolute w-full top-0 left-0 px-6 py-4 bg-darkblue')}>
      <div
        className='flex justify-center mb-4 cursor-pointer'
        onClick={() => history.push(SELECTRESTAURANT)}
      >
        <Logo />
      </div>
      <ModeSwitcher value={mode} options={MODES} onClick={onModeChange} />
      {mode === 'location' && !isLoading && selectedLocation && (
        <div
          className='flex items-center mt-2 px-2 py-3 rounded-lg bg-light-8 cursor-pointer'
          role='button'
          onClick={() => toggleLocationDrawer && toggleLocationDrawer()}
        >
          <SolidMapPin size={12} className='text-white' />
          <span className='flex-1 ml-1 text-white text-xs'>
            {selectedLocation.name}
          </span>
          <AngleDown size={12} className='text-white' />
        </div>
      )}
    </div>
  )
}
