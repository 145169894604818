import ColorThief from 'colorthief'

/**
 * 获取图片颜色
 * @param dom 已插入html的img标签的选择器
 * @returns
 */
export default function getThiefColor(
  dom = '#thief-color',
  url?: string,
): Promise<string[]> {
  const img: any = document.querySelector(`${dom}`)
  img.setAttribute('src', `${url}?t=${Date.now()}`)
  img.setAttribute('crossOrigin', '')

  return new Promise(resolve => {
    if (img.complete) {
      const result = initThiefColors(img) || []
      resolve(result)
    } else {
      img.addEventListener('load', function () {
        const result = initThiefColors(img) || []
        resolve(result)
      })
    }
  })
}

function initThiefColors(img: HTMLImageElement) {
  try {
    const colorThief = new ColorThief()
    const colorArrs = colorThief.getPalette(img, 8) || []
    return colorArrs.map((color: number[]) => rgbToHex(color))
  } catch (e) {
    console.log(e)
  }
}

/**
 * rgb颜色转16进制
 * @param r
 * @param g
 * @param b
 * @returns
 */
export function rgbToHex(rgb: number[]): string {
  const str = rgb
    .map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    })
    .join('')
  return `#${str}`
}

interface IhexType {
  r: string | number
  g: string | number
  b: string | number
}
export function hexToRgb(hex: string): IhexType {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  let obj: IhexType = { r: '', g: '', b: '' }
  if (result) {
    obj = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
  }
  return obj
}
