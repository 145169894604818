import React, { useState } from 'react'

import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'

import { getModalConstant } from '../helpers/index'

interface IModalTypes {
  type: 'services' | 'token'
}
export default function ModalTipsButton({ type }: IModalTypes) {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <Button color='secondary' size='sm' onClick={() => setIsVisible(true)}>
        QUESTION?
      </Button>
      <ModalFull
        open={isVisible}
        toggle={() => setIsVisible(false)}
        title={getModalConstant[type].modalTitle}
      >
        <div className='text-center'>{getModalConstant[type].modalContent}</div>
      </ModalFull>
    </>
  )
}
