import { useState, useEffect } from 'react'

import { Dollar } from '@rushable/icons'
import Field from 'components/Field'
import { Select } from 'components/Select'
import { IOptions } from 'components/Select/help'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { getCouponMenuItems } from 'redux/coupon'
import { useAppDispatch } from 'redux/hooks'
import type { TCouponForm } from 'types/coupon'

import { DISCOUNT_COVERAGE_OPTIONS } from '../helpers/constant'
import { menuItemBasePrice } from '../helpers/utils'

type TFreeItemFieldsProps = {
  disabled: boolean
  formData: TCouponForm
  cancelledItemName: string
  onFormDataChange: (type: string, value: any) => void
}

export default function FreeItemFields({
  disabled,
  formData,
  cancelledItemName,
  onFormDataChange,
}: TFreeItemFieldsProps) {
  const { locationId } = useParams<TParamTypes>()
  const dispatch = useAppDispatch()
  const [options, setOptions] = useState<IOptions[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (cancelledItemName) {
      setOptions([
        {
          value: formData.menu_item_id!,
          label: cancelledItemName,
        },
      ])
    } else {
      getMenuItems()
    }
  }, [])

  const getMenuItems = async () => {
    try {
      setLoading(true)
      const res = await dispatch(
        getCouponMenuItems({
          locationId: locationId!,
        }),
      )
      if (res?.length > 0) {
        setOptions(
          res.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              item,
            }
          }),
        )
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (formData.menu_item && formData.menu_item?.menu_modifiers) {
      const amount = menuItemBasePrice(formData.menu_item)
      onFormDataChange('cheapest_price', amount)
    } else if (!formData.menu_item) {
      onFormDataChange('cheapest_price', '')
    }
  }, [formData.menu_item])

  return (
    <>
      <Select
        label='Menu item'
        options={options}
        isLoading={loading}
        placeholder='Select'
        value={formData.menu_item_id}
        onChange={(value, item) => onFormDataChange('menu_item_id', item)}
        disabled={disabled}
      />
      {formData.menu_item_id && (
        <>
          <div className='flex space-x-4'>
            <Field
              containerClassName='flex-1'
              label='Minimum price'
              name='minimum price'
              align='left'
              type='text'
              inputMode='money'
              suffix={<Dollar className='text-silver' />}
              placeholder='5.00'
              value={formData.cheapest_price || ''}
              onChangeMoney={e => onFormDataChange('cheapest_price', e)}
              disabled
            />
            <Field
              containerClassName='flex-1'
              label='Order minimum'
              name='Order minimum'
              align='left'
              type='text'
              inputMode='money'
              suffix={<Dollar className='text-silver' />}
              placeholder='25.00'
              value={formData.threshold}
              onChangeMoney={e => onFormDataChange('threshold', e)}
              disabled={disabled}
            />
          </div>
          <div>
            <Select
              label='Discount coverage'
              options={DISCOUNT_COVERAGE_OPTIONS}
              placeholder='Select'
              value={formData.discount_coverage_type}
              onChange={value =>
                onFormDataChange('discount_coverage_type', value)
              }
              disabled={disabled}
            />
            {formData.discount_coverage_type === 'min_price_amount' && (
              <div className='mt-4 text-sm text-dark-64'>
                If an item's price exceeds its minimum due to modifiers, the
                free item coupon discounts the base price, leaving the customer
                to pay the extra amount.
              </div>
            )}
            {formData.discount_coverage_type === 'full_amount' && (
              <div className='mt-4 text-sm text-dark-64'>
                If an item's price exceeds its minimum due to modifiers, the
                free item coupon discounts the full amount regardless of what
                the customer chooses.
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
