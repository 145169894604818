import React from 'react'

import cn from 'classnames'
import NavItem from 'components/NavItem'
import { brandRoutes } from 'routes/brandRoutes'

export type TBrandNavItemsProp = {
  routeToPath: (v: string) => void
  activePath?: string
  permission: Record<string, any>
}
export default function BrandNavItems({
  routeToPath,
  activePath = 'nullablepath',
  permission,
}: TBrandNavItemsProp): JSX.Element {
  return (
    <div className={cn('pt-28 pb-28 h-full overflow-y-auto overscroll-none')}>
      {brandRoutes.map((v, i) => (
        <NavItem
          key={i}
          icon={v.icon}
          label={v.label}
          value={v.path}
          className='font-bold !text-base'
          onClick={() => routeToPath(v.path)}
          disabled={permission?.[v?.permissionId || '']?.allowed === 0}
          active={activePath.includes(v.path)}
        />
      ))}
    </div>
  )
}
