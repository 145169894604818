import React, { useState, useEffect } from 'react'

import { SolidCalendar } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getCoupons, editMarketingFlyer } from 'redux/marketingFlyer'
import { getCouponTitle } from 'utils/coupon'

import SkeletonCoupon from './SkeletonCoupon'

type TCouponSelectDrawer = {
  open: boolean
  close: () => void
  couponId: number
  flyerId: string
  getData: () => void
}

export default function CouponSelectDrawer({
  open,
  close,
  couponId,
  flyerId,
  getData,
}: TCouponSelectDrawer): JSX.Element {
  const { brandId, locationId } = useParams<TParamTypes>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { coupons, getCouponsRequest } = useAppSelector(
    state => state.marketingFlyer,
  )
  const [selectCouponId, setSelectCouponId] = useState<number | null>(null)
  const [selectTitle, setSelectTitle] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleSave = async () => {
    setLoading(true)
    const data = await dispatch(
      editMarketingFlyer({
        locationId: locationId as string,
        id: flyerId,
        params: {
          promo_offer: 1,
          coupon_id: selectCouponId,
          coupon_description: selectTitle,
        },
      }),
    )
    if (data.type === 'flyer/editMarketingFlyer/fulfilled') {
      await getData()
      close()
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const routeToPath = (toPath: string) => {
    if (toPath.includes(':brandId') && brandId) {
      toPath = toPath.replace(':brandId', brandId)
    }
    if (toPath.includes(':locationId') && locationId) {
      toPath = toPath.replace(':locationId', locationId)
    }
    history.push(toPath)
  }

  useEffect(() => {
    if (open) {
      dispatch(
        getCoupons({
          id: locationId as string,
          marketing_type: 'marketing_flyer',
        }),
      )
    }
  }, [open, couponId])

  useEffect(() => {
    if (coupons?.length > 0 && couponId) {
      coupons.forEach(c => {
        if (c.id === couponId) {
          setSelectCouponId(couponId)
        }
      })
    }
  }, [coupons, couponId])

  return (
    <Drawer
      open={open}
      toggle={close}
      rightFooter={
        <>
          <Button color='secondary' onClick={close}>
            CANCEL
          </Button>
          {selectCouponId ? (
            <Button color='primary' loading={loading} onClick={handleSave}>
              CONFIRM
            </Button>
          ) : (
            <Button color='primary' onClick={() => routeToPath('coupon-code')}>
              CREATE COUPON
            </Button>
          )}
        </>
      }
    >
      <div>
        <div className='text-base font-bold text-center pt-6 pb-2'>
          Select Coupon Code
        </div>
        <div>
          {getCouponsRequest ? (
            <>
              <SkeletonCoupon />
              <SkeletonCoupon />
              <SkeletonCoupon />
            </>
          ) : (
            <>
              {coupons.length === 0 && (
                <p className='text-red text-center text-base mt-6 bg-red-opacity-4 py-4 px-4 rounded-xl'>
                  No available Coupons found
                </p>
              )}
              {coupons.map(item => {
                return (
                  <div
                    key={item.id}
                    className={cn(
                      'mt-4 option-box-class',
                      selectCouponId === item.id ? 'selected' : '',
                    )}
                    onClick={() => {
                      setSelectCouponId(item.id)
                      setSelectTitle(getCouponTitle(item).title)
                    }}
                  >
                    <div>
                      <div className='flex items-center justify-between'>
                        <div className='text-base text-ink font-bold pr-2'>
                          {item.description}
                        </div>
                        <div className='text-xs text-lead px-2 py-0.5 bg-field rounded'>
                          {item.code}
                        </div>
                      </div>
                      <div className='text-xs py-2 text-lead'>
                        {getCouponTitle(item).showTitle}
                      </div>
                      <div className='flex text-xs text-lead bg-field py-2 px-3 rounded-lg'>
                        <SolidCalendar size={14} className='text-silver mr-2' />
                        <span>
                          {moment(item.start_date).format('MMM DD, YYYY')} to{' '}
                          {item.end_date
                            ? `${moment(item.end_date).format('MMM DD, YYYY')}`
                            : 'Forever'}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </Drawer>
  )
}
