import React, { useEffect, useState } from 'react'

import { AngleDown } from '@rushable/icons'
import cn from 'classnames'

import NavItem from '../NavItem'

export type TNavDropdownProp = {
  children?: React.ReactNode
  items?: Array<{
    label: string
    value: string
    display?: boolean
    active?: boolean
  }>
  text: string
  open?: boolean
  suffix: React.ReactNode
  onToggle?: (open: boolean, text: string) => void
}

export default function NavDropdown({
  text,
  children,
  items = [],
  open = false,
  suffix,
  onToggle,
}: TNavDropdownProp): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const toggle = () => {
    setIsOpen(!isOpen)
    onToggle && onToggle(!isOpen, text)
  }
  useEffect(() => {
    setIsOpen(open)
  }, [open])
  return (
    <div className='block relative cursor-pointer'>
      <div
        className='flex items-center py-3 px-2'
        role='button'
        onClick={toggle}
      >
        {suffix}
        <span className={cn('flex-1 pl-2 text-white text-base font-bold')}>
          {text}
        </span>
        <AngleDown
          size={16}
          className={cn(
            'text-white transition-all duration-200	',
            isOpen ? '-scale-y-100' : '',
          )}
        />
      </div>
      <div className={cn(isOpen ? 'display' : 'hidden')}>
        {children}
        {items.map(
          (item, index) =>
            item.display && (
              <NavItem
                key={index}
                label={item.label}
                value={item.value}
                lv={2}
                active={item.active}
              />
            ),
        )}
      </div>
    </div>
  )
}
