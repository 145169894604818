import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { TLoginProp, TPasswordResetVerifyProp } from 'types/user'
import { removeAccessToken } from 'utils/auth'
import axios from 'utils/axiosApi'
/**
 * Call API to sign in user
 */
export const login = createAsyncThunk(
  'auth/login',
  async (
    { email, password, phone, verification_code }: TLoginProp,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.request({
        url: 'auth/login',
        data: email
          ? { email, password, role_type: 'brand' }
          : { phone, verification_code, role_type: 'brand' },
        method: 'post',
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Call API to get profile of the logged user
 */
export const getProfile = createAsyncThunk('auth/getProfile', async () => {
  const response = await axios.request({
    url: 'profile',
    method: 'get',
  })
  return response.data
})

/**
 * 获取权限列表和个人信息，brand 相关
 */
export const getPermissionsAndProfile = createAsyncThunk(
  'auth/permissionAndProfile',
  async ({ brand_id, refresh }: { brand_id: number; refresh?: boolean }) => {
    const response = await axios.request({
      url: `brands/${brand_id}/profile`,
      method: 'get',
      cache: { ignoreCache: !!refresh },
    })
    return response.data
  },
)

/**
 * Call API to log user out
 */
export const logout = createAsyncThunk('auth/logout', async () => {
  removeAccessToken()
  window.location.href = '/login'
  const response = await axios.request({
    url: 'auth/logout',
    method: 'post',
  })
  return response.data
})

/**
 * Call API to send code
 */
export const sendCode = createAsyncThunk(
  'auth/send-code',
  async ({ phone, type }: { phone: string; type: string }) => {
    const response = await axios.request({
      url: 'auth/send_code',
      data: {
        phone,
        type,
      },
      method: 'post',
    })
    return response.data
  },
)

/**
 * Call API to send code of password reset
 */
export const passwordResetSend = createAsyncThunk(
  'auth/password-reset-send',
  async (email: string) => {
    const response = await axios.request({
      url: 'account/password_reset_send',
      data: {
        email,
      },
      method: 'post',
    })
    return response.data
  },
)

/**
 * Call API to send code of password reset verify
 */
export const passwordResetVerify = createAsyncThunk(
  'auth/password-reset-verify',
  async ({
    email,
    verification_code,
    new_password,
    new_password_confirmation,
  }: TPasswordResetVerifyProp) => {
    const response = await axios.request({
      url: 'account/password_reset_verify',
      data: {
        email,
        verification_code,
        new_password,
        new_password_confirmation,
      },
      method: 'post',
    })
    return response.data
  },
)

export const clearError = createAction('auth/clear-error')

export async function getCloverAPI(params: {
  code?: string
  location_id?: string
  merchant_id?: string
  employee_id?: string
}) {
  const response = await axios.request({
    url: 'oauth/clover',
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
