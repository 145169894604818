import React from 'react'

export type TFraudSettingItemProp = {
  label: string
  description: string
  children: React.ReactElement
  className: string
  containerClassName: string
}

export default function FraudSettingItem({
  label,
  description,
  children,
  className,
  containerClassName,
}: TFraudSettingItemProp): JSX.Element {
  return (
    <div className={`flex flex-1 ${className}`}>
      <div className={containerClassName}>
        <div className='flex justify-between items-center h-10'>
          <h6 className='font-normal text-dark-100'>{label}</h6>
          {children}
        </div>
        <p className='mt-2 text-lead text-xs leading-normal'>{description}</p>
      </div>
    </div>
  )
}
