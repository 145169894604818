import { useState, useEffect } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
export type TUseActivePathTab = {
  parent?: string
  redirect?: string
}
export default function useActivePageTab({
  parent,
  redirect,
}: TUseActivePathTab) {
  const [activePath, setActivePath] = useState('')
  const { pathname } = useLocation()
  const history = useHistory()
  useEffect(() => {
    const getActivePath = () => {
      const lastIndex = pathname.lastIndexOf('/')
      const path = pathname.substring(lastIndex + 1)
      if (path === parent && redirect) {
        history.push(redirect, { replace: true })
      } else {
        setActivePath(path)
      }
    }
    getActivePath()
  }, [pathname])
  return activePath
}
