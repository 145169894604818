import React from 'react'

import cn from 'classnames'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

type TEventTimeProps = {
  className?: string
  datetimeStr: string
  timezone: string
  outputFormat: string
}

export default function EventTime({
  className,
  datetimeStr,
  timezone,
  outputFormat,
}: TEventTimeProps) {
  return (
    <div className={cn('text-ink font-bold', className)}>
      {parseServerTimeToLocalTime(datetimeStr, timezone, outputFormat)}
    </div>
  )
}
