import React from 'react'

import cn from 'classnames'

type TSkeletonTextProp = {
  mode?: 'light' | 'dark'
  className?: string
}
export default function SkeletonText({
  className = '',
  mode = 'light',
}: TSkeletonTextProp): JSX.Element {
  return (
    <div
      className={cn(
        'rounded-lg min-h-2 min-w-4 flex flex-1 animate-pulse',
        mode === 'dark' ? 'bg-light-8' : 'bg-slate',
        className,
      )}
    ></div>
  )
}
