import React, { useEffect, useState } from 'react'

import { SolidMapPin } from '@rushable/icons'
import cn from 'classnames'
import Label from 'components/Label'
import useDebounce from 'hooks/useDebounce'
import { components, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { searchAreaApi } from 'redux/common/actions'

import {
  customStyles,
  customTheme,
  Control,
  IndicatorsContainer,
  IOptions,
} from './help'

export interface TProps {
  className?: string
  options?: any
  controlIcon?: React.ReactElement
  label?: string | React.ReactElement
  disabled?: boolean
  placeholder?: string
  value?: any | any[]
  defaultValue?: any | any[]
  onChange: (item: any, rest: any) => void
  requestApi?: (inputValue: string) => Promise<IOptions>
}

const prefix = 'ru-form-search-select'

// 查询地址接口 自定义 options
const getAddressData = async (inputValue: string): Promise<any> => {
  const res = await searchAreaApi(inputValue)
  return res.map((item: string) => ({ label: item, value: item }))
}

// @ts-ignore
const Input = props => {
  return <components.Input {...props} id='search-select-input' />
}

export default function SearchSelect({
  className,
  options = [],
  label,
  disabled,
  placeholder = '',
  value = '',
  controlIcon,
  onChange,
  requestApi = getAddressData,
}: TProps) {
  const [asyncOptions, setAsyncOptions] = useState(options)
  const [inputValue, setInputValue] = useState('')

  const customSearchOption = (props: OptionProps<IOptions>) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps
    const newProps = { ...props, innerProps: rest }
    const { data } = props
    let el = null
    if (data.optionChildren) {
      el = data.optionChildren
    } else {
      el = data.label || ''
      if (inputValue && data.label?.includes(inputValue)) {
        const arr = data.label.split(inputValue)
        const keyWord = <span className='text-blue'>{inputValue}</span>
        el = (
          <>
            {arr[0]}
            {keyWord}
            {arr[1]}
          </>
        )
      }
    }
    return <components.Option {...newProps}>{el}</components.Option>
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const getOptions = (value: string, callback: any) => {
    requestApi(value)
      .then((res: any) => {
        callback(res)
        setAsyncOptions(res)
      })
      .catch(() => {
        callback([])
        setAsyncOptions([])
      })
  }

  const getOptionsDebounce = useDebounce(getOptions, 1000)

  // @ts-ignore
  const handleChange = item => {
    setInputValue(item.label || '')
    onChange(item.label, item)
  }

  // @ts-ignore
  const handleInputChange = (e, { action }) => {
    if (action === 'input-change') {
      setInputValue(e)
    }
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: IOptions[]) => void,
  ) => {
    if (!inputValue) {
      return
    }
    getOptionsDebounce(inputValue, callback)
  }

  return (
    <div className={cn(className)}>
      {label && <Label className='mb-2'>{label}</Label>}
      <AsyncSelect
        className={cn(prefix)}
        // @ts-ignore
        icon={controlIcon}
        indicator={<SolidMapPin size={16} color='#D8DCDF' />}
        inputValue={inputValue}
        // menuIsOpen={true}
        isDisabled={disabled}
        blurInputOnSelect={true}
        cacheOptions={asyncOptions}
        defaultOptions={asyncOptions}
        loadOptions={loadOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        backspaceRemovesValue={true}
        hideSelectedOptions={false}
        styles={customStyles}
        theme={customTheme}
        components={{
          Input,
          // @ts-ignore
          Control,
          IndicatorsContainer,
          Option: customSearchOption,
        }}
      />
    </div>
  )
}
