import cn from 'classnames'
import Button from 'components/Button'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode.react'
import { useAppSelector } from 'redux/hooks'

interface Iprops {
  className?: string
}

export default function QRCodeDownload({ className }: Iprops): JSX.Element {
  const { selectedBrand } = useAppSelector(state => state.brand)
  const { selectedLocation } = useAppSelector(state => state.location)

  const handleDownload = (str: string) => {
    html2canvas(document.querySelector(str) as HTMLElement, {
      useCORS: true,
      backgroundColor: '#ffffff',
      scale: 3,
    }).then(canvas => {
      const pngUrl = canvas.toDataURL('image/jpeg')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = 'qrcodeimage.jpeg'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    })
  }

  const brand = selectedBrand?.name || '--'
  const location = selectedLocation?.name || '--'
  const onlineUrl = selectedLocation?.order_url || ''
  const tableUrl = selectedLocation?.order_url_contactless || ''
  return (
    <>
      <div className={cn('flex', className)}>
        <div className='mr-6'>
          <QRCodeWrap
            label='QR code for online ordering'
            type='online'
            url={onlineUrl}
            brand={brand}
            location={`@ ${location}`}
            handleClick={() => handleDownload('#download-online')}
          />
        </div>
        <div>
          <QRCodeWrap
            label='QR code for table ordering'
            type='table'
            url={tableUrl}
            brand={brand}
            location={`@ ${location}`}
            handleClick={() => handleDownload('#download-table')}
          />
        </div>
      </div>
      {/* 下载模板 */}
      <div className='fixed left-20 top-20 z-[-1] border-2'>
        <div className='p-4 bg-white' id='download-online'>
          <QRCodeItem
            type='online'
            showType='image'
            url={onlineUrl}
            brand={brand}
            location={`@ ${location}`}
          />
        </div>
      </div>
      <div className='fixed left-200 top-20 z-[-1] border-2'>
        <div
          className='p-[30px] grid grid-cols-3 gap-x-6 gap-y-[30px] bg-white'
          id='download-table'
        >
          {new Array(9).fill('-').map((item, index) => {
            return (
              <QRCodeItem
                key={index}
                type='table'
                showType='image'
                url={tableUrl}
                brand={brand}
                location={`@ ${location}`}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

interface Iqrcode {
  label?: string
  type: 'online' | 'table'
  showType?: 'web' | 'image'
  url: string
  brand: string
  location: string
  handleClick?: () => void
}

function QRCodeWrap(props: Iqrcode) {
  return (
    <>
      <div className='text-xs text-silver font-bold mb-2'>{props.label}</div>
      <div className='relative'>
        <QRCodeItem {...props} />
        <div className='absolute rounded-lg top-0 left-0 w-full h-full z-30 flex justify-center items-center bg-opacity-0 opacity-0	hover:opacity-100 hover:bg-opacity-64 transition-all duration-300'>
          <Button
            color='secondary'
            size='sm'
            className='cursor-pointer'
            onClick={() => props.handleClick?.()}
          >
            DOWNLOAD
          </Button>
        </div>
      </div>
    </>
  )
}

/**
 * 此处打印样式、与展示样式略有不同，因为 html2canvas 对 dom 截的图与真是 dom 展示的间距不同，所以在此处做了判断
 */
function QRCodeItem({ type, url, brand, location, showType = 'web' }: Iqrcode) {
  const obj = {
    online: 'Online Ordering',
    table: 'Table Ordering',
  }
  // const showUrl = url?.split('//')?.[1] || ''
  return (
    <div className='flex justify-center w-[168px] h-[224px] border border-zinc rounded-lg'>
      <div
        className={cn('text-center w-full mt-[26px]', {
          'mt-[22px]': showType === 'image',
        })}
      >
        <div className='text-base text-ink font-bold'>{obj[type]}</div>
        <div className='m-auto my-4 w-full h-[10px] relative'>
          <div
            className='absolute text-lead h-5 leading-5'
            style={{
              fontSize: '20px',
              width: '200%',
              transform: 'scale(0.5)',
              transformOrigin: 'top left',
            }}
          >
            {type === 'online' && '📱 Scan to place order'}
            {type === 'table' && 'TABLE# ______'}
          </div>
        </div>
        <div className='flex justify-center'>
          <QRCode size={1000} style={{ width: 72, height: 72 }} value={url} />
        </div>
        <div
          className={cn('mb-1 text-xs text-ink', {
            'mt-4': showType === 'web',
            'mt-[6px]': showType === 'image',
          })}
        >
          {brand}
        </div>
        <div
          className='text-xxs-c text-ink'
          style={{ transformOrigin: 'top center' }}
        >
          {location}
        </div>
      </div>
    </div>
  )
}
