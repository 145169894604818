import CouponNoneImg from 'assets/img/coupon-none.png'
import Button from 'components/Button'
import { useHistory, useRouteMatch } from 'react-router-dom'

export default function CouponNone(): JSX.Element {
  const history = useHistory()
  const { url } = useRouteMatch()
  const goCreateCoupon = () => {
    history.push(`${url}/detail/create`)
  }
  return (
    <div className='pt-[120px] flex justify-center'>
      <div>
        <div className='text-base text-lead pb-8'>
          You don’t have any coupon yet...
        </div>
        <img src={CouponNoneImg} className='w-[160px] m-auto' />
        <Button className='mt-6 m-auto' onClick={goCreateCoupon}>
          ADD NEW COUPON
        </Button>
      </div>
    </div>
  )
}
