import { Route, Switch, useRouteMatch } from 'react-router-dom'

import OrderDetailPage from './OrderDetailPage'
import OrderListPage from './OrderListPage'

export default function OrderHistoryPage(): JSX.Element {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <OrderListPage />
        </Route>
        <Route exact path={`${path}/order-detail/:orderId`}>
          <OrderDetailPage />
        </Route>
      </Switch>
    </>
  )
}
